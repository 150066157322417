import { useMemo } from "react";
import OpexDocument from "../opexPanel/DocumentView/modelOpexDocument";
import useDocument from "./useDocument";

const useDocumentTables = () => {
  const { document } = useDocument();

  const documentTables = useMemo(() => flattenTables(document), [document]);

  return {
    documentTables
  };
};

export default useDocumentTables;

const flattenTables = document => {
  let metaDataIndex = 0;
  const tablesArray = [];
  const documentModel = new OpexDocument(document);
  document &&
    document.raw_data_json.pages.forEach((page, pi) => {
      page.tableData.forEach((table, ti) => {
        if (document.meta_data_json.headers[metaDataIndex].qualified) {
          table.forEach((row, ri) => {
            row.forEach(cell => {
              cell.documentRowIndex = ri;
            });
          });
          const chartsOfAccountsIndex = documentModel.getChartOfAccountsColumnIndexByPageIndexTableIndex(
            pi,
            ti
          );
          tablesArray.push({
            pageIndex: pi,
            tableIndex: ti,
            tableData: table,
            chartsOfAccountsIndex,
            metaDataIndex
          });
        }
        metaDataIndex++;
      });
    });
  return tablesArray;
};
