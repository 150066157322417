import PropTypes from "prop-types";
import { BsCircleFill } from "react-icons/bs";
import IncludeExcludeRowButton from "../IncludeExcludeRowButton";
import SquareColorIcon from "../../../../../SquareColorIcon/SquareColorIcon";

const ExcludeButtonCellRenderer = ({
  copyCategoryToElements,
  baseTableRowId,
  rowToCopyId,
  rowHoverIndex,
  rowIndex,
  isExcluded,
  toggleExcludedRow,
  metaDataIndex,
  documentRowIndex,
  color
}) => {
  return (
    <>
      <div className="AccountsBaseTable__ExcludeButtons">
        <IncludeExcludeRowButton
          style={{
            display:
              copyCategoryToElements.every(
                data => data.baseTableRowId !== baseTableRowId
              ) && rowToCopyId !== baseTableRowId
                ? "flex"
                : "none"
          }}
          className={`AccountsBaseTable__ExcludeButtons--button ${
            rowHoverIndex === rowIndex ? "active" : ""
          }`}
          isExcluded={isExcluded}
          onClick={() => toggleExcludedRow(metaDataIndex, documentRowIndex)}
        />
      </div>
      <div
        style={{
          display: "flex",
          width: "auto",
          height: "100%",
          minWidth: "2rem",
          alignItems: "center",
          justifyContent: "flex-start"
        }}
      >
        {!isExcluded && color && <SquareColorIcon color={color} />}
      </div>
    </>
  );
};

export default ExcludeButtonCellRenderer;

ExcludeButtonCellRenderer.propTypes = {
  copyCategoryToElements: PropTypes.array,
  baseTableRowId: PropTypes.string,
  rowToCopyId: PropTypes.string,
  rowHoverIndex: PropTypes.number,
  rowIndex: PropTypes.number,
  isExcluded: PropTypes.bool,
  toggleExcludedRow: PropTypes.func,
  metaDataIndex: PropTypes.number,
  documentRowIndex: PropTypes.number,
  color: PropTypes.string
};
