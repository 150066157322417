import PropTypes from "prop-types";
import Spinner from "ui/components/shared/Spinner";
import { AiFillCloseCircle } from "react-icons/ai";

const PdfEditorCanvas = ({
  message,
  image,
  isProcessing,
  currentPage,
  canvasRef,
  canvasContainerRef,
  handleDrawing,
  handleMouseDown,
  handleMouseUp,
  movePosition,
  errorMessage,
  setErrorMessage
}) => {
  const clearErrorMessage = () => {
    setErrorMessage("");
  };
  return (
    <div className="SplitPanelV2__PdfEditor__canvas">
      {errorMessage.length > 0 && (
        <div className="SplitPanelV2__PdfEditor__error-message">
          <div
            className="SplitPanelV2__PdfEditor__error-close-button"
            onClick={clearErrorMessage}
          >
            <AiFillCloseCircle color={"red"} />
          </div>
          <span style={{ marginLeft: ".5rem" }}>{errorMessage}</span>
        </div>
      )}
      <div className="SplitPanelV2__PdfEditor__canvas-header">
        {message.length > 0 && (
          <div className="SplitPanelV2__PdfEditor__page-loading-message">
            <div className="SplitPanelV2__PdfEditor__info_message">
              {message}
            </div>
            <Spinner
              type="ThreeDots"
              classname="SplitPanelV2__PdfEditor__loading-page-image"
              color="#009bdf"
              height={40}
              width={40}
            />
          </div>
        )}
        {image && !isProcessing && message === "" && (
          <div className="SplitPanelV2__PdfEditor__info_message">{`Page ${currentPage +
            1}`}</div>
        )}
      </div>

      {!isProcessing && (
        <div
          className="SplitPanelV2__PdfEditorCanvas__canvas-container"
          ref={canvasContainerRef}
        >
          <canvas
            height={0}
            width={0}
            id="canvas"
            ref={canvasRef}
            onClick={handleDrawing}
            onMouseMove={movePosition}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
          ></canvas>
        </div>
      )}
      {isProcessing && (
        <div className="SplitPanelV2__PdfEditor__page-loading-message">
          <div className="SplitPanelV2__PdfEditor__info_message">
            Processing Edited Page
          </div>
          <Spinner
            type="ThreeDots"
            classname="SplitPanelV2__PdfEditor__loading-page-image"
            color="#009bdf"
            height={40}
            width={40}
          />
        </div>
      )}
    </div>
  );
};

export default PdfEditorCanvas;

PdfEditorCanvas.propTypes = {
  message: PropTypes.string,
  image: PropTypes.object,
  isProcessing: PropTypes.bool,
  currentPage: PropTypes.number,
  canvasRef: PropTypes.object,
  canvasContainerRef: PropTypes.object,
  handleDrawing: PropTypes.func,
  handleMouseDown: PropTypes.func,
  handleMouseUp: PropTypes.func,
  movePosition: PropTypes.func,
  errorMessage: PropTypes.string,
  setErrorMessage: PropTypes.func
};
