import { formatAggregationMetrics } from "ui/helpers/metrics";

export function addTenantMetricsToTenantRow(tenant) {
  const totals = formatAggregationMetrics(tenant.metrics);
  return {
    ...tenant,
    property: {},
    annual_rent: totals.totalPerYear,
    monthly_rent: totals.totalPerMonth,
    monthly_rent_per_sqft: totals.averageMonthlyRentPerSqft,
    square_footage: totals.totalSquareFootage,
    average_annual_rent_per_sqft: totals.averageAnnualRentPerSqft,
    lease_term: totals.averageLeaseTerm,
    remaining_term: totals.averageLeaseRemaining
  };
}

export function expandTenantProperties(tenant, tenants, spliceTenants) {
  let index = -1;
  while (index < tenants.length) {
    index++;
    const currTenant = tenants[index];
    if (currTenant.tenant === tenant) {
      break;
    }
  }
  return tenants
    .slice(0, index + 1)
    .concat(spliceTenants)
    .concat(tenants.slice(index + 1, tenants.length));
}

export function collapseTenantProperties(tenants, tenant) {
  let index = 0;
  const newTenantsArray = [];
  while (index < tenants.length) {
    let currTenant = tenants[index];
    newTenantsArray.push(currTenant);
    let j = index + 1;
    if (currTenant.tenant === tenant) {
      let nextTenant = tenants[j];
      while (nextTenant && nextTenant.tenant === tenant) {
        j++;
        nextTenant = tenants[j];
      }
    }
    index = j;
  }
  return newTenantsArray;
}
