import Select from "react-select";

export default function CustomDropDown({
  className,
  options,
  value,
  defaultValue,
  dropdownStateChange,
  ...otherProps
}) {
  const onSelectedDocChange = dropdownValue => {
    dropdownStateChange(dropdownValue);
  };

  return (
    <div className={"custom-drop-down"}>
      <Select
        className={className}
        options={options}
        onChange={onSelectedDocChange}
        value={value}
        defaultValue={defaultValue}
        {...otherProps}
      />
    </div>
  );
}
