import { Component, createRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import cx from "classnames";
import { bindActionCreators } from "redux";
import { StickyTable, Row, Cell } from "react-sticky-table";

import { fetchNeedAttentionDocuments } from "ui/store/actions/rentRoll";

import Button from "ui/components/shared/Button";
import NavLink from "ui/components/shared/NavLink.jsx";
import DocumentRow from "./DocumentRow";

export class DocumentsPanel extends Component {
  constructor(props) {
    super(props);
    this.panelRef = createRef();
  }

  componentDidMount() {
    const { fetchNeedAttentionDocuments } = this.props;
    window.addEventListener("click", this.closeOutsideDiv);
    fetchNeedAttentionDocuments();
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.closeOutsideDiv);
  }

  closeOutsideDiv = e => {
    const { closePopover } = this.props;
    if (e.target !== this.panelRef) {
      closePopover();
    }
  };

  renderDocumentList = () => {
    const headers = ["Document", "Property", "Date", "Status", "Action"];
    const { needAttentionDocs, userName } = this.props;
    let element = <div></div>;
    if (needAttentionDocs && needAttentionDocs.length > 0) {
      element = (
        <StickyTable
          stckyHeaderCount={1}
          leftStickyColumnCount={0}
          borderWidth={"1px"}
        >
          <Row
            style={{ whiteSpace: "normal" }}
            className={cx("sticky-table-header")}
          >
            {headers.map(col_header => (
              <Cell
                className={"StatusTable__cell" + col_header}
                style={{
                  color: "#3B3E42"
                }}
                key={col_header}
              >
                {col_header}
              </Cell>
            ))}
          </Row>
          {needAttentionDocs.map(doc => (
            <DocumentRow key={doc.id} doc={doc} />
          ))}
        </StickyTable>
      );
    } else {
      element = (
        <div style={{ padding: "12px 0.75rem", lineHeight: "16px" }}>
          No documents uploaded by {userName}, click{" "}
          <span style={{ fontWeight: "bold" }}>Manage All Documents</span> to
          see all documents in the queue.
        </div>
      );
    }
    return element;
  };

  render() {
    return (
      <div
        className="UploadPanel__panel"
        style={{ marginTop: "20px" }}
        ref={this.panelRef}
      >
        <div className="StatusTable__panel" style={{ height: "426px" }}>
          <div
            className="PropertiesList__grid"
            style={{ height: "initial", maxHeight: "396px" }}
          >
            <div
              style={{
                padding: "12px 0.75rem 0",
                fontSize: "16px",
                fontWeight: "bold"
              }}
            >
              My Documents
            </div>

            {this.renderDocumentList()}
          </div>
        </div>
        <div className="UploadPanel__bottomBar">
          <NavLink to={{ pathname: "/manage-files-view" }}>
            <Button
              className={cx("UploadPanel__bottomButton", "Button__blueButton")}
              style={{
                fontFamily: "Roboto",
                fontWeight: "normal",
                fontSize: "12px",
                borderRadius: "3px",
                textTransform: "capitalize",
                height: "70%"
              }}
            >
              Manage All Documents
            </Button>
          </NavLink>
        </div>
      </div>
    );
  }
}

DocumentsPanel.propTypes = {
  fetchNeedAttentionDocuments: PropTypes.func,
  closePopover: PropTypes.func,
  needAttentionDocs: PropTypes.array,
  userName: PropTypes.string
};

function mapStateToProps(state) {
  return {
    needAttentionDocs: state.rentRoll.needAttentionDocs,
    userName: state.currentUser.email
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchNeedAttentionDocuments }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsPanel);
