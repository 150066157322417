import { useState, useEffect } from "react";

/**
 * This hook allows you to easily detect when an element is visible on the screen as well as specify how
 * much of the element should be visible before being considered on screen. Perfect for lazy loading
 * images or triggering animations when the user has scrolled down to a particular section.
 */
function useOnScreen(ref) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(([entry]) =>
    setIntersecting(entry.isIntersecting)
  );

  useEffect(() => {
    observer.observe(ref.current);
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, []);

  return isIntersecting;
}

export default useOnScreen;
