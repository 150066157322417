import PropTypes from "prop-types";
import {
  CELL_STATUS_CORRECTED_COLOR,
  CONFIDENCE_CONFIRMED_COLOR,
  isCellConfirmed
} from "ui/components/opex/shared";
import SquareColorIcon from "../SquareColorIcon/SquareColorIcon";

const CorrectedCellIndicator = ({ corrected }) => {
  if (corrected) {
    return <SquareColorIcon color={CELL_STATUS_CORRECTED_COLOR} />;
  }
  if (isCellConfirmed(corrected)) {
    return <SquareColorIcon color={CONFIDENCE_CONFIRMED_COLOR} />;
  }
  return null;
};

export default CorrectedCellIndicator;

CorrectedCellIndicator.propTypes = {
  corrected: PropTypes.bool
};
