import { merge } from "ramda";
import {
  SHOW_LOGIN_MODAL,
  HIDE_LOGIN_MODAL
} from "ui/store/actions/mainLoginPage";

export default function mainLoginPage(state = { login: false }, action) {
  switch (action.type) {
    case SHOW_LOGIN_MODAL: {
      return merge(state, {
        login: true
      });
    }
    case HIDE_LOGIN_MODAL: {
      return merge(state, {
        login: false
      });
    }
    default:
      return state;
  }
}
