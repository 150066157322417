import React from "react";
import PropTypes from "prop-types";
import { uniq } from "ramda";
import { compact } from "helpers/presence";
import { connect } from "react-redux";
import { getUsageReport } from "ui/store/actions/usageAnalytics";
import { DatePicker } from "antd";
import dayjs from "ui/components/helpers/dayjs";
import { PERIOD_DATE_FORMAT } from "ui/components/helpers/dayjs";
import AuthenticateAdmin from "ui/components/routing/AuthenticateAdmin";
import AdminLayout from "ui/components/admin/AdminLayout";

class UsageReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: dayjs().startOf("day"),
      endDate: dayjs().startOf("day"),
      companyFilter: "All"
    };
  }
  componentDidMount() {
    const { getUsageReport } = this.props;
    const { startDate, endDate } = this.state;
    getUsageReport(
      startDate.format(PERIOD_DATE_FORMAT),
      endDate.format(PERIOD_DATE_FORMAT)
    );
  }

  filterByCompany = () => {
    const { usageReport } = this.props;
    const { companyFilter } = this.state;
    return compact(
      usageReport.map((row, i) => {
        if (companyFilter === "All" || row.company_name === companyFilter) {
          return (
            <tr key={i}>
              <td>{row.company_name}</td>
              <td>{row.document_type}</td>
              <td>{row.file_extension}</td>
              <td>{row.number_of_properties_with_document_uploaded}</td>
              <td>
                {row.number_of_properties_with_document_uploaded_and_complete}
              </td>
              <td>{row.count_of_documents_uploaded}</td>
              <td>
                {parseFloat(row.average_number_of_pages_per_document).toFixed(
                  2
                )}
              </td>
              <td>{parseFloat(row.average_file_size / 1000).toFixed(2)}</td>
            </tr>
          );
        } else {
          return null;
        }
      })
    );
  };

  getAllCompanyNamesFromReport = (usageReport = []) => {
    return uniq(usageReport.map(row => row.company_name));
  };

  render() {
    const { usageReport, getUsageReport } = this.props;
    const companiesInUsageReport = this.getAllCompanyNamesFromReport(
      usageReport
    );
    companiesInUsageReport.unshift("All");
    const { RangePicker } = DatePicker;

    return (
      <AdminLayout breadcrumbs={[{ label: "Dashboard", path: "/admin" }]}>
        <RangePicker
          defaultValue={[this.state.startDate, this.state.endDate]}
          style={{ margin: "15px 0px" }}
          format={PERIOD_DATE_FORMAT}
          onCalendarChange={value => {
            // SQL query requires endDate to be exclusive so 1 day is added to endDate
            this.setState({
              startDate: dayjs(value[0]).startOf("day"),
              endDate: dayjs(value[1]).startOf("day")
            });
            getUsageReport(
              this.state.startDate.format(PERIOD_DATE_FORMAT),
              this.state.endDate.format(PERIOD_DATE_FORMAT)
            );
          }}
        />
        <div className="AnalyticsTable">
          <table>
            <thead>
              <tr>
                <th>
                  <select
                    type="select"
                    onChange={e => {
                      if (e.target.value === "All") {
                        this.setState({ companyFilter: "All" });
                      } else {
                        this.setState({ companyFilter: e.target.value });
                      }
                    }}
                  >
                    {companiesInUsageReport.map((companyName, cidx) => {
                      return (
                        <option
                          key={cidx}
                          selected={this.state.companyFilter === companyName}
                          value={companyName}
                        >
                          {companyName}
                        </option>
                      );
                    })}
                  </select>
                </th>
                <th>Document Type</th>
                <th>File Type</th>
                <th># of Properties with documents uploaded</th>
                <th>
                  # of Properties which documents have been fully processed
                  (marked complete)
                </th>
                <th># of total documents uploaded</th>
                <th>Average number of pages per document</th>
                <th>Average File Size (in KB)</th>
              </tr>
            </thead>
            <tbody>{this.filterByCompany()}</tbody>
          </table>
        </div>
      </AdminLayout>
    );
  }
}

UsageReport.propTypes = {
  usageReport: PropTypes.array,
  getUsageReport: PropTypes.func
};

function mapStateToProps({ usageAnalytics }) {
  return {
    usageReport: usageAnalytics.report
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getUsageReport: (date1, date2) => {
      dispatch(getUsageReport(date1, date2));
    }
  };
}

export default AuthenticateAdmin(
  connect(mapStateToProps, mapDispatchToProps)(UsageReport)
);
