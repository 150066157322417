export function rowOuter(row) {
  return {
    x1: row[0].geometry.boundingBox.Left * 100,
    y1: row[0].geometry.boundingBox.Top * 100,
    x2:
      (row[row.length - 1].geometry.boundingBox.Left +
        row[row.length - 1].geometry.boundingBox.Width) *
      100,
    y2: row[row.length - 1].geometry.boundingBox.Top * 100,
    x3:
      (row[row.length - 1].geometry.boundingBox.Left +
        row[row.length - 1].geometry.boundingBox.Width) *
      100,
    y3:
      (row[row.length - 1].geometry.boundingBox.Top +
        row[row.length - 1].geometry.boundingBox.Height) *
      100,
    x4: row[0].geometry.boundingBox.Left * 100,
    y4:
      (row[0].geometry.boundingBox.Top + row[0].geometry.boundingBox.Height) *
      100
  };
}
