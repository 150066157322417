import moment from "moment";
import { documentStatus as DocumentStatus } from "server/middleware/api/helpers/documents";

export const documentStatus = {
  [DocumentStatus.COMPLETED]: "Complete",
  [DocumentStatus.PENDING_REVIEW]: "Needs review",
  [DocumentStatus.CREATED]: "Uploading",
  [DocumentStatus.PROCESSING_IMAGES]: "Processing Images",
  [DocumentStatus.ANALYSIS_QUEUED]: "Analysis Queued",
  [DocumentStatus.ANALYZING]: "Analyzing Document",
  [DocumentStatus.TEXTRACT_TIMEOUT]: "Timeout during Upload",
  [DocumentStatus.FAILED]: "Failed during Upload",
  [DocumentStatus.PROPERTY_INFO_NEEDED]: "Property Info Needed"
};

export const fields = [
  "File Name",
  "Date Modified",
  "Portfolio",
  "Property",
  "Effective Date",
  "Status",
  "Review Document"
];

export const columnDetails = {
  "File Name": {
    columnName: "File Name",
    sortName: "documents.original_filename"
  },
  "Date Modified": {
    columnName: "Date Modified",
    sortName: "documents.updated_at"
  },
  Portfolio: {
    columnName: "Portfolio",
    sortName: "portfolios.name"
  },
  Property: {
    columnName: "Property",
    sortName: "properties.street_1"
  },
  "Effective Date": {
    columnName: "Effective Date",
    sortName: "documents.effective_date"
  },
  Status: {
    columnName: "Status",
    sortName: "documents.status"
  },
  "Review Document": {
    columnName: "",
    sortName: ""
  }
};

export const parseAddress = doc => {
  return `${doc.street1}${doc.street2 ? ` ${doc.street2}` : ""}${
    doc.city ? ` ${doc.city},` : ""
  }${doc.state ? ` ${doc.state}` : ""}${
    doc.postalCode ? ` ${doc.postalCode}` : ""
  }`;
};

export function formatDate(date) {
  if (!date) return "-";
  return moment(date)
    .utc()
    .format("MM/DD/YYYY");
}

export function formatTimestamp(date) {
  if (!date) return "-";
  return moment(date).format("MM/DD/YYYY HH:mm:ss");
}
