import useDocument from "ui/components/rentRoll/splitPanelV2/hooks/useDocument";

export function getPageImage(index) {
  const { document } = useDocument();
  if (!document) return "";
  if (!document.raw_data_json) return "";
  if (document.raw_data_json.pages.length === 0) return "";

  return document.raw_data_json.pages[index].imageUrl;
}

export function getPageTableData(index) {
  const { document } = useDocument();

  const tableData = document.raw_data_json.pages[index];

  return tableData;
}

export default function usePageData() {
  const { document } = useDocument();

  return document;
}
