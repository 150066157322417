import PropTypes from "prop-types";
import cx from "classnames";
import Toggle from "../Toggle/Toggle";
import { useEffect, useRef } from "react";
import "../../../../helpers/scrollIntoViewIfNeeded.polyfill";

export default function TableInfo({
  isSelected,
  isActive,
  onSelectTable,
  onSetActiveTable,
  table: {
    columnCount,
    pageIndex,
    rowCount,
    headerIndex,
    sheetName,
    tableIndex
  }
}) {
  const headerIndexDisplayValue = headerIndex + 1;
  const countText = `${rowCount} rows, ${columnCount} columns`;
  const el = useRef();

  function handleClick() {
    onSelectTable({ headerIndex, pageIndex });
  }

  async function handleActivateTable() {
    onSetActiveTable({ active: true, headerIndex, pageIndex });
  }

  async function handleDeactivateTable() {
    onSetActiveTable({ active: false, headerIndex, pageIndex });
  }

  const tableText = tableIndex + 1;

  const text = !isActive
    ? "Not Used"
    : sheetName
    ? sheetName
    : `Page ${pageIndex + 1} (Table ${tableText})`;

  useEffect(() => {
    isSelected && el.current.scrollIntoViewIfNeeded();
  }, [isSelected]);

  return (
    <div
      ref={el}
      onClick={handleClick}
      className={cx("SplitPanelV2__LeftSidebar__TableInfo", {
        ["SplitPanelV2__LeftSidebar__TableInfo--selected"]: isSelected
      })}
    >
      <div className="SplitPanelV2__LeftSidebar__TableInfo-number">
        {headerIndexDisplayValue}
      </div>
      <div className="SplitPanelV2__LeftSidebar__TableInfo-data">
        <div
          className={cx("SplitPanelV2__LeftSidebar__TableInfo-text", {
            ["SplitPanelV2__LeftSidebar__TableInfo-text--not-active"]: !isActive
          })}
        >
          {text}
          <br />
          <small>{countText}</small>
        </div>

        <Toggle
          activate={handleActivateTable}
          deactivate={handleDeactivateTable}
          isActive={isActive}
          isSelected={isSelected}
        />
      </div>
    </div>
  );
}

TableInfo.propTypes = {
  isActive: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onSelectTable: PropTypes.func.isRequired,
  onSetActiveTable: PropTypes.func.isRequired,
  table: PropTypes.shape({
    columnCount: PropTypes.number.isRequired,
    pageIndex: PropTypes.number.isRequired,
    rowCount: PropTypes.number.isRequired,
    headerIndex: PropTypes.number.isRequired,
    sheetName: PropTypes.string,
    tableIndex: PropTypes.number,
    tableIndexes: PropTypes.array
  }).isRequired
};
