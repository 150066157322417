import { Component } from "react";
import { connect } from "react-redux";

import AdminLayout from "ui/components/admin/AdminLayout";
import AdminPredefForm from "./AdminPredefForm";

import AuthenticateAdmin from "ui/components/routing/AuthenticateAdmin";

class AdminPredefDashboard extends Component {
  render() {
    return (
      <AdminLayout
        className="AdminPredefDashboard"
        breadcrumbs={[{ label: "Dashboard", path: "/admin" }]}
      >
        <AdminPredefForm />
      </AdminLayout>
    );
  }
}

const AuthAdminPredefDashboard = AuthenticateAdmin(AdminPredefDashboard);

export default connect()(AuthAdminPredefDashboard);
