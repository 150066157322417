import { MANUAL_ADD } from "services/helpers/rentRoll";
import { DELETED_STATUS } from "../hooks/useRentRollTableData";
import { generateTableKey } from "./tableKeys";

export function createPaginationArray(document) {
  if (!document) return [];
  if (!document.raw_data_json) return [];
  if (!document.meta_data_json) return [];
  if (!document.raw_data_json.pages) return [];
  if (!document.meta_data_json.headers) return [];
  if (document.raw_data_json.pages.length === 0) return [];
  if (document.meta_data_json.headers.length === 0) return [];

  const pageArray = document.raw_data_json.pages;
  const headerArray = [...document.meta_data_json.headers];

  let headerArrayIdx = 0;
  const paginationArray = pageArray.map((page, pi) => {
    if (page.tableData.length > 0 && headerArray.length > 0) {
      let tableDataArray = [];
      page.tableData.forEach((table, ti) => {
        tableDataArray.push({
          ti,
          tableData: table,
          headers: headerArray[headerArrayIdx]
        });
        headerArrayIdx++;
      });
      return {
        pi,
        tableData: tableDataArray
      };
    } else {
      return {
        pi,
        tableData: []
      };
    }
  });
  return paginationArray;
}

export const getTemplateObject = (
  document,
  headerData,
  location,
  cache,
  rowIds
) => {
  const { pi, ti, ri } = location;

  let templateObj =
    headerData[pi][ti].headers.length > 0
      ? headerData[pi][ti].headers
      : Array.apply(
          null,
          Array(document.raw_data_json.pages[pi].tableData[ti][ri].length)
        ).map(_ => {
          return {};
        });
  templateObj.forEach((header, ci) => {
    let id = cache["headerSelect-" + pi + "-" + ti + "-" + ri + "-" + ci];
    let required =
      cache["headerRequired-" + pi + "-" + ti + "-" + ri + "-" + ci];
    let headerName =
      rowIds && rowIds.length > 1
        ? rowIds.length === 2
          ? `${
              document.raw_data_json.pages[pi].tableData[ti][rowIds[0]][ci].text
            }${
              document.raw_data_json.pages[pi].tableData[ti][rowIds[1]][ci].text
                ? ` ${
                    document.raw_data_json.pages[pi].tableData[ti][rowIds[1]][
                      ci
                    ].text
                  }`
                : ""
            }`
          : `${
              document.raw_data_json.pages[pi].tableData[ti][rowIds[0]][ci].text
            }${
              document.raw_data_json.pages[pi].tableData[ti][rowIds[1]][ci].text
                ? ` ${
                    document.raw_data_json.pages[pi].tableData[ti][rowIds[1]][
                      ci
                    ].text
                  }`
                : ""
            }${
              document.raw_data_json.pages[pi].tableData[ti][rowIds[2]][ci].text
                ? ` ${
                    document.raw_data_json.pages[pi].tableData[ti][rowIds[2]][
                      ci
                    ].text
                  }`
                : ""
            }`
        : document.raw_data_json.pages[pi].tableData[ti][ri][ci].text;
    let skipPos = 0;
    let skipVal =
      cache["skipRow-" + pi + "-" + ti + "-" + ri + "-" + ci + "-" + skipPos];

    if (header.skipRowForValue == null) {
      header.skipRowForValue = [];
    }

    while (skipVal != null || header.skipRowForValue[skipPos] != null) {
      if (skipVal != null) {
        if (header.skipRowForValue[skipPos] != null) {
          header.skipRowForValue[skipPos] = skipVal;
        } else {
          header.skipRowForValue.push(skipVal);
        }
      }
      skipPos++;
      skipVal =
        cache["skipRow-" + pi + "-" + ti + "-" + ri + "-" + ci + "-" + skipPos];
    }

    header.skipRowForValue = header.skipRowForValue.filter(
      skip => skip.length > 0
    );

    if (id) {
      header.id = id;
      header.isMapped = id !== "none";
    }

    if (typeof required !== "undefined") {
      header.required = required;
    }

    if (headerName) {
      header.headerNames = [headerName];
    } else {
      header.headerNames = [""];
    }
    header.simScore = 0.9;
    delete header.score;
  });

  return templateObj;
};

// adding these functions for potential later use, but not currently used.
export function configurePreviewPaneHeader(document, rentRollColumns) {
  if (!document) {
    return {
      rawDataConfig: [],
      previewTableConfig: [],
      unfilteredRawDataConfig: []
    };
  }

  const pagination = createPaginationArray(document);

  if (pagination.length === 0) {
    return {
      rawDataConfig: [],
      previewTableConfig: [],
      unfilteredRawDataConfig: []
    };
  }

  let qualifiedHeaderConfigArray = [];
  pagination.forEach((page, pi) => {
    if (page.tableData.length > 0) {
      page.tableData.forEach((table, ti) => {
        if (table.headers.qualified) {
          qualifiedHeaderConfigArray = [
            ...qualifiedHeaderConfigArray,
            { ...table.headers, ti, pi }
          ];
        }
      });
    }
  });

  let mappedAndRequiredHeaders = [];
  let tenantName = [];
  let advancedRules = [];
  let notMappedAndRequired = [];
  let notMappedAndNotRequired = [];

  qualifiedHeaderConfigArray.forEach(config => {
    if (config.advancedRules && config.advancedRules.length > 0) {
      config.advancedRules.forEach(rule => {
        advancedRules.push({ id: rule.field, adv: true });
      });
    }
    config.headers.forEach(headerObj => {
      if (headerObj.id === "tenant_name") {
        tenantName.push({
          ...headerObj,
          pi: config.pi,
          ti: config.ti,
          ri: config.rowIndex
        });
      } else if (
        headerObj.isMapped &&
        headerObj.required &&
        headerObj.id !== "tenant_name"
      ) {
        mappedAndRequiredHeaders = [
          ...mappedAndRequiredHeaders,
          { ...headerObj, pi: config.pi, ti: config.ti, ri: config.rowIndex }
        ];
      } else if (
        headerObj.isMapped &&
        !headerObj.required &&
        headerObj.id !== "tenant_name"
      ) {
        notMappedAndRequired = [
          ...notMappedAndRequired,
          { ...headerObj, pi: config.pi, ti: config.ti, ri: config.rowIndex }
        ];
      }
    });
  });

  const mappedAndRequiredHeadersKey = mappedAndRequiredHeaders.map(
    header => header.id
  );
  const advancedRulesKey = advancedRules.map(header => header.id);

  const notMappedAndRequiredKey = notMappedAndRequired.map(header => header.id);
  qualifiedHeaderConfigArray.forEach(config => {
    config.headers.forEach(headerObj => {
      if (
        !advancedRulesKey.includes(headerObj.id) &&
        !mappedAndRequiredHeadersKey.includes(headerObj.id) &&
        !notMappedAndRequiredKey.includes(headerObj.id) &&
        !notMappedAndNotRequired
          .map(header => header.id)
          .includes(headerObj.id) &&
        headerObj.id !== "tenant_name"
      ) {
        notMappedAndNotRequired = [
          ...notMappedAndNotRequired,
          { ...headerObj, pi: config.pi, ti: config.ti, ri: config.rowIndex }
        ];
      }
    });
  });

  const unfilteredRawDataConfig = [
    ...tenantName,
    ...notMappedAndRequired,
    ...notMappedAndNotRequired
  ];

  const orderedHeadersWithConfig = [
    ...tenantName,
    ...mappedAndRequiredHeaders,
    ...notMappedAndRequired,
    ...advancedRules,
    { id: "is_vacant" },
    ...notMappedAndNotRequired
  ];
  const headersObj = orderedHeadersWithConfig.map((header, hi) => {
    const key = `header-${hi}-${header.id}-${header.pi}-${header.ti}`;

    let headerObj = {};

    headerObj = {
      title: header.id,
      dataIndex: header.id,
      key,
      opaque:
        !advancedRulesKey.includes(header.id) &&
        !mappedAndRequiredHeadersKey.includes(header.id) &&
        !notMappedAndRequiredKey.includes(header.id),
      className: "PreviewPaneTable__middleColumn"
    };

    if (hi === 0) {
      headerObj.opaque = false;
      headerObj["className"] = "PreviewPaneTable__firstColumn";
    } else if (header.id === "is_vacant") {
      headerObj.opaque = false;
    }

    return headerObj;
  });

  const previewHeaders = Array.from(
    new Set(headersObj.map(header => header.dataIndex))
  ).map(id => {
    const previewHeader = {
      title: id,
      dataIndex: id,
      key: headersObj.find(header => header.dataIndex === id).key,
      opaque: headersObj.find(header => header.dataIndex === id).opaque,
      className: headersObj.find(header => header.dataIndex === id).className
    };

    if (
      rentRollColumns &&
      rentRollColumns.fields &&
      rentRollColumns.fields.length > 0 &&
      rentRollColumns.fields.find(
        column => column.term === previewHeader.title
      ) &&
      rentRollColumns.fields.find(column => column.term === previewHeader.title)
        .dataType
    ) {
      const foundColumn = rentRollColumns.fields.find(
        column => column.term === previewHeader.title
      );
      previewHeader.dataType = foundColumn.dataType;
    }

    return previewHeader;
  });

  return {
    unfilteredRawDataConfig,
    rawDataConfig: orderedHeadersWithConfig,
    previewTableConfig: [
      ...previewHeaders,
      {
        title: "Actions",
        dataIndex: "actions",
        key: `header-${headersObj.length}-actions`,
        opaque: false,
        className: "PreviewPaneTable__lastColumn"
      }
    ]
  };
}

export function mapRentRollToDocument(
  rentRoll,
  document,
  previewTableConfig = [],
  documentTables
) {
  const headerData = organizeHeaderData(document);

  if (
    !rentRoll ||
    !document ||
    !previewTableConfig ||
    !rentRoll.length ||
    !previewTableConfig.length ||
    headerData.length === 0
  )
    return [];

  const headerKeys = previewTableConfig.map(header => header.dataIndex);
  const documentRows = rentRoll.filter(({ status }) => status !== MANUAL_ADD);
  const mapped = [];
  for (let rowIndex = 0; rowIndex < documentRows.length; rowIndex++) {
    const {
      doc_page_id: rowPageIndex,
      doc_table_id: rowTableIndex,
      doc_data_json: { row: rowData },
      doc_row_ids: rowIds
    } = documentRows[rowIndex];
    const rentRollRowIndex = rentRoll
      .filter(
        ({ doc_page_id, doc_table_id }) =>
          doc_page_id === rowPageIndex && doc_table_id === rowTableIndex
      )
      .findIndex(({ doc_row_ids }) => doc_row_ids[0] === rowIds[0]);
    for (let headerIndex = 0; headerIndex < headerKeys.length; headerIndex++) {
      if (headerData[rowPageIndex][rowTableIndex]) {
        const rentRollKey = `${rentRollRowIndex}-${headerIndex}`;
        const header = headerData[rowPageIndex][rowTableIndex];
        const headers = header.headers;
        const docColumnIndex = headers.findIndex(
          ({ id }) => id === headerKeys[headerIndex]
        );
        const advancedRulesFields =
          header.advancedRules && header.advancedRules.length > 0
            ? header.advancedRules.map(rule => rule.field)
            : [];

        if (advancedRulesFields.includes(headerKeys[headerIndex])) {
          const docIds = rowData.map(({ id }) => id);
          const docIdIdx = docIds.indexOf(headerKeys[headerIndex]);
          if (docIdIdx > -1) {
            const { docPosition } = rowData[docIdIdx];
            if (docPosition) {
              const { col, row, page, table } = docPosition;
              mapped.push({
                rentRollKey,
                documentKey: generateTableKey({
                  headerIndex: documentTables
                    .filter(
                      elem =>
                        elem.pageIndex === page && elem.tableIndex === table
                    )
                    .map(({ headerIndex }) => headerIndex)[0],
                  pageIndex: page,
                  tableIndex: table,
                  rowIndex: row,
                  colIndex: col
                })
              });
            }
          }
        } else if (docColumnIndex > -1) {
          mapped.push({
            rentRollKey,
            documentKey: generateTableKey({
              headerIndex: documentTables
                .filter(
                  elem =>
                    elem.pageIndex === rowPageIndex &&
                    elem.tableIndex === rowTableIndex
                )
                .map(({ headerIndex }) => headerIndex)[0],
              rowIndex: rowIds[0],
              columnIndex: docColumnIndex,
              tableIndex: rowTableIndex,
              pageIndex: rowPageIndex
            })
          });
        }
      }
    }
  }
  return mapped;
}

export function organizeHeaderData(document) {
  if (!document) return [];
  let headerData = [];

  if (document.raw_data_json && document.raw_data_json.pages) {
    let hi = 0;
    for (let pi = 0; pi < document.raw_data_json.pages.length; pi++) {
      headerData[pi] = [];
      for (
        let ti = 0;
        ti < document.raw_data_json.pages[pi].tableData.length;
        ti++
      ) {
        headerData[pi][ti] = document.meta_data_json.headers[hi];
        headerData[pi][ti].headerIndex = hi;
        hi++;
      }
    }
  }

  return headerData;
}

export const getTotalPercentageComplete = (rentRollDocument, rentRoll) => {
  if (!rentRollDocument) return 0;
  if (!rentRollDocument.raw_data_json) return 0;

  const headerData = organizeHeaderData(rentRollDocument);
  let totalCount = 0;
  let completeCount = 0;

  const deletedRentRollRows = rentRoll.filter(
    row => row.status === DELETED_STATUS
  );

  const isADeletedRentRollRow = (deletedRentRollRows, location) => {
    for (let i = 0; i < deletedRentRollRows.length; i++) {
      if (
        deletedRentRollRows[i].doc_page_id === location.pi &&
        deletedRentRollRows[i].doc_table_id === location.ti &&
        deletedRentRollRows[i].doc_row_ids.includes(location.ri)
      ) {
        return true;
      }
    }

    return false;
  };

  const isHeaderUnmapedAndUnqualified = headerData => {
    return headerData && !headerData.metaName && !headerData.qualified;
  };

  const isBlankRow = (row, requiredCols, headerData) => {
    if (isHeaderUnmapedAndUnqualified(headerData)) {
      return false;
    }

    const isBlankArray = new Array(requiredCols.length);
    for (let i = 0; i < row.length; i++) {
      isBlankArray[i] = row[i].text ? false : true;
    }
    return !isBlankArray.includes(false);
  };

  const isExcludedRow = (row, exclRows, requiredCols) => {
    if (isBlankRow(row, requiredCols)) {
      return true;
    }

    for (let ci = 0; ci < exclRows.length; ci++) {
      if (
        exclRows[ci] &&
        exclRows[ci].length > 0 &&
        (exclRows[ci].includes(row[ci].text.toLowerCase()) ||
          exclRows[ci].includes(row[ci].text) ||
          exclRows[ci].some(excludedValue => {
            return row[ci].text
              .trim()
              .toLowerCase()
              .includes(excludedValue);
          }))
      ) {
        return true;
      } else if (
        exclRows[ci] &&
        exclRows[ci].length === 0 &&
        requiredCols[ci] &&
        row[ci].text === ""
      ) {
        return true;
      }
    }

    return false;
  };

  // Calculate total count
  rentRollDocument.raw_data_json.pages.forEach((page, pi) => {
    page.tableData.forEach((table, ti) => {
      if (
        headerData[pi][ti] &&
        headerData[pi][ti].headers &&
        headerData[pi][ti].headers.length > 0
      ) {
        let exclCols = [];
        let exclRows = new Array(headerData[pi][ti].headers.length);
        let requiredCols = new Array(headerData[pi][ti].headers.length);
        headerData[pi][ti].headers.forEach((header, hi) => {
          if (!header.isMapped) exclCols.push(hi);
          if (header.skipRowForValue) exclRows[hi] = header.skipRowForValue;
          requiredCols[hi] = header.required ? header.required : false;
        });
        table.forEach((row, ri) => {
          if (
            headerData[pi][ti].rowIndex < ri &&
            !isADeletedRentRollRow(deletedRentRollRows, { pi, ti, ri }) &&
            !isExcludedRow(row, exclRows, requiredCols)
          ) {
            if (!headerData[pi][ti].metaName) {
              totalCount += row.length;
            } else {
              row.forEach((_, ci) => {
                if (!exclCols.includes(ci)) {
                  totalCount++;
                }
              });
            }
          }
        });
      }
    });
  });

  // Calculate complete count
  rentRollDocument.raw_data_json.pages.forEach((page, pi) => {
    page.tableData.forEach((table, ti) => {
      if (
        headerData[pi][ti] &&
        headerData[pi][ti].headers &&
        headerData[pi][ti].headers.length > 0
      ) {
        let exclCols = [];
        let exclRows = new Array(headerData[pi][ti].headers.length);
        let requiredCols = new Array(headerData[pi][ti].headers.length);
        headerData[pi][ti].headers.forEach((header, hi) => {
          if (!header.isMapped) exclCols.push(hi);
          if (header.skipRowForValue) exclRows[hi] = header.skipRowForValue;
          requiredCols[hi] = header.required ? header.required : false;
        });
        table.forEach((row, ri) => {
          if (
            !isADeletedRentRollRow(deletedRentRollRows, { pi, ti, ri }) &&
            !isExcludedRow(row, exclRows, requiredCols)
          ) {
            if (headerData[pi][ti].rowIndex < ri) {
              row.forEach((col, ci) => {
                if (!exclCols.includes(ci)) {
                  if (col.predef && col.predef.id) {
                    completeCount++;
                  } else if (!col.predef) {
                    completeCount++;
                  }
                }
              });
            }
          }
        });
      }
    });
  });

  return totalCount !== 0 ? Math.floor((completeCount / totalCount) * 100) : 0;
};
