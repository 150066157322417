import { useEffect, useState } from "react";
import useDocument from "./useDocument";

export default function useDocumentMetadata() {
  const [documentMetadata, setDocumentMetadata] = useState(null);
  const { document } = useDocument();

  useEffect(() => {
    if (!document || documentMetadata) return;

    setDocumentMetadata(
      document.meta_data_json.headers.map(h => ({
        ...h,
        rowIds: h.rowIds || [],
        selectedColumns: new Set()
      }))
    );
  }, [document]);

  function resetDocumentMetadata() {
    setDocumentMetadata(
      document.meta_data_json.headers.map(h => ({
        ...h,
        rowIds: h.rowIds || [],
        selectedColumns: new Set()
      }))
    );
  }

  return {
    documentMetadata,
    resetDocumentMetadata,
    setDocumentMetadata
  };
}
