import useSWR from "swr";
import fetchData from "ui/components/rentRoll/splitPanelV2/helpers/fetchData";
import updateData from "ui/components/rentRoll/splitPanelV2/helpers/updateData";
import useOpexPanelContext from "./useOpexPanelContext";

const useDocumentWorkflows = () => {
  const { documentId, widgetAuth } = useOpexPanelContext();

  const { data, error, mutate } = useSWR(
    `/api/workflows/documents/${documentId}`,
    fetchData(widgetAuth)
  );

  const updateWorkflow = async fields => {
    await updateData({
      path: `/api/workflows/documents/${documentId}`,
      method: "PUT",
      data: { fields },
      widgetAuth
    });
  };

  return {
    workflows: data ? data : null,
    error,
    mutate,
    isLoading: !error && !data,
    updateWorkflow
  };
};

export default useDocumentWorkflows;
