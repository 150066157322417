import Popup from "reactjs-popup";
import PropTypes from "prop-types";
import Button from "ui/components/shared/Button";
import Checkbox from "ui/components/shared/Checkbox2";
import { useRef, useState, useEffect } from "react";
import OpexButton from "../OpexButton/OpexButton";
import SquareColorIcon from "../SquareColorIcon/SquareColorIcon";

export const FilterPopup = ({
  defaultValues,
  options,
  getOptionCount,
  onFilter,
  onClearFilter,
  disabled,
  triggerLabel,
  triggerIcon,
  title,
  minSelection,
  maxSelection,
}) => {
  // Hooks
  const [filterBy, setFilterBy] = useState(defaultValues || []);
  const popupRef = useRef();

  useEffect(() => {
    const closePopup = () => popupRef?.current?.close();
    const documentViewElement = document.getElementsByClassName(
      "OpexPanelView__DocumentView"
    )[0];
    documentViewElement?.addEventListener("scroll", closePopup);

    return () => documentViewElement?.removeEventListener("scroll", closePopup);
  }, []);

  // Handlers
  const handleFilterValues = filterValue => {
    if (maxSelection === filterBy.length && !filterBy.includes(filterValue)) return;

    setFilterBy(filterValues =>
      filterValues?.find(filter => filter === filterValue)
        ? filterValues.filter(filter => filter !== filterValue)
        : [...filterValues, filterValue]
    );
  };
  const clearFilter = () => {
    setFilterBy([]);
    onClearFilter();
  };

  const getOptionTemplate = (data, index) => {
    return <li key={index} role="button" onClick={() => handleFilterValues(data.value)}>
      <Checkbox
        readOnly
        disabled={
          maxSelection === filterBy.length && !filterBy.includes(data.value)
        }
        checked={filterBy.includes(data.value)}
      />
      {!!data.squareColor ? <SquareColorIcon color={data.squareColor} /> : <></>}
      <span style={{ marginLeft: !data.squareColor ? "0.3rem" : "" }}>
        {data.label} {!!getOptionCount ? `(${getOptionCount(data.value)})` : ""}
      </span>
    </li>
  }
  const onClose = () => {
    if (filterBy.length < minSelection && defaultValues) {
      setFilterBy(defaultValues);
    }
  }

  return (
    <Popup
      ref={popupRef}
      disabled={disabled}
      onClose={onClose}
      trigger={
        <div>
          <Button className={`FilterButton ${disabled ? "disabled" : ""}`}>
            <span>{triggerLabel || "Filter"}</span>
            <img
              src={triggerIcon || require("ui/images/filter.svg")}
              className="FilterButton__icon"
            />
          </Button>
        </div>
      }
      position="bottom left"
      contentStyle={{ width: "auto" }}
    >
      <div className="OpexPanelView__TableFilter">
        {
          title ?
            <div className="OpexPanelView__TableFilter--header">
              {title}
            </div>
            : null
        }
        <div className="OpexPanelView__TableFilter--options">
          <ul>{options.map((option, i) => getOptionTemplate(option, i))}</ul>
        </div>
        <div
          style={{ justifyContent: onClearFilter ? "space-between" : "flex-end" }}
          className="OpexPanelView__TableFilter--footer"
        >
          {
            onClearFilter &&
            <OpexButton onClick={clearFilter} variant="secondary">
              Clear filters
            </OpexButton>
          }
          <OpexButton
            variant="primary"
            disabled={
              (minSelection && filterBy.length < minSelection) ||
              (maxSelection && filterBy.length > maxSelection)
            }
            onClick={() => onFilter(filterBy)}
          >
            Apply
          </OpexButton>
        </div>
      </div>
    </Popup>
  );
};

FilterPopup.propTypes = {
  defaultValues: PropTypes.array,
  options: PropTypes.array,
  getOptionCount: PropTypes.func,
  onFilter: PropTypes.func,
  onClearFilter: PropTypes.func,
  disabled: PropTypes.bool,
  triggerLabel: PropTypes.string,
  triggerIcon: PropTypes.string,
  title: PropTypes.string,
  minSelection: PropTypes.number,
  maxSelection: PropTypes.number,
};
