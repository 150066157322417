import PropTypes from "prop-types";
import {
  getConfidenceColorByConfidenceCorrected,
  CONFIDENCE_COLOR_OPACITY
} from "ui/components/opex/shared";

const CoaPolygon = ({ polygonProps, data = {} }) => {
  const confidence = data?.predef?.confidence;
  const color = getConfidenceColorByConfidenceCorrected({ confidence });

  const style = {};
  if (color) {
    style.fill = color;

    // `fill` will make the entire polygon a color and block out
    // the text behind it, so we need to give it an opacity value in order
    // to see through the polygon element
    style.opacity = CONFIDENCE_COLOR_OPACITY;
  }

  return <polygon style={style} {...polygonProps} />;
}

export default CoaPolygon;

CoaPolygon.propTypes = {
  polygonProps: PropTypes.object,
  data: PropTypes.object // cell data from document
};
