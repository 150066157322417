import Popup from "reactjs-popup";
import Button from "ui/components/shared/Button";
import { FaTimes } from "react-icons/fa";
import { DELETE } from "ui/components/admin/templates/shared/constants";
import PropTypes from "prop-types";

const DeleteConfirmPopup = ({
  deleteTemplate,
  templateId,
  description,
  trigger
}) => {
  return (
    <Popup modal trigger={trigger} position="right center" closeOnDocumentClick>
      {close => (
        <div className="DeleteConfirm">
          <div className="DeleteConfirm--description">
            <p>{description}</p>
            <Button
              onClick={() => close()}
              className="Button Button__whiteBackground"
            >
              <FaTimes />
            </Button>
          </div>

          <div className="DeleteConfirm--options">
            <Button
              onClick={() => close()}
              className={"Button Button__whiteBackground"}
            >
              Cancel
            </Button>
            <Button
              onClick={() => deleteTemplate(templateId, DELETE) && close()}
              className={"Button Button__blueBackground"}
            >
              Confirm
            </Button>
          </div>
        </div>
      )}
    </Popup>
  );
};

DeleteConfirmPopup.propTypes = {
  deleteTemplate: PropTypes.func,
  templateId: PropTypes.number,
  description: PropTypes.string,
  trigger: PropTypes.node
};

export default DeleteConfirmPopup;
