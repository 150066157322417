import PropTypes from "prop-types";

const SortImageDefault = () => {
  return (
    <span
      style={{
        marginLeft: "5px",
        display: "inline-block",
        verticalAlign: "bottom"
      }}
    >
      <img
        src={require("ui/images/icon-triangle-arrow-up.svg")}
        style={{
          display: "block",
          opacity: "0.5"
        }}
      />
      <img
        src={require("ui/images/icon-triangle-arrow-down.svg")}
        style={{
          display: "block",
          opacity: "0.5"
        }}
      />
    </span>
  );
};

const SortImageDefaultGray = () => {
  return (
    <span
      style={{
        marginLeft: "5px",
        display: "inline-block",
        verticalAlign: "bottom"
      }}
    >
      <img
        src={require("ui/images/icon-triangle-arrow-up-gray.svg")}
        style={{
          display: "block",
          opacity: "0.5"
        }}
      />
      <img
        src={require("ui/images/icon-triangle-arrow-down-gray.svg")}
        style={{
          display: "block",
          opacity: "0.5"
        }}
      />
    </span>
  );
};

const SortImageAsc = () => {
  return (
    <span style={{ marginLeft: "5px" }}>
      <img src={require("ui/images/icon-triangle-arrow-up-l.svg")} />
    </span>
  );
};

const SortImageAscGray = () => {
  return (
    <span style={{ marginLeft: "5px" }}>
      <img src={require("ui/images/icon-triangle-arrow-up-l-gray.svg")} />
    </span>
  );
};

const SortImageDesc = () => {
  return (
    <span style={{ marginLeft: "5px" }}>
      <img src={require("ui/images/icon-triangle-arrow-down-l.svg")} />
    </span>
  );
};

const SortImageDescGray = () => {
  return (
    <span style={{ marginLeft: "5px" }}>
      <img src={require("ui/images/icon-triangle-arrow-down-l-gray.svg")} />
    </span>
  );
};
export default function SearchSortImage({
  sortOrder,
  selectedColumnName,
  columnName,
  columnDetails,
  isGray = false
}) {
  if (selectedColumnName === columnName) {
    if (sortOrder && sortOrder.length > 0) {
      if (sortOrder === "asc") {
        if (!isGray) return <SortImageAsc />;
        else return <SortImageAscGray />;
      } else {
        if (!isGray) return <SortImageDesc />;
        else return <SortImageDescGray />;
      }
    }
  } else if (
    selectedColumnName !== columnName &&
    (!columnDetails.sector || columnName !== columnDetails.sector.columnName)
  ) {
    if (!isGray) return <SortImageDefault />;
    else return <SortImageDefaultGray />;
  }
  return null;
}

SearchSortImage.propTypes = {
  sortOrder: PropTypes.string,
  selectedColumnName: PropTypes.string,
  columnName: PropTypes.string,
  columnDetails: PropTypes.object,
  isGray: PropTypes.bool
};

SearchSortImage.defaultProps = {};
