import useSWR from "swr";
import fetchData from "../helpers/fetchData";
import useSplitPanelV2Context from "./useSplitPanelV2Context";

export default function useRentRollFieldsByPropertyType() {
  const { widgetAuth } = useSplitPanelV2Context();

  const { data, error, mutate } = useSWR(
    "/api/rent-roll/fields",
    fetchData(widgetAuth)
  );

  return {
    rentRollFieldsByPropertyType: data ? data.fieldsByPropertyType : null,
    error,
    mutate,
    isLoading: !error && !data
  };
}
