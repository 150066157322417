import PropTypes from "prop-types";

const SquareColorIcon = ({ color, style }) => (
  <div className="SquareColorIcon" style={{ backgroundColor: color, ...style }} />
);

SquareColorIcon.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object
};

export default SquareColorIcon;
