import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { clearFilterList, setSearchInput } from "ui/store/actions/filters";

import FilterBreadCrumb from "./FilterBreadCrumb";
import BreadCrumbDropdown from "./BreadCrumbDropdown";

const FilterBreadCrumbs = ({
  activeFilterList,
  clearFilterList,
  updateCanSearch,
  setSearchInput
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [visibleList, setVisibleList] = useState(0);
  const [breadCrumbContainerWidth, setBreadCrumbContainerWidth] = useState(0);
  const [breadCrumbDropdown, setBreadCrumbDropdown] = useState([]);
  const [breadCrumbList, setBreadCrumbList] = useState([]);
  const filterBreadCrumbContainer = useRef(null);
  const activeFilterListContainer = useRef(null);
  // const eventObj = {
  //   handleEvent() {
  //     if (filterBreadCrumbContainer.current) {
  //       clearTimeout(resize);
  //       let resize = setTimeout(() => {
  //         setBreadCrumbContainerWidth(
  //           filterBreadCrumbContainer.current.offsetWidth
  //         );
  //       }, 1000);
  //     }
  //   }
  // };
  // if (filterBreadCrumbContainer.current) {
  //   window.addEventListener("resize", eventObj);
  // }
  useEffect(() => {
    if (
      filterBreadCrumbContainer.current &&
      activeFilterListContainer.current
    ) {
      setBreadCrumbContainerWidth(
        filterBreadCrumbContainer.current.offsetWidth
      );
      const divSpaceOccupied =
        activeFilterListContainer.current.offsetWidth /
        breadCrumbContainerWidth;
      if (divSpaceOccupied > 0.75) {
        setBreadCrumbList([...activeFilterList.slice(0, visibleList + 1)]);
        setBreadCrumbDropdown([...activeFilterList.slice(visibleList + 1)]);
        setShowDropdown(true);
      } else {
        setBreadCrumbList(activeFilterList);
        setShowDropdown(false);
        setVisibleList(breadCrumbList.length);
      }
    }
  }, [activeFilterList, breadCrumbContainerWidth]);
  return (
    <div className="FilterBreadCrumbs" ref={filterBreadCrumbContainer}>
      <div
        ref={activeFilterListContainer}
        style={{ height: "100%", width: "auto", display: "flex" }}
      >
        {breadCrumbList.map(filter => {
          return (
            <FilterBreadCrumb
              key={`${filter.filterType}-${filter.filterValue}`}
              filterType={filter.filterType}
              filterValue={filter.filterValue}
              updateCanSearch={updateCanSearch}
            />
          );
        })}
      </div>
      {showDropdown && breadCrumbDropdown.length > 0 && (
        <BreadCrumbDropdown list={breadCrumbDropdown} />
      )}
      {activeFilterList && activeFilterList.length > 0 && (
        <div
          className="FilterBreadCrumbs__clearFilters"
          onClick={() => {
            updateCanSearch(true);
            setSearchInput(null);
            clearFilterList();
          }}
        >
          Clear all filters
        </div>
      )}
    </div>
  );
};

FilterBreadCrumbs.propTypes = {
  activeFilterList: PropTypes.array,
  clearFilterList: PropTypes.func,
  updateCanSearch: PropTypes.func,
  setSearchInput: PropTypes.func
};

const mapStateToProps = ({ filters }) => ({
  activeFilterList: filters.activeFilterList
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ clearFilterList, setSearchInput }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FilterBreadCrumbs);
