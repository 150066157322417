export const canCellBeMarkedAsMapped = (
  isMappedInRentRoll,
  metadataHeader,
  columnIndex,
  rowIndex
) => {
  // this function is used to track changes in the UI before a user hits Save & Continue
  // the user's changes are being tracked in documentMetadata
  let isMapped = isMappedInRentRoll;
  // if its marked as false in the rent roll, but user updates the mapping,
  // we want to update the cells below the header in the UI to reflect that change.
  if (
    isMappedInRentRoll === false &&
    metadataHeader.headers &&
    metadataHeader.headers[columnIndex] &&
    metadataHeader.headers[columnIndex].isMapped &&
    metadataHeader.headers[columnIndex].skipRowForValue &&
    metadataHeader.headers[columnIndex].skipRowForValue.length < 1
  ) {
    if (rowIndex > metadataHeader.rowIndex) isMapped = true;
  }
  // the opposite case for if a user unmaps a column, we want to mark the cells
  // below the header as unmapped.
  else if (
    isMappedInRentRoll &&
    metadataHeader.headers &&
    metadataHeader.headers[columnIndex] &&
    metadataHeader.headers[columnIndex].isMapped === false
  ) {
    if (rowIndex > metadataHeader.rowIndex) isMapped = false;
  }
  return isMapped;
};
