import PropTypes from "prop-types";
import cx from "classnames";

export default function Color({ children, className, colordeterminator }) {
  const css = cx("Color", `Color--${colordeterminator}`, className);
  return <div className={css}>{children}</div>;
}

Color.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  colordeterminator: PropTypes.string.isRequired
};
