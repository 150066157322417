export default function doesCellHaveRentRoll({
  rentRolls = [],
  location: { columnIndex, pageIndex, rowIndex, tableIndex }
}) {
  return rentRolls.some(
    ({ doc_row_ids, doc_data_json }) =>
      doc_row_ids &&
      doc_row_ids.includes(rowIndex) &&
      doc_data_json &&
      doc_data_json.row &&
      doc_data_json.row.some(
        row =>
          row &&
          row.isMapped &&
          row.docPosition.col === columnIndex &&
          row.docPosition.row === rowIndex &&
          row.docPosition.page === pageIndex &&
          row.docPosition.table === tableIndex
      )
  );
}
