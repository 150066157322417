import { useEffect, useState } from "react";
import PropTypes from "prop-types";

export default function NewDataActionsCell({
  column: { handleSubmitNewRow, handleCancelNewRow},
  rentRollKey
}) {
  const [refrentRollKey, setRefKey] = useState("0-0");
  useEffect(() => {
    if (rentRollKey) {
      setRefKey(rentRollKey);
    }
  }, [rentRollKey]);


  return (
    <div
      tabIndex={refrentRollKey.split("-")[1]}
      aria-label="actions for new row"
    >
      <img
        className={"DataCell__newRowButtons-cancel"}
        onClick={handleCancelNewRow}
        onKeyDown={ e => e.key === "Enter" ? handleCancelNewRow() : ""}
        src={require("ui/images/icon-x.png")}
        tabIndex={parseInt(refrentRollKey.split("-")[1])+1}
        aria-label="delete new row"
      />
      <img
        className={"DataCell__newRowButtons-submit"}
        onClick={handleSubmitNewRow}
        onKeyDown={ e => e.key === "Enter" ? handleSubmitNewRow() : ""}
        src={require("ui/images/icon-check.svg")}
        tabIndex={parseInt(refrentRollKey.split("-")[1])+2}
        aria-label="submit new row"
      />
    </div>
  );
}

NewDataActionsCell.propTypes = {
  column: PropTypes.shape({
    handleSubmitNewRow: PropTypes.func,
    handleCancelNewRow: PropTypes.func
  }),
  rentRollKey: PropTypes.string
};
