// custom style for drop down
export const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px solid #EAEAEA",
    color: state.isSelected ? "#2196f3" : "#3B3E42",
    fontWeight: state.isSelected ? "bold" : "normal",
    padding: 20,
    backgroundColor: null
  }),
  control: () => ({
    width: 150,
    display: "flex"
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, fontWeight: "bold", opacity, transition };
  }
};
