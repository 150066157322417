import { apiFetch } from "./apiClient";

export const USAGE_REPORT_SUCCESSFUL = "USAGE_REPORT_SUCCESSFUL";

export function getUsageReport(date1, date2) {
  return async function(dispatch) {
    const response = await apiFetch(
      `/api/usage_analytics?date1=${date1}&date2=${date2}`
    );
    const responseBody = await response.json();
    if (!response.ok) {
      throw new Error(responseBody.error.message);
    }
    dispatch(usageReport(responseBody.usageReport));
  };
}
function usageReport(report) {
  return {
    report,
    type: USAGE_REPORT_SUCCESSFUL
  };
}
