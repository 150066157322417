import PropTypes from "prop-types";
import { useEffect, useRef } from "react";
import useOnScreen from "../../../../hooks/useOnScreen";

const OpexImage = ({ url, showLoadingSpinner, onResize, onImageLoad }) => {
  const ref = useRef(null);
  const isRefVisible = useOnScreen(ref); // `false` if the sidepanel is closed

  // When an image is loaded from cache in the browser, the `onLoad` event does not fire.
  // So use `.complete` to know if the image is loaded in this way.
  // eg.  visit a <OpexPage> (image loads from url)
  //      click browser back
  //      click browser forward (image loads from cache)
  useEffect(() => {
    if (ref.current.complete) {
      handleOnLoad();
    }
  }, []);

  const handleResize = () => {
    if (isRefVisible) {
      onResize(ref.current?.clientHeight, ref.current?.clientWidth);
    }
  };

  const handleOnLoad = () => {
    handleResize();
    if (onImageLoad) onImageLoad();
  };

  useEffect(handleResize, [
    // only perform resize if the image is visible on the DOM
    isRefVisible,

    // as this toggles it causes the image to shift up/and down, and changes the image
    // css `display` property.
    showLoadingSpinner
  ]);

  useEffect(() => {
    // when the browser resizes we need to update image height and width so the
    // table polygons can redraw
    window.addEventListener("resize", handleResize, false);
    return () => window.removeEventListener("resize", handleResize, false);
  }, [handleResize]);

  let imageStyle = {};
  if (showLoadingSpinner) {
    imageStyle.display = "none";
  }

  return (
    <>
      {showLoadingSpinner && <div className="loading">Loading...</div>}
      <img src={url} ref={ref} onLoad={handleOnLoad} style={imageStyle} />
    </>
  );
};

export default OpexImage;
