import PropTypes from "prop-types";
import { useState } from "react";
import OpexPage from "../OpexPage/OpexPage";
import OpexDocument from "../../modelOpexDocument";
import OpexPageNumber from "../OpexPageNumber/OpexPageNumber";
import OpexModal from "../OpexModal/OpexModal";

const TableSelectionExcel = ({ document, onChange }) => {
  const documentModel = new OpexDocument(document);
  const [selectedSheet, setSelectedSheet] = useState(null);

  const handleOnSheetConfirm = () => {
    documentModel.setAllTablesQualified(false);
    documentModel.setTableQualifiedByHeaderIndex(selectedSheet, true);
    const nextMetaDataJson = documentModel.getMetaDataJson();
    onChange(nextMetaDataJson);
  };

  const renderPage = () => {
    const pageIndex = documentModel.getQualifiedSheetHeaderIndex();
    return (
      <div>
        <OpexPageNumber pageIndex={pageIndex} />
        <OpexPage
          views={["tables"]}
          document={document}
          pageIndex={pageIndex}
          onTableClick={() => {}}
        />
      </div>
    );
  };

  const handleOnChangeRadio = e => {
    const value = e.target.value;
    setSelectedSheet(value);
  };

  const renderElement = () => {
    // Excel Document. Render the modal in case it has multiple sheets.
    if (documentModel.hasOneExcelSheetQualified()) {
      return (
        <div className="OpexPanelView__TableSelection TableSelectionExcel">
          {renderPage()}
        </div>
      );
    } else {
      return (
        <OpexModal
          headerText="Select Sheet"
          open={true}
          confirmText="Select"
          onConfirm={handleOnSheetConfirm}
          hideCancel={true}
          closeOnDocumentClick={false}
          body={
            <>
              <form className="TableSelectionExcelModal">
                {documentModel.getExcelSheetNames().map((name, index) => (
                  <div key={index}>
                    <label>
                      <input
                        type="radio"
                        checked={index === parseInt(selectedSheet)}
                        value={index}
                        onChange={handleOnChangeRadio}
                      />
                      {name}
                    </label>
                  </div>
                ))}
              </form>
            </>
          }
        />
      );
    }
  };

  return <>{renderElement()}</>;
};

export default TableSelectionExcel;

TableSelectionExcel.propTypes = {
  document: PropTypes.object,
  onChange: PropTypes.func
};
