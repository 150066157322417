import { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { multiFamilyPropertyType } from "ui/components/properties/propertiesUtil";
import {
  fetchRentRollAnalyticsByDocId,
  clearAnalytics
} from "ui/store/actions/rentRoll";

import {
  compactCurrencyFormatter,
  squareFootageFormatter,
  percentFormatter
} from "../../helpers/metrics";
const _ = require("lodash");

const analyticsFieldLabels = {
  gross_monthly_rent: "Monthly Income",
  gross_annual_rent: "Annual Income",
  total_sqft: "Square Footage (SF)",
  vacancy_rate_unit: "Vacancy Rate (units)",
  vacancy_rate_sqft: "Vacancy Rate (SF)",
  total_units: "Units",
  total_tenants: "Tenants"
};

class RentRollAnalytics extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { currDocId, getRentRollAnalyticsByDocId } = this.props;
    getRentRollAnalyticsByDocId(currDocId);
  }

  componentDidUpdate(prevProps) {
    const { getRentRollAnalyticsByDocId, currDocId } = this.props;
    if (prevProps.currDocId !== currDocId) {
      getRentRollAnalyticsByDocId(currDocId);
    }
  }

  componentWillUnmount() {
    const { resetAnalytics } = this.props;
    resetAnalytics();
  }

  formatAnalyticsData = (analytics, property) => {
    const analyticsData = [];
    // Income Data
    const incomeField =
      analytics && analytics.current && analytics.current.gross_monthly_rent
        ? "gross_monthly_rent"
        : "gross_annual_rent";
    const incomeData = {
      field: incomeField,
      title: analyticsFieldLabels[incomeField],
      current: analytics.current
        ? compactCurrencyFormatter.format(analytics.current[incomeField])
        : "-",
      previous: analytics.previous
        ? compactCurrencyFormatter.format(analytics.previous[incomeField])
        : "-",
      variation: analytics.previous
        ? Math.sign(
            analytics.current[incomeField] - analytics.previous[incomeField]
          )
        : 0
    };

    analyticsData.push(incomeData);

    // Square Footage
    const sqftField = "total_sqft";
    const sqftData = {
      field: sqftField,
      title: analyticsFieldLabels[sqftField],
      current:
        analytics.current && analytics.current[sqftField]
          ? squareFootageFormatter.format(analytics.current[sqftField])
          : "-",
      previous:
        analytics.previous && analytics.previous[sqftField]
          ? squareFootageFormatter.format(analytics.previous[sqftField])
          : "-"
      //      variation: analytics.previous
      //        ? Math.sign(
      //          analytics.current[sqftField] - analytics.previous[sqftField]
      //        )
      //        : 0
    };
    analyticsData.push(sqftData);

    // Vacancy Rate
    const vacancyField =
      property.property_type === multiFamilyPropertyType.id
        ? "vacancy_rate_unit"
        : "vacancy_rate_sqft";
    const vacancyData = {
      field: vacancyField,
      title: analyticsFieldLabels[vacancyField],
      current:
        analytics.current && analytics.current[vacancyField]
          ? percentFormatter.format(analytics.current[vacancyField])
          : "-",
      previous:
        analytics.previous && analytics.previous[vacancyField]
          ? percentFormatter.format(analytics.previous[vacancyField])
          : "-"
      //      variation: analytics.previous
      //        ? Math.sign(
      //          analytics.current[vacancyField] - analytics.previous[vacancyField]
      //        )
      //        : 0
    };
    analyticsData.push(vacancyData);

    // Tenants / units
    const tenantsUnitsData = {
      field: "tenantsUnits",
      title: [
        analyticsFieldLabels.total_tenants,
        analyticsFieldLabels.total_units
      ],
      current: analytics.current
        ? [analytics.current.total_tenants, analytics.current.total_units]
        : ["-", "-"],
      previous: analytics.previous
        ? [analytics.previous.total_tenants, analytics.previous.total_units]
        : ["-", "-"]
    };
    analyticsData.push(tenantsUnitsData);

    return analyticsData;
  };

  renderCell(analyticsData) {
    return analyticsData.map(data => {
      let variationClassName = "";
      if (data.variation > 0) {
        variationClassName = "RentRollAnalytics__cell-data-label-up";
      } else if (data.variation < 0) {
        variationClassName = "RentRollAnalytics__cell-data-label-down";
      }
      return (
        <div key={data.field} className="RentRollAnalytics__cell">
          <div className="RentRollAnalytics__cell-title">
            <span>
              {Array.isArray(data.title) ? data.title.join(" / ") : data.title}
            </span>
          </div>
          <div className="RentRollAnalytics__cell-data-container">
            <div className="RentRollAnalytics__cell-data">
              <span className="RentRollAnalytics__cell-data-value-current">
                {Array.isArray(data.current)
                  ? data.current.reduce((acc, val, i) => [
                      acc,
                      <span
                        key={i}
                        className="RentRollAnalytics__cell-data-value-divider"
                      >
                        /
                      </span>,
                      val
                    ])
                  : data.current}
              </span>
              <span
                className={
                  "RentRollAnalytics__cell-data-label " + variationClassName
                }
              >
                Current
              </span>
            </div>
            <div className="RentRollAnalytics__cell-data-divider" />
            <div className="RentRollAnalytics__cell-data">
              <span className="RentRollAnalytics__cell-data-value-previous">
                {Array.isArray(data.previous)
                  ? data.previous.reduce((acc, val, i) => [
                      acc,
                      <span
                        key={i}
                        className="RentRollAnalytics__cell-data-value-divider"
                      >
                        /
                      </span>,
                      val
                    ])
                  : data.previous}
              </span>
              <span className="RentRollAnalytics__cell-data-label">
                Previous
              </span>
            </div>
          </div>
        </div>
      );
    });
  }

  render() {
    const { analytics, property } = this.props;

    const analyticsData =
      analytics && this.formatAnalyticsData(analytics, property);

    return (
      <div className="RentRollAnalytics__container">
        {analyticsData && this.renderCell(analyticsData)}
      </div>
    );
  }
}

RentRollAnalytics.propTypes = {
  getRentRollAnalyticsByDocId: PropTypes.func,
  resetAnalytics: PropTypes.func,
  property: PropTypes.object,
  analytics: PropTypes.object,
  document: PropTypes.object,
  currDocId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

function mapStateToProps(state) {
  return {
    analytics: state.rentRoll.analytics,
    currDocId: state.propertyRentRollViewUI.docId
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getRentRollAnalyticsByDocId: documentId => {
      dispatch(fetchRentRollAnalyticsByDocId(documentId));
    },
    resetAnalytics: () => dispatch(clearAnalytics())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RentRollAnalytics);
