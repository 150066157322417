export default function tableOuter(table) {
  const firstRow = table[0];
  const lastRow = table[table.length - 1];
  const tl = firstRow[0];
  const tr = firstRow[firstRow.length - 1];
  const bl = lastRow[0];
  const br = lastRow[lastRow.length - 1];
  return {
    x1: `${tl.geometry.boundingBox.Left * 100}`,
    y1: `${tl.geometry.boundingBox.Top * 100}`,
    x2: `${(tr.geometry.boundingBox.Left + tr.geometry.boundingBox.Width) *
      100}`,
    y2: `${tr.geometry.boundingBox.Top * 100}`,
    x3: `${(br.geometry.boundingBox.Left + br.geometry.boundingBox.Width) *
      100}`,
    y3: `${(br.geometry.boundingBox.Top + br.geometry.boundingBox.Height) *
      100}`,
    x4: `${bl.geometry.boundingBox.Left * 100}`,
    y4: `${(bl.geometry.boundingBox.Top + bl.geometry.boundingBox.Height) *
      100}`
  };
}
