import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { updateExceptionSidebar } from "ui/store/actions/rentRoll";

import { exceptionSidebarConfig } from "../helpers/exceptions";

const SidebarHeader = ({ updateExceptionSidebar }) => (
  <div className="ExceptionSidebar__header">
    <img
      className="ExceptionSidebar__header__closeButton"
      src={require("../../../../images/arrow-right-blue.png")}
      onClick={() => updateExceptionSidebar(false)}
    />
    <div className="ExceptionSidebar__header__label">
      {exceptionSidebarConfig.label}
    </div>
  </div>
);

SidebarHeader.propTypes = {
  updateExceptionSidebar: PropTypes.func
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateExceptionSidebar }, dispatch);

export default connect(null, mapDispatchToProps)(SidebarHeader);
