import PropTypes from "prop-types";
import OpexCellValueText from "./OpexCellValueText";
const PeriodMetricsCell = ({ cellData, selectedPerdiodMetrics }) => {
  const { metrics, disabled } = cellData;
  const buildMetricsTemplates = () => selectedPerdiodMetrics.map((metric, index, selectedMetrics) =>
    <div
      key={`${index}-${metric.value}`}
      className={`metric ${index === selectedMetrics.length - 1 ? "lastMetric" : ""}`}
    >
      <div className="metricName">{metric.label}</div>
      <div className="metricValue">
        <OpexCellValueText value={metrics[metric.value]} type="amount" />
      </div>
    </div>
  )

  return (
    <div
      className={`OpexBaseTable--PeriodMetricsCell ${disabled ? "cellDisabled" : ""
        }`}
    >
      {buildMetricsTemplates()}
    </div>
  );
}

export default PeriodMetricsCell;

PeriodMetricsCell.propTypes = {
  cellData: PropTypes.shape({
    metrics: PropTypes.object,
    disabled: PropTypes.bool
  }),
  selectedPerdiodMetrics: PropTypes.array
};
