import { apiFetch } from "./apiClient";
import { stringify } from "query-string";

import {
  setFiltersFromElasticSearchResult,
  updateElasticSearchMetrics
} from "ui/store/actions/filters";
import {
  assignPropertyToDocument,
  fetchRentRollByPropId
} from "ui/store/actions/rentRoll";
import { formatRequestSearchObject } from "ui/helpers/search";

export const FETCHING_PROPERTIES = "FETCHING_PROPERTIES";
export const FETCHING_PROPERTIES_SUCCESSFUL = "FETCHING_PROPERTIES_SUCCESSFUL";
export const FETCHING_PROPERTY = "FETCHING_PROPERTY";
export const FETCH_PROPERTY_SUCCESSFUL = "FETCH_PROPERTY_SUCCESSFUL";
export const CREATING_PROPERTY = "CREATING_PROPERTY";
export const CREATING_PROPERTY_SUCCESSFUL = "CREATING_PROPERTY_SUCCESSFUL";
export const HANDLE_TEXT_CHANGE = "HANDLE_TEXT_CHANGE";
export const FETCHING_PROPERTY_FROM_REIS_NETWORK =
  "FETCHING_PROPERTY_FROM_REIS_NETWORK";
export const FETCHING_PROPERTY_FROM_REIS_NETWORK_SUCCESSFUL =
  "FETCHING_PROPERTY_FROM_REIS_NETWORK_SUCCESSFUL";
export const AUTOCOMPLETE_SUCCESSFUL = "AUTOCOMPLETE_SUCCESSFUL";
export const RESET_SEARCH = "RESET_SEARCH";
export const HANDLE_TEXT_CHANGE_SUGGESTIONS = "HANDLE_TEXT_CHANGE_SUGGESTIONS";
export const SUGGESTIONS_SUCCESSFUL = "SUGGESTIONS_SUCCESSFUL";
export const HANDLE_SUGGESTIONS_TEXT_CHANGE = "HANDLE_SUGGESTIONS_TEXT_CHANGE";
export const RESET_SUGGESTIONS = "RESET_SUGGESTIONS";
export const CLEAR_SELECTED_PORTFOLIO = "CLEAR_SELECTED_PORTFOLIO";
export const CLEAR_METRICS = "CLEAR_METRICS";
export const CLEAR_PROPERTIES = "CLEAR_PROPERTIES";
export const FETCH_PROPERTIES_BY_COMPANY_SUCCESFULLY =
  "properties/FETCH_PROPERTIES_BY_COMPANY_SUCCESFULLY";
export const PROPERTIES_SUCCESS_FETCH_TO_SELECT =
  "properties/PROPERTIES_SUCCESS_FETCH_TO_SELECT";
export const CLEAR_PROPERTIES_SELECT = "properties/CLEAR_PROPERTIES_SELECT";
export const AUTOCOMPLETE_PROPERTY_SEARCH_SUCCESSFUL =
  "AUTOCOMPLETE_PROPERTY_SEARCH_SUCCESSFUL";
export const AUTOCOMPLETE_GOOGLE_PLACES_SUCCESSFUL =
  "AUTOCOMPLETE_GOOGLE_PLACES_SUCCESSFUL";
export const FIND_GOOGLE_PLACES_SUCCESSFUL = "FIND_GOOGLE_PLACES_SUCCESSFUL";
export const FIND_GOOGLE_PLACES_BY_ID_SUCCESSFUL =
  "FIND_GOOGLE_PLACES_BY_ID_SUCCESSFUL";

// currently searchObject is city, propertyType, tenant;
export function searchProperties(
  portfolioId,
  searchObject = {},
  setFilters = false,
  paginationAndSort = {}
) {
  return async function(dispatch) {
    dispatch(fetchingProperties(portfolioId));
    try {
      const response = await apiFetch(
        `/api/rent-roll/search/properties?${stringify({
          portfolioId,
          searchObject: formatRequestSearchObject({
            ...searchObject,
            paginationAndSort: paginationAndSort
          })
        })}`,
        {
          method: "get"
        }
      );

      if (!response.ok) {
        const responseBody = await response.json();
        throw new Error(responseBody.error);
      }
      const responseBody = await response.json();
      if (setFilters) {
        dispatch(setFiltersFromElasticSearchResult(responseBody.metrics));
      }
      dispatch(updateElasticSearchMetrics(responseBody.metrics));
      dispatch(
        fetchingPropertiesSuccessful(
          responseBody.properties,
          responseBody.selectedPortfolio,
          responseBody.metrics
        )
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export function searchSuggestions(portfolioId, query) {
  return async function(dispatch) {
    const searchObject = JSON.stringify({
      searchInput: query,
      showVacant: true
    });
    try {
      const response = await apiFetch(
        `/api/rent-roll/search/suggestions?${stringify({
          portfolioId,
          searchObject: searchObject
        })}`,
        {
          method: "get"
        }
      );

      if (!response.ok) {
        const responseBody = await response.json();
        throw new Error(responseBody.error);
      }
      const responseBody = await response.json();
      const suggestionsO = responseBody.suggestions.suggestions_o.buckets.map(
        element => element.key
      );
      const suggestionsL = responseBody.suggestions.suggestions_l.buckets.map(
        element => element.key
      );
      const suggestionsU = responseBody.suggestions.suggestions_u.buckets.map(
        element => element.key
      );
      const suggestionsT = responseBody.suggestions.suggestions_t.buckets.map(
        element => element.key
      );
      const suggestions = responseBody.suggestions.suggestions_n.buckets.map(
        element => element.key
      );
      const indexOf = (arr, q) =>
        arr.findIndex(item => q.toLowerCase() === item.toLowerCase());
      suggestions.forEach(function(part, index, theArray) {
        const indexO = indexOf(suggestionsO, part);
        const indexL = indexOf(suggestionsL, part);
        const indexU = indexOf(suggestionsU, part);
        const indexT = indexOf(suggestionsT, part);
        if (indexO > -1) {
          theArray[index] = suggestionsO[indexO];
        } else if (indexL > -1) {
          theArray[index] = suggestionsL[indexL];
        } else if (indexU > -1) {
          theArray[index] = suggestionsU[indexU];
        } else if (indexT > -1) {
          theArray[index] = suggestionsT[indexT];
        }
      });
      dispatch(suggestionsSuccessful(suggestions));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export function deleteProperties(portfolioId, propertyIds) {
  return async function(dispatch, getState) {
    dispatch(fetchingProperties(portfolioId));
    try {
      const response = await apiFetch(`/api/properties/${portfolioId}`, {
        method: "delete",
        body: JSON.stringify({ propertyIds })
      });
      const responseBody = await response.json();
      if (!response.ok) {
        throw new Error(responseBody.error.message);
      }
      const searchObject = getState().filters.filters;
      dispatch(searchProperties(portfolioId, searchObject));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export function fetchProperty(propertyId, widgetAuth = {}) {
  return async function(dispatch) {
    try {
      const response = await apiFetch(
        `/api/properties/${propertyId}`,
        {
          method: "get"
        },
        widgetAuth
      );

      if (!response.ok) {
        const responseBody = await response.json();
        throw new Error(responseBody.error);
      }
      const responseBody = await response.json();
      dispatch(fetchPropertySuccessful(responseBody.property));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export async function fetchPropertyInfo(propertyId, widgetAuth = {}) {
  try {
    const response = await apiFetch(
      `/api/properties/${propertyId}`,
      {
        method: "get"
      },
      widgetAuth
    );

    if (!response.ok) {
      const responseBody = await response.json();
      throw new Error(responseBody.error);
    }
    const responseBody = await response.json();
    return responseBody.property;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}

export function fetchPropertiesByCompany(updateSelectOnly, widgetAuth = {}) {
  return async function(dispatch) {
    try {
      const response = await apiFetch(
        `/api/properties`,
        {
          method: "get"
        },
        widgetAuth
      );

      if (!response.ok) {
        const responseBody = await response.json();
        throw new Error(responseBody.error);
      }
      const responseBody = await response.json();
      if (updateSelectOnly) {
        const sorted = responseBody.properties.sort((a, b) =>
          a.street_1.localeCompare(b.street_1)
        );
        dispatch(propertiesSuccessFetchToSelect(sorted));
      } else {
        dispatch(fetchPropertiesByCompanySuccessful(responseBody.properties));
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export function propertiesSuccessFetchToSelect(propertiesSelect) {
  return { propertiesSelect, type: PROPERTIES_SUCCESS_FETCH_TO_SELECT };
}

export function clearPropertiesSelect() {
  return { type: CLEAR_PROPERTIES_SELECT };
}

export function addProperty(
  property,
  portfolioId,
  attachToDocument,
  documentId,
  refreshPropertiesList,
  widgetAuth = {}
) {
  return async function(dispatch, getState) {
    const searchObj = getState().filters.filters;
    dispatch(creatingProperty());
    try {
      const response = await apiFetch(
        `/api/properties/add-property/${portfolioId}`,
        {
          method: "post",
          body: JSON.stringify({ property })
        },
        widgetAuth
      );

      if (!response.ok) {
        const responseBody = await response.json();
        throw new Error(responseBody.error);
      }
      const responseBody = await response.json();
      await dispatch(creatingPropertySuccessful(responseBody.property));
      if (attachToDocument) {
        await dispatch(
          assignPropertyToDocument(
            documentId,
            responseBody.property[0].id,
            widgetAuth
          )
        );
      }
      if (refreshPropertiesList) {
        await dispatch(
          searchProperties(
            portfolioId,
            searchObj,
            false,
            {
              pagination: { property: 0 }
            },
            widgetAuth
          )
        );
      }
      return responseBody;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export function addPropertyToCPM(data, widgetAuth = {}) {
  return async function() {
    try {
      const response = await apiFetch(
        `/api/properties/add-property-to-cpm`,
        {
          method: "post",
          body: JSON.stringify(data)
        },
        widgetAuth
      );
      if (!response.ok) {
        const responseBody = await response.json();
        throw new Error(responseBody.error);
      }
      const responseBody = await response.json();
      return responseBody;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export function updateProperty(selectedProperty, property) {
  return async function(dispatch) {
    const updatedProperty = { ...selectedProperty, ...property };
    dispatch(creatingProperty());
    const propertyId = selectedProperty.id;

    try {
      const response = await apiFetch(`/api/properties/${propertyId}`, {
        method: "put",
        body: JSON.stringify({ ...updatedProperty })
      });

      if (!response.ok) {
        const responseBody = await response.json();
        throw new Error(responseBody.error);
      }
      dispatch(fetchRentRollByPropId(property.id));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export function fetchPropertyFromReisNetwork(location, propertyType) {
  return async function(dispatch) {
    dispatch(fetchingPropertyFromReisNetwork(location));
    try {
      const response = await apiFetch(`/api/reisnetwork?${location}`, {
        method: "get"
      });

      if (!response.ok) {
        const responseBody = await response.json();
        throw new Error(responseBody.error);
      }
      const responseBody = await response.json();
      const reisProperty = responseBody.reisProperty;
      if (propertyType) {
        reisProperty.property_type = propertyType;
      }
      dispatch(fetchingPropertyFromReisNetworkSuccessful(reisProperty));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export function mapPropertyToReisNetwork(property) {
  return async function(dispatch) {
    try {
      const reisProperty = {
        id: property.id,
        city: property.city,
        state: property.state,
        zip_code: property.postal_code,
        reis_id: property.reis_id,
        name: property.name,
        floors: property.floors,
        latitude: property.latitude,
        longitude: property.longitude,
        size_sqft: property.size_sqft,
        year_built: property.year_built,
        parcel_number: property.parcel_number,
        size_lot_sqft: property.size_lot_sqft,
        postal_code: property.postal_code,
        property_type: property.property_type,
        street_1: property.street_1
      };
      dispatch(fetchingPropertyFromReisNetworkSuccessful(reisProperty));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export function autocompletePropertySearch(query, widgetAuth = {}) {
  return async function(dispatch) {
    try {
      const response = await apiFetch(
        `/api/properties/search/autocomplete?q=${query}`,
        {
          method: "get"
        },
        widgetAuth
      );
      const responseBody = await response.json();
      if (!response.ok) {
        throw new Error(responseBody.error);
      }
      dispatch(autocompletePropertySearchSuccessful(responseBody));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export function autocompletePropertySearchSuccessful(suggestedProperties) {
  return {
    suggestedProperties,
    type: AUTOCOMPLETE_PROPERTY_SEARCH_SUCCESSFUL
  };
}

export function autocompleteGooglePlaces(query, widgetAuth = {}) {
  return async function(dispatch) {
    try {
      const response = await apiFetch(
        `/api/google-places/autocomplete?input=${query}`,
        {
          method: "get"
        },
        widgetAuth
      );
      const responseBody = await response.json();
      if (!response.ok) {
        throw new Error(responseBody.error);
      }
      dispatch(autocompleteGooglePlacesSuccessful(responseBody));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export function autocompleteGooglePlacesSuccessful(addresses) {
  return {
    addresses,
    type: AUTOCOMPLETE_GOOGLE_PLACES_SUCCESSFUL
  };
}

export function findGooglePlaces(query, widgetAuth = {}) {
  return async function(dispatch) {
    try {
      const response = await apiFetch(
        `/api/google-places/find-place?input=${query}`,
        {
          method: "get"
        },
        widgetAuth
      );
      const responseBody = await response.json();
      if (!response.ok) {
        throw new Error(responseBody.error);
      }
      dispatch(findGooglePlacesSuccessful(responseBody));
      return responseBody;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export function findGooglePlacesSuccessful(addresses) {
  return {
    addresses,
    type: FIND_GOOGLE_PLACES_SUCCESSFUL
  };
}

export function findGooglePlacesById(query, widgetAuth = {}) {
  return async function(dispatch) {
    try {
      const response = await apiFetch(
        `/api/google-places/find-place-by-id?input=${query}`,
        {
          method: "get"
        },
        widgetAuth
      );
      const responseBody = await response.json();
      if (!response.ok) {
        throw new Error(responseBody.error);
      }
      dispatch(findGooglePlacesByIdSuccessful(responseBody));
      return responseBody;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export function findGooglePlacesByIdSuccessful(addresses) {
  return {
    addresses,
    type: FIND_GOOGLE_PLACES_BY_ID_SUCCESSFUL
  };
}

export function autocomplete(query) {
  return async function(dispatch) {
    try {
      const response = await apiFetch(`/api/autocomplete?q=${query}`, {
        method: "get"
      });
      const responseBody = await response.json();
      if (!response.ok) {
        throw new Error(responseBody.error);
      }
      dispatch(autocompleteSuccessful(responseBody));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export function handleTextChange(text) {
  return { type: HANDLE_TEXT_CHANGE, text };
}

export function autocompleteSuccessful(recommendedQueries) {
  return {
    recommendedQueries,
    type: AUTOCOMPLETE_SUCCESSFUL
  };
}

export function handleSuggestionsTextChange(text) {
  return { type: HANDLE_SUGGESTIONS_TEXT_CHANGE, text };
}

export function suggestionsSuccessful(recommendedSuggestions) {
  return {
    recommendedSuggestions,
    type: SUGGESTIONS_SUCCESSFUL
  };
}

export function resetSuggestions() {
  return {
    type: RESET_SUGGESTIONS
  };
}

export function resetSearch() {
  return {
    type: RESET_SEARCH
  };
}

export function fetchingProperties(portfolioId) {
  return { portfolioId, type: FETCHING_PROPERTIES };
}

export function fetchingPropertiesSuccessful(
  properties,
  selectedPortfolio,
  metrics
) {
  return {
    properties,
    selectedPortfolio,
    metrics,
    type: FETCHING_PROPERTIES_SUCCESSFUL
  };
}

export function creatingProperty() {
  return { type: CREATING_PROPERTY };
}

export function creatingPropertySuccessful(property) {
  return { property, type: CREATING_PROPERTY_SUCCESSFUL };
}

export function fetchingPropertyFromReisNetwork(location) {
  return { location, type: FETCHING_PROPERTY_FROM_REIS_NETWORK };
}

export function fetchingPropertyFromReisNetworkSuccessful(property) {
  return { property, type: FETCHING_PROPERTY_FROM_REIS_NETWORK_SUCCESSFUL };
}

export function fetchPropertySuccessful(property) {
  return { property, type: FETCH_PROPERTY_SUCCESSFUL };
}

export function clearSelectedPortfolioObj() {
  return { type: CLEAR_SELECTED_PORTFOLIO };
}

export function clearMetricsObj() {
  return { type: CLEAR_METRICS };
}

export function clearPropertiesObj() {
  return { type: CLEAR_PROPERTIES };
}

export function fetchPropertiesByCompanySuccessful(properties) {
  return { properties, type: FETCH_PROPERTIES_BY_COMPANY_SUCCESFULLY };
}
