import _ from "lodash";
import { useState, useEffect } from "react";
import Header from "ui/components/rentRoll/splitPanelV2/components/Header/Header";
import DocumentView from "./DocumentView/DocumentView";
import BottomBar from "./BottomBar/BottomBar";
import useDocument from "../hooks/useDocument";
import useDocumentWorkflows from "../hooks/useDocumentWorkflows";
import useOpex from "../hooks/useOpex";
import {
  WORKFLOW_TABLE_SELECTION,
  WORKFLOW_CHART_OF_ACCOUNTS_SELECTION,
  WORKFLOW_ACCOUNT_MAPPING,
  WORKFLOW_COLUMN_PERIODIZATION,
  OPERATING_STATEMENT
} from "ui/components/opex/helpers/opex";
import {
  isCreditLensByGroupName,
  PERIOD_STATUS_SET
} from "ui/components/opex/shared";
import useOpexPanelContext from "../hooks/useOpexPanelContext";
import useOpexTemplates from "../hooks/useOpexTemplates";
import { TemplateStatus } from "helpers/opex";
import OpexData from "./DocumentView/modelOpexData";

const OpexPanelView = () => {
  const {
    document,
    mutate: mutateDocument,
    update: updateDocument,
    updateAccounts,
    isLoading,
    markOpexAsComplete,
    mapRowsToAccounts,
    remapRowsToAccounts
  } = useDocument();
  const { opex, mutate: mutateOpex, extractOpexData, groupName } = useOpex();
  const {
    workflows,
    mutate: mutateWorkflow,
    updateWorkflow
  } = useDocumentWorkflows();
  const modelOpexData = new OpexData({ document, opex });

  const { isWidgetAuthorized, widgetAuth } = useOpexPanelContext();
  const [currentWorkflowStatus, setCurrentWorkflowStatus] = useState(null);
  const [currentTable, setCurrentTable] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [rawDataJson, setRawDataJson] = useState(null);
  const [metaDataJson, setMetaDataJson] = useState(null);
  const getTemplateDetail = useOpexTemplates(document?.property_type)
    ?.getTemplateDetail;
  const template = getTemplateDetail(
    document?.template_id,
    document?.property_type
  )?.data;

  const [isMapping, setIsMapping] = useState(false);

  useEffect(() => {
    const isCL = isCreditLensByGroupName(groupName);
    if (
      !isCL &&
      document &&
      !modelOpexData.isPublished() &&
      template?.status === TemplateStatus.ARCHIVED &&
      currentWorkflowStatus === WORKFLOW_COLUMN_PERIODIZATION
    ) {
      goToStep(WORKFLOW_ACCOUNT_MAPPING);
    }

    if (
      document &&
      modelOpexData.isPublished() &&
      template?.status === TemplateStatus.ARCHIVED &&
      currentWorkflowStatus !== WORKFLOW_COLUMN_PERIODIZATION
    ) {
      goToStep(WORKFLOW_COLUMN_PERIODIZATION);
    }
  }, [document, template, currentWorkflowStatus, modelOpexData]);

  useEffect(() => {
    if (workflows) {
      const currentWorkflow = workflows.filter(
        workflow => workflow.document_type === OPERATING_STATEMENT
      );

      const initialWorkflow = currentWorkflow.length
        ? currentWorkflow[0].workflow_status
        : WORKFLOW_TABLE_SELECTION;

      setCurrentWorkflowStatus(initialWorkflow);
    } else {
      setCurrentWorkflowStatus(WORKFLOW_TABLE_SELECTION);
    }
  }, [workflows]);

  const goToStep = async workflowStatus => {
    await updateWorkflow({
      document_id: document.id,
      workflow_status: workflowStatus,
      document_type: OPERATING_STATEMENT
    });
    await refreshData();
  };

  const refreshData = async () => {
    const data = await mutateDocument();
    await mutateWorkflow();
    return data;
  };

  const updateWorkflowStatus = async workflowStatus => {
    await updateWorkflow({
      document_id: document.id,
      workflow_status: workflowStatus,
      document_type: OPERATING_STATEMENT
    });
    await refreshData();
  };

  const saveAccountMapping = async () => {
    await updateWorkflowStatus(WORKFLOW_COLUMN_PERIODIZATION);
    await extractOpexData();
    await mutateOpex();
  };

  const saveCoaSelection = async () => {
    if (document.template_id && isCreditLensByGroupName(groupName)) {
      setIsMapping(true);
      await mapRowsToAccounts(document.template_id);
    }
    await updateWorkflowStatus(WORKFLOW_ACCOUNT_MAPPING);
    setIsMapping(false);
  };

  const saveColumnPeriodization = async () => {
    const setPeriods = _.filter(opex.periods, {
      periodStatus: PERIOD_STATUS_SET
    });
    const lastPeriod = _.last(setPeriods);
    const lastEffectiveDate = lastPeriod?.endDate;

    if (!lastEffectiveDate) {
      throw "Last `periodStatus: SET` period has no end date.";
    }

    await updateDocument({ effective_date: lastEffectiveDate });
    await markOpexAsComplete(document.id, document.property_id);
    await refreshData();
  };

  const saveAndContinue = () => {
    switch (currentWorkflowStatus) {
      case WORKFLOW_TABLE_SELECTION:
        return updateWorkflowStatus(WORKFLOW_CHART_OF_ACCOUNTS_SELECTION);
      case WORKFLOW_CHART_OF_ACCOUNTS_SELECTION:
        return saveCoaSelection();
      case WORKFLOW_ACCOUNT_MAPPING:
        return saveAccountMapping();
      case WORKFLOW_COLUMN_PERIODIZATION:
        return saveColumnPeriodization();
    }
  };

  const handleChangeTableSelection = async metaDataJson => {
    setMetaDataJson(metaDataJson);
    await updateDocument({ meta_data_json: metaDataJson });
    if (document.template_id) {
      await mapRowsToAccounts(document.template_id);
    }
    await refreshData();
  };

  const handleChangeChartOfAccount = async metaDataJson => {
    await updateDocument({ meta_data_json: metaDataJson });
    if (document.template_id) {
      await mapRowsToAccounts(document.template_id);
    }
    const response = await refreshData();
    setMetaDataJson(response.document.meta_data_json);
  };

  const goToPreviousStep = async () => {
    switch (currentWorkflowStatus) {
      case WORKFLOW_CHART_OF_ACCOUNTS_SELECTION:
        await updateWorkflow({
          workflow_status: WORKFLOW_TABLE_SELECTION,
          document_type: OPERATING_STATEMENT
        });
        await refreshData();
        break;
      case WORKFLOW_ACCOUNT_MAPPING:
        await updateWorkflow({
          workflow_status: WORKFLOW_CHART_OF_ACCOUNTS_SELECTION,
          document_type: OPERATING_STATEMENT
        });
        await refreshData();
        break;
      case WORKFLOW_COLUMN_PERIODIZATION:
        await updateWorkflow({
          workflow_status: WORKFLOW_ACCOUNT_MAPPING,
          document_type: OPERATING_STATEMENT
        });
        await refreshData();
        break;
    }
  };

  const handleUpdateRawDataRows = async rows => {
    await updateAccounts(rows);
    const { document } = await refreshData();
    setRawDataJson(document.raw_data_json);
  };

  const handleUpdateMetadataJson = async metaData => {
    setMetaDataJson(metaData);
    await updateDocument({ meta_data_json: metaData });
    await refreshData();
  };

  const handleUpdateDocumentTemplate = async id => {
    await remapRowsToAccounts(id);
    const { document } = await refreshData();
    setMetaDataJson(document.meta_data_json);
    setRawDataJson(document.raw_data_json);
  };

  const handleSelectAllTables = async () => {
    if (modelOpexData.hasAllTablesQualified()) {
      modelOpexData.setAllTablesQualified(false);
    } else {
      modelOpexData.setAllTablesQualified(true);
    }
    return handleChangeTableSelection(modelOpexData.getMetaDataJson());
  };

  const layout4Rows = [
    WORKFLOW_TABLE_SELECTION,
    WORKFLOW_CHART_OF_ACCOUNTS_SELECTION
  ];
  const layout3Rows = [
    null,
    WORKFLOW_ACCOUNT_MAPPING,
    WORKFLOW_COLUMN_PERIODIZATION
  ];
  const isWidget = isWidgetAuthorized;
  const shouldShowHeader = !isWidget;

  let containerClassName;

  if (_.includes(layout4Rows, currentWorkflowStatus)) {
    containerClassName = "OpexPanelView--4-rows";
  } else if (_.includes(layout3Rows, currentWorkflowStatus)) {
    containerClassName = "OpexPanelView--3-rows";
  }

  if (isWidget) {
    containerClassName += " OpexPanelView--widget";
  }

  return (
    <div className={containerClassName}>
      {shouldShowHeader && <Header />}
      <DocumentView
        groupName={groupName}
        currentWorkflowStatus={currentWorkflowStatus}
        currentTable={currentTable}
        setCurrentTable={setCurrentTable}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        document={document}
        opex={opex}
        isLoading={isLoading}
        refreshData={refreshData}
        rawDataJson={rawDataJson}
        setRawDataJson={setRawDataJson}
        onUpdateRawDataRows={handleUpdateRawDataRows}
        metaDataJson={metaDataJson}
        setMetaDataJson={setMetaDataJson}
        onUpdateMetaDataJson={handleUpdateMetadataJson}
        onChangeTableSelection={handleChangeTableSelection}
        onChangeChartOfAccount={handleChangeChartOfAccount}
        onUpdateDocumentTemplate={handleUpdateDocumentTemplate}
        onToggleSelectAllTables={handleSelectAllTables}
        isMapping={isMapping}
        widgetAuth={widgetAuth}
      />
      <BottomBar
        template={template}
        currentWorkflowStatus={currentWorkflowStatus}
        saveAndContinue={saveAndContinue}
        goToPreviousStep={goToPreviousStep}
      />
    </div>
  );
};

export default OpexPanelView;
