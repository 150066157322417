import { useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import Popup from "reactjs-popup";
import { PERIOD_DATE_FORMAT } from "ui/components/helpers/dayjs";
import { DatePicker } from "antd";
import dayjs from "dayjs";

const RawRowEditDate = ({ dataIndex, date, updateRowForm }) => {
  const [isOpen, updateIsOpen] = useState(false);
  const [isFocused, updateIsFocused] = useState(false);
  const [tmpDate, updateTmpDate] = useState(undefined);
  return (
    <Popup
      trigger={
        <div>{date ? dayjs(date).format(PERIOD_DATE_FORMAT) : "date"}</div>
      }
      modal
      open={isOpen}
      onOpen={() => {
        updateIsOpen(true);
        updateIsFocused(false);
      }}
      onClose={() => updateIsOpen(false)}
      className="EditModal"
      position={"center center"}
      nested
    >
      <div className="EditModal__header">Add Date</div>
      <div className={cx("EditModal__body", "RawRowEditDate__body")}>
        <DatePicker
          onChange={date => {
            updateTmpDate(date);
          }}
          defaultValue={tmpDate}
          format={PERIOD_DATE_FORMAT}
          id={`new-row-${dataIndex}`}
        />
      </div>
      <div className="EditModal__verify">
        {tmpDate && (
          <button
            className={cx("Button", "Button__blueButton")}
            style={{ marginRight: "15px" }}
            onClick={() => {
              updateRowForm(dataIndex, tmpDate);
              updateIsOpen(false);
            }}
            disabled={!tmpDate}
          >
            Ok
          </button>
        )}
        <button
          className={cx("Button", "Button__blackButton")}
          onClick={() => {
            updateIsOpen(false);
          }}
        >
          Cancel
        </button>
      </div>
    </Popup>
  );
};

RawRowEditDate.propTypes = {
  dataIndex: PropTypes.string,
  date: PropTypes.string,
  updateRowForm: PropTypes.func
};

export default RawRowEditDate;
