import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import OpexPage from "../../../OpexPage/OpexPage";
import OpexPageControls from "../../../OpexPageControls/OpexPageControls";
import OpexDocument from "../../../../modelOpexDocument";
import { DEFAULT_ZOOM, scrollCellIntoView } from "ui/components/opex/shared";
import CoaPolygon from "../../../CoaPolygon/CoaPolygon";

const AccountsRightPanel = ({
  document,
  currentTable,
  currentPage,
  activeRowLocation
}) => {
  // css selector to the <OpexPage> cell that is currently selected
  const CLASS_OF_SIDEBAR_IMAGE_ROW = ".OpexPanelView__OpexPage .selected";

  const documentModel = new OpexDocument(document);
  const [zoom, setZoom] = useState(DEFAULT_ZOOM);
  const [isPageLoading, setIsPageLoading] = useState(true);

  function setCurrentPageByCurrentTable() {
    setIsPageLoading(true);
  }

  function scrollIntoView() {
    if (activeRowLocation) {
      scrollCellIntoView(CLASS_OF_SIDEBAR_IMAGE_ROW, { inline: "start" });
    }
  }

  function handleOnImageLoad() {
    setIsPageLoading(false);
    scrollIntoView();
  }

  function shouldUseCellPolygonRenderer({ ci, data }) {
    // useSWR will trigger a `document` fetch when selecting a different table from the
    // bottom nav. This will cause <OpexPage> `pageIndex` value, and the `pageIndex` value
    // in this function to be out of sync, causing this function to run on the incorrect
    // chart of accounts column. Regardless, we shouldn't run this function if there is
    // no predef value on the cell data.
    if (!data.predef) {
      return false;
    }

    // do not use the cell renderer the row if it is excluded as we don't want excluded
    // rows to appear colored
    const { documentRowIndex } = data;
    if (
      documentModel.isRowExcludedByPageIndexTableIndexRowIndex(
        currentPage,
        currentTable,
        documentRowIndex
      )
    ) {
      return false;
    }

    // we only want the chart of accounts column to use the custom renderer for step2
    const coaIndex = documentModel.getChartOfAccountsColumnIndexByPageIndexTableIndex(
      currentPage,
      currentTable
    );
    if (ci === coaIndex) {
      return true;
    }

    return false;
  }

  useEffect(() => {
    setCurrentPageByCurrentTable();
  }, [currentTable]);

  useEffect(() => {
    scrollIntoView();
  }, [activeRowLocation]);

  const style = {
    zoom
  };

  return (
    <div className="OpexPanelView__DocumentView__Pane OpexPanelView__Accounts">
      <div className="pane-header">
        <OpexPageControls
          controls={["zoomin", "zoomout"]}
          initialZoom={zoom}
          currentPage={currentPage}
          totalPages={documentModel.countPages()}
          onChangePage={() => {}}
          onChangeZoom={setZoom}
        />
      </div>
      <div className="pane-content" style={style}>
        <OpexPage
          views={["rows", "cells"]}
          document={document}
          pageIndex={currentPage}
          useFixedWidthHeightFromImage={true}
          selectedRows={[activeRowLocation]}
          showLoadingSpinner={isPageLoading}
          onImageLoad={handleOnImageLoad}
          cellPolygonRenderer={CoaPolygon}
          shouldUseCellPolygonRenderer={shouldUseCellPolygonRenderer}
        />
      </div>
    </div>
  );
};

export default AccountsRightPanel;

AccountsRightPanel.propTypes = {
  document: PropTypes.object,
  tables: PropTypes.array,
  currentTable: PropTypes.number,
  currentPage: PropTypes.number,
  activeRowLocation: PropTypes.number
};
