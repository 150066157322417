import { useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import FieldSelect from "./FieldSelect";
import Button from "ui/components/shared/Button";

const NewRule = ({ headerData, dispatch, setNewRuleDisplay, isAdminUser }) => {
  const initialField = headerData.headers.map(header => header.id)[0];
  const [newField, setNewField] = useState(initialField);
  const [customField, setCustomField] = useState();
  const [newFunc, setNewFunc] = useState("");

  return (
    <div
      className={cx("EditModal__body", "HeaderEditPopover__body")}
      style={{ backgroundColor: "#fcfcfc", border: "1px dashed #EAEAEA" }}
    >
      {/* <div
        className="HeaderEditPopover__subtitle"
        style={{ marginBottom: "15px" }}
      >
        New Rule
      </div> */}
      <div
        className="AdvancedRulesPopover__advancedRule"
        style={{ paddingBottom: "0" }}
      >
        <div
          className="AdvancedRulesPopover__mapHeader"
          style={{ marginBottom: "5px" }}
        >
          <div style={{ display: "flex" }}>
            <div className="AdvancedRulesPopover__subtitle">Field:</div>
            {isAdminUser ? (
              <FieldSelect
                value={initialField}
                headerData={headerData}
                dispatch={dispatch}
                setNewField={setNewField}
                setCustomField={setCustomField}
              />
            ) : (
              <h3 className="AdvancedRulesPopover__subtitle">{initialField}</h3>
            )}
          </div>
        </div>
        {isAdminUser ? (
          <textarea
            value={newFunc}
            onChange={e => {
              setNewFunc(e.target.value);
            }}
            rows="4"
            cols="53"
            height="auto"
          />
        ) : (
          <p>None</p>
        )}
        {isAdminUser && (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                marginTop: "5px"
              }}
            >
              {newFunc !== "" && (
                <Button
                  className={cx(
                    "Button",
                    "Button__blueButton",
                    "NewRules__smButton"
                  )}
                  onClick={() => {
                    const field =
                      newField === "custom_field" ? customField : newField;
                    dispatch({ type: "create_rule", field, rule: newFunc });
                    setNewField("");
                    setCustomField("");
                    setNewFunc("");
                    setNewRuleDisplay(false);
                  }}
                >
                  Add
                </Button>
              )}
              <Button
                className={cx(
                  "Button",
                  "Button__blackButton",
                  "NewRules__smButton"
                )}
                onClick={() => {
                  setNewField("");
                  setCustomField("");
                  setNewFunc("");
                  setNewRuleDisplay(false);
                }}
              >
                Cancel
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

NewRule.propTypes = {
  headerData: PropTypes.object,
  state: PropTypes.object,
  dispatch: PropTypes.func,
  setNewRuleDisplay: PropTypes.func,
  isAdminUser: PropTypes.bool
};

export default NewRule;
