import { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Popup from "reactjs-popup";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import cx from "classnames";

import {
  predefSugestionCleared,
  updateCache,
  clearCache
} from "../../../../../store/actions/rentRoll";

// import { returnFieldNames } from "../../helpers/dictionaries";

const HeaderEditPopover = ({
  cellData,
  fieldNamesMappingForDropDown,
  headerData,
  location,
  cache,
  updateCache,
  clearCache,
  isExcel,
  updateHeader,
  selectedProperty,
  multiLineHeader
}) => {
  const { pi, ti, ri, ci } = location;
  const ref = useRef();
  const closePopover = () => {
    ref.current.close();
  };
  const cancelAndClosePopover = () => {
    clearCache();
    setUpdateErrorMessage(null);
    closePopover();
  };
  const [columnRequired, updateColumnRequired] = useState();
  const [updateErrorMessage, setUpdateErrorMessage] = useState();

  useEffect(() => {
    const isHeaderRequried =
      typeof cache["headerRequired-" + pi + "-" + ti + "-" + ri + "-" + ci] !==
      "undefined"
        ? !!cache["headerRequired-" + pi + "-" + ti + "-" + ri + "-" + ci]
        : !!(
            headerData[pi][ti].headers[ci] &&
            headerData[pi][ti].headers[ci].required
          );

    updateColumnRequired(isHeaderRequried);
  }, [headerData, cache]);

  const NONE = "none";
  const checkDuplicate = (headers, value) => {
    if (value !== NONE && headers && headers.length > 0) {
      for (let j = 0; j < headers.length; j++) {
        if (headers[j].isMapped) {
          if (headers[j].id === value) {
            return (
              "This field has already been mapped to column no. " +
              (j + 1) +
              " in the document."
            );
          }
        }
      }
    }
    return null;
  };

  return (
    <Popup
      ref={ref}
      trigger={
        <div
          style={{
            fontWeight: 700,
            width: "100%",
            height: "100%",
            lineHeight: "16px",
            verticalAlign: "middle",
            backgroundColor: "rgba(0, 0, 224, 0.0)",
            cursor: "pointer"
          }}
        >
          {isExcel && cellData.text
            ? cellData.text
            : isExcel
            ? `(${headerData[pi][ti].headers[ci].id})`
            : ""}
        </div>
      }
      position={["center center"]}
      modal
      onClose={cancelAndClosePopover}
      className="EditModal"
    >
      <div className={cx("EditModal__header", "HeaderEditPopover__header")}>
        Field Mapping
      </div>
      <div className={cx("EditModal__body", "HeaderEditPopover__body")}>
        <div className="HeaderEditPopover__mapHeader">
          <div className="HeaderEditPopover__subtitle">
            {multiLineHeader ? multiLineHeader : cellData.text}
            {isExcel && !cellData.text
              ? `(${headerData[pi][ti].headers[ci].id})`
              : ""}
          </div>
          <select
            name={"headerSelect-" + pi + "-" + ti + "-" + ri + "-" + ci}
            value={
              cache["headerSelect-" + pi + "-" + ti + "-" + ri + "-" + ci]
                ? cache["headerSelect-" + pi + "-" + ti + "-" + ri + "-" + ci]
                : headerData[pi][ti].headers[ci] &&
                  headerData[pi][ti].headers[ci].isMapped
                ? headerData[pi][ti].headers[ci].id
                : NONE
            }
            onChange={event => {
              setUpdateErrorMessage(null);
              updateCache(
                "headerSelect-" + pi + "-" + ti + "-" + ri + "-" + ci,
                event.target.value
              );
            }}
          >
            <option value={NONE}>Not Mapped</option>
            {Object.entries(
              fieldNamesMappingForDropDown[
                selectedProperty.property_type.toLowerCase()
              ]
            )
              .sort((a, b) => a[1].localeCompare(b[1]))
              .map(entry => {
                return (
                  <option key={entry[0]} value={entry[0]}>
                    {entry[1]}
                  </option>
                );
              })}
          </select>
        </div>
        {updateErrorMessage && (
          <div className="HeaderEditPopover__errorMessage">
            {updateErrorMessage}
          </div>
        )}
        <div className="HeaderEditPopover__requiredContainer">
          <div className="HeaderEditPopover__subtitle">Skip blanks?</div>
          <div className="HeaderEditPopover__requiredRadioContainer">
            <div className="HeaderEditPopover__requiredInputContainer">
              <input
                type="radio"
                checked={columnRequired}
                readOnly
                onClick={() => {
                  updateCache(
                    "headerRequired-" + pi + "-" + ti + "-" + ri + "-" + ci,
                    true
                  );
                  updateColumnRequired(true);
                }}
              />
              <div className="HeaderEditPopover__requiredRadioLabel">Yes</div>
            </div>
            <div className="HeaderEditPopover__requiredInputContainer">
              <input
                type="radio"
                checked={!columnRequired}
                readOnly
                onClick={() => {
                  updateCache(
                    "headerRequired-" + pi + "-" + ti + "-" + ri + "-" + ci,
                    false
                  );
                  updateColumnRequired(false);
                }}
              />
              <div className="HeaderEditPopover__requiredRadioLabel">No</div>
            </div>
          </div>
        </div>
        <div
          className="HeaderEditPopover__subtitle"
          style={{ margin: "10px 0" }}
        >
          Exclude rows with these values:
        </div>
        {headerData[pi][ti].headers[ci] &&
          headerData[pi][ti].headers[ci].skipRowForValue &&
          headerData[pi][ti].headers[ci].skipRowForValue.map((val, i) => {
            return (
              <div
                key={"skipRow-" + pi + "-" + ti + "-" + ri + "-" + ci + "-" + i}
                style={{ marginBottom: "10px" }}
              >
                <input
                  type="text"
                  name={
                    "skipRow-" + pi + "-" + ti + "-" + ri + "-" + ci + "-" + i
                  }
                  defaultValue={
                    typeof cache[
                      "skipRow-" + pi + "-" + ti + "-" + ri + "-" + ci + "-" + i
                    ] !== "undefined"
                      ? cache[
                          "skipRow-" +
                            pi +
                            "-" +
                            ti +
                            "-" +
                            ri +
                            "-" +
                            ci +
                            "-" +
                            i
                        ]
                      : val
                  }
                  onChange={event => {
                    updateCache(
                      "skipRow-" +
                        pi +
                        "-" +
                        ti +
                        "-" +
                        ri +
                        "-" +
                        ci +
                        "-" +
                        i,
                      event.target.value
                    );
                  }}
                />
              </div>
            );
          })}
        <div
          style={{
            textAlign: "left"
          }}
        >
          <input
            type="text"
            name={
              "skipRow-" +
              pi +
              "-" +
              ti +
              "-" +
              ri +
              "-" +
              ci +
              "-" +
              (headerData[pi][ti].headers[ci] &&
              headerData[pi][ti].headers[ci].skipRowForValue
                ? headerData[pi][ti].headers[ci].skipRowForValue.length
                : 0)
            }
            defaultValue={
              typeof cache[
                "skipRow-" +
                  pi +
                  "-" +
                  ti +
                  "-" +
                  ri +
                  "-" +
                  ci +
                  "-" +
                  (headerData[pi][ti].headers[ci] &&
                  headerData[pi][ti].headers[ci].skipRowForValue
                    ? headerData[pi][ti].headers[ci].skipRowForValue.length
                    : 0)
              ] !== "undefined"
                ? cache[
                    "skipRow-" +
                      pi +
                      "-" +
                      ti +
                      "-" +
                      ri +
                      "-" +
                      ci +
                      "-" +
                      (headerData[pi][ti].headers[ci] &&
                      headerData[pi][ti].headers[ci].skipRowForValue
                        ? headerData[pi][ti].headers[ci].skipRowForValue.length
                        : 0)
                  ]
                : ""
            }
            //onChange={this.inputChanged}
            onChange={event => {
              updateCache(
                "skipRow-" +
                  pi +
                  "-" +
                  ti +
                  "-" +
                  ri +
                  "-" +
                  ci +
                  "-" +
                  (headerData[pi][ti].headers[ci] &&
                  headerData[pi][ti].headers[ci].skipRowForValue
                    ? headerData[pi][ti].headers[ci].skipRowForValue.length
                    : 0),
                event.target.value
              );
            }}
          />
        </div>
      </div>
      <div className="EditModal__verify">
        <div
          className={cx("Button", "Button__blackButton")}
          onClick={() => {
            cancelAndClosePopover();
          }}
        >
          Cancel
        </div>
        <div
          className={cx("Button", "Button__blueButton")}
          style={{ marginLeft: "5px" }}
          onClick={async () => {
            const duplicateMessage = checkDuplicate(
              headerData[pi][ti].headers,
              cache["headerSelect-" + pi + "-" + ti + "-" + ri + "-" + ci]
            );
            if (duplicateMessage === null) {
              await updateHeader(
                pi,
                ti,
                ri,
                cache["rowHeader-" + pi + "-" + ti]
                  ? cache["rowHeader-" + pi + "-" + ti].row
                  : headerData[pi][ti].rowIds
                  ? headerData[pi][ti].rowIds
                  : [headerData[pi][ti].rowIndex]
              );
              closePopover();
            } else {
              setUpdateErrorMessage(duplicateMessage);
            }
          }}
        >
          Update
        </div>
      </div>
    </Popup>
  );
};

HeaderEditPopover.propTypes = {
  cellData: PropTypes.object,
  fieldNamesMappingForDropDown: PropTypes.object,
  headerData: PropTypes.array,
  location: PropTypes.object,
  cache: PropTypes.object,
  updateCache: PropTypes.func,
  clearCache: PropTypes.func,
  updateHeader: PropTypes.func,
  isExcel: PropTypes.bool,
  selectedProperty: PropTypes.object,
  multiLineHeader: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

const mapStateToProps = ({ rentRoll, properties }) => ({
  selectedProperty: properties.selectedProperty,
  fieldNamesMappingForDropDown: rentRoll.fieldNamesMappingForDropDown
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { predefSugestionCleared, updateCache, clearCache },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(HeaderEditPopover);
