import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import {
  addProperty,
  addPropertyToCPM,
  fetchPropertyFromReisNetwork,
  resetSearch,
  updateProperty
} from "ui/store/actions/properties";
import {
  fetchCompanyDocuments,
  updateDocument
} from "ui/store/actions/rentRoll";
import { documentType as DocumentType } from "ui/components/helpers/documents";

import EditDataPopupBody from "./EditDataPopupBody";
import AddPropertyForm from "ui/components/properties/AddPropertyForm";
import { bindActionCreators } from "redux";

class EditDataBody extends Component {
  state = {
    page: "main",
    documentType: DocumentType.RENT_ROLL
  };

  changePage = page => {
    if (page) {
      this.setState({ page });
    }
  };

  getDocumentType = documentType => {
    this.setState({ documentType });
  };

  render() {
    const {
      closePopover,
      documentId,
      fetchPropertyFromReisNetwork,
      addProperty,
      addPropertyToCPM,
      reisProperty,
      company,
      isLoadingReisProperty,
      resetSearch,
      fetchCompanyDocuments,
      properties,
      widgetAuth,
      sortOrder,
      sortName,
      filterObject,
      setSortByLastUpdated,
      fileExtension,
      updateDocument,
      updateProperty
    } = this.props;
    const { page, documentType } = this.state;

    switch (page) {
      case "main":
        return (
          <EditDataPopupBody
            documentId={documentId}
            changePage={this.changePage.bind(this)}
            closePopover={closePopover}
            fetchCompanyDocuments={fetchCompanyDocuments}
            widgetAuth={widgetAuth}
            sortOrder={sortOrder}
            sortName={sortName}
            filterObject={filterObject}
            setSortByLastUpdated={setSortByLastUpdated}
            fileExtension={fileExtension}
            getDocumentType={this.getDocumentType.bind(this)}
          />
        );
      case "new-property":
        return (
          <div style={{ backgroundColor: "white", borderRadius: "4px" }}>
            <AddPropertyForm
              childToEditProperty={true}
              changePage={this.changePage.bind(this)}
              onNext={location => {
                fetchPropertyFromReisNetwork(location);
              }}
              onSubmitAttachDoc={async property => {
                const apResponse = await addProperty(
                  property,
                  property.portfolio,
                  true,
                  documentId,
                  false,
                  widgetAuth
                );
                const { external_id, group_name } = company;
                if (external_id && group_name) {
                  const apResponseId = apResponse.property[0].id;
                  let uuid = null;
                  const cpmResponse = await addPropertyToCPM(
                    {
                      property,
                      companyId: company.id
                    },
                    widgetAuth
                  );
                  uuid = cpmResponse?.data?.id;
                  if (uuid) {
                    await updateProperty(
                      { id: apResponseId },
                      { external_id: uuid }
                    );
                  }
                }
                if (documentType !== DocumentType.RENT_ROLL) {
                  await updateDocument(
                    documentId,
                    {
                      document_type: documentType
                    },
                    widgetAuth
                  );
                }
                await fetchCompanyDocuments(
                  0,
                  100,
                  `${sortName}%7C${sortOrder}`,
                  widgetAuth,
                  filterObject
                );
                setSortByLastUpdated();
                closePopover();
              }}
              propertyData={reisProperty}
              isLoadingReisProperty={isLoadingReisProperty}
              resetSearch={resetSearch}
              closePopover={closePopover}
              properties={properties}
              widgetAuth={widgetAuth}
              documentType={documentType}
            />
          </div>
        );
      default:
        return (
          <EditDataPopupBody
            documentId={documentId}
            changePage={this.changePage.bind(this)}
            closePopover={closePopover}
            widgetAuth={widgetAuth}
            sortOrder={sortOrder}
            sortName={sortName}
            setSortByLastUpdated={setSortByLastUpdated}
            filterObject={filterObject}
            fileExtension={fileExtension}
          />
        );
    }
  }
}

EditDataBody.propTypes = {
  closePopover: PropTypes.func,
  documentId: PropTypes.number,
  addProperty: PropTypes.func,
  addPropertyToCPM: PropTypes.func,
  fetchPropertyFromReisNetwork: PropTypes.func,
  reisProperty: PropTypes.object,
  isLoadingReisProperty: PropTypes.func,
  resetSearch: PropTypes.func,
  fetchCompanyDocuments: PropTypes.func,
  properties: PropTypes.array,
  widgetAuth: PropTypes.object,
  company: PropTypes.object,
  sortOrder: PropTypes.string,
  sortName: PropTypes.string,
  filterObject: PropTypes.object,
  setSortByLastUpdated: PropTypes.func,
  fileExtension: PropTypes.string,
  updateDocument: PropTypes.func,
  updateProperty: PropTypes.func
};

const mapStateToProps = ({ properties, currentUser }) => ({
  properties: properties.propertiesSelect,
  reisProperty: properties.reisProperty,
  isLoadingReisProperty: properties.isLoadingReisProperty,
  company: currentUser.company
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchPropertyFromReisNetwork,
      addProperty,
      addPropertyToCPM,
      resetSearch,
      fetchCompanyDocuments,
      updateDocument,
      updateProperty
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditDataBody)
);
