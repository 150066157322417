import { useRef, useState } from "react";
import Popup from "reactjs-popup";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { DatePicker } from "antd";
import dayjs from "dayjs";

import {
  assignExceptionToEffectiveDate,
  fetchCompanyDocuments
} from "ui/store/actions/rentRoll";
import { updateDocument } from "ui/store/actions/rentRoll";
import { columnDetails } from "ui/components/manageFiles/helpers/manageFileListView";

import Button from "ui/components/shared/Button";

const EditEffectiveDateModal = ({
  currentEffectiveDate,
  documentId,
  updateDocument,
  fetchCompanyDocuments,
  assignExceptionToEffectiveDate,
  widgetAuth = {},
  filterObject = {},
  setSortByLastUpdated = () => {}
}) => {
  const ref = useRef();
  const closePopover = () => ref.current.close();
  const toggleOpen = () => {
    setDatePickerOpen(!isDatePickerOpen);
  };
  const [selectedEffectiveDate, setSelectedEffectiveDate] = useState(
    currentEffectiveDate
  );

  const [isDatePickerOpen, setDatePickerOpen] = useState(true);
  const sortName = columnDetails["Last Updated"].sortName;
  const sortOrder = "desc";
  const PERIOD_DATE_FORMAT = "MM/DD/YYYY";

  return (
    <Popup
      ref={ref}
      trigger={
        <img
          src={require("ui/images/edit-icon.svg")}
          style={{ height: "10px", marginLeft: "5px", cursor: "pointer" }}
        />
      }
      position="center center"
      modal
      closeOnDocumentClick
      className="centered-popover"
      nested
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "white",
          width: "400px",
          height: "auto",
          padding: "15px",
          justifyContent: "center"
        }}
      >
        <div className="EditPropertyModal__header">Update Effective Date</div>
        <div
          style={{
            width: "100%",
            marginTop: "20px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <DatePicker
            defaultValue={
              selectedEffectiveDate
                ? dayjs(selectedEffectiveDate)
                : dayjs().startOf("day")
            }
            id="effective_date_picker"
            style={{ margin: "0px 15px" }}
            format={PERIOD_DATE_FORMAT}
            onChange={date => {
              if (date) {
                toggleOpen();
                setSelectedEffectiveDate(dayjs(date).startOf("day"));
              }
            }}
            value={
              selectedEffectiveDate
                ? dayjs(selectedEffectiveDate)
                : dayjs().startOf("day")
            }
            open={isDatePickerOpen}
          />
          <Button
            style={{ marginRight: "10px" }}
            onClick={() => {
              closePopover();
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={async () => {
              await updateDocument(
                documentId,
                {
                  effective_date: selectedEffectiveDate.toISOString()
                },
                widgetAuth
              );
              await assignExceptionToEffectiveDate(
                documentId,
                "MANUAL_EFFECTIVE_DATE",
                widgetAuth
              );
              await fetchCompanyDocuments(
                0,
                100,
                `${sortName}%7C${sortOrder}`,
                widgetAuth,
                filterObject
              );
              setSortByLastUpdated();
              closePopover();
            }}
          >
            Update
          </Button>
        </div>
      </div>
    </Popup>
  );
};

EditEffectiveDateModal.propTypes = {
  currentEffectiveDate: PropTypes.string,
  documentId: PropTypes.number,
  updateDocument: PropTypes.func,
  fetchCompanyDocuments: PropTypes.func,
  assignExceptionToEffectiveDate: PropTypes.func,
  widgetAuth: PropTypes.object,
  filterObject: PropTypes.object,
  setSortByLastUpdated: PropTypes.func
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { updateDocument, fetchCompanyDocuments, assignExceptionToEffectiveDate },
    dispatch
  );

export default connect(null, mapDispatchToProps)(EditEffectiveDateModal);
