import { blankRow, getItemsByType, subcategoryNames } from "./processRows";
import { deepCopy } from "services/helpers/objectUtils";
import { isEmpty } from "ramda";
import { getFormattedRow } from "./excelFormatting";
import {
  singleTab,
  doubleTab,
  whiteFillColor,
  subcategoryColor
} from "./excelFormatting";

export const processOpexDataCPM = (
  opexTableData,
  ignoredColumnsIdx,
  NoiAndNcf
) => {
  const accounts = getItemsByType(opexTableData, "account");
  const categories = getItemsByType(opexTableData, "category").filter(cat => {
    return cat.children.length > 0;
  });
  const subcategories = getItemsByType(opexTableData, "subcategory");

  const subcategoriesHeader = deepCopy(subcategories);
  const categoriesHeader = deepCopy(categories);

  const filterIgnoredAccountsAndTotals = columns => {
    return columns.reduce((arr, column, index) => {
      if (!ignoredColumnsIdx.includes(index))
        arr.push(parseFloat(column.value));
      return arr;
    }, []);
  };
  const getFormattedTotalsAndValues = (name, values, fillColor) => {
    return [getFormattedRow(name, fillColor, "bold")].concat(
      filterIgnoredAccountsAndTotals(values).map(value => {
        return getFormattedRow(value, fillColor, "boldNumericValue");
      })
    );
  };

  const getFormattedAccountsAndValues = (name, values) => {
    // we'll use null as the fill color, since we want accounts to have no fill
    return [getFormattedRow(name, null, "nameNoBorders")].concat(
      filterIgnoredAccountsAndTotals(values).map(value => {
        return getFormattedRow(value, null, "numericValueNoBorders");
      })
    );
  };

  const exportedRows = [];
  let prevAccountParentId = accounts[0].parentId;
  let subcategoryLvlOnePrevParentId = subcategories[0].parentId;
  let subcategoryLvlOneNextParentId, subcategoryLvlOne, category;
  let categoryHeader = categoriesHeader.shift().name;
  let subcategoryHeader = subcategoriesHeader.shift().name;
  exportedRows.push(
    [getFormattedRow(categoryHeader, null, "boldNoBorders")],
    [getFormattedRow(singleTab + subcategoryHeader, null, "nameNoBorders")]
  );

  for (let k = 0; k < accounts.length; k += 1) {
    if (prevAccountParentId === accounts[k].parentId) {
      exportedRows.push([
        ...getFormattedAccountsAndValues(
          doubleTab + accounts[k].name,
          accounts[k].values
        )
      ]);
      prevAccountParentId = accounts[k].parentId;
    } else if (
      prevAccountParentId !== accounts[k].parentId &&
      !isEmpty(subcategories)
    ) {
      // if next account has a different subcategory, then push the top subcategory
      subcategoryLvlOne = subcategories.shift();
      if (subcategoryLvlOnePrevParentId === subcategoryLvlOne.parentId) {
        exportedRows.push([
          ...getFormattedTotalsAndValues(
            singleTab + `Total ${subcategoryLvlOne.name}`,
            subcategoryLvlOne.totals,
            subcategoryColor
          )
        ]);
        subcategoryLvlOnePrevParentId = subcategoryLvlOne.parentId;
        // before pushing the next account, we want to check that the current subcategory level 1 is under
        // the same same category as the previous subcategory level 1
        //(peek at the next subcategory 1 parentId without popping it off)

        if (!isEmpty(subcategories))
          subcategoryLvlOneNextParentId = subcategories[0].parentId;

        // if the next subcat has a different parentId than the current one
        // meaning that it has a different category, we want to add the current category
        if (
          subcategoryLvlOnePrevParentId !== subcategoryLvlOneNextParentId &&
          !isEmpty(categories)
        ) {
          category = categories.shift();
          exportedRows.push([
            ...getFormattedTotalsAndValues(
              `Total ${category.name}`,
              category.totals,
              whiteFillColor
            )
          ]);
          if (
            category.name === subcategoryNames.OPERATING_EXPENSES &&
            subcategoryNames.NOI in NoiAndNcf
          ) {
            exportedRows.push(blankRow, NoiAndNcf.NOI, blankRow);
          } else if (
            category.name === subcategoryNames.CAPITAL_EXPENDITURES &&
            subcategoryNames.NCF in NoiAndNcf
          ) {
            exportedRows.push(blankRow, NoiAndNcf.NCF, blankRow);
          }

          categoryHeader = categoriesHeader.shift().name;
          subcategoryHeader = subcategoriesHeader.shift().name;
          exportedRows.push(
            [getFormattedRow(categoryHeader, null, "boldNoBorders")],
            [
              getFormattedRow(
                singleTab + subcategoryHeader,
                null,
                "nameNoBorders"
              )
            ]
          );

          exportedRows.push([
            ...getFormattedAccountsAndValues(
              doubleTab + accounts[k].name,
              accounts[k].values
            )
          ]);
          prevAccountParentId = accounts[k].parentId;
        }
        // otherwise if the subcategory is under the same category as the previous one,
        // we can keep adding the next account
        else if (
          subcategoryLvlOnePrevParentId === subcategoryLvlOneNextParentId
        ) {
          let subcategoryHeader = subcategoriesHeader.shift().name;
          exportedRows.push([
            getFormattedRow(
              singleTab + subcategoryHeader,
              null,
              "nameNoBorders"
            )
          ]);
          exportedRows.push([
            ...getFormattedAccountsAndValues(
              doubleTab + accounts[k].name,
              accounts[k].values
            )
          ]);
          prevAccountParentId = accounts[k].parentId;
        }
      }
      // if the subcat1 is different, push the next account
      else if (subcategoryLvlOnePrevParentId !== subcategoryLvlOne.parentId) {
        exportedRows.push([
          ...getFormattedTotalsAndValues(
            singleTab + `Total ${subcategoryLvlOne.name}`,
            subcategoryLvlOne.totals,
            subcategoryColor
          )
        ]);
        subcategoryLvlOnePrevParentId = subcategoryLvlOne.parentId;
        if (!isEmpty(subcategories))
          subcategoryLvlOneNextParentId = subcategories[0].parentId;

        // if the next subcat has a different parentId than the current one
        // meaning that it has a different category, we want to add the current category
        if (
          subcategoryLvlOnePrevParentId !== subcategoryLvlOneNextParentId &&
          !isEmpty(categories)
        ) {
          category = categories.shift();
          exportedRows.push([
            ...getFormattedTotalsAndValues(
              `Total ${category.name}`,
              category.totals,
              whiteFillColor
            )
          ]);
          if (
            category.name === subcategoryNames.OPERATING_EXPENSES &&
            subcategoryNames.NOI in NoiAndNcf
          ) {
            exportedRows.push(blankRow, NoiAndNcf.NOI, blankRow);
          } else if (
            category.name === subcategoryNames.CAPITAL_EXPENDITURES &&
            subcategoryNames.NCF in NoiAndNcf
          ) {
            exportedRows.push(blankRow, NoiAndNcf.NCF, blankRow);
          }
          let categoryHeader = categoriesHeader.shift().name;
          let subcategoryHeader = subcategoriesHeader.shift().name;
          exportedRows.push(
            [getFormattedRow(categoryHeader, null, "boldNoBorders")],
            [
              getFormattedRow(
                singleTab + subcategoryHeader,
                null,
                "nameNoBorders"
              )
            ]
          );

          exportedRows.push([
            ...getFormattedAccountsAndValues(
              doubleTab + accounts[k].name,
              accounts[k].values
            )
          ]);
          prevAccountParentId = accounts[k].parentId;
        }
        // otherwise if the subcategory is under the same category as the previous one,
        // we can keep adding the next account
        else if (
          subcategoryLvlOnePrevParentId === subcategoryLvlOneNextParentId
        ) {
          let subcategoryHeader = subcategoriesHeader.shift().name;
          exportedRows.push([
            getFormattedRow(
              singleTab + subcategoryHeader,
              null,
              "nameNoBorders"
            )
          ]);
          exportedRows.push([
            ...getFormattedAccountsAndValues(
              doubleTab + accounts[k].name,
              accounts[k].values
            )
          ]);
          prevAccountParentId = accounts[k].parentId;
        }
      }
    }
    if (k === accounts.length - 1) {
      if (!isEmpty(subcategories)) {
        subcategoryLvlOne = subcategories.shift();
        exportedRows.push([
          ...getFormattedTotalsAndValues(
            singleTab + `Total ${subcategoryLvlOne.name}`,
            subcategoryLvlOne.totals,
            subcategoryColor
          )
        ]);
      }
      if (!isEmpty(categories)) {
        category = categories.shift();
        exportedRows.push([
          ...getFormattedTotalsAndValues(
            `Total ${category.name}`,
            category.totals,
            whiteFillColor
          )
        ]);
        if (
          category.name === subcategoryNames.OPERATING_EXPENSES &&
          subcategoryNames.NOI in NoiAndNcf
        ) {
          exportedRows.push(blankRow, NoiAndNcf.NOI, blankRow);
        } else if (
          category.name === subcategoryNames.CAPITAL_EXPENDITURES &&
          subcategoryNames.NCF in NoiAndNcf
        ) {
          exportedRows.push(blankRow, NoiAndNcf.NCF, blankRow);
        }
      }
    }
  }
  return exportedRows;
};
