import { apiFetch } from "ui/store/actions/apiClient";

/**
 * Fetches predef values based on params
 * @param {*} propertyType
 * @param {*} field
 * @returns the exsisiting predef values
 */
export async function fetchPredefValues(propertyType, field) {
  try {
    const response = await apiFetch(
      `/api/predef-values/${propertyType}/${field}`,
      {
        method: "get"
      }
    );
    if (!response.ok) {
      const responseBody = await response.json();
      throw new Error(responseBody.error);
    }
    const responseBody = await response.json();
    const { predefValues } = responseBody;
    return predefValues;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}

/**
 * post a new predef value
 * @param {string} value
 * @param {string} field
 * @param {string} propertyType
 * @returns the new predef value
 */
export async function createPredefValue(value, field, propertyType) {
  const body = { value, field };

  try {
    const response = await apiFetch(
      `/api/predef-values/create/${propertyType}`,
      {
        method: "post",
        body: JSON.stringify(body)
      }
    );
    if (!response.ok) {
      const responseBody = await response.json();
      throw new Error(responseBody.error);
    }
    const responseBody = await response.json();
    return responseBody;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

/**
 *  deletes a predef value based on id
 * @param {string} predefId
 */
export async function deletePredefValue(predefId) {
  try {
    const response = await apiFetch(`/api/predef-values/delete/${predefId}`, {
      method: "delete"
    });

    if (!response.ok) {
      const responseBody = await response.json();
      throw new Error(responseBody.error);
    }
    const responseBody = await response.json();

    const { predefValues } = responseBody;
    return predefValues;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}

/**
 * Updates an existing predef value
 * @param {*} predefId
 * @param {*} update
 * @returns nothing
 */

export async function editPredefValue(predefId, update) {
  const body = { update };
  try {
    const response = await apiFetch(`/api/predef-values/update/${predefId}`, {
      method: "put",
      body: JSON.stringify(body)
    });

    if (!response.ok) {
      const responseBody = await response.json();
      throw new Error(responseBody.error);
    }
    const responseBody = await response.json();
    return responseBody;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}
