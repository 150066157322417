import { merge } from "ramda";

import {
  ADD_CELL_TO_DOCUMENT_NAV,
  ADD_CELL_TO_RENT_ROLL_NAV,
  ADD_PAGE_TO_NAV,
  BEGIN_NAV_TO_DOCUMENT,
  BEGIN_NAV_TO_CELL,
  BEGIN_NAV_TO_PAGE,
  FINISH_NAV_TO_DOCUMENT,
  FINISH_NAV_TO_CELL,
  FINISH_NAV_TO_PAGE,
  FINISH_EXCEL_PARSE_TO_HTML,
  UPDATED_COMPLETED_AT
} from "../actions/splitScreen";

export default function splitScreen(
  state = {
    documentNav: "",
    rentRollNav: "",
    pageNav: undefined,
    cellNavInProgress: false,
    pageNavInProgress: false,
    htmlPageString: "",
    dateUpdatedAt: ""
  },
  action
) {
  switch (action.type) {
    case ADD_CELL_TO_DOCUMENT_NAV: {
      const { location } = action;
      return merge(state, { documentNav: location });
    }
    case ADD_CELL_TO_RENT_ROLL_NAV: {
      const { location } = action;
      return merge(state, { rentRollNav: location });
    }
    case ADD_PAGE_TO_NAV: {
      const { page } = action;
      return merge(state, { pageNav: page });
    }
    case BEGIN_NAV_TO_DOCUMENT: {
      return merge(state, { cellNavInProgress: true });
    }
    case BEGIN_NAV_TO_CELL: {
      return merge(state, { cellNavInProgress: true });
    }
    case BEGIN_NAV_TO_PAGE: {
      return merge(state, { pageNavInProgress: true });
    }
    case FINISH_NAV_TO_DOCUMENT: {
      return merge(state, { cellNavInProgress: false, documentNav: "" });
    }
    case FINISH_NAV_TO_CELL: {
      return merge(state, { cellNavInProgress: false, rentRollNav: "" });
    }
    case FINISH_NAV_TO_PAGE: {
      return merge(state, { pageNavInProgress: false, pageNav: undefined });
    }
    case FINISH_EXCEL_PARSE_TO_HTML: {
      const { html } = action;
      return merge(state, { htmlPageString: html });
    }
    case UPDATED_COMPLETED_AT: {
      const { date } = action;
      return merge(state, { dateUpdatedAt: date });
    }
    default: {
      return state;
    }
  }
}
