import PropTypes from "prop-types";
import OpexDocument from "../../modelOpexDocument";
import TableSelectionExcel from "../TableSelectionExcel/TableSelectionExcel";
import TableSelectionPdf from "../TableSelectionPdf/TableSelectionPdf";

const TableSelection = ({ document, onChange }) => {
  if (!document) {
    return null;
  }
  const documentModel = new OpexDocument(document);

  return (
    <>
      {documentModel.isExcel() && (
        <TableSelectionExcel document={document} onChange={onChange} />
      )}

      {documentModel.isPdf() && (
        <TableSelectionPdf document={document} onChange={onChange} />
      )}
    </>
  );
};

export default TableSelection;

TableSelection.propTypes = {
  document: PropTypes.object,
  onChange: PropTypes.func
};
