import { blankRow, getItemsByType, subcategoryNames } from "./processRows";
import { deepCopy } from "services/helpers/objectUtils";
import { isEmpty } from "ramda";
import { getFormattedRow } from "./excelFormatting";
import { singleTab, whiteFillColor } from "./excelFormatting";

export const processSubcategoryViewCPM = (
  opexTableData,
  ignoredColumnsIdx,
  NoiAndNcf
) => {
  const categories = getItemsByType(opexTableData, "category").filter(
    category => category.totals.length > 0
  );
  const subcategories = getItemsByType(opexTableData, "subcategory");
  const categoriesHeader = deepCopy(categories);

  const filterIgnoredAccountsAndTotals = columns => {
    return columns.reduce((arr, column, index) => {
      if (!ignoredColumnsIdx.includes(index))
        arr.push(parseFloat(column.value));
      return arr;
    }, []);
  };

  const getFormattedTotalsAndValues = (name, values, fillColor) => {
    return [getFormattedRow(name, fillColor, "bold")].concat(
      filterIgnoredAccountsAndTotals(values).map(value => {
        return getFormattedRow(value, fillColor, "boldNumericValue");
      })
    );
  };

  const getFormattedSubcategoriesAndValues = (name, values, fillColor) => {
    return [getFormattedRow(name, fillColor, "nameNoBorders")].concat(
      filterIgnoredAccountsAndTotals(values).map(value => {
        return getFormattedRow(value, fillColor, "numericValueNoBorders");
      })
    );
  };

  const exportedRows = [];
  let subcategoryPrevParentId = subcategories[0].parentId;
  let category;
  // pop off the first category header row
  let categoryHeader = categoriesHeader.shift().name;
  exportedRows.push([getFormattedRow(categoryHeader, null, "boldNoBorders")]);

  for (let k = 0; k < subcategories.length; k += 1) {
    // if subcategory level 2's share a parent subcategory, keep pushing them
    if (subcategoryPrevParentId === subcategories[k].parentId) {
      exportedRows.push([
        ...getFormattedSubcategoriesAndValues(
          singleTab + subcategories[k].name,
          subcategories[k].totals,
          whiteFillColor
        )
      ]);
      subcategoryPrevParentId = subcategories[k].parentId;
    } else if (
      subcategories[k].parentId !== subcategoryPrevParentId &&
      !isEmpty(categories)
    ) {
      category = categories.shift();
      let dontSkip = category.totals || false;
      if (dontSkip) {
        exportedRows.push([
          ...getFormattedTotalsAndValues(
            `Total ${category.name}`,
            category.totals,
            whiteFillColor
          )
        ]);
        if (
          category.name === subcategoryNames.OPERATING_EXPENSES &&
          subcategoryNames.NOI in NoiAndNcf
        ) {
          exportedRows.push(blankRow, NoiAndNcf.NOI, blankRow);
        } else if (
          category.name === subcategoryNames.CAPITAL_EXPENDITURES &&
          subcategoryNames.NCF in NoiAndNcf
        ) {
          exportedRows.push(blankRow, NoiAndNcf.NCF, blankRow);
        }
      }
      categoryHeader = categoriesHeader.shift().name;
      exportedRows.push([
        getFormattedRow(categoryHeader, null, "boldNoBorders")
      ]);

      exportedRows.push([
        ...getFormattedSubcategoriesAndValues(
          singleTab + subcategories[k].name,
          subcategories[k].totals,
          whiteFillColor
        )
      ]);
      subcategoryPrevParentId = subcategories[k].parentId;
    }
    if (k === subcategories.length - 1) {
      if (!isEmpty(categories)) {
        category = categories.shift();
        let dontSkip = category.totals || false;
        if (dontSkip) {
          exportedRows.push([
            ...getFormattedTotalsAndValues(
              `Total ${category.name}`,
              category.totals || [{ value: 0 }],
              whiteFillColor
            )
          ]);
          if (
            category.name === subcategoryNames.OPERATING_EXPENSES &&
            subcategoryNames.NOI in NoiAndNcf
          ) {
            exportedRows.push(blankRow, NoiAndNcf.NOI, blankRow);
          } else if (
            category.name === subcategoryNames.CAPITAL_EXPENDITURES &&
            subcategoryNames.NCF in NoiAndNcf
          ) {
            exportedRows.push(blankRow, NoiAndNcf.NCF, blankRow);
          }
        }
      }
    }
  }
  return exportedRows;
};
