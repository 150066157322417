import PropTypes from "prop-types";
import { accountCodeRegex } from "helpers/opex";

const AccountsCellRenderer = ({ rowData, onClickRowName }) => {
  const { isExcluded, text } = rowData;
  return (
    <div
      className={`rowName ${isExcluded ? "isExcluded" : ""}`}
      onClick={() => onClickRowName(rowData)}
    >
      {text.replace(accountCodeRegex, "").trim()}
    </div>
  );
};

export default AccountsCellRenderer;

AccountsCellRenderer.propTypes = {
  rowData: PropTypes.object,
  onClickRowName: PropTypes.func
};
