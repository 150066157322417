import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Row, Cell } from "react-sticky-table";
import cx from "classnames";
import moment from "moment";
import Popup from "reactjs-popup";

import {
  clearPropertiesSelect,
  fetchPropertiesByCompany
} from "ui/store/actions/properties";

import {
  assignPropertyToDocument,
  fetchNeedAttentionDocuments
} from "ui/store/actions/rentRoll";

import { statusMap } from "../upload/StatusBarUtil";
import ActionTrigger from "./ActionTrigger";

const DocumentRow = ({
  doc,
  properties,
  fetchPropertiesByCompany,
  clearPropertiesSelect,
  assignPropertyToDocument,
  fetchNeedAttentionDocuments
}) => {
  const [editProperty, setEditProperty] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (editProperty) {
      fetchPropertiesByCompany(true);
    } else {
      clearPropertiesSelect();
    }
  }, [editProperty]);
  return (
    <>
      <Row
        style={{
          whiteSpace: "normal"
        }}
        className="sticky-table__body-row"
      >
        <Cell key={"filename"}>
          <Popup
            trigger={
              <div
                style={{
                  maxWidth: "120px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  cursor: "default"
                }}
              >
                {doc.original_filename}
              </div>
            }
            on="hover"
            position="bottom center"
            closeOnDocumentClick
            contentStyle={{ width: "auto" }}
          >
            <div
              style={{
                backgroundColor: "white",
                padding: "2px",
                border: "1px solid black"
              }}
            >
              {doc.original_filename}
            </div>
          </Popup>
        </Cell>
        <Cell key={"property"}>
          {doc.property_name ? doc.property_name : "-"}
        </Cell>
        <Cell key={"date"}>{moment(doc.created_at).format("MM/DD/YYYY")}</Cell>
        <Cell key={"status"}>{statusMap[doc.status].status}</Cell>
        <Cell key={"action"} className="DocumentRow__action">
          <ActionTrigger
            doc={doc}
            editProperty={editProperty}
            setEditProperty={setEditProperty}
          />
        </Cell>
      </Row>
      {editProperty && (
        <Row>
          <div
            style={{
              width: "145px",
              padding: "15px",
              backgroundColor: "#bdc3c7"
            }}
          >
            <h2>Add Property Info</h2>
            <select
              value={selectedProperty}
              onChange={e => setSelectedProperty(e.target.value)}
            >
              <option key="" value="">
                -- Choose a Property --
              </option>
              {properties.map((property, idx) => (
                <option key={`${property.id}-${idx}`} value={property.id}>
                  {`${property.street_1}${
                    property.city ? ` ${property.city}` : ""
                  }${property.state ? `, ${property.state}` : ""}${
                    property.postal_code ? ` ${property.postal_code}` : ""
                  }`}
                </option>
              ))}
            </select>
            {isLoading ? (
              <button
                className="Button"
                style={{ marginLeft: "10px" }}
                disabled={true}
              >
                Updating...
              </button>
            ) : (
              selectedProperty !== "" && (
                <button
                  className={cx("Button", "Button__blueButton")}
                  style={{ marginLeft: "10px" }}
                  onClick={async () => {
                    setIsLoading(true);
                    await assignPropertyToDocument(doc.id, selectedProperty);
                    await fetchNeedAttentionDocuments();
                    setIsLoading(false);
                    setEditProperty(false);
                  }}
                >
                  Add
                </button>
              )
            )}
            <button
              className={cx("Button", "Button__blackButton")}
              style={{ marginLeft: "10px" }}
              onClick={() => setEditProperty(false)}
            >
              Cancel
            </button>
          </div>
          <Cell style={{ backgroundColor: "#bdc3c7" }} />
          <Cell style={{ backgroundColor: "#bdc3c7" }} />
          <Cell style={{ backgroundColor: "#bdc3c7" }} />
          <Cell style={{ backgroundColor: "#bdc3c7" }} />
        </Row>
      )}
    </>
  );
};

DocumentRow.propTypes = {
  doc: PropTypes.object,
  properties: PropTypes.array,
  fetchPropertiesByCompany: PropTypes.func,
  clearPropertiesSelect: PropTypes.func,
  assignPropertyToDocument: PropTypes.func,
  fetchNeedAttentionDocuments: PropTypes.func
};

const mapStateToProps = ({ properties }) => ({
  properties: properties.propertiesSelect
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchPropertiesByCompany,
      clearPropertiesSelect,
      assignPropertyToDocument,
      fetchNeedAttentionDocuments
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DocumentRow);
