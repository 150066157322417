import {
  isLeaseLongerThanTenYears,
  isLeaseStartDateAfterEnd,
  isCellValueRequired,
  isIntegrationDataInvalid
} from "ui/components/rentRoll/splitPanel/helpers/exceptions";

export default function computeCellFlags({
  confidence,
  corrected,
  isMapped,
  location,
  predef,
  exceptions = []
}) {
  const hasLeaseLengthError = isLeaseLongerThanTenYears(
    exceptions,
    transformLocation(location)
  );

  const hasLeaseConcurrencyError = isLeaseStartDateAfterEnd(
    exceptions,
    transformLocation(location)
  );

  const hasCellValueRequired = isCellValueRequired(
    exceptions,
    transformLocation(location)
  );

  const hasIntegrationDataInvalid = isIntegrationDataInvalid(
    exceptions,
    transformLocation(location)
  );

  const hasNoPredefMapping = predef ? !predef.id : true;
  const hasLowPredefConfidence = predef ? predef.confidence < 90 : false;
  const hasLowConfidence = !isNaN(confidence) !== undefined && confidence < 90;

  const hasWarning =
    isMapped &&
    !!(
      (!corrected && hasLeaseLengthError) ||
      (!corrected && hasLowPredefConfidence)
    );

  const hasError = !!(
    isMapped &&
    (hasLeaseConcurrencyError ||
      hasIntegrationDataInvalid ||
      (!corrected && hasCellValueRequired) ||
      // If predef use Predef confidence levels
      ((!corrected && !hasNoPredefMapping) && hasLowPredefConfidence) ||
      // If not Predef use OCR confidence levels
      ((!corrected && hasNoPredefMapping) && hasLowConfidence) ||
      // Only check for mapping if cell is Predef
      (predef ? !corrected && hasNoPredefMapping : false))
  );

  const isCorrected = isMapped && !!(!hasLeaseConcurrencyError && corrected);

  return {
    hasError,
    hasWarning,
    isCorrected
  };
}

export function transformLocation({
  columnIndex,
  headerIndex,
  pageIndex,
  rowIndex,
  tableIndex
}) {
  return {
    ci: columnIndex,
    hi: headerIndex,
    pi: pageIndex,
    ri: rowIndex,
    ti: tableIndex
  };
}
