import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import cx from "classnames";
import CompanyRow from "./CompanyRow";
import {
  fetchCompanies,
  updateCompany,
  generateCompanyApiKeys,
  getCompanyApiKeys,
  loginOnBehalf
} from "ui/store/actions/companies";

class CompaniesTable extends Component {
  componentDidMount() {
    this.props.fetchCompanies();
  }

  render() {
    let companies, companyRows;
    if (this.props.companies) {
      companies = this.props.companies;
      companyRows = companies.map((company, i) => {
        return (
          <CompanyRow
            id={company.id}
            name={company.company_name}
            groupName={company.group_name}
            externalId={company.external_id}
            maxActiveUsers={company.max_active_users}
            activeUsers={company.active_users}
            createdAt={company.created_at}
            expiresAt={company.end_date}
            key={`company-row-${i}`}
            updateCompany={this.props.updateCompany}
            generateCompanyApiKeys={this.props.generateCompanyApiKeys}
            getCompanyApiKeys={this.props.getCompanyApiKeys}
            loginOnBehalf={this.props.loginOnBehalf}
            apiPublicKey={this.props.s2sAuthentication.apiPublicKey}
            secretKey={this.props.s2sAuthentication.secretKey}
          />
        );
      });
    } else {
      return <div>Loading...</div>;
    }

    return (
      <div className="CompaniesTable">
        <div className="CompaniesTable__header">
          <div className={cx("CompaniesTable__columnName", "ms-column-1")}>
            Id
          </div>
          <div className={cx("CompaniesTable__columnName", "ms-column-2")}>
            Name
          </div>
          <div className={cx("CompaniesTable__columnName", "ms-column-3")}>
            Group
          </div>
          <div className={cx("CompaniesTable__columnName", "ms-column-4")}>
            External ID
          </div>
          <div className={cx("CompaniesTable__columnName", "ms-column-5")}>
            Active Users
          </div>
          <div className={cx("CompaniesTable__columnName", "ms-column-6")}>
            Max Active Users
          </div>
          <div className={cx("CompaniesTable__columnName", "ms-column-7")}>
            Created At
          </div>
          <div className={cx("CompaniesTable__columnName", "ms-column-8")}>
            Expires At
          </div>
          <div className={cx("CompaniesTable__columnName", "ms-column-9")}>
            Update
          </div>
          <div className={cx("CompaniesTable__columnName", "ms-column-10")}>
            API Keys
          </div>
          <div
            className={cx("CompaniesTable__columnName", "ms-column-11")}
          ></div>
        </div>
        <div className="CompaniesTable__rows">{companyRows}</div>
      </div>
    );
  }
}

CompaniesTable.propTypes = {
  companies: PropTypes.array,
  s2sAuthentication: PropTypes.object,
  fetchCompanies: PropTypes.func.isRequired,
  updateCompany: PropTypes.func,
  generateCompanyApiKeys: PropTypes.func,
  getCompanyApiKeys: PropTypes.func,
  loginOnBehalf: PropTypes.func
};

function mapStateToProps({ companies, s2sAuthentication }) {
  return {
    companies: companies.companies,
    s2sAuthentication
  };
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchCompanies,
      updateCompany,
      generateCompanyApiKeys,
      getCompanyApiKeys,
      loginOnBehalf
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesTable);
