import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import AccountsLeftPanel from "./components/AccountsLeftPanel/AccountsLeftPanel";
import AccountsRightPanel from "./components/AccountsRightPanel/AccountsRightPanel";
import Spinner from "ui/components/shared/Spinner";
import useOpexTemplates from "ui/components/opex/hooks/useOpexTemplates";
import useDocumentTables from "ui/components/opex/hooks/useDocumentTables";
import OpexSplitPanel from "../OpexSplitPanel/OpexSplitPanel";
import Popup from "reactjs-popup";
import OpexButton from "../OpexButton/OpexButton";
import { TemplateStatus } from "helpers/opex";
import { isCreditLensByGroupName } from "ui/components/opex/shared";

const Accounts = ({
  groupName,
  document,
  currentTable,
  setCurrentTable,
  currentPage,
  setCurrentPage,
  rawDataJson,
  setRawDataJson,
  onUpdateRawDataRows,
  metaDataJson,
  setMetaDataJson,
  onUpdateMetaDataJson,
  onUpdateDocumentTemplate,
  setStatusHeaderCounters
}) => {
  // 50/50 default was requested on Accounts Step, by PM
  const defaultPanelSplits = [50, 50];
  const { documentTables } = useDocumentTables();
  const [activeRowLocation, setActiveRowLocation] = useState(null);
  const [showArchivedTempModal, setShowArchivedTempModal] = useState(false);
  const { templates, getTemplateDetail } = useOpexTemplates(
    document.property_type
  );
  const { data: template } = getTemplateDetail(
    document.template_id,
    document.property_type
  );

  useEffect(
    () =>
      setShowArchivedTempModal(
        !isCreditLensByGroupName(groupName) &&
          template?.status === TemplateStatus.ARCHIVED
      ),
    [template]
  );
  // If `activeRowLocation` is set to the same value twice
  // it means the user is trying to deselect. We have to use
  // useRef because when trying to use `activeRowLocation`
  // in the `handleOnClickRowName` function, scope is not working
  // and if you try to read state value for `activeRowLocation`
  // in `handleOnClickRowName` it is always null.
  const prevActiveRowLocation = useRef();
  useEffect(() => {
    prevActiveRowLocation.current = activeRowLocation;
  }, [activeRowLocation]);

  const handleOnClickRowName = rowData => {
    const rowIndex = rowData.documentRowIndex;
    const setActiveRow =
      rowData.currentTable !== rowData.pageIndex ||
      prevActiveRowLocation.current !== rowIndex;
    setCurrentTable(rowData.tableIndex);
    setCurrentPage(rowData.pageIndex);
    setActiveRowLocation(setActiveRow ? rowIndex : null);
  };
  useEffect(() => {
    setMetaDataJson(document.meta_data_json);
    setRawDataJson(document.raw_data_json);
    const firstPageIndexWithData = document.raw_data_json.pages.reduce(
      (prevIndex, page, currIndex, pages) => {
        return !pages[prevIndex].tableData.length ? currIndex : prevIndex;
      },
      0
    );
    setCurrentPage(firstPageIndexWithData);
  }, [document.id]);

  useEffect(() => {
    if (templates?.length && !document.template_id) {
      onUpdateDocumentTemplate(templates[0].id);
    }
  }, [document.template_id, templates]);

  const toggleExcludedRow = (tableIndex, rowIndex) => {
    const current = { ...metaDataJson };
    const excludedRowTest = current.headers[tableIndex].excludedRows;
    excludedRowTest[rowIndex] = !excludedRowTest[rowIndex];
    onUpdateMetaDataJson(current);
  };

  return documentTables.length &&
    rawDataJson &&
    metaDataJson &&
    document.template_id ? (
    <>
      <div className="OpexPanelView__DocumentView__Content">
        <OpexSplitPanel sizes={defaultPanelSplits}>
          <div className="OpexPanelView__DocumentView__Content__Content">
            <AccountsLeftPanel
              tables={documentTables}
              currentTable={currentTable}
              rawDataJson={rawDataJson}
              onUpdateRawDataRows={onUpdateRawDataRows}
              metaDataJson={metaDataJson}
              templates={templates}
              template={template}
              toggleExcludedRow={toggleExcludedRow}
              onChangeTemplate={onUpdateDocumentTemplate}
              onClickRowName={handleOnClickRowName}
              setStatusHeaderCounters={setStatusHeaderCounters}
            />
          </div>
          <AccountsRightPanel
            document={document}
            tables={documentTables}
            currentTable={currentTable}
            currentPage={currentPage}
            activeRowLocation={activeRowLocation}
          />
        </OpexSplitPanel>
      </div>
      <Popup
        modal
        open={showArchivedTempModal}
        position="right center"
        closeOnDocumentClick={false}
      >
        <div className="ChangeTemplateModal">
          <div className="Title">
            Select a new template
            <img
              src={require("ui/images/x-icon.svg")}
              style={{ cursor: "pointer" }}
              onClick={() => setShowArchivedTempModal(false)}
            />
          </div>
          <div className="Content">
            The template selected is no longer in use. Please select another
            template from the drop down menu.
          </div>
          <div className="Footer">
            <OpexButton
              variant="primary"
              onClick={() => setShowArchivedTempModal(false)}
            >
              Got it
            </OpexButton>
          </div>
        </div>
      </Popup>
    </>
  ) : (
    <div className="OpexPanelView__DocumentView__Spinner-Wrapper">
      <Spinner type="ThreeDots" color="#009bdf" height={40} width={40} />
    </div>
  );
};

export default Accounts;

Accounts.propTypes = {
  groupName: PropTypes.string,
  document: PropTypes.object,
  currentTable: PropTypes.number,
  setCurrentTable: PropTypes.func,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  refreshData: PropTypes.func,
  rawDataJson: PropTypes.object,
  setRawDataJson: PropTypes.func,
  onUpdateRawDataRows: PropTypes.func,
  metaDataJson: PropTypes.object,
  setMetaDataJson: PropTypes.func,
  onUpdateMetaDataJson: PropTypes.func,
  onUpdateDocumentTemplate: PropTypes.func,
  setStatusHeaderCounters: PropTypes.func
};
