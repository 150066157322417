export function generateTableKey({
  headerIndex,
  pageIndex,
  tableIndex,
  rowIndex,
  columnIndex
}) {
  return `${headerIndex}-${pageIndex}-${tableIndex}-${rowIndex}-${columnIndex}`;
}

export function computeIndexesFromKey(key = "") {
  const [headerIndex, pageIndex, tableIndex, rowIndex, columnIndex] = key
    .split("-")
    .map(val => {
      const parsed = parseInt(val, 10);
      return isNaN(parsed) ? undefined : parsed;
    });

  return { headerIndex, pageIndex, tableIndex, rowIndex, columnIndex };
}

export function isBrokenTable(headerRowIndex) {
  return headerRowIndex === -1;
}
