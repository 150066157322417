import { Component } from "react";
import PropTypes from "prop-types";
import { StickyTable, Row, Cell } from "react-sticky-table";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import cx from "classnames";
import moment from "moment";
import Popup from "reactjs-popup";

import { statusMap } from "./StatusBarUtil";

import {
  fetchCompanyDocuments,
  fetchUploadingDocuments,
  fetchNeedAttentionDocuments
} from "ui/store/actions/rentRoll";

export class StatusTable extends Component {
  initerval = null;
  state = { intervalRunning: false };

  componentDidMount = () => {
    const { uploadedDocs, fetchUploadingDocuments, widgetAuth } = this.props;
    fetchUploadingDocuments(widgetAuth);
    if (uploadedDocs.length > 0) {
      this.interval = setInterval(() => {
        fetchUploadingDocuments(widgetAuth);
      }, 5000);
    }
  };

  render() {
    const headers = ["Document", "Property", "Date", "Status" /*"Action"*/];
    const { uploadedDocs, analyzingDocs } = this.props;
    return (
      <div className="StatusTable__panel">
        <div
          className="PropertiesList__grid"
          style={{ height: "initial", maxHeight: "396px" }}
        >
          <StickyTable
            stckyHeaderCount={1}
            leftStickyColumnCount={0}
            borderWidth={"1px"}
          >
            <Row
              style={{ whiteSpace: "normal" }}
              className={cx("sticky-table-header")}
            >
              {headers.map(col_header => (
                <Cell
                  className={"StatusTable__cell" + col_header}
                  style={{
                    color: "#3B3E42"
                  }}
                  key={col_header}
                >
                  {col_header}
                </Cell>
              ))}
            </Row>
            {(uploadedDocs || analyzingDocs) &&
              (uploadedDocs.length > 0 || analyzingDocs.length > 0) &&
              [...uploadedDocs, ...analyzingDocs].map((doc, index) => (
                <Row
                  style={{
                    whiteSpace: "normal"
                  }}
                  key={index}
                  className={cx("sticky-table__body-row")}
                >
                  <Cell key={"filename"}>
                    <Popup
                      trigger={
                        <div
                          style={{
                            maxWidth: "150px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            cursor: "default"
                          }}
                        >
                          {doc.original_filename}
                        </div>
                      }
                      on="hover"
                      position="bottom center"
                      closeOnDocumentClick
                      contentStyle={{ width: "auto" }}
                    >
                      <div
                        style={{
                          backgroundColor: "white",
                          padding: "2px",
                          border: "1px solid black"
                        }}
                      >
                        {doc.original_filename}
                      </div>
                    </Popup>
                  </Cell>
                  <Cell key={"property"}>
                    {doc.property_name ? doc.property_name : "-"}
                  </Cell>
                  <Cell key={"date"}>
                    {moment(doc.created_at).format("MM/DD/YYYY")}
                  </Cell>
                  <Cell key={"status"}>{statusMap[doc.status].status}</Cell>
                  {/* <Cell key={"action"}>{statusMap[doc.status].action}</Cell> */}
                </Row>
              ))}
          </StickyTable>
        </div>
      </div>
    );
  }
}

StatusTable.propTypes = {
  uploadedDocs: PropTypes.array,
  analyzingDocs: PropTypes.array,
  uploadStatus: PropTypes.string,
  fetchCompanyDocuments: PropTypes.func,
  fetchUploadingDocuments: PropTypes.func,
  fetchNeedAttentionDocuments: PropTypes.func,
  widgetAuth: PropTypes.object
};

function mapStateToProps(state) {
  return {
    uploadedDocs: state.rentRoll.uploadingDocs,
    analyzingDocs: state.rentRoll.analyzingDocs,
    uploadStatus: state.rentRoll.uploadStatus,
    status: state.rentRoll.status,
    widgetAuth: state.currentUser.widgetAuth
  };
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchCompanyDocuments,
      fetchUploadingDocuments,
      fetchNeedAttentionDocuments
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(StatusTable);
