export function getRentRollFieldsForDropDown(fieldsByPropertyType) {
  const fieldNamesMapping = {};
  Object.keys(fieldsByPropertyType).forEach(propertyType => {
    fieldNamesMapping[propertyType] = {};
    const propertyTypeFields = fieldsByPropertyType[propertyType].fields;
    propertyTypeFields.forEach(field => {
      const { term: fieldName, displayTerm: displayName, status } = field;
      if (status === "show") {
        fieldNamesMapping[propertyType][fieldName] = displayName;
      }
    });
  });
  return fieldNamesMapping;
}
