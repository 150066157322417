import PropTypes from "prop-types";
import {
  WORKFLOW_TABLE_SELECTION,
  WORKFLOW_CHART_OF_ACCOUNTS_SELECTION
} from "ui/components/opex/helpers/opex";

import { IGNORED_COLOR, RECOGNIZED_COLOR } from "ui/components/opex/shared";
import SquareColorIcon from "../SquareColorIcon/SquareColorIcon";
import ToggleSwitch from "ui/components/apps/ToggleSwitch";
import OpexDocument from "../../modelOpexDocument";

const TableSelectionNav = ({
  document,
  currentWorkflowStatus,
  onToggleSelectAllTables
}) => {
  const documentModel = new OpexDocument(document);
  const shouldShowSelectAllTables = () =>
    currentWorkflowStatus === WORKFLOW_TABLE_SELECTION && documentModel.isPdf();

  const titleText = (
    <>
      <span
        className={
          currentWorkflowStatus === WORKFLOW_TABLE_SELECTION ? "active" : ""
        }
      >
        Select Tables
      </span>
      {" / "}
      <span
        className={
          currentWorkflowStatus === WORKFLOW_CHART_OF_ACCOUNTS_SELECTION
            ? "active"
            : ""
        }
      >
        Select chart of accounts
      </span>
    </>
  );

  return (
    <div className="OpexPanelView__TableSelectionNav OpexPanelView__SubHeader">
      <div className="OpexPanelView__TableSelectionNav--title">{titleText}</div>
      <div className="OpexPanelView__TableSelectionNav--legend">
        <SquareColorIcon
          color={RECOGNIZED_COLOR}
          style={{ marginLeft: ".5rem" }}
        />
        <span style={{ marginLeft: ".3rem", marginRight: "1rem" }}>
          Recognized tables
        </span>
        <SquareColorIcon color={IGNORED_COLOR} />
        <span style={{ marginLeft: ".3rem" }}>Ignored tables</span>
        {shouldShowSelectAllTables() && (
          <>
            <div className="filterbar-splitter" />
            <span className="select-all-tables-label">Select all tables</span>
            <ToggleSwitch
              className="select-all-tables-toggleswitch"
              isOn={documentModel.hasAllTablesQualified()}
              handleToggle={onToggleSelectAllTables}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default TableSelectionNav;

TableSelectionNav.propTypes = {
  document: PropTypes.object,
  onToggleSelectAllTables: PropTypes.func,
  currentWorkflowStatus: PropTypes.string
};
