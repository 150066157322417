import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  updateUploadStatus,
  fetchUploadingDocuments,
  fetchNeedAttentionDocuments
} from "ui/store/actions/rentRoll";
import { setIntervalRunning, setIntervalId } from "ui/store/actions/statusBar";
import { showFlash, clearFlash } from "ui/store/actions/flash";

import SpiralSpinner from "ui/components/spinners/SpiralSpinner";

export class StatusBar extends Component {
  interval = null;

  componentDidMount() {
    const {
      fetchUploadingDocuments,
      fetchNeedAttentionDocuments,
      intervalRunning,
      intervalId
    } = this.props;

    fetchUploadingDocuments();
    fetchNeedAttentionDocuments();

    if (intervalRunning === false) {
      clearInterval(intervalId);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      uploadedDocs,
      analyzingDocs,
      totalCountNeedAttentionDocs,
      needAttentionDocs,
      updateUploadStatus,
      fetchUploadingDocuments,
      fetchNeedAttentionDocuments,
      setIntervalRunning,
      intervalRunning,
      setIntervalId,
      intervalId,
      showFlash,
      clearFlash
    } = this.props;

    if (
      prevProps.uploadedDocs !== uploadedDocs ||
      prevProps.analyzingDocs !== analyzingDocs ||
      prevProps.needAttentionDocs !== needAttentionDocs ||
      prevProps.totalCountNeedAttentionDocs !== totalCountNeedAttentionDocs
    ) {
      if (uploadedDocs.length > 0) {
        showFlash({
          type: "warning",
          message: "Uploading. Please don't close this browser window.",
          close: false
        });
        updateUploadStatus("uploading");
        setIntervalRunning(true);
      } else if (analyzingDocs.length > 0 && uploadedDocs.length === 0) {
        showFlash({
          type: "warning",
          message:
            "Upload complete. You may close your browser while we analyze the documents."
        });
        updateUploadStatus("analyzing");
        setIntervalRunning(true);
      } else if (
        uploadedDocs.length === 0 &&
        analyzingDocs.length === 0 &&
        totalCountNeedAttentionDocs > 0
      ) {
        clearFlash();
        updateUploadStatus("processed");
        setIntervalRunning(false);
      } else if (
        uploadedDocs.length === 0 &&
        analyzingDocs.length === 0 &&
        totalCountNeedAttentionDocs === 0
      ) {
        clearFlash();
        updateUploadStatus("");
      }
    }

    if (
      prevProps.intervalRunning !== intervalRunning &&
      prevProps.intervalRunning === false &&
      intervalRunning === true
    ) {
      this.interval = setInterval(() => {
        fetchUploadingDocuments();
        fetchNeedAttentionDocuments();
      }, 5000);
      setIntervalId(this.interval);
    } else if (
      prevProps.intervalRunning !== intervalRunning &&
      intervalRunning === false
    ) {
      clearInterval(intervalId);
    }
  }

  displayStatusMessage = () => {
    const {
      uploadStatus,
      uploadedDocs,
      totalCountNeedAttentionDocs,
      analyzingDocs
    } = this.props;
    switch (uploadStatus) {
      case "uploading":
        return (
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <SpiralSpinner style={{ marginRight: "5px" }} />
            {`Uploading ${uploadedDocs.length} ${
              uploadedDocs.length === 1 ? "Document" : "Documents"
            } Now`}
          </span>
        );
      case "analyzing":
        return (
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <SpiralSpinner style={{ marginRight: "5px" }} />
            {`Analyzing ${analyzingDocs.length} ${
              analyzingDocs.length === 1 ? "Document" : "Documents"
            } Now`}
          </span>
        );
      case "processed":
        return (
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <div className="StatusBar__attention-circle">
              {totalCountNeedAttentionDocs > 99 ? (
                <span>
                  {99}
                  <sup>+</sup>
                </span>
              ) : (
                <span>{totalCountNeedAttentionDocs}</span>
              )}
            </div>

            {` ${
              totalCountNeedAttentionDocs === 1
                ? "Document Needs"
                : "Documents Need"
            } Review`}
          </span>
        );
      case "":
        return `Documents`;
      default:
        return `Documents`;
    }
  };

  render() {
    return (
      <div
        className="StatusBar__placeholder"
        style={{ cursor: "pointer", width: "100%", padding: "0 10px" }}
      >
        <div>{this.displayStatusMessage()}</div>
      </div>
    );
  }
}

StatusBar.propTypes = {
  uploadStatus: PropTypes.string,
  uploadedDocs: PropTypes.array,
  analyzingDocs: PropTypes.array,
  totalCountNeedAttentionDocs: PropTypes.number,
  needAttentionDocs: PropTypes.array,
  updateUploadStatus: PropTypes.func,
  fetchUploadingDocuments: PropTypes.func,
  fetchNeedAttentionDocuments: PropTypes.func,
  setIntervalRunning: PropTypes.func,
  intervalRunning: PropTypes.bool,
  setIntervalId: PropTypes.func,
  showFlash: PropTypes.func,
  clearFlash: PropTypes.func,
  intervalId: PropTypes.any
};

function mapStateToProps(state) {
  return {
    uploadedDocs: state.rentRoll.uploadingDocs,
    analyzingDocs: state.rentRoll.analyzingDocs,
    totalCountNeedAttentionDocs: state.rentRoll.totalCountNeedAttentionDocs,
    needAttentionDocs: state.rentRoll.needAttentionDocs,
    numUploadsInProgress: state.rentRoll.numUploadsInProgress,
    uploadStatus: state.rentRoll.uploadStatus,
    intervalRunning: state.statusBar.intervalRunning,
    intervalId: state.statusBar.intervalId
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateUploadStatus,
      fetchUploadingDocuments,
      fetchNeedAttentionDocuments,
      setIntervalRunning,
      setIntervalId,
      showFlash,
      clearFlash
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(StatusBar);
