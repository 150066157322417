import { SortableElement, SortableHandle } from "react-sortable-hoc";
import PropTypes from "prop-types";
import { FaBars } from "react-icons/fa";
import { CATEGORY } from "server/middleware/api/helpers/opex";

const DraggableElement = SortableElement(({ children }) => children);
const DraggableHandle = SortableHandle(({ children }) => children);

const Row = ({ key, index, children, data, canEdit, ...rest }) => (
  <DraggableElement key={key} index={index} disabled={data.type === CATEGORY}>
    <div {...rest}>
      {canEdit && data.type !== CATEGORY && (
        <DraggableHandle>
          <div className="TemplatesSubCategoryDragHandle">
            <FaBars className="TemplatesSubCategoryDragHandle__icon" />
          </div>
        </DraggableHandle>
      )}
      {children}
    </div>
  </DraggableElement>
);

Row.propTypes = {
  key: PropTypes.any,
  index: PropTypes.number,
  canEdit: PropTypes.bool,
  children: PropTypes.node,
  data: PropTypes.shape({
    type: PropTypes.string
  })
};

export default Row;
