import { useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Popup from "reactjs-popup";
import cx from "classnames";

import { updateFilter, updateIsFilterOpen } from "ui/store/actions/filters";
import { searchProperties } from "ui/store/actions/properties";
import { searchTenants } from "ui/store/actions/tenants";

import Button from "ui/components/shared/Button";
import FilterBody from "ui/components/filters/FilterBody";
import { bindActionCreators } from "redux";

const FilterPopover = ({
  searchObject,
  queryObject,
  updateFilter,
  searchProperties,
  searchTenants,
  currentView,
  updateCanSearch
}) => {
  const ref = useRef();
  const closePopover = () => ref.current.close();

  const applyFilter = (filterType, filterVal, subfilter) => {
    updateCanSearch(true);
    const portfolioId = queryObject.portfolioId;
    updateFilter(filterType, filterVal, subfilter);
    if (subfilter) {
      searchObject[filterType][subfilter] = filterVal;
    } else {
      searchObject[filterType] = filterVal;
    }
    switch (currentView) {
      case "properties":
        searchProperties(portfolioId, searchObject);
        break;
      case "tenants":
        searchTenants(portfolioId, searchObject);
        break;
      default:
        // eslint-disable-next-line no-console
        console.error("search did not run, invalid currentView");
        break;
    }
  };

  const removeFilter = (filterType, subfilter) => {
    applyFilter(filterType, undefined, subfilter);
  };
  return (
    <Popup
      ref={ref}
      trigger={open => (
        <Button
          className={cx("Button__blackButton")}
          style={{ zIndex: "999", display: "flex", cursor: "pointer" }}
        >
          {!open && (
            <img
              src={require("ui/images/filter-bar-icon.svg")}
              style={{ marginRight: "4px", height: "12px" }}
            />
          )}
          FILTER
          {open && (
            <img
              src={require("ui/images/x-icon.svg")}
              style={{ marginLeft: "4px", height: "12px" }}
            />
          )}
        </Button>
      )}
      arrow={false}
      position="bottom center"
      closeOnDocumentClick={true}
      contentStyle={{
        margin: "0",
        width: "0",
        padding: "0",
        top: "160px"
      }}
    >
      {close => (
        <FilterBody
          onDone={() => close()} //updateIsFilterOpen(false)}
          applyFilter={applyFilter}
          removeFilter={removeFilter}
          portfolioId={queryObject.portfolioId}
          updateCanSearch={updateCanSearch}
          closePopover={closePopover}
        />
      )}
    </Popup>
  );
};

FilterPopover.propTypes = {
  isFilterOpen: PropTypes.bool,
  queryObject: PropTypes.object,
  searchObject: PropTypes.object,
  updateFilter: PropTypes.func,
  searchProperties: PropTypes.func,
  updateIsFilterOpen: PropTypes.func,
  searchTenants: PropTypes.func,
  updateCanSearch: PropTypes.func,
  closePopover: PropTypes.func,
  currentView: PropTypes.string
};

const mapStateToProps = ({ filters }) => ({
  isFilterOpen: filters.isFilterOpen,
  searchObject: filters.filters
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { updateFilter, searchProperties, updateIsFilterOpen, searchTenants },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(FilterPopover);
