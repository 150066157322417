import PropTypes from "prop-types";
import { connect } from "react-redux";
import cx from "classnames";

import AdminHeader from "./AdminHeader";
import Flash from "ui/components/notification/Flash";
import Breadcrumbs from "./Breadcrumbs";

import { hasAccess } from "helpers/authorization";

function AdminLayout({ children, className, breadcrumbs, title, role }) {
  return (
    <div className={cx("AdminLayout", className)}>
      <AdminHeader subheader={title} />
      <Flash />
      <div className="AdminLayout__body">
        {hasAccess(role) && <Breadcrumbs breadcrumbs={breadcrumbs} />}
        {!hasAccess(role) && publicFacing && (
          <Breadcrumbs
            breadcrumbs={[{ label: "Back to Login Page", path: "/login" }]}
          />
        )}
        {children}
      </div>
    </div>
  );
}

AdminLayout.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  breadcrumbs: PropTypes.array,
  title: PropTypes.string,
  role: PropTypes.string
};

function mapStateToProps({ currentUser }) {
  return {
    role: currentUser.role
  };
}

export default connect(mapStateToProps)(AdminLayout);
