import cx from "classnames";
import { useState } from "react";
import PropTypes from "prop-types";
import ColumnSideBar from "./components/ColumnSideBar/ColumnSideBar";
import useDocumentTables from "../../../../hooks/useDocumentTables";
import OpexColumnBaseTable from "../OpexBaseTable/OpexColumnBaseTable";
import useOpex from "ui/components/opex/hooks/useOpex";
import useDocument from "ui/components/opex/hooks/useDocument";
import OpexData from "../../modelOpexData";
import { FilterPopup } from "../FilterPopup/FilterPopup";
import CompletedStatus from "../CompletedStatus/CompletedStatus";
import OpexSplitPanel from "../OpexSplitPanel/OpexSplitPanel";
import ToggleSwitch from "ui/components/apps/ToggleSwitch";
import {
  CELL_STATUS_CORRECTED,
  CELL_STATUS_CORRECTED_COLOR,
  CONFIDENCE_CONFIRMED,
  CONFIDENCE_CONFIRMED_COLOR,
  CONFIDENCE_NEEDS_REVIEW,
  CONFIDENCE_NEEDS_REVIEW_COLOR,
  CONFIDENCE_UNMAPPED,
  CONFIDENCE_UNMAPPED_COLOR,
  getConfidenceStatus,
  isCellConfirmed,
  isCPMByGroupName,
  isCreditLensByGroupName
} from "ui/components/opex/shared";
import Button from "ui/components/shared/Button";
import { exportOpexExcel } from "server/services/opex/exportOpexExcel";
import moment from "moment";
import useOpexTemplates from "ui/components/opex/hooks/useOpexTemplates";
import { BsFillExclamationTriangleFill } from "react-icons/bs";
import { TemplateStatus } from "helpers/opex";
import { fetchPropertyInfo } from "ui/store/actions/properties";
import useOpexPanelContext from "ui/components/opex/hooks/useOpexPanelContext";

const isCellDisabled = ({ confidence, corrected }, filterValues) => {
  if (filterValues.length === 0) {
    return false;
  }

  if (corrected && filterValues.includes(CELL_STATUS_CORRECTED)) {
    return false;
  }

  if (
    isCellConfirmed(corrected) &&
    filterValues.includes(CONFIDENCE_CONFIRMED)
  ) {
    return false;
  }

  const confidenceStatus = getConfidenceStatus(confidence);
  if (
    confidenceStatus === CONFIDENCE_NEEDS_REVIEW &&
    filterValues.includes(CONFIDENCE_NEEDS_REVIEW)
  ) {
    return false;
  }

  if (
    confidenceStatus === CONFIDENCE_UNMAPPED &&
    filterValues.includes(CONFIDENCE_UNMAPPED)
  ) {
    return false;
  }

  return true;
};

const Columns = ({ currentTable, setStatusHeaderCounters, widgetAuth }) => {
  const {
    opex,
    groupName,
    updateCellValue,
    updatePeriod,
    mutate: mutateOpex
  } = useOpex();
  const defaultKpisFilterValues = ["totalIncome", "totalExpenses", "noi"];
  const { document, mutate: mutateDocument } = useDocument();
  const { documentTables } = useDocumentTables();
  const [filterValues, setFilterValues] = useState([]);
  const [kpiFilterValues, setKpiFilterValues] = useState(
    defaultKpisFilterValues
  );
  const [activeCellLocation, setActiveCellLocation] = useState(null);
  const [hideIgnoredColumns, setHideIgnoredColumns] = useState(false);
  const [activeRowLocation, setActiveRowLocation] = useState(null);
  const { getTemplateDetail } = useOpexTemplates(document.property_type);
  const { data: template } = getTemplateDetail(
    document.template_id,
    document.property_type
  );
  const { isWidgetAuthorized } = useOpexPanelContext();
  const confidenceFilterOptions = [
    {
      label: "Medium confidence",
      value: CONFIDENCE_NEEDS_REVIEW,
      squareColor: CONFIDENCE_NEEDS_REVIEW_COLOR
    },
    {
      label: "Needs review",
      value: CONFIDENCE_UNMAPPED,
      squareColor: CONFIDENCE_UNMAPPED_COLOR
    },
    {
      label: "Confirmed by user",
      value: CONFIDENCE_CONFIRMED,
      squareColor: CONFIDENCE_CONFIRMED_COLOR
    },
    {
      label: "Changed by user",
      value: CELL_STATUS_CORRECTED,
      squareColor: CELL_STATUS_CORRECTED_COLOR
    }
  ];
  const kpisFilterOptions = [
    { label: "Total Income", value: "totalIncome" },
    { label: "Total Expenses", value: "totalExpenses" },
    { label: "NOI", value: "noi" },
    { label: "Net Cashflow", value: "netCashFlow" },
    { label: "Total CapEx", value: "totalCapex" }
  ];

  const modelOpexData = new OpexData({ document, opex });

  async function refreshDocumentAndOpex() {
    await mutateDocument();
    await mutateOpex();
  }

  async function handleOnSubmitCell(nextValue, cellData) {
    const data = {
      new_value: nextValue,
      subcategory_id: cellData.parentId,
      period_id: cellData.periodId,
      location: cellData.location
    };

    // Reset active cell
    handleOnCancel();
    await updateCellValue(cellData.id, data);
    await refreshDocumentAndOpex();
  }

  async function handleOnSubmitPeriod(nextValues, columnData) {
    const data = {
      statement_type: nextValues.statementType,
      method_prepared: nextValues.methodPrepared,
      start_date: nextValues.dayjsStartDate?.format(),
      end_date: nextValues.dayjsEndDate?.format(),
      status: nextValues.periodStatus
    };

    await updatePeriod(columnData.opexData.id, data);
    await refreshDocumentAndOpex();
  }

  function handleOnCancel() {
    setActiveCellLocation(null);
    setActiveRowLocation(null);
  }

  function handleOnClickCell(cellData) {
    setActiveCellLocation(cellData?.location);
    setActiveRowLocation(cellData?.location.ri);
  }

  const getOptionCount = type => {
    switch (type) {
      case CELL_STATUS_CORRECTED:
        const correctedCount = modelOpexData.countCorrectedCellsByGroupIndex(0);
        setStatusHeaderCounters(counter =>
          counter[type] === correctedCount
            ? counter
            : { ...counter, [type]: correctedCount }
        );
        return correctedCount;
      case CONFIDENCE_CONFIRMED:
        const confirmedCount = modelOpexData.countConfirmedCellsByGroupIndex(0);
        setStatusHeaderCounters(counter =>
          counter[type] === confirmedCount
            ? counter
            : { ...counter, [type]: confirmedCount }
        );
        return confirmedCount;
      case CONFIDENCE_NEEDS_REVIEW:
        const needsReviewCount = modelOpexData.countNeedsReviewCellsByGroupIndex(
          0
        );
        setStatusHeaderCounters(counter =>
          counter[type] === needsReviewCount
            ? counter
            : { ...counter, [type]: needsReviewCount }
        );
        return needsReviewCount;
      case CONFIDENCE_UNMAPPED:
        const unmappedCount = modelOpexData.countUnmappedCellsByGroupIndex(0);
        setStatusHeaderCounters(counter =>
          counter[type] === unmappedCount
            ? counter
            : { ...counter, [type]: unmappedCount }
        );
        return unmappedCount;
      default:
        return "-";
    }
  };

  const disableCellIfFiltered = valuesInRawDataJson => {
    let disabled = false;

    if (valuesInRawDataJson?.confidence) {
      disabled = isCellDisabled(valuesInRawDataJson, filterValues);
    }

    return disabled;
  };

  const hasRequiredData = () => modelOpexData.hasGroups();
  const shouldShowOverlay =
    modelOpexData.isStatusCompleted() &&
    !isCPMByGroupName(groupName) &&
    !isWidgetAuthorized;

  const readOnlyCLWidget =
    modelOpexData.isStatusCompleted() &&
    isCreditLensByGroupName(groupName) &&
    isWidgetAuthorized;

  if (!hasRequiredData()) {
    return null;
  }

  const shouldShowPublishedDate =
    isCPMByGroupName(groupName) && modelOpexData.isStatusCompleted();

  const columnGridClassName = cx(
    "column-grid",
    shouldShowPublishedDate ? "cpm-completed" : ""
  );

  const getSelectedPerdiodMetrics = () => {
    return kpiFilterValues.map(kpiValue =>
      kpisFilterOptions.find(kpiOption => kpiOption.value === kpiValue)
    );
  };
  const filename = `op-stmt-${moment().format()}.xlsx`;
  return (
    <div className="OpexPanelView__DocumentView__Content Opex__Columns">
      <OpexSplitPanel>
        <div className="OpexPanelView__DocumentView__Content__Content">
          {modelOpexData.isPublished() &&
            template?.status === TemplateStatus.ARCHIVED && (
              <div className="WarningBanner">
                <BsFillExclamationTriangleFill />
                <span className="Message">
                  You can no longer edit this document, since the original
                  template has been archived.
                </span>
              </div>
            )}
          <div className={columnGridClassName}>
            {shouldShowPublishedDate && (
              <div className="column-published">
                <CompletedStatus
                  date={modelOpexData.getLastPublishedDate()}
                  text="Published on"
                  type="singleline"
                />
              </div>
            )}
            <div className="column-filters">
              <div className="OpexPanelView__Filters">
                <div className="OpexPanelView__Filter">
                  <FilterPopup
                    options={confidenceFilterOptions}
                    getOptionCount={getOptionCount}
                    onClearFilter={() => setFilterValues([])}
                    onFilter={setFilterValues}
                  />
                </div>
                <div className="OpexPanelView__Filter">
                  {isCPMByGroupName(groupName) && (
                    <FilterPopup
                      title="Select 3 KPIs"
                      defaultValues={kpiFilterValues}
                      options={kpisFilterOptions}
                      onFilter={setKpiFilterValues}
                      triggerLabel="Column Metrics"
                      triggerIcon={require("ui/images/icon-downarrow.svg")}
                      minSelection={3}
                      maxSelection={3}
                    />
                  )}
                </div>
                <div className="OpenPanelView__AccountsLeftPanel--toggle">
                  <span>Hide ignored columns</span>
                  <ToggleSwitch
                    isOn={hideIgnoredColumns}
                    className="ExcludedRowsToggle"
                    handleToggle={() =>
                      setHideIgnoredColumns(currentVal => !currentVal)
                    }
                  />
                </div>
                <div className="OpexPanelView__ExcelExport">
                  <Button
                    className={"Button__excelExportButton"}
                    onClick={async () => {
                      const property = await fetchPropertyInfo(
                        document.property_id,
                        widgetAuth
                      );
                      exportOpexExcel(opex, filename, groupName, property);
                    }}
                  >
                    Export to Excel
                  </Button>
                </div>
              </div>
            </div>
            <div className="column-content">
              <OpexColumnBaseTable
                template={template}
                document={document}
                data={opex}
                groupName={groupName}
                groupIndex={0} // always 0 until we add table merging
                hideIgnoredColumns={hideIgnoredColumns}
                onClickCell={handleOnClickCell}
                onSubmitCell={handleOnSubmitCell}
                onCancelCell={handleOnCancel}
                onSubmitPeriod={handleOnSubmitPeriod}
                beforeAddCell={disableCellIfFiltered}
                activeCellLocation={activeCellLocation}
                selectedPerdiodMetrics={getSelectedPerdiodMetrics()}
                readOnlyCLWidget={readOnlyCLWidget}
              />
            </div>
          </div>
        </div>
        <ColumnSideBar
          activeCellLocation={activeCellLocation}
          activeRowLocation={activeRowLocation}
          document={document}
          tables={documentTables}
          currentTable={currentTable}
        />
      </OpexSplitPanel>
    </div>
  );
};

export { isCellDisabled };
export default Columns;
Columns.propTypes = {
  document: PropTypes.object,
  opex: PropTypes.object,
  currentTable: PropTypes.number,
  setStatusHeaderCounters: PropTypes.func,
  widgetAuth: PropTypes.object
};
