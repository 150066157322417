export const fieldNames = {
  size_sqft: "Square Footage",
  deposit: "Deposit",
  lease_end_date: "Lease End Date",
  lease_start_date: "Lease Start Date",
  lease_dates: "Lease Dates",
  comments: "Comments",
  rent_status: "Status",
  lease_term: "Lease Term",
  space_type: "Space Type",
  tenant_name: "Tenant Name",
  annual_rent: "Annual Rent",
  monthly_rent: "Monthly Rent",
  annual_rent_per_sqft: "Annual Rent/SF",
  monthly_rent_per_sqft: "Monthly Rent/SF",
  unit_name: "Unit Name",
  actions: "Actions",
  is_vacant: "Is Vacant"
};

export function returnFieldNames(key) {
  const names = Object.keys(fieldNames);
  if (names.includes(key)) {
    return fieldNames[key];
  } else {
    return key;
  }
}
