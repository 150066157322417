import { USAGE_REPORT_SUCCESSFUL } from "../actions/usageAnalytics";

export default function usageAnalytics(
  state = {
    report: []
  },
  action
) {
  switch (action.type) {
    case USAGE_REPORT_SUCCESSFUL: {
      const { report } = action;
      return { ...state, report };
    }
    default: {
      return state;
    }
  }
}
