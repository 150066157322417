import { Component } from "react";
import PropTypes from "prop-types";
import BaseTable, { AutoResizer, Column } from "react-base-table";
import ReadOnlyCell from "./ReadOnlyCell";
import EditableCell from "./EditableCell";
import PeriodHeader from "./PeriodHeader";
import PeriodMetricsCell from "./PeriodMetricsCell";
import CellTriage from "./CellTriage";
import { unflatten } from "react-base-table";
import { PERIOD_STATUS_IGNORED } from "ui/components/opex/shared";
import OpexData from "../../modelOpexData";
import { TemplateStatus } from "helpers/opex";

const PERIOD_PREFIX = "period-";
const PERIOD_METRICS_ROW_ID = "period-metrics-";
const CATEGORY_PREFIX = "category-";
const COA_NAME_ID = "coa-name";
const ROW_PREFIX = "row-";

const COA_COLUMN_WIDTH = 275;
const PERIOD_COLUMN_WIDTH = 275;
const FIXED_COLUMN_WIDTH = 75;

class OpexColumnBaseTable extends Component {
  constructor(props) {
    super(props);
    this.defaultExpandedRowKeys = [];
    this.state = {
      modelOpexData: this.buildModelOpexData()
    };
  }

  componentDidUpdate(prevProps) {
    if (this.shouldUpdateStateModelOpexData(prevProps)) {
      const modelOpexData = this.buildModelOpexData();
      this.setState({ modelOpexData });
    }
  }

  shouldUpdateStateModelOpexData(prevProps) {
    const { document, data } = prevProps;

    const hasDocumentChanged =
      JSON.stringify(this.props.document) !== JSON.stringify(document);

    const hasOpexChanged =
      JSON.stringify(this.props.data) !== JSON.stringify(data);

    return hasDocumentChanged || hasOpexChanged;
  }

  buildModelOpexData() {
    return new OpexData({
      document: this.props.document,
      opex: this.props.data
    });
  }

  addDefaultExpandedRowKey(key) {
    this.defaultExpandedRowKeys.push(key);
  }

  getDefaultExpandedRowKeys() {
    return this.defaultExpandedRowKeys;
  }

  getBaseTablePeriodColumnsByGroupIndex(groupIndex) {
    const periods = this.state.modelOpexData.getPeriodsByGroupIndex(groupIndex);

    return periods
      .filter(
        period =>
          !this.props.hideIgnoredColumns ||
          period.periodStatus !== PERIOD_STATUS_IGNORED
      )
      .map(period => ({
        key: `${PERIOD_PREFIX}${period.id}`,
        dataKey: `${PERIOD_PREFIX}${period.id}`, // this is supposed help the cell value identify which column to go
        headerRenderer: PeriodHeader,
        cellRenderer: CellTriage,
        // flexGrow: 1, // https://autodesk.github.io/react-base-table/examples/flex-column
        width: PERIOD_COLUMN_WIDTH,
        opexData: { ...period, readOnlyCLWidget: this.props.readOnlyCLWidget },
        editDisabled: this.props.template?.status === TemplateStatus.ARCHIVED,
        groupName: this.props.groupName,
        onSubmit: this.props.onSubmitPeriod
      }));
  }

  getMetricsRowByGroupIndex(groupIndex) {
    const periods = this.state.modelOpexData.getPeriodsByGroupIndex(groupIndex);
    const row = {
      id: PERIOD_METRICS_ROW_ID
    };

    periods.forEach(period => {
      const { metrics } = period;
      const key = `${PERIOD_PREFIX}${period.id}`; // needs to be the same value as `dataKey` of the period
      row[key] = {
        metrics,
        disabled: this.state.modelOpexData.isPeriodIgnored(period.id)
      };
    });

    return row;
  }

  getDataRowsByGroupIndex(groupIndex) {
    const { beforeAddCell } = this.props;
    const group = this.state.modelOpexData.getDataByGroupIndex(groupIndex);
    const { tableData } = group;
    const accounts = this.state.modelOpexData.getItemsByType(
      tableData,
      "account"
    );
    const categories = this.state.modelOpexData.getItemsByType(
      tableData,
      "category"
    );
    const subcategories = this.state.modelOpexData.getItemsByType(
      tableData,
      "subcategory"
    );

    const accountsToRows = accounts.map(account => {
      const id = `${ROW_PREFIX}${account.id}`;
      const parentId = `${CATEGORY_PREFIX}${account.parentId}`;
      const periodValues = account.values.reduce((periodVals, period) => {
        const periodKey = `${PERIOD_PREFIX}${period.periodId}`;
        const valuesInRawDataJson = this.state.modelOpexData.getDocumentCellByOpexValue(
          period
        );
        const cell = {
          ...period,
          valuesInRawDataJson,
          parentId: account.parentId,
          triageCellRenderer:
            (this.state.modelOpexData.isPublished() &&
              this.props.template?.status === TemplateStatus.ARCHIVED) ||
            this.props.readOnlyCLWidget
              ? ReadOnlyCell
              : EditableCell,
          disabled:
            beforeAddCell?.(valuesInRawDataJson) ||
            this.state.modelOpexData.isPeriodIgnored(period.periodId),
          // if the period is ignored, disable the cell regardless
          activeCellLocation: this.props.activeCellLocation,
          onSubmit: this.props.onSubmitCell,
          onCancel: this.props.onCancelCell,
          onClick: this.props.onClickCell,
          onMouseEnterCell: this.props.onMouseEnterCell,
          onMouseLeaveCell: this.props.onMouseLeaveCell
        };

        return {
          ...periodVals,
          grayBg: account.grayBg,
          [periodKey]: cell
        };
      }, {});

      const row = {
        [COA_NAME_ID]: account.name,
        id,
        parentId,
        type: account.type,
        ...periodValues
      };

      return row;
    });

    const allCategories = categories.concat(
      subcategories.map(subCategory => ({
        ...subCategory,
        firstSubCat: categories.some(
          category => category.id === subCategory.parentId
        )
      }))
    );
    const performanceSummaryIds = [];
    const totalsRows = allCategories.map(category => {
      let periodsTotals = {};
      let grayBg = false;
      if (category.totals && category.totals.length) {
        if (category.name === "Performance Summary") {
          performanceSummaryIds.push(category.id);
        }
        periodsTotals = category.totals.reduce((periodsTotals, total) => {
          const periodKey = `${PERIOD_PREFIX}${total.periodId}`;
          return {
            ...periodsTotals,
            [periodKey]: {
              ...total,
              triageCellRenderer: ReadOnlyCell,
              disabled: this.state.modelOpexData.isPeriodIgnored(total.periodId)
            }
          };
        }, {});
      }
      const belongsToMainCategory = category.type === "category";
      const parentId = category.beyondLvlTwo
        ? `${CATEGORY_PREFIX}${category.id}`
        : !belongsToMainCategory
        ? `${CATEGORY_PREFIX}${category.parentId}`
        : "";

      if (category.beyondLvlTwo) {
        const accountBeforeTotal = accountsToRows
          .filter(accountRow => accountRow.parentId === parentId)
          .at(-1);
        grayBg = !accountBeforeTotal.grayBg;
      }
      const emptyTotals =
        performanceSummaryIds.includes(category.parentId) ||
        performanceSummaryIds.includes(category.id)
          ? true
          : !Object.keys(periodsTotals).length;

      return {
        [COA_NAME_ID]: `Total ${category.name}`,
        id: `${ROW_PREFIX}${category.id}`,
        belongsTo: `${CATEGORY_PREFIX}${category.id}`,
        totalsRow: true,
        type: category.type,
        grayBg: grayBg,
        beyondLvlTwo: category.beyondLvlTwo,
        emptyTotals: emptyTotals,
        ...(!!parentId && { parentId: parentId }),
        ...periodsTotals
      };
    });
    const allCategoriesToRows = allCategories.map(category => {
      const id = `${CATEGORY_PREFIX}${category.id}`;
      const row = {
        [COA_NAME_ID]: category.name,
        id,
        type: category.type,
        firstSubCat: category.firstSubCat
      };

      if (category.parentId) {
        row.parentId = `${CATEGORY_PREFIX}${category.parentId}`;
      }

      this.addDefaultExpandedRowKey(id);
      return row;
    });

    /*
      This will make sure to pair all categories with its totals
      This is done since the totals should not be hidden when the category is collapse
      unless they are beyond subcategory 2 and also because the total should be displayed with the same margin as the category
    */
    const categoriesWithTotalsRows = allCategoriesToRows.reduce(
      (rows, currRow) => {
        const totalsRow = totalsRows.find(
          totalsRow => totalsRow.belongsTo === currRow.id
        );
        const accounts = accountsToRows.filter(
          accountRow => accountRow.parentId === currRow.id
        );
        return [
          ...rows,
          currRow,
          ...(accounts.length ? accounts : []),
          ...(!totalsRow.emptyTotals ? [totalsRow] : [])
        ];
      },
      []
    );

    return categoriesWithTotalsRows;
  }

  buildTableHeaders(headerProps) {
    const { groupIndex } = this.props;
    const { cells, columns, headerIndex } = headerProps;
    const metricsRow = this.getMetricsRowByGroupIndex(groupIndex);

    // First row
    if (headerIndex === 0) {
      return cells;
    }
    // Second row
    else if (headerIndex === 1) {
      const groupCells = columns.reduce(
        (newCells, column, columnIndex) => [
          ...newCells,
          column?.opexData?.metrics ? (
            <div
              key={column.key}
              className="OpexBaseTable--PeriodMetricsCellContainer"
              style={{
                ...cells[columnIndex].props.style,
                width: `${cells[columnIndex].props.style.width}px`
              }}
            >
              <PeriodMetricsCell
                cellData={{
                  metrics: metricsRow[column.key].metrics,
                  disabled: metricsRow[column.key].disabled
                }}
                selectedPerdiodMetrics={this.props.selectedPerdiodMetrics}
              />
            </div>
          ) : (
            // This is to occupy the space created by frozen columns
            <div
              key={column.key}
              style={{
                ...cells[columnIndex].props.style,
                width: `${cells[columnIndex].props.style.width}px`
              }}
            ></div>
          )
        ],
        []
      );

      return groupCells;
    }
  }

  buildRowStyleClasses({ rowData }) {
    const boldFontClassName = rowData.type === "category" ? "BoldFont" : "";
    const lightGrayBgClassName =
      (rowData.totalsRow && !rowData.beyondLvlTwo) || rowData.grayBg
        ? "LightGrayBg"
        : "";
    const darkGrayBgClassName = rowData.firstSubCat ? "DarkGrayBg" : "";
    const defaultBgClassName =
      !darkGrayBgClassName && !lightGrayBgClassName ? "Default" : "";

    return `${boldFontClassName} ${lightGrayBgClassName} ${darkGrayBgClassName} ${defaultBgClassName}`;
  }

  /**
   * @returns properties you can spread `...` directly on to the <BaseTable> component
   */
  buildBaseTableProps() {
    const { groupIndex } = this.props;

    let columns = [];

    const chartOfAccountsColumn = {
      key: COA_NAME_ID,
      dataKey: COA_NAME_ID,
      title: "Chart of Accounts",
      width: COA_COLUMN_WIDTH
    };
    const fixedLastColumn = {
      key: "foo",
      dataKey: "foo",
      title: "",
      width: FIXED_COLUMN_WIDTH
    };

    const periodColumns = this.getBaseTablePeriodColumnsByGroupIndex(
      groupIndex
    );

    columns.push(chartOfAccountsColumn);
    columns = columns.concat(periodColumns);
    columns.push(fixedLastColumn);
    columns = columns.map((column, columnIndex) => {
      let frozen;
      let className = "";
      if (columnIndex === 0) {
        frozen = Column.FrozenDirection.LEFT;
      } else if (columnIndex === columns.length - 1) {
        frozen = Column.FrozenDirection.RIGHT;
      } else {
        className = "NoFrozenCell";
      }
      return { ...column, frozen, className };
    });

    const dataRows = this.getDataRowsByGroupIndex(groupIndex);
    // basetable api properties
    // https://autodesk.github.io/react-base-table/api/basetable
    return {
      columns,
      fixed: true,
      data: unflatten(dataRows),
      defaultExpandedRowKeys: this.getDefaultExpandedRowKeys(),
      expandColumnKey: COA_NAME_ID,
      rowClassName: this.buildRowStyleClasses,
      //[headerHeight] The array's length determines the number of rows the header will have
      headerHeight: [50, 100],
      headerRenderer: this.buildTableHeaders.bind(this)
    };
  }

  render() {
    const { data, ...baseTableProps } = this.buildBaseTableProps();

    return (
      <AutoResizer>
        {({ width, height }) => (
          <BaseTable
            width={width}
            height={height}
            data={data}
            {...baseTableProps}
          />
        )}
      </AutoResizer>
    );
  }
}

export default OpexColumnBaseTable;

OpexColumnBaseTable.propTypes = {
  template: PropTypes.object,
  groupName: PropTypes.string, // user company.group_name from widgetAuth
  document: PropTypes.object, // document so you can look up cell confidence since cell confidence is stored in raw_data_json
  data: PropTypes.object, // `get-opex` data
  groupIndex: PropTypes.number,
  tableIndex: PropTypes.number,
  hideIgnoredColumns: PropTypes.bool,
  onClickCell: PropTypes.func,
  onSubmitCell: PropTypes.func,
  onCancelCell: PropTypes.func,
  onMouseEnterCell: PropTypes.func,
  onMouseLeaveCell: PropTypes.func,
  onSubmitPeriod: PropTypes.func,
  beforeAddCell: PropTypes.func, // fn to run before adding a cell. useful to add additional properties
  selectedPerdiodMetrics: PropTypes.array,
  activeCellLocation: PropTypes.object, // { pi, ti, ci, ri }
  readOnlyCLWidget: PropTypes.bool
};
