import PropTypes from "prop-types";
import { connect } from "react-redux";

import { OpexPanelContextProvider } from "../hooks/useOpexPanelContext";
import AuthenticatedRoute from "ui/components/routing/AuthenticatedRoute";
import OpexPanelView from "ui/components/opex/opexPanel/OpexPanelView";

const OpexPanel = () => {
  return (
    <OpexPanelContextProvider>
      <OpexPanelView />
    </OpexPanelContextProvider>
  );
};

OpexPanel.propTypes = {
  widgetAuth: PropTypes.object
};

const mapStateToProps = ({ currentUser }) => ({
  widgetAuth: currentUser.widgetAuth
});

export default AuthenticatedRoute(connect(mapStateToProps)(OpexPanel));
