export const deepCopy = obj => {
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  let copy;
  if (Array.isArray(obj)) {
    copy = [...obj].map(elem => deepCopy(elem));
  } else {
    copy = { ...obj };
    for (const [key, value] of Object.entries(obj)) {
      copy[key] = deepCopy(value);
    }
  }

  return copy;
};

export const deepEqual = (obj1, obj2) => {
  if (obj1 === obj2) return true;
  if (obj1 === null || obj2 === null) {
    return false;
  }
  if (typeof obj1 !== "object" || typeof obj2 !== "object") return false;

  let keys1 = Object.keys(obj1);
  let keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) return false;
  }

  return true;
};
