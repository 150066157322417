import PropTypes from "prop-types";
import { getPropertyValues } from "ui/components/properties/propertiesUtil";
import moment from "moment";
import Popup from "reactjs-popup";
import EditInfo from "ui/components/admin/templates/details/EditInfo";
import { FaPencilAlt } from "react-icons/fa";

export default function Info({ info, updateInfo, isCL }) {
  return (
    <div className="template-details">
      <div className="template-details__header">
        <span>Details</span>
        {info.status !== "PUBLISHED" && info.status !== "ARCHIVED" && !isCL && (
          <Popup
            modal
            className="template-details__header__centered-popover"
            trigger={
              <FaPencilAlt className="template-details__header__editButton" />
            }
            position="right center"
            closeOnDocumentClick
          >
            {// @ts-ignore
            close => (
              <EditInfo
                onClose={close}
                info={info}
                onSave={data => updateInfo(data)}
                isCL={isCL}
              />
            )}
          </Popup>
        )}
      </div>

      <div className="template-details__body">
        <div className="template-details__body__top">
          <div className="template-details__body__top__side">
            <p className="template-details__body__title">Name</p>
            <p>{info.name || "New template"}</p>
          </div>
          <div className="template-details__body__top__side">
            <p className="template-details__body__title">Property Type</p>
            <div className="template-details__body__tags">
              {info.property_types && info.property_types.length > 0 ? (
                info.property_types.map(type => (
                  <div key={type} className="template-details__body__tags__tag">
                    {
                      getPropertyValues(isCL).find(ptype => ptype.id === type)
                        .name
                    }
                  </div>
                ))
              ) : (
                <p>Not defined</p>
              )}
            </div>
          </div>
        </div>
        <div className="template-details__body__description">
          <p className="template-details__body__title">Description</p>
          <p>{info.description || "There is not description available."}</p>
        </div>
        <hr />
        <div className="template-details__body__bottom">
          <div className="template-details__body__bottom__side">
            <p className="template-details__body__title">Date of creation </p>
            <p>{moment(info.created_at).format("MM/DD/YYYY")}</p>
          </div>
          <div className="template-details__body__bottom__side">
            <p className="template-details__body__title">Created by</p>
            <p>{info.created_by?.email}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
Info.propTypes = {
  info: PropTypes.object.isRequired,
  updateInfo: PropTypes.func.isRequired,
  isCL: PropTypes.bool
};
