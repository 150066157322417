import { useState } from "react";
import PropTypes from "prop-types";
import Popup from "reactjs-popup";
import IconButton from "ui/components/shared/IconButton";
import Button from "ui/components/shared/Button";
import PropertiesForm from "ui/components/properties/PropertiesForm";
import { propertyActions } from "ui/components/properties/helpers/propertiesList";

const PropertyPopup = ({
  onNext,
  onSubmit,
  propertyData,
  isLoadingReisProperty,
  resetSearch,
  action,
  canUpdatePropertyType
}) => {
  const [isOpen, updateIsOpen] = useState(false);

  function onSubmission(property) {
    onSubmit(property);
    updateIsOpen(false);
  }

  const buttonLabel = action => {
    switch (action) {
      case propertyActions.add:
        return "+ Add Property";
      case propertyActions.edit:
        return "Edit Property";
    }
  };
  return (
    <Popup
      trigger={
        <Button
          className="Button__blueButton"
          onClick={() => updateIsOpen(true)}
        >
          {" "}
          {buttonLabel(action)}
        </Button>
      }
      modal
      position="top left"
      open={isOpen}
      onClose={() => updateIsOpen(false)}
      onOpen={() => updateIsOpen(true)}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: "white",
          border: "1.5px solid grey",
          justifyContent: "space-between"
        }}
      >
        <PropertiesForm
          onNext={onNext}
          onSubmit={onSubmission}
          propertyData={propertyData}
          isLoadingReisProperty={isLoadingReisProperty}
          isModalOpen={isOpen}
          resetSearch={resetSearch}
          action={action}
          canUpdatePropertyType={canUpdatePropertyType}
        />
        <IconButton
          className="close-button"
          onClick={() => updateIsOpen(false)}
          src={require("ui/images/x-icon.svg")}
        />
      </div>
    </Popup>
  );
};

PropertyPopup.defaultProps = {
  canUpdatePropertyType: true
};

PropertyPopup.propTypes = {
  onNext: PropTypes.func,
  onSubmit: PropTypes.func,
  propertyData: PropTypes.object,
  isLoadingReisProperty: PropTypes.bool,
  resetSearch: PropTypes.func,
  action: PropTypes.string,
  canUpdatePropertyType: PropTypes.bool
};

export default PropertyPopup;
