import { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ItemForm from "./ItemForm";
import DropDown from "ui/components/shared/dropdowns/DropDown";
import { getPropertyValues } from "ui/components/properties/propertiesUtil";
import Button from "ui/components/shared/Button";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import cx from "classnames";

import SpiralSpinner from "ui/components/spinners/SpiralSpinner";
import { propertyActions } from "ui/components/properties/helpers/propertiesList";
import { isCreditLensByGroupName } from "ui/components/opex/shared";

const PropertiesSecondForm = ({
  setForm,
  formData,
  navigation,
  onSubmit,
  selCountry,
  selState,
  selectCountry,
  selectRegion,
  childToEditProperty,
  changePage,
  onSubmitAttachDoc,
  action,
  canUpdatePropertyType,
  groupName
}) => {
  const {
    name,
    property_type,
    parcel_number,
    floors,
    year_built,
    size_sqft,
    street_1,
    street_2,
    city,
    postal_code
  } = formData;
  const fields = [
    "name",
    "property_type",
    "parcel_number",
    "floors",
    "year_built",
    "size_sqft"
  ];
  const { previous } = navigation;
  const [isSubmit, setIsSubmit] = useState(false);
  const isCL = isCreditLensByGroupName(groupName);

  function clearSecondFormFields() {
    fields.forEach(key => {
      setForm({
        target: {
          name: key,
          value: ""
        }
      });
    });
  }

  function onSubmitClick() {
    if (childToEditProperty) {
      onSubmitAttachDoc(formData);
    } else {
      onSubmit(formData);
    }
  }

  function OnBack() {
    clearSecondFormFields();
    previous();
  }

  return (
    <form style={{ width: "100%" }}>
      <div className="Properties-second-form">
        <div className="Properties-first-form__header">
          {action === propertyActions.edit ? "Edit" : "Add"} property
        </div>
        <div className="Properties-second-form__fillable">
          <ItemForm
            label="Address Line 1"
            name="street_1"
            value={street_1}
            onChange={setForm}
            placeholder="Street"
          />
          <ItemForm
            label="Address Line 2"
            name="street_2"
            value={street_2}
            onChange={setForm}
            placeholder="Apt, floor, etc"
          />
          <div className="Properties-first-form__city_state_zip_grid">
            <ItemForm
              label="City"
              name="city"
              value={city}
              onChange={setForm}
              placeholder="City"
            />
            <ItemForm label="State" type="custom">
              <RegionDropdown
                defaultOptionLabel="State"
                country={formData.country}
                value={selState}
                labelType="short"
                valueType="short"
                onChange={val => selectRegion(val)}
                name="state"
                style={{
                  width: "100%",
                  height: "38px",
                  borderColor: "#FFFFFF"
                }}
              />
            </ItemForm>
            <ItemForm
              label="Zip Code"
              name="postal_code"
              value={postal_code}
              onChange={setForm}
              placeholder="Zip Code"
            />
          </div>
          <ItemForm label="Country" type="custom">
            <CountryDropdown
              name="country"
              showDefaultOption={true}
              value={selCountry}
              onChange={val => selectCountry(val)}
              style={{
                borderColor: "#FFFFFF",
                width: "100%",
                height: "38px"
              }}
            />
          </ItemForm>

          <ItemForm
            label="Name"
            name="name"
            value={name}
            onChange={setForm}
            placeholder="Property Name"
          />
          <ItemForm label="Property Type *" type="custom">
            <DropDown
              label="Property Type"
              name="property_type"
              value={property_type}
              values={getPropertyValues(isCL)}
              onChange={setForm}
              defaultValue={formData.property_type}
              disabled={!canUpdatePropertyType}
            />
          </ItemForm>
          <div className="Properties-second-form__details">
            <ItemForm
              label="Square Footage"
              name="size_sqft"
              value={size_sqft}
              onChange={setForm}
              type="number"
              placeholder="Sq. footage"
            />
            <ItemForm
              label="Year Built"
              name="year_built"
              value={year_built}
              onChange={setForm}
              type="number"
              placeholder="Year"
            />
          </div>
          <div className="Properties-second-form__details">
            <ItemForm
              label="Floors"
              name="floors"
              value={floors}
              onChange={setForm}
              type="number"
              placeholder="# Floors"
            />
            <ItemForm
              label="Parcel Number"
              name="parcel_number"
              value={parcel_number}
              onChange={setForm}
              placeholder="Ex. 555000555"
            />
          </div>
        </div>
        <div className="Properties-second-form__Nav">
          {childToEditProperty && (
            <Button
              className="Button__blackButton"
              onClick={() => changePage("main")}
            >
              Cancel
            </Button>
          )}
          <Button
            className={cx(
              "Properties-second-form__previous-button",
              "Button__blueButton"
            )}
            onClick={() => OnBack()}
          >
            Back
          </Button>
          {isSubmit ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <SpiralSpinner style={{ marginRight: "5px" }} />
              Updating
            </div>
          ) : (
            formData.property_type && (
              <Button
                className={cx(
                  "Properties-second-form__previous-button",
                  "Button__blueSolidButton"
                )}
                onClick={async e => {
                  e.preventDefault();
                  setIsSubmit(true);
                  await onSubmitClick();
                }}
              >
                Submit
              </Button>
            )
          )}
        </div>
      </div>
    </form>
  );
};

PropertiesSecondForm.propTypes = {
  setForm: PropTypes.func,
  formData: PropTypes.object,
  navigation: PropTypes.object,
  onSubmit: PropTypes.func,
  selCountry: PropTypes.string,
  selState: PropTypes.string,
  selectCountry: PropTypes.func,
  selectRegion: PropTypes.func,
  childToEditProperty: PropTypes.bool,
  changePage: PropTypes.func,
  onSubmitAttachDoc: PropTypes.func,
  action: PropTypes.string,
  canUpdatePropertyType: PropTypes.bool,
  groupName: PropTypes.string
};

function mapStateToProps(state) {
  return {
    groupName: state.currentUser.company.group_name
  };
}
export default connect(mapStateToProps)(PropertiesSecondForm);
