export function validateCustomFormData(allFields) {
  const allTerms = allFields.map(field => field.term);
  const allDisplayTerms = allFields.map(field => field.displayTerm);
  const hasDuplicates = new Set(allTerms).size !== allTerms.length;

  if (hasDuplicates) {
    return { isValid: false, error: "has-duplicates" };
  }

  for (let displayTerm of allDisplayTerms) {
    if (displayTerm === "") {
      return { isValid: false, error: "empty-term" };
    }
  }

  for (let term of allTerms) {
    if (term === "") {
      return { isValid: false, error: "empty-term" };
    }

    if (/[^a-zA-Z\d\_]/g.test(term)) {
      return { isValid: false, error: "term-convention" };
    }

    const termArray = term.split("");
    for (let character of termArray) {
      if (/[A-Z]/.test(character)) {
        return { isValid: false, error: "term-convention" };
      }
    }
  }

  return { isValid: true };
}

export const errorFlashMessage = {
  "has-duplicates": {
    type: "error",
    message: "There are duplicate Terms."
  },
  "empty-term": {
    type: "error",
    message: "There is a row in the form with an empty term input."
  },
  "term-convention": {
    type: "error",
    message:
      "A term does not match the term convention (ex: snake_case NOT SNake-CAse)."
  }
};
