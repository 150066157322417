import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Tab, Tabs, TabList } from "react-tabs";
import {
  searchProperties,
  addProperty,
  fetchPropertyFromReisNetwork,
  clearSelectedPortfolioObj,
  clearMetricsObj,
  clearPropertiesObj,
  resetSearch
} from "ui/store/actions/properties";
import { resetFilters } from "ui/store/actions/filters";
import { originalFilters } from "ui/store/reducers/filters";
import AuthenticatedRoute from "ui/components/routing/AuthenticatedRoute";
import HeaderWithSidebar from "ui/components/shared/HeaderWithSidebar";
import HeaderNavTabs from "ui/components/shared/HeaderNavTabs";
import NavLink from "ui/components/shared/NavLink.jsx";
import PropertiesList from "ui/components/properties/PropertiesList";
import PropertiesAnalytics from "./PropertiesAnalytics";
import PropertyPopup from "ui/components/properties/PropertyPopup";
import FilterPopover from "ui/components/filters/FilterPopover";
import FilterBreadCrumbs from "ui/components/filters/FilterBreadCrumbs";
const queryString = require("query-string");
import { searchPageSize } from "ui/helpers/search";
import { propertyActions } from "ui/components/properties/helpers/propertiesList";

class PropertiesView extends Component {
  constructor() {
    super();
    this.state = {
      updateSearchProperties: false,
      pageIdx: 1
    };
  }

  componentDidMount() {
    this.refreshPreview();
  }

  componentDidUpdate(prevProps, prevState) {
    const { filters, searchProperties } = this.props;
    const {
      updateSearchProperties,
      pageIdx,
      sortColumnName,
      sortOrder
    } = this.state;
    if (filters !== prevProps.filters && updateSearchProperties) {
      const queryObject = queryString.parse(location.search);
      const portfolioId = queryObject.portfolioId;
      searchProperties(portfolioId, filters, false, {
        pagination: { property: 0 },
        sort: { field: sortColumnName, order: sortOrder }
      });
      this.setUpdateSearchProperties(false);
    } else if (
      (pageIdx !== prevState.pageIdx ||
        sortColumnName !== prevState.sortColumnName ||
        sortOrder !== prevState.sortOrder) &&
      updateSearchProperties
    ) {
      const queryObject = queryString.parse(location.search);
      const portfolioId = queryObject.portfolioId;
      searchProperties(portfolioId, filters, false, {
        pagination: { property: pageIdx - 1 },
        sort: { field: sortColumnName, order: sortOrder }
      });
      this.setUpdateSearchProperties(false);
    }
  }

  componentWillUnmount() {
    const {
      clearSelectedPortfolio,
      clearMetrics,
      clearProperties
    } = this.props;
    clearSelectedPortfolio();
    clearMetrics();
    clearProperties();
  }

  refreshPreview = () => {
    const {
      searchProperties,
      filters: searchObject,
      activeFilterList
    } = this.props;
    const queryObject = queryString.parse(location.search);
    if (activeFilterList.length === 0) {
      searchProperties(queryObject.portfolioId, searchObject, true, {
        pagination: { property: 0 }
      });
    } else {
      searchProperties(queryObject.portfolioId, searchObject, false, {
        pagination: { property: 0 }
      });
    }
  };

  setUpdateSearchProperties = bool => {
    this.setState({ updateSearchProperties: bool });
  };

  addProperty = async property => {
    const { createProperty } = this.props;
    await createProperty(
      property,
      this.props.selectedPortfolio.id,
      false,
      null,
      true
    );
  };

  getReisNetworkProperty = location => {
    const { getPropertyFromReisNetwork } = this.props;
    getPropertyFromReisNetwork(location);
  };

  getNav() {
    return (
      <div>
        <NavLink
          className="HeaderWithSidebar__back-button-nav"
          to={{ pathname: `/portfolios` }}
        >
          <img
            className="HeaderWithSidebar__back-button-img"
            src={require("ui/components/shared/images/arrows/LeftArrow.png")}
          />
        </NavLink>
      </div>
    );
  }

  getPropertiesHeader = portfolioName => {
    return (
      <div className="HeaderWithSidebar__header-components">
        {this.getNav()}
        <div>
          <h3 className="HeaderWithSidebar__title-name">{portfolioName}</h3>
        </div>
      </div>
    );
  };

  clearFilters = () => {
    const { resetFilters, searchProperties } = this.props;
    const queryObject = queryString.parse(location.search);
    const portfolioId = queryObject.portfolioId;
    resetFilters();
    searchProperties(portfolioId, originalFilters, true, {
      pagination: { property: 0 }
    });
  };

  getAddPropertyModal = () => {
    return (
      <div className="Properties__buttons">
        <PropertyPopup
          onNext={this.getReisNetworkProperty}
          onSubmit={this.addProperty}
          propertyData={this.props.reisProperty}
          isLoadingReisProperty={this.props.isLoadingReisProperty}
          resetSearch={this.props.resetSearch}
          action={propertyActions.add}
        />
      </div>
    );
  };

  isAnyFilterOn = () => {
    let bool = false;
    if (this.props.filters) {
      Object.keys(this.props.filters).forEach(filterType => {
        if (this.props.filters[filterType]) {
          bool = true;
        }
      });
    }
    return bool;
  };

  customNavTab = destination => {
    const {
      match: {
        params: { portfolioId }
      }
    } = this.props;
    return (
      <NavLink
        className="action-nav"
        to={{
          pathname: `/${destination}/${portfolioId}`
        }}
      >
        <Tab>{destination}</Tab>
      </NavLink>
    );
  };

  getNavTabs = portfolioId => {
    return (
      <Tabs>
        <TabList>
          <NavLink
            className="action-nav"
            to={{
              pathname: "/properties",
              search: `?portfolioId=${portfolioId}`
            }}
            style={{
              textDecoration: "none"
            }}
          >
            <Tab>PROPERTIES</Tab>
          </NavLink>
          <NavLink
            className="action-nav"
            to={{
              pathname: "/tenants",
              search: `?portfolioId=${portfolioId}`
            }}
            style={{
              textDecoration: "none"
            }}
          >
            <Tab>TENANTS</Tab>
          </NavLink>
        </TabList>
      </Tabs>
    );
  };

  updatePagination = newPageIdx => {
    this.setState({ pageIdx: newPageIdx, updateSearchProperties: true });
  };

  handleSortChange = (columnName, order) => {
    const { pageIdx } = this.state;
    this.setState({
      sortColumnName: columnName,
      sortOrder: order,
      pageIdx: pageIdx,
      updateSearchProperties: true
    });
  };

  render() {
    const {
      properties,
      metrics,
      filters,
      selectedPortfolio,
      excelTenants,
      status
    } = this.props;
    const queryObject = queryString.parse(this.props.location.search);
    const { pageIdx, sortColumnName, sortOrder } = this.state;
    let totalPages = 1;
    if (
      metrics &&
      metrics.facet_property &&
      metrics.facet_property.facet &&
      metrics.facet_property.facet.total_count &&
      metrics.facet_property.facet.total_count.value
    ) {
      totalPages = Math.ceil(
        metrics.facet_property.facet.total_count.value / searchPageSize
      );
    }

    return (
      <HeaderWithSidebar
        className="Properties__body"
        button={selectedPortfolio ? this.getAddPropertyModal() : ""}
        subHeader={
          selectedPortfolio
            ? this.getPropertiesHeader(selectedPortfolio.name)
            : ""
        }
      >
        <div className="Properties__components">
          <div className="Properties__filterPanel" id="popup-root">
            {status === "LOADED" && (
              <FilterPopover
                queryObject={queryObject}
                currentView="properties"
                updateCanSearch={this.setUpdateSearchProperties}
              />
            )}
            <FilterBreadCrumbs
              updateCanSearch={this.setUpdateSearchProperties}
            />
          </div>
          <div className="Properties__analytics">
            <PropertiesAnalytics
              metrics={metrics}
              filter={this.isAnyFilterOn()}
            />
          </div>
          <div className="Properties__grid">
            <HeaderNavTabs
              portfolioId={queryObject.portfolioId}
              selectedIndex={0}
            />
            <PropertiesList
              filters={filters}
              properties={properties}
              portfolioId={queryObject.portfolioId}
              applyFilter={this.applyFilter}
              removeFilter={this.removeFilter}
              excelTenants={excelTenants}
              pageIdx={pageIdx}
              totalPages={totalPages}
              updatePagination={this.updatePagination}
              handleSortChange={this.handleSortChange}
              sortColumnName={sortColumnName}
              sortOrder={sortOrder}
              status={status}
            />
          </div>
        </div>
      </HeaderWithSidebar>
    );
  }
}

PropertiesView.propTypes = {
  properties: PropTypes.array,
  match: PropTypes.object,
  refreshPreview: PropTypes.func,
  searchProperties: PropTypes.func,
  createProperty: PropTypes.func,
  selectedPortfolio: PropTypes.object,
  getPropertyFromReisNetwork: PropTypes.func,
  reisProperty: PropTypes.object,
  isLoadingReisProperty: PropTypes.bool,
  resetSearch: PropTypes.func,
  metrics: PropTypes.object,
  filters: PropTypes.object,
  updateFilter: PropTypes.func,
  resetFilters: PropTypes.func,
  excelTenants: PropTypes.array,
  activeFilterList: PropTypes.array,
  location: PropTypes.object,
  handleSortChange: PropTypes.func,
  clearSelectedPortfolio: PropTypes.func,
  clearMetrics: PropTypes.func,
  clearProperties: PropTypes.func,
  status: PropTypes.string
};

//update the state
function mapStateToProps(state) {
  return {
    properties: state.properties.properties,
    excelTenants: state.tenants.excelTenants,
    metrics: state.properties.metrics,
    selectedPortfolio: state.properties.selectedPortfolio,
    reisProperty: state.properties.reisProperty,
    isLoadingReisProperty: state.properties.isLoadingReisProperty,
    isFilterPanelVisible: state.filters.isFilterPanelVisible,
    filters: state.filters.filters,
    activeFilterList: state.filters.activeFilterList,
    status: state.properties.status
  };
}

function mapDispatchToProps(dispatch) {
  return {
    searchProperties: function(
      portfolioId,
      searchObject,
      setFilters,
      pagination
    ) {
      dispatch(
        searchProperties(portfolioId, searchObject, setFilters, pagination)
      );
    },
    createProperty: (property, portfolioId) => {
      dispatch(addProperty(property, portfolioId));
    },
    getPropertyFromReisNetwork: function(location) {
      dispatch(fetchPropertyFromReisNetwork(location));
    },
    resetSearch: function() {
      dispatch(resetSearch());
    },
    resetFilters: function() {
      dispatch(resetFilters());
    },
    clearSelectedPortfolio: () => dispatch(clearSelectedPortfolioObj()),
    clearMetrics: () => dispatch(clearMetricsObj()),
    clearProperties: () => dispatch(clearPropertiesObj())
  };
}

export default AuthenticatedRoute(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(PropertiesView))
);
