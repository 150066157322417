import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import AccountMenu from "ui/components/account/AccountMenu";
import NavLink from "ui/components/shared/NavLink.jsx";

// import NavLink from "ui/components/shared/NavLink";
// import { hasAccess } from "helpers/authorization";

class AdminHeader extends Component {
  render() {
    return (
      <nav className="AdminHeader">
        <div className="AdminHeader__header">
          <NavLink to={{ pathname: "/manage-files-view" }}>
            <img
              className="AdminHeader__headerMainLogo"
              src={require("ui/images/quiq-property-logo-white-256px.svg")}
              alt="logo"
            />
          </NavLink>

          <div className="AdminHeader__right-nav">
            <AccountMenu className="AdminHeader" inAdminView={true} />
            {/* {hasAccess(role) && (
            <NavLink
              className="AdminHeader__button Button Button--action"
              to="/login"
            >
              To Login
            </NavLink>
          )} */}
          </div>
        </div>
      </nav>
    );
  }
}

AdminHeader.propTypes = {
  role: PropTypes.string,
  subheader: PropTypes.string
};

function mapStateToProps({ currentUser }) {
  return {
    role: currentUser.role
  };
}

export default connect(mapStateToProps)(AdminHeader);
