import PropTypes from "prop-types";
import { connect } from "react-redux";
import SplitPanel from "./SplitPanel";
import SplitPanelV2 from "../splitPanelV2/SplitPanelV2";
import { SplitPanelV2ContextProvider } from "../splitPanelV2/hooks/useSplitPanelV2Context";

function SplitPanelVersion({ location: { search }, authenticated }) {
  const isVersionOne = checkVersion({ search, version: 1 });

  if (authenticated && isVersionOne) {
    return <SplitPanel />;
  }

  return (
    <SplitPanelV2ContextProvider>
      <SplitPanelV2 />
    </SplitPanelV2ContextProvider>
  );
}

SplitPanelVersion.propTypes = {
  location: PropTypes.object,
  role: PropTypes.string,
  authenticated: PropTypes.bool,
  widgetAuth: PropTypes.object
};

function checkVersion({ search, version }) {
  if (!search) return false;

  const parts = search.replace("?", "").split("&");
  const part = parts.find(part => part.indexOf("version=") === 0) || "";
  return parseInt(part.split("=").pop()) === version;
}

const mapStateToProps = ({ currentUser, authenticated }) => ({
  role: currentUser.role,
  authenticated,
  widgetAuth: currentUser.widgetAuth
});

export default connect(mapStateToProps)(SplitPanelVersion);
