import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { parseLeaseDate } from "server/middleware/api/helpers/parsingHelper";
import { PERIOD_DATE_FORMAT } from "ui/components/helpers/dayjs";
import { DatePicker } from "antd";
import dayjs from "dayjs";
function NewDataCell({
  column: {
    dataKey,
    rowFormData,
    setRowFormData,
    columnInfo,
    selectedRentRollCell
  },
  rowData,
  isCurrency,
  setSelectedRentRollCell,
  columnIndex,
  rowIndex
}) {
  const [cellValue, setCellValue] = useState(
    typeof rowFormData[dataKey] === "string"
      ? rowFormData[dataKey]
      : rowFormData[dataKey]?.text
  );

  const [refRentRollKey, setRefKey] = useState("0-0");
  useEffect(() => {
    if (rowData[dataKey]?.rentRollKey) {
      setRefKey(rowData[dataKey].rentRollKey);
    } else if (rowFormData[dataKey]?.rentRollKey) {
      setRefKey(rowFormData[dataKey].rentRollKey);
    } else {
      setRefKey(`${rowIndex}-${columnIndex}`);
    }
  }, [rowFormData, rowData]);

  const inputMap = (dataKey, type) => {
    const inputType = {
      numeric: "number",
      "character varying": "text"
    };
    return isCurrency(dataKey) ? "number" : inputType[type];
  };

  const [cellInputType, setCellInputType] = useState(
    inputMap(dataKey, columnInfo.columnInfo[dataKey]?.type) || "text"
  );
  const [isEditing, setIsEditing] = useState(false);
  useEffect(() => {
    if (selectedRentRollCell === refRentRollKey) {
      setIsEditing(true);
    }
  }, [selectedRentRollCell]);

  const isDateCell = dataKey => {
    if (dataKey === "lease_start_date") return true;
    if (dataKey === "lease_end_date") return true;
    return false;
  };

  const handleInputChange = e => {
    const txt = e.target.value;
    setCellValue(txt);
    // setRowFormData(rowFormData => ({ ...rowFormData, [dataKey]: txt }));
  };

  const handleDateUpdate = value => {
    let date = cleanInput(readDate(value));
    setCellValue(date);
    setRowFormData(rowFormData => ({
      ...rowFormData,
      [dataKey]: { ...rowFormData[dataKey], text: date }
    }));
  };

  const readDate = dateString => {
    if (dateString) {
      return dayjs(dateString).format(PERIOD_DATE_FORMAT);
    } else {
      return "";
    }
  };

  const handleKeyDown = e => {
    // HTML tree is out of order making the defaut Tab
    // behavior unintuitve. This forces Tab and Shit+Tab to jump
    // from tenant_name to next column and back.
    let [row, col] = refRentRollKey.split("-");
    if (e.key === "Tab" && !e.shiftKey && col === "0") {
      e.preventDefault();
      e.target.blur();
      const nextCell = `${row}-${parseInt(col) + 1}`;
      setSelectedRentRollCell(nextCell);
      document.getElementById(nextCell).focus();
      return;
    }
    if (e.key === "Tab" && e.shiftKey && col === "1") {
      e.preventDefault();
      e.target.blur();
      const prevCell = `${row}-${parseInt(col) - 1}`;
      setSelectedRentRollCell(prevCell);
      document.getElementById(prevCell).focus();
      return;
    }
    if (e.key === "Enter") {
      e.preventDefault();
      setCellValue(cleanInput(e.target.value));
      e.target.blur();
      return;
    }
  };

  const handleBlur = e => {
    // needed to save last charater of typed input
    const txt = cleanInput(e.target.value);
    setCellValue(txt);
    setRowFormData(rowFormData => ({
      ...rowFormData,
      [dataKey]: { ...rowFormData[dataKey], text: txt }
    }));
    setIsEditing(false);
  };

  const cleanInput = input => {
    if (input === "" || input === undefined) {
      return "";
    }

    let workingInput = input;
    if (cellInputType === "number") {
      workingInput = workingInput
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      if (isCurrency(dataKey)) {
        workingInput =
          "$" +
          parseFloat(workingInput.replaceAll(",", "").replaceAll("$", ""))
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        workingInput = workingInput.split(".")[0];
      }
    } else if (isDateCell(dataKey)) {
      const tempWorkingInput = parseLeaseDate(workingInput);
      if (!tempWorkingInput) {
        workingInput = "";
      }
    }

    return workingInput;
  };

  if (!isEditing) {
    return (
      <div
        id={refRentRollKey}
        className={"DataCell Cell__default"}
        tabIndex={refRentRollKey ? refRentRollKey.split("-")[1] : "0"}
        autoFocus
        onFocus={e => {
          setIsEditing(true);
        }}
      >
        <div
          className={"DataCell__clickbox"}
          onClick={e => {
            setIsEditing(true);
          }}
        >
          <p>
            {isDateCell(dataKey) && cellValue
              ? dayjs(cellValue).format(PERIOD_DATE_FORMAT)
              : cleanInput(cellValue)}
          </p>
        </div>
      </div>
    );
  } else {
    return (
      <div className={"DataCell DataCell__clickbox"}>
        {isDateCell(dataKey) ? (
          <div className="DataCell__CellDatePicker">
            <DatePicker
              autoFocus
              bordered={false}
              defaultValue={
                cellValue ? dayjs(cellValue) : dayjs().startOf("day")
              }
              format={PERIOD_DATE_FORMAT}
              name={dataKey}
              onBlur={handleBlur}
              onChange={handleDateUpdate}
              onKeyDown={e => this.handleKeyDown(e)}
              size={"small"}
              style={{ margin: "0 -10px 0 0px" }}
              tabIndex={
                refRentRollKey ? parseInt(refRentRollKey.split("-")[1]) : 0
              }
            />
          </div>
        ) : (
          <input
            autoFocus
            tabIndex={refRentRollKey ? refRentRollKey.split("-")[1] : "0"}
            className="DataCell__inputs"
            type={cellInputType}
            value={
              cellInputType === "number"
                ? cellValue.replace(/[$,]/g, "")
                : cellValue
            }
            min={dataKey === "size_sqft" ? "0" : ""}
            name={dataKey}
            onChange={handleInputChange}
            onKeyDown={e => handleKeyDown(e)}
            onBlur={handleBlur}
          />
        )}
      </div>
    );
  }
}

NewDataCell.propTypes = {
  column: PropTypes.shape({
    dataKey: PropTypes.string,
    rowFormData: PropTypes.object,
    setRowFormData: PropTypes.func,
    selectedRentRollCell: PropTypes.string
  }),
  isCurrency: PropTypes.func,
  setSelectedRentRollCell: PropTypes.func,
  columnIndex: PropTypes.number,
  rowIndex: PropTypes.number
};

export default NewDataCell;
