import PropTypes from "prop-types";
import { connect } from "react-redux";
import cx from "classnames";

import SidebarHeader from "./SidebarHeader";
import ExceptionSubsection from "./ExceptionSubsection";

import {
  configureExceptions,
  exceptionSidebarConfig,
  exceptionSidebarLabelDescription
} from "../helpers/exceptions";

const ExceptionsSidebar = ({
  document,
  exceptions,
  rentRoll,
  handleScrollToCell,
  totalPctComplete,
  includeTopNav,
  property
}) => {
  const exceptionsConfig = configureExceptions(
    exceptions,
    document,
    rentRoll,
    "",
    property
  );
  return (
    <div
      className={cx("ExceptionSidebar", {
        ["ExceptionSidebar__widget"]: !includeTopNav
      })}
    >
      <SidebarHeader />
      <div>
        {totalPctComplete
          ? exceptionSidebarLabelDescription(100)
          : exceptionSidebarLabelDescription(totalPctComplete)}
      </div>
      <div className="ExceptionsSidebar__body">
        {exceptionsConfig
          ? ["blocking", "warnings", "resolved"].map(key => {
              if (exceptionsConfig[key] && exceptionsConfig[key].length === 0) {
                return null;
              }
              return (
                <ExceptionSubsection
                  key={key}
                  title={exceptionSidebarConfig[key].subHeader}
                  exceptions={exceptionsConfig[key]}
                  countColor={exceptionSidebarConfig[key].countColor}
                  // scrollType={exceptionSidebarConfig[key].scrollType}
                  handleScrollToCell={handleScrollToCell}
                />
              );
            })
          : null}
      </div>
    </div>
  );
};

ExceptionsSidebar.propTypes = {
  document: PropTypes.object,
  rentRoll: PropTypes.array,
  exceptions: PropTypes.array,
  handleScrollToCell: PropTypes.func,
  updatePdfMarkdownLoading: PropTypes.func,
  totalPctComplete: PropTypes.number,
  includeTopNav: PropTypes.bool,
  property: PropTypes.object
};

const mapStateToProps = ({ rentRoll }) => ({
  document: rentRoll.document,
  rentRoll: rentRoll.rentRoll,
  exceptions: rentRoll.exceptions
});

export default connect(mapStateToProps)(ExceptionsSidebar);
