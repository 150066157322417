import { reject } from "ramda";
import { compact } from "helpers/presence";

export function formatRequestSearchObject(searchObject) {
  const {
    cities,
    propertyTypes,
    tenants,
    searchInput,
    showVacant,
    dateTypeForCalc,
    dateForCalc,
    postalCode,
    propertyName,
    paginationAndSort
  } = searchObject;
  return JSON.stringify(
    compact({
      cities: cities && reject(city => !cities[city], Object.keys(cities)),
      tenants:
        tenants && reject(tenant => !tenants[tenant], Object.keys(tenants)),
      propertyTypes:
        propertyTypes &&
        reject(
          propertyType => !propertyTypes[propertyType],
          Object.keys(propertyTypes)
        ),
      postalCode:
        postalCode && reject(zip => !postalCode[zip], Object.keys(postalCode)),
      propertyName:
        propertyName &&
        reject(property => !propertyName[property], Object.keys(propertyName)),
      searchInput,
      showVacant,
      dateTypeForCalc,
      dateForCalc,
      paginationAndSort
    })
  );
}

/**
 * Get ES bucket name to sort by
 * @param {*} object with following structure {details: {columnName: "UI column", sortName:"ES bucket name"},...}
 * @param {*} value
 * @returns ES bucket name
 */
export const getESSortNameByColumnName = (object, value) => {
  let sortName = "";
  for (let key of Object.keys(object)) {
    if (object[key].columnName === value) {
      sortName = object[key].sortName;
    }
  }
  return sortName;
};

/**
 * Get UI column name based on bucket name
 * @param {*} object with following structure {details: {columnName: "UI column", sortName:"ES bucket name"},...}
 * @param {*} value
 * @returns UI column name
 */
export const getColumnNameByESSortName = (object, value) => {
  let columnName = "";
  for (let key of Object.keys(object)) {
    if (object[key].sortName === value) {
      columnName = object[key].columnName;
    }
  }
  return columnName;
};

export const searchPageSize = 100;
