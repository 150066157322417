export const properties_columns = [
  { label: "Name", key: "name" },
  { label: "Property Type", key: "property_type" },
  {
    label: "REIS Surveyed Rent",
    key: "one_year_asking_rent",
    format: "currency"
  },
  { label: "One Year Vacancy", key: "one_year_vacancy", format: "percent" },
  { label: "Parcel Number", key: "parcel_number" },
  { label: "Year Built", key: "year_built" },
  { label: "Size Lot Sqft", key: "size_lot_sqft" },
  { label: "Sise Sqft", key: "size_sqft" },
  { label: "Street 1", key: "street_1" },
  { label: "Street 2", key: "street_2" },
  { label: "County", key: "county" },
  { label: "City", key: "city" },
  { label: "State", key: "state" },
  { label: "Postal Code", key: "postal_code" },
  { label: "Country", key: "country" },
  { label: "Floors", key: "floors" },
  { label: "latitude", key: "latitude" },
  { label: "Longitude", key: "longitude" }
];

export const rentroll_columns = [
  { label: "QP Filename", key: "filename" },
  { label: "QP Effective Date", key: "effective_date", format: "date" },
  { label: "QP Tenant Name", key: "tenant_name" },
  { label: "QP Tenant Id", key: "tenant_id" },
  { label: "Orbis ID", key: "orbis_id" },
  { label: "Orbis Address", key: "orbis_address" },
  { label: "Orbis Industry", key: "orbis_industry" },
  { label: "Orbis Ownership Name", key: "orbis_ownershipName" },
  { label: "Orbis Ownership Id", key: "orbis_ownershipId" },
  { label: "QP Unit Name", key: "unit_name" },
  { label: "QP Is Vacant", key: "is_vacant", format: "yn" },
  { label: "QP Is Occupied", key: "is_occupied", format: "yn" },
  { label: "QP Is Primary", key: "is_primary", format: "yn" },
  { label: "QP Annual Rent", key: "annual_rent", format: "currency" },
  { label: "QP Monthly Rent", key: "monthly_rent", format: "currency" },
  {
    label: "QP Annual Rent Per Sqft",
    key: "annual_rent_per_sqft",
    format: "currency"
  },
  {
    label: "QP Monthly Rent Per Sqft",
    key: "monthly_rent_per_sqft",
    format: "currency"
  },
  { label: "QP Size Sqft", key: "size_sqft", format: "size" },
  { label: "QP Lease Start Date", key: "lease_start_date", format: "date" },
  { label: "QP Lease End Date", key: "lease_end_date", format: "date" },
  { label: "QP Comments", key: "comments" },
  { label: "QP Rent Status", key: "rent_status" },
  { label: "QP Space Type", key: "space_type" }
];

export const tenants_analysis_columns = [
  { label: "Property Name", key: "property_name" },
  { label: "Address", key: "address" },
  { label: "Tenant", key: "tenant" },
  { label: "Square Footage", key: "square_footage" },
  { label: "Annual Rent", key: "annual_rent", format: "currency" },
  { label: "Monthly Rent", key: "monthly_rent", format: "currency" },
  {
    label: "Monthly Rent Per Sqft",
    key: "monthly_rent_per_sqft",
    format: "currency"
  },
  {
    label: "Percent Of Property Rent",
    key: "percent_of_property_rent",
    format: "percent"
  },
  {
    label: "Percent Of Property Space",
    key: "percent_of_property_space",
    format: "percent"
  },
  { label: "Lease Start Date", key: "lease_start_date" },
  { label: "Lease End Date", key: "lease_end_date" },
  { label: "Lease Term", key: "lease_term" },
  { label: "Remaining Term", key: "remaining_term" }
];

export const property_composition_columns = [
  { label: "Property Name", key: "property_name" },
  { label: "Address", key: "address" },
  { label: "Tenant", key: "tenant" },
  { label: "Square Footage", key: "square_footage" },
  { label: "Annual Rent", key: "annual_rent", format: "currency" },
  { label: "Monthly Rent", key: "monthly_rent", format: "currency" },
  {
    label: "Monthly Rent Per Sqft",
    key: "monthly_rent_per_sqft",
    format: "currency"
  },
  {
    label: "Percent Of Property Rent",
    key: "percent_of_property_rent",
    format: "percent"
  },
  {
    label: "Percent Of Property Space",
    key: "percent_of_property_space",
    format: "percent"
  },
  { label: "Lease Start Date", key: "lease_start_date" },
  { label: "Lease End Date", key: "lease_end_date" },
  { label: "Lease Term", key: "lease_term" },
  { label: "Remaining Term", key: "remaining_term" }
];

export const rentroll_composition_columns = [
  // { label: "Tenant Name", key: "agg_value" },
  // { label: "Avg Sqft per Unit", key: "total_sqft" },
  {
    label: "Avg Monthly Rent",
    key: "gross_monthly_rent_per_unit",
    format: "currency"
  },
  { label: "Occupied Units", key: "occupied_units" },
  { label: "Total Units", key: "total_units" },
  { label: "Total Sqft", key: "total_sqft" },
  { label: "% Vacant", key: "vacancy_rate_unit", format: "percent" }
];
