import Status from "ui/components/routing/Status";

export default function NotAuthorized() {
  return (
    <Status code={401}>
      <div style={{ fontSize: "18px", textAlign: "center", margin: "50px" }}>
        You are not authorized to access this page.
      </div>
    </Status>
  );
}
