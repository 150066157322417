import { companyGroupNames } from "helpers/companies";
import {
  OCR_WARNING,
  LOW_PREDEF_CONFIDENCE,
  NO_PREDEF_MAPPING,
  CELL_VALUE_REQUIRED,
  INTEGRATION_DATA_INVALID
} from "services/helpers/exceptions";

export const exceptionErrorMessages = {
  RESOLVE_ERRORS:
    "Resolving errors is needed before marking this document as complete.",
  EFFECTIVE_DATE_MISSING:
    "Effective Date is needed before marking this document as complete.",
  CPM_DATA_INVALID:
    "Property Data is in Invalid Format. Please contact support."
};

export const checkForBlockingException = (
  exception,
  groupName,
  propertyType
) => {
  if (
    groupName === companyGroupNames.CPM.id &&
    propertyType === "MULTI_FAMILY"
  ) {
    return (
      (exception.type === NO_PREDEF_MAPPING ||
        exception.type === CELL_VALUE_REQUIRED ||
        exception.type === INTEGRATION_DATA_INVALID) &&
      exception.corrected_at === null &&
      exception.ignored_at === null
    );
  } else {
    return (
      (exception.type === CELL_VALUE_REQUIRED ||
        exception.type === INTEGRATION_DATA_INVALID) &&
      exception.corrected_at === null &&
      exception.ignored_at === null
    );
  }
};

export const checkForCellsException = (exception, groupName, propertyType) => {
  if (
    groupName === companyGroupNames.CPM.id &&
    propertyType === "MULTI_FAMILY"
  ) {
    return (
      (exception.type === OCR_WARNING ||
        exception.type === LOW_PREDEF_CONFIDENCE) &&
      exception.corrected_at === null &&
      exception.ignored_at === null
    );
  } else {
    return (
      (exception.type === OCR_WARNING ||
        exception.type === NO_PREDEF_MAPPING) &&
      exception.corrected_at === null &&
      exception.ignored_at === null
    );
  }
};
