import { Component } from "react";
import PropTypes from "prop-types";
import NewDataCell from "./NewDataCell";
import { isEntityMappingEnabled } from "../../../../../../splitPanel/popovers/cell/helpers/predef";
import CellUpdateValue from "../Popover/PredefCellUpdateVal";
import { parseLeaseDate } from "server/middleware/api/helpers/parsingHelper";
import { PERIOD_DATE_FORMAT } from "ui/components/helpers/dayjs";
import { DatePicker } from "antd";
import dayjs from "dayjs";
class DataCell extends Component {
  constructor(props) {
    super(props);
    const text = this.props.rowData[this.props.column?.dataKey]?.text || "";
    this.state = {
      cellValue: text,
      originalCellValue: text,
      isEditing: false,
      hasBeenEdited: false,
      cellDataType: "non-editable",
      rowIndex: this.props.rowIndex,
      columnIndex: this.props.columnIndex,
      hasError: false,
      hasWarning: false,
      isDeleted: false,
      userAddedRow:
        this.props.rowData?.actions?.status === "MANUAL_ADD" || false
    };
  }

  componentDidUpdate(prevProps) {
    const dataKey = this.props.column.dataKey;
    if (
      prevProps.rowData[dataKey]?.isDeleted !==
      this.props.rowData[dataKey]?.isDeleted
    ) {
      this.findCellDataType();
    }
  }

  findCellDataType() {
    const columnInfo = this.props.column.columnInfo.columnInfo;
    const dataKey = this.props.column.dataKey;
    const dataType = columnInfo[dataKey];
    if (dataType) {
      this.setState({
        cellDataType: dataType.type
      });
    }
    if (dataKey) {
      this.setState({
        hasBeenEdited: this.checkCellCorrected() || false,
        hasError: this.props.rowData[dataKey]?.hasError || false,
        hasWarning: this.props.rowData[dataKey]?.hasWarning || false,
        isDeleted: this.props.rowData[dataKey]?.isDeleted || false
      });
    }
  }

  checkCellCorrected() {
    const dataKey = this.props.column.dataKey;
    // render issue persists with isCorrected, checkifEdited is a stop gap
    return (
      this.props.rowData[dataKey]?.isCorrected ||
      this.props.checkIfEdited(
        `${this.state.rowIndex}-${this.state.columnIndex}`
      ) ||
      this.state.hasBeenEdited
    );
  }

  componentDidMount() {
    this.findCellDataType();
  }

  handleClick(e, text) {
    if (this.state.isEditing) {
      // console.log("IS EDITING");
    } else {
      this.setState({
        isEditing: true,
        originalCellValue: text || ""
      });
    }
  }

  handleKeyDown(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      e.target.blur();
    }
  }

  isBoolCell(dataKey) {
    if (dataKey === "is_vacant") return true;
    return false;
  }

  isDateCell(dataKey) {
    if (dataKey === "lease_start_date") return true;
    if (dataKey === "lease_end_date") return true;
    return false;
  }

  isPredefTenantNameCell(propertyType, dataKey, userCompanyEntitySources) {
    if (
      dataKey === "tenant_name" &&
      isEntityMappingEnabled(propertyType, dataKey, userCompanyEntitySources)
    ) {
      return true;
    }
    return false;
  }

  isPredefCell(dataKey) {
    if (dataKey === "space_type") {
      return true;
    }
    return false;
  }

  editedStyles() {
    if (this.state.isDeleted) {
      return "Cell__Deleted";
    } else if (this.state.hasError) {
      return "Cell__Error";
    } else if (this.state.hasWarning) {
      return "Cell__Warning";
    } else if (this.state.hasBeenEdited) {
      return "Cell__Corrected";
    } else {
      return "Cell__default";
    }
  }

  render() {
    const { rowData, documentKey, addingRow } = this.props;

    const {
      column: {
        dataKey,
        updateDocumentCell,
        userCompanyEntitySources,
        propertyType,
        setPredefModalInfo,
        openPopover,
        ...restColumnProps
      }
    } = this.props;

    const { text, isNewRow, columnIndex, pageIndex, rowIndex, tableIndex } =
      rowData[dataKey] || {};

    const { cellDataType, originalCellValue } = this.state;

    const getAcceptableInputType = () => {
      if (cellDataType === "numeric") {
        return "number";
      } else if (cellDataType === "character varying") {
        return "text";
      } else if (cellDataType === "timestamp with time") {
        return "date";
      }
    };

    /* START DATE METHODS */
    /** returns date format of MM/DD/YYYY */
    const getFormattedDate = dateString => {
      if (dateString) {
        return dayjs(dateString).format(PERIOD_DATE_FORMAT);
      } else {
        return "";
      }
    };

    const handleDateClick = () => {
      this.setState({
        isEditing: true,
        originalCellValue: text || ""
      });
    };

    const handleDateChange = value => {
      if (value) {
        this.setState({
          cellValue: getFormattedDate(value)
        });
      }
    };
    /* END DATE METHODS */

    const sendCellEdit = async value => {
      const field = {
        page: pageIndex,
        table: tableIndex,
        row: rowIndex,
        col: columnIndex,
        text: value || this.state.cellValue
      };
      await updateDocumentCell(field);
      await Promise.all([
        this.props.mutateDocument(),
        this.props.mutateExceptions(),
        this.props.mutateRentRoll()
      ]);
    };

    const handlePredefClick = () => {
      const val = this.state.cellValue !== "" ? this.state.cellValue : text;

      setPredefModalInfo(
        val,
        pageIndex,
        tableIndex,
        rowIndex,
        columnIndex,
        dataKey
      );
      openPopover();
    };

    if (addingRow && rowData.actions?.isNewRow) {
      return (
        <NewDataCell
          column={{ dataKey, ...restColumnProps }}
          columnIndex={this.props.columnIndex}
          rowIndex={this.props.rowIndex}
          rowData={rowData}
          isCurrency={this.props.isCurrency}
          setSelectedRentRollCell={this.props.setSelectedRentRollCell}
        />
      );
    }

    const cleanInput = input => {
      if (input === "") {
        return "";
      }
      let workingInput = input || this.state.originalCellValue;

      if (
        originalCellValue.includes("$") &&
        !workingInput.includes("$") &&
        cellDataType === "numeric"
      ) {
        workingInput = workingInput
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        if (!workingInput.includes("$")) {
          workingInput = "$" + workingInput;
        }
      } else if (
        !originalCellValue.includes("$") &&
        cellDataType === "numeric"
      ) {
        workingInput = workingInput
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else if (
        originalCellValue.includes("$") &&
        workingInput.includes("$") &&
        cellDataType === "numeric"
      ) {
        workingInput = workingInput
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else if (this.isDateCell(dataKey)) {
        const tempWorkingInput = parseLeaseDate(workingInput);
        if (!tempWorkingInput) {
          workingInput = "";
        }
      }

      return workingInput;
    };

    const handleBlur = async e => {
      if (!e.currentTarget.contains(e.relatedTarget)) {
        if (this.state.cellValue === this.state.originalCellValue) {
          // no edits, dont do anything
          this.setState({
            isEditing: false
          });
          return;
        }

        // changed data so submit it
        // render issue persists with isCorrected, this is a stop gap
        this.props.addToEditedCells(
          `${this.state.rowIndex}-${this.state.columnIndex}`
        );

        const cleanedInput = cleanInput(this.state.cellValue);

        this.setState({
          hasBeenEdited: true,
          cellValue: cleanedInput,
          isEditing: false
        });

        await sendCellEdit(cleanedInput);
      }
    };

    // These are somewhat usless split up like this now, but it's for later functionality when we have to use a date picker, dropdown, etc.
    if (this.state.userAddedRow) {
      return (
        <div className={"DataCell Cell__Corrected"}>
          <p>{this.state.cellValue !== "" ? this.state.cellValue : text}</p>
        </div>
      );
    } else if (this.isBoolCell(dataKey)) {
      return (
        <div id={documentKey}>
          <p>{this.state.cellValue !== "" ? this.state.cellValue : text}</p>
        </div>
      );
    } else if (
      this.isPredefTenantNameCell(
        propertyType,
        dataKey,
        userCompanyEntitySources
      )
    ) {
      return (
        <div className={"DataCell " + this.editedStyles()}>
          <div onClick={handlePredefClick} className="DataCell__clickbox">
            {this.state.isEditing ? (
              <CellUpdateValue />
            ) : (
              <p>{this.state.cellValue !== "" ? this.state.cellValue : text}</p>
            )}
          </div>
        </div>
      );
    } else if (this.isDateCell(dataKey)) {
      return (
        <div
          className={"DataCell " + this.editedStyles()}
          onClick={handleDateClick}
        >
          {this.state.isEditing ? (
            <div className="DataCell__CellDatePicker">
              <DatePicker
                autoFocus
                bordered={false}
                defaultValue={
                  this.state.cellValue ? dayjs(this.state.cellValue) : undefined
                }
                format={PERIOD_DATE_FORMAT}
                id={`date-cell-${this.state.rowIndex}-${this.state.columnIndex}`}
                name="date"
                onBlur={handleBlur}
                onChange={handleDateChange}
                onKeyDown={e => this.handleKeyDown(e)}
                size={"small"}
                style={{ margin: "0 -10px 0 0px" }}
              />
            </div>
          ) : (
            <p>
              {this.state.cellValue !== ""
                ? getFormattedDate(this.state.cellValue)
                : getFormattedDate(text)}
            </p>
          )}
        </div>
      );
    } else if (this.isPredefCell(dataKey)) {
      return (
        <div
          onClick={handlePredefClick}
          className={"DataCell " + this.editedStyles()}
        >
          <div>
            {this.state.isEditing ? (
              <div></div>
            ) : (
              <p>{this.state.cellValue !== "" ? this.state.cellValue : text}</p>
            )}
          </div>
        </div>
      );
    }

    const inputType = getAcceptableInputType();

    return (
      <div id={documentKey} className={"DataCell " + this.editedStyles()}>
        <div
          className="DataCell__clickbox"
          onClick={e => this.handleClick(e, text)}
        >
          {this.state.isEditing ? (
            <input
              autoFocus
              type={inputType}
              onChange={e => {
                this.setState({
                  cellValue: e.target.value
                });
              }}
              onKeyDown={e => this.handleKeyDown(e)}
              title="Numbers and $ and , and . characters"
              value={
                inputType === "number"
                  ? this.state.cellValue.replace(/[$,]/g, "")
                  : this.state.cellValue
              }
              onBlur={e => handleBlur(e)}
              className="DataCell__inputs"
            />
          ) : (
            <p>{this.state.cellValue !== "" ? this.state.cellValue : text}</p>
          )}
        </div>
      </div>
    );
  }
}

DataCell.propTypes = {
  column: PropTypes.shape({
    dataKey: PropTypes.string,
    update: PropTypes.func,
    updateDocumentCell: PropTypes.func,
    userCompanyEntitySources: PropTypes.object,
    propertyType: PropTypes.string,
    setPredefModalInfo: PropTypes.func,
    openPopover: PropTypes.func,
    columnInfo: PropTypes.object
  }),
  documentKey: PropTypes.string,
  selected: PropTypes.string,
  propertyType: PropTypes.string,
  rowData: PropTypes.object,
  addToEditedCells: PropTypes.func,
  checkIfEdited: PropTypes.func,
  rowIndex: PropTypes.number,
  columnIndex: PropTypes.number,
  mutateRentRoll: PropTypes.func,
  mutateExceptions: PropTypes.func,
  mutateDocument: PropTypes.func,
  addingRow: PropTypes.bool,
  isCurrency: PropTypes.func,
  setSelectedRentRollCell: PropTypes.func
};

export default DataCell;
