// to be used in processOpexData
export const getItemsByType = (startNode, type) => {
  if (!startNode) return [];

  let result = [];
  _.each(startNode, item => {
    if (item.type === type) {
      result.push(item);
    }
    result = result.concat(getItemsByType(item.children, type));
  });

  return result;
};

export const blankRow = [
  {
    v: " ",
    t: "s"
  }
];

export const subcategoryNames = {
  VARIABLE_OPERATING_EXPENSES: "Variable Operating Expenses",
  FIXED_OPERATING_EXPENSES: "Fixed Operating Expenses",
  OTHER_EXPENSES: "Other Expenses",
  OPERATING_EXPENSES: "Operating Expenses",
  CAPITAL_EXPENDITURES: "Capital Expenditures",
  NOI: "NOI",
  NCF: "NCF"
};
