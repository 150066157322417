import { merge } from "ramda";

import { API_SIGNATURE_GENERATED } from "ui/store/actions/s2sAuthentication";

export default function s2sAuthentication(
  state = {
    apiPublicKey: "",
    secretKey: "",
    apiSignature: "",
    apiRequestDate: "",
    error: ""
  },
  action
) {
  switch (action.type) {
    case API_SIGNATURE_GENERATED: {
      const {
        apiPublicKey,
        secretKey,
        apiSignature,
        apiRequestDate,
        error
      } = action;
      return merge(state, {
        apiPublicKey,
        secretKey,
        apiSignature,
        apiRequestDate,
        error
      });
    }
    default:
      return state;
  }
}
