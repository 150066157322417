import PropTypes from "prop-types";

const ItemForm = ({
  label,
  children,
  type = "text",
  autocomplete = "new-value",
  labelStyle = {},
  itemStyle = {},
  ...otherProps
}) => (
  <div className="ItemForm" style={itemStyle}>
    <label style={labelStyle}>{label}</label>
    {type === "custom" ? (
      <div className="ItemForm__custom-input">{children}</div>
    ) : (
      <input
        className="ItemForm__input"
        type={type}
        autoComplete={autocomplete}
        {...otherProps}
      />
    )}
  </div>
);

ItemForm.propTypes = {
  label: PropTypes.string,
  children: PropTypes.object,
  type: PropTypes.string,
  autocomplete: PropTypes.string,
  labelStyle: PropTypes.object,
  itemStyle: PropTypes.object
};
export default ItemForm;
