import PropTypes from "prop-types";
import Popup from "reactjs-popup";

import FilterBreadCrumb from "./FilterBreadCrumb";

const BreadCrumbDropdown = ({ list }) => (
  <Popup
    trigger={
      <div className="BreadCrumbDropdown__trigger">{`+ ${list.length}`}</div>
    }
    position="bottom left"
    closeOnDocumentClick
    contentStyle={{
      width: "auto",
      height: "300px"
    }}
    arrow={false}
    keepTooltipInside=".HeaderWithSidebar__body"
  >
    {close => (
      <div className="BreadCrumbDropdown__panel">
        <div className="BreadCrumbDropdown__navBar">
          <span onClick={() => close()}>x</span>
        </div>
        {list.map(filter => (
          <FilterBreadCrumb
            key={`${filter.filterType}-${filter.filterValue}`}
            filterType={filter.filterType}
            filterValue={filter.filterValue}
          />
        ))}
      </div>
    )}
  </Popup>
);

BreadCrumbDropdown.propTypes = {
  list: PropTypes.array
};

export default BreadCrumbDropdown;
