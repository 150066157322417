import { useState } from "react";
import PropTypes from "prop-types";
import { fieldNames } from "ui/components/rentRoll/splitPanel/helpers/dictionaries";
import { drop } from "ramda";

const FieldSelect = ({
  value,
  keyIdx,
  headerData,
  advancedRule,
  dispatch,
  setNewField,
  setCustomField
}) => {
  const [field, setField] = useState(value);
  const [custom, setCustom] = useState("");

  const dropDownList = [
    ...new Set(
      headerData.headers
        .map(header => header.id)
        .concat(Object.keys(fieldNames))
    )
  ];

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <select
        value={field}
        onChange={e => {
          if (advancedRule) {
            if (e.target.value === "custom_field") {
              setField(e.target.value);
            } else {
              setField(e.target.value);
              dispatch({
                type: "update_field",
                old: advancedRule.field,
                new: e.target.value
              });
            }
          } else {
            if (e.target.value === "custom_field") {
              setField(e.target.value);
              setNewField(e.target.value);
            } else {
              setField(e.target.value);
              setNewField(e.target.value);
              setCustomField("");
            }
          }
        }}
      >
        {advancedRule
          ? dropDownList.map(header => (
              <option key={header} value={header}>
                {header}
              </option>
            ))
          : dropDownList
              .filter(key => {
                if (!headerData.advancedRules) {
                  return key;
                }
                const fieldsWithRules = headerData.advancedRules.map(
                  rule => rule.field
                );
                return !fieldsWithRules.includes(key);
              })
              .map(header => (
                <option key={header} value={header}>
                  {header}
                </option>
              ))}
        {advancedRule && !dropDownList.includes(advancedRule.field) && (
          <option value={advancedRule.field}>{advancedRule.field}</option>
        )}
        <option value="custom_field">custom field</option>
      </select>
      {field === "custom_field" && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ marginTop: "5px" }}>enter custom field below</div>
          <input
            value={custom}
            onChange={e => {
              if (advancedRule) {
                setCustom(e.target.value);
                dispatch({
                  type: "update_custom_field",
                  old: keyIdx,
                  custom: e.target.value
                });
              } else {
                setCustom(e.target.value);
                setCustomField(e.target.value);
              }
            }}
          />
        </div>
      )}
    </div>
  );
};

FieldSelect.propTypes = {
  value: PropTypes.string,
  headerData: PropTypes.object,
  advancedRule: PropTypes.object,
  dispatch: PropTypes.func,
  setNewField: PropTypes.func,
  setCustomField: PropTypes.func
};

export default FieldSelect;
