import { createContext, useContext } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import PropTypes from "prop-types";

export const OpexPanelContext = createContext();

function ContextProvider({
  match,
  widgetAuth,
  userCompanyEntitySources,
  userRole,
  groupName,
  children
}) {
  const {
    params: { propertyId, documentId, portfolioId }
  } = match;

  return (
    <OpexPanelContext.Provider
      value={{
        documentId,
        portfolioId,
        propertyId,
        widgetAuth,
        userCompanyEntitySources,
        userRole,
        groupName,
        isWidgetAuthorized: !!(widgetAuth && widgetAuth.authorized)
      }}
    >
      {children}
    </OpexPanelContext.Provider>
  );
}

ContextProvider.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      propertyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      documentId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      portfolioId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    })
  }).isRequired,
  widgetAuth: PropTypes.object,
  userCompanyEntitySources: PropTypes.object,
  userRole: PropTypes.string,
  groupName: PropTypes.string,
  children: PropTypes.any
};

const mapStateToProps = ({ currentUser }, ownProps) => ({
  widgetAuth: currentUser.widgetAuth,
  userCompanyEntitySources: currentUser.company.entity_sources,
  userRole: currentUser.role,
  groupName: currentUser.company.group_name,
  ...ownProps
});

export const OpexPanelContextProvider = withRouter(
  connect(mapStateToProps)(ContextProvider)
);

export default function useOpexPanelContext() {
  return useContext(OpexPanelContext);
}
