import { companyGroupNames } from "helpers/companies";
import { notification } from "antd";

export const PERIOD_STATUS_IGNORED = "IGNORED";
export const PERIOD_STATUS_SET = "SET";
export const OPEX_VALUE_TYPE_AMOUNT = "amount";
export const OPEX_VALUE_TYPE_NEGATIVE_AMOUNT = "negative_amount";
export const OPEX_VALUE_TYPE_PERCENT = "percent";
export const OPEX_VALUE_TYPE_INTEGER = "integer";

export const CONFIDENCE_COLOR_OPACITY = 0.15;
export const CELL_STATUS_CORRECTED = "corrected";
export const CONFIDENCE_CONFIRMED = "confirmed";
export const CONFIDENCE_NEEDS_REVIEW = "needs_review";
export const CONFIDENCE_UNMAPPED = "unmapped";
export const CONFIDENCE_HIGH = "high";
export const CELL_STATUS_CORRECTED_COLOR = "#62BC66";
export const CONFIDENCE_CONFIRMED_COLOR = "#4E0576";
export const CONFIDENCE_NEEDS_REVIEW_COLOR = "#FC3";
export const CONFIDENCE_UNMAPPED_COLOR = "#F22C54";
export const RECOGNIZED_COLOR = "#FFE085";
export const IGNORED_COLOR = "#BDBDBD";

export const isCreditLensByGroupName = groupName => {
  return groupName === companyGroupNames.CL.id;
};
export const isCPMByGroupName = groupName => {
  return groupName === companyGroupNames.CPM.id;
};

export const getConfidenceColorByConfidenceCorrected = ({
  confidence,
  corrected
}) => {
  if (isCellCorrected(corrected)) {
    return CELL_STATUS_CORRECTED_COLOR;
  }

  if (isConfidenceNeedsReview(confidence)) {
    return CONFIDENCE_NEEDS_REVIEW_COLOR;
  }

  if (isConfidenceUnmapped(confidence)) {
    return CONFIDENCE_UNMAPPED_COLOR;
  }

  return null;
};

export const getConfidenceCSSClassByConfidence = confidence => {
  if (isConfidenceNeedsReview(confidence)) {
    return "NeedsReview";
  }

  if (isConfidenceUnmapped(confidence)) {
    return "Unmapped";
  }

  return "";
};

export const isCellCorrected = corrected => corrected;
export const isCellConfirmed = corrected => corrected === false;
export const isConfidenceHigh = confidence => confidence > 90;
export const isConfidenceNeedsReview = confidence =>
  confidence >= 50 && confidence <= 90;
export const isConfidenceUnmapped = confidence =>
  confidence >= 0 && confidence < 50;

// default zoom for page controls
export const DEFAULT_ZOOM = 1;

// convert a number `confidence` to the constant string value
export function getConfidenceStatus(confidence) {
  if (isConfidenceHigh(confidence)) {
    return CONFIDENCE_HIGH;
  }
  if (isConfidenceNeedsReview(confidence)) {
    return CONFIDENCE_NEEDS_REVIEW;
  }
  if (isConfidenceUnmapped(confidence)) {
    return CONFIDENCE_UNMAPPED;
  }
  throw "Unrecognized confidence.";
}

// basetable will render this class on its cells, so that when you
// hover on the image, you know where to zoom to
export function getClassNameForCellScrollIntoView(location) {
  const { pi, ti, ri, ci } = location;
  const className = `cell-${pi}-${ti}-${ri}-${ci}`;
  return className;
}

// scrolls an element into view
// used for when you hover on cell in the image, the UI can zoom to the same cell in
// the basetable and vice versa
export function scrollCellIntoView(className, options = {}) {
  // 200 chosen to give a little time for the image to load
  // in an attempt to fix an issue where if you move cells too fast
  // and the images keep loading/unloading, sometimes it wouldn't
  // scroll
  const finalDelay = options.delay || 200;

  setTimeout(() => {
    const selectedCell = window.document.querySelector(className);
    if (selectedCell) {
      selectedCell.scrollIntoView({
        behavior: options.behavior ? options.behavior : "smooth",
        block: options.block ? options.block : "center",
        inline: options.inline ? options.inline : "center"
      });
    }
  }, finalDelay);
}

// publish a completed event to cpm, who listens to this event
// more about widget here:
// https://moodysanalytics.atlassian.net/wiki/spaces/QQP/pages/434536467/Widget+Authentication
export function publishCompletedEventToCpm({ documentId, propertyId }) {
  console.log("*");
  const message = JSON.stringify({
    event_type: "MARKED_AS_COMPLETE",
    document_id: documentId,
    property_id: propertyId
  });
  window.parent.postMessage(message, "*");
  return true;
}

export function showNotification(options) {
  notification.config({
    placement: "bottomRight"
  });
  notification.open({
    className: `OpexNotification OpexNotification-${options.type}`,
    duration: 500,
    ...options
  });
}
