import _ from "lodash";
import PropTypes from "prop-types";
import {
  methodPrepareCPM,
  statementTypeCPM,
  methodPrepareCL,
  statementTypeCL
} from "helpers/opex";
import { isCreditLensByGroupName } from "ui/components/opex/shared";

function PeriodStatusCell({ cellData }) {
  const { methodPrepared, statementType, disabled, groupName } = cellData;

  let allLabels;
  if (isCreditLensByGroupName(groupName)) {
    allLabels = { ...methodPrepareCL, ...statementTypeCL };
  } else {
    allLabels = { ...methodPrepareCPM, ...statementTypeCPM };
  }

  return (
    <div
      className={`OpexBaseTable--PeriodStatusCell ${
        disabled ? "cellDisabled" : ""
      }`}
    >
      {!_.isEmpty(methodPrepared) && !_.isEmpty(statementType) && (
        <span>
          {_.find(allLabels, { value: methodPrepared })?.label}/
          {_.find(allLabels, { value: statementType })?.label}
        </span>
      )}
    </div>
  );
}

export default PeriodStatusCell;

PeriodStatusCell.propTypes = {
  cellData: PropTypes.shape({
    methodPrepared: PropTypes.string,
    statementType: PropTypes.string,
    disabled: PropTypes.bool,
    groupName: PropTypes.string
  })
};
