import PropTypes from "prop-types";
import _ from "lodash";
import OpexPage from "../OpexPage/OpexPage";
import OpexDocument from "../../modelOpexDocument";
import OpexPageNumber from "../OpexPageNumber/OpexPageNumber";

const TableSelectionPdf = ({ document, onChange }) => {
  const documentModel = new OpexDocument(document);

  const handleOnTableClick = (pageIndex, tableIndex) => {
    const nextQualified = !documentModel.isTableQualifiedByPageIndexTableIndex(
      pageIndex,
      tableIndex
    );
    documentModel.setTableQualifiedByPageIndexTableIndex(
      pageIndex,
      tableIndex,
      nextQualified
    );

    const nextMetaDataJson = documentModel.getMetaDataJson();
    onChange(nextMetaDataJson);
  };

  const renderPages = () => {
    const pages = documentModel.getPages();
    return pages.map((page, pageIndex) => (
      <div key={pageIndex}>
        <OpexPageNumber pageIndex={pageIndex} />
        <OpexPage
          views={["tables"]}
          document={document}
          pageIndex={pageIndex}
          onTableClick={tableIndex => handleOnTableClick(pageIndex, tableIndex)}
        />
      </div>
    ));
  };

  return (
    <>
      <div className="OpexPanelView__TableSelection">{renderPages()}</div>
    </>
  );
};

export default TableSelectionPdf;

TableSelectionPdf.propTypes = {
  document: PropTypes.object,
  onChange: PropTypes.func
};
