import { hideLoginModal, showLoginModal } from "ui/store/actions/mainLoginPage";

import Button from "ui/components/shared/Button";
import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import cx from "classnames";

class LoginHeader extends Component {
  constructor(props) {
    super(props);
  }

  handleLogin = () => {
    const { showLoginModal, hideLoginModal, login } = this.props;

    if (!login) {
      showLoginModal();
    } else {
      hideLoginModal();
    }
  };

  render() {
    return (
      <nav className={cx("HeaderWithSidebar__header", "LoginHeader")}>
        <img
          src={require("ui/images/quiq-property-logo-color.svg")}
          style={{ height: "100%" }}
        />
        <div
          className="MainHeader__right-nav"
          style={{ display: "flex", alignItems: "center" }}
        >
          <Button
            className="LoginHeader__loginButton"
            style={{
              border: "3px solid #009BDF",
              borderRadius: "15px",
              height: "75%"
            }}
            onClick={this.handleLogin}
          >
            Login
          </Button>
        </div>
      </nav>
    );
  }
}

LoginHeader.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  role: PropTypes.string,
  toggleLogin: PropTypes.func,
  showLoginModal: PropTypes.func,
  hideLoginModal: PropTypes.func,
  login: PropTypes.bool
};

function mapStateToProps({ authenticated, currentUser, mainLoginPage }) {
  return {
    authenticated,
    role: currentUser.role,
    login: mainLoginPage.login
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showLoginModal: function() {
      dispatch(showLoginModal());
    },
    hideLoginModal: function() {
      dispatch(hideLoginModal());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginHeader);
