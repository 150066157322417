import {
  UPDATE_FILTER_PANEL,
  UPDATE_SELECTED_FILTER,
  UPDATE_FILTER,
  SET_FILTERS,
  RESET_FILTERS,
  ADD_TO_ACTIVE_FILTERS,
  REMOVE_FROM_ACTIVE_FILTERS,
  CLEAR_FILTER_LIST,
  SET_SEARCHED_FILTERS,
  UPDATE_ELASTIC_SEARCH_METRICS,
  SET_SEARCH_INPUT,
  UPDATE_ACTIVE_SEARCH_INPUT
} from "../actions/filters";
import { clone } from "ramda";

export const originalFilters = {
  cities: {},
  propertyTypes: {},
  tenants: {},
  postalCode: {},
  propertyName: {},
  searchInput: null,
  showVacant: true,
  dateTypeForCalc: "effective",
  dateForCalc: Date.now()
};

export default function filters(
  state = {
    searchedFilters: {},
    activeFilterList: [],
    metrics: {},
    isFilterOpen: false,
    selected: "",
    filters: clone(originalFilters)
  },
  action
) {
  switch (action.type) {
    case UPDATE_FILTER_PANEL: {
      const { isFilterOpen } = action;
      return {
        ...state,
        isFilterOpen
      };
    }
    case UPDATE_SELECTED_FILTER: {
      const { selected } = action;
      return {
        ...state,
        selected
      };
    }
    case UPDATE_FILTER: {
      const { filterType, subfilter, filterVal } = action;
      return {
        ...state,
        filters: {
          ...state.filters,
          [filterType]: subfilter
            ? { ...state.filters[filterType], [subfilter]: filterVal }
            : filterVal
        }
      };
    }
    case SET_FILTERS: {
      const {
        cities,
        propertyTypes,
        tenants,
        postalCode,
        propertyName
      } = action;
      return {
        ...state,
        filters: {
          ...state.filters,
          cities,
          propertyTypes,
          tenants,
          postalCode,
          propertyName
        }
      };
    }
    case SET_SEARCHED_FILTERS: {
      const {
        cities,
        propertyTypes,
        tenants,
        postalCode,
        propertyName
      } = action;
      return {
        ...state,
        searchedFilters: {
          cities,
          propertyTypes,
          tenants,
          postalCode,
          propertyName
        }
      };
    }
    case RESET_FILTERS: {
      return {
        ...state,
        filters: clone(originalFilters)
      };
    }
    case ADD_TO_ACTIVE_FILTERS: {
      const { filterType, filterValue } = action;
      let newActiveFilterList;
      if (state.activeFilterList) {
        newActiveFilterList = [
          ...state.activeFilterList,
          { filterType, filterValue }
        ];
      } else {
        newActiveFilterList = [{ filterType, filterValue }];
      }
      return {
        ...state,
        activeFilterList: newActiveFilterList
      };
    }
    case REMOVE_FROM_ACTIVE_FILTERS: {
      const { filterType, filterValue } = action;
      let newActiveFilterList = state.activeFilterList.filter(
        filter =>
          !(
            filter.filterType === filterType &&
            filter.filterValue === filterValue
          )
      );
      return {
        ...state,
        activeFilterList: newActiveFilterList
      };
    }
    case CLEAR_FILTER_LIST: {
      let clearedFilters = { ...state.filters };
      Object.keys(clearedFilters).map(filter => {
        if (filter !== "searchInput" && filter !== "showVacant") {
          Object.keys(clearedFilters[filter]).map(filterValue => {
            clearedFilters[filter][filterValue] = false;
          });
        }
      });
      return {
        ...state,
        activeFilterList: [],
        filters: clearedFilters
      };
    }
    case UPDATE_ELASTIC_SEARCH_METRICS: {
      const { metrics } = action;
      return {
        ...state,
        metrics
      };
    }
    case SET_SEARCH_INPUT: {
      const { searchInput } = action;
      return {
        ...state,
        filters: {
          ...state.filters,
          searchInput
        }
      };
    }
    case UPDATE_ACTIVE_SEARCH_INPUT: {
      const { string } = action;
      const updatedActiveFilterList = state.activeFilterList.filter(
        filter => filter.filterType !== "searchInput"
      );
      return {
        ...state,
        activeFilterList: [
          ...updatedActiveFilterList,
          { filterType: "searchInput", filterValue: string }
        ]
      };
    }
    default: {
      return state;
    }
  }
}
