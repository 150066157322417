export const statementTypeCL = Object.freeze({
  ANNUAL: { label: "Annual", value: "annual" },
  FISCAL_YTD: { label: "Fiscal YTD", value: "fiscal_ytd" },
  QUARTERLY: { label: "Quarterly", value: "quarterly" },
  MONTHLY: { label: "Monthly", value: "monthly" },
  ROLLING_STMT: { label: "Rolling Stmt", value: "rolling_stmt" }
});

export const methodPrepareCL = Object.freeze({
  CO_PREPD: { label: "Co. Prep'd", value: "co_prep_d" },
  AS_IS: { label: "As-Is", value: "as_is" },
  STABILIZED: { label: "Stabilized", value: "stabilized" },
  MARKET: { label: "Market", value: "market" },
  ASKING: { label: "Asking", value: "asking" },
  APPRAISAL: { label: "Appraisal", value: "appraisal" },
  AUDITED: { label: "Audited", value: "audited" },
  PROFORMA: { label: "Proforma", value: "proforma" },
  UNDERWRITING: { label: "Underwriting", value: "underwriting" },
  TAX_RETURN: { label: "Tax Return", value: "tax_return" },
  COMPILED: { label: "Compiled", value: "compiled" },
  REVIEWED: { label: "Reviewed", value: "reviewed" },
  BUDGET: { label: "Budget", value: "budget" }
});

export const statementTypeCPM = Object.freeze({
  ANNUAL: { label: "Annual", value: "yearly" },
  QUARTERLY: { label: "Quarterly", value: "quarterly" },
  MONTHLY: { label: "Monthly", value: "monthly" }
});

export const methodPrepareCPM = Object.freeze({
  ACTUAL: { label: "Actual", value: "actual" },
  BUDGET: { label: "Budget", value: "budget" },
  PROFORMA: { label: "Proforma", value: "proforma" },
  UNDERWRITTEN: { label: "Underwritten", value: "underwritten" }
});

export const TemplateStatus = Object.freeze({
  PUBLISHED: "PUBLISHED",
  UNPUBLISHED: "UNPUBLISHED",
  ARCHIVED: "ARCHIVED"
});

export const TemplateRowType = Object.freeze({
  CATEGORY: "category",
  ACCOUNT: "account"
});

export const TemplateRowDefaults = Object.freeze([
  {
    name: "Income",
    code: null,
    type: TemplateRowType.CATEGORY,
    order: 0,
    is_credit: true
  },
  {
    name: "Operating Expenses",
    code: null,
    type: TemplateRowType.CATEGORY,
    order: 1,
    is_credit: false
  },
  {
    name: "Non-Operating",
    code: null,
    type: TemplateRowType.CATEGORY,
    order: 2,
    is_credit: false
  },
  {
    name: "Capital Expenditures",
    code: null,
    type: TemplateRowType.CATEGORY,
    order: 3,
    is_credit: false
  },
  {
    name: "Debt Servicing",
    code: null,
    type: TemplateRowType.CATEGORY,
    order: 4,
    is_credit: false
  }
]);

export const accountCodeRegex = /^[\d.-]+\s((-|\.)\s)?/g;
