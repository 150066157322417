import { merge } from "ramda";
import {
    COMPANY_TEMPLATES_FETCHING,
    COMPANY_TEMPLATES_FETCH_SUCCESSFUL,
    COMPANY_TEMPLATES_FETCH_FAILED,
    CLEAR_COMPANY_TEMPLATES_STORE
} from "ui/store/actions/companyTemplates";

export const FETCHING = "FETCHING";
export const LOADED = "LOADED";
export const FAILED = "FAILED";



export default function companyTemplates(
    state = {
        templates: [],
        status: FETCHING,
        totalCount: 0,
        page: 0,
        totalPages: 0
    },
    action
) {
    switch (action.type) {
        case COMPANY_TEMPLATES_FETCHING: {
            return merge(state, { status: FETCHING });
        }
        case CLEAR_COMPANY_TEMPLATES_STORE: {
            return merge(state, { templates: [] });
        }
        case COMPANY_TEMPLATES_FETCH_SUCCESSFUL: {
            const { templates } = action;
            return {
                ...state,
                templates,
                status: LOADED
            };
        }
        case COMPANY_TEMPLATES_FETCH_FAILED: {
            const { error } = action;
            return merge(state, { error, status: FAILED });
        }
        default:
            return state;
    }
}