import { apiFetch } from "./apiClient";
import { stringify } from "query-string";
import {
  setFiltersFromElasticSearchResult,
  updateElasticSearchMetrics
} from "ui/store/actions/filters";
import { formatRequestSearchObject } from "ui/helpers/search";
import { exportExcel } from "services/downloadExcel";
import { getExcelRentRollColumns } from "helpers/excel";
import moment from "moment";

export const FETCHING_TENANTS = "FETCHING_TENANTS";
export const FETCHING_TENANTS_SUCCESSFUL = "FETCHING_TENANTS_SUCCESSFUL";
export const FETCHING_TENANT_PROPERTIES_SUCCESSFUL =
  "FETCHING_TENANT_PROPERTIES_SUCCESSFUL";
export const COLLAPSE_TENANT_PROPERTIES = "COLLAPSE_TENANT_PROPERTIES";
export const EXPAND_TENANT_PROPERTIES = "EXPAND_TENANT_PROPERTIES";
export const FETCHING_EXCEL_RENT_ROLLS_SUCCESSFUL =
  "FETCHING_EXCEL_RENT_ROLLS_SUCCESSFUL";
export const CLEAR_SELECTED_PORTFOLIO_TENANT =
  "CLEAR_SELECTED_PORTFOLIO_TENANT";
export const CLEAR_METRICS_TENANT = "CLEAR_METRICS_TENANT";
export const CLEAR_TENANTS = "CLEAR_TENANTS";

export function searchTenants(
  portfolioId,
  searchObject = {},
  setFilters = false,
  paginationAndSort = {}
) {
  return async function(dispatch) {
    dispatch(fetchingTenants(portfolioId));
    try {
      const response = await apiFetch(
        `/api/rent-roll/search/tenants?${stringify({
          portfolioId,
          searchObject: formatRequestSearchObject({
            ...searchObject,
            paginationAndSort: paginationAndSort
          })
        })}`,
        {
          method: "get"
        }
      );
      if (!response.ok) {
        const responseBody = await response.json();
        throw new Error(responseBody.error);
      }
      const responseBody = await response.json();
      if (setFilters) {
        dispatch(setFiltersFromElasticSearchResult(responseBody.metrics));
      }
      dispatch(updateElasticSearchMetrics(responseBody.metrics));
      dispatch(
        fetchingTenantsSuccessful(
          responseBody.tenants,
          responseBody.selectedPortfolio,
          responseBody.metrics
        )
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export function getExcelRentRolls(
  portfolioId,
  searchObject = {}
) {
  return async function(dispatch) {
    try {
      const response = await apiFetch(
        `/api/rent-roll/search/excelRentRolls?${stringify({
          portfolioId,
          searchObject: formatRequestSearchObject(searchObject)
        })}`,
        {
          method: "get"
        }
      );
      if (!response.ok) {
        const responseBody = await response.json();
        throw new Error(responseBody.error);
      }
      const responseBody = await response.json();
      exportExcel(
        responseBody.excelRentRolls,
        responseBody.fileName,
        getExcelRentRollColumns()
      );
      dispatch(fetchingExcelRentRollsSuccessful(responseBody.excelRentRolls));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export function getTenantProperties(
  portfolioId,
  tenant,
  searchObject = {},
  action = "analysis",
  setFilters = false
) {
  return async function(dispatch) {
    try {
      const response = await apiFetch(
        `/api/rent-roll/search/tenantProperties?${stringify({
          portfolioId,
          searchObject: formatRequestSearchObject({
            ...searchObject,
            tenants: { [tenant]: true }
          })
        })}`,
        {
          method: "get"
        }
      );

      if (!response.ok) {
        const responseBody = await response.json();
        throw new Error(responseBody.error);
      }
      const responseBody = await response.json();
      if (action === "analysis") {
        if (setFilters) {
          dispatch(setFiltersFromElasticSearchResult(responseBody.metrics));
        }
        dispatch(updateElasticSearchMetrics(responseBody.metrics));
        dispatch(
          fetchingTenantPropertiesSuccessful(
            responseBody.tenants,
            responseBody.selectedPortfolio,
            responseBody.metrics
          )
        );
      } else if (action === "expand") {
        dispatch(
          expandTenantProperties(
            tenant,
            responseBody.tenants,
            responseBody.selectedPortfolio,
            responseBody.metrics
          )
        );
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export function fetchingTenants(portfolioId) {
  return { portfolioId, type: FETCHING_TENANTS };
}

export function fetchingTenantsSuccessful(tenants, selectedPortfolio, metrics) {
  return {
    tenants,
    selectedPortfolio,
    metrics,
    type: FETCHING_TENANTS_SUCCESSFUL
  };
}

export function fetchingExcelRentRollsSuccessful(excelRentRolls) {
  return {
    excelRentRolls,
    type: FETCHING_EXCEL_RENT_ROLLS_SUCCESSFUL
  };
}

export function fetchingTenantPropertiesSuccessful(
  tenants,
  selectedPortfolio,
  metrics
) {
  return {
    tenants,
    selectedPortfolio,
    metrics,
    type: FETCHING_TENANT_PROPERTIES_SUCCESSFUL
  };
}

export function expandTenantProperties(
  tenant,
  tenants,
  selectedPortfolio,
  metrics
) {
  return {
    tenant,
    tenants,
    selectedPortfolio,
    metrics,
    type: EXPAND_TENANT_PROPERTIES
  };
}

export function collapseTenantProperties(tenant) {
  return { tenant, type: COLLAPSE_TENANT_PROPERTIES };
}

export function clearSelectedPortfolioObj() {
  return { type: CLEAR_SELECTED_PORTFOLIO_TENANT };
}

export function clearMetricsObj() {
  return { type: CLEAR_METRICS_TENANT };
}

export function clearTenantsObj() {
  return { type: CLEAR_TENANTS };
}
