import { Component } from "react";
import PropTypes from "prop-types";
import enhanceWithClickOutside from "react-click-outside";
import { connect } from "react-redux";

import { hideDropDown, logoutQuiqProperty } from "ui/store/actions/accountMenu";
import { prop, props } from "ramda";
import propTypes from "prop-types";

export class AccountMenuDropDown extends Component {
  // handleClickOutside(event) {
  //   const { toggleClassName } = this.props;
  //   if (toggleClassName && event.target.closest(`.${toggleClassName}`)) {
  //     return;
  //   }
  //   this.props.handleClose();
  // }

  logout = () => {
    logoutQuiqProperty();
  };

  dropDown = view => {
    if (view) {
      return (
        <a href="/manage-files-view" className="AccountMenuDropDown__item">
          Home
        </a>
      );
    } else {
      return (
        <a href="/admin" className="AccountMenuDropDown__item">
          Settings
        </a>
      );
    }
  };

  render() {
    return (
      <div className="AccountMenuDropDown">
        <ul>
          <li>{this.dropDown(this.props.inAdminView)}</li>
          <li>
            <span onClick={this.logout} className="AccountMenuDropDown__item">
              Logout
            </span>
          </li>
        </ul>
      </div>
    );
  }
}

AccountMenuDropDown.propTypes = {
  inAdminView: propTypes.bool,
  handleClose: PropTypes.func.isRequired,
  toggleClassName: PropTypes.string
};

function mapDispatchToProps(dispatch) {
  return {
    handleClose: function() {
      dispatch(hideDropDown());
    }
  };
}

const EnhancedAccountMenuDropDown = enhanceWithClickOutside(
  AccountMenuDropDown
);
export default connect(null, mapDispatchToProps)(EnhancedAccountMenuDropDown);
