export const CATEGORY = "category";
export const SUBCATEGORY = "subcategory";
export const ACCOUNT = "account";
export const AMOUNT = "amount";
export const NEGATIVE_AMOUNT = "negative_amount";
export const PERCENT = "percent"; //percentage
export const INTEGER = "integer"; //whole number
export const INCOME = "income";
export const OPERATING_EXPENSES = "operating-expenses";
export const CAPEX = "capital-expenditures";
export const EXPENSES = "expenses";
export const NEEDS_REVIEW = "NEEDS_REVIEW";
export const FINANCIAL_STATEMENTS = "financial_statements";
export const ASSETS = "assets";
export const COMPANIES = "companies";
export const SET = "SET";
