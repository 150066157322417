import { apiFetch } from "./apiClient";

export const ADD_CELL_TO_DOCUMENT_NAV = "ADD_CELL_TO_DOCUMENT_NAV";
export const ADD_CELL_TO_RENT_ROLL_NAV = "ADD_CELL_TO_RENT_ROLL_NAV";
export const ADD_PAGE_TO_NAV = "ADD_PAGE_TO_NAV";

export const BEGIN_NAV_TO_DOCUMENT = "BEGIN_NAV_TO_DOCUMENT";
export const BEGIN_NAV_TO_CELL = "BEGIN_NAV_TO_CELL";
export const BEGIN_NAV_TO_PAGE = "BEGIN_NAV_TO_PAGE";

export const FINISH_NAV_TO_DOCUMENT = "FINISH_NAV_TO_DOCUMENT";
export const FINISH_NAV_TO_CELL = "FINISH_NAV_TO_CELL";
export const FINISH_NAV_TO_PAGE = "FINISH_NAV_TO_PAGE";

export const BEGIN_EXCEL_PARSE_TO_HTML = "BEGIN_EXCEL_PARSE_TO_HTML";
export const FINISH_EXCEL_PARSE_TO_HTML = "FINISH_EXCEL_PARSE_TO_HTML";

export const UPDATED_COMPLETED_AT = "UPDATED_COMPLETED_AT";
export const CLEAR_COMPLETED_AT = "CLEAR_COMPLETED_AT";

export const updateCompletedAt = date => ({
  date,
  type: UPDATED_COMPLETED_AT
});

export const clearCompletedAt = () => ({
  type: UPDATED_COMPLETED_AT
});

const beginExcelParseToHTML = () => ({
  type: BEGIN_EXCEL_PARSE_TO_HTML
});

const finishExcelParseToHTML = html => ({
  html,
  type: FINISH_EXCEL_PARSE_TO_HTML
});

export const parseExcelPageToHtml = (page, dataTableId) => {
  return async function(dispatch) {
    dispatch(beginExcelParseToHTML());
    try {
      const response = await apiFetch(`/api/rent-roll/parse-page-to-html/`, {
        method: "post",
        body: JSON.stringify({ page, dataTableId })
      });
      if (!response.ok) {
        const responseBody = await response.json();
        throw new Error(responseBody.error);
      }
      const responseBody = await response.json();
      dispatch(finishExcelParseToHTML(responseBody.html));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };
};

export const AddCellToDocumentNav = location => ({
  location,
  type: ADD_CELL_TO_DOCUMENT_NAV
});

export const AddCellToRentRollNav = location => ({
  location,
  type: ADD_CELL_TO_RENT_ROLL_NAV
});

export const AddPageToNav = page => ({
  page,
  type: ADD_PAGE_TO_NAV
});

export const BeginDocumentNav = () => ({
  type: BEGIN_NAV_TO_DOCUMENT
});

export const BeginRentRollNav = () => ({
  type: BEGIN_NAV_TO_CELL
});

export const BeginPageNav = () => ({
  type: BEGIN_NAV_TO_PAGE
});

export const EndDocumentNav = () => ({
  type: FINISH_NAV_TO_DOCUMENT
});

export const EndRentRollNav = () => ({
  type: FINISH_NAV_TO_CELL
});

export const EndPageNav = () => ({
  type: FINISH_NAV_TO_PAGE
});
