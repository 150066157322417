import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { withRouter } from "react-router";
import cx from "classnames";
import { companyGroupNames } from "helpers/companies";
import { createNewCompany } from "ui/store/actions/companies";

function NewCompanyForm({ handleSubmit, error }) {
  return (
    <form method="post" onSubmit={handleSubmit} className="NewCompanyForm">
      <ul className="NewCompanyForm__fields">
        <li className="NewCompanyForm__list">
          <div>
            <label htmlFor="email" className="NewCompanyForm__label">
              Company Name
            </label>
          </div>
          <div className="NewCompanyForm__field">
            <Field
              className={cx("NewCompanyForm__input", {
                "NewCompanyForm__input--error": error && error.companyName
              })}
              component="input"
              type="text"
              name="companyName"
            />
            {error && error.email && (
              <div className="NewCompanyForm__error">{error.companyName}</div>
            )}
          </div>
        </li>
        <li className="NewCompanyForm__list">
          <div>
            <label htmlFor="groupName" className="NewCompanyForm__label">
              Group
            </label>
          </div>
          <div className="NewCompanyForm__field">
            <Field
              className={cx("NewCompanyForm__input", {
                "NewCompanyForm__input--error": error && error.companyName
              })}
              component="select"
              type="select"
              name="groupName"
              onChange={e => {
                const val = e.target.value;
                if (val === "CPM") {
                  document
                    .getElementById("externalID")
                    .removeAttribute("disabled");
                } else {
                  document
                    .getElementById("externalID")
                    .setAttribute("disabled", "");
                }
              }}
            >
              {Object.keys(companyGroupNames).map(groupName => (
                <option key={groupName} value={companyGroupNames[groupName].id}>
                  {companyGroupNames[groupName].label}
                </option>
              ))}
            </Field>
            {error && error.groupName && (
              <div className="NewCompanyForm__error">{error.companyName}</div>
            )}
          </div>
        </li>
        <li className="NewCompanyForm__list">
          <div>
            <label htmlFor="email" className="NewCompanyForm__label">
              External Id (Input the Company ref uuid found in the CPM Company
              Admin Console only if there is a preexisting account for this
              client in CPM)
            </label>
          </div>
          <div className="NewCompanyForm__field">
            <Field
              className={cx("NewCompanyForm__input", {
                "NewCompanyForm__input--error": error && error.companyName
              })}
              component="input"
              type="text"
              name="externalId"
              id="externalID"
              disabled
            />
            {error && error.email && (
              <div className="NewCompanyForm__error">{error.companyName}</div>
            )}
          </div>
        </li>
        <li className="NewCompanyForm__list">
          <div>
            <label htmlFor="role" className="NewCompanyForm__label">
              Set Max Active Users
            </label>
          </div>
          <div className="NewCompanyForm__field">
            <Field
              className={cx("NewCompanyForm__input", {
                "NewCompanyForm__input--error": error && error.maxActiveUsers
              })}
              component="input"
              type="number"
              name="maxActiveUsers"
            />
            {error && error.maxActiveUsers && (
              <div className="NewCompanyForm__error">
                {error.maxActiveUsers}
              </div>
            )}
          </div>
        </li>
        <li className="NewCompanyForm__list">
          <div>
            <label htmlFor="role" className="NewCompanyForm__label">
              Select Account Duration Type
            </label>
          </div>
          <div className="NewCompanyForm__field">
            <Field
              className={cx("NewCompanyForm__select", {
                "NewCompanyForm__select--error": error && error.maxActiveUsers
              })}
              component="select"
              name="accountActiveLengthType"
            >
              <option value="">Select Type</option>
              <option value="day">day</option>
              <option value="week">week</option>
              <option value="month">month</option>
              <option value="year">year</option>
            </Field>
            <div className="NewCompanyForm__select-caret" />
            {error && error.maxActiveUsers && (
              <div className="NewCompanyForm__error">
                {error.maxActiveUsers}
              </div>
            )}
          </div>
        </li>
        <li className="NewCompanyForm__list">
          <div>
            <label htmlFor="role" className="NewCompanyForm__label">
              Set Account Duration Based on Type Selection (Starting Today)
            </label>
          </div>
          <div className="NewCompanyForm__field">
            <Field
              className={cx("NewCompanyForm__input", {
                "NewCompanyForm__input--error":
                  error && error.accountActiveLengthAmmount
              })}
              component="input"
              type="number"
              name="accountActiveLengthAmmount"
            />
            {error && error.accountActiveLengthAmmount && (
              <div className="NewCompanyForm__error">
                {error.accountActiveLengthAmmount}
              </div>
            )}
          </div>
        </li>
        <li>
          <input
            type="submit"
            value="Submit"
            className="NewCompanyForm__submit Button Button--action"
          />
        </li>
      </ul>
    </form>
  );
}

NewCompanyForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  error: PropTypes.object
};

const NewUserReduxForm = reduxForm({
  form: "newCompany",
  initialValues: {
    companyName: "",
    groupName: "",
    externalId: "",
    maxActiveUsers: 5,
    accountActiveLengthAmmount: 1,
    accountActiveLengthType: "year"
  }
})(NewCompanyForm);

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmit: (values, dispatch) => {
      dispatch(createNewCompany(values, ownProps.history));
    }
  };
};

export default withRouter(connect(null, mapDispatchToProps)(NewUserReduxForm));
