import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { returnTypeBucket, returnTypeCount } from "./filterUtils";

import Button from "ui/components/shared/Button";
import FilterCheckbox from "./FilterCheckbox";

export class FilterExpanded extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subFilters: [],
      structuredSubfilters: [],
      showMore: false,
      isLoading: true,
      pageIdx: 1,
      totalPages: 1
    };
  }

  componentDidMount() {
    const { allFilters, expandedType, metrics } = this.props;
    if (allFilters && expandedType !== "") {
      this.setState({
        isLoading: false,
        totalPages:
          returnTypeCount(metrics, expandedType) <= 200
            ? Math.ceil(returnTypeCount(metrics, expandedType) / 20)
            : 10
      });
      this.updateFilterList();
    }
  }

  async componentDidUpdate(prevProps) {
    const { expandedType, metrics } = this.props;
    if (prevProps.metrics !== metrics) {
      this.setState(
        {
          isLoading: false,
          totalPages:
            returnTypeCount(metrics, expandedType) <= 200
              ? Math.ceil(returnTypeCount(metrics, expandedType) / 20)
              : 10,
          pageIdx: 1
        },
        () => this.updateFilterList()
      );
    }
    if (prevProps.expandedType !== expandedType) {
      await this.setState(
        {
          isLoading: false,
          pageIdx: 1,
          totalPages:
            returnTypeCount(metrics, expandedType) <= 200
              ? Math.ceil(returnTypeCount(metrics, expandedType) / 20)
              : 10
        },
        () => this.updateFilterList()
      );
    }
  }

  restructureFilterList = () => {
    const { subFilters } = this.state;
    const rowCap = 10;
    const colCap = 2;
    let structured = [
      [...subFilters.slice(0, rowCap)],
      [...subFilters.slice(rowCap, 2 * rowCap)]
    ];
    this.setState({ structuredSubfilters: structured });
    if (subFilters.length > colCap * rowCap) {
      this.setState({ showMore: true });
    }
  };

  generateExpandedColumn = (list, index) => {
    const { expandedType, updateCanSearch } = this.props;
    return (
      list &&
      list.length > 0 && (
        <div
          className="FilterExpanded__column"
          key={index}
          style={{ order: index }}
        >
          {list.map(checkItem => (
            <FilterCheckbox
              className="FilterExpanded__checkbox"
              filterType={expandedType}
              key={checkItem}
              name={checkItem}
              updateCanSearch={updateCanSearch}
            />
          ))}
        </div>
      )
    );
  };

  updateFilterList = () => {
    const { metrics, expandedType } = this.props; //note: expandedType should be consistent w. allFilters field name
    const beginingSliceIdx =
      this.state.pageIdx === 1 ? 0 : (this.state.pageIdx - 1) * 20;
    const endSliceIdx = beginingSliceIdx + 20;
    const allFiltersOnType = returnTypeBucket(metrics, expandedType);
    this.setState(
      {
        subFilters: [...allFiltersOnType.slice(beginingSliceIdx, endSliceIdx)]
      },
      () => this.restructureFilterList()
    );
  };

  render() {
    const { expandedHeader, onDone } = this.props;
    const { isLoading, structuredSubfilters, pageIdx, totalPages } = this.state;

    return (
      <div className="FilterExpanded__panel">
        <div className="FilterExpanded__prompt">
          Please select {expandedHeader}
        </div>
        <div className="FilterExpanded__subpanel">
          {!isLoading &&
            structuredSubfilters &&
            structuredSubfilters.length > 0 &&
            structuredSubfilters.map((list, index) =>
              this.generateExpandedColumn(list, index)
            )}
        </div>
        <div className="FilterBody__doneButton">
          <div className="FilterBody__paginationControl">
            {pageIdx > 1 && (
              <img
                src={require("ui/images/icon-chevron-right.svg")}
                style={{ transform: "rotate(180deg)", cursor: "pointer" }}
                onClick={async () => {
                  const newPageIdx = pageIdx - 1;
                  await this.setState({ pageIdx: newPageIdx });
                  this.updateFilterList();
                }}
              />
            )}
            {totalPages !== 0 && `${pageIdx} / ${totalPages}`}
            {pageIdx < totalPages && (
              <img
                src={require("ui/images/icon-chevron-right.svg")}
                style={{ cursor: "pointer" }}
                onClick={async () => {
                  const newPageIdx = pageIdx + 1;
                  await this.setState({ pageIdx: newPageIdx });
                  this.updateFilterList();
                }}
              />
            )}
          </div>
          <Button
            className="Button__blackButton"
            style={{
              padding: "8px 30px",
              marginLeft: "5px",
              textTransform: "none",
              fontWeight: "normal"
            }}
            onClick={onDone}
          >
            Done
          </Button>
        </div>
      </div>
    );
  }
}

FilterExpanded.propTypes = {
  isExpanded: PropTypes.bool,
  searchPanelOn: PropTypes.bool,
  expandedType: PropTypes.string,
  expandedHeader: PropTypes.string,
  allFilters: PropTypes.object,
  searchedFilters: PropTypes.object,
  metrics: PropTypes.object,
  onDone: PropTypes.func,
  updateCanSearch: PropTypes.func
};

function mapStateToProps(state) {
  return {
    allFilters: state.filters.filters,
    metrics: state.filters.metrics
  };
}

export default withRouter(connect(mapStateToProps)(FilterExpanded));
