import { apiFetch } from "ui/store/actions/apiClient";
import queryString from "query-string";

export const predefSuggestionListSize = 10; //lazy load 10 suggestions
export const predefsByIdsListSize = 20; //lazy load 20 predef values

export async function fetchPredefSugestionList(
  documentId,
  values,
  field,
  idx,
  widgetAuth = {}
) {
  try {
    const start = idx > 0 ? idx * predefSuggestionListSize : 0;
    const end =
      idx > 0 ? start + predefSuggestionListSize : predefSuggestionListSize;
    const valueString = values.map(value => JSON.stringify({ value, field }));
    const query = { values: valueString.slice(start, end), idx };
    const response = await apiFetch(
      `/api/predef-fields/suggest-list/${documentId}?${queryString.stringify(
        query,
        { arrayFormat: "index" }
      )}`,
      {
        method: "get"
      },
      widgetAuth
    );
    if (!response.ok) {
      const responseBody = await response.json();
      throw new Error(responseBody.error);
    }
    const responseBody = await response.json();
    const { suggestionList } = responseBody;
    return suggestionList;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}

export async function fetchPredefsByIds(predefIds, idx, widgetAuth = {}) {
  try {
    const start = idx > 0 ? idx * predefsByIdsListSize : 0;
    const end = idx > 0 ? start + predefsByIdsListSize : predefsByIdsListSize;
    const query = { predefIds: predefIds.slice(start, end), idx };
    const response = await apiFetch(
      `/api/predef-fields/list-by-ids?${queryString.stringify(query, {
        arrayFormat: "index"
      })}`,
      {
        method: "get"
      },
      widgetAuth
    );
    if (!response.ok) {
      const responseBody = await response.json();
      throw new Error(responseBody.error);
    }
    const responseBody = await response.json();
    const { predefs } = responseBody;
    return predefs;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}
