import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  updateFilter,
  addToActiveFilters,
  removeFromActiveFilters
} from "ui/store/actions/filters";
import { bindActionCreators } from "redux";

export class FilterCheckbox extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onSelectCheckbox = item => {
    const {
      allFilters,
      filterType,
      updateFilter,
      addToActiveFilters,
      removeFromActiveFilters,
      updateCanSearch
    } = this.props; //note: expandedType should be consistent w. allFilters field name
    updateCanSearch(true);
    updateFilter(filterType, !allFilters[filterType][item], item);
    if (!allFilters[filterType][item]) {
      addToActiveFilters(filterType, item);
    } else {
      removeFromActiveFilters(filterType, item);
    }
  };

  isFilterChecked = subfilter => {
    const { allFilters, filterType } = this.props; //note: expandedType should be consistent w. allFilters field name
    return allFilters &&
      allFilters[filterType] &&
      allFilters[filterType][subfilter]
      ? allFilters[filterType][subfilter]
      : false;
  };

  render() {
    const { name, filterType } = this.props;
    return (
      <div className="FilterExpanded__checkbox">
        <input
          type="checkbox"
          name={name}
          checked={this.isFilterChecked(name)}
          onClick={() => this.onSelectCheckbox(name)}
        />
        {filterType === "tenants" && name.toUpperCase()}
        {filterType !== "tenants" && name.toLowerCase()}
      </div>
    );
  }
}

FilterCheckbox.propTypes = {
  name: PropTypes.string,
  checked: PropTypes.bool,
  toggleCheck: PropTypes.func,
  filterType: PropTypes.string,
  allFilters: PropTypes.object,
  disabled: PropTypes.bool,
  updateFilter: PropTypes.func,
  addToActiveFilters: PropTypes.func,
  removeFromActiveFilters: PropTypes.func,
  updateCanSearch: PropTypes.func
};

function mapStateToProps(state) {
  return {
    allFilters: state.filters.filters,
    metrics: state.filters.metrics
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { updateFilter, addToActiveFilters, removeFromActiveFilters },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterCheckbox);
