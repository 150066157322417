import PropTypes from "prop-types";
import { connect } from "react-redux";

import BrowseUpload from "ui/components/main/upload/BrowseUpload";
import DragAndDrop from "ui/components/rentRoll/property/fileUpload/DragAndDrop";
import { documentFileType } from "server/middleware/api/helpers/documents.js";

const DragPanel = ({ saveDroppedFile }) => {
  const importPrompt = () => {
    return (
      <div className="DragPanel__panel">
        <div className="DragPanel__dragBox">
          <div className="DragPanel__prompt">
            Drag files here or
            <BrowseUpload saveDroppedFile={saveDroppedFile} />
          </div>
          <div className="DragPanel__subPrompt">
            We support{" "}
            {Object.keys(documentFileType)
              .join(", ")
              .replace(/, ([^,]*)$/, " and $1")}
          </div>
        </div>
      </div>
    );
  };

  return (
    <DragAndDrop
      saveDroppedFile={saveDroppedFile}
      importPrompt={importPrompt()}
    />
  );
};

DragPanel.propTypes = {
  saveDroppedFile: PropTypes.func
};

export default connect()(DragPanel);
