import moment from "moment";

export const createDataObjForNewRow = rowFormData => {
  const bodyData = { data_jsonb: {} };
  Object.keys(rowFormData).forEach(field => {
    if (!rowFormData[field]) {
      bodyData[rowFormData] = null;
    } else {
      switch (field) {
        case "lease_start_date":
        case "lease_end_date":
          bodyData[field] = rowFormData[field];
          bodyData["data_jsonb"][field] = moment(rowFormData[field]).format(
            "MM/DD/YYYY"
          );
          break;
        case "tenant_name":
        case "unit_name":
        case "space_type":
        case "is_vacant":
          bodyData[field] = rowFormData[field];
          bodyData["data_jsonb"][field] = rowFormData[field];
          break;
        case "annual_rent_per_sqft":
        case "annual_rent":
        case "monthly_rent_per_sqft":
        case "monthly_rent":
        case "size_sqft":
          bodyData[field] = parseFloat(rowFormData[field]);
          bodyData["data_jsonb"][field] = rowFormData[field];
          bodyData["data_jsonb"][`${field}_num`] = parseFloat(
            rowFormData[field]
          );
          break;
        default:
          bodyData["data_jsonb"][field] = rowFormData[field];
          bodyData["data_jsonb"][`${field}_num`] = parseFloat(
            rowFormData[field]
          );
          break;
      }
    }
  });
  if (bodyData["size_sqft"]) {
    if (bodyData["annual_rent_per_sqft"] && !bodyData["annual_rent"]) {
      bodyData["annual_rent"] =
        bodyData["annual_rent_per_sqft"] * bodyData["size_sqft"];
    }
    if (bodyData["monthly_rent_per_sqft"] && !bodyData["monthly_rent"]) {
      bodyData["monthly_rent"] =
        bodyData["monthly_rent_per_sqft"] * bodyData["size_sqft"];
    }
  }
  return bodyData;
};
