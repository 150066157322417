import { createRef, Component } from "react";
import PropTypes from "prop-types";

export default class DragAndDrop extends Component {
  state = {
    dragging: false
  };

  dragCounter = 0;
  dropRef = createRef();

  handleDrag = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleDragIn = e => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter++;
    // TODO consider adding an error message if user drags more than one file
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({ dragging: true });
    }
  };

  handleDragOut = e => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter--;
    if (this.dragCounter === 0) {
      this.setState({ dragging: false });
    }
  };

  handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ dragging: false });
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      if (e.dataTransfer.items[0].kind === "file") {
        const { items } = e.dataTransfer;
        for (let item of items) {
          this.props.saveDroppedFile(item.getAsFile());
        }
      }
      e.dataTransfer.clearData();
      this.dragCounter = 0;
      this.setState({ dragging: false });
    }
  };

  onDragOver = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleFileUpload = event => {
    this.setState({ file: event.target.files });
  };

  render() {
    return (
      <div
        className="DragAndDrop__container"
        onDragEnter={e => this.handleDragIn(e)}
        onDragLeave={e => this.handleDragOut(e)}
        onDragOver={e => this.onDragOver(e)}
        onDrop={e => this.handleDrop(e)}
      >
        {this.props.importPrompt ? (
          this.props.importPrompt
        ) : (
          <div className="DragAndDrop__file-upload-target">
            {this.state.dragging && (
              <div className="DragAndDrop__drag-over-overlay-box">
                <div className="DragAndDrop__drag-over-overlay-box__content">
                  drop here
                </div>
              </div>
            )}
            <h2 className="DragAndDrop__file-upload-target__header">
              Drag & Drop a File Here
            </h2>
            <br />
            <img
              style={{
                height: 80
              }}
              src={require("ui/images/icon-upload.svg")}
            />
            <br />
            <h3>or, if you&apos;d prefer...</h3>
            <br />

            {this.props.children}
          </div>
        )}
        {/* {this.state.imagePreviewURL && (
          <div className="DragAndDrop__preview-image">
            <h4>PREVIEW</h4>
            <img
              src={this.state.imagePreviewURL}
              className="DragAndDrop__upload_preview"
            />
          </div>
        )} */}
        <div>
          {/* <ItemForm
            label="Effective Date"
            name="effective_date"
            value={this.state.effective_date}
            onChange={val => this.setState({ effective_date: val })}
            type="number"
          /> */}

          {/* <DatePicker
            selected={this.state.effective_date}
            onChange={date => this.setState({ effective_date: date })}
          /> */}
          {/* <Button>Upload</Button> */}
        </div>
      </div>
    );
  }
}

DragAndDrop.propTypes = {
  saveDroppedFile: PropTypes.func,
  importPrompt: PropTypes.object,
  children: PropTypes.object
};
