import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import cx from "classnames";
import Spinner from "ui/components/shared/Spinner";

import CellUpdatePredefOptions from "./CellUpdatePredefOptions";

import {
  fetchPredefSugestions,
  updateDocumentField,
  createNewPredefAndUpdateField
} from "ui/store/actions/rentRoll";

import { predefConfig } from "./helpers/predef";

const CellUpdatePredef = ({
  documentId,
  cellData,
  updateDocumentField,
  fetchPredefSugestions,
  location,
  predefValues,
  createNewPredefAndUpdateField,
  closePopover,
  isPredefLoading,
  widgetAuth
}) => {
  const { pi, ti, ri, ci } = location;

  const [step, updateStep] = useState(predefConfig.search_for_value);
  const [selected, updateSelected] = useState();
  const [newPredefSelected, updateNewPredefSelected] = useState(false);
  const [inputVal, updateInputVal] = useState(cellData.text);

  useEffect(() => {
    if (
      predefValues.length === 0 &&
      cellData.text &&
      step !== predefConfig.select_a_value
    ) {
      fetchPredefSugestions(
        documentId,
        cellData.text,
        "space_type",
        widgetAuth
      );
      updateStep(predefConfig.select_a_value);
    }
  }, [predefValues]);

  useEffect(() => {
    const preselectedValueIdx = predefValues.findIndex(value => {
      if (cellData && cellData.predef) {
        return value.value === cellData.predef.value;
      } else {
        return false;
      }
    });

    if (preselectedValueIdx > -1) {
      updateSelected(preselectedValueIdx);
    }
  }, [predefValues]);

  const handleInputUpdate = e => {
    if (e.key === "Enter") {
      fetchPredefSugestions(
        documentId,
        e.target.value,
        "space_type",
        widgetAuth
      );
      updateStep(predefConfig.select_a_value);
    }
  };

  const handleOnChange = e => {
    updateInputVal(e.target.value);
  };

  const updateValue = async () => {
    const fieldLocation = {
      page: pi,
      table: ti,
      row: ri,
      col: ci
    };
    if (newPredefSelected) {
      await createNewPredefAndUpdateField(
        inputVal,
        "space_type",
        documentId,
        fieldLocation,
        widgetAuth
      );
    } else {
      const field = {
        ...fieldLocation,
        text: predefValues[selected].value,
        predefValueId: predefValues[selected].id,
        ref: predefValues[selected].ref
      };
      await updateDocumentField(field, documentId, widgetAuth);
    }
  };

  return (
    <>
      <div className="Popover__popupHeader">Suggested Change</div>
      <div className="Popover__changeDescription">{predefConfig[step]}</div>
      <input
        className="Popover__input"
        type="text"
        value={inputVal}
        onKeyDown={handleInputUpdate}
        onChange={handleOnChange}
      />
      {!isPredefLoading ? (
        predefValues.length > 0 ? (
          <CellUpdatePredefOptions
            selected={selected}
            updateSelected={updateSelected}
            predefValues={predefValues}
          />
        ) : (
          <div className="Popover__loadingContent">No results</div>
        )
      ) : (
        <Spinner
          type="ThreeDots"
          classname="Popover__loadingContent"
          color="#00BFFF"
          height={40}
          width={40}
        />
      )}

      <div
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          marginTop: "10px"
        }}
      >
        <button
          className="Popover__updateButton"
          style={{ marginLeft: "5px", color: "gray" }}
          disabled={
            step !== predefConfig.select_a_value &&
            step !== predefConfig.create_new_predef
          }
          onClick={() => {
            closePopover();
          }}
        >
          Cancel
        </button>
        <button
          className="Popover__updateButton"
          disabled={
            step !== predefConfig.select_a_value &&
            step !== predefConfig.create_new_predef
          }
          onClick={() => {
            updateValue();
            closePopover();
          }}
        >
          Update
        </button>
      </div>
    </>
  );
};

CellUpdatePredef.defaultProps = {
  widgetAuth: {}
};

CellUpdatePredef.propTypes = {
  documentId: PropTypes.number,
  cellData: PropTypes.object,
  isPredefLoading: PropTypes.bool,
  updateValue: PropTypes.string,
  location: PropTypes.object,
  predefValues: PropTypes.array,
  updateIsOpen: PropTypes.func,
  updateDocumentField: PropTypes.func,
  fetchPredefSugestions: PropTypes.func,
  createNewPredefAndUpdateField: PropTypes.func,
  closePopover: PropTypes.func,
  widgetAuth: PropTypes.object,
  isMultiFamily: PropTypes.bool
};

const mapStateToProps = ({ rentRoll }) => ({
  predefValues: rentRoll.predefValues,
  isPredefLoading: rentRoll.isPredefLoading
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchPredefSugestions,
      updateDocumentField,
      createNewPredefAndUpdateField
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CellUpdatePredef);
