import PropTypes from "prop-types";
import cx from "classnames";
import { useState, useEffect, useRef } from "react";
import _ from "lodash";
import OpexPage from "../OpexPage/OpexPage";
import OpexDocument from "../../modelOpexDocument";
import OpexPageNumber from "../OpexPageNumber/OpexPageNumber";
import OpexModal from "../OpexModal/OpexModal";
import Spinner from "ui/components/shared/Spinner";

const ChartOfAccounts = ({ document, onChange, isMapping }) => {
  const didMount = useRef(false);

  if (!document) {
    return null;
  }

  const [currentDoc, setCurrentDoc] = useState(document);
  const [showApplyAllModal, setShowApplyAllModal] = useState(false);
  const [showIntroModal, setShowIntroModal] = useState(true);
  const [clickedColumnData, setClickedColumnData] = useState({});
  const documentModel = new OpexDocument(currentDoc);

  const handleOnColumnClick = (pageIndex, tableIndex, columnIndex) => {
    setClickedColumnData({
      pageIndex,
      tableIndex,
      columnIndex
    });
  };

  useEffect(() => {
    // Do not run on first mount. If it runs on first mount a save will trigger
    // and save the COA as null (ie. wipes the COA)
    if (didMount.current === false) {
      didMount.current = true;
      return null;
    }

    // Excel documents don't need the modal since there is always only 1 table.
    if (documentModel.isPdf()) {
      setShowApplyAllModal(true);
    } else {
      handleOnConfirmShowApplyAllModal();
    }
  }, [clickedColumnData]);

  const handleOnCancelShowApplyAllModal = () => {
    const { pageIndex, tableIndex, columnIndex } = clickedColumnData;
    documentModel.setChartOfAccountsColumnIndexByPageIndexTableIndexColumnIndex(
      pageIndex,
      tableIndex,
      columnIndex
    );
    const nextDocument = { ...documentModel.getDocument() };
    const nextMetaDataJson = documentModel.getMetaDataJson();

    setCurrentDoc(nextDocument);
    onChange(nextMetaDataJson);
    setShowApplyAllModal(false);
  };

  const handleOnConfirmShowApplyAllModal = () => {
    const { columnIndex } = clickedColumnData;
    documentModel.setAllChartOfAccountsColumnIndexByColumnIndex(columnIndex);
    const nextDocument = { ...documentModel.getDocument() };
    const nextMetaDataJson = documentModel.getMetaDataJson();

    setCurrentDoc(nextDocument);
    onChange(nextMetaDataJson);
    setShowApplyAllModal(false);
  };

  const renderPage = pageIndex => {
    return (
      <div key={pageIndex}>
        <OpexPageNumber pageIndex={pageIndex} />
        <OpexPage
          views={["columns"]}
          document={currentDoc}
          pageIndex={pageIndex}
          onColumnClick={(tableIndex, columnIndex) =>
            handleOnColumnClick(pageIndex, tableIndex, columnIndex)
          }
        />
      </div>
    );
  };

  const renderPages = () => {
    if (documentModel.isExcel()) {
      const pageIndex = documentModel.getQualifiedSheetHeaderIndex();
      return renderPage(pageIndex);
    }

    if (documentModel.isPdf()) {
      const pages = documentModel.getPages();
      return pages.map((page, pageIndex) => renderPage(pageIndex));
    }

    return "Unrecognized file type.";
  };

  const tableSectionClass = cx(
    "OpexPanelView__TableSelection",
    documentModel.isExcel() ? "TableSelectionExcel" : ""
  );

  return isMapping ? (
    <div className="OpexPanelView__DocumentView__Spinner-Wrapper">
      <Spinner type="ThreeDots" color="#009bdf" height={40} width={40} />
    </div>
  ) : (
    <>
      <OpexModal
        headerText=""
        open={showApplyAllModal}
        onClose={() => setShowApplyAllModal(false)}
        confirmText="All tables"
        cancelText="Just this table"
        onConfirm={handleOnConfirmShowApplyAllModal}
        onCancel={handleOnCancelShowApplyAllModal}
        body={
          <>
            Do you want to define this column as the chart of accounts for
            <br />
            all tables or just the one selected?
          </>
        }
      />
      <OpexModal
        headerText="Define chart of accounts"
        open={showIntroModal}
        onClose={() => setShowIntroModal(false)}
        confirmText="Got it"
        body={
          <>
            In order to finish table selection, please indicate the columns of
            <br />
            each table that contains the chart of accounts to be mapped. When
            <br />
            finishing you can proceed to the mapping process.
          </>
        }
      />
      <div className={tableSectionClass}>{renderPages()}</div>
    </>
  );
};

export default ChartOfAccounts;

ChartOfAccounts.propTypes = {
  document: PropTypes.object,
  onChange: PropTypes.func,
  isMapping: PropTypes.bool
};
