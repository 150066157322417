import useSWR from "swr";
import fetchData from "../helpers/fetchData";
import useSplitPanelV2Context from "./useSplitPanelV2Context";

export default function useExceptions() {
  const { documentId, widgetAuth } = useSplitPanelV2Context();

  const { data, error, mutate } = useSWR(
    `/api/rent-roll/exceptions/${documentId}`,
    fetchData(widgetAuth)
  );

  return {
    exceptions: data ? data.exceptions : null,
    error,
    mutate,
    isLoading: !error && !data
  };
}
