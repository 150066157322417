import { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import cx from "classnames";
import LoginLayout from "./LoginLayout";
import { withRouter } from "react-router";

import Flash from "ui/components/notification/Flash";
import LoginModal from "ui/components/account/LoginModal";

class MainLoginPage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { login, loginError } = this.props;
    return (
      <LoginLayout className={cx("MainLoginPage")}>
        <Flash />
        {login && <LoginModal loginError={loginError} />}
        <div className="MainLoginPage__container">
          <div className="MainLoginPage__main">
            <div className="MainLoginPage__main__desc">
              <h1>QUIQproperty for Better, Faster Decisions</h1>
              <ul>
                <li>Automated rent roll reader</li>
                <li>Intelligent tenant and property mapping</li>
                <li>Tenant + Property analysis</li>
                <li>CreditLens Integration</li>
              </ul>
            </div>
            <div className="MainLoginPage__main__imgContainer">
              <img
                src={require("ui/images/laptop-screenshot.png")}
                style={{ width: "100%" }}
              />
            </div>
          </div>
          <div className="MainLoginPage__features">
            <div className="MainLoginPage__features__section">
              <img
                src={require("ui/images/cloud-download-icon.png")}
                style={{
                  marginTop: "120%"
                }}
              />
              <p className="MainLoginPage__features__section--1">
                Start with property details from origination or portfolio system
              </p>
            </div>
            <div className="MainLoginPage__features__section">
              <img
                src={require("ui/images/formats-icon.png")}
                style={{
                  marginTop: "80%"
                }}
              />
              <p className="MainLoginPage__features__section--2">
                Load and parse rent rolls from various formats
              </p>
            </div>
            <div className="MainLoginPage__features__section">
              <img src={require("ui/images/graph-ml-icon.png")} />
              <p className="MainLoginPage__features__section--3">
                Associate tenants and properties with customers, counterparties
                and individuals -- create a graph machine learning to identify
                relationships &amp; score tennants
              </p>
            </div>
            <div className="MainLoginPage__features__section">
              <img
                src={require("ui/images/analyze-benchmark-icon.png")}
                style={{
                  marginTop: "80%"
                }}
              />
              <p className="MainLoginPage__features__section--4">
                Analyze rent rolls across portfolios, versus benchmarks and with
                scenarios
              </p>
            </div>
            <div className="MainLoginPage__features__section">
              <img
                src={require("ui/images/cloud-refresh-icon.png")}
                style={{
                  marginTop: "120%"
                }}
              />
              <p className="MainLoginPage__features__section--5">
                Synchronize with loan portfolio managment / origination system
              </p>
            </div>
          </div>
          <div className="MainLoginPage__reladex">
            <div className="MainLoginPage__reladex__header">
              <h2>Reladex -- Relationship Index Service</h2>
            </div>
            <div className="MainLoginPage__reladex__description">
              <div className="MainLoginPage__reladex__image">
                <img
                  src={require("ui/images/reladex-graph.png")}
                  style={{ width: "100%" }}
                />
              </div>
              <div className="MainLoginPage__reladex__details">
                <ul>
                  <li>
                    Expand your CRE analysis with tenant and property machine
                    learning mapping
                  </li>
                  <li>
                    Able to connect to internal and external data sources to
                    enrich your data
                  </li>
                  <li>
                    Preconfigured to connect to 375M BvD Entities and 15M Reis
                    Properties
                  </li>
                  <li>
                    Uncover hidden concentrations and relationships in your
                    portfolio
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="MainLoginPage__featuresTwo">
            <div className="MainLoginPage__featuresTwo__section">
              <div className="MainLoginPage__featuresTwo__header">
                <div
                  className={cx(
                    "MainLoginPage__featuresTwo__logo",
                    "MainLoginPage__featuresTwo__logo--1"
                  )}
                >
                  <img src={require("ui/images/rent-roll-icon-white.svg")} />
                </div>
                <div className="MainLoginPage__featuresTwo__title">
                  Rent Roll Reader
                </div>
              </div>
              <div className="MainLoginPage__featuresTwo__body">
                <h3>Save time</h3>
                <p>Automate tedious keying of rent rolls</p>
                <h3>Format flexible</h3>
                <p>
                  Handles rent roll files from xls, pdf, csv as well as common
                  property valuation and accounting systems
                </p>
              </div>
            </div>
            <div className="MainLoginPage__featuresTwo__section">
              <div className="MainLoginPage__featuresTwo__header">
                <div
                  className={cx(
                    "MainLoginPage__featuresTwo__logo",
                    "MainLoginPage__featuresTwo__logo--2"
                  )}
                >
                  <img
                    src={require("ui/images/machine-learning-icon-white.svg")}
                  />
                </div>
                <div className="MainLoginPage__featuresTwo__title">
                  Machine Learning
                </div>
              </div>
              <div className="MainLoginPage__featuresTwo__body">
                <h3>AI for Rent Rolls</h3>
                <p>
                  Table recognition trained on multiple formats (pdf, xls, doc)
                  and auto-mapping of tenant names
                </p>
                <h3>Improve monitoring</h3>
                <p>
                  Updates and tracks history of rent rolls to show property
                  quality through time
                </p>
              </div>
            </div>
            <div className="MainLoginPage__featuresTwo__section">
              <div className="MainLoginPage__featuresTwo__header">
                <div
                  className={cx(
                    "MainLoginPage__featuresTwo__logo",
                    "MainLoginPage__featuresTwo__logo--3"
                  )}
                >
                  <img
                    src={require("ui/images/portfolio-analysis-icon-white.svg")}
                  />
                </div>
                <div className="MainLoginPage__featuresTwo__title">
                  QUIQspread Extension for CRE
                </div>
              </div>
              <div className="MainLoginPage__featuresTwo__body">
                <h3>Workflow Automation</h3>
                <p>
                  Supports CreditLens rent roll analysis and arguments
                  QUIQspread financial analysis for CRE
                </p>
                <h3>Rapid Cloud Deployment</h3>
                <p>Back-office and front office solutions available</p>
              </div>
            </div>
          </div>
        </div>
      </LoginLayout>
    );
  }
}

MainLoginPage.propTypes = {
  loginError: PropTypes.string,
  login: PropTypes.bool
};

function mapStateToProps({ loginError, mainLoginPage }) {
  return {
    loginError,
    login: mainLoginPage.login
  };
}

export default withRouter(connect(mapStateToProps)(MainLoginPage));
