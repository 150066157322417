import PropTypes from "prop-types";
import cx from "classnames";
import { connect } from "react-redux";

const Subheader = ({ className, subHeader, button, children }) => (
  <>
    <div className={cx("HeaderWithSidebar__subHeader")}>
      <div className="HeaderWithSidebar__subHeader-title">{subHeader}</div>
      <div className={cx("HeaderWithSidebar__subHeader-button")}>{button}</div>
    </div>
    <div className={cx("HeaderWithSidebar__body", className)}>{children}</div>
  </>
);

Subheader.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  subHeader: PropTypes.any,
  button: PropTypes.any
};

function mapStateToProps(state) {
  return {
    sidebarTabSelected: state.headerSidebar.sidebarTabSelected,
    exceptionSidebarIsActive: state.rentRoll.exceptionSidebarIsActive
  };
}

export default connect(mapStateToProps)(Subheader);
