import React, { Component } from 'react';
import PropTypes from "prop-types";

/*
 * This component solves the issue of Checkbox2.
 * If user clicks on either checkbox or text, both checkbox and text has sync behavior with the check mark.
 */
class Checkbox extends Component {
  state = {
    isChecked: false,
  }

  componentDidMount() {
    const { hasChecked } = this.props;
    if (hasChecked) {
      this.setState({
        isChecked: hasChecked,
      })
    }
  }

  toggleCheckboxChange = () => {
    const { handleCheckboxChange, value } = this.props;

    this.setState(({ isChecked }) => (
      {
        isChecked: !isChecked,
      }
    ));

    handleCheckboxChange(value);
  }

  render() {
    const { label, value, disabled } = this.props;
    const { isChecked } = this.state;

    return (
      <div className="checkbox3">
        <label className="checkbox3__label">
          <input
            type="checkbox"
            className="checkbox3__input"
            checked={isChecked}
            disabled={disabled}
            onChange={this.toggleCheckboxChange}
            value={value}
          />
          <span>{label}</span>
        </label>
      </div>
    );
  }
}

Checkbox.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.string,
  handleCheckboxChange: PropTypes.func,
  hasChecked: PropTypes.bool,
};

export default Checkbox;
