import { apiFetch } from "./apiFetch";

const fetchData = widgetAuth => async url => {
  const response = await apiFetch(url, { method: "get" }, widgetAuth);
  if (!response.ok) {
    const responseBody = await response.json();
    throw new Error(responseBody.error);
  }
  const body = await response.json();

  return body;
};

export default fetchData;
