import PropTypes from "prop-types";
import cx from "classnames";

const PredefOption = ({
  value,
  idx,
  selected,
  updateSelected,
  updateInputVal,
  predefModalValue
}) => {
  const updateCheckedAndReturn = () => {
    if (selected === undefined) {
      if (predefModalValue === value.value) {
        updateSelected(idx);
        return true;
      } else {
        return false;
      }
    } else {
      return selected === idx;
    }
  };
  return (
    <div
      className={cx("DataCell__Popover__popupButton", {
        unchecked: selected !== idx,
        checked: updateCheckedAndReturn()
      })}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "5px"
      }}
      onClick={() => {
        updateSelected(idx);
        updateInputVal(value.value);
      }}
    >
      {value.value}
    </div>
  );
};

PredefOption.propTypes = {
  value: PropTypes.object,
  idx: PropTypes.number,
  selected: PropTypes.number,
  updateSelected: PropTypes.func,
  updateInputVal: PropTypes.func,
  predefModalValue: PropTypes.string
};

export default PredefOption;
