import { blankRow, getItemsByType, subcategoryNames } from "./processRows";
import { deepCopy } from "services/helpers/objectUtils";
import { isEmpty } from "ramda";
import { getFormattedRow } from "./excelFormatting";
import {
  singleTab,
  doubleTab,
  tripleTab,
  whiteFillColor,
  subcategoryColor
} from "./excelFormatting";

/*
  This is to determine which total that NOI should follow.
  NOI should appear after “Total Variable Operating Expenses”.
  Only when there is no “Total Variable Operating Expenses” should NOI
  appear after “Total Fixed Operating Expenses”
*/
const findNOIOrder = subcategories => {
  const subcatNames = subcategories.map(subcategory => {
    return subcategory.name;
  });
  if (subcatNames.includes(subcategoryNames.VARIABLE_OPERATING_EXPENSES)) {
    return subcategoryNames.VARIABLE_OPERATING_EXPENSES;
  } else return subcategoryNames.FIXED_OPERATING_EXPENSES;
};

export const processOpexDataCL = (
  opexTableData,
  ignoredColumnsIdx,
  NoiAndNcf
) => {
  const lineItemNoiAndNcf = deepCopy(NoiAndNcf);
  const accounts = getItemsByType(opexTableData, "account");
  const categories = getItemsByType(opexTableData, "category").filter(cat => {
    return cat.children.length > 0;
  });
  const subcategories = getItemsByType(opexTableData, "subcategory");
  const subcategoriesBeyondLvlTwo = subcategories.filter(subcategory => {
    return subcategory.beyondLvlTwo === true;
  });
  const subcategoriesLvlOne = subcategories.filter(subcategory => {
    return subcategory.beyondLvlTwo !== true;
  });

  const subcategoriesHeader = deepCopy(subcategoriesLvlOne);
  const subcategoriesBeyondLvlTwoHeader = deepCopy(subcategoriesBeyondLvlTwo);
  const categoriesHeader = deepCopy(categories);
  const NOIOrder = findNOIOrder(subcategoriesLvlOne);

  const filterIgnoredAccountsAndTotals = columns => {
    return columns.reduce((arr, column, index) => {
      if (!ignoredColumnsIdx.includes(index))
        arr.push(parseFloat(column.value));
      return arr;
    }, []);
  };

  const getFormattedTotalsAndValues = (name, values, fillColor) => {
    return [getFormattedRow(name, fillColor, "bold")].concat(
      filterIgnoredAccountsAndTotals(values).map(value => {
        return getFormattedRow(value, fillColor, "boldNumericValue");
      })
    );
  };

  const getFormattedSubcategoryTotalsAndValues = (name, values) => {
    return [getFormattedRow(name, null, "boldNoBorders")].concat(
      filterIgnoredAccountsAndTotals(values).map(value => {
        return getFormattedRow(value, null, "boldNumericNoBorders");
      })
    );
  };

  const getFormattedAccountsAndValues = (name, values) => {
    // we'll use null as the fill color, since we want accounts to have no fill
    return [getFormattedRow(name, null, "nameNoBorders")].concat(
      filterIgnoredAccountsAndTotals(values).map(value => {
        return getFormattedRow(value, null, "numericValueNoBorders");
      })
    );
  };

  const exportedRows = [];
  let prevAccountParentId = accounts[0].parentId;
  let subcategoryLvlTwoPrevParentId = subcategoriesBeyondLvlTwo[0].parentId;
  let subcategoryLvlOnePrevParentId,
    subcategoryLvlOneNextParentId,
    subcategoryLvlOne,
    subcategoryLvlTwoNextParentId,
    subcategoryLvlTwo,
    category;
  // push the initial top level category and subcategories
  let categoryHeader = categoriesHeader.shift().name;
  let subcategoryLvlTwoHeader = subcategoriesBeyondLvlTwoHeader.shift().name;
  let subcategoryHeader = subcategoriesHeader.shift().name;
  exportedRows.push(
    [getFormattedRow(categoryHeader, null, "boldNoBorders")],
    [getFormattedRow(singleTab + subcategoryHeader, null, "nameNoBorders")],
    [
      getFormattedRow(
        doubleTab + subcategoryLvlTwoHeader,
        null,
        "nameNoBorders"
      )
    ]
  );

  for (let k = 0; k < accounts.length; k += 1) {
    // if the current and previous accounts share a parent subcategory level 2, we can just add
    // the account to the excel

    if (prevAccountParentId === accounts[k].parentId) {
      exportedRows.push([
        ...getFormattedAccountsAndValues(
          tripleTab + accounts[k].name,
          accounts[k].values
        )
      ]);

      prevAccountParentId = accounts[k].parentId;
    } else if (
      accounts[k].parentId !== prevAccountParentId &&
      !isEmpty(subcategoriesBeyondLvlTwo)
    ) {
      // if the next account has a different subcategory level 2, then push the top subcategory 2 from the list
      subcategoryLvlTwo = subcategoriesBeyondLvlTwo.shift();
      // if the subcategory level 2 has the same subcategory parent, we can just push the
      // subcategory level 2
      if (subcategoryLvlTwo.parentId === subcategoryLvlTwoPrevParentId) {
        let dontSkip = subcategoryLvlTwo.totals || false;
        if (dontSkip) {
          exportedRows.push([
            ...getFormattedSubcategoryTotalsAndValues(
              doubleTab + `Total ${subcategoryLvlTwo.name}`,
              subcategoryLvlTwo.totals || [{ value: 0 }]
            )
          ]);
        }
        // before pushing the next account, we want to check that the current subcategory level 2 is under
        // the same subcategory level one and same category as the previous subcategory level 2
        //(peek at the next subcategory two parentId without popping it off)
        subcategoryLvlTwoPrevParentId = subcategoryLvlTwo.parentId;
        if (!isEmpty(subcategoriesBeyondLvlTwo))
          subcategoryLvlTwoNextParentId = subcategoriesBeyondLvlTwo[0].parentId;

        // if the next subcat2 has a different parentID than the current one,
        // meaning that it has a different subcategory, we want to add the current subcategory
        if (
          subcategoryLvlTwoPrevParentId !== subcategoryLvlTwoNextParentId &&
          !isEmpty(subcategoriesLvlOne)
        ) {
          subcategoryLvlOne = subcategoriesLvlOne.shift();
          let dontSkip = subcategoryLvlOne.totals || false;
          if (dontSkip) {
            exportedRows.push([
              ...getFormattedTotalsAndValues(
                singleTab + `Total ${subcategoryLvlOne.name}`,
                subcategoryLvlOne.totals || [{ value: 0 }],
                subcategoryColor
              )
            ]);
            if (
              subcategoryLvlOne.name === NOIOrder &&
              subcategoryNames.NOI in lineItemNoiAndNcf
            ) {
              exportedRows.push(blankRow, lineItemNoiAndNcf.NOI, blankRow);
              delete lineItemNoiAndNcf.NOI;
            } else if (
              subcategoryLvlOne.name === subcategoryNames.OTHER_EXPENSES &&
              subcategoryNames.NCF in lineItemNoiAndNcf
            ) {
              exportedRows.push(blankRow, lineItemNoiAndNcf.NCF, blankRow);
              delete lineItemNoiAndNcf.NCF;
            }
          }
          subcategoryLvlOnePrevParentId = subcategoryLvlOne.parentId;
          if (!isEmpty(subcategoriesLvlOne))
            subcategoryLvlOneNextParentId = subcategoriesLvlOne[0].parentId;
          // if the next subcategory's parentId is different, it is under a different category
          // meaning that we want to push the current category that we are iterating on
          if (
            subcategoryLvlOnePrevParentId !== subcategoryLvlOneNextParentId &&
            !isEmpty(categories)
          ) {
            category = categories.shift();
            let dontSkip = category.totals || false;
            if (dontSkip) {
              exportedRows.push([
                ...getFormattedTotalsAndValues(
                  `Total ${category.name}`,
                  category.totals || [{ value: 0 }],
                  whiteFillColor
                )
              ]);
            }
            categoryHeader = categoriesHeader.shift().name;
            subcategoryLvlTwoHeader = subcategoriesBeyondLvlTwoHeader.shift()
              .name;
            subcategoryHeader = subcategoriesHeader.shift().name;
            exportedRows.push(
              [getFormattedRow(categoryHeader, null, "boldNoBorders")],
              [
                getFormattedRow(
                  singleTab + subcategoryHeader,
                  null,
                  "nameNoBorders"
                )
              ],
              [
                getFormattedRow(
                  doubleTab + subcategoryLvlTwoHeader,
                  null,
                  "nameNoBorders"
                )
              ]
            );

            exportedRows.push([
              ...getFormattedAccountsAndValues(
                tripleTab + accounts[k].name,
                accounts[k].values
              )
            ]);
            prevAccountParentId = accounts[k].parentId;
          } else if (
            subcategoryLvlOnePrevParentId === subcategoryLvlOneNextParentId
          ) {
            subcategoryLvlTwoHeader = subcategoriesBeyondLvlTwoHeader.shift()
              .name;
            subcategoryHeader = subcategoriesHeader.shift().name;
            exportedRows.push(
              [
                getFormattedRow(
                  singleTab + subcategoryHeader,
                  null,
                  "nameNoBorders"
                )
              ],
              [
                getFormattedRow(
                  doubleTab + subcategoryLvlTwoHeader,
                  null,
                  "nameNoBorders"
                )
              ]
            );

            exportedRows.push([
              ...getFormattedAccountsAndValues(
                tripleTab + accounts[k].name,
                accounts[k].values
              )
            ]);
            prevAccountParentId = accounts[k].parentId;
          }
        }
        // if the subcategory2 do share a subcategory, continue by pushing the next account
        else if (
          subcategoryLvlTwoPrevParentId === subcategoryLvlTwoNextParentId
        ) {
          subcategoryLvlTwoHeader = subcategoriesBeyondLvlTwoHeader.shift()
            .name;
          exportedRows.push([
            getFormattedRow(
              doubleTab + subcategoryLvlTwoHeader,
              null,
              "nameNoBorders"
            )
          ]);
          exportedRows.push([
            ...getFormattedAccountsAndValues(
              tripleTab + accounts[k].name,
              accounts[k].values
            )
          ]);
          prevAccountParentId = accounts[k].parentId;
        }
      }
      // if the subcategory is different, push the current subcategory level 2 and account
      else if (subcategoryLvlTwo.parentId !== subcategoryLvlTwoPrevParentId) {
        let dontSkip = subcategoryLvlTwo.totals || false;
        if (dontSkip) {
          exportedRows.push([
            ...getFormattedSubcategoryTotalsAndValues(
              doubleTab + `Total ${subcategoryLvlTwo.name}`,
              subcategoryLvlTwo.totals || [{ value: 0 }]
            )
          ]);
        }
        subcategoryLvlTwoPrevParentId = subcategoryLvlTwo.parentId;

        // another check here before pushing current account
        // check if we should push the subcategory and the category here, then push account last
        if (!isEmpty(subcategoriesBeyondLvlTwo))
          subcategoryLvlTwoNextParentId = subcategoriesBeyondLvlTwo[0].parentId;

        // if the next subcat2 has a different parentID than the current one,
        // meaning that it has a different subcategory, we want to add the current subcategory
        if (
          subcategoryLvlTwoPrevParentId !== subcategoryLvlTwoNextParentId &&
          !isEmpty(subcategoriesLvlOne)
        ) {
          subcategoryLvlOne = subcategoriesLvlOne.shift();
          let dontSkip = subcategoryLvlOne.totals || false;
          if (dontSkip) {
            exportedRows.push([
              ...getFormattedTotalsAndValues(
                singleTab + `Total ${subcategoryLvlOne.name}`,
                subcategoryLvlOne.totals || [{ value: 0 }],
                subcategoryColor
              )
            ]);
            if (
              subcategoryLvlOne.name === NOIOrder &&
              subcategoryNames.NOI in lineItemNoiAndNcf
            ) {
              exportedRows.push(blankRow, lineItemNoiAndNcf.NOI, blankRow);
              delete lineItemNoiAndNcf.NOI;
            } else if (
              subcategoryLvlOne.name === subcategoryNames.OTHER_EXPENSES &&
              subcategoryNames.NCF in lineItemNoiAndNcf
            ) {
              exportedRows.push(blankRow, lineItemNoiAndNcf.NCF, blankRow);
              delete lineItemNoiAndNcf.NCF;
            }
          }
          subcategoryLvlOnePrevParentId = subcategoryLvlOne.parentId;

          if (!isEmpty(subcategoriesLvlOne))
            subcategoryLvlOneNextParentId = subcategoriesLvlOne[0].parentId;
          // if the next subcategory's parentId is different, it is under a different category
          // meaning that we want to push the current category that we are iterating on
          if (
            subcategoryLvlOnePrevParentId !== subcategoryLvlOneNextParentId &&
            !isEmpty(categories)
          ) {
            category = categories.shift();
            let dontSkip = category.totals || false;
            if (dontSkip) {
              exportedRows.push([
                ...getFormattedTotalsAndValues(
                  `Total ${category.name}`,
                  category.totals || [{ value: 0 }],
                  whiteFillColor
                )
              ]);
            }
            categoryHeader = categoriesHeader.shift().name;
            subcategoryLvlTwoHeader = subcategoriesBeyondLvlTwoHeader.shift()
              .name;
            subcategoryHeader = subcategoriesHeader.shift().name;
            exportedRows.push(
              [getFormattedRow(categoryHeader, null, "boldNoBorders")],
              [
                getFormattedRow(
                  singleTab + subcategoryHeader,
                  null,
                  "nameNoBorders"
                )
              ],
              [
                getFormattedRow(
                  doubleTab + subcategoryLvlTwoHeader,
                  null,
                  "nameNoBorders"
                )
              ]
            );
            exportedRows.push([
              ...getFormattedAccountsAndValues(
                tripleTab + accounts[k].name,
                accounts[k].values
              )
            ]);
            prevAccountParentId = accounts[k].parentId;
          } else if (
            subcategoryLvlOnePrevParentId === subcategoryLvlOneNextParentId
          ) {
            subcategoryLvlTwoHeader = subcategoriesBeyondLvlTwoHeader.shift()
              .name;
            subcategoryHeader = subcategoriesHeader.shift().name;
            exportedRows.push(
              [
                getFormattedRow(
                  singleTab + subcategoryHeader,
                  null,
                  "nameNoBorders"
                )
              ],
              [
                getFormattedRow(
                  doubleTab + subcategoryLvlTwoHeader,
                  null,
                  "nameNoBorders"
                )
              ]
            );
            exportedRows.push([
              ...getFormattedAccountsAndValues(
                tripleTab + accounts[k].name,
                accounts[k].values
              )
            ]);
            prevAccountParentId = accounts[k].parentId;
          }
        } else {
          subcategoryLvlTwoHeader = subcategoriesBeyondLvlTwoHeader.shift()
            .name;

          exportedRows.push([
            getFormattedRow(
              doubleTab + subcategoryLvlTwoHeader,
              null,
              "nameNoBorders"
            )
          ]);
          exportedRows.push([
            ...getFormattedAccountsAndValues(
              tripleTab + accounts[k].name,
              accounts[k].values
            )
          ]);
          prevAccountParentId = accounts[k].parentId;
        }
      }
    }
    // handle the final iteration by pushing remaining subcategory level 2, subcategory, and category
    if (k === accounts.length - 1) {
      if (!isEmpty(subcategoriesBeyondLvlTwo)) {
        subcategoryLvlTwo = subcategoriesBeyondLvlTwo.shift();
        let dontSkip = subcategoryLvlTwo.totals || false;
        if (dontSkip) {
          exportedRows.push([
            ...getFormattedSubcategoryTotalsAndValues(
              doubleTab + `Total ${subcategoryLvlTwo.name}`,
              subcategoryLvlTwo.totals || [{ value: 0 }]
            )
          ]);
        }
      }
      if (!isEmpty(subcategoriesLvlOne)) {
        subcategoryLvlOne = subcategoriesLvlOne.shift();
        let dontSkip = subcategoryLvlOne.totals || false;
        if (dontSkip) {
          exportedRows.push([
            ...getFormattedTotalsAndValues(
              singleTab + `Total ${subcategoryLvlOne.name}`,
              subcategoryLvlOne.totals || [{ value: 0 }],
              subcategoryColor
            )
          ]);
          if (
            subcategoryLvlOne.name === NOIOrder &&
            subcategoryNames.NOI in lineItemNoiAndNcf
          ) {
            exportedRows.push(blankRow, lineItemNoiAndNcf.NOI, blankRow);
            delete lineItemNoiAndNcf.NOI;
          } else if (
            subcategoryLvlOne.name === subcategoryNames.OTHER_EXPENSES &&
            subcategoryNames.NCF in lineItemNoiAndNcf
          ) {
            exportedRows.push(blankRow, lineItemNoiAndNcf.NCF, blankRow);
            delete lineItemNoiAndNcf.NCF;
          }
        }
      }
      if (!isEmpty(categories)) {
        category = categories.shift();
        let dontSkip = category.totals || false;
        if (dontSkip) {
          exportedRows.push([
            ...getFormattedTotalsAndValues(
              `Total ${category.name}`,
              category.totals || [{ value: 0 }],
              whiteFillColor
            )
          ]);
        }
      }
    }
  }
  return exportedRows;
};
