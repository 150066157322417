import useSWR from "swr";
import fetchData from "ui/components/rentRoll/splitPanelV2/helpers/fetchData";
import updateData from "ui/components/rentRoll/splitPanelV2/helpers/updateData";

import { apiFetch } from "ui/components/rentRoll/splitPanelV2/helpers/apiFetch";
import useOpexPanelContext from "./useOpexPanelContext";

export default function useOpex() {
  const { documentId, widgetAuth, groupName } = useOpexPanelContext();

  const { data, error, mutate } = useSWR(
    `/api/opex/get-opex/${documentId}`,
    fetchData(widgetAuth)
  );

  const updateCellValue = (opexValueId, data) =>
    updateData({
      path: `/api/opex/documents/${documentId}/opex-values/${opexValueId}`,
      data,
      method: "PATCH",
      widgetAuth
    });

  const updatePeriod = (opexPeriodId, data) =>
    updateData({
      path: `/api/opex/documents/${documentId}/opex-periods/${opexPeriodId}`,
      data,
      method: "PATCH",
      widgetAuth
    });

  const extractOpexData = () =>
    apiFetch(
      `/api/opex/extract-opex/${documentId}`,
      { method: "post" },
      widgetAuth
    );

  return {
    opex: data ? data.opex : null,
    error,
    mutate,
    groupName,
    isLoading: !error && !data,
    extractOpexData,
    updateCellValue,
    updatePeriod
  };
}
