import Row from "./Row";

export const rowProps = ({ rowData, rowIndex, columns }) => ({
  data: rowData,
  tagName: Row,
  index: rowIndex,
  canEdit: columns[0].canEdit
});

export const getItemByIndex = (index, items, expandedRows) => {
  let allVisibleRows = [];
  items.forEach(cat => {
    allVisibleRows.push(cat);
    if (expandedRows.includes(cat.id)) allVisibleRows.push(...cat.children);
  });
  return allVisibleRows[index];
};

export const refreshOrderValues = children => {
  return children.map((row, index) => ({ ...row, order: index }));
};

export const sortChildren = (children, draggedOrder, affectedOrder) => {
  let newChildren = children
    .map(subCat => {
      if (subCat.order === draggedOrder)
        return { ...subCat, order: affectedOrder };
      else {
        if (draggedOrder > subCat.order && affectedOrder <= subCat.order)
          return { ...subCat, order: subCat.order + 1 };
        else if (draggedOrder < subCat.order && affectedOrder >= subCat.order)
          return { ...subCat, order: subCat.order - 1 };
      }
      return subCat;
    })
    .sort((a, b) => a.order - b.order);
  return newChildren;
};
