import { apiFetch } from "./apiClient";
export const FULL_GET_USAGE_DATA_ERROR = "FULL_GET_USAGE_DATA_ERROR";
export const REQUEST_FULL_ES_IMPORT = "REQUEST_FULL_ES_IMPORT";
export const FULL_ES_IMPORT_SUCCESSFUL = "FULL_ES_IMPORT_SUCCESSFUL";
export const FULL_ES_IMPORT_ERROR = "FULL_ES_IMPORT_ERROR";
export const REQUEST_FULL_USAGE_IMPORT = "REQUEST_FULL_USAGE_IMPORT";
export const FULL_DATA_USAGE_IMPORT_SUCCESSFUL =
  "FULL_DATA_USAGE_IMPORT_SUCCESSFUL";

export const requestFullEsImport = () => ({
  type: REQUEST_FULL_ES_IMPORT
});

export const fullESImportSuccessful = () => ({
  type: FULL_ES_IMPORT_SUCCESSFUL
});

export const fullESImportError = error => ({
  type: FULL_ES_IMPORT_ERROR,
  error
});

export const fullUsageReportError = error => ({
  type: FULL_GET_USAGE_DATA_ERROR,
  error
});

export const requestFullUsageImport = () => ({
  type: REQUEST_FULL_USAGE_IMPORT
});

export const fullUsageReportSuccessful = () => ({
  type: FULL_DATA_USAGE_IMPORT_SUCCESSFUL
});

// export function runFullESImport() {
//   return async function(dispatch) {
//     dispatch(requestFullEsImport());
//     try {
//       const response = await apiFetch("/api/rent-roll/search/fullImport");
//       const responseBody = await response.json();
//       if (!response.ok) {
//         throw new Error(responseBody.error.message);
//       }
//       dispatch(fullESImportSuccessful());
//     } catch (error) {
//       dispatch(fullESImportError(error));
//     }
//   };
// }

export function runFullUsageImport() {
  return async function(dispatch) {
    try {
      const response = await apiFetch("/api/usageReport", { method: "post" });
      const responseBody = await response.json();
      if (!response.ok) {
        throw new Error(responseBody.error.message);
      }
      dispatch(fullUsageReportSuccessful());
    } catch (error) {
      dispatch(fullUsageReportError(error));
    }
  };
}
