import { Component } from "react";
import PropTypes from "prop-types";

export default class BrowseUpload extends Component {
  state = {
    file: null
  };

  handleOnChange = e => {
    console.log("handlechange");
    const files = e.target.files;
    for (let i = 0; i < files.length; i++) {
      this.props.saveDroppedFile(files[i]);
    }
  };
  render() {
    return (
      <span className="BrowseUpload">
        <form className="BrowseUpload__form">
          <input
            id="file"
            className="BrowseUpload__input"
            type="file"
            onChange={e => this.handleOnChange(e)}
            onClick={e => (e.target.value = null)}
            multiple
          />
          <label
            htmlFor="file"
            style={{ fontWeight: "bold", color: "#009BDF" }}
          >
            browse
          </label>
        </form>
      </span>
    );
  }
}

BrowseUpload.propTypes = {
  saveDroppedFile: PropTypes.func,
  disableButton: PropTypes.bool
};
