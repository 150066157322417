import { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { updateDocumentField } from "ui/store/actions/rentRoll";

const CellUpdateValue = ({
  documentId,
  cellData,
  updateDocumentField,
  cache,
  location,
  closePopover,
  widgetAuth
}) => {
  const { pi, ti, ri, ci } = location;
  const name = `cellText-${pi}-${ti}-${ri}-${ci}`;
  const cacheValue = cache[name] ? cache[name] : undefined;
  const curValue = cacheValue ? cacheValue : cellData.text;
  const [value, updateValue] = useState(curValue);

  return (
    <>
      <div className="Popover__popupHeader">Suggested Change</div>
      <div className="Popover__changeDescription">{`Change the value of the input, then click update.`}</div>
      <input
        className="Popover__input"
        type="text"
        name={name}
        value={value}
        onChange={e => updateValue(e.target.value)}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          marginTop: "10px"
        }}
      >
        <button
          className="Popover__updateButton"
          style={{ marginLeft: "5px", color: "gray" }}
          onClick={() => {
            closePopover();
          }}
        >
          Cancel
        </button>
        <button
          className="Popover__updateButton"
          onClick={() => {
            const field = {
              page: pi,
              table: ti,
              row: ri,
              col: ci,
              text: value
            };
            updateDocumentField(field, documentId, widgetAuth);
            closePopover();
          }}
        >
          Update
        </button>
      </div>
    </>
  );
};

CellUpdateValue.defaultProps = {
  widgetAuth: {}
};

CellUpdateValue.propTypes = {
  documentId: PropTypes.number,
  cellData: PropTypes.object,
  updateValue: PropTypes.string,
  cache: PropTypes.object,
  location: PropTypes.object,
  updateIsOpen: PropTypes.func,
  updateDocumentField: PropTypes.func,
  closePopover: PropTypes.func,
  widgetAuth: PropTypes.object
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateDocumentField }, dispatch);

export default connect(null, mapDispatchToProps)(CellUpdateValue);
