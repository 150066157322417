import { useRef } from "react";
import { connect } from "react-redux";
import Popup from "reactjs-popup";
import cx from "classnames";

import Button from "ui/components/shared/Button";
import UploadPanel from "ui/components/main/upload/UploadPanel";

const UploadPopoverBlue = () => {
  const ref = useRef();
  const closePopover = () => ref.current.close();
  return (
    <div className="UploadPopoverBlue">
      <Popup
        ref={ref}
        trigger={
          <Button className={cx("Button__blueBackground")}>Upload Files</Button>
        }
        arrow={false}
        closeOnDocumentClick={true}
        contentStyle={{
          marginRight: "40px",
          width: "0",
          padding: "0",
          top: "120px",
          right: "500px"
        }}
      >
        <UploadPanel manageFilesView={true} closePopover={closePopover} />
      </Popup>
    </div>
  );
};

export default connect()(UploadPopoverBlue);
