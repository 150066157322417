import PropTypes from "prop-types";
import StatusLegend from "../../../BottomBar/components/StatusLegend";
import {
  WORKFLOW_ACCOUNT_MAPPING,
  WORKFLOW_CHART_OF_ACCOUNTS_SELECTION,
  WORKFLOW_COLUMN_PERIODIZATION,
  WORKFLOW_TABLE_SELECTION
} from "ui/components/opex/helpers/opex";
import OpexSelect from "../OpexSelect/OpexSelect";
import TableSelectionNav from "../TableSelectionNav/TableSelectionNav";

const StatusHeader = ({
  document,
  currentTable,
  setCurrentTable,
  currentWorkflowStatus,
  statusHeaderCounters,
  onToggleSelectAllTables
}) => {
  const isStepOne =
    currentWorkflowStatus === null ||
    currentWorkflowStatus === WORKFLOW_TABLE_SELECTION ||
    currentWorkflowStatus === WORKFLOW_CHART_OF_ACCOUNTS_SELECTION;
  const isStepTwo = currentWorkflowStatus === WORKFLOW_ACCOUNT_MAPPING;
  const isStepThree = currentWorkflowStatus === WORKFLOW_COLUMN_PERIODIZATION;
  const handleTableSelect = value => {
    setCurrentTable(parseInt(value));
  };
  const shouldShowSelect = () => {
    const allowed = [WORKFLOW_COLUMN_PERIODIZATION];
    return allowed.includes(currentWorkflowStatus);
  };
  const renderSelect = () => {
    const options = [
      {
        label: "Group 1",
        value: 0
      }
    ];

    return (
      <OpexSelect
        value={currentTable}
        options={options}
        onChange={handleTableSelect}
      />
    );
  };

  return <div className="OpexPanelView__StatusHeader">
    {shouldShowSelect() && renderSelect()}
    {
      (isStepOne) && <TableSelectionNav
        document={document}
        currentWorkflowStatus={currentWorkflowStatus}
        onToggleSelectAllTables={onToggleSelectAllTables}
      />
    }
    {(isStepTwo || isStepThree) && <StatusLegend step={currentWorkflowStatus} statusHeaderCounters={statusHeaderCounters} />}
  </div>
};

StatusHeader.propTypes = {
  document: PropTypes.object,
  currentWorkflowStatus: PropTypes.string,
  currentTable: PropTypes.number,
  setCurrentTable: PropTypes.func,
  onToggleSelectAllTables: PropTypes.func,
  statusHeaderCounters: PropTypes.object
};

export default StatusHeader;
