import { apiFetch } from "ui/store/actions/apiClient";

export async function fetchEffectiveDateStatus(documentId, widgetAuth = {}) {
  try {
    const response = await apiFetch(
      `/api/rent-roll/effective-date-exception/${documentId}`,
      {},
      widgetAuth
    );
    if (!response.ok) {
      const responseBody = await response.json();
      throw new Error(responseBody.error);
    }
    const responseBody = await response.json();
    const { exception } = responseBody;
    return exception ? exception[0] : {};
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}
