import { Component } from "react";
import PropType from "prop-types";
import Popup from "reactjs-popup";
import cx from "classnames";

import Button from "ui/components/shared/Button";
import { exportExcel } from "services/downloadExcel";

class DownloadExcel extends Component {
  render() {
    const {
      dataset,
      headers,
      filename,
      overrideFunction,
      text,
      isManageFilesPage = false
    } = this.props;
    return isManageFilesPage ? (
      <Popup
        className="DownloadExcel"
        trigger={
          <button
            className={"Button__manageFilesDownloadButton"}
            onClick={() => {
              if (overrideFunction) {
                overrideFunction();
              } else {
                exportExcel(dataset, filename, headers);
              }
            }}
          ></button>
        }
        position="top center"
        on={["hover", "focus"]}
        closeOnDocumentClick
      >
        <div className={"DownloadExcel__tooltip"} style={{ color: "white" }}>
          Download Excel file
        </div>
      </Popup>
    ) : (
      <Button
        className={"Button__downloadButton"}
        onClick={() => {
          if (overrideFunction) {
            overrideFunction();
          } else {
            exportExcel(dataset, filename, headers);
          }
        }}
      >
        {text}
      </Button>
    );
  }
}

DownloadExcel.propTypes = {
  dataset: PropType.array,
  headers: PropType.array,
  filename: PropType.string,
  overrideFunction: PropType.func,
  text: PropType.string,
  isManageFilesPage: PropType.bool
};

export default DownloadExcel;
