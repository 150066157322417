import * as React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import cx from "classnames";

class Cell {
  constructor(props) {
    // a tableData cell
    this.data = props.data;

    /**
    These values are needed to calcualte height and width of the cell since
    textract uses a ratio from the parent image height and width to determine
    the cell size. See:
    https://docs.aws.amazon.com/textract/latest/dg/API_BoundingBox.html
    **/
    this.imageHeight = props.imageHeight;
    this.imageWidth = props.imageWidth;
  }

  /* Returns (x,y) coordinates for each corner of the cell */
  getPoints() {
    const boundingBox = _.get(this.data, "geometry.boundingBox");

    // to understand why these values are calculated this way, read the aws page
    // on API_BoundingBox
    const cellHeight = Math.floor(
      _.multiply(this.imageHeight, boundingBox.Height)
    );
    const cellWidth = Math.floor(
      _.multiply(this.imageWidth, boundingBox.Width)
    );

    const topLeft = {
      // floor these values because if render a svg for all the individual
      // cells in the table, as it gets closer to the bottom, the cells toward
      // the bottom will be off by a few pixels
      x: Math.floor(boundingBox.Left * this.imageWidth),
      y: Math.floor(boundingBox.Top * this.imageHeight)
    };

    const topRight = {
      x: _.add(topLeft.x, cellWidth),
      y: topLeft.y
    };

    const bottomRight = {
      x: topRight.x,
      y: _.add(topRight.y, cellHeight)
    };

    const bottomLeft = {
      x: topLeft.x,
      y: _.add(topLeft.y, cellHeight)
    };

    return {
      topLeft,
      topRight,
      bottomRight,
      bottomLeft
    };
  }
}

class Table {
  constructor(props) {
    this.data = props.data;
    this.tableIndex = props.tableIndex;
    this.imageHeight = props.imageHeight;
    this.imageWidth = props.imageWidth;

    this.onClickTable = props.onClickTable;
    this.onClickColumn = props.onClickColumn;
    this.onClickRow = props.onClickRow;
    this.onClickCell = props.onClickCell;
    this.onMouseEnterCell = props.onMouseEnterCell;
    this.onMouseLeaveCell = props.onMouseLeaveCell;

    this.cellPolygonRenderer = props.cellPolygonRenderer;
    this.shouldUseCellPolygonRenderer = props.shouldUseCellPolygonRenderer;

    this.shouldShowCOATooltip = props.shouldShowCOATooltip;

    this.OPEX_TOOLTIP_ID_AND_CLASS = "OpexTableTooltip";
    this.coaTooltipOffset = props.coaTooltipOffset || { top: 0, left: 0 };
    this.showCOATooltip = this.showCOATooltip.bind(this);
    this.removeCOATooltip = this.removeCOATooltip.bind(this);
  }

  buildCell(data) {
    const { imageHeight, imageWidth } = this;
    return new Cell({ data, imageHeight, imageWidth });
  }

  useCellPolygonRenderer({ className, ci, data }) {
    return (
      className === "cell" &&
      this.cellPolygonRenderer &&
      (this.shouldUseCellPolygonRenderer
        ? this.shouldUseCellPolygonRenderer({ ci, data })
        : true)
    );
  }

  // This function is purposefully made to not be customizable becuase this is a
  // very non-react friendly way to generate a tooltip, but just doing it like this
  // for the sake of time. When we need another tooltip, we can remove this code
  // and make a proper "show-menu-bar-on-hover".
  showCOATooltip(e) {
    if (!this.shouldShowCOATooltip) {
      return false;
    }

    const pageContainer = e.target.parentElement.parentElement;

    // returns eg. [344, 10], the first point (top left corner) of the polygon
    // so we know where to absolutely position the tooltip
    const points = e.target
      .getAttribute("points")
      .split(" ")[0]
      .split(",");

    const top = parseInt(points[1]) + this.coaTooltipOffset.top;
    const left = parseInt(points[0]) + this.coaTooltipOffset.left;

    // create and position the tooltip
    const tooltip = document.createElement("div");
    tooltip.append("Select chart of accounts");
    tooltip.setAttribute("id", this.OPEX_TOOLTIP_ID_AND_CLASS);
    tooltip.setAttribute("class", this.OPEX_TOOLTIP_ID_AND_CLASS);
    tooltip.style.top = `${top}px`;
    tooltip.style.left = `${left}px`;
    tooltip.style.position = "absolute";

    pageContainer.append(tooltip);
  }

  removeCOATooltip() {
    if (!this.shouldShowCOATooltip) {
      return false;
    }

    document.getElementById(this.OPEX_TOOLTIP_ID_AND_CLASS).remove();
  }

  // Given 4 cells, returns a polygon
  // Can render columns, rows, and individual cells
  // Just supply it the same cell 1,2, or 4 times if you want column/row/cell polygon
  _renderPolygon(
    topLeftCell,
    topRightCell,
    bottomRightCell,
    bottomLeftCell,
    options
  ) {
    const {
      handleOnClick,
      handleOnMouseEnter,
      handleOnMouseLeave,
      selected,
      key,
      className,
      data,
      ci
    } = options;

    const topLeftPoints = topLeftCell.getPoints();
    const topRightPoints = topRightCell.getPoints();
    const bottomRightPoints = bottomRightCell.getPoints();
    const bottomLeftPoints = bottomLeftCell.getPoints();

    const topLeftCoordinate = `${topLeftPoints.topLeft.x},${topLeftPoints.topLeft.y}`;
    const topRightCoordinate = `${topRightPoints.topRight.x},${topRightPoints.topRight.y}`;
    const bottomRightCoordinate = `${bottomRightPoints.bottomRight.x},${bottomRightPoints.bottomRight.y}`;
    const bottomLeftCoordinate = `${bottomLeftPoints.bottomLeft.x},${bottomLeftPoints.bottomRight.y}`;

    const selectedClassName = selected ? "selected" : "";

    const polygonProps = {
      key,
      className: cx(className, selectedClassName),
      onClick: handleOnClick,
      onMouseEnter: handleOnMouseEnter || (() => {}),
      onMouseLeave: handleOnMouseLeave || (() => {}),
      points: `${topLeftCoordinate} ${topRightCoordinate} ${bottomRightCoordinate} ${bottomLeftCoordinate}`,
      onMouseOver: this.showCOATooltip,
      onMouseOut: this.removeCOATooltip
    };

    if (this.useCellPolygonRenderer({ className, ci, data })) {
      const CellPolygonRenderer = this.cellPolygonRenderer;
      return (
        <CellPolygonRenderer
          key={key}
          polygonProps={polygonProps}
          data={data}
        />
      );
    }

    return <polygon {...polygonProps} />;
  }

  // Given 2 cells, returns a polygon
  // Shorthand so you dont have to supply _renderPolygon with dupe cells
  // as this function does it for you
  _renderColumnPolygon(firstCell, lastCell, columnIndex, { selected, key }) {
    const handleOnClick = () => {
      this.onClickColumn(columnIndex);
    };
    return this._renderPolygon(firstCell, firstCell, lastCell, lastCell, {
      className: "column",
      handleOnClick,
      handleOnMouseEnter: null,
      handleOnMouseLeave: null,
      selected,
      key
    });
  }

  _renderRowPolygon(firstCell, lastCell, rowIndex, { selected, key }) {
    const handleOnClick = () => {
      this.onClickRow(rowIndex);
    };
    return this._renderPolygon(firstCell, lastCell, lastCell, firstCell, {
      className: "row",
      handleOnClick,
      handleOnMouseEnter: null,
      handleOnMouseLeave: null,
      selected,
      key
    });
  }

  _renderCellPolygon(cell, rowIndex, columnIndex, { selected, key, data }) {
    const handleOnClick = () => {
      this.onClickCell({
        ri: rowIndex,
        ci: columnIndex,
        ti: this.tableIndex
      });
    };

    const handleOnMouseEnter = () => {
      this.onMouseEnterCell({
        ri: rowIndex,
        ci: columnIndex,
        ti: this.tableIndex
      });
    };

    const handleOnMouseLeave = () => {
      this.onMouseLeaveCell({
        ri: rowIndex,
        ci: columnIndex,
        ti: this.tableIndex
      });
    };

    return this._renderPolygon(cell, cell, cell, cell, {
      className: "cell",
      handleOnClick,
      handleOnMouseEnter,
      handleOnMouseLeave,
      selected,
      key,
      data,
      ci: columnIndex
    });
  }

  getPolygonForTable({ selected }) {
    const topLeftCell = this.buildCell(_.first(_.first(this.data)));
    const topRightCell = this.buildCell(_.last(_.first(this.data)));
    const bottomRightCell = this.buildCell(_.last(_.last(this.data)));
    const bottomLeftCell = this.buildCell(_.first(_.last(this.data)));

    return this._renderPolygon(
      topLeftCell,
      topRightCell,
      bottomRightCell,
      bottomLeftCell,
      {
        handleOnClick: this.onClickTable,
        selected,
        className: "table",
        handleOnMouseEnter: null,
        handleOnMouseLeave: null,
        key: "table"
      }
    );
  }

  getPolygonsForColumns({ selected }) {
    const polygons = [];

    const firstRow = _.first(this.data);
    const lastRow = _.last(this.data);

    const numberOfColumns = _.size(firstRow);

    _.range(0, numberOfColumns).forEach(columnIndex => {
      const firstCell = this.buildCell(firstRow[columnIndex]);
      const lastCell = this.buildCell(lastRow[columnIndex]);

      const isSelected = _.includes(selected, columnIndex);
      polygons.push(
        this._renderColumnPolygon(firstCell, lastCell, columnIndex, {
          selected: isSelected,
          key: columnIndex
        })
      );
    });

    return polygons;
  }

  getPolygonsForRows({ selected }) {
    const polygons = [];

    const numberOfRows = _.size(this.data);

    _.range(0, numberOfRows).forEach(rowIndex => {
      const row = this.data[rowIndex];
      const firstCell = this.buildCell(_.first(row));
      const lastCell = this.buildCell(_.last(row));

      const isSelected = _.includes(selected, rowIndex);

      polygons.push(
        this._renderRowPolygon(firstCell, lastCell, rowIndex, {
          selected: isSelected,
          key: `row-${rowIndex}`
        })
      );
    });

    return polygons;
  }

  getPolygonsForCells({ selected }) {
    const polygons = [];

    _.each(this.data, (row, rowIndex) => {
      _.each(row, (cell, columnIndex) => {
        const singleCell = this.buildCell(cell);
        const isSelected = _.find(selected, { ri: rowIndex, ci: columnIndex });
        polygons.push(
          this._renderCellPolygon(singleCell, rowIndex, columnIndex, {
            selected: isSelected,
            key: `cell-${rowIndex}-${columnIndex}`,
            data: cell
          })
        );
      });
    });

    return polygons;
  }
}

const OpexTable = ({
  data,
  tableIndex,
  selected,
  selectedColumns,
  selectedRows,
  selectedCells,
  imageHeight,
  imageWidth,
  views,
  onClick,
  onColumnClick,
  onRowClick,
  onCellClick,
  onMouseEnterCell,
  onMouseLeaveCell,
  shouldShowCOATooltip,
  cellPolygonRenderer,
  shouldUseCellPolygonRenderer,
  coaTooltipOffset
}) => {
  const table = new Table({
    data,
    tableIndex,
    imageHeight,
    imageWidth,
    shouldShowCOATooltip,
    cellPolygonRenderer,
    shouldUseCellPolygonRenderer,
    coaTooltipOffset,
    onClickTable: () => {
      onClick();
    },
    onClickColumn: columnIndex => {
      onColumnClick(columnIndex);
    },
    onClickRow: rowIndex => {
      onRowClick(rowIndex);
    },
    onClickCell: (rowIndex, columnIndex) => {
      if (onCellClick) {
        onCellClick(rowIndex, columnIndex);
      }
    },
    onMouseEnterCell: (rowIndex, columnIndex) => {
      if (onMouseEnterCell) {
        onMouseEnterCell(rowIndex, columnIndex);
      }
    },
    onMouseLeaveCell: (rowIndex, columnIndex) => {
      if (onMouseLeaveCell) {
        onMouseLeaveCell(rowIndex, columnIndex);
      }
    }
  });

  let polygons = [];

  if (_.includes(views, "tables")) {
    polygons = polygons.concat(table.getPolygonForTable({ selected }));
  }

  if (_.includes(views, "columns")) {
    polygons = table.getPolygonsForColumns({
      selected: selectedColumns
    });
  }

  if (_.includes(views, "rows")) {
    polygons = polygons.concat(
      table.getPolygonsForRows({
        selected: selectedRows
      })
    );
  }

  if (_.includes(views, "cells")) {
    polygons = polygons.concat(
      table.getPolygonsForCells({
        selected: selectedCells
      })
    );
  }

  return <>{polygons}</>;
};

export default OpexTable;

OpexTable.propTypes = {
  data: PropTypes.array,
  tableIndex: PropTypes.number, // `document` table index
  selected: PropTypes.bool, // is the entire table selected?
  selectedColumns: PropTypes.array, // indexes of the columns that are selected
  selectedRows: PropTypes.array, // indexes of the columns that are selected
  selectedCells: PropTypes.array, // array of location of cells that are selected {ri, ci}
  onClick: PropTypes.func, // handler when a user clicks on a entire table
  onColumnClick: PropTypes.func, // handler when a user clicks on a single column
  onRowClick: PropTypes.func, // handler when a user clicks on a single row
  onCellClick: PropTypes.func, // handler for when a user clicks on a cell
  onMouseEnterCell: PropTypes.func,
  onMouseLeaveCell: PropTypes.func,
  imageHeight: PropTypes.number, // height of the image, needed for polygon dimension computation
  imageWidth: PropTypes.number, // height of the image, needed for polygon dimension computation
  views: PropTypes.arrayOf(
    PropTypes.oneOf(["page", "tables", "columns", "rows", "cells"])
  ).isRequired, // render polygons for tables, columns, rows, or cells
  shouldShowCOATooltip: PropTypes.bool,
  cellPolygonRenderer: PropTypes.elementType,
  shouldUseCellPolygonRenderer: PropTypes.func,

  // offset to manually move the coa tooltip. Needed for sheets since the <OpexSheet> container has overflow: scroll
  // and if the tooltip falls outside the parent container it will not display
  coaTooltipOffset: PropTypes.object
};
