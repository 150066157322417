import PropTypes from "prop-types";

const OPEX_VALUE_TYPE_AMOUNT = "amount";
import {
  OPEX_VALUE_TYPE_INTEGER,
  OPEX_VALUE_TYPE_PERCENT,
  OPEX_VALUE_TYPE_NEGATIVE_AMOUNT
} from "ui/components/opex/shared";

const numberWithCommas = x =>
  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

const OpexCellValueText = ({ value, type }) => {
  if (type === OPEX_VALUE_TYPE_AMOUNT) {
    const roundedValue = parseFloat(value).toFixed(2);
    return <span>{numberWithCommas(roundedValue)}</span>;
  }
  if (type === OPEX_VALUE_TYPE_NEGATIVE_AMOUNT) {
    const roundedValue = Math.abs(parseFloat(value)).toFixed(2);
    return <span>{numberWithCommas(roundedValue)}</span>;
  }
  if (type === OPEX_VALUE_TYPE_INTEGER) {
    return <span>{numberWithCommas(parseInt(value))}</span>;
  }
  if (type === OPEX_VALUE_TYPE_PERCENT) {
    return <span>{`${parseFloat(value).toFixed(2)}%`}</span>;
  }
  return <span>{value}</span>;
};

export default OpexCellValueText;

OpexCellValueText.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  type: PropTypes.string
};
