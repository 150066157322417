import Popup from "reactjs-popup";
import { Fragment, useEffect, useRef, useState } from "react";
import Button from "ui/components/shared/Button";
import { useLocalStorage } from "ui/hooks/useLocalStorage";

export const TemplatePopup = ({ templates, value, onChange }) => {
  const [selectedTemplate, setSelectedTemplate] = useState(false);
  const popupRef = useRef();
  useEffect(() => {
    const closePopup = () => popupRef?.current?.close();
    const documentViewElement = document.getElementsByClassName(
      "OpexPanelView__DocumentView"
    )[0];
    documentViewElement?.addEventListener("scroll", closePopup);

    return () => documentViewElement?.removeEventListener("scroll", closePopup);
  }, []);
  const [doNotAskAgain, setDoNotAskAgain] = useState(false);
  const [showWarning, setShowWarning] = useLocalStorage(
    "changeTemplateWarning",
    true
  );
  const handleChange = template => {
    if (showWarning) {
      setSelectedTemplate(template.id);
    } else {
      onChange(template.id);
    }
  };
  const handleConfirm = () => {
    setShowWarning(!doNotAskAgain);
    setSelectedTemplate(null);
    onChange(selectedTemplate);
  };
  return (
    <Fragment>
      {showWarning && selectedTemplate && (
        <Popup
          open
          closeOnDocumentClick
          onClose={() => setSelectedTemplate(null)}
          className="centered-popover"
          modal
        >
          <div className="TemplatePopup-warning">
            <h1>Warning message</h1>
            <p>
              All current mappings for this document will be deleted if you
              proceed with switching templates.
            </p>
            <label>
              <input
                type="checkbox"
                onChange={e => setDoNotAskAgain(e.target.checked)}
              />
              Do not ask again
            </label>
            <div className="submit">
              <div
                role="button"
                className="OpexButton OpexButton-primary"
                onClick={handleConfirm}
              >
                Got it
              </div>
              <div
                role="button"
                className="OpexButton OpexButton-secondary"
                onClick={() => setSelectedTemplate(null)}
              >
                Cancel
              </div>
            </div>
          </div>
        </Popup>
      )}
      <Popup
        ref={popupRef}
        trigger={
          <div>
            <Button className="TemplateButton">
              <span>{value?.description || "Select Template"}</span>
              <img src={require("ui/images/chevron-blue.svg")} />
            </Button>
          </div>
        }
        position="bottom left"
        contentStyle={{ width: "auto" }}
      >
        <div className="TemplatePopup">
          <ul>
            {templates?.map(template => (
              <li
                key={template.id}
                role="button"
                className={template.id === value?.id ? "active" : ""}
                onClick={() => handleChange(template)}
              >
                {template.name}
                {template.id === value?.id && (
                  <img
                    src={require("ui/images/check-blue.svg")}
                    className="FilterButton__icon"
                  />
                )}
              </li>
            ))}
          </ul>
        </div>
      </Popup>
    </Fragment>
  );
};
