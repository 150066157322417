import PropTypes from "prop-types";
import Popup from "reactjs-popup";
import cx from "classnames";

const OpexModal = ({
  headerText,
  body,
  closeOnDocumentClick,
  hideCancel,
  cancelText,
  confirmText,
  open,
  onConfirm,
  onCancel,
  onClose
}) => {
  function handleOnConfirm() {
    if (onConfirm) {
      onConfirm();
    }
    handleOnClose();
  }

  function handleOnCancel() {
    if (onCancel) {
      onCancel();
    }
    handleOnClose();
  }

  function handleOnClose() {
    if (onClose) {
      onClose();
    }
  }

  return (
    <Popup
      open={open}
      closeOnDocumentClick={closeOnDocumentClick === false ? false : true}
      onClose={handleOnClose}
      className="centered-popover"
      modal
    >
      <div
        style={{
          display: "flex",
          backgroundColor: "white",
          justifyContent: "space-between",
          flexDirection: "column",
          padding: "15px",
          minWidth: "300px"
        }}
      >
        <div className="EditPortfolioPopover__header">
          <h1>{headerText}</h1>
        </div>
        <div className="EditPortfolioPopover__input-section">{body}</div>
        <div className="EditPortfolioPopover__submit-form">
          <button
            className={cx("Button", "Button__blueButton")}
            style={{ marginLeft: "10px" }}
            onClick={handleOnConfirm}
          >
            {confirmText ? confirmText : "OK"}
          </button>
          {!hideCancel && (
            <button
              className={cx("Button", "Button__blackButton")}
              onClick={handleOnCancel}
            >
              {cancelText ? cancelText : "Cancel"}
            </button>
          )}
        </div>
      </div>
    </Popup>
  );
};

export default OpexModal;

OpexModal.propTypes = {
  headerText: PropTypes.string,
  body: PropTypes.element,
  hideCancel: PropTypes.bool,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  closeOnDocumentClick: PropTypes.bool
};
