import { useState } from "react";
import PropTypes from "prop-types";
import Button from "ui/components/shared/Button";
import cx from "classnames";

import { editPredefValue } from "ui/components/admin/helpers/api";

const AdminPredefRow = props => {
  const [editable, setEditable] = useState(false);
  const [currentPredef, setCurrentPredef] = useState(props.predefValue);

  /**
   * Renders the Edit button or an input field with submit/cancel options
   * @param {string} predefId
   * @returns some jsx
   */
  const renderEditOrSubmit = predefId => {
    if (editable) {
      return (
        <div className="AdminPredefRow__edit">
          <input id={predefId} type="text" placeholder={currentPredef} />

          <Button
            className="edit__submit"
            onClick={e => handleSubmitUpdate(predefId, e)}
          >
            Submit
          </Button>
          <Button className="edit__cancel" onClick={() => setEditable(false)}>
            Cancel
          </Button>
        </div>
      );
    }
    return (
      <div className="AdminPredefRow__toggle">
        <Button className="toggle_edit" onClick={() => setEditable(true)}>
          Edit
        </Button>
        <Button
          className="toggle_delete"
          type="button"
          onClick={() => props.del(predefId, currentPredef)}
        >
          Delete
        </Button>
      </div>
    );
  };

  /**
   * Takes the inputId and extracts the value and calls on editPredefValue() API
   * @param {event} e
   * @param {string} inputId
   */
  const handleSubmitUpdate = (inputId, e) => {
    const newValue = document.getElementById(inputId).value.trim();
    const hasDuplicate = props.duplicates(newValue, e);

    if (!newValue) {
      alert("Please submit a valid value");
      e.preventDefault();
    } else if (!hasDuplicate) {
      editPredefValue(inputId, newValue);
      setEditable(false);
      setCurrentPredef(newValue);
    }
  };

  return (
    <div className={cx("CompanyRow")}>
      <h2 className={cx("AdminPredefRow__details")}>{currentPredef}</h2>
      <div className={cx("AdminPredefRow__details")}>
        {renderEditOrSubmit(props.predefId)}
      </div>
    </div>
  );
};

AdminPredefRow.propTypes = {
  predefValue: PropTypes.string,
  predefId: PropTypes.number,
  del: PropTypes.func,
  duplicates: PropTypes.func
};

export default AdminPredefRow;
