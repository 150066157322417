import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import cx from "classnames";

import { fetchCompanies } from "ui/store/actions/companies.js";
import {
  updateFilter,
  removeFilter,
  fetchUsers,
  updateAlphaFilter
} from "ui/store/actions/users.js";

import Button from "ui/components/shared/Button";

import { hasSuperAdminAccess } from "helpers/authorization";

class UsersTableFilters extends Component {
  componentDidMount() {
    const { getCompanies } = this.props;
    getCompanies();
  }

  alphaSort = () => {
    const { updateSort, refreshPage, page, alpha } = this.props;
    if (alpha === "desc") {
      updateSort("asc");
    } else {
      updateSort("desc");
    }
    refreshPage(page);
  };

  filterRole = e => {
    const { refreshPage, clearFilter, selectFilter, page } = this.props;
    const role = e.target.value;
    if (role === "all") {
      clearFilter("role");
    } else {
      selectFilter("role", role);
    }
    refreshPage(page);
  };

  filterCompany = e => {
    const { refreshPage, clearFilter, selectFilter, page } = this.props;
    const companyId = e.target.value;
    if (companyId === "all") {
      clearFilter("company");
    } else {
      selectFilter("company", companyId);
    }
    refreshPage(page);
  };

  filterStatus = e => {
    const { refreshPage, clearFilter, selectFilter, page } = this.props;
    const status = e.target.value;
    if (status === "all") {
      clearFilter("status");
    } else {
      selectFilter("status", status);
    }
    refreshPage(page);
  };

  render() {
    let role;
    const { companies, currentUser, alpha } = this.props;
    if (currentUser) {
      role = currentUser.role;
    } else {
      role = "";
    }
    return (
      <div className="UsersTable__filter-superadmin">
        <div
          className={cx(
            "UsersTable__filter-superadmin__columnName",
            "ms-column-1",
            "PaginationNavigation__sort-container"
          )}
        >
          <Button
            className="PaginationNavigation__Button PaginationNavigation__Button__alpha"
            onClick={this.alphaSort}
          >
            {alpha === "asc" ? `email \u2193` : `email \u2191`}
          </Button>
        </div>
        {hasSuperAdminAccess(role) && (
          <div
            className={cx(
              "UsersTable__filter-superadmin__columnName",
              "ms-column-2"
            )}
          >
            <select type="select" onChange={this.filterRole}>
              <option value="all">all</option>
              <option value="admin">admin</option>
              <option value="user">user</option>
              <option value="superadmin">superadmin</option>
            </select>
          </div>
        )}
        {hasSuperAdminAccess(role) && (
          <div
            className={cx(
              "UsersTable__filter-superadmin__columnName",
              "ms-column-3"
            )}
          >
            <select type="select" onChange={this.filterCompany}>
              <option value="all">all</option>
              {companies.map((company, idx) => {
                const { company_name, id } = company;
                return (
                  <option key={`company-option-${idx}`} value={id}>
                    {company_name}
                  </option>
                );
              })}
            </select>
          </div>
        )}
        {hasSuperAdminAccess(role) && (
          <div
            className={cx(
              "ms-column-4",
              "UsersTable__filter-superadmin__columnName",
              "center"
            )}
          >
            <select type="select" onChange={this.filterStatus}>
              <option value="all">all</option>
              <option value="active">active</option>
              <option value="inactive">inactive</option>
            </select>
          </div>
        )}
      </div>
    );
  }
}

UsersTableFilters.propTypes = {
  filterRole: PropTypes.func.isRequired,
  filterCompany: PropTypes.func.isRequired,
  filterStatus: PropTypes.func.isRequired,
  companies: PropTypes.array,
  getCompanies: PropTypes.func,
  refreshPage: PropTypes.func,
  clearFilter: PropTypes.func,
  selectFilter: PropTypes.func,
  updateSort: PropTypes.func,
  page: PropTypes.number,
  alpha: PropTypes.string,
  currentUser: PropTypes.object
};

function mapStateToProps({ companies, users, currentUser }) {
  return {
    currentUser,
    companies: companies.companies,
    page: users.page,
    filter: users.filter,
    alpha: users.alpha
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getCompanies: () => dispatch(fetchCompanies()),
    selectFilter: (filterType, val) => dispatch(updateFilter(filterType, val)),
    clearFilter: filterType => dispatch(removeFilter(filterType)),
    refreshPage: (toPage, filter) => dispatch(fetchUsers(toPage, filter)),
    updateSort: alphaFilter => dispatch(updateAlphaFilter(alphaFilter))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersTableFilters);
