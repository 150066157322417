import { useRef, useReducer, useState } from "react";
import PropTypes from "prop-types";
import Popup from "reactjs-popup";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import cx from "classnames";

import { updateDocumentTemplate } from "ui/store/actions/rentRoll";
import useDocument from "../../hooks/useDocument";

import Button from "ui/components/shared/Button";
import FieldSelect from "./FieldSelect";
import NewRule from "./NewRule";
import {
  getTemplateObject,
  organizeHeaderData
} from "../../helpers/processing";
import { fieldNames } from "ui/components/rentRoll/splitPanel/helpers/dictionaries";
import { hasAdminAccess } from "helpers/authorization";
import rentRollHasManualAddedRow from "../../helpers/rentRollHasManualAddedRow";

function initialState(headerData) {
  const { advancedRules, multipleLineGrouping } = headerData || {
    multipleLineGrouping: null,
    advancedRules: null
  };
  let rules = {};
  if (advancedRules) {
    for (let rule of advancedRules) {
      rules[rule.field] = { field: rule.field, func: rule.func };
    }
  }
  return { rules, multipleLineGrouping };
}

function reducer(state, action) {
  switch (action.type) {
    case "reset_state":
      return action.state;
    case "update_multiple_line_grouping":
      return {
        ...state,
        multipleLineGrouping: action.grouping
      };
    case "update_rule":
      return {
        ...state,
        rules: {
          ...state.rules,
          [action.field]: { ...state.rules[action.field], func: action.rule }
        }
      };
    case "create_rule":
      return {
        ...state,
        rules: {
          ...state.rules,
          [action.field]: { field: action.field, func: action.rule }
        }
      };
    case "delete_rule":
      delete state.rules[action.field];
      return {
        ...state,
        rules: {
          ...state.rules
        }
      };
    case "update_field":
      if (state.rules[action.old].custom) {
        delete state.rules[action.old].custom;
      }
      return {
        ...state,
        rules: {
          ...state.rules,
          [action.old]: { ...state.rules[action.old], field: action.new }
        }
      };
    case "update_custom_field":
      return {
        ...state,
        rules: {
          ...state.rules,
          [action.old]: {
            ...state.rules[action.old],
            field: "custom_field",
            custom: action.custom
          }
        }
      };
    case "add_error":
      return {
        ...state,
        rules: {
          ...state.rules,
          [action.field]: { ...state.rules[action.field], error: action.error }
        }
      };
    case "delete_error":
      delete state.rules[action.field].error;
      return {
        ...state,
        rules: {
          ...state.rules
        }
      };
    default:
      return state;
  }
}

export const AdvancedRulesPopover = ({
  rentRollDocument,
  location,
  cache,
  userRole,
  refreshData,
  documentMetadata,
  setDocumentMetadata,
  currentHeaderIndex,
  advancedRulesOpen,
  setAdvancedRulesOpen,
  rentRoll
}) => {
  const { pi, ti } = location;
  const ref = useRef();
  const closePopover = () => {
    setAdvancedRulesOpen(false);
    ref.current.close();
  };
  const headerData = organizeHeaderData(rentRollDocument);
  const { updateTemplate } = useDocument();
  const [state, dispatch] = useReducer(
    reducer,
    initialState(headerData[pi][ti])
  );
  const [newRuleDisplay, setNewRuleDisplay] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const isPowerUser = hasAdminAccess(userRole);
  function packageRules() {
    let rules = [];
    Object.keys(state.rules).forEach(field => {
      let ruleField = state.rules[field].custom
        ? state.rules[field].custom
        : state.rules[field].field;
      rules.push({
        field: ruleField,
        func: [state.rules[field].func]
      });
    });
    return rules;
  }

  return (
    <Popup
      ref={ref}
      trigger={
        <div style={{ cursor: "pointer" }}>
          <img src={require("ui/images/icon-cog.svg")} />
        </div>
      }
      position={["center center"]}
      modal
      className="EditModal"
      open={advancedRulesOpen}
      onOpen={() => {
        dispatch({
          type: "reset_state",
          state: initialState(headerData[pi][ti])
        });
      }}
      onClose={() => {
        dispatch({
          type: "reset_state",
          state: { rules: [], multipleLineGrouping: "none" }
        });
      }}
    >
      <div className={cx("EditModal__header", "HeaderEditPopover__header")}>
        Advanced Rules
      </div>
      <div className={cx("EditModal__body", "HeaderEditPopover__body")}>
        <div className="AdvancedRulesPopover__multipleLineGrouping">
          <div className="HeaderEditPopover__subtitle">
            Multiple Line Grouping
          </div>
          <select
            value={state.multipleLineGrouping}
            onChange={e => {
              dispatch({
                type: "update_multiple_line_grouping",
                grouping: e.target.value
              });
            }}
          >
            <option value={"none"}>none</option>
            {headerData[pi][ti] &&
              [
                ...new Set(
                  headerData[pi][ti].headers
                    .map(header => header.id)
                    .concat(Object.keys(fieldNames))
                )
              ].map(header => (
                <option key={header} value={header}>
                  {header}
                </option>
              ))}
          </select>
        </div>
      </div>
      <div
        className={cx("EditModal__body", "HeaderEditPopover__body")}
        style={{ backgroundColor: "white", border: "none" }}
      >
        <div
          className="AdvancedRulesPopover__mapHeader"
          style={{ alignItems: "end" }}
        >
          <div className="HeaderEditPopover__subtitle">Functions</div>
          {!newRuleDisplay && isPowerUser && (
            <Button
              onClick={() => setNewRuleDisplay(true)}
              className={cx("Button__blueButton", "NewRules__smButton")}
            >
              Create New Function
            </Button>
          )}
        </div>
      </div>
      {newRuleDisplay && (
        <NewRule
          headerData={headerData[pi][ti]}
          dispatch={dispatch}
          setNewRuleDisplay={setNewRuleDisplay}
          isAdminUser={isPowerUser}
        />
      )}
      {Object.keys(state.rules).map(field => (
        <div
          key={field}
          className={cx("EditModal__body", "HeaderEditPopover__body")}
        >
          <div className="AdvancedRulesPopover__advancedRule">
            <div
              className="AdvancedRulesPopover__mapHeader"
              style={{ marginBottom: "5px" }}
            >
              <div style={{ display: "flex" }}>
                <div className="AdvancedRulesPopover__subtitle">Field:</div>
                {isPowerUser ? (
                  <FieldSelect
                    value={state.rules[field].field}
                    keyIdx={field}
                    headerData={headerData[pi][ti]}
                    advancedRule={state.rules[field]}
                    dispatch={dispatch}
                  />
                ) : (
                  <h3 className="AdvancedRulesPopover__subtitle">
                    {state.rules[field].field}
                  </h3>
                )}
              </div>
              {isPowerUser && (
                <img
                  src={require("ui/images/icon-x.svg")}
                  style={{ cursor: "pointer" }}
                  onClick={() => dispatch({ type: "delete_rule", field })}
                />
              )}
            </div>
            {state.rules[field].error && (
              <div style={{ color: "red" }}>
                {state.rules[field].error.message}
              </div>
            )}
            {isPowerUser ? (
              <textarea
                value={state.rules[field].func}
                onChange={e => {
                  dispatch({
                    type: "update_rule",
                    field: state.rules[field].field,
                    rule: e.target.value
                  });
                }}
                rows="4"
                cols="53"
                height="auto"
              />
            ) : (
              <p>{state.rules[field].func}</p>
            )}
          </div>
        </div>
      ))}
      <div className="EditModal__verify">
        <Button
          className={cx("Button", "Button__blackButton")}
          onClick={() => {
            closePopover();
          }}
        >
          Cancel
        </Button>
        {isSaving ? (
          <Button
            className={cx("Button", "Button__blackButton")}
            disabled={true}
            style={{ marginLeft: "5px" }}
          >
            Updating...
          </Button>
        ) : (
          <Button
            className={cx("Button", "Button__blueButton")}
            onClick={async () => {
              setIsSaving(true);
              const templateData = getTemplateObject(
                rentRollDocument,
                headerData,
                { pi, ti, ri: headerData[pi][ti].rowIndex },
                cache,
                cache["rowHeader-" + pi + "-" + ti]
                  ? cache["rowHeader-" + pi + "-" + ti].row
                  : headerData[pi][ti].rowIds
              );
              const metaName = headerData[pi][ti].metaName;
              const headerIndex = headerData[pi][ti].headerIndex;
              const multipleLineGrouping =
                state.multipleLineGrouping === "none"
                  ? null
                  : state.multipleLineGrouping;
              const advancedRules = packageRules();
              if (documentMetadata[currentHeaderIndex]) {
                documentMetadata[
                  currentHeaderIndex
                ].multipleLineGrouping = multipleLineGrouping;
                documentMetadata[
                  currentHeaderIndex
                ].advancedRules = advancedRules;
                setDocumentMetadata([...documentMetadata]);
              }
              const hasManualRow = rentRollHasManualAddedRow(rentRoll);
              if (hasManualRow) {
                const confirmed = window.confirm(
                  "Updating advanced rules will remove any manually added rows."
                );
                if (confirmed) {
                  await updateTemplate({
                    templateData,
                    metaName,
                    headerIndex,
                    multipleLineGrouping: multipleLineGrouping,
                    advancedRules: advancedRules
                  });
                  await refreshData();
                }
              } else {
                await updateTemplate({
                  templateData,
                  metaName,
                  headerIndex,
                  multipleLineGrouping: multipleLineGrouping,
                  advancedRules: advancedRules
                });
                await refreshData();
              }
              setIsSaving(false);
              closePopover();
            }}
            style={{ marginLeft: "5px" }}
          >
            Update
          </Button>
        )}
      </div>
    </Popup>
  );
};

AdvancedRulesPopover.defaultProps = {
  widgetAuth: {}
};

AdvancedRulesPopover.propTypes = {
  rentRollDocument: PropTypes.object,
  cache: PropTypes.object,
  documentId: PropTypes.number,
  location: PropTypes.object,
  headerData: PropTypes.array,
  updateDocumentTemplate: PropTypes.func,
  rows: PropTypes.array,
  widgetAuth: PropTypes.object,
  userRole: PropTypes.string,
  refreshData: PropTypes.func,
  documentMetadata: PropTypes.array,
  setDocumentMetadata: PropTypes.func,
  currentHeaderIndex: PropTypes.number,
  advancedRulesOpen: PropTypes.bool,
  setAdvancedRulesOpen: PropTypes.func,
  rentRoll: PropTypes.any
};

const mapStateToProps = ({ rentRoll, currentUser }) => ({
  rentRollDocument: rentRoll.document,
  cache: rentRoll.cache,
  widgetAuth: currentUser.widgetAuth,
  userRole: currentUser.role
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateDocumentTemplate }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdvancedRulesPopover);
