import moment from "moment";
import { documentStatus as DocumentStatus } from "server/middleware/api/helpers/documents";
import { documentType as DocumentType } from "server/middleware/api/helpers/documents";

export const documentStatus = {
  [DocumentStatus.COMPLETED]: "Complete",
  [DocumentStatus.PENDING_REVIEW]: "Needs review",
  [DocumentStatus.CREATED]: "Uploading",
  [DocumentStatus.PROCESSING_IMAGES]: "Processing Images",
  [DocumentStatus.ANALYSIS_QUEUED]: "Analysis Queued",
  [DocumentStatus.ANALYZING]: "Analyzing Document",
  [DocumentStatus.TEXTRACT_TIMEOUT]: "Upload Timeout",
  [DocumentStatus.FAILED]: "Upload Failed",
  [DocumentStatus.PROPERTY_INFO_NEEDED]: "Info Required"
};

// Static document status
// Need to make it dynamic in the future
export const documentStatusStaticFilter = {
  [DocumentStatus.COMPLETED]: "Complete",
  [DocumentStatus.PENDING_REVIEW]: "Needs review",
  [DocumentStatus.PROPERTY_INFO_NEEDED]: "Info Required",
  [DocumentStatus.FAILED]: "Upload Failed",
  [DocumentStatus.TEXTRACT_TIMEOUT]: "Upload Timeout",
}

export const documentStatusStyle = {
  [DocumentStatus.COMPLETED]: "complete",
  [DocumentStatus.PENDING_REVIEW]: "needs_review",
  [DocumentStatus.CREATED]: "uploading",
  [DocumentStatus.PROCESSING_IMAGES]: "processing_images",
  [DocumentStatus.ANALYSIS_QUEUED]: "analysis_queued",
  [DocumentStatus.ANALYZING]: "analyzing_document",
  [DocumentStatus.TEXTRACT_TIMEOUT]: "timeout",
  [DocumentStatus.FAILED]: "failed",
  [DocumentStatus.PROPERTY_INFO_NEEDED]: "info_needed"
};

export const documentType = {
  [DocumentType.RENT_ROLL]: "Rent Roll",
  [DocumentType.OPERATING_STATEMENT]: "Operating Statement"
};

export const fields = [
  "File Name",
  "Last Updated",
  "User Name",
  "Effective Date",
  "Property",
  "Type",
  "Document",
  "Status",
  ""
];

export const columnDetails = {
  "File Name": {
    columnName: "File Name",
    sortName: "documents.original_filename"
  },
  "Last Updated": {
    columnName: "Last Updated",
    sortName: "documents.updated_at"
  },
  "User Name": {
    columnName: "User Name",
    sortName: "users.email"
  },
  "Effective Date": {
    columnName: "Effective Date",
    sortName: "documents.effective_date"
  },
  Property: {
    columnName: "Property",
    sortName: "properties.street_1"
  },
  Type: {
    columnName: "Type",
    sortName: "properties.property_type"
  },
  Document: {
    columnName: "Document Type",
    sortName: "documents.document_type"
  },
  Status: {
    columnName: "Status",
    sortName: "documents.status"
  },
  "": {
    columnName: ""
  }
};

export const parseAddress = doc => {
  return `${doc.street1}${doc.street2 ? ` ${doc.street2}` : ""}${
    doc.city ? ` ${doc.city}` : ""
  }${doc.state ? `, ${doc.state}` : ""}${
    doc.postalCode ? ` ${doc.postalCode}` : ""
  }`;
};

export function formatDate(date) {
  if (!date) return "-";
  return moment(date)
    .utc()
    .format("MM/DD/YYYY");
}

export function formatTimestamp(date) {
  if (!date) return "-";
  return moment(date).format("MM/DD/YYYY HH:mm:ss");
}
