import { useState } from "react";
import PropTypes from "prop-types";
import Popup from "reactjs-popup";
import FileUploader from "./fileUpload";
import IconButton from "ui/components/shared/IconButton";
import Button from "ui/components/shared/Button";

const PropertyRentRollPopup = ({
  beginUploadingFile,
  currentUser,
  isUploading,
  isProcessing
}) => {
  const [isOpen, updateIsOpen] = useState(false);

  return (
    <Popup
      trigger={
        <Button
          className="Button__uploadButton"
          onClick={() => updateIsOpen(true)}
        >
          Upload Document
        </Button>
      }
      modal
      position="top left"
      open={isOpen}
      onClose={() => updateIsOpen(false)}
      onOpen={() => updateIsOpen(true)}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: "white",
          border: "1.5px solid grey",
          width: "fit-content"
        }}
      >
        <FileUploader
          currentUser={currentUser}
          beginUploadingFile={beginUploadingFile}
          isProcessing={isProcessing}
          isUploading={isUploading}
          OnUploadClose={() => updateIsOpen(false)}
        />
        <IconButton
          className="close-button"
          onClick={() => updateIsOpen(false)}
          src={require("ui/images/x-icon.svg")}
        />
      </div>
    </Popup>
  );
};

PropertyRentRollPopup.propTypes = {
  beginUploadingFile: PropTypes.func,
  isUploading: PropTypes.bool,
  isProcessing: PropTypes.bool
};

export default PropertyRentRollPopup;
