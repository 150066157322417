import { Component, createRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Checkbox from "ui/components/shared/Checkbox3";
import Button from "ui/components/shared/Button";
import { documentType as DocumentType } from "ui/components/helpers/documents";
import {
  documentType,
  documentStatusStaticFilter
} from "../manageFiles/helpers/manageFileListView";
import { getPropertyValues } from "ui/components/properties/propertiesUtil";
import cx from "classnames";
import { fetchPropertiesByCompany } from "ui/store/actions/properties";
import { bindActionCreators } from "redux";
import { isCreditLensByGroupName } from "ui/components/opex/shared";

class FilterDocuments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      propertyTypeChecked: {},
      documentTypeChecked: {},
      propertyListChecked: {},
      documentStatusChecked: {},
      isPropertyTypeExpanded: false,
      isDocumentTypeExpanded: false,
      isPropertyListExpanded: false,
      isDocumentStatusExpanded: false
    };
    this.propertyTypeRef = createRef();
    this.documentTypeRef = createRef();
    this.propertyListRef = createRef();
    this.documentStatusRef = createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    const { fetchPropertiesByCompany, widgetAuth } = this.props;
    document.addEventListener("mousedown", this.handleClickOutside);
    fetchPropertiesByCompany(true, widgetAuth);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (
      this.propertyTypeRef &&
      !this.propertyTypeRef.current.contains(event.target)
    ) {
      this.setState({ isPropertyTypeExpanded: false });
    }
    if (
      this.documentTypeRef &&
      !this.documentTypeRef.current.contains(event.target)
    ) {
      this.setState({ isDocumentTypeExpanded: false });
    }
    if (
      this.propertyListRef &&
      !this.propertyListRef.current.contains(event.target)
    ) {
      this.setState({ isPropertyListExpanded: false });
    }
    if (
      this.documentStatusRef &&
      !this.documentStatusRef.current.contains(event.target)
    ) {
      this.setState({ isDocumentStatusExpanded: false });
    }
  }

  render() {
    const {
      properties,
      propertiesSelect,
      setPropertyTypeFilter,
      setDocumentTypeFilter,
      setPropertyListFilter,
      setDocumentStatusFilter,
      groupName
    } = this.props;

    const allProperties = properties.concat(propertiesSelect);
    const dropdownIconStyle = {
      display: "block",
      opacity: "0.5",
      width: "0.75em",
      marginTop: "2px",
      paddingLeft: "20px"
    };

    let isCL = isCreditLensByGroupName(groupName);
    const propertyTypes = getPropertyValues(isCL);

    const handlePropertyTypeFilterChange = text => {
      if (this.state.propertyTypeChecked[text] === true) {
        setPropertyTypeFilter(prev => ({
          ...prev,
          [text]: false
        }));
        this.setState({
          propertyTypeChecked: {
            ...this.state.propertyTypeChecked,
            [text]: false
          }
        });
      } else {
        setPropertyTypeFilter(prev => ({
          ...prev,
          [text]: true
        }));
        this.setState({
          propertyTypeChecked: {
            ...this.state.propertyTypeChecked,
            [text]: true
          }
        });
      }
    };

    const handleDocumentTypeFilterChange = text => {
      if (this.state.documentTypeChecked[text] === true) {
        setDocumentTypeFilter(prev => ({
          ...prev,
          [text]: false
        }));
        this.setState({
          documentTypeChecked: {
            ...this.state.documentTypeChecked,
            [text]: false
          }
        });
      } else {
        setDocumentTypeFilter(prev => ({
          ...prev,
          [text]: true
        }));
        this.setState({
          documentTypeChecked: {
            ...this.state.documentTypeChecked,
            [text]: true
          }
        });
      }
    };

    const handleDocumentStatusFilterChange = label => {
      if (this.state.documentStatusChecked[label] === true) {
        setDocumentStatusFilter(prev => ({
          ...prev,
          [label]: false
        }));
        this.setState({
          documentStatusChecked: {
            ...this.state.documentStatusChecked,
            [label]: false
          }
        });
      } else {
        setDocumentStatusFilter(prev => ({
          ...prev,
          [label]: true
        }));
        this.setState({
          documentStatusChecked: {
            ...this.state.documentStatusChecked,
            [label]: true
          }
        });
      }
    };

    const handlePropertyListFilterChange = text => {
      if (this.state.propertyListChecked[text] === true) {
        setPropertyListFilter(prev => ({
          ...prev,
          [text]: false
        }));
        this.setState({
          propertyListChecked: {
            ...this.state.propertyListChecked,
            [text]: false
          }
        });
      } else {
        setPropertyListFilter(prev => ({
          ...prev,
          [text]: true
        }));
        this.setState({
          propertyListChecked: {
            ...this.state.propertyListChecked,
            [text]: true
          }
        });
      }
    };

    return (
      <div className="FilterDocuments__body">
        <Button
          className={cx("Button__filterDocumentsButton")}
          style={{ cursor: "default", width: "fit-content" }}
        >
          <img
            src={require("ui/images/filter.svg")}
            style={{ paddingRight: "10px", width: "0.9em" }}
          />
          Filter By
        </Button>

        <span className="FilterDocuments__divider" />

        <Button
          className={cx("Button__filterDocumentsButton")}
          onClick={() =>
            this.setState(prevState => ({
              isPropertyListExpanded: !prevState.isPropertyListExpanded
            }))
          }
        >
          {"Property"}
          {Object.values(this.state.propertyListChecked).filter(Boolean)
            .length > 0 && (
            <div className="FilterDocuments__filterCount">
              {
                Object.values(this.state.propertyListChecked).filter(Boolean)
                  .length
              }
            </div>
          )}
          <img
            src={require("ui/images/icon-triangle-arrow-down-gray.svg")}
            style={dropdownIconStyle}
          />
        </Button>
        <div ref={this.propertyListRef}>
          {this.state.isPropertyListExpanded && (
            <div
              className={cx(
                "FilterDocuments__filterExpanded",
                "FilterDocuments__filterExpanded--propertyList"
              )}
            >
              {allProperties.map(property => (
                <Checkbox
                  label={property.name}
                  value={property.id}
                  handleCheckboxChange={handlePropertyListFilterChange}
                  hasChecked={
                    this.state.propertyListChecked[property.id] === true
                  }
                  key={"checkbox_property" + property.id}
                />
              ))}
            </div>
          )}
        </div>

        <span className="FilterDocuments__divider" />

        <Button
          className={cx("Button__filterDocumentsButton")}
          onClick={() =>
            this.setState(prevState => ({
              isPropertyTypeExpanded: !prevState.isPropertyTypeExpanded
            }))
          }
        >
          {"Property Type"}
          {Object.values(this.state.propertyTypeChecked).filter(Boolean)
            .length > 0 && (
            <div className="FilterDocuments__filterCount">
              {
                Object.values(this.state.propertyTypeChecked).filter(Boolean)
                  .length
              }
            </div>
          )}
          <img
            src={require("ui/images/icon-triangle-arrow-down-gray.svg")}
            style={dropdownIconStyle}
          />
        </Button>
        <div ref={this.propertyTypeRef}>
          {this.state.isPropertyTypeExpanded && (
            <div
              className={cx(
                "FilterDocuments__filterExpanded",
                "FilterDocuments__filterExpanded--propertyType"
              )}
            >
              {propertyTypes.map(propertyType => (
                <Checkbox
                  label={propertyType.name}
                  value={propertyType.id}
                  handleCheckboxChange={handlePropertyTypeFilterChange}
                  hasChecked={
                    this.state.propertyTypeChecked[propertyType.id] === true
                  }
                  key={"checkbox_propertyType" + propertyType.id}
                />
              ))}
            </div>
          )}
        </div>

        <span className="FilterDocuments__divider" />

        <Button
          className={cx("Button__filterDocumentsButton")}
          onClick={() =>
            this.setState(prevState => ({
              isDocumentTypeExpanded: !prevState.isDocumentTypeExpanded
            }))
          }
        >
          {"Document Type"}
          {Object.values(this.state.documentTypeChecked).filter(Boolean)
            .length > 0 && (
            <div className="FilterDocuments__filterCount">
              {
                Object.values(this.state.documentTypeChecked).filter(Boolean)
                  .length
              }
            </div>
          )}
          <img
            src={require("ui/images/icon-triangle-arrow-down-gray.svg")}
            style={dropdownIconStyle}
          />
        </Button>
        <div ref={this.documentTypeRef}>
          {this.state.isDocumentTypeExpanded && (
            <div
              className={cx(
                "FilterDocuments__filterExpanded",
                "FilterDocuments__filterExpanded--documentType"
              )}
            >
              {Object.values(DocumentType).map(docType => (
                <Checkbox
                  label={documentType[docType]}
                  value={docType}
                  handleCheckboxChange={handleDocumentTypeFilterChange}
                  hasChecked={this.state.documentTypeChecked[docType] === true}
                  key={"checkbox_docType" + docType}
                >
                  {documentType[docType]}
                </Checkbox>
              ))}
            </div>
          )}
        </div>

        <span className="FilterDocuments__divider" />

        <div>
          <Button
            className={cx("Button__filterDocumentsButton")}
            onClick={() =>
              this.setState(prevState => ({
                isDocumentStatusExpanded: !prevState.isDocumentStatusExpanded
              }))
            }
          >
            {"Status"}
            {Object.values(this.state.documentStatusChecked).filter(Boolean)
              .length > 0 && (
              <div className="FilterDocuments__filterCount">
                {
                  Object.values(this.state.documentStatusChecked).filter(
                    Boolean
                  ).length
                }
              </div>
            )}
            <img
              src={require("ui/images/icon-triangle-arrow-down-gray.svg")}
              style={dropdownIconStyle}
            />
          </Button>
          <div ref={this.documentStatusRef}>
            {this.state.isDocumentStatusExpanded && (
              <div
                className={cx(
                  "FilterDocuments__filterExpanded",
                  "FilterDocuments__filterExpanded--documentStatus"
                )}
              >
                {Object.keys(documentStatusStaticFilter).map(docStatus => (
                  <Checkbox
                    label={documentStatusStaticFilter[docStatus]}
                    value={docStatus}
                    handleCheckboxChange={handleDocumentStatusFilterChange}
                    hasChecked={
                      this.state.documentStatusChecked[docStatus] === true
                    }
                    key={"checkbox_documentStatus" + docStatus}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

FilterDocuments.propTypes = {
  setActiveFilter: PropTypes.func,
  properties: PropTypes.array,
  propertiesSelect: PropTypes.array,
  setPropertyTypeFilter: PropTypes.func,
  setDocumentTypeFilter: PropTypes.func,
  setPropertyListFilter: PropTypes.func,
  setDocumentStatusFilter: PropTypes.func,
  fetchPropertiesByCompany: PropTypes.func,
  widgetAuth: PropTypes.object,
  groupName: PropTypes.string
};

function mapStateToProps(state) {
  return {
    propertiesSelect: state.properties.propertiesSelect,
    properties: state.properties.properties,
    widgetAuth: state.currentUser.widgetAuth,
    groupName: state.currentUser.company.group_name
  };
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchPropertiesByCompany
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(FilterDocuments);
