import {
  createPaginationArray,
  configurePreviewPaneHeader,
  mapRentRollToDocument
} from "./processing";
import {
  LEASE_START_DATE_BEFORE_END_DATE,
  LEASE_DATE_LONGER_THAN_TEN,
  CELL_VALUE_REQUIRED,
  IMPLICIT_EFFECTIVE_DATE,
  NO_GUESS_EFFECTIVE_DATE,
  MANUAL_EFFECTIVE_DATE
} from "services/helpers/exceptions";

import {
  checkForBlockingException,
  checkForCellsException
} from "helpers/exceptions";

import { generateTableKey } from "./tableKeys";

export const exceptionSidebarConfig = {
  label: "Items to Review",
  warnings: {
    subHeader: "Warnings",
    countColor: "orange"
  },
  blocking: {
    subHeader: "Errors",
    countColor: "red"
  },
  resolved: { subHeader: "Errors Resolved", countColor: "green" }
};

export const exceptionSidebarLabelDescription = totalPctComplete => {
  return `We were able to map ${totalPctComplete}% of the rent roll based on past rent rolls for this property, but we need you to review a few items.`;
};

export const configureExceptions = (
  exceptions,
  document,
  rentRoll,
  documentTables,
  groupName,
  property
) => {
  if (!document) return {};
  if (!exceptions) return {};
  if (exceptions.length === 0) return {};

  const {
    unfilteredRawDataConfig,
    previewTableConfig
  } = configurePreviewPaneHeader(document);

  if (!unfilteredRawDataConfig) return {};
  if (!previewTableConfig) return {};
  if (unfilteredRawDataConfig.length == 0) return {};
  if (previewTableConfig.length == 0) return {};

  const rentRollToDocument = mapRentRollToDocument(
    rentRoll,
    document,
    previewTableConfig,
    documentTables
  );

  if (!rentRollToDocument) return {};

  const pagination = createPaginationArray(document);
  const getHeaderIndex = (pi, ti) => {
    if (documentTables.length === 0) return null;

    return documentTables.find(
      ({ pageIndex, tableIndex }) => pageIndex === pi && tableIndex === ti
    ).headerIndex;
  };

  if (!pagination) return {};
  if (pagination.length == 0) return {};

  const propertyType = property.property_type;

  const cells = exceptions
    .filter(exception =>
      checkForCellsException(exception, groupName, propertyType)
    )
    .map(exception => {
      const {
        location: { pi, ti, ri, ci }
      } = exception;

      const tableKey = generateTableKey({
        pageIndex: pi,
        tableIndex: ti,
        rowIndex: ri,
        columnIndex: ci,
        headerIndex: getHeaderIndex(pi, ti)
      });
      const row = rentRollToDocument.find(
        ({ documentKey }) => documentKey === tableKey
      );

      return {
        ...exception,
        rentRollKey: row && row.rentRollKey,
        documentKey: row && row.documentKey
      };
    });

  const dataValidations = exceptions
    .filter(
      exception =>
        exception.type === LEASE_START_DATE_BEFORE_END_DATE ||
        exception.type === LEASE_DATE_LONGER_THAN_TEN
    )
    .map(exception => {
      let location;
      if (exception && exception.location && exception.location.start) {
        location = exception.location.start;
      } else if (exception && exception.location) {
        location = exception.location;
      }

      const { pi, ti, ri, ci } = location;
      const tableKey = generateTableKey({
        pageIndex: pi,
        tableIndex: ti,
        rowIndex: ri,
        columnIndex: ci,
        headerIndex: getHeaderIndex(pi, ti)
      });
      const row = rentRollToDocument.find(
        ({ documentKey }) => documentKey === tableKey
      );

      return {
        ...exception,
        rentRollKey: row && row.rentRollKey,
        documentKey: row && row.documentKey
      };
    });

  const blocking = exceptions
    .filter(exception =>
      checkForBlockingException(exception, groupName, propertyType)
    )
    .map(exception => {
      const {
        location: { pi, ti, ri, ci }
      } = exception;

      const tableKey = generateTableKey({
        pageIndex: pi,
        tableIndex: ti,
        rowIndex: ri,
        columnIndex: ci,
        headerIndex: getHeaderIndex(pi, ti)
      });

      const row = rentRollToDocument.find(
        ({ documentKey }) => documentKey === tableKey
      );

      return {
        ...exception,
        rentRollKey: row && row.rentRollKey,
        documentKey: row && row.documentKey
      };
    });

  const resolved = exceptions.filter(
    exception =>
      exception.corrected_at !== null || exception.ignored_at !== null
  );

  const warnings = [...cells, ...dataValidations];

  return { warnings, resolved, blocking };
};

export const getTotalExceptions = exceptionConfig => {
  if (!exceptionConfig) return { total: 0, blocking: 0 };
  const warnings = exceptionConfig.warnings
    ? exceptionConfig.warnings.length
    : 0;
  const blocking = exceptionConfig.blocking
    ? exceptionConfig.blocking.length
    : 0;
  return { blocking, warnings };
};

export const isLeaseStartDateAfterEnd = (exceptions, location) => {
  const { pi, ti, ri, ci } = location;

  const filtered = exceptions.filter(exception => {
    return (
      pi === exception.location.pi &&
      ti === exception.location.ti &&
      ri === exception.location.ri &&
      ci === exception.location.ci
    );
  });

  if (filtered.length > 0) {
    const leaseException = filtered.filter(
      exception => exception.type === LEASE_START_DATE_BEFORE_END_DATE
    );
    if (leaseException[0]) {
      return true;
    } else {
      return false;
    }
  }

  return false;
};

export const isLeaseLongerThanTenYears = (exceptions, location) => {
  const { pi, ti, ri, ci } = location;

  const filtered = exceptions.filter(
    exception =>
      exception &&
      exception.type === LEASE_DATE_LONGER_THAN_TEN &&
      exception.location.start &&
      exception.location.end &&
      ((pi === exception.location.start.pi &&
        ti === exception.location.start.ti &&
        ri === exception.location.start.ri &&
        ci === exception.location.start.ci) ||
        (pi === exception.location.end.pi &&
          ti === exception.location.end.ti &&
          ri === exception.location.end.ri &&
          ci === exception.location.end.ci))
  );

  return filtered.length > 0;
};

export const getLeaseLongerThanTenYearsRange = exception => {
  return {
    start: exception.location.start,
    end: exception.location.end
  };
};

export const isCellValueRequired = (exceptions, location) => {
  const { pi, ti, ri, ci } = location;

  const filtered = exceptions.filter(exception => {
    return (
      pi === exception.location.pi &&
      ti === exception.location.ti &&
      ri === exception.location.ri &&
      ci === exception.location.ci
    );
  });

  if (filtered.length > 0) {
    const leaseException = filtered.filter(
      exception => exception.type === CELL_VALUE_REQUIRED
    );
    if (leaseException[0]) {
      return true;
    } else {
      return false;
    }
  }

  return false;
};

export const getEffectiveDateHighlight = status => {
  switch (status) {
    case IMPLICIT_EFFECTIVE_DATE:
      return "orange";
    case NO_GUESS_EFFECTIVE_DATE:
      return "red";
    case MANUAL_EFFECTIVE_DATE:
      return "";
    default:
      return "";
  }
};
