import { useState } from "react";
import PropTypes from "prop-types";

const PdfEditorPopup = ({
  header,
  message,
  submitButtonText,
  closePopup,
  handleSubmit,
  inputUsed,
  warningPrompt
}) => {
  const [value, setValue] = useState("");
  const [checked, setChecked] = useState(false);

  const handleChecked = () => {
    setChecked(!checked);
  };

  return (
    <div className="SplitPanelV2__PdfEditor__popup">
      <div className="SplitPanelV2__PdfEditor__popup-content">
        <div className="SplitPanelV2__PdfEditor__popup-header">{header}</div>
        <div className="SplitPanelV2__PdfEditor__popup-message">{message}</div>
        {inputUsed && (
          <input
            className="SplitPanelV2__PdfEditor__popup-input"
            type="number"
            min="1"
            autoFocus
            value={value}
            onChange={e => setValue(e.target.value)}
            style={{ marginTop: "1rem" }}
          />
        )}
        {warningPrompt && (
          <label className="SplitPanelV2__PdfEditor__popup-hide-warning-prompt">
            <input type="checkbox" onChange={handleChecked} />
            Do not show this message again
          </label>
        )}
        <div className="SplitPanelV2__PdfEditor__popup-buttons">
          <button
            className="SplitPanelV2__PdfEditor__popup-button"
            onClick={closePopup}
            style={{ color: "gray" }}
          >
            Cancel
          </button>
          {!inputUsed && (
            <button
              className="SplitPanelV2__PdfEditor__popup-button"
              onClick={() => handleSubmit(checked)}
            >
              {submitButtonText}
            </button>
          )}
          {inputUsed && (
            <button
              className="SplitPanelV2__PdfEditor__popup-button"
              onClick={() => handleSubmit(value)}
            >
              {submitButtonText}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

PdfEditorPopup.propTypes = {
  header: PropTypes.string,
  message: PropTypes.string,
  submitButtonText: PropTypes.string,
  closePopup: PropTypes.func,
  handleSubmit: PropTypes.func,
  inputUsed: PropTypes.bool,
  warningPrompt: PropTypes.bool
};

export default PdfEditorPopup;
