import { merge } from "ramda";
import Cookies from "js-cookie";

let jwt = null;

function getJwt() {
  if (!jwt) {
    jwt = Cookies.get("quiq-property-jwt");
  }

  return jwt;
}

export async function apiFetch(input, init = {}, widgetAuth = {}) {
  if (widgetAuth && widgetAuth.authorized) {
    return await apiWidgetFetch(input, init, widgetAuth);
  } else {
    return await apiJwtFetch(input, init);
  }
}

async function apiJwtFetch(input, init = {}) {
  const jwt = getJwt();

  const headers = new Headers({
    Authorization: `Bearer ${jwt}`,
    "Content-Type": "application/json",
    Accept: "application/json",
    pragma: "no-cache",
    "cache-control": "no-cache"
  });
  //if a different content-type was sent we remove the default one
  if (
    init.hasOwnProperty("Content-Type") &&
    init["Content-Type"] === "multipart/form-data"
  )
    headers.delete("Content-Type");

  const _fetchOptions = merge({ headers }, init);
  return fetch(input, _fetchOptions);
}

export async function externalApiFetch(input, init = {}, token) {
  const headers = new Headers({
    Authorization: `Bearer ${decodeURIComponent(token)}`,
    "Content-Type": "application/json",
    Accept: "application/json",
    pragma: "no-cache",
    "cache-control": "no-cache"
  });

  const _fetchOptions = merge({ headers }, init);
  return fetch(input, _fetchOptions);
}

async function apiWidgetFetch(input, init = {}, widgetAuth = {}) {
  const token = widgetAuth.token;
  const headers = new Headers({
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "application/json",
    pragma: "no-cache",
    "cache-control": "no-cache"
  });
  //if a different content-type was sent we remove the default one
  if (
    init.hasOwnProperty("Content-Type") &&
    init["Content-Type"] === "multipart/form-data"
  )
    headers.delete("Content-Type");
  const _fetchOptions = merge({ headers }, init);
  return fetch(input, _fetchOptions);
}
