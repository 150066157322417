import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hooks-helper";
import AddPropertyFormBody from "ui/components/properties/AddPropertyFormBody";
import { getColumns } from "ui/components/properties/propertiesUtil";
import { propertyActions } from "ui/components/properties/helpers/propertiesList";

const AddPropertyForm = ({
  onSubmit,
  propertyData,
  isModalOpen,
  resetSearch,
  childToEditProperty,
  changePage,
  onSubmitAttachDoc,
  action,
  canUpdatePropertyType,
  closePopover,
  properties,
  widgetAuth
}) => {
  const [formData, setForm] = useForm(getColumns());
  const [selCountry, setSelCountry] = useState("United States");
  const [selState, setSelState] = useState(formData.state);
  const [selZip, setSelZip] = useState("");
  const [
    isRecommendedPropertySelected,
    setRecommendedPropertySelected
  ] = useState(false);

  formData.country = selCountry;

  useEffect(() => {
    for (const [key, value] of Object.entries(propertyData)) {
      setForm({
        target: {
          name: key,
          value: value
        }
      });
    }
  }, [propertyData]);

  useEffect(() => {
    if (action !== propertyActions.edit && !isModalOpen) {
      // Reset form Data
      for (const [key, _value] of Object.entries(propertyData)) {
        setForm({
          target: {
            name: key,
            value: ""
          }
        });
      }
      resetSearch();
    }
  }, [isModalOpen]);

  function selectCountry(val) {
    setSelCountry(val);
    formData.country = val;
  }

  function selectRegion(val) {
    setSelState(val);
    formData.state = val;
  }
  function selectZip(val) {
    setSelZip(val);
    formData.postal_code = val;
  }
  const props = {
    formData,
    setForm,
    onSubmit,
    propertyData,
    resetSearch,
    selectCountry,
    selectRegion,
    selectZip,
    isRecommendedPropertySelected,
    setRecommendedPropertySelected,
    selCountry,
    selState,
    selZip,
    childToEditProperty,
    changePage,
    onSubmitAttachDoc,
    action,
    canUpdatePropertyType,
    closePopover,
    properties,
    widgetAuth
  };

  return <AddPropertyFormBody {...props} />;
};

AddPropertyForm.defaultProps = {
  canUpdatePropertyType: true
};

AddPropertyForm.propTypes = {
  onSubmit: PropTypes.func,
  propertyData: PropTypes.object,
  isModalOpen: PropTypes.bool,
  resetSearch: PropTypes.func,
  childToEditProperty: PropTypes.bool,
  changePage: PropTypes.func,
  onSubmitAttachDoc: PropTypes.func,
  action: PropTypes.string,
  canUpdatePropertyType: PropTypes.bool,
  closePopover: PropTypes.func,
  properties: PropTypes.array,
  widgetAuth: PropTypes.object
};

export default AddPropertyForm;
