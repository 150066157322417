import useSWR from "swr";
import fetchData from "ui/components/rentRoll/splitPanelV2/helpers/fetchData";
import useOpexPanelContext from "./useOpexPanelContext";
import { TemplateStatus } from "helpers/opex";

export default function useOpexTemplates(propertyType) {
  const { widgetAuth } = useOpexPanelContext();

  const { data: templates, error: templatesError } = useSWR(
    `/api/opex/templates?propertyType=${propertyType}&status=${TemplateStatus.PUBLISHED}`,
    fetchData(widgetAuth)
  );

  const getTemplateDetail = (templateId, primaryUse) => {
    const searchParams = new URLSearchParams();
    if (primaryUse) {
      searchParams.set("primaryUse", primaryUse);
    }
    return useSWR(
      templateId
        ? `/api/opex/templates/${templateId}?${searchParams.toString()}`
        : null,
      fetchData(widgetAuth)
    );
  };

  return {
    templates,
    templatesError,
    getTemplateDetail
  };
}
