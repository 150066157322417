import { Component } from "react";
import PropTypes from "prop-types";

import CellUpdateValue from "./CellUpdateValue";
import CellUpdatePredef from "./CellUpdatePredef";
import PredefModal from "./PredefModal/PredefModal";
import { isEntityMappingEnabled } from "./helpers/predef";

const popupTypes = {
  ENTITY_MAPPING: "entity_mapping",
  PREDEF: "predef",
  VALUE: "value"
};

class CellEditPopoverBody extends Component {
  render() {
    const {
      documentId,
      cellData,
      cache,
      location,
      closePopover,
      propertyType,
      headerData,
      widgetAuth,
      userCompanyEntitySources
    } = this.props;

    let type;
    let field;

    const { pi, ti, ci } = location;
    if (
      headerData[pi] &&
      headerData[pi][ti] &&
      headerData[pi][ti].headers &&
      headerData[pi][ti].headers[ci]
    ) {
      field = headerData[pi][ti].headers[ci].id;
    }

    if (isEntityMappingEnabled(propertyType, field, userCompanyEntitySources)) {
      type = popupTypes.ENTITY_MAPPING;
    } else if (field === "space_type") {
      type = popupTypes.PREDEF;
    } else {
      type = popupTypes.VALUE;
    }

    switch (type) {
      case popupTypes.ENTITY_MAPPING: {
        return <PredefModal />;
      }
      case popupTypes.PREDEF: {
        return (
          <CellUpdatePredef
            documentId={documentId}
            cellData={cellData}
            closePopover={closePopover}
            cache={cache}
            location={location}
            widgetAuth={widgetAuth}
          />
        );
      }
      case popupTypes.VALUE: {
        return (
          <CellUpdateValue
            documentId={documentId}
            cellData={cellData}
            closePopover={closePopover}
            cache={cache}
            location={location}
            widgetAuth={widgetAuth}
          />
        );
      }
      default:
        return null;
    }
  }
}

CellEditPopoverBody.defaultProps = {
  widgetAuth: {}
};

CellEditPopoverBody.propTypes = {
  documentId: PropTypes.number,
  cellData: PropTypes.object,
  updateValue: PropTypes.string,
  cache: PropTypes.object,
  location: PropTypes.object,
  updateIsOpen: PropTypes.func,
  closePopover: PropTypes.func,
  propertyType: PropTypes.string,
  headerData: PropTypes.array,
  widgetAuth: PropTypes.object,
  userCompanyEntitySources: PropTypes.object
};

export default CellEditPopoverBody;
