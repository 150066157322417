import { useEffect, useState } from "react";
import BaseTable, { Column, AutoResizer } from "react-base-table";
import PropTypes from "prop-types";
import useSplitPanelV2Context from "../../../../hooks/useSplitPanelV2Context";
import useRentRollTableData, {
  VACANCY_KEY
} from "../../../../hooks/useRentRollTableData";
import DataCell from "./Cell/DataCell";
import ActionsCell from "./Cell/ActionsCell";
import VacantCell from "./Cell/VacantCell";
import useRentRoll from "../../../../hooks/useRentRoll";
import useExceptions from "../../../../hooks/useExceptions";
import useDocument from "ui/components/rentRoll/splitPanelV2/hooks/useDocument";
import { createDataObjForNewRow } from "ui/components/rentRoll/splitPanel/helpers/previewPane";
import { MANUAL_ADD } from "services/helpers/rentRoll.js";

// TODO: remove this when implementing
/* eslint-disable no-unused-vars */
export default function DataStep({
  onSelectedCell,
  selected,
  tableRefs,
  columnInfo,
  documentTables,
  exceptions,
  propertyType,
  rentRoll,
  rentRollFields,
  currentHeaderIndex,
  fieldNamesMappingForDropDown,
  handleScrollToCell,
  markupConfig,
  openPopover,
  previewTableConfig,
  selectedPropertyType,
  selectedRentRollCell,
  scrollToCellOnRentRoll,
  setSelectedRentRollCell,
  setPredefModalInfo,
  removeRowFromRentRoll,
  updateSelectedDocumentCell,
  updateSelectedRentRollCell,
  undoRemoveRowFromRentRoll,
  setAddingRow,
  addingRow,
  document,
  currentPageIndex
}) {
  const {
    removeRentRollRowAndExceptions,
    undoRemoveRentRollRowAndExceptions,
    addNewRowToRentRoll,
    mutate: mutateRentRoll
  } = useRentRoll();

  const { mutate: mutateDocument } = useDocument();

  const { widgetAuth, userCompanyEntitySources } = useSplitPanelV2Context();
  const rentRollTableData = useRentRollTableData();
  const { columns, tableData } = rentRollTableData[currentHeaderIndex] || {};

  useEffect(() => {
    // scroll to selected (if not in view)
    if (!selectedRentRollCell) return;
    scrollToCellOnRentRoll(tableRefs, selectedRentRollCell);
  }, [selectedRentRollCell]);

  const [editedCells, setEditedCells] = useState([]);
  const { mutate: mutateExceptions } = useExceptions();
  const { updateDocumentCell, updateVacantFlagCell } = useDocument();
  const [rowFormData, setRowFormData] = useState({});

  useEffect(() => {
    if (
      addingRow &&
      rowFormData.actions !== undefined &&
      tableData.length > 0 &&
      tableData.at(-1).id !== ""
    ) {
      tableData.push(rowFormData);
    }
    if (addingRow && rowFormData.actions === undefined) {
      createNewRow();
      if (tableData.length > 1) {
        handleScrollToCell(
          "to_rent_roll",
          "",
          `${tableData.at(-1).actions.rentRollKey.split("-")[0]}-1`,
          tableRefs
        );
      }
    }
  }, [tableData, addingRow]);

  const isCurrency = dataKey => {
    switch (dataKey) {
      case "monthly_rent_per_sqft":
        return true;
      case "monthly_rent":
        return true;
      case "annual_rent_per_sqft":
        return true;
      case "annual_rent":
        return true;
      case "deposit":
        return true;
      default:
        return false;
    }
  };

  const handleSubmitNewRow = async () => {
    // simplify object to values only
    const quickClean = Object.keys(rowFormData).reduce((obj, key) => {
      if (typeof obj[key] === "object") {
        if (isCurrency(key) || key === "size_sqft") {
          obj[key] = obj[key].text.replaceAll(",", "").replaceAll("$", "");
        } else {
          obj[key] = obj[key].text;
        }
        return obj;
      } else {
        if ((obj[key] && isCurrency(key)) || key === "size_sqft") {
          obj[key] = obj[key].replaceAll(",", "").replaceAll("$", "");
        }
        return obj;
      }
    }, rowFormData);
    delete quickClean.actions;
    delete quickClean.isNewRow;

    // Formats object for API  Request
    const bodyData = createDataObjForNewRow(quickClean);

    // Delete and add needed values
    delete bodyData["[object Object]"];
    // bodyData.deposit = parseFloat(quickClean.deposit) || null;
    bodyData.doc_page_id = currentPageIndex;
    bodyData.doc_table_id = documentTables[currentHeaderIndex].tableIndex || 0;
    bodyData.doc_data_json = null;

    let lastRowId = null;
    if (rentRoll.length > 0) {
      // finds last row in current rentRoll table
      lastRowId = rentRoll.findLast(r => {
        return (
          r.doc_page_id === currentPageIndex &&
          r.doc_table_id === bodyData.doc_table_id
        );
      });
    }

    bodyData.doc_row_ids =
      rentRoll.length < 1 ||
      lastRowId?.doc_row_ids === null ||
      lastRowId.doc_row_ids.length > 1
        ? null
        : [lastRowId.doc_row_ids.at(-1) + 1];

    setRowFormData({});
    setAddingRow(false);

    await addNewRowToRentRoll(document.id, bodyData);
    await Promise.all([mutateExceptions(), mutateRentRoll(), mutateDocument()]);
  };

  const handleCancelNewRow = () => {
    tableData.pop();
    setRowFormData({});
    setAddingRow(false);

    if (tableData.at(-1)) {
      setSelectedRentRollCell(
        `${tableData.at(-1).actions.rentRollKey.split("-")[0]}-2`
      );
    }
  };

  /** Creates a new row object and appends it to the TableData array */
  const createNewRow = () => {
    let row = {};
    let referenceObj = tableData.at(-1);
    //clone Obj for template
    if (referenceObj) {
      row = Object.keys(referenceObj).reduce((obj, fieldName) => {
        if (fieldName === "id") {
          obj.id = "";
          return obj;
        } else if (fieldName === "rowId") {
          obj.rowId = "";
          return obj;
        } else {
          obj[fieldName] = {
            columnIndex: referenceObj[fieldName].columnIndex
              ? referenceObj[fieldName].columnIndex
              : "",
            documentKey: `${referenceObj[fieldName].headerIndex}-${
              referenceObj[fieldName].pageIndex
            }-${referenceObj[fieldName].tableIndex}-${parseInt(
              referenceObj[fieldName].rowIndex
            ) + 1}-${referenceObj[fieldName].columnIndex}`,
            hasError: false,
            hasWarning: false,
            headerIndex: referenceObj[fieldName].headerIndex, // 0
            id: "", // 32148
            isCorrected: false,
            isDeleted: false,
            isMapped: referenceObj[fieldName].isMapped,
            key: fieldName,
            pageIndex: referenceObj[fieldName].pageIndex,
            rentRollKey: referenceObj[fieldName].rentRollKey
              ? `${tableData.length}-${
                  referenceObj[fieldName].rentRollKey.split("-")[1]
                }`
              : "",
            rowIndex: parseInt(referenceObj[fieldName].rowIndex) + 1,
            status: MANUAL_ADD,
            tableIndex: referenceObj[fieldName].tableIndex,
            text: "", // "$5.00"
            isNewRow: true
          };
          return obj;
        }
      }, {});
    } else {
      let cols = document.meta_data_json.headers.find(h => {
        return h.headerIndex === currentHeaderIndex;
      });

      const ACTION_CELL_CI = -1;

      row = cols.headers.reduce((obj, colHeader, idx) => {
        if (idx === 0) {
          obj.actions = {
            columnIndex: ACTION_CELL_CI,
            // hi-pi-ti-ri-ci | column index in documentKey for action cells is -1
            documentKey: `${colHeader.headerIndex}-${colHeader.pageIndex}-${colHeader.tableIndex}-0-${ACTION_CELL_CI}`,
            hasError: false,
            hasWarning: false,
            headerIndex: colHeader.headerIndex,
            id: "",
            isCorrected: false,
            isDeleted: false,
            isMapped: colHeader.isMapped,
            isNewRow: true,
            key: "actions",
            pageIndex: colHeader.pageIndex,
            // rentRollKey for action cells always last
            rentRollKey: `0-${cols.headers.length + 1}`,
            rowIndex: 0,
            status: MANUAL_ADD,
            tableIndex: colHeader.tableIndex,
            text: ""
          };
        }
        obj[colHeader.key] = {
          columnIndex: colHeader.columnIndex,
          documentKey: `${colHeader.headerIndex}-${colHeader.pageIndex}-${colHeader.tableIndex}-0-${colHeader.columnIndex}`, // hi-pi-ti-ri-ci
          hasError: false,
          hasWarning: false,
          headerIndex: colHeader.headerIndex, // 0
          id: "", // 32148
          isCorrected: false,
          isDeleted: false,
          isMapped: colHeader.isMapped,
          isNewRow: true,
          key: colHeader.key,
          pageIndex: colHeader.pageIndex,
          rentRollKey: `0-${colHeader.columnIndex}`, // rentroll table "ri-ci"
          rowIndex: 0,
          status: MANUAL_ADD,
          tableIndex: colHeader.tableIndex,
          text: "" // "$5.00"
        };
        return obj;
      }, {});
      row.id = "";
      row.rowId = "";
    }
    row.isNewRow = true;
    row.data_jsonb = {};
    setRowFormData(row);
    tableData.push(row);
  };

  const columnProps = column => {
    switch (column.dataKey) {
      case "actions":
        return actionsProps(column);
      case VACANCY_KEY:
        return vacantProps(column);
      default:
        return dataProps(column);
    }
  };

  const actionsProps = column => {
    return {
      ...column,
      cellRenderer: ActionsCell,
      widgetAuth,
      undoRemoveRowFromRentRoll,
      removeRowFromRentRoll,
      handleSubmitNewRow,
      handleCancelNewRow,
      documentId: document.id,
      propertyType,
      removeRentRollRowAndExceptions,
      undoRemoveRentRollRowAndExceptions,
      mutateRentRoll,
      mutateExceptions,
      mutateDocument,
      updateDocumentCell,
      userCompanyEntitySources,
      addingRow,
      setAddingRow
      // newRow,
    };
  };

  const vacantProps = column => {
    return {
      ...column,
      columnInfo,
      updateSelectedDocumentCell,
      updateSelectedRentRollCell,
      handleScrollToCell,
      selectedRentRollCell,
      rowFormData,
      setRowFormData,
      documentId: document.id,
      setPredefModalInfo,
      openPopover,
      propertyType,
      widgetAuth,
      updateVacantFlagCell,
      userCompanyEntitySources,
      cellRenderer: renderVacantCell(selected)
    };
  };

  const renderVacantCell = selectedCell => {
    return <VacantCell selectedCell={selectedCell} />;
  };

  const addToEditedCells = rowColString => {
    let newArray = editedCells;
    // return if value already present
    if (newArray.includes(rowColString)) {
      // editedCells.includes -> not a function??
      return;
    } else {
      newArray.push(rowColString);
      setEditedCells(newArray);
    }
  };

  // May or may not be useful
  // const removeFromEditedCells = (rowColString) => {
  //   let newArray = editedCells
  //   // return if value not present
  //   if(!newArray.includes(rowColString)){ return }

  //   newArray = newArray.filter( s => {
  //     return s !== rowColString
  //   })
  //   setEditedCells( newArray )
  // }

  /** takes `'row-col'` and returns `true` if string is in array */
  const checkIfEdited = rowColString => {
    if (editedCells.length > 0) {
      let newArray = editedCells;
      return newArray.includes(rowColString);
    } else {
      return false;
    }
  };

  const renderDataCell = selectedCell => {
    return (
      <DataCell
        onSelectedCell={onSelectedCell}
        setSelectedRentRollCell={setSelectedRentRollCell}
        selected={selectedCell}
        selectedRentRollCell={selectedRentRollCell}
        checkIfEdited={checkIfEdited}
        addToEditedCells={addToEditedCells}
        mutateRentRoll={mutateRentRoll}
        mutateExceptions={mutateExceptions}
        mutateDocument={mutateDocument}
        addingRow={addingRow}
        isCurrency={isCurrency}
      />
    );
  };

  const dataProps = column => {
    return {
      ...column,
      // columnTypeInfo,
      columnInfo,
      updateSelectedDocumentCell,
      updateSelectedRentRollCell,
      handleScrollToCell,
      selectedRentRollCell,
      rowFormData,
      setRowFormData,
      documentId: document.id,
      setPredefModalInfo,
      openPopover,
      propertyType,
      widgetAuth,
      updateDocumentCell,
      userCompanyEntitySources,
      cellRenderer: renderDataCell(selected)
    };
  };

  const setSelectedCells = (docKey, rentRollKey, newRow) => {
    const documentKey = `${newRow ? "" : docKey}`;
    onSelectedCell(documentKey);
    setSelectedRentRollCell(rentRollKey);
    if (docKey === selected || rentRollKey === selectedRentRollCell) {
      // clicked on same cell, do nothing
      return;
    }
    handleScrollToCell("to_rent_roll", documentKey, rentRollKey, tableRefs);
  };

  const misingRow = () => {
    if (
      addingRow &&
      rowFormData.actions &&
      ((tableData.length > 0 && tableData.at(-1).id !== "") ||
        tableData.length === 0)
    ) {
      tableData.push(rowFormData);
    }
    return tableData;
  };

  return (
    <AutoResizer>
      {({ height, width }) => (
        <BaseTable
          height={height}
          width={width}
          data={misingRow()}
          ref={tableRefs}
          selected={selected}
          classPrefix="BaseTable"
          rowProps={({ columns, rowData, rowIndex }) => {
            return {
              rowData,
              columns,
              rowIndex,
              selected: selected,
              id: rowIndex
            };
          }}
          cellProps={({ columns, column, columnIndex, rowData, rowIndex }) => {
            const newRow =
              rowData?.isNewRow || typeof rowData[column.key] === "string";
            // If row is New and idx = 0 then its a manual row and the 1st on a empty table
            if (
              newRow &&
              rowIndex === 0 &&
              rowData[column.key] &&
              column.key !== "actions"
            ) {
              rowData[column.key].rentRollKey = `0-${columnIndex}`;
            }

            const rentRollKey =
              rowData[column.key]?.rentRollKey || `${rowIndex}-${columnIndex}`;
            const documentKey = rowData[column.key]?.documentKey || "0-0-0-0-0";
            rowData.rowId ? "" : (rowData.rowId = `${rowIndex}`);

            return {
              rowData: rowData,
              selected: selected,
              onSelectedCell: onSelectedCell,
              documentKey,
              rentRollKey,
              onClick: () => {
                setSelectedCells(documentKey, rentRollKey, newRow);
              },
              onFocus: () => {
                setSelectedCells(documentKey, rentRollKey, newRow);
              }
            };
          }}
          fixed
        >
          {columns.map(column => (
            <Column
              key={column.key}
              dataKey={column.dataKey}
              width={column.computeWidth(width)}
              title={column.text}
              align={column.align}
              frozen={column.frozen}
              dataGetter={({ rowData, column }) => {
                rowData[column.key] ? rowData[column.key].text : "";
              }}
              headerClassName={({
                columns,
                column,
                columnIndex,
                headerIndex
              }) => ""}
              className={({
                columns,
                column,
                columnIndex,
                headerIndex,
                rowData
              }) => {
                if (
                  selectedRentRollCell &&
                  rowData[column.key] &&
                  selectedRentRollCell === rowData[column.key].rentRollKey
                ) {
                  return "RentRollCell__selected";
                } else {
                  if (rowData[column.key] === undefined) {
                    rowData[column.key] = {};
                  }
                  return "RentRollCell__normal";
                }
              }}
              rowClassName={({ columns, column, columnIndex, headerIndex }) =>
                columnIndex % 2 === 0 ? "WhiteRow" : "GrayRow"
              }
              {...columnProps(column)}
            />
          ))}
        </BaseTable>
      )}
    </AutoResizer>
  );
}

DataStep.propTypes = {
  onSelectedCell: PropTypes.func.isRequired,
  selectedRentRollCell: PropTypes.string,
  tableRefs: PropTypes.object,
  propertyType: PropTypes.string,
  selected: PropTypes.string,
  rentRoll: PropTypes.array,
  setAddingRow: PropTypes.func,
  addingRow: PropTypes.bool,
  document: PropTypes.any,
  currentPageIndex: PropTypes.any
};
