export const DOCUMENT_ADD = "DOCUMENT_ADD";
export const MANUAL_ADD = "MANUAL_ADD";
export const DOCUMENT_DEL = "DOCUMENT_DEL";

// export function getDocData(rentRoll) {
//   if (rentRoll.length === 0) return {};
//   const rentRollNoManual = rentRoll.filter(row => row.status !== "MANUAL_ADD");
//   return rentRollNoManual.reduce((rowAcc, row) => {
//     const rowData = row.doc_data_json.row.reduce((colAcc, col) => {
//       if (col.isMapped && col.docPosition) {
//         colAcc[
//           col.docPosition.page +
//             "-" +
//             col.docPosition.table +
//             "-" +
//             col.docPosition.row +
//             "-" +
//             col.docPosition.col
//         ] = { id: col.id, value: col.value };
//       }
//       return colAcc;
//     }, {});
//     return { ...rowAcc, ...rowData };
//   }, {});
// }
