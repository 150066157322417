// this file is intentionally written as a class component
// when using functional component and hooks, BaseTable was throwing
// "Rendered fewer hooks than expected" errors

import _ from "lodash";
import { Component } from "react";
import PropTypes from "prop-types";
import {
  BsFillPencilFill,
  BsFillExclamationTriangleFill
} from "react-icons/bs";
import dayjs from "ui/components/helpers/dayjs";
import OpexButton from "../OpexButton/OpexButton";
import OpexSelect from "../OpexSelect/OpexSelect";
import OpexDropdown from "../OpexDropdown/OpexDropdown";
import ToggleSwitch from "ui/components/apps/ToggleSwitch";
import OpexData from "../../modelOpexData";

import {
  PERIOD_STATUS_IGNORED,
  PERIOD_STATUS_SET,
  isCreditLensByGroupName
} from "ui/components/opex/shared";
import {
  methodPrepareCPM,
  statementTypeCPM,
  methodPrepareCL,
  statementTypeCL
} from "helpers/opex";
import { DatePicker } from "antd";
import PeriodStatusCell from "./PeriodStatusCell";
import {
  PERIOD_DATE_FORMAT,
  MDYYYY_DATE_FORMAT,
  MDDYYYY_DATE_FORMAT,
  MMDYYYY_DATE_FORMAT,
  MDYY_DATE_FORMAT,
  MDDYY_DATE_FORMAT,
  MMDDYY_DATE_FORMAT,
  NO_DATES_TEXT
} from "ui/components/helpers/dayjs";

function getMethodPreparedValues(groupName) {
  if (isCreditLensByGroupName(groupName)) {
    return methodPrepareCL;
  }
  return methodPrepareCPM;
}

function getStatementTypeValues(groupName) {
  if (isCreditLensByGroupName(groupName)) {
    return statementTypeCL;
  }
  return statementTypeCPM;
}

function statusToToggleBoolean(periodStatus) {
  return periodStatus === PERIOD_STATUS_IGNORED ? true : false;
}

function toggleBooleanToStatus(value) {
  return value === true ? PERIOD_STATUS_IGNORED : PERIOD_STATUS_SET;
}
function StartDateEndDate({ startDate, endDate }) {
  let text = NO_DATES_TEXT;

  if (!_.isEmpty(startDate) && !_.isEmpty(endDate)) {
    const formattedStartDate = dayjs.utc(startDate).format(PERIOD_DATE_FORMAT);
    const formattedEndDate = dayjs.utc(endDate).format(PERIOD_DATE_FORMAT);
    text = `${formattedStartDate} - ${formattedEndDate}`;
  }
  return <span>{text}</span>;
}
StartDateEndDate.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string
};

// when the popup displays it will attempt to autofocus the first input.
// this will prevent that
function PreventAutofocusInput() {
  return (
    <input type="text" autoFocus="autofocus" style={{ display: "none" }} />
  );
}
class PeriodHeader extends Component {
  constructor(props) {
    super(props);

    const {
      startDate,
      endDate,
      methodPrepared,
      statementType,
      periodStatus,
      readOnlyCLWidget
    } = props.column.opexData;

    this.state = {
      dayjsStartDate: _.isEmpty(startDate) ? null : dayjs.utc(startDate),
      dayjsEndDate: _.isEmpty(endDate) ? null : dayjs.utc(endDate),
      // default these two values
      methodPrepared: methodPrepared ? methodPrepared : undefined,
      statementType: statementType ? statementType : undefined,
      periodStatus:
        periodStatus === PERIOD_STATUS_IGNORED
          ? PERIOD_STATUS_IGNORED
          : PERIOD_STATUS_SET
    };

    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.handleChangeDates = this.handleChangeDates.bind(this);
    this.getPopupContentElement = this.getPopupContentElement.bind(this);
  }

  componentDidMount() {
    const { groupName } = this.props.column;

    if (this.state.dayjsStartDate && this.state.dayjsEndDate) {
      const monthDiffernce = this.monthDifference(
        this.state.dayjsStartDate,
        this.state.dayjsEndDate
      );
      const firstMonth = this.state.dayjsStartDate.format("MMMM");
      let calculatedStatementType = this.getStatementType(
        monthDiffernce,
        firstMonth,
        groupName
      );
      if (calculatedStatementType) {
        this.setState({
          statementType: calculatedStatementType
        });
      }
    }
  }

  handleOnSubmit() {
    this.props.column.onSubmit(this.state, this.props.column);
  }

  handleChangeDates(dates, groupName) {
    if (dates) {
      const startDate = dayjs(dates[0]).startOf("day");
      const endDate = dayjs(dates[1]).startOf("day");

      const monthDifference = this.monthDifference(startDate, endDate);
      const firstMonth = startDate.format("MMMM");
      const calculatedStatementType = this.getStatementType(
        monthDifference,
        firstMonth,
        groupName
      );
      this.setState({
        dayjsStartDate: startDate,
        dayjsEndDate: endDate,
        statementType: calculatedStatementType
      });
    }
  }

  monthDifference(startDate, endDate) {
    if (!startDate || !endDate) {
      return null;
    }
    return endDate.add(1, "month").diff(startDate, "month");
  }

  getStatementType(difference, firstMonth, groupName) {
    switch (difference) {
      case 1:
        return statementTypeCL.MONTHLY.value;
      case 3:
        return statementTypeCL.QUARTERLY.value;
      case 12:
        if (firstMonth === "January") {
          return isCreditLensByGroupName(groupName)
            ? statementTypeCL.ANNUAL.value
            : statementTypeCPM.ANNUAL.value;
        }
        return isCreditLensByGroupName(groupName)
          ? statementTypeCL.ROLLING_STMT.value
          : statementTypeCPM.ANNUAL.value;
      default:
        return undefined;
    }
  }

  getPopupContentElement(close) {
    const { groupName } = this.props.column;
    const { RangePicker } = DatePicker;

    return (
      <>
        <PreventAutofocusInput />
        <div className="popup-label">
          Date<span className="required-text">*</span>
        </div>
        <RangePicker
          defaultValue={[this.state.dayjsStartDate, this.state.dayjsEndDate]}
          style={{ margin: "15px 0px" }}
          format={[
            PERIOD_DATE_FORMAT,
            MDYYYY_DATE_FORMAT,
            MDDYYYY_DATE_FORMAT,
            MMDYYYY_DATE_FORMAT,
            MDYY_DATE_FORMAT,
            MDDYY_DATE_FORMAT,
            MMDDYY_DATE_FORMAT
          ]}
          onCalendarChange={dates => this.handleChangeDates(dates, groupName)}
        />
        <div className="popup-label">
          Statement Type<span className="required-text">*</span>
        </div>
        <div className="popup-field">
          <OpexSelect
            undefinedOption={true}
            value={this.state.methodPrepared}
            options={_.map(getMethodPreparedValues(groupName), value => value)}
            onChange={payload => {
              this.setState({ methodPrepared: payload });
            }}
          />
        </div>
        <div className="popup-label">
          Period Type<span className="required-text">*</span>
        </div>
        <div className="popup-field">
          <OpexSelect
            undefinedOption={true}
            value={this.state.statementType}
            options={_.map(getStatementTypeValues(groupName), value => value)}
            onChange={payload => {
              this.setState({ statementType: payload });
            }}
          />
        </div>
        <div className="popup-label">Ignored</div>
        <div className="popup-field">
          <ToggleSwitch
            isOn={statusToToggleBoolean(this.state.periodStatus)}
            handleToggle={() => {
              const nextBoolean = !statusToToggleBoolean(
                this.state.periodStatus
              );
              const payload = toggleBooleanToStatus(nextBoolean);
              this.setState({ periodStatus: payload });
            }}
          />
        </div>

        <div className="button-bar">
          <OpexButton
            variant="secondary"
            onClick={() => {
              close();
            }}
          >
            Cancel
          </OpexButton>
          <OpexButton
            variant="primary"
            onClick={() => {
              this.handleOnSubmit();
              close();
            }}
          >
            Save
          </OpexButton>
        </div>
      </>
    );
  }

  render() {
    const {
      startDate,
      endDate,
      periodStatus,
      methodPrepared,
      statementType,
      readOnlyCLWidget
    } = this.props.column.opexData;
    const { groupName } = this.props.column;
    const disabled = periodStatus === PERIOD_STATUS_IGNORED;

    // create OpexData instance with 1 period to take advantage of the
    // class methods
    const modelOpexData = new OpexData({
      document: null,
      opex: {
        periods: [this.props.column.opexData]
      }
    });

    let button;
    if (modelOpexData.hasAllActivePeriodsSet()) {
      button = (
        <div
          className={`editButton ${readOnlyCLWidget ? "disabledButton" : ""}`}
        >
          <BsFillPencilFill />
        </div>
      );
    } else {
      button = (
        <div className="warningButton">
          <BsFillExclamationTriangleFill />
        </div>
      );
    }

    return (
      <div className="OpexBaseTable--PeriodHeader">
        <div
          className={`OpexBaseTable--PeriodHeader--date ${
            disabled ? "cellDisabled" : ""
          }`}
        >
          <StartDateEndDate startDate={startDate} endDate={endDate} />
        </div>
        <div className="OpexBaseTable--PeriodHeader--status">
          <PeriodStatusCell
            cellData={{
              disabled,
              groupName,
              statementType,
              methodPrepared
            }}
          />
        </div>

        <OpexDropdown
          disabled={readOnlyCLWidget || this.props.column.editDisabled}
          position="bottom right"
          triggerComponent={button}
          popupContent={this.getPopupContentElement}
        />
      </div>
    );
  }
}

export default PeriodHeader;

PeriodHeader.propTypes = {
  column: PropTypes.shape({
    editDisabled: PropTypes.bool,
    groupName: PropTypes.string,
    onSubmit: PropTypes.func,
    opexData: PropTypes.shape({
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      methodPrepared: PropTypes.string,
      statementType: PropTypes.string,
      periodStatus: PropTypes.string,
      readOnlyCLWidget: PropTypes.bool
    }).isRequired
  })
};
