import PropTypes from "prop-types";
import cx from "classnames";
import FORM_STATES from "../../../../helpers/formStates";
import useSplitPanelV2Context from "ui/components/rentRoll/splitPanelV2/hooks/useSplitPanelV2Context";
import { useState } from "react";

export default function Tabs({ formState }) {
  const [throttleTimer, setThrottleTimer] = useState(false);
  const [resizing, setResizing] = useState(false);
  const tabs = [
    // { key: FORM_STATES.PROPERTY, text: "Property & Effective Date" },
    { key: FORM_STATES.HEADERS, text: "Map Header" },
    { key: FORM_STATES.DATA, text: "Review Data" }
  ].map(tab => ({
    ...tab,
    active: tab.key === formState,
    // TODO: Need to understand completed state
    completed: false
  }));

  const {
    ui: { setRentRollHeight }
  } = useSplitPanelV2Context();

  const adjustHeight = e => {
    if (throttleTimer) return;

    setThrottleTimer(true);
    const clientY = e.clientY || e.touches[0].clientY;
    const offsetY = clientY - e.currentTarget.getBoundingClientRect().top;
    const rentRollHeight =
      100 - Math.floor((offsetY / e.currentTarget.clientHeight) * 100);
    setTimeout(() => {
      rentRollHeight > 30 &&
        rentRollHeight < 80 &&
        setRentRollHeight(rentRollHeight);
      setThrottleTimer(false);
    }, 10);
  };

  const startResize = () => {
    setResizing(true);
    const container = document.querySelector(".SplitPanelV2__contents");
    container.addEventListener("mousemove", adjustHeight);
    container.addEventListener("touchmove", adjustHeight);
    container.addEventListener("mouseup", e => {
      e.currentTarget.removeEventListener("mousemove", adjustHeight);
      e.currentTarget.removeEventListener("touchmove", adjustHeight);
      setResizing(false);
    });
  };

  return (
    <div
      className={cx("SplitPanelV2__RentRoll__Tabs", { resizing: resizing })}
      onMouseDown={startResize}
      onTouchStart={startResize}
    >
      <div className="SplitPanelV2__RentRoll__Tabs-tab-group">
        {tabs.map(({ key, text, active, completed }) => (
          <div
            key={key}
            className={cx("SplitPanelV2__RentRoll__Tabs-tab-group-tab", {
              ["SplitPanelV2__RentRoll__Tabs-tab-group-tab--active"]: active
            })}
          >
            {completed ? (
              <img
                src={require("ui/images/green-tick.svg")}
                className="SplitPanelV2__RentRoll__Tabs-tab-group-tab-icon--completed"
              />
            ) : (
              <div className="SplitPanelV2__RentRoll__Tabs-tab-group-tab-icon--not-completed" />
            )}
            {text}
          </div>
        ))}
      </div>
    </div>
  );
}

Tabs.propTypes = {
  formState: PropTypes.string.isRequired
};
