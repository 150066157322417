import PropTypes from "prop-types";
import cx from "classnames";

import { Cell } from "react-sticky-table";

const ActionCellForAddRow = ({
  submitRowForm,
  setIsAddingRow,
  setRowFormData
}) => (
  <>
    <Cell
      className={cx(
        "PreviewPaneTable__cell",
        "PreviewPaneTable__actionCellNewRow"
      )}
      style={{
        display: "flex",
        justifyContent: "space-evenly"
      }}
    >
      <img
        className="PreviewPaneTable__actionIcon"
        src={require("ui/images/icon-x.png")}
        onClick={() => {
          setIsAddingRow(false);
          setRowFormData({});
        }}
        style={{ cursor: "pointer" }}
      />
      <img
        className="PreviewPaneTable__actionIcon"
        src={require("ui/images/icon-check.svg")}
        style={{ cursor: "pointer" }}
        onClick={() => {
          submitRowForm();
          setIsAddingRow(false);
          setRowFormData({});
        }}
      />
    </Cell>
  </>
);

ActionCellForAddRow.propTypes = {
  submitRowForm: PropTypes.func,
  setIsAddingRow: PropTypes.func,
  setRowFormData: PropTypes.func
};

export default ActionCellForAddRow;
