import { Component } from "react";
import EntityView from "./EntityView";
import EntitySearch from "./EntitySearch";

class PredefModal extends Component {
  state = {
    view: "entity-view",
    selectedValue: "",
    selectedField: "",
    selectedLocation: {},
    selectedTab: 0
  };

  changeView = (view, selectedTab) => this.setState({ view, selectedTab });

  setFieldParams = (selectedValue, selectedField, selectedLocation) =>
    this.setState({
      selectedValue,
      selectedField,
      selectedLocation
    });

  render() {
    const {
      view,
      selectedField,
      selectedValue,
      selectedLocation,
      selectedTab
    } = this.state;
    switch (view) {
      case "entity-view":
        return (
          <EntityView
            changeView={this.changeView}
            setFieldParams={this.setFieldParams}
            selectedTab={selectedTab}
          />
        );
      case "search-entity-view":
        return (
          <EntitySearch
            changeView={this.changeView}
            selectedValue={selectedValue}
            selectedField={selectedField}
            selectedLocation={selectedLocation}
            setFieldParams={this.setFieldParams}
            selectedTab={selectedTab}
          />
        );
      default:
        return (
          <EntityView
            changeView={this.changeView}
            setFieldParams={this.setFieldParams}
            selectedTab={selectedTab}
          />
        );
    }
  }
}

export default PredefModal;
