import { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { clone } from "ramda";
import { isBlank } from "helpers/presence";
import { bindActionCreators } from "redux";

import CheckBoxTableHeader from "ui/components/shared/CheckBoxTableHeader";
import CheckBoxTableRows from "ui/components/shared/CheckBoxTableRows";
import { updateRentRollFieldsSuccessful } from "ui/store/actions/rentRoll";

class RentRollFieldsTable extends Component {
  constructor(props) {
    super(props);
  }

  handleToggleHeader = propertyType => {
    const { fieldsByPropertyType, updateRentRollFieldsSuccessful } = this.props;
    const clonedFieldsByPropertyType = clone(fieldsByPropertyType);
    const propertyTypeFields = clonedFieldsByPropertyType[propertyType].fields;
    let status;
    if (this.isHeaderChecked(propertyType)) {
      status = "hide";
    } else {
      status = "show";
    }
    propertyTypeFields.forEach(field => {
      field.status = status;
    });
    updateRentRollFieldsSuccessful(clonedFieldsByPropertyType);
  };

  handleToggleIndividualCheckBox = (field, propertyType) => {
    const { fieldsByPropertyType, updateRentRollFieldsSuccessful } = this.props;
    const clonedFieldsByPropertyType = clone(fieldsByPropertyType);
    const propertyTypeFields = clonedFieldsByPropertyType[propertyType].fields;
    propertyTypeFields.forEach(propertyTypeField => {
      if (propertyTypeField.displayTerm === field) {
        if (propertyTypeField.status === "hide") {
          propertyTypeField.status = "show";
        } else {
          propertyTypeField.status = "hide";
        }
      }
    });
    updateRentRollFieldsSuccessful(clonedFieldsByPropertyType);
  };

  isChecked = (field, propertyType) => {
    const { fieldsByPropertyType } = this.props;
    const propertyTypeFields = fieldsByPropertyType[propertyType].fields;
    let checked = false;
    propertyTypeFields.forEach(propertyTypeField => {
      if (
        propertyTypeField.displayTerm === field &&
        propertyTypeField.status === "show"
      ) {
        checked = true;
      }
    });
    return checked;
  };

  isHeaderChecked = propertyType => {
    const { fieldsByPropertyType } = this.props;
    const propertyTypeFields = fieldsByPropertyType[propertyType].fields;
    return propertyTypeFields.some(
      propertyTypeField => propertyTypeField.status === "show"
    );
  };

  render() {
    const { fieldsByPropertyType } = this.props;
    const propertyTypes = Object.keys(fieldsByPropertyType);
    const fields = isBlank(propertyTypes)
      ? []
      : fieldsByPropertyType[propertyTypes[0]].fields;
    return (
      <div className="RentRollFieldsTable">
        <div className="RentRollFieldsTable__property-labels">
          <div className="RentRollFieldsTable__name-label" />
          {propertyTypes.map((propertyType, i) => {
            return (
              <div key={i} className="RentRollFieldsTable__property-label">
                {propertyType}
              </div>
            );
          })}
        </div>
        <CheckBoxTableHeader
          headerNames={propertyTypes}
          title={"Property Types"}
          handleToggle={this.handleToggleHeader}
          colordeterminators={propertyTypes}
          determineChecked={this.isHeaderChecked}
        />
        <CheckBoxTableRows
          rowNames={fields.map(field => field.displayTerm)}
          colNames={propertyTypes}
          handleToggle={this.handleToggleIndividualCheckBox}
          determineChecked={this.isChecked}
        />
      </div>
    );
  }
}

RentRollFieldsTable.propTypes = {
  users: PropTypes.array,
  updateRentRollFieldsSuccessful: PropTypes.func
};

function mapStateToProps({ rentRoll }) {
  return {
    fieldsByPropertyType: rentRoll.fieldsByPropertyType
  };
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateRentRollFieldsSuccessful
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RentRollFieldsTable);
