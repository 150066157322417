import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import NavLink from "ui/components/shared/NavLink";
import cx from "classnames";
import { DatePicker } from "antd";
import dayjs from "dayjs";

import {
  fetchRentRollByDocId,
  fetchRentRollDocument,
  clearCache,
  updatePageIndex,
  fetchExceptionsByDocId,
  getRentRollFields,
  updateDocument,
  updatePdfMarkdownLoading,
  refreshSplitPanel,
  clearSplitPanel,
  updateExceptionSidebar,
  assignExceptionToEffectiveDate
} from "ui/store/actions/rentRoll";
import { fetchProperty } from "ui/store/actions/properties";
import {
  AddCellToDocumentNav,
  AddCellToRentRollNav,
  BeginPageNav,
  EndDocumentNav,
  EndPageNav
} from "../../../store/actions/splitScreen";

import {
  getRentRollKeyFromDocument,
  getTotalPercentageComplete,
  getCurrentEffectiveDate
} from "./helpers/processing";

import { fetchEffectiveDateStatus } from "ui/components/helpers/effectiveDate";

import SplitPanelHeader from "./SplitPanelHeader";
import AuthenticatedRoute from "ui/components/routing/AuthenticatedRoute";
import SplitPanelSubHeader from "./SplitPanelSubHeader";
import PreviewPane from "./PreviewPane";
import ExceptionsSidebar from "./exceptions/ExceptionsSidebar";
import IconButton from "ui/components/shared/IconButton";
import DocumentView from "ui/components/rentRoll/splitPanel/DocumentView";
import BottomStatusBar from "ui/components/rentRoll/splitPanel/BottomStatusBar";
import ThreeBounceSpinner from "ui/components/spinners/ThreeBounceSpinner";

import {
  configureExceptions,
  getEffectiveDateHighlight,
  getTotalExceptions
} from "ui/components/rentRoll/splitPanel/helpers/exceptions";
import Button from "ui/components/shared/Button";
import { scrollToCellOnDocument } from "./helpers/navigation";
import { PERIOD_DATE_FORMAT } from "ui/components/helpers/dayjs";

class SplitPanelView extends Component {
  state = {
    tableRefs: null,
    deletedRows: [],
    selectedDocumentCell: "",
    selectedRentRowCell: "",
    editEffectivDate: false,
    highlightEffectiveDate: ""
  };

  async componentDidMount() {
    await this.refreshPreview();
  }

  componentDidUpdate(prevProps) {
    const {
      match: {
        params: { portfolioId, propertyId, documentId }
      },
      widgetAuth
    } = this.props;

    if (
      prevProps.rentRollDocument !== this.props.rentRollDocument &&
      this.props.rentRollDocument.id
    ) {
      (async () => {
        const exception = await fetchEffectiveDateStatus(
          this.props.rentRollDocument.id,
          widgetAuth
        );
        const status = exception ? exception.status : "";
        const highlight = getEffectiveDateHighlight(status);
        this.setState({ highlightEffectiveDate: highlight });
      })();
    }

    if (
      prevProps.match.params.portfolioId !== portfolioId ||
      prevProps.match.params.propertyId !== propertyId ||
      prevProps.match.params.documentId !== documentId
    ) {
      this.refreshPreview();
    }
  }

  componentWillUnmount() {
    const {
      clearCache,
      clearSplitPanel
      // rentRollDocument,
      // rentRollDocuments
    } = this.props;
    clearCache();
    clearSplitPanel();
    this.setState({ selectedDocumentCell: "", selectedRentRowCell: "" });
    // Todo: Investigate if reactLocalStorage is needed in split screen to save docId and tabs
    // const doc = rentRollDocuments
    //   .filter(doc => doc.status === "COMPLETED")
    //   .find(doc => doc.id === rentRollDocument.id);
    // if (doc) {
    //   reactLocalStorage.set("documentId", rentRollDocument.id);
    // }
  }

  updateSelectedDocumentCell = key => {
    this.setState({ selectedDocumentCell: key });
  };

  updateSelectedRentRollCell = key => {
    this.setState({ selectedRentRowCell: key });
  };

  updateDeletedRows = rentRoll => {
    const deleted = [];
    rentRoll.forEach(row => {
      if (row.status === "DOCUMENT_DEL") {
        deleted.push({
          pi: row.doc_page_id,
          ri: row.doc_row_ids[0]
        });
      }
    });
    this.setState({ deletedRows: deleted });
  };

  handleTableRef = ref => {
    this.setState({
      tableRefs: ref
    });
  };

  setEditEffectiveDate = flag => {
    this.setState({ editEffectivDate: flag });
    this.setState({
      newEffectiveDate: this.props.rentRollDocument.effective_date
    });
  };

  updateEffectiveDate = async (id, effectiveDate) => {
    const {
      updateDocument,
      assignExceptionToEffectiveDate,
      widgetAuth
    } = this.props;
    if (effectiveDate) {
      if (typeof effectiveDate === "string") {
        effectiveDate = dayjs(effectiveDate).startOf("day");
      }
      await updateDocument(
        id,
        { effective_date: effectiveDate.toISOString() },
        widgetAuth
      );
      await assignExceptionToEffectiveDate(
        id,
        "MANUAL_EFFECTIVE_DATE",
        widgetAuth
      );
      await (async () => {
        const exception = await fetchEffectiveDateStatus(
          this.props.rentRollDocument.id,
          widgetAuth
        );
        const status = exception ? exception.status : "";
        const highlight = getEffectiveDateHighlight(status);
        this.setState({ highlightEffectiveDate: highlight });
      })();
      this.setState({ editEffectivDate: false });
      this.refreshPreview();
    }
  };

  refreshPreview = async () => {
    const {
      getRentRollFields,
      refreshSplitPanel,
      updateExceptionSidebar,
      match: {
        params: { propertyId, documentId }
      },
      widgetAuth
    } = this.props;
    updateExceptionSidebar(false);
    await fetchProperty(propertyId, widgetAuth);
    await getRentRollFields(widgetAuth);
    await refreshSplitPanel(propertyId, documentId, widgetAuth);
  };

  handleScrollToCell = (type, documentKey) => {
    const {
      updatePageIndex,
      pageIndex,
      AddCellToDocumentNav,
      AddCellToRentRollNav,
      BeginPageNav,
      rentRollDocument,
      rentRoll,
      EndPageNav
    } = this.props;
    switch (type) {
      case "exception": {
        const documentKeyPage = parseInt(documentKey.split("-")[0]);
        const rentRollKey = getRentRollKeyFromDocument(
          rentRoll,
          rentRollDocument,
          documentKey
        );
        AddCellToDocumentNav(documentKey);
        AddCellToRentRollNav(rentRollKey);

        if (pageIndex !== documentKeyPage) {
          BeginPageNav();
          updatePageIndex(documentKeyPage);
          EndPageNav();
          // updatePdfMarkdownLoading();
        }
        this.updateSelectedDocumentCell(documentKey);
        this.updateSelectedRentRollCell(rentRollKey);
        break;
      }
      case "to_document": {
        const documentKeyPage = parseInt(documentKey.split("-")[0]);

        AddCellToDocumentNav(documentKey);
        const rentRollKey = getRentRollKeyFromDocument(
          rentRoll,
          rentRollDocument,
          documentKey
        );

        if (pageIndex !== documentKeyPage) {
          BeginPageNav();
          updatePageIndex(documentKeyPage);
          EndPageNav();
        } else {
          scrollToCellOnDocument(documentKey);
        }
        this.updateSelectedDocumentCell(documentKey);
        this.updateSelectedRentRollCell(rentRollKey);
        break;
      }
      case "to_rent_roll": {
        const rentRollKey = getRentRollKeyFromDocument(
          rentRoll,
          rentRollDocument,
          documentKey
        );
        AddCellToRentRollNav(rentRollKey);
        this.updateSelectedDocumentCell(documentKey);
        this.updateSelectedRentRollCell(rentRollKey);
        break;
      }
      default: {
        // eslint-disable-next-line no-console
        console.error("unrecognized scroll to type");
        break;
      }
    }
  };

  getNav = (portfolioId, propertyId) => {
    return (
      <div>
        <NavLink
          className="HeaderWithSidebar__back-button-nav"
          to={{
            pathname: `/rent-roll/property/${propertyId}${
              portfolioId ? `/portfolio/${portfolioId}` : ""
            }`
          }}
        >
          <img
            className="HeaderWithSidebar__back-button-img"
            src={require("ui/components/shared/images/arrows/LeftArrow.png")}
          />
        </NavLink>
      </div>
    );
  };

  getSplitPannelHeader = (portfolioId, propertyId) => {
    const { rentRollDocument, widgetAuth } = this.props;
    //const date = moment(rentRollDocument.created_at).format("MMM YYYY");
    const titleContent =
      rentRollDocument && rentRollDocument.original_filename
        ? `${rentRollDocument.original_filename}`
        : "loading...";
    const isWidgetAuthorized = widgetAuth && widgetAuth.authorized;
    return (
      <div className="HeaderWithSidebar__header-components">
        {!isWidgetAuthorized ? this.getNav(portfolioId, propertyId) : ""}
        <div className="HeaderWithSidebar__title-name">{titleContent}</div>
      </div>
    );
  };

  getEffectiveDateEditButton = () => {
    const { rentRollDocument } = this.props;
    const { highlightEffectiveDate } = this.state;
    return (
      <div style={{ marginRight: "45px" }}>
        {this.state.editEffectivDate ? (
          <div
            style={{
              border: highlightEffectiveDate
                ? `1px solid ${highlightEffectiveDate}`
                : "none",
              padding: "5px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <DatePicker
              onChange={date => {
                this.setState({ newEffectiveDate: date });
              }}
              defaultValue={dayjs(
                this.state.newEffectiveDate
                  ? this.state.newEffectiveDate
                  : rentRollDocument.effective_date
                  ? rentRollDocument.effective_date
                  : dayjs().startOf("day")
              )}
              format={PERIOD_DATE_FORMAT}
              id="effective_date_picker"
              size={"small"}
            />
            <div
              style={{
                marginLeft: "16px",
                marginRight: "10px"
              }}
            >
              <Button
                onClick={() =>
                  this.updateEffectiveDate(
                    rentRollDocument.id,
                    this.state.newEffectiveDate
                  )
                }
              >
                save
              </Button>
            </div>
            <IconButton
              onClick={() => this.setEditEffectiveDate(false)}
              src={require("../../../images/x-icon.svg")}
            />
          </div>
        ) : (
          <div
            style={{
              border: highlightEffectiveDate
                ? `1px solid ${highlightEffectiveDate}`
                : "none",
              padding: "5px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <h3 style={{ fontWeight: "500", margin: "0px 7px 0 0" }}>
              Effective Date
            </h3>
            {`${getCurrentEffectiveDate(
              this.state.newEffectiveDate,
              rentRollDocument.effective_date
            )}`}

            <IconButton
              className="IconButton__smallButton"
              onClick={() => this.setEditEffectiveDate(true)}
              src={require("../../../images/edit-icon.svg")}
              style={{ marginLeft: "4px", height: "12px" }}
            />
          </div>
        )}
      </div>
    );
  };

  render() {
    const {
      exceptionSidebarIsActive,
      rentRollDocument,
      exceptions,
      rentRoll,
      match: {
        params: { portfolioId, propertyId }
      },
      excelMarkdownStatus,
      updateExceptionSidebar,
      selectedProperty,
      widgetAuth
    } = this.props;
    const exceptionsConfig = configureExceptions(
      exceptions,
      rentRollDocument,
      rentRoll,
      "",
      selectedProperty
    );
    const totalExceptions = getTotalExceptions(exceptionsConfig);
    const totalPctComplete = getTotalPercentageComplete(
      rentRollDocument,
      rentRoll
    );
    const isWidgetAuthorized = widgetAuth && widgetAuth.authorized;

    return (
      <SplitPanelHeader
        className="SplitPanel__body"
        isBackButtonEnabled={true}
        subHeader={this.getSplitPannelHeader(portfolioId, propertyId)}
        button={!isWidgetAuthorized ? this.getEffectiveDateEditButton() : ""}
        includeTopNav={!isWidgetAuthorized}
      >
        {!exceptionSidebarIsActive && (
          <div
            className={cx("SplitPanel__exceptionSidebarTrigger", {
              ["SplitPanel__exceptionSidebarTrigger__widget"]: isWidgetAuthorized
            })}
            onClick={() => updateExceptionSidebar(true)}
          >
            <img src={require("ui/images/arrow-left-blue.png")} />
          </div>
        )}
        {exceptionSidebarIsActive && (
          <ExceptionsSidebar
            totalPctComplete={totalPctComplete}
            handleScrollToCell={this.handleScrollToCell}
            includeTopNav={!isWidgetAuthorized}
            property={selectedProperty}
          />
        )}
        <div
          className={cx("SplitPanel__editorContainer", {
            ["SplitPanel__editorContainer__widget"]: isWidgetAuthorized
          })}
        >
          <SplitPanelSubHeader
            tableRefs={this.state.tableRefs}
            includeTopNav={!isWidgetAuthorized}
          />
          {/* Todo: Make spinner work for other file formats */}
          {excelMarkdownStatus !== "LOADED" && (
            <div
              style={{
                width: "100vw",
                height: "50vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <ThreeBounceSpinner />
            </div>
          )}
          {rentRollDocument && rentRollDocument.file_extension && (
            <DocumentView
              fileExtension={rentRollDocument.file_extension}
              deletedRows={this.state.deletedRows}
              tableRefs={this.state.tableRefs}
              handleScrollToCell={this.handleScrollToCell}
              selectedDocumentCell={this.state.selectedDocumentCell}
              updateSelectedDocumentCell={this.updateSelectedDocumentCell}
              updateSelectedRentRollCell={this.updateSelectedRentRollCell}
              propertyType={selectedProperty.property_type}
            />
          )}
          {selectedProperty && (
            <PreviewPane
              handleRef={this.handleTableRef}
              handleScrollToCell={this.handleScrollToCell}
              updateDeletedRows={this.updateDeletedRows}
              updateSelectedDocumentCell={this.updateSelectedDocumentCell}
              updateSelectedRentRollCell={this.updateSelectedRentRollCell}
              selectedRentRowCell={this.state.selectedRentRowCell}
              propertyType={selectedProperty.property_type}
              widgetAuth={widgetAuth}
            />
          )}
        </div>
        <BottomStatusBar
          propertyId={propertyId}
          totalPctComplete={totalPctComplete}
          totalExceptions={totalExceptions}
          widgetAuth={widgetAuth}
        />
      </SplitPanelHeader>
    );
  }
}

SplitPanelView.defaultProps = {
  widgetAuth: {}
};

SplitPanelView.propTypes = {
  match: PropTypes.object,
  rentRollDocument: PropTypes.object,
  rentRoll: PropTypes.array,
  rentRollDocuments: PropTypes.array,
  fetchRentRollByDocId: PropTypes.func,
  fetchRentRollDocument: PropTypes.func,
  clearCache: PropTypes.func,
  exceptionSidebarIsActive: PropTypes.bool,
  pagination: PropTypes.array,
  updatePageIndex: PropTypes.func,
  pageIndex: PropTypes.number,
  fetchExceptionsByDocId: PropTypes.func,
  exceptions: PropTypes.array,
  updateDocument: PropTypes.func,
  clearSplitPanel: PropTypes.func,
  documentNav: PropTypes.string,
  rentRollNav: PropTypes.string,
  pageNavInProgress: PropTypes.bool,
  getRentRollFields: PropTypes.func,
  refreshSplitPanel: PropTypes.func,
  AddCellToDocumentNav: PropTypes.func,
  AddCellToRentRollNav: PropTypes.func,
  BeginPageNav: PropTypes.func,
  updatePdfMarkdownLoading: PropTypes.func,
  EndPageNav: PropTypes.func,
  excelMarkdownStatus: PropTypes.string,
  updateExceptionSidebar: PropTypes.func,
  assignExceptionToEffectiveDate: PropTypes.func,
  selectedProperty: PropTypes.object,
  widgetAuth: PropTypes.object
};

const mapStateToProps = ({
  rentRoll,
  splitScreen,
  properties,
  currentUser
}) => ({
  rentRollDocument: rentRoll.document,
  rentRollDocuments: rentRoll.documents,
  rentRoll: rentRoll.rentRoll,
  exceptionSidebarIsActive: rentRoll.exceptionSidebarIsActive,
  excelMarkdownStatus: rentRoll.excelMarkdownStatus,
  pageIndex: rentRoll.pageIndex,
  exceptions: rentRoll.exceptions,
  fetchProperty: rentRoll.fetchProperty,
  documentNav: splitScreen.documentNav,
  renRollNav: splitScreen.renRollNav,
  pageNavInProgress: splitScreen.pageNavInProgress,
  cellNavInProgress: splitScreen.cellNavInProgress,
  // Todo: consolidate Selected property in rentRoll and properties
  selectedProperty: properties.selectedProperty,
  widgetAuth: currentUser.widgetAuth
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchRentRollByDocId,
      fetchRentRollDocument,
      fetchExceptionsByDocId,
      fetchProperty,
      clearCache,
      updatePageIndex,
      getRentRollFields,
      updateDocument,
      AddCellToDocumentNav,
      AddCellToRentRollNav,
      BeginPageNav,
      updatePdfMarkdownLoading,
      refreshSplitPanel,
      clearSplitPanel,
      EndDocumentNav,
      EndPageNav,
      updateExceptionSidebar,
      assignExceptionToEffectiveDate
    },
    dispatch
  );

export default AuthenticatedRoute(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(SplitPanelView))
);
