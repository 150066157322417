import { Component } from "react";
import PropTypes from "prop-types";
import {
  searchSuggestions,
  handleSuggestionsTextChange,
  resetSuggestions
} from "ui/store/actions/properties";
import { connect } from "react-redux";
import Autocomplete from "react-autocomplete";
import { withRouter } from "react-router";
import cx from "classnames";
import {
  addToActiveFilters,
  updateActiveSearchInput
} from "ui/store/actions/filters";
import { searchProperties } from "ui/store/actions/properties";
import IconButton from "ui/components/shared/IconButton";

class SearchAutocomplete extends Component {
  constructor(props) {
    super(props);
    this.autocompleteScheduler = [];
    this.state = {
      value: ""
    };
  }

  handleChange = e => {
    const {
      searchSuggestions,
      handleSuggestionsTextChange,
      portfolioId,
      resetSuggestions
    } = this.props;
    const text = e.target.value;
    this.setState({ value: text });
    const lastSchedule = this.autocompleteScheduler.pop();
    lastSchedule && clearTimeout(lastSchedule);
    this.autocompleteScheduler.push(
      setTimeout(() => {
        if (text) {
          searchSuggestions(portfolioId, text);
        } else {
          resetSuggestions();
        }
      }, 350)
    );
    handleSuggestionsTextChange(text);
  };

  onSelect = query => {
    const { handleSuggestionsTextChange } = this.props;
    if (query && query.length > 0) {
      handleSuggestionsTextChange(query);
      this.setState({ value: query });
      this.setSearchInputFilter(query);
    }
  };

  handleOnKeyDown = e => {
    const searchString = e.target.value;
    if (e.key === "Enter") {
      if (searchString !== "") {
        this.setSearchInputFilter(searchString);
      }
    }
  };

  setSearchInputFilter = searchString => {
    const {
      filters,
      portfolioId,
      applyFilter,
      updateActiveSearchInput,
      addToActiveFilters,
      searchProperties
    } = this.props;
    if (filters.searchInput) {
      applyFilter("searchInput", searchString);
      updateActiveSearchInput(searchString);
      searchProperties(portfolioId, filters, false);
    } else {
      applyFilter("searchInput", searchString);
      addToActiveFilters("searchInput", searchString);
      searchProperties(portfolioId, filters, false);
    }
  };

  render() {
    const {
      recommendedSuggestions,
      placeholder,
      name,
      setInputField
    } = this.props;

    return (
      <Autocomplete
        freeSolo
        style={{ border: "none" }}
        wrapperProps={{ style: { display: "inline-block", margin: "0 5%" } }}
        inputProps={{ placeholder, name }}
        getItemValue={item => item}
        renderItem={(item, isHighlighted) => (
          <div
            key={item}
            style={{
              padding: "7px",
              background: isHighlighted ? "#2196f3" : "#fff",
              color: isHighlighted ? "#fff" : "#000",
              cursor: "default",
              lineHeight: "18px"
            }}
          >
            {item}
          </div>
        )}
        onSelect={this.onSelect}
        onChange={this.handleChange}
        value={this.state.value}
        items={this.state.value.length > 0 ? recommendedSuggestions : []}
        ref={setInputField}
        autoHighlight={false}
        clearOnEscape={true}
        renderMenu={(items, value, style) => {
          if (items.length === 0) {
            return <div style={{ display: "none" }} />;
          }
          return (
            <div
              style={{
                ...style,
                ...{
                  border: "1px solid #e5e5e5",
                  boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
                  background: "rgba(255, 255, 255, 0.9)",
                  padding: "2px 0",
                  position: "sticky",
                  overflow: "auto",
                  width: "200px",
                  zIndex: "100"
                }
              }}
            >
              {items}
            </div>
          );
        }}
        renderInput={props => {
          return (
            <div className={cx("SearchAutocomplete")}>
              <input
                {...props}
                onKeyDown={this.handleOnKeyDown}
                className={cx("SearchAutocomplete__searchInput")}
                autoComplete="off"
              />
              <IconButton
                className={cx("close-button", "IconButton__smallButton")}
                onClick={() => {
                  if (this.state.value !== "") {
                    this.setState({ value: "" });
                  }
                }}
                src={require("ui/images/x-icon.svg")}
              />
            </div>
          );
        }}
      />
    );
  }
}

SearchAutocomplete.propTypes = {
  handleKeyDown: PropTypes.func,
  removeFilter: PropTypes.func,
  applyFilter: PropTypes.func,
  updateActiveSearchInput: PropTypes.func,
  addToActiveFilters: PropTypes.func,
  filters: PropTypes.object,
  recommendedSuggestions: PropTypes.array,
  searchSuggestions: PropTypes.func,
  renderInput: PropTypes.func,
  setInputField: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  handleSuggestionsTextChange: PropTypes.func,
  query: PropTypes.string,
  authenticated: PropTypes.bool,
  location: PropTypes.object,
  portfolioId: PropTypes.any,
  resetSuggestions: PropTypes.func,
  searchProperties: PropTypes.func
};

function mapStateToProps(state) {
  return {
    recommendedSuggestions: state.properties.recommendedSuggestions,
    query: state.properties.q,
    authenticated: state.authenticated,
    filters: state.filters.filters
  };
}

function mapDispatchToProps(dispatch) {
  return {
    searchSuggestions: function(portfolioId, query) {
      dispatch(searchSuggestions(portfolioId, query));
    },
    handleSuggestionsTextChange: function(text) {
      dispatch(handleSuggestionsTextChange(text));
    },
    resetSuggestions: function() {
      dispatch(resetSuggestions());
    },
    addToActiveFilters: function(name, query) {
      dispatch(addToActiveFilters(name, query));
    },
    updateActiveSearchInput: function(query) {
      dispatch(updateActiveSearchInput(query));
    },
    searchProperties: function(portfolioId, filters, bval) {
      dispatch(searchProperties(portfolioId, filters, bval));
    }
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SearchAutocomplete)
);
