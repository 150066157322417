import {
  percentFormatter,
  compactCurrencyFormatter,
  compactNumberFormatter,
  squareFootageFormatter
} from "ui/components/helpers/metrics";
import { path } from "ramda";

export function formatAggregationMetrics(metrics) {
  return {
    totalPerMonth: path(["total_monthly_rent", "value"], metrics)
      ? compactCurrencyFormatter.format(
          path(["total_monthly_rent", "value"], metrics)
        )
      : "-",
    totalPerYear: path(["total_annual_rent", "value"], metrics)
      ? parseFloat(path(["total_annual_rent", "value"], metrics)) > 100000
        ? compactCurrencyFormatter.format(
            path(["total_annual_rent", "value"], metrics)
          )
        : parseFloat(path(["total_annual_rent", "value"], metrics))
      : "-",
    totalSquareFootage: path(["total_square_footage", "value"], metrics)
      ? (parseFloat(path(["total_square_footage", "value"], metrics)) > 100000
          ? compactNumberFormatter.format(
              parseFloat(path(["total_square_footage", "value"], metrics))
            )
          : squareFootageFormatter.format(
              parseFloat(path(["total_square_footage", "value"], metrics))
            )) + " sq ft"
      : "-",
    totalUnits: path(["total_units", "value"], metrics)
      ? compactNumberFormatter.format(
          parseFloat(path(["total_units", "value"], metrics))
        )
      : "-",
    averageAnnualRentPerSqft:
      path(["occupied_total_annual_rent_with_sqft", "value"], metrics) &&
      path(["occupied_total_sqft_with_annual_rent", "value"], metrics)
        ? compactCurrencyFormatter.format(
            parseFloat(
              parseFloat(
                path(
                  ["occupied_total_annual_rent_with_sqft", "value"],
                  metrics
                ) /
                  path(
                    ["occupied_total_sqft_with_annual_rent", "value"],
                    metrics
                  )
              ).toFixed(2)
            )
          )
        : "-",
    percentOfPortfolioIncome:
      path(["total_annual_rent", "value"], metrics) &&
      path(["total_portfolio_income", "value"], metrics)
        ? parseFloat(
            parseFloat(
              path(["total_annual_rent", "value"], metrics) /
                path(["total_portfolio_income", "value"], metrics)
            ).toFixed(2)
          ) * 100
        : 0,
    percentOfPortfolioIncomeGLA:
      path(["total_square_footage", "value"], metrics) &&
      path(["total_portfolio_square_footage", "value"], metrics)
        ? parseFloat(
            parseFloat(
              path(["total_square_footage", "value"], metrics) /
                path(["total_portfolio_square_footage", "value"], metrics)
            ).toFixed(2)
          ) * 100
        : 0,
    vacancyRatePerUnit:
      path(["total_units", "value"], metrics) &&
      path(["occupied_total_units", "value"], metrics)
        ? percentFormatter.format(
            1 -
              parseFloat(path(["occupied_total_units", "value"], metrics)) /
                parseFloat(path(["total_units", "value"], metrics))
          )
        : "-",
    vacancyRatePerSqft:
      path(["total_square_footage", "value"], metrics) &&
      path(["occupied_total_square_footage", "value"], metrics)
        ? percentFormatter.format(
            1 -
              parseFloat(
                path(["occupied_total_square_footage", "value"], metrics)
              ) /
                parseFloat(path(["total_square_footage", "value"], metrics))
          )
        : "-",
    averageLeaseTerm: path(["average_lease_term", "value"], metrics)
      ? Math.max(
          0,
          parseFloat(path(["average_lease_term", "value"], metrics).toFixed(2))
        )
      : 0,
    averageLeaseRemaining: path(["average_lease_remaining", "value"], metrics)
      ? Math.max(
          0,
          parseFloat(
            path(["average_lease_remaining", "value"], metrics).toFixed(2)
          )
        )
      : 0
  };
}
