import { Component } from "react";
import PropTypes from "prop-types";

/*
 * This component has issue on checkbox click event.
 * If user clicks on checkbox directly, the check mark is not shown up at first.
 * Checkbox3 solves the issue.
 */
class Checkbox extends Component {
  render() {
    const {
      name,
      checked,
      toggleCheck,
      disabled,
      children,
      readOnly,
      labelStyle = {}
    } = this.props;
    return (
      <div className="Checkbox">
        <input
          type="checkbox"
          name={name}
          checked={checked}
          onChange={toggleCheck}
          disabled={disabled}
          readOnly={readOnly}
        />
        {children && (
          <span
            className="Checkbox__label"
            style={labelStyle}
            onClick={toggleCheck}
          >
            {children}
          </span>
        )}
      </div>
    );
  }
}

Checkbox.propTypes = {
  name: PropTypes.any,
  checked: PropTypes.bool,
  toggleCheck: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  labelStyle: PropTypes.object
};

export default Checkbox;
