import { Component } from "react";
import PropTypes from "prop-types";

import Checkbox from "./Checkbox2";

class CheckBoxTableRows extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: {}
    };
  }
  handleToggle = e => {
    const [rowName, colName] = JSON.parse(e.target.name);
    if (this.state.checked[[rowName, colName]] === true) {
      this.setState({
        checked: { [[rowName, colName]]: false }
      });
    } else {
      this.setState({
        checked: { [[rowName, colName]]: true }
      });
    }
    this.props.handleToggle(rowName, colName);
  };

  determineChecked = (rowName, colName) => {
    return this.state[[rowName, colName]] === true;
  };

  render() {
    const { rowNames, colNames } = this.props;
    const determineChecked =
      this.props.determineChecked || this.determineChecked;
    return rowNames.map((rowName, ri) => {
      return (
        <div key={`checkbox_row_${ri}`} className="CheckBoxTableRow">
          <div className="CheckBoxTableRow__row-name">{rowName}</div>
          {colNames.map((colName, ci) => {
            return (
              <Checkbox
                key={`checkbox_col_${ci}`}
                name={JSON.stringify([rowName, colName])}
                checked={determineChecked(rowName, colName)}
                toggleCheck={this.handleToggle}
              />
            );
          })}
        </div>
      );
    });
  }
}

CheckBoxTableRows.propTypes = {
  rowsNames: PropTypes.array,
  determineChecked: PropTypes.func
};

export default CheckBoxTableRows;
