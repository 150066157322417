import PropTypes from "prop-types";
import OpexCellValueText from "./OpexCellValueText";
import { getClassNameForCellScrollIntoView } from "ui/components/opex/shared";

const ReadOnlyCell = ({ cellData }) => (
  <div
    className={`OpexBaseTable--ReadOnlyCell ${
      cellData.disabled ? "cellDisabled" : ""
    } ${getClassNameForCellScrollIntoView(cellData.location)}`}
    onClick={!cellData.disabled ? () => cellData.onClick(cellData) : () => {}}
  >
    <div className="cellContent">
      <OpexCellValueText value={cellData.value} type={cellData.valueType} />
    </div>
  </div>
);

export default ReadOnlyCell;

ReadOnlyCell.propTypes = {
  cellData: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    valueType: PropTypes.string,
    disabled: PropTypes.bool
  })
};
