import { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import cx from "classnames";
import { connect } from "react-redux";
// import { withRouter } from "react-router";
import { reactLocalStorage } from "reactjs-localstorage";

import NavLink from "ui/components/shared/NavLink";
import Button from "ui/components/shared/Button";
import { getRentrollDocumentPdf } from "ui/store/actions/rentRoll";
import CustomDropDown from "ui/components/shared/dropdowns/CustomDropDown";
import { customStyles } from "ui/components/shared/dropdowns/helper.js";
import { updateSelectedDocId } from "ui/store/actions/propertyRentRollViewUI";
import { fetchRentRollByDocId } from "../../../store/actions/rentRoll";

export class RentRollStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDoc: undefined,
      selectedDropdown: undefined,
      isLoading: true,
      rentrollList: []
    };
  }

  componentDidMount() {
    this.refreshPreview();
    this.getRentRollMenuList();
    this.setState({ isLoading: false });
  }

  refreshPreview = () => {
    const { getRentrollDocumentPdfUrl } = this.props;

    if (this.state.selectedDoc && this.state.selectedDoc.s3PdfUrl) {
      getRentrollDocumentPdfUrl(this.state.selectedDoc.s3Ref);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      getRentrollDocumentPdfUrl,
      getRentRollByDocId,
      documents
    } = this.props;
    const { selectedDropdown } = this.state;

    if (prevProps.documents !== documents) {
      this.getRentRollMenuList();
    }

    if (selectedDropdown !== prevState.selectedDropdown && selectedDropdown) {
      const currDoc = documents.find(doc => doc.id === selectedDropdown.value);
      this.setState({ selectedDoc: currDoc });

      getRentrollDocumentPdfUrl(currDoc.s3Ref);
      getRentRollByDocId(currDoc.id);
    }
  }

  getRentRollMenuList = () => {
    const { documents, changeSelectedDocId } = this.props;
    const docId = reactLocalStorage.get("documentId", undefined);
    let currDoc, doc;

    if (documents.find(doc => doc.id == docId)) {
      currDoc = documents.find(doc => doc.id == docId);
      changeSelectedDocId(docId);
      this.setState({
        rentrollList: documents
          .filter(doc => doc.status === "COMPLETED")
          .map(doc => ({
            value: doc.id,
            label: this.getLabel(doc.effectiveDate)
          })),
        selectedDropdown: {
          value: currDoc.id,
          label: this.getLabel(currDoc.effectiveDate)
        },
        currDoc
      });
    } else {
      doc = documents.filter(doc => doc.status === "COMPLETED")[0];
      // reactLocalStorage.set("documentId", doc.id);
      changeSelectedDocId(doc.id);
      this.setState({
        rentrollList: documents
          .filter(doc => doc.status === "COMPLETED")
          .map(doc => ({
            value: doc.id,
            label: this.getLabel(doc.effectiveDate)
          })),
        selectedDropdown: {
          value: doc.id,
          label: this.getLabel(doc.effectiveDate)
        },
        currDoc: doc
      });
    }
  };

  getLabel = effectiveDate => {
    return `${moment(effectiveDate)
      .utc()
      .format("MMM YYYY")} Rentroll`;
  };

  dropdownStateChange = e => {
    const { documents, changeSelectedDocId } = this.props;
    const docId = e.value;
    const doc = documents.find(doc => doc.id === docId);
    changeSelectedDocId(docId);
    reactLocalStorage.set("documentId", docId);
    this.setState({
      selectedDropdown: {
        value: doc.id,
        label: this.getLabel(doc.effectiveDate)
      }
    });
  };

  render() {
    const {
      propertyId,
      portfolioId,
      fileUrl,
      documents,
      currDocId
    } = this.props;
    const {
      selectedDoc,
      selectedDropdown,
      isLoading,
      rentrollList
    } = this.state;

    return (
      <div className="RentRollStatus">
        <div className="RentRollStatus__left-div">
          {documents && rentrollList.length > 0 && (
            <CustomDropDown
              className="RentRollStatus__rentroll-dropdown"
              value={selectedDropdown}
              options={rentrollList}
              defaultValue={rentrollList.find(doc => doc.value === currDocId)}
              dropdownStateChange={this.dropdownStateChange}
              styles={customStyles}
              components={{
                IndicatorSeparator: () => null
              }}
            />
          )}

          {!isLoading && (
            <div className="RentRollStatus__EffectiveDate">
              {` Effective Date ${
                selectedDoc && selectedDoc.id && selectedDoc.effectiveDate
                  ? moment(selectedDoc.effectiveDate)
                      .utc()
                      .format("MMMM DD, YYYY")
                  : currDocId &&
                    documents &&
                    documents.find(doc => doc.id === currDocId)
                  ? moment(
                      documents.find(doc => doc.id === currDocId).effectiveDate
                    )
                      .utc()
                      .format("MMMM DD, YYYY")
                  : "-"
              }`}
            </div>
          )}
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <img src={require("ui/images/green-tick.svg")} />
          <div style={{ padding: "10px 0px 0px 6px" }}>
            {!isLoading &&
            selectedDoc &&
            selectedDoc.lastStatus &&
            selectedDoc.lastStatus.updated_at
              ? `Completed on ${moment(
                  selectedDoc.lastStatus.updated_at
                ).format("MMMM DD, YYYY")}`
              : "-"}
          </div>
          <div style={{ display: "inline-block", width: "20px" }} />
          <a href={fileUrl} rel="noopener noreferrer" target="_blank">
            <Button className={cx("Button__blackButton")}>
              VIEW SOURCE DOC
            </Button>
          </a>
          <div style={{ display: "inline-block", width: "20px" }} />
          {selectedDoc && selectedDoc.id && (
            <NavLink
              className="action-nav"
              to={{
                pathname: `/rent-roll/split-panel/property/${propertyId}/document/${
                  selectedDoc.id
                }${portfolioId ? `/portfolio/${portfolioId}` : ""}`
              }}
            >
              <Button className="Button__blueButton">REVIEW</Button>
            </NavLink>
          )}
        </div>
      </div>
    );
  }
}

RentRollStatus.propTypes = {
  document: PropTypes.object,
  selectedProperty: PropTypes.object,
  propertyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  portfolioId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fileUrl: PropTypes.string,
  currDocId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  changeSelectedDocId: PropTypes.func,
  getRentrollDocumentPdfUrl: PropTypes.func,
  getRentRollByDocId: PropTypes.func,
  documents: PropTypes.array
};

function mapStateToProps(state) {
  return {
    fileUrl: state.rentRoll.fileUrl,
    documents: state.rentRoll.documents,
    currDocId: state.propertyRentRollViewUI.docId,
    selectedProperty: state.rentRoll.selectedProperty
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getRentrollDocumentPdfUrl: filename => {
      dispatch(getRentrollDocumentPdf(filename));
    },
    changeSelectedDocId: id => {
      dispatch(updateSelectedDocId(id));
    },
    getRentRollByDocId: id => {
      dispatch(fetchRentRollByDocId(id));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RentRollStatus);
