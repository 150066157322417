import { useMemo } from "react";
import computeCellFlags from "ui/components/rentRoll/splitPanelV2/helpers/computeCellFlags";
import filterExceptions from "ui/components/rentRoll/splitPanelV2/helpers/filterExceptions";
import { generateTableKey } from "ui/components/rentRoll/splitPanelV2/helpers/tableKeys";
import useDocument from "ui/components/rentRoll/splitPanelV2/hooks/useDocument";
import useExceptions from "ui/components/rentRoll/splitPanelV2/hooks/useExceptions";
import useRentRoll from "ui/components/rentRoll/splitPanelV2/hooks/useRentRoll";
import doesCellHaveRentRoll from "ui/components/rentRoll/splitPanelV2/helpers/doesCellHaveRentRoll";

export default function useExcelDocumentData(documentTables) {
  const { document } = useDocument();
  const { exceptions } = useExceptions();
  const { rentRoll } = useRentRoll();

  return useMemo(
    () =>
      computeExcelTablesData({
        document,
        documentTables,
        exceptions,
        rentRoll
      }),
    [document, documentTables, exceptions, rentRoll]
  );
}

export function computeExcelTablesData({
  document,
  documentTables = [],
  exceptions = [],
  rentRoll = []
}) {
  if (!document) return [];

  return document.raw_data_json.pages.map((_, pageIndex) => {
    const tableData = computeConcatenatedTableDataForPage({
      document,
      documentTables,
      exceptions,
      pageIndex,
      rentRoll
    });

    const columnWidths = computeColumnWidths(tableData);

    const columns = computeColumns({
      columnWidths,
      tableDataItem: tableData[0]
    });

    return { columns, tableData };
  });
}

export function computeConcatenatedTableDataForPage({
  document,
  documentTables,
  exceptions,
  pageIndex,
  rentRoll
}) {
  return document.raw_data_json.pages[pageIndex].tableData.reduce(
    (acc, table, tableIndex) => {
      const { headerIndex } = documentTables.find(
        table =>
          table.pageIndexes.includes(pageIndex) &&
          table.tableIndexes.includes(tableIndex)
      );

      const tableRentRolls = rentRoll.filter(
        ({ doc_page_id, doc_table_id }) =>
          doc_page_id === pageIndex && doc_table_id === tableIndex
      );

      const tableExceptions = filterExceptions({
        exceptions,
        pageIndexes: [pageIndex],
        tableIndexes: [tableIndex]
      });

      const tableLocation = {
        headerIndex,
        pageIndex,
        tableIndex
      };

      return acc.concat(
        computeRowData({
          table,
          tableLocation,
          exceptions: tableExceptions,
          rentRolls: tableRentRolls
        })
      );
    },
    []
  );
}

export function computeRowData({
  tableLocation,
  exceptions = [],
  rentRolls = [],
  table = []
}) {
  return table.map((row, rowIndex) => {
    const rowLocation = {
      ...tableLocation,
      rowIndex
    };
    return {
      ...rowLocation,
      id: generateTableKey(rowLocation),
      data: computeColumnData({
        row,
        rentRolls,
        exceptions,
        rowLocation
      })
    };
  });
}

export function computeColumnData({
  rowLocation,
  exceptions = [],
  rentRolls = [],
  row = []
}) {
  return row.map((column, columnIndex) => {
    const location = { ...rowLocation, columnIndex };
    const key = generateTableKey(location);
    const isMapped = doesCellHaveRentRoll({ rentRolls, location });
    const { confidence, corrected, predef } = column;
    const { hasWarning, hasError, isCorrected } = computeCellFlags({
      confidence,
      corrected,
      isMapped,
      location,
      predef,
      exceptions
    });

    return {
      ...location,
      hasWarning,
      hasError,
      key,
      isCorrected,
      isMapped,
      text: column.text
    };
  });
}

export function computeColumnWidths(tableData) {
  const FONT_SIZE = 13;
  const PADDING = 24;

  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  ctx.font = ctx.font.replace(/\d+px/, `${FONT_SIZE}px`);

  return tableData[0].data.map((column, columnIndex) =>
    Math.max(
      ...tableData.map(({ data }) => {
        const { text } = data[columnIndex];

        const textWidth = ctx.measureText(text || "").width + PADDING;

        return Math.min(textWidth, 200);
      })
    )
  );
}

function computeWidth(computedColumnWidths, columnIndex) {
  const ACTIONS_WIDTH = 70;
  const totalComputedWidth = computedColumnWidths.reduce(
    (acc, curr) => acc + curr,
    0
  );

  return width => {
    if (width > totalComputedWidth) {
      let evenlySpacedWidth =
        (width - ACTIONS_WIDTH) / computedColumnWidths.length;

      const greater = computedColumnWidths.filter(w => w > evenlySpacedWidth);
      const diff = greater.reduce((acc, w) => acc + (w - evenlySpacedWidth), 0);

      evenlySpacedWidth -=
        diff / (computedColumnWidths.length - greater.length);

      return Math.max(evenlySpacedWidth, computedColumnWidths[columnIndex]);
    } else {
      return computedColumnWidths[columnIndex];
    }
  };
}

export function computeColumns({ columnWidths, tableDataItem }) {
  const { headerIndex, rowIndex, pageIndex, tableIndex } = tableDataItem;
  const location = { headerIndex, rowIndex, pageIndex, tableIndex };

  return [
    {
      ...location,
      columnIndex: -1,
      computeWidth: () => 70,
      frozen: "left",
      key: generateTableKey({
        ...location,
        columnIndex: -1
      })
    },
    ...tableDataItem.data.map(({ columnIndex }) => {
      return {
        ...location,
        columnIndex,
        computeWidth: computeWidth(columnWidths, columnIndex),
        frozen: null,
        key: generateTableKey({
          ...location,
          columnIndex
        })
      };
    })
  ];
}
