import { merge } from "ramda";

import {
  USERS_FETCHING,
  USERS_FETCH_SUCCESSFUL,
  USERS_FETCH_FAILED,
  USER_UPDATING,
  USER_UPDATE_SUCCESSFUL,
  USER_DELETING,
  USER_DELETE_SUCCESSFUL,
  FILTER_USERS_BY_COMPANY,
  FILTER_USERS_BY_ROLE,
  FILTER_USERS_BY_STATUS,
  CLEAR_USER_STORE,
  REMOVE_USER_FILTER,
  UPDATE_USER_FILTER,
  CLEAR_USER_FILTER,
  UPDATE_ALPHA_FILTER,
  USERS_FETCH_SUCCESSFUL_NO_PAGINATION
} from "ui/store/actions/users";

import {
  runFiltersAndReturnState
  // runFiltersAfterFetch
} from "./helpers/users";

export const FETCHING = "FETCHING";
export const UPDATING = "UPDATING";
export const UPDATED = "UPDATED";
export const LOADED = "LOADED";
export const FAILED = "FAILED";
export const DELETING = "DELETING";
export const DELETED = "DELETED";

export default function users(
  state = {
    status: FETCHING,
    filteredResults: [],
    users: [],
    filters: [],
    filter: {},
    alpha: "asc",
    // filteredCompanyId: "all",
    // filteredRole: "all",
    // filteredStatus: "all",
    totalCount: 0,
    page: 0,
    totalPages: 0
  },
  action
) {
  switch (action.type) {
    case UPDATE_ALPHA_FILTER: {
      const { filterType } = action;
      return merge(state, { alpha: filterType });
    }
    case REMOVE_USER_FILTER: {
      const { filterType } = action;
      delete state.filter[`${filterType}`];
      return state;
    }
    case UPDATE_USER_FILTER: {
      const { filterType, val } = action;
      return {
        ...state,
        filter: {
          ...state.filter,
          [`${filterType}`]: val
        }
      };
    }
    case CLEAR_USER_FILTER: {
      return { ...state, filter: {} };
    }
    case USERS_FETCHING: {
      return merge(state, { status: FETCHING });
    }
    case CLEAR_USER_STORE: {
      return merge(state, { users: [] });
    }
    case USERS_FETCH_SUCCESSFUL_NO_PAGINATION: {
      const { users } = action;
      return { ...state, users };
    }
    case USERS_FETCH_SUCCESSFUL: {
      const { paginatedUsers } = action;
      const { rows, totalCount, page, totalPages } = paginatedUsers;
      return {
        ...state,
        status: LOADED,
        users: rows,
        totalCount,
        page,
        totalPages
      };
    }
    case USERS_FETCH_FAILED: {
      const { error } = action;
      return merge(state, { error, status: FAILED });
    }
    case USER_UPDATING: {
      return merge(state, { status: UPDATING });
    }
    case USER_UPDATE_SUCCESSFUL: {
      return merge(state, { status: UPDATED });
    }
    case USER_DELETING: {
      return merge(state, { status: DELETING });
    }
    case USER_DELETE_SUCCESSFUL: {
      return merge(state, { status: DELETED });
    }
    case FILTER_USERS_BY_COMPANY: {
      const { companyId } = action;
      return runFiltersAndReturnState(state, action, companyId);
    }
    case FILTER_USERS_BY_ROLE: {
      const { role } = action;
      return runFiltersAndReturnState(state, action, role);
    }
    case FILTER_USERS_BY_STATUS: {
      const { status } = action;
      return runFiltersAndReturnState(state, action, status);
    }
    default:
      return state;
  }
}
