import { apiFetch } from "./apiClient";
import { showFlash } from "./flash";
import axios from "axios";
import Cookies from "js-cookie";

import { fetchUploadingDocuments } from "ui/store/actions/rentRoll";
import { API_SIGNATURE_GENERATED } from "ui/store/actions/s2sAuthentication";

export const COMPANIES_FETCHING = "COMPANIES_FETCHING";
export const COMPANIES_FETCH_SUCCESSFUL = "COMPANIES_FETCH_SUCCESSFUL";
export const COMPANIES_FETCH_FAILED = "COMPANIES_FETCH_FAILED";
export const UPLOADING_DOCUMENT_WITOUT_PROPERTY =
  "company/UPLOADING_DOCUMENT_WITOUT_PROPERTY";

export function fetchCompanies() {
  return async function(dispatch) {
    dispatch(companiesFetching());

    try {
      const response = await apiFetch("/api/companies");
      const responseBody = await response.json();
      if (!response.ok) {
        throw new Error(responseBody.error.message);
      }
      dispatch(companiesFetchSuccessful(responseBody));
    } catch (error) {
      dispatch(companiesFetchFailed(error));
    }
  };
}

function companiesFetching() {
  return {
    type: COMPANIES_FETCHING
  };
}

function companiesFetchSuccessful(companies) {
  return {
    companies,
    type: COMPANIES_FETCH_SUCCESSFUL
  };
}

function companiesFetchFailed(error) {
  return {
    error,
    type: COMPANIES_FETCH_FAILED
  };
}

export function createNewCompany(params, history) {
  const {
    companyName,
    maxActiveUsers,
    groupName,
    externalId,
    accountActiveLengthAmmount,
    accountActiveLengthType
  } = params;
  const companyObj = {
    companyName,
    maxActiveUsers,
    groupName,
    externalId,
    accountActiveLength: `${accountActiveLengthAmmount} ${accountActiveLengthType}`
  };
  return async function(dispatch) {
    try {
      const response = await apiFetch("/api/companies/new", {
        method: "post",
        body: JSON.stringify(companyObj)
      });
      const responseBody = await response.json();
      if (!response.ok) {
        throw new Error(responseBody.error);
      }
      history.push("/admin/companies");
      dispatch(
        showFlash({
          type: "success",
          message: `Company ${companyName} was created.`
        })
      );
    } catch (e) {
      dispatch(
        showFlash({
          type: "error",
          message: `${e}`
        })
      );
    }
  };
}

export function updateCompany(
  companyId,
  endDate,
  maxActiveUsers,
  groupName,
  externalId
) {
  const companyUpdateObj = {
    companyId,
    endDate,
    maxActiveUsers,
    groupName,
    externalId
  };
  return async function(dispatch) {
    try {
      const response = await apiFetch("/api/companies/update", {
        method: "put",
        body: JSON.stringify(companyUpdateObj)
      });
      if (!response.ok) {
        const responseBody = await response.json();
        throw new Error(responseBody.error);
      }
      dispatch(
        showFlash({
          type: "success",
          message: `Company with id ${companyId} updated!`
        })
      );
      dispatch(fetchCompanies());
    } catch (e) {
      dispatch(
        showFlash({
          type: "error",
          message: `${e}`
        })
      );
    }
  };
}

export const uploadRentRollDocumentWithoutProperty = (
  file,
  widgetAuth = {}
) => {
  return async function(dispatch) {
    try {
      dispatch(uploadingRentRollDocumentWithoutProperty());
      const jwt = Cookies.get("quiq-property-jwt");
      const formData = new FormData();
      formData.append("file", file);

      if (widgetAuth && widgetAuth.authorized) {
        const token = widgetAuth.token;
        axios
          .post(`/api/companies/upload-documents/`, formData, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data"
            }
          })
          .then(() => {
            dispatch(fetchUploadingDocuments(widgetAuth));
          });
      } else {
        axios
          .post(`/api/companies/upload-documents/`, formData, {
            headers: {
              Authorization: `Bearer ${jwt}`,
              "Content-Type": "multipart/form-data"
            }
          })
          .then(() => {
            dispatch(fetchUploadingDocuments());
          });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };
};

export function uploadingRentRollDocumentWithoutProperty() {
  return { type: UPLOADING_DOCUMENT_WITOUT_PROPERTY };
}

export function generateCompanyApiKeys(companyId) {
  const companyObj = {
    companyId
  };
  return async function(dispatch) {
    try {
      const response = await apiFetch("/api/companies/generate-api-keys", {
        method: "put",
        body: JSON.stringify(companyObj)
      });
      if (!response.ok) {
        const responseBody = await response.json();
        throw new Error(responseBody.error);
      }
      dispatch(
        showFlash({
          type: "success",
          message: `Keys for company with id ${companyId} updated!`
        })
      );
      dispatch(fetchCompanies());
    } catch (e) {
      dispatch(
        showFlash({
          type: "error",
          message: `${e}`
        })
      );
    }
  };
}

export function getCompanyApiKeys(companyId) {
  return async function(dispatch) {
    try {
      const response = await apiFetch(`/api/companies/api-keys/` + companyId, {
        method: "get"
      });
      const responseBody = await response.json();
      if (!response.ok) {
        throw new Error(responseBody.error);
      }
      const { apiPublicKey, secretKey } = responseBody;
      dispatch(getKeysSuccessful(apiPublicKey, secretKey));
    } catch (e) {
      dispatch(
        showFlash({
          type: "error",
          message: `${e}`
        })
      );
    }
  };
}

function getKeysSuccessful(apiPublicKey, secretKey) {
  return {
    apiPublicKey,
    secretKey,
    type: API_SIGNATURE_GENERATED
  };
}

export function loginOnBehalf(companyId) {
  const companyObj = {
    companyId
  };
  return async function(dispatch) {
    try {
      const response = await apiFetch("/api/companies/login-on-behalf", {
        method: "post",
        body: JSON.stringify(companyObj)
      });
      if (!response.ok) {
        const responseBody = await response.json();
        throw new Error(responseBody.error);
      }
      dispatch(
        showFlash({
          type: "success",
          message: `Login on behalf for company with id ${companyId} successful!`
        })
      );
    } catch (e) {
      dispatch(
        showFlash({
          type: "error",
          message: `${e}`
        })
      );
    }
  };
}
