import _ from "lodash";
import { documentStatus } from "server/middleware/api/helpers/documents";

const CHART_OF_ACCOUNTS_ACCOUNT_KEY = "account";
const CHART_OF_ACCOUNTS_AMOUNT_KEY = "amount";
class OpexDocument {
  constructor(data) {
    this.data = data;
  }

  getDocument() {
    return this.data;
  }

  getMetaDataJson() {
    return _.get(this.data, "meta_data_json");
  }

  setMetaDataJson(value) {
    return _.set(this.data, "meta_data_json", value);
  }

  getPageByPageIndex(pageIndex) {
    const pages = this.getPages();
    return pages[pageIndex];
  }

  getPages() {
    const pages = _.get(this.data, "raw_data_json.pages");
    return pages;
  }

  getQualifiedSheetHeaderIndex() {
    const pages = this.getPages();
    let headerIndex;
    pages.every((page, index) => {
      if (this.isTableQualifiedByHeaderIndex(index) === true) {
        headerIndex = index;
        return false;
      }
      return true;
    });

    return headerIndex;
  }

  getLastPublishedDate() {
    return _.get(this.data, "last_published_at");
  }

  getUpdatedAt() {
    return _.get(this.data, "updated_at");
  }

  countPages() {
    return this.getPages().length;
  }

  /**
   * Given a document, page index, and table index from the page, determine the index in
   * `meta_data_json.headers` for that table
   * @param {*} document
   * @param {number} pageIndex
   * @param {number} tableIndex
   * @returns {number} the index in `meta_data_json.headers` for that table
   */
  getMetadataJsonHeaderIndexByPageIndexTableIndex(pageIndex, tableIndex) {
    const pages = this.getPages();
    let headerIndexForTable = 0;

    _.each(pages, (page, i) => {
      const tables = _.get(page, "tableData");

      _.each(tables, (table, j) => {
        // If we are on the correct page, and the correct table, we can exit the loop.
        // Otherwise, increment the counter.
        if (i === pageIndex && j === tableIndex) {
          return false;
        } else {
          headerIndexForTable = headerIndexForTable + 1;
        }
      });

      // We don't need to check all the pages and can stop once we hit the page we are looking for.
      if (i === pageIndex) {
        return false;
      }
    });

    return headerIndexForTable;
  }

  getRawDataJsonCellByPageIndexTableIndexRowIndexColumnIndex(
    pageIndex,
    tableIndex,
    rowIndex,
    columnIndex
  ) {
    const pages = this.getPages();
    const cell = _.get(
      pages[pageIndex],
      `tableData[${tableIndex}][${rowIndex}][${columnIndex}]`
    );

    if (!cell) {
      console.warn(
        "unable to find cell in raw_data_json for",
        `pi:${pageIndex}`,
        `ti:${tableIndex}`,
        `ri:${rowIndex}`,
        `ci:${columnIndex}`
      );
    }

    return cell;
  }

  getMetaDataJsonByHeaderIndex(headerIndex) {
    return _.get(this.data, `meta_data_json.headers[${headerIndex}]`);
  }

  isRowExcludedByPageIndexTableIndexRowIndex(pageIndex, tableIndex, rowIndex) {
    const metaDataJson = this.getMetaDataJsonByPageIndexTableIndex(
      pageIndex,
      tableIndex
    );
    return metaDataJson.excludedRows[rowIndex];
  }

  getMetaDataJsonByPageIndexTableIndex(pageIndex, tableIndex) {
    const headerIndex = this.getMetadataJsonHeaderIndexByPageIndexTableIndex(
      pageIndex,
      tableIndex
    );
    const metaDataJson = this.getMetaDataJsonByHeaderIndex(headerIndex);
    return metaDataJson;
  }

  getSelectedTablesByPageIndex(pageIndex) {
    const page = this.getPageByPageIndex(pageIndex);
    const tables = _.get(page, "tableData");
    const selectedTables = [];

    _.each(tables, (table, tableIndex) => {
      const headerIndex = this.getMetadataJsonHeaderIndexByPageIndexTableIndex(
        pageIndex,
        tableIndex
      );
      if (this.isTableQualifiedByHeaderIndex(headerIndex)) {
        selectedTables.push(tableIndex);
      }
    });

    return selectedTables;
  }

  isTableQualifiedByHeaderIndex(headerIndex) {
    const metaData = this.getMetaDataJsonByHeaderIndex(headerIndex);
    return !!metaData.qualified;
  }

  isTableQualifiedByPageIndexTableIndex(pageIndex, tableIndex) {
    const headerIndex = this.getMetadataJsonHeaderIndexByPageIndexTableIndex(
      pageIndex,
      tableIndex
    );
    const qualified = this.isTableQualifiedByHeaderIndex(headerIndex);
    return !!qualified;
  }

  setTableQualifiedByPageIndexTableIndex(pageIndex, tableIndex, value) {
    const headerIndex = this.getMetadataJsonHeaderIndexByPageIndexTableIndex(
      pageIndex,
      tableIndex
    );
    const isTableQualified = this.isTableQualifiedByHeaderIndex(headerIndex);
    this.data.meta_data_json.headers[headerIndex].qualified = value;
    return this.data;
  }

  setTableQualifiedByHeaderIndex(headerIndex, value) {
    this.data.meta_data_json.headers[headerIndex].qualified = value;
    return this.data;
  }

  getChartOfAccountsColumnIndexByPageIndexTableIndex(pageIndex, tableIndex) {
    const metaDataJson = this.getMetaDataJsonByPageIndexTableIndex(
      pageIndex,
      tableIndex
    );
    const columns = _.get(metaDataJson, "headers");

    let chartOfAccountsColumIndex;
    _.each(columns, (column, columnIndex) => {
      const isChartOfAccounts =
        _.get(column, "key") === CHART_OF_ACCOUNTS_ACCOUNT_KEY;
      if (isChartOfAccounts) {
        chartOfAccountsColumIndex = columnIndex;
        return false;
      }
    });

    return chartOfAccountsColumIndex;
  }

  setAllChartOfAccountsColumnIndexByColumnIndex(columnIndex) {
    const nextMetaDataJson = { ...this.getMetaDataJson() };
    nextMetaDataJson.headers.forEach((header, index) => {
      if (this.isTableQualifiedByHeaderIndex(index)) {
        // set all headers to "amount" key
        header.headers.forEach(tableHeader => {
          _.set(tableHeader, "key", CHART_OF_ACCOUNTS_AMOUNT_KEY);
        });

        // set the new COA
        _.set(
          header,
          `headers[${columnIndex}].key`,
          CHART_OF_ACCOUNTS_ACCOUNT_KEY
        );
      }
    });

    this.setMetaDataJson(nextMetaDataJson);
    return this.data;
  }

  setChartOfAccountsColumnIndexByPageIndexTableIndexColumnIndex(
    pageIndex,
    tableIndex,
    columnIndex
  ) {
    const metaDataJson = this.getMetaDataJsonByPageIndexTableIndex(
      pageIndex,
      tableIndex
    );

    const currentChartOfAccountsColumnIndex = this.getChartOfAccountsColumnIndexByPageIndexTableIndex(
      pageIndex,
      tableIndex
    );

    // unset the current key
    _.set(
      metaDataJson,
      `headers[${currentChartOfAccountsColumnIndex}].key`,
      CHART_OF_ACCOUNTS_AMOUNT_KEY
    );

    // set the new `account` key
    _.set(
      metaDataJson,
      `headers[${columnIndex}].key`,
      CHART_OF_ACCOUNTS_ACCOUNT_KEY
    );

    return this.data;
  }

  isStatusCompleted() {
    return this.data?.status === documentStatus.COMPLETED;
  }

  isPublished() {
    return !!this.data?.last_published_at;
  }

  hasChangesAfterPublish() {
    return (
      !!this.data?.last_published_at &&
      this.data.last_published_at !== this.data.updated_at
    );
  }

  hasAllTablesQualified() {
    const headers = this.data?.meta_data_json?.headers || [];
    const qualified = headers.filter(header => header.qualified === true);
    if (headers.length === qualified.length) {
      return true;
    }
    return false;
  }

  setAllTablesQualified(value) {
    const headers = this.data?.meta_data_json?.headers || [];
    headers.forEach(header => (header.qualified = value));
    return this.data;
  }

  hasNoTablesQualified() {
    const headers = this.data?.meta_data_json?.headers || [];
    const qualified = headers.filter(header => header.qualified === true);
    if (qualified.length === 0) {
      return true;
    }
    return false;
  }

  isExcel() {
    return (
      this.data.file_extension === "xlsx" || this.data.file_extension === "xls"
    );
  }

  isPdf() {
    return this.data.file_extension === "pdf";
  }

  getExcelSheet(pageIndex) {
    return this.data.raw_data_json.pages[pageIndex].tableData[0];
  }

  // Excel documents are only supposed to have one qualified header ie. only 1 sheet active at a time.
  hasOneExcelSheetQualified() {
    const metaDataJson = this.getMetaDataJson();
    const qualified = metaDataJson.headers.filter(
      header => header.qualified === true
    );
    return qualified.length === 1;
  }

  getExcelSheetNames() {
    return this.data.excel_workbook.SheetNames;
  }

  /**
   * Gets the TableData for a given pageIndex and tableIndex.
   * You can supply it optional arguments to augment the tableData.
   * The main usecase for this is because excel documents do not have boundingBoxes
   * since they are not an image, so we created these bounding boxes elsewhere
   * treating the DOM like the image, and now that the document has bounding boxes, it
   * can take advantage of the SVG behaviors in <OpexTable>
   * @param {number} pageIndex
   * @param {number} tableIndex
   * @param {Object} options
   * @param {(Object|Array)} options.boundingBoxes
   * @returns Array
   */
  getTableDataForOpexTableByPageIndexTableIndex(
    pageIndex,
    tableIndex,
    options = {
      boundingBoxes: undefined
    }
  ) {
    const { boundingBoxes } = options;
    let nextTableData = [
      ...this.data.raw_data_json.pages[pageIndex].tableData[tableIndex]
    ];

    // Add bounding boxes to the excel sheet raw_data_json information to imitate images
    if (this.isExcel() && boundingBoxes) {
      const merged = nextTableData.map((arr, i) => {
        return arr.map((obj, j) => {
          return Object.assign({}, obj, boundingBoxes[i][j]);
        });
      });
      nextTableData = merged;
    }

    return nextTableData;
  }
}

export default OpexDocument;
