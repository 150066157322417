import useSWR from "swr";
import updateData from "../helpers/updateData";
import fetchData from "../helpers/fetchData";
import useSplitPanelV2Context from "./useSplitPanelV2Context";

export default function useDocument() {
  const { documentId, widgetAuth } = useSplitPanelV2Context();

  const { data, error, mutate } = useSWR(
    `/api/get-document/${documentId}`,
    fetchData(widgetAuth)
  );

  async function updateDocumentCell(field) {
    await updateData({
      path: `/api/rent-roll/update-field/${documentId}`,
      method: "put",
      data: { field },
      widgetAuth
    });
  }

  async function updateVacantFlagCell(field) {
    await updateData({
      path: `/api/rent-roll/update-vacant-flag/${documentId}`,
      method: "put",
      data: { field },
      widgetAuth
    });
  }

  async function initializedVacantFlag(data) {
    await updateData({
      path: `/api/rent-roll/init-vacant-flag/${documentId}`,
      method: "put",
      data,
      widgetAuth
    });
  }

  async function update(fields) {
    await updateData({
      path: `/api/documents/update/${documentId}`,
      method: "put",
      data: { fields },
      widgetAuth
    });
  }

  async function updateTemplate(data) {
    await updateData({
      path: `/api/rent-roll/update-template/${documentId}`,
      method: "put",
      data,
      widgetAuth
    });
  }

  async function ignoreSingleTable(data) {
    await updateData({
      path: `/api/rent-roll/ignore-table/${documentId}`,
      method: "put",
      data,
      widgetAuth
    });
  }

  return {
    document: data ? data.document : null,
    updateDocumentCell,
    updateVacantFlagCell,
    initializedVacantFlag,
    update,
    updateTemplate,
    ignoreSingleTable,
    error,
    mutate,
    isLoading: !error && !data
  };
}
