import { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import PdfMarkupView from "./components/PdfMarkupView";
import PdfEditor from "./components/PdfEditor";
import Popup from "reactjs-popup";
import {
  getPageImage,
  getPageTableData
} from "ui/components/rentRoll/splitPanelV2/hooks/usePageData";
import { scrollToCellOnDocument } from "../../../../helpers/navigation";
import useSplitPanelV2Context from "ui/components/rentRoll/splitPanelV2/hooks/useSplitPanelV2Context";
import { hasSuperAdminAccess } from "helpers/authorization";
import Button from "ui/components/shared/Button";
import PageNavigation from "./components/PageNavigation";
import Spinner from "ui/components/shared/Spinner";
import { generateTableKey } from "../../../../helpers/tableKeys";
export default function PdfDocument({
  onSelectTable,
  onGotoPage,
  activeDocumentTables,
  currentPageIndex,
  currentHeaderIndex,
  documentMetadata,
  rentRoll,
  formState,
  setDocumentMetadata,
  numPages,
  documentTables,
  onSelectedCell,
  selected,
  refreshData,
  selectedRentRollCell,
  setSelectedRentRollCell
}) {
  const containerRef = useRef();
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [imageMaxWidth, setImageMaxWidth] = useState();
  const [leftMargin, setLeftMargin] = useState(0);
  const currentTableMetadata =
    (documentMetadata && documentMetadata[currentHeaderIndex]) || {};
  const currentTable = documentTables[currentHeaderIndex] || {};
  const headers = currentTableMetadata.headers || [];
  const {
    ui: { rentRollHeight },
    userRole
  } = useSplitPanelV2Context();

  useEffect(() => {
    // goToPage, scroll to selected. highlight row and column if selected is header
    scrollToCellOnDocument(selected, false, selected);
  }, [selected, currentPageIndex, selectedRentRollCell]);

  useEffect(() => {
    if (currentHeaderIndex === undefined) return;
    const key = generateTableKey({
      headerIndex: currentHeaderIndex,
      pageIndex: currentPageIndex,
      tableIndex: currentTable.tableIndex || 0,
      columnIndex: 0,
      rowIndex: 0
    });
    scrollToCellOnDocument(key, false, key);
  }, [currentHeaderIndex, currentPageIndex, activeDocumentTables]);

  const page = getPageTableData(currentPageIndex);
  const pageImage = getPageImage(currentPageIndex);

  useEffect(() => {
    setIsImageLoaded(false);
  }, [currentPageIndex]);

  const imageHasLoaded = e => {
    setIsImageLoaded(true);
    setImageMaxWidth(e.target.naturalWidth);
  };

  return (
    <div
      className="SplitPanelV2__Document SplitPanelV2__PdfDocument"
      style={{
        height:
          activeDocumentTables[currentHeaderIndex] && `${100 - rentRollHeight}%`
      }}
    >
      {hasSuperAdminAccess(userRole) && (
        <div
          className={cx("SplitPanelV2__Document__open-editor-button", {
            "SplitPanelV2__Document__open-editor-button--multiple-pages":
              numPages > 1
          })}
        >
          <Popup
            trigger={
              <Button
                className={"SplitPanelV2__PdfDocument__edit-document-button"}
              >
                Open Image Editor
              </Button>
            }
            contentStyle={{ margin: "0", width: "0", padding: "0" }}
            arrow={false}
          >
            {close => (
              <PdfEditor
                pageImage={pageImage}
                currentPageIndex={currentPageIndex}
                closeEditor={close}
                refreshData={refreshData}
              />
            )}
          </Popup>
        </div>
      )}
      <PageNavigation
        numPages={numPages}
        onGotoPage={onGotoPage}
        currentPageIndex={currentPageIndex}
      />
      <div
        className="SplitPanelV2__PdfDocument__document-container"
        style={{ paddingLeft: leftMargin, paddingTop: numPages > 1 ? 44 : 0 }}
      >
        <div
          className="SplitPanelV2__PdfDocument__document"
          ref={containerRef}
          style={{
            maxWidth: imageMaxWidth
          }}
        >
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              backgroundColor: "none"
            }}
          >
            {
              <PdfMarkupView
                onSelectTable={onSelectTable}
                visible={isImageLoaded}
                setLeftMargin={setLeftMargin}
                formState={formState}
                documentTables={documentTables}
                setDocumentMetadata={setDocumentMetadata}
                documentMetadata={documentMetadata}
                rentRoll={rentRoll}
                onSelectedCell={onSelectedCell}
                setSelectedRentRollCell={setSelectedRentRollCell}
                activeDocumentTables={activeDocumentTables}
                page={page}
                headers={headers}
                selected={selected}
                currentPageIndex={currentPageIndex}
                currentHeaderIndex={currentHeaderIndex}
                parentElement={containerRef.current}
              />
            }
          </div>
          {!isImageLoaded && (
            <Spinner
              type="ThreeDots"
              classname="SplitPanelV2__PdfDocument__spinner"
              color="#00BFFF"
              height={40}
              width={40}
            />
          )}
          <img
            className="SplitPanelV2__PdfDocument__image"
            src={pageImage}
            onLoad={imageHasLoaded}
            style={{ width: "100%", opacity: isImageLoaded ? 1 : 0 }}
          />
        </div>
      </div>
    </div>
  );
}

PdfDocument.propTypes = {
  onSelectTable: PropTypes.func.isRequired,
  onGotoPage: PropTypes.func.isRequired,
  tableActive: PropTypes.bool,
  activeDocumentTables: PropTypes.array,
  selectedRentRollCell: PropTypes.string,
  setSelectedRentRollCell: PropTypes.func.isRequired,
  currentPageIndex: PropTypes.number.isRequired,
  currentHeaderIndex: PropTypes.number,
  documentMetadata: PropTypes.arrayOf(
    PropTypes.shape({
      rowIds: PropTypes.arrayOf(PropTypes.number),
      headers: PropTypes.array.isRequired
    }).isRequired
  ),
  documentTables: PropTypes.arrayOf(
    PropTypes.shape({
      headerIndex: PropTypes.number.isRequired,
      pageIndex: PropTypes.number.isRequired,
      tableIndex: PropTypes.number.isRequired
    })
  ),
  formState: PropTypes.string.isRequired,
  onSelectedCell: PropTypes.func.isRequired,
  selected: PropTypes.string,
  refreshData: PropTypes.func,
  rentRoll: PropTypes.array,
  setDocumentMetadata: PropTypes.func.isRequired,
  numPages: PropTypes.number.isRequired
};
