// switching to extension of XLSX, documentation can be found here https://github.com/gitbrent/xlsx-js-style
import XLSX from "xlsx-js-style";
import _ from "lodash";
import dayjs, {
  PERIOD_DATE_FORMAT,
  NO_DATE_TEXT
} from "ui/components/helpers/dayjs";
import {
  methodPrepareCPM,
  statementTypeCPM,
  methodPrepareCL,
  statementTypeCL
} from "helpers/opex";
import { getFormattedRow } from "./helpers/excelFormatting";
import {
  isCPMByGroupName,
  PERIOD_STATUS_IGNORED
} from "ui/components/opex/shared";
import { isBlank } from "helpers/presence";
import { whiteFillColor, subcategoryColor } from "./helpers/excelFormatting";
import { processOpexDataCL } from "./helpers/processExcelOpexDataCL";
import { processOpexDataCPM } from "./helpers/processExcelOpexDataCPM";
import { processSubcategoryViewCL } from "./helpers/processSubcategoryViewCL";
import { processSubcategoryViewCPM } from "./helpers/processSubcategoryViewCPM";
import { getPropertyValues } from "ui/components/properties/propertiesUtil";
import { getItemsByType } from "./helpers/processRows";
import { deepCopy } from "services/helpers/objectUtils";

const PERIOD_DATES_COL_WIDTH = 25;
const PERIOD_METRICS = {
  totalIncome: "Total Income",
  totalExpenses: "Total OpEx",
  noi: "NOI",
  totalCapex: "Total CapEx",
  netCashFlow: "Net Cash Flow"
};

function removeTotalsFromPerformanceSummary(performanceSummaryOpexData) {
  const accounts = getItemsByType(performanceSummaryOpexData, "account");
  const categories = getItemsByType(
    performanceSummaryOpexData,
    "category"
  ).filter(cat => {
    return cat.children.length > 0;
  });
  const subcategories = getItemsByType(
    performanceSummaryOpexData,
    "subcategory"
  );
  const subcategoriesBeyondLvlTwo = subcategories.filter(subcategory => {
    return subcategory.beyondLvlTwo === true;
  });
  const subcategoriesLvlOne = subcategories.filter(subcategory => {
    return subcategory.beyondLvlTwo !== true;
  });
  accounts.forEach(a => delete a.totals);
  categories.forEach(c => delete c.totals);
  subcategories.forEach(sc => delete sc.totals);
  subcategoriesBeyondLvlTwo.forEach(sc => delete sc.totals);
  subcategoriesLvlOne.forEach(sc => delete sc.totals);
}

function extractPerformanceSummary(opexTableData, ignoredColumnsIdx) {
  if (opexTableData[0].name === "Performance Summary") {
    // we don't need the totals for performance summary
    removeTotalsFromPerformanceSummary([opexTableData[0]]);
    // performance summary is the first element of our tableData array
    const performanceSummary = opexTableData.shift();
    const performanceSummaryAccounts = getItemsByType(
      [performanceSummary],
      "account"
    );
    const performanceSummarySubcategories = getItemsByType(
      [performanceSummary],
      "subcategory"
    );
    const subcategoriesBeyondLvlTwo = performanceSummarySubcategories.filter(
      subcategory => {
        return subcategory.beyondLvlTwo === true;
      }
    );
    // we map the subcategory level two names to the account values instead of the account names
    const performanceSummaryNamesAndValues = performanceSummaryAccounts.map(
      (x, i) => {
        return [subcategoriesBeyondLvlTwo[i].name, x.values];
      }
    );

    const filterIgnoredValues = columns => {
      return columns.reduce((arr, column, index) => {
        if (!ignoredColumnsIdx.includes(index))
          arr.push(parseFloat(column.value));
        return arr;
      }, []);
    };

    const exportedSummary = performanceSummaryNamesAndValues.map(metric => {
      return [getFormattedRow(metric[0], whiteFillColor, "bold")].concat(
        filterIgnoredValues(metric[1]).map(value => {
          return getFormattedRow(value, whiteFillColor, "numericValue");
        })
      );
    });

    return exportedSummary;
  }
}

function extractNoiAndNcf(opexPeriods) {
  const filterIgnoredMetrics = (periods, metric) => {
    return periods.reduce((arr, period) => {
      if (
        period.periodStatus !== PERIOD_STATUS_IGNORED &&
        period.metrics &&
        period.metrics[metric]
      )
        arr.push(parseFloat(period.metrics[metric]));
      return arr;
    }, []);
  };
  const NOI = [
    getFormattedRow("Net Operating Income", subcategoryColor, "bold")
  ].concat(
    filterIgnoredMetrics(opexPeriods, "noi").map(value => {
      return getFormattedRow(value, subcategoryColor, "boldNumericValue");
    })
  );
  const NCF = [
    getFormattedRow("Net Cash Flow", subcategoryColor, "bold")
  ].concat(
    filterIgnoredMetrics(opexPeriods, "netCashFlow").map(value => {
      return getFormattedRow(value, subcategoryColor, "boldNumericValue");
    })
  );
  return { NOI: NOI, NCF: NCF };
}

export function exportOpexExcel(
  opex,
  fileName,
  groupName,
  property,
  isManageFilesView = false
) {
  try {
    const propertyName = isManageFilesView
      ? property.propertyName
      : property.name;

    const streetProp = isManageFilesView ? "street1" : "street_1";
    const postalCodeProp = isManageFilesView ? "postalCode" : "postal_code";
    const propertyAddress = [
      !isBlank(property[streetProp]) ? property[streetProp] : "",
      !isBlank(property.city) ? ", " + property.city : "",
      !isBlank(property.state) ? ", " + property.state : "",
      !isBlank(property[postalCodeProp]) ? " " + property[postalCodeProp] : ""
    ].join("");

    const propertyTypes = getPropertyValues(!isCPMByGroupName(groupName));
    const getPropertyTypeName = id => {
      const PropertyType = propertyTypes.filter(item => item.id === id);
      return PropertyType[0] ? PropertyType[0].name : id;
    };
    const propertyType = isManageFilesView
      ? getPropertyTypeName(property.propertyType)
      : getPropertyTypeName(property.property_type);

    const opexPeriods = deepCopy(opex.periods);
    const opexTableData = deepCopy(opex.tableGroups[0].tableData);
    const ignoredColumnsIdx = opexPeriods.reduce((arr, period, index) => {
      if (period.periodStatus === PERIOD_STATUS_IGNORED) {
        arr.push(index);
      }
      return arr;
    }, []);
    // get period dates and metrics to add to header
    const headerMetrics = processHeaderMetrics(opexPeriods, groupName);
    // process the remaining table data, stored as an array of arrays with each
    // array representing one row on the table in step 3

    // handle hotel case here
    const performanceSummary = extractPerformanceSummary(
      opexTableData,
      ignoredColumnsIdx
    );
    const NoiAndNcf = extractNoiAndNcf(opexPeriods);
    const processed_data = isCPMByGroupName(groupName)
      ? processOpexDataCPM(opexTableData, ignoredColumnsIdx, NoiAndNcf)
      : processOpexDataCL(opexTableData, ignoredColumnsIdx, NoiAndNcf);

    const opexTableDataSubcategoryView = deepCopy(opexTableData);

    const line_item_view = XLSX.utils.book_new();
    const subcategory_view = XLSX.utils.book_new();

    XLSX.utils.sheet_add_aoa(
      line_item_view,
      [
        [getFormattedRow(propertyName, null, "boldItalicized")],
        [getFormattedRow(propertyAddress, null, "boldItalicized")],
        [getFormattedRow(propertyType, null, "boldItalicized")]
      ],
      {
        origin: "A1"
      }
    );

    XLSX.utils.sheet_add_aoa(
      subcategory_view,
      [
        [getFormattedRow(propertyName, null, "boldItalicized")],
        [getFormattedRow(propertyAddress, null, "boldItalicized")],
        [getFormattedRow(propertyType, null, "boldItalicized")]
      ],
      {
        origin: "A1"
      }
    );

    // adds the period dates and metrics
    XLSX.utils.sheet_add_aoa(line_item_view, headerMetrics, {
      origin: "A5"
    });
    XLSX.utils.sheet_add_aoa(subcategory_view, headerMetrics, {
      origin: "A5"
    });

    // add performance summary data if its there
    if (performanceSummary) {
      XLSX.utils.sheet_add_aoa(line_item_view, performanceSummary, {
        origin: "A" + (headerMetrics.length + 6).toString()
      });
      XLSX.utils.sheet_add_aoa(subcategory_view, performanceSummary, {
        origin: "A" + (headerMetrics.length + 6).toString()
      });
    }

    // add the remaining account and subcategory/category data
    XLSX.utils.sheet_add_json(line_item_view, processed_data, {
      origin:
        "A" +
        (performanceSummary
          ? (headerMetrics.length + performanceSummary.length + 7).toString()
          : (headerMetrics.length + 6).toString()),
      skipHeader: true,
      dateNF: "MM-DD-YYYY",
      cellDates: true
    });

    // format column width for account names based on the longest account name
    const max_width_accounts = processed_data.reduce(
      (max, r) => Math.max(max, r[0].v.length),
      20
    );

    // this sets the column width based on the longest string in each column
    line_item_view["!cols"] = [
      { wch: max_width_accounts },
      ...new Array(headerMetrics[0].length).fill({
        wch: PERIOD_DATES_COL_WIDTH
      })
    ];

    // handle the subcategory only view
    const processed_subcategory_view = isCPMByGroupName(groupName)
      ? processSubcategoryViewCPM(
          opexTableDataSubcategoryView,
          ignoredColumnsIdx,
          NoiAndNcf
        )
      : processSubcategoryViewCL(
          opexTableDataSubcategoryView,
          ignoredColumnsIdx,
          NoiAndNcf
        );

    XLSX.utils.sheet_add_json(subcategory_view, processed_subcategory_view, {
      origin:
        "A" +
        (performanceSummary
          ? (headerMetrics.length + performanceSummary.length + 7).toString()
          : (headerMetrics.length + 6).toString()),
      skipHeader: true,
      dateNF: "MM-DD-YYYY",
      cellDates: true
    });

    const max_width_subcategories = processed_subcategory_view.reduce(
      (max, r) => Math.max(max, r[0].v.length),
      20
    );

    // this sets the column width based on the longest string in each column
    subcategory_view["!cols"] = [
      { wch: max_width_subcategories },
      ...new Array(headerMetrics[0].length).fill({
        wch: PERIOD_DATES_COL_WIDTH
      })
    ];

    // finally, write the file with line item and subcategory views on separate sheets
    const wb = {
      Sheets: {
        "Statement with Line Items": line_item_view,
        "Statement by Subcategories": subcategory_view
      },
      SheetNames: ["Statement with Line Items", "Statement by Subcategories"]
    };
    XLSX.writeFile(wb, fileName);
  } catch (e) {
    throw new Error("Error while downloading excel - " + e);
  }
}

const processHeaderMetrics = (opexPeriods, groupName) => {
  const filterIgnoredMetrics = (periods, metric) => {
    return periods.reduce((arr, period) => {
      if (
        period.periodStatus !== PERIOD_STATUS_IGNORED &&
        period.metrics &&
        period.metrics[metric]
      )
        arr.push(parseFloat(period.metrics[metric]));
      return arr;
    }, []);
  };

  const filterIgnoredPeriodDates = (periods, metric) => {
    return periods.reduce((arr, period) => {
      if (period.periodStatus !== PERIOD_STATUS_IGNORED && period[metric])
        arr.push(period[metric]);
      else if (period.periodStatus !== PERIOD_STATUS_IGNORED && !period[metric])
        arr.push(NO_DATE_TEXT);
      return arr;
    }, []);
  };

  const filterIgnoredHeaderInfo = (periods, method, headerNameByGroupName) => {
    return periods.reduce((arr, period) => {
      if (
        period.periodStatus !== PERIOD_STATUS_IGNORED &&
        period[method] !== null
      )
        arr.push(
          _.find(headerNameByGroupName, { value: period[method] })?.label
        );
      return arr;
    }, []);
  };

  const periodStartDates = [
    getFormattedRow("Start Date", whiteFillColor, "bold")
  ].concat(
    filterIgnoredPeriodDates(opexPeriods, "startDate").map(date => {
      if (date !== NO_DATE_TEXT) {
        return getFormattedRow(
          dayjs.utc(date).format(PERIOD_DATE_FORMAT),
          whiteFillColor,
          "stringValue"
        );
      } else {
        return getFormattedRow(NO_DATE_TEXT, whiteFillColor, "stringValue");
      }
    })
  );

  const periodEndDates = [
    getFormattedRow("End Date", whiteFillColor, "bold")
  ].concat(
    filterIgnoredPeriodDates(opexPeriods, "endDate").map(date => {
      if (date !== NO_DATE_TEXT) {
        return getFormattedRow(
          dayjs.utc(date).format(PERIOD_DATE_FORMAT),
          whiteFillColor,
          "stringValue"
        );
      } else {
        return getFormattedRow(NO_DATE_TEXT, whiteFillColor, "stringValue");
      }
    })
  );

  const headerMetrics = Object.keys(PERIOD_METRICS).map(metric => {
    return [
      getFormattedRow(PERIOD_METRICS[metric], whiteFillColor, "bold")
    ].concat(
      filterIgnoredMetrics(opexPeriods, metric).map(value => {
        return getFormattedRow(value, whiteFillColor, "numericValue");
      })
    );
  });

  const methodPrepared = [
    getFormattedRow("Statement Type", whiteFillColor, "bold")
  ].concat(
    filterIgnoredHeaderInfo(
      opexPeriods,
      "methodPrepared",
      isCPMByGroupName(groupName) ? methodPrepareCPM : methodPrepareCL
    ).map(value => {
      return getFormattedRow(value, whiteFillColor, "stringValue");
    })
  );

  const statementType = [
    getFormattedRow("Period Type", whiteFillColor, "bold")
  ].concat(
    filterIgnoredHeaderInfo(
      opexPeriods,
      "statementType",
      isCPMByGroupName(groupName) ? statementTypeCPM : statementTypeCL
    ).map(value => {
      return getFormattedRow(value, whiteFillColor, "stringValue");
    })
  );

  return [
    periodStartDates,
    periodEndDates,
    methodPrepared,
    statementType,
    ...headerMetrics
  ];
};
