import React from "react";
import PropTypes from "prop-types";
import { FaPencilAlt, FaTrash, FaPlus, FaCheck, FaBan } from "react-icons/fa";
import cx from "classnames";

export default class EditableCell extends React.PureComponent {
  constructor(props) {
    super(props);
    this.duplicateAlertRef = React.createRef();
  }

  state = {
    editing: false,
    value: this.props.cellData,
    editingValue: "",
    duplicated: false,
    showingDuplicateAlert: false
  };

  componentDidMount() {
    if (this.props.rowData.justAdded) this.setState({ editing: true });
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = event => {
    if (
      this.duplicateAlertRef &&
      !this.duplicateAlertRef.current.contains(event.target)
    ) {
      this.setState({ showingDuplicateAlert: false });
    }
  };

  handleClick = () =>
    this.setState({
      editing: true,
      editingValue: this.state.value
    });

  handleChange = e => {
    const { value } = e.target;
    this.setState({
      editingValue: value
    });

    const rows = this.props.container.props.data;
    const children = rows.find(row => row.id === this.props.rowData.parentId)
      .children;

    if (
      !value ||
      !children ||
      children.length === 0 ||
      !children.some(
        sibling => sibling.name.toLowerCase() === value.toLowerCase()
      )
    )
      this.setState({
        duplicated: false,
        showingDuplicateAlert: false
      });
    else
      this.setState({
        duplicated: true,
        showingDuplicateAlert: true
      });
  };

  handleSave = (column, rowData) => {
    this.setState({ value: this.state.editingValue, editing: false });
    column.rowUpdate(
      Object.assign({}, rowData, { name: this.state.editingValue })
    );
  };

  handleCancel = () => {
    this.setState({
      editing: false
    });
  };

  handleDelete = (column, rowData) => {
    column.rowDelete(rowData);
  };

  handleAdd = (column, rowData) => {
    column.rowAdd(rowData.id);
  };

  render() {
    const { column, rowData } = this.props;
    return (
      <div className="cell-container">
        {this.state.editing && (
          <div
            className={cx(
              "cell-container__editBox",
              this.state.duplicated && "cell-container__editBox--duplicated"
            )}
          >
            <input
              type="text"
              value={this.state.editingValue}
              onChange={this.handleChange}
            />
            {!this.state.duplicated && (
              <FaCheck
                className="cell-container__editBox__checkMark"
                onClick={() => this.handleSave(column, rowData)}
              />
            )}
            <FaBan
              onClick={this.handleCancel}
              className="cell-container__editBox__cancelButton"
            />
            {this.state.showingDuplicateAlert && (
              <div
                ref={this.duplicateAlertRef}
                className="cell-container__editBox__DuplicationAlert"
              >
                Label/Name already in use
              </div>
            )}
          </div>
        )}
        {!this.state.editing && (
          <p className="cell-container__value">{this.state.value}</p>
        )}
        {column.canEdit && (
          <div className="cell-container__options">
            {rowData.parentId && (
              <>
                <FaTrash
                  onClick={() => this.handleDelete(column, rowData)}
                  className="cell-container__options__deleteButton"
                />
                {!this.state.editing && (
                  <FaPencilAlt
                    onClick={this.handleClick}
                    className="cell-container__options__editButton"
                  />
                )}
              </>
            )}
            {!rowData.parentId && (
              <span
                className="cell-container__options__addButton"
                onClick={() => this.handleAdd(column, rowData)}
              >
                <FaPlus />
                Sub category
              </span>
            )}
          </div>
        )}
      </div>
    );
  }
}

EditableCell.propTypes = {
  cellData: PropTypes.string.isRequired,
  rowData: PropTypes.shape({
    parentId: PropTypes.number,
    id: PropTypes.any,
    justAdded: PropTypes.bool
  }).isRequired,
  column: PropTypes.shape({
    rowAdd: PropTypes.func,
    rowDelete: PropTypes.func,
    rowUpdate: PropTypes.func,
    canEdit: PropTypes.bool
  }).isRequired,
  container: PropTypes.shape({
    props: PropTypes.shape({
      data: PropTypes.array
    })
  })
};
