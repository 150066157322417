import PropTypes from "prop-types";

import PredefOption from "./PredefOption";

const CellUpdatePredefOptions = ({
  selected,
  updateSelected,
  predefValues
}) => (
  <div style={{ marginBottom: "15px" }}>
    {predefValues.map((value, idx) => (
      <PredefOption
        key={value.value}
        idx={idx}
        value={value}
        selected={selected}
        updateSelected={updateSelected}
      />
    ))}
  </div>
);

CellUpdatePredefOptions.propTypes = {
  selected: PropTypes.number,
  predefValues: PropTypes.array,
  updateSelected: PropTypes.func
};

export default CellUpdatePredefOptions;
