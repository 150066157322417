import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { updatePageIndex } from "ui/store/actions/rentRoll";

import { getTotalPages } from "ui/components/rentRoll/splitPanel/helpers/processing";

const FormPageChanger = ({ rentRollDocument, pageIndex, updatePageIndex }) => {
  const [page, setPage] = useState(1);

  const setPageState = e => {
    setPage(e.target.value);
  };

  useEffect(() => {
    setPage(pageIndex + 1);
  }, [pageIndex]);

  const goToPage = e => {
    e.preventDefault();
    if (page < 1 || page > getTotalPages(rentRollDocument)) {
      setPage(pageIndex + 1);
    } else {
      updatePageIndex(page - 1);
    }
  };

  return (
    <form style={{ display: "flex" }} onSubmit={goToPage}>
      <input
        type="number"
        name="number"
        value={page}
        onChange={setPageState}
        onSubmit={goToPage}
        style={{ width: "35px" }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: "5px"
        }}
      >{`/ ${getTotalPages(rentRollDocument)}`}</div>
    </form>
  );
};

FormPageChanger.propTypes = {
  updatePageIndex: PropTypes.func,
  rentRollDocument: PropTypes.object,
  pageIndex: PropTypes.number
};

const mapStateToProps = ({ rentRoll }) => ({
  rentRollDocument: rentRoll.document,
  pageIndex: rentRoll.pageIndex
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updatePageIndex }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FormPageChanger);
