import PropTypes from "prop-types";
import Loader from "react-loader-spinner";
import cx from "classnames";

export default function Spinner({ classname, type, color, height, width }) {
    return (
        <Loader className={cx("Loader", classname)} type={type} color={color} height={height} width={width} />
    );
}

Spinner.propTypes = {
    className: PropTypes.string,
    type: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    height: PropTypes.number,
    width: PropTypes.number
};



