import { useState, useEffect } from "react";
import { connect } from "react-redux";

import {
  fetchPredefValues,
  createPredefValue,
  deletePredefValue
} from "ui/components/admin/helpers/api";

import { useLocalStorage } from "ui/hooks/useLocalStorage";
import AdminPredefRow from "./AdminPredefRow";
import Button from "ui/components/shared/Button";

import { getPropertyValues } from "ui/components/properties/propertiesUtil";

const fields = ["space_type"];

const AdminPredefForm = () => {
  const propertyTypes = getPropertyValues();
  const [propertyType, setPropertyType] = useLocalStorage(
    "propertyType",
    propertyTypes[0].id
  );
  const [fieldType, setFieldType] = useState(fields[0]);
  const [predefs, setPredefs] = useState([]);

  /**
   * API call to fetch the predef values
   */
  useEffect(() => {
    (async () => {
      const predefValues = await fetchPredefValues(propertyType, fieldType);
      await setPredefs([...predefValues]);
    })();
  }, [propertyType, fieldType]);

  /**
   * Sets the propertyType based on what the user clicks in the select dropdown
   */
  const onChangeProperty = e => {
    let value = e.target.value;
    setPropertyType(value);
  };

  /**
   * Deletes a predef value by calling deletePredefValue() api
   * @param {string} id of the predef value
   */
  const handleDelete = id => {
    const newList = predefs.filter(item => item.id !== id);
    deletePredefValue(id);
    setPredefs(newList);
  };

  /**
   * Alerts the user to confirm if they really want to delete a predef value
   * @param {string} id
   * @param {string} value
   * @returns a bool and a calls handleDelete if the user confirms delete
   */
  const confirmDeleteAlert = (id, value) => {
    let r = confirm(
      "Are you sure you want to delete the predef value: " + value + " ?"
    );
    if (r == true) {
      handleDelete(id);
      return true;
    } else {
      return false;
    }
  };

  /**
   * Alerts the user if they are submitting a duplicate predef value
   * @param {string} newValue
   * @param {evnet} e
   * @returns a bool
   */
  const hasDuplicates = (newValue, e) => {
    for (let i = 0; i < predefs.length; i++) {
      if (predefs[i].value === newValue) {
        alert("Value already exists, please create a different value");
        e.preventDefault();
        return true;
      }
    }
    return false;
  };

  /**
   * creates a new predef value by calling createPredefValue() api
   * @param {event} e
   */
  const handleCreateNewPredef = e => {
    let newValue = document.getElementById("newPredefValue").value.trim();
    const hasDuplicate = hasDuplicates(newValue, e);
    const newValues = predefs;

    if (!newValue) {
      alert("Please submit a valid value");
      e.preventDefault();
    } else if (!hasDuplicate) {
      createPredefValue(newValue, fieldType, propertyType);
      document.getElementById("newPredefValue").value = "";
      newValues.push(newValue);
      setPredefs([...newValues]);
    }
  };

  return (
    <div className="AdminPredefForm__return" style={{ padding: "40px" }}>
      <h1 className="return__title">Predef Values</h1>
      <label className="return__propLabel">Property Type:</label>
      <select
        className="return__propSelect"
        id="select-property-type"
        style={{
          marginBottom: "20px"
        }}
        value={propertyType}
        onChange={e => onChangeProperty(e)}
      >
        {propertyTypes.map(type => (
          <option key={type.id} value={type.id}>
            {type.id.toLocaleUpperCase()}
          </option>
        ))}
      </select>
      <label className="return__fieldLabel">Field:</label>
      <select
        className="return__fieldSelect"
        id="select-field-type"
        style={{
          marginBottom: "20px"
        }}
        value={fieldType}
        onChange={e => {
          setFieldType(e.target.value);
        }}
      >
        {fields.map(type => (
          <option key={type} value={type}>
            {type.toLocaleUpperCase()}
          </option>
        ))}
      </select>

      <ol className="AdmiPredefForm__List">
        {predefs.map(predefVal => {
          return (
            <li key={predefVal.id}>
              <AdminPredefRow
                predefValue={predefVal.value}
                predefId={predefVal.id}
                del={confirmDeleteAlert}
                duplicates={hasDuplicates}
              />
            </li>
          );
        })}

        <form className="Form" onSubmit={e => handleCreateNewPredef(e)}>
          <input
            id="newPredefValue"
            type="text"
            name="newPredefVal"
            placeholder="Create a new Predef Value"
          />
          <Button className="List__submitButton">Submit</Button>
        </form>
      </ol>
    </div>
  );
};

export default connect(null)(AdminPredefForm);
