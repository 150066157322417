import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import enhanceWithClickOutside from "react-click-outside";
import { clearFlash } from "ui/store/actions/flash";
import classNames from "classnames";

export class Flash extends Component {
  render() {
    const { type, message, clearFlash, close = true } = this.props;

    if (!type || !message) {
      return null;
    }
    const flashClass = classNames("Flash", `Flash--${type}`);

    return (
      <div className={flashClass}>
        <div className="Flash__message">{message}</div>
        {close && (
          <div className="Flash__close-flash-button-container">
            <img
              src={require("../../images/icon-close.svg")}
              className="Flash__close-flash-button"
              onClick={_ => {
                clearFlash();
              }}
            />
          </div>
        )}
      </div>
    );
  }
}

Flash.propTypes = {
  type: PropTypes.string,
  message: PropTypes.node,
  clearFlash: PropTypes.func,
  close: PropTypes.bool
};

function mapStateToProps(state) {
  const { flash } = state;
  return flash;
}

function mapDispatchToProps(dispatch) {
  return {
    clearFlash: () => dispatch(clearFlash())
  };
}

// const EnhancedFlash = enhanceWithClickOutside(Flash);
export default connect(mapStateToProps, mapDispatchToProps)(Flash);
