import { PropTypes } from "prop-types";
import { useEffect, useState } from "react";

const PageNavigation = ({ numPages, onGotoPage, currentPageIndex }) => {
  if (numPages <= 1) return null;

  const [value, setValue] = useState(currentPageIndex + 1);

  useEffect(() => {
    if (currentPageIndex + 1 !== value) {
      setValue(currentPageIndex + 1);
    }
  }, [currentPageIndex]);

  const handleValueChange = ({ target: { value } }) => {
    if ((value <= numPages && value > 0) || value === "") {
      setValue(value);
      if (value && value !== currentPageIndex) {
        onGotoPage(Number(value) - 1);
      }
    }
  };

  const handleValueBlur = ({ target: { value } }) => {
    if (value === "") {
      setValue(currentPageIndex + 1);
    }
  };

  return (
    <div className="SplitPanelV2__PageNavigation">
      <div className="SplitPanelV2__PageNavigation__controls">
        <button
          onClick={() => onGotoPage(currentPageIndex - 1)}
          disabled={currentPageIndex === 0}
        >
          <img
            width="24px"
            alt="Next Page"
            aria-label="Next Page"
            src={require("ui/images/icon-chevron-circle-left.svg")}
          />
        </button>
        <div>
          <input
            onBlur={handleValueBlur}
            onChange={handleValueChange}
            type="number"
            min={1}
            max={numPages}
            value={value}
          />
          / {numPages}
        </div>
        <button
          onClick={() => onGotoPage(currentPageIndex + 1)}
          disabled={currentPageIndex === numPages - 1}
        >
          <img
            width="24px"
            alt="Next Page"
            aria-label="Next Page"
            src={require("ui/images/icon-chevron-circle-right.svg")}
          />
        </button>
      </div>
    </div>
  );
};

PageNavigation.propTypes = {
  onGotoPage: PropTypes.func.isRequired,
  numPages: PropTypes.number.isRequired,
  currentPageIndex: PropTypes.number.isRequired
};

export default PageNavigation;
