import { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import cx from "classnames";
import { bindActionCreators } from "redux";
import { StickyTable, Row, Cell } from "react-sticky-table";

import NonEditableHeader from "./cells/NonEditableHeader";
import ActionCellForAddRow from "./cells/ActionCellForAddRow";
import ActionCellForDocRow from "./cells/ActionCellForDocRow";
import RawRowEditDate from "../popovers/rawRowEdit/editDate/RawRowEditDate";

import {
  updatePdfMarkdownLoading,
  addNewRowToRentRoll,
  removeRowFromRentRoll,
  undoRemoveRowFromRentRoll
} from "../../../../store/actions/rentRoll";
import { EndRentRollNav } from "../../../../store/actions/splitScreen";

import { markupCell, parseValue, parseDataType } from "../helpers/processing";

import { createDataObjForNewRow } from "../helpers/previewPane";
import { scrollToCellOnRentRoll } from "../helpers/navigation";

const PreviewPaneTable = ({
  document,
  previewTableConfig,
  tableRefs,
  dataList,
  addNewRowToRentRoll,
  removeRowFromRentRoll,
  undoRemoveRowFromRentRoll,
  isAddingRow,
  setIsAddingRow,
  markupConfig,
  handleScrollToCell,
  rentRollNav,
  EndRentRollNav,
  updateSelectedDocumentCell,
  updateSelectedRentRollCell,
  selectedRentRowCell,
  cellNavInProgress,
  documentNav,
  widgetAuth
}) => {
  const [rowFormData, setRowFormData] = useState({});

  const updateRowForm = (field, value) => {
    const newRowFormData = { ...rowFormData, [field]: value };
    setRowFormData(newRowFormData);
  };

  const submitRowForm = () => {
    const bodyData = createDataObjForNewRow(rowFormData);
    addNewRowToRentRoll(document.id, bodyData, widgetAuth);
  };

  useEffect(() => {
    if (rentRollNav && !cellNavInProgress && !documentNav) {
      scrollToCellOnRentRoll(rentRollNav, tableRefs);
      EndRentRollNav();
    }
  }, [rentRollNav, documentNav, cellNavInProgress]);

  return (
    <div className="PreviewPane__tableContainer">
      <StickyTable
        stickyHeaderCount={1}
        leftStickyColumnCount={1}
        rightStickyColumnCount={1}
        borderWidth={"1px"}
      >
        <Row>
          {previewTableConfig.map((column, hi) => {
            return (
              <NonEditableHeader
                key={column.key}
                column={column}
                tableRefs={tableRefs}
                className={cx(
                  "PreviewPaneTable__cell",
                  "PreviewPaneTable__header",
                  column.className,
                  {
                    ["PreviewPaneTable__tenantName"]: hi === 0
                  },
                  {
                    ["PreviewPaneTable__cell--notMapped__header"]: column.opaque
                  }
                )}
              />
            );
          })}
        </Row>
        {isAddingRow && (
          <Row>
            {previewTableConfig.map(column => {
              if (column.dataIndex === "actions") {
                return (
                  <ActionCellForAddRow
                    key={column.dataIndex}
                    submitRowForm={submitRowForm}
                    setIsAddingRow={setIsAddingRow}
                    setRowFormData={setRowFormData}
                  />
                );
              }
              if (
                column.dataIndex === "lease_start_date" ||
                column.dataIndex === "lease_end_date"
              ) {
                return (
                  <Cell
                    key={column.dataIndex}
                    className={cx(
                      "PreviewPaneTable__cell",
                      "PreviewPaneTable__newRowCell"
                    )}
                  >
                    <RawRowEditDate
                      dataIndex={column.dataIndex}
                      date={rowFormData[column.dataIndex]}
                      updateRowForm={updateRowForm}
                    />
                  </Cell>
                );
              }
              return (
                <Cell
                  key={column.dataIndex}
                  className={cx(
                    "PreviewPaneTable__cell",
                    "PreviewPaneTable__newRowCell"
                  )}
                >
                  <input
                    className="PreviewPaneTable__inputFieldNewRow"
                    style={{
                      width: "100%",
                      height: "100%",
                      border: "none",
                      textAlign:
                        column.dataIndex === "tenant_name" ? "left" : "right"
                    }}
                    name={column.dataIndex}
                    onChange={e =>
                      updateRowForm(column.dataIndex, e.target.value)
                    }
                  />
                </Cell>
              );
            })}
          </Row>
        )}
        {dataList.map(dataRow => {
          const headers = previewTableConfig.map(column => column.dataIndex);
          return (
            <Row
              key={dataRow.key}
              className={cx({
                "PreviewPaneTable__row--documentDel":
                  dataRow.status === "DOCUMENT_DEL"
              })}
            >
              {headers.map((header, hi) => {
                if (header === "actions") {
                  return (
                    <ActionCellForDocRow
                      key={dataRow[`key-${header}`]}
                      documentId={document.id}
                      dataRow={dataRow}
                      className={cx(
                        "PreviewPaneTable__cell",
                        "PreviewPaneTable__actionCell",
                        previewTableConfig[hi].className
                      )}
                      undoRemoveRowFromRentRoll={undoRemoveRowFromRentRoll}
                      removeRowFromRentRoll={removeRowFromRentRoll}
                      widgetAuth={widgetAuth}
                    />
                  );
                }
                return (
                  <Cell
                    key={dataRow[`key-${header}`]}
                    ref={ref =>
                      (tableRefs.current[dataRow[`key-${header}`]] = ref)
                    }
                    className={cx(
                      "PreviewPaneTable__cell",
                      previewTableConfig[hi].className,
                      markupCell(markupConfig, dataRow[`dockey-${header}`]),
                      {
                        ["PreviewPaneTable__cell--notMapped"]:
                          previewTableConfig[hi].opaque,
                        ["SplitPanel--cellFocused"]:
                          selectedRentRowCell === dataRow[`key-${header}`]
                      }
                    )}
                    onClick={() => {
                      console.log(dataRow);
                      const key = dataRow[`dockey-${header}`];
                      if (key) {
                        updateSelectedDocumentCell(dataRow[`dockey-${header}`]);
                        updateSelectedRentRollCell(dataRow[`key-${header}`]);
                        handleScrollToCell("to_document", key);
                      }
                    }}
                  >
                    {previewTableConfig &&
                    previewTableConfig[hi] &&
                    previewTableConfig[hi].dataType
                      ? parseDataType(
                          dataRow,
                          header,
                          previewTableConfig[hi].dataType
                        )
                      : parseValue(dataRow, header)}
                  </Cell>
                );
              })}
            </Row>
          );
        })}
      </StickyTable>
    </div>
  );
};

PreviewPaneTable.propTypes = {
  previewTableConfig: PropTypes.array,
  markupConfig: PropTypes.array,
  dataList: PropTypes.array,
  document: PropTypes.object,
  rentRollNav: PropTypes.string,
  tableRefs: PropTypes.object,
  handleRef: PropTypes.func,
  updatePdfMarkdownLoading: PropTypes.func,
  handleScrollToCell: PropTypes.func,
  addNewRowToRentRoll: PropTypes.func,
  removeRowFromRentRoll: PropTypes.func,
  undoRemoveRowFromRentRoll: PropTypes.func,
  isAddingRow: PropTypes.bool,
  setIsAddingRow: PropTypes.func,
  EndRentRollNav: PropTypes.func,
  updateSelectedDocumentCell: PropTypes.func,
  updateSelectedRentRollCell: PropTypes.func,
  selectedRentRowCell: PropTypes.string,
  cellNavInProgress: PropTypes.bool,
  documentNav: PropTypes.string,
  widgetAuth: PropTypes.object
};

const mapStateToProps = ({ splitScreen }) => ({
  rentRollNav: splitScreen.rentRollNav,
  documentNav: splitScreen.documentNav,
  cellNavInProgress: splitScreen.cellNavInProgress
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updatePdfMarkdownLoading,
      addNewRowToRentRoll,
      removeRowFromRentRoll,
      undoRemoveRowFromRentRoll,
      EndRentRollNav
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PreviewPaneTable);
