import { useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

const PredefOption = ({ value, idx, selected, updateSelected }) => {
  return (
    <div
      className={cx("Popover__popupButton", {
        unchecked: selected !== idx,
        checked: selected === idx
      })}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "5px"
      }}
      onClick={() => {
        updateSelected(idx);
      }}
    >
      {value.value}
    </div>
  );
};

PredefOption.propTypes = {
  value: PropTypes.object,
  idx: PropTypes.number,
  selected: PropTypes.number,
  updateSelected: PropTypes.func
};

export default PredefOption;
