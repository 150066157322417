import { useState } from "react";
import PropTypes from "prop-types";
import CustomDropdownLabel from "./CustomDropdownLabel"

const CustomSelectGroup = props => {
  const { children, className, cx, label } = props;
  const [expanded, setExpanded] = useState(true);

  return (
    <div className={cx({ group: true }, className)}>
      <div>
        <CustomDropdownLabel label={label} expanded={expanded} onClick={setExpanded}/>
      </div>
      <div style={{ display: expanded ? "block" : "none" }}>{children}</div>
    </div>
  );
};

export default CustomSelectGroup;

CustomSelectGroup.propTypes = {
  children: PropTypes.array,
  className: PropTypes.string,
  cx: PropTypes.func,
  label: PropTypes.string
};
