import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  fetchPredefSugestionList,
  predefSuggestionListSize
} from "./helpers/api";
import { getPredefField, getPredefsNeedAttention } from "./helpers/util";

import Suggestion from "./Suggestion";
import { organizeHeaderData } from "../../../helpers/processing";
import ThreeBounceSpinner from "ui/components/spinners/ThreeBounceSpinner";

const EntityResolution = ({
  rentRollDocument,
  rentRoll,
  changeView,
  setFieldParams,
  widgetAuth
}) => {
  const ref = useRef();
  const documentId = rentRollDocument.id;
  const [loading, setLoading] = useState(true);
  const [pageIdx, setPageIdx] = useState(0);
  const [predefSuggestionList, setPredefSuggestionList] = useState([]);
  const headerData = organizeHeaderData(rentRollDocument);
  const field = getPredefField(headerData);

  const handleScroll = e => {
    const predefsNeedAttention = getPredefsNeedAttention(
      rentRollDocument,
      rentRoll
    );
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      const predefsNeedAttentionText = predefsNeedAttention.map(
        predef => predef.text
      );
      const endIdx = Math.round(
        predefsNeedAttention.length / predefSuggestionListSize
      );
      if (pageIdx < endIdx) {
        (async () => {
          const list = await fetchPredefSugestionList(
            documentId,
            predefsNeedAttentionText,
            field,
            pageIdx + 1,
            widgetAuth
          );
          setPageIdx(pageIdx + 1);
          setPredefSuggestionList([...predefSuggestionList, ...list]);
        })();
      }
    }
  };

  useEffect(() => {
    const predefsNeedAttention = getPredefsNeedAttention(
      rentRollDocument,
      rentRoll
    );
    (async () => {
      const predefsNeedAttentionText = predefsNeedAttention.map(
        predef => predef.text
      );
      const list = await fetchPredefSugestionList(
        documentId,
        predefsNeedAttentionText,
        field,
        0,
        widgetAuth
      );
      setPageIdx(0);
      setPredefSuggestionList(list);
      setLoading(false);
    })();
  }, [rentRollDocument]);

  return (
    <div className="EntityResolution__container">
      <div
        ref={ref}
        className="EntityResolution__listContainer"
        onScroll={handleScroll}
      >
        {loading ? (
          <ThreeBounceSpinner />
        ) : (
          predefSuggestionList &&
          predefSuggestionList.length > 0 &&
          predefSuggestionList.map((suggestion, suggestionIdx) => (
            <Suggestion
              key={suggestion.value}
              documentId={documentId}
              suggestion={suggestion}
              suggestionIdx={suggestionIdx}
              rentRollDocument={rentRollDocument}
              rentRoll={rentRoll}
              changeView={changeView}
              setFieldParams={setFieldParams}
            />
          ))
        )}
      </div>
    </div>
  );
};

EntityResolution.defaultProps = {
  widgetAuth: {}
};

EntityResolution.propTypes = {
  rentRollDocument: PropTypes.object,
  rentRoll: PropTypes.array,
  changeView: PropTypes.func,
  setFieldParams: PropTypes.func,
  widgetAuth: PropTypes.object
};

const mapStateToProps = ({ rentRoll, currentUser }) => ({
  rentRollDocument: rentRoll.document,
  rentRoll: rentRoll.rentRoll,
  widgetAuth: currentUser.widgetAuth
});

export default connect(mapStateToProps)(EntityResolution);
