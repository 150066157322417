import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import EntityResolution from "./EntityResolution";
import Resolved from "./Resolved";

import { getPredefsNeedAttention } from "./helpers/util";

const EntityView = ({
  rentRollDocument,
  rentRoll,
  changeView,
  setFieldParams,
  selectedTab
}) => {
  const predefsNeedAttention = getPredefsNeedAttention(
    rentRollDocument,
    rentRoll
  );
  const numberNeedAttention = predefsNeedAttention
    ? predefsNeedAttention.length
    : 0;
  return (
    <>
      <div className="EntityView__header">
        {numberNeedAttention > 0 ? (
          <div>
            We identified{" "}
            <span style={{ fontWeight: "bold" }}>{numberNeedAttention}</span>
            {numberNeedAttention > 1
              ? ` issues that need `
              : ` issue that needs `}
            your attention.
          </div>
        ) : (
          <div>No issues need attention</div>
        )}
        <div className="EntityView__subheader">
          Please select the correct match, search or create a new record.
        </div>
      </div>
      <Tabs defaultIndex={selectedTab}>
        <TabList>
          <Tab>NEED ATTENTION</Tab>
          <Tab>RESOLVED</Tab>
        </TabList>
        <TabPanel>
          <EntityResolution
            changeView={changeView}
            setFieldParams={setFieldParams}
          />
        </TabPanel>
        <TabPanel>
          <Resolved changeView={changeView} setFieldParams={setFieldParams} />
        </TabPanel>
      </Tabs>
    </>
  );
};

EntityView.propTypes = {
  rentRollDocument: PropTypes.object,
  rentRoll: PropTypes.array,
  changeView: PropTypes.func,
  setFieldParams: PropTypes.func,
  selectedTab: PropTypes.number
};

const mapStateToProps = ({ rentRoll }) => ({
  rentRollDocument: rentRoll.document,
  rentRoll: rentRoll.rentRoll
});

export default connect(mapStateToProps)(EntityView);
