import PropTypes from "prop-types";
import { useEffect, useRef } from "react";

// When creating the bounding box `clientHeight` and `clientWidth` do not take
// into account the borders. "1"px is from css.
const BORDER_OFFSET = 1;

/**
 * Generate headers array in the style of Excel
 * @param {number} numColumns
 * @returns {(string|Array)} [A, B, C, D... Z, AA, AB, AC...]
 * */
function generateSheetHeaders(numColumns) {
  let headers = [];

  for (let i = 0; i < numColumns; i++) {
    let dividend = i + 1;
    let columnName = "";

    while (dividend > 0) {
      let modulo = (dividend - 1) % 26;
      columnName = String.fromCharCode(65 + modulo) + columnName;
      dividend = Math.floor((dividend - modulo) / 26);
    }

    headers.push(columnName);
  }

  return headers;
}

const OpexSheet = ({ data, onMount, children }) => {
  const ref = useRef(null);

  useEffect(() => {
    let tableHeight = ref.current.clientHeight;
    let tableWidth = ref.current.clientWidth;

    // create boundingbox information
    const boundingBoxes = [];
    const allRows = ref.current.children;

    if (allRows.length) {
      let tableHeightOffset = 0;
      Array.from(allRows).forEach((row, rowIndex) => {
        // Skip the first row because it is the column-headers
        if (rowIndex === 0) {
          return null;
        }

        // Restore the indexes to start at 0 so that when we create the boundingBoxes the
        // matrix will start with 0, and mirror the tableData. If we don't remove the index
        // that represents the header column, when the matrices are merged it will be off
        // in modelOpexDocument.js
        const rowIndexWithoutColumn = rowIndex - 1;

        let tableWidthOffset = 0;

        const cells = row.children;

        Array.from(cells).forEach((cell, cellIndex) => {
          // Skip the first cell because it is the row-header
          if (cellIndex === 0) {
            return null;
          }

          boundingBoxes[rowIndexWithoutColumn] =
            boundingBoxes[rowIndexWithoutColumn] || [];

          // create a geometry bounding box in style AWS per
          // https://docs.aws.amazon.com/textract/latest/dg/API_BoundingBox.html
          boundingBoxes[rowIndexWithoutColumn].push({
            geometry: {
              boundingBox: {
                Height: cell.clientHeight / tableHeight,
                Width: cell.clientWidth / tableWidth,
                Left: tableWidthOffset / tableWidth,
                Top: tableHeightOffset / tableHeight
              }
            }
          });

          tableWidthOffset += cell.clientWidth + BORDER_OFFSET;
        });

        tableHeightOffset += cells[0].clientHeight + BORDER_OFFSET;
      });
    }

    onMount(tableHeight, tableWidth, boundingBoxes);
  }, []);

  const sheetHeaders = generateSheetHeaders(data[0].length);

  return (
    <div className="OpexSheet">
      <table>
        <tbody ref={ref}>
          <tr key="headers">
            <td className="header header-corner"></td>
            {sheetHeaders.map((letter, index) => (
              <td className="header" key={`header-col-${index}`}>
                {letter}
              </td>
            ))}
          </tr>
          {data.map((row, ri) => (
            <tr key={ri}>
              <td className="header header-row">{ri}</td>
              {row.map((cell, ci) => (
                <td key={ci}>{cell.text}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {children}
    </div>
  );
};

export default OpexSheet;

OpexSheet.propTypes = {
  data: PropTypes.array.isRequired,
  onMount: PropTypes.func,
  children: PropTypes.node
};
