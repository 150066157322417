export const documentStatus = Object.freeze({
  CREATED: "CREATED",
  PROCESSING_IMAGES: "PROCESSING_IMAGES",
  ANALYSIS_QUEUED: "ANALYSIS_QUEUED",
  ANALYZING: "ANALYZING",
  TEXTRACT_TIMEOUT: "TEXTRACT_TIMEOUT",
  FAILED: "FAILED",
  PROPERTY_INFO_NEEDED: "PROPERTY_INFO_NEEDED",
  PENDING_REVIEW: "PENDING_REVIEW",
  COMPLETED: "COMPLETED"
});

export const documentType = Object.freeze({
  RENT_ROLL: "RENT_ROLL",
  OPERATING_STATEMENT: "OPERATING_STATEMENT"
});

export const documentFileType = Object.freeze({
  PDF: "pdf",
  XLS: "xls",
  XLSX: "xlsx",
  XLSM: "xlsm",
  CSV: "csv",
  PNG: "png",
  JPG: "jpg",
  JPEG: "jpeg"
});

export const editedImageSuffix = "-edited";

export const appId = "QUIQProperty";

export const normalizeDocumentOutputData = data => {
  let documents = [];
  for (const output of data) {
    documents.push({
      id: output.id,
      s3Ref: output.s3_ref,
      originalFilename: output.original_filename,
      propertyId: output.property_id,
      documentType: output.document_type,
      effectiveDate: output.effective_date,
      status: output.status,
      createdAt: output.created_at,
      updatedAt: output.updated_at,
      userId: output.user_id,
      userEmail: output.email,
      lastStatus: output.last_status,
      propertyName: output.property_name,
      propertyType: output.property_type,
      street1: output.street_1,
      street2: output.street_2,
      county: output.county,
      city: output.city,
      state: output.state,
      postalCode: output.postal_code,
      country: output.country,
      portfolioId: output.portfolio_id,
      portfolioName: output.portfolio_name
    });
  }
  return documents;
};

export const getHeaderIndexForColumn = (pi, ti, document) => {
  let headerIndex = 0;

  for (const [pageIndex, page] of document.raw_data_json.pages.entries()) {
    for (const [tableIndex] of page.tableData.entries()) {
      if (pi === pageIndex && ti === tableIndex) {
        return headerIndex;
      }
      headerIndex++;
    }
  }

  return headerIndex;
};

export const getPageAndTableIndexFromHeaders = (pages, hi) => {
  let count = 0;

  for (const [pi, page] of pages.entries()) {
    for (const [ti] of page.tableData.entries()) {
      if (count === hi) {
        return [pi, ti];
      }
      count++;
    }
  }
};

export const setRowExcludedState = (headerIndex, ri, document, state) => {
  document.meta_data_json.headers[headerIndex].excludedRows[ri] = state;
};

export const VALID_CURRENCY = /(?<!\d|-)\$?(-?|\(?)\d+(,\d{3})*(\.\d{2})?\)?$/;
