import MainLoginPage from "ui/components/main/MainLoginPage";
import NotFound from "ui/components/routing/NotFound";
import App from "ui/App";
import AdminHomePage from "./components/admin/AdminHomePage";
import UsersDashboard from "ui/components/admin/users/UsersDashboard";
import TemplatesDashboard from "ui/components/admin/templates/TemplatesDashboard";
import TemplateDetails from "ui/components/admin/templates/TemplateDetails";
import AddNewUserPage from "ui/components/admin/users/AddNewUserPage";
import CompanyDashboard from "ui/components/admin/Company/CompanyDashboard";
import AddNewCompanyPage from "ui/components/admin/Company/AddNewCompanyPage";
import GenerateAPISignature from "ui/components/admin/Company/GenerateAPISignature";
import PropertyRentRollView from "ui/components/rentRoll/property/PropertyRentRollView";
import SplitPanelVersion from "ui/components/rentRoll/splitPanel/SplitPanelVersion";
import OpexPanel from "ui/components/opex/opexPanel/OpexPanel";
// import PortfoliosView from "ui/components/portfolios/PortfoliosView";
import PropertiesView from "ui/components/properties/PropertiesView";
import RentRollDashboard from "ui/components/admin/rentroll/RentRollDashboard";
// import TenantAnalysis from "ui/components/rentRoll/property/demo/TenantAnalysis";
// import TenantsView from "ui/components/Tenants/TenantsView";
import AskPermission from "ui/components/permission/AskPermission";
import DataDashboard from "ui/components/admin/Data/DataDashboard";
import AdminPredefDashboard from "./components/admin/AdminPredefDashboard";
import UsageReport from "ui/components/admin/analytics/UsageReport";
import ManageFilesView from "ui/components/manageFiles/ManageFilesView";

export default [
  {
    path: "/",
    component: App,
    routes: [
      {
        exact: true,
        path: "/admin/data",
        component: DataDashboard
      },
      {
        exact: true,
        path: "/admin/companies/new",
        component: AddNewCompanyPage
      },
      {
        exact: true,
        path: "/admin/companies",
        component: CompanyDashboard
      },
      {
        exact: true,
        path: "/generate-api-signature",
        component: GenerateAPISignature
      },
      {
        exact: true,
        path: "/admin/users/new",
        component: AddNewUserPage
      },
      {
        exact: true,
        path: "/admin/predef-values",
        component: AdminPredefDashboard
      },
      {
        exact: true,
        path: "/admin/rent-roll/fieldsTable",
        component: RentRollDashboard
      },
      {
        exact: true,
        path: "/admin/analytics",
        component: UsageReport
      },
      { exact: true, path: "/admin/users", component: UsersDashboard },
      { exact: true, path: "/admin/templates", component: TemplatesDashboard },
      {
        exact: true,
        path: "/admin/templates/:templateId",
        component: TemplateDetails
      },
      { exact: true, path: "/admin", component: AdminHomePage },
      { exact: true, path: "/login", component: MainLoginPage },
      {
        exact: true,
        path: "/properties",
        component: PropertiesView
      },
      // {
      //   exact: true,
      //   path: "/manage-files",
      //   component: ManageFiles
      // },
      {
        exact: true,
        path: "/manage-files-view",
        component: ManageFilesView
      },
      {
        exact: true,
        path: "/widget/manage-files",
        component: ManageFilesView
      },
      {
        exact: true,
        path: "/rent-roll/property/:propertyId/portfolio/:portfolioId",

        component: PropertyRentRollView
      },
      {
        exact: true,
        path: "/rent-roll/property/:propertyId",
        component: PropertyRentRollView
      },
      {
        exact: true,
        path:
          "/rent-roll/split-panel/property/:propertyId/document/:documentId/portfolio/:portfolioId",
        component: SplitPanelVersion
      },
      {
        exact: true,
        path:
          "/rent-roll/split-panel/property/:propertyId/document/:documentId",
        component: SplitPanelVersion
      },
      {
        exact: true,
        path: "/widget/split-panel/property/:propertyId/document/:documentId",
        component: SplitPanelVersion
      },
      {
        exact: true,
        path:
          "/opex/split-panel/property/:propertyId/document/:documentId/portfolio/:portfolioId",
        component: OpexPanel
      },
      {
        exact: true,
        path: "/opex/split-panel/property/:propertyId/document/:documentId",
        component: OpexPanel
      },
      {
        exact: true,
        path:
          "/widget/opex/split-panel/property/:propertyId/document/:documentId",
        component: OpexPanel
      },
      // {
      //   exact: true,
      //   path: "/portfolios",
      //   component: PortfoliosView
      // },
      // {
      //   exact: true,
      //   path: "/portfolios/tenant-analysis/:tenant",
      //   component: TenantAnalysis
      // },
      // {
      //   exact: true,
      //   path: "/tenants",
      //   component: TenantsView
      // },
      {
        exact: true,
        path: "/askPermission",
        component: AskPermission
      },
      { exact: true, path: "/", component: ManageFilesView },
      { component: NotFound }
    ]
  }
];
