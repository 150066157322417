import { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Popup from "reactjs-popup";
import { connect } from "react-redux";
import cx from "classnames";
import {
  organizeHeaderData,
  getTemplateObject
} from "../../helpers/processing";
import useDocument from "../../hooks/useDocument";
import rentRollHasManualAddedRow from "../../helpers/rentRollHasManualAddedRow";
import Button from "ui/components/shared/Button";

const ExcludedValuesPopover = ({
  location,
  headerKey,
  rentRollDocument,
  documentMetadata,
  currentHeaderIndex,
  setHeaderData,
  refreshData,
  rentRoll
}) => {
  const { pi, ti, ri, ci } = location;
  const { updateTemplate } = useDocument();
  const [isUpdated, setUpdated] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const ref = useRef();
  const { multipleLineGrouping, advancedRules } = (documentMetadata &&
    documentMetadata[currentHeaderIndex]) || {
    multipleLineGrouping: null,
    advancedRules: null
  };
  const closePopover = () => {
    ref.current.close();
    setUpdated(false);
  };

  const cancelAndClosePopover = headerData => {
    if (
      headerData[pi][ti].headers[ci] &&
      headerData[pi][ti].headers[ci].skipRowForValue &&
      headerData[pi][ti].headers[ci].skipRowForValue.length > 0
    ) {
      headerData[pi][ti].headers[ci].skipRowForValue = headerData[pi][
        ti
      ].headers[ci].skipRowForValue.filter(element => {
        return element !== "";
      });
    }
    closePopover();
  };
  const [columnRequired, updateColumnRequired] = useState();
  const headerData = organizeHeaderData(rentRollDocument);
  const [excludedValues, setExcludedValues] = useState({});

  const handleNewExclusion = (event, headerData) => {
    headerData[pi][ti].headers[ci].skipRowForValue.push(
      event.target.previousSibling.value
    );
    event.target.previousSibling.value = "";
    const updatedNewExclusions = {
      ...excludedValues,
      ["skipRow-" +
      pi +
      "-" +
      ti +
      "-" +
      ri +
      "-" +
      ci +
      "-" +
      (headerData[pi][ti].headers[ci] &&
      headerData[pi][ti].headers[ci].skipRowForValue
        ? headerData[pi][ti].headers[ci].skipRowForValue.length
        : 0)]: ""
    };
    setExcludedValues(updatedNewExclusions);
  };

  const updateHeader = async (pi, ti, ri, rowIds) => {
    const templateObj = getTemplateObject(
      rentRollDocument,
      headerData,
      { pi, ti, ri },
      excludedValues,
      rowIds
    );
    setHeaderData(templateObj);
    const hasManualRow = rentRollHasManualAddedRow(rentRoll);
    if (hasManualRow) {
      const confirmed = window.confirm(
        "Updating excluded values will remove any manually added rows."
      );
      if (confirmed) {
        await updateTemplate({
          templateData: templateObj,
          metaName: headerData[pi][ti].metaName,
          headerIndex: headerData[pi][ti].headerIndex,
          multipleLineGrouping: multipleLineGrouping,
          advancedRules: advancedRules
        });
        await refreshData();
      }
    } else {
      await updateTemplate({
        templateData: templateObj,
        metaName: headerData[pi][ti].metaName,
        headerIndex: headerData[pi][ti].headerIndex,
        multipleLineGrouping: multipleLineGrouping,
        advancedRules: advancedRules
      });
      await refreshData();
    }
    setSaving(false);
    closePopover();
  };

  useEffect(() => {
    const isHeaderRequired =
      typeof excludedValues[
        "headerRequired-" + pi + "-" + ti + "-" + ri + "-" + ci
      ] !== "undefined"
        ? !!excludedValues[
            "headerRequired-" + pi + "-" + ti + "-" + ri + "-" + ci
          ]
        : !!(
            headerData[pi][ti].headers[ci] &&
            headerData[pi][ti].headers[ci].required
          );

    updateColumnRequired(isHeaderRequired);
  }, [headerData, excludedValues]);

  return (
    <Popup
      ref={ref}
      trigger={
        <input
          id={`SourceColumnExclude__${headerKey}`}
          name="SourceColumnExclude"
          type="text"
          className="SourceColumnExclude"
          title={
            headerData[pi][ti].headers[ci] &&
            headerData[pi][ti].headers[ci].required &&
            headerData[pi][ti].headers[ci].skipRowForValue &&
            headerData[pi][ti].headers[ci].skipRowForValue.length > 0 &&
            headerData[pi][ti].headers[ci].skipRowForValue.some(Boolean)
              ? "[BLANKS]," + headerData[pi][ti].headers[ci].skipRowForValue
              : headerData[pi][ti].headers[ci] &&
                headerData[pi][ti].headers[ci].required
              ? "[BLANKS]"
              : headerData[pi][ti].headers[ci] &&
                headerData[pi][ti].headers[ci].skipRowForValue &&
                headerData[pi][ti].headers[ci].skipRowForValue.length > 0 &&
                headerData[pi][ti].headers[ci].skipRowForValue.some(Boolean)
              ? headerData[pi][ti].headers[ci].skipRowForValue
              : ""
          }
          value={
            headerData[pi][ti].headers[ci] &&
            headerData[pi][ti].headers[ci].required &&
            headerData[pi][ti].headers[ci].skipRowForValue &&
            headerData[pi][ti].headers[ci].skipRowForValue.length > 0 &&
            headerData[pi][ti].headers[ci].skipRowForValue.some(Boolean)
              ? "[BLANKS]," +
                headerData[pi][ti].headers[ci].skipRowForValue[0] +
                "..."
              : headerData[pi][ti].headers[ci] &&
                headerData[pi][ti].headers[ci].required
              ? "[BLANKS]"
              : headerData[pi][ti].headers[ci] &&
                headerData[pi][ti].headers[ci].skipRowForValue &&
                headerData[pi][ti].headers[ci].skipRowForValue.length > 0 &&
                headerData[pi][ti].headers[ci].skipRowForValue.some(Boolean)
              ? headerData[pi][ti].headers[ci].skipRowForValue[0] + "..."
              : ""
          }
        />
      }
      position={["center center"]}
      modal
      onClose={closePopover}
      className="EditModal"
    >
      <div className={cx("EditModal__body", "HeaderEditPopover__body")}>
        <div className="HeaderEditPopover__requiredContainer">
          <div className="HeaderEditPopover__subtitle">Skip blanks?</div>
          <div className="HeaderEditPopover__requiredRadioContainer">
            <div className="HeaderEditPopover__requiredInputContainer">
              <input
                type="radio"
                checked={columnRequired}
                readOnly
                onClick={() => {
                  setExcludedValues({
                    ...excludedValues,
                    ["headerRequired-" +
                    pi +
                    "-" +
                    ti +
                    "-" +
                    ri +
                    "-" +
                    ci]: true
                  });
                  updateColumnRequired(true);
                }}
              />
              <div className="HeaderEditPopover__requiredRadioLabel">Yes</div>
            </div>
            <div className="HeaderEditPopover__requiredInputContainer">
              <input
                type="radio"
                checked={!columnRequired}
                readOnly
                onClick={() => {
                  setExcludedValues({
                    ...excludedValues,
                    ["headerRequired-" +
                    pi +
                    "-" +
                    ti +
                    "-" +
                    ri +
                    "-" +
                    ci]: false
                  });
                  updateColumnRequired(false);
                }}
              />
              <div className="HeaderEditPopover__requiredRadioLabel">No</div>
            </div>
          </div>
        </div>
        <div
          className="HeaderEditPopover__subtitle"
          style={{ margin: "10px 0" }}
        >
          Exclude rows with these values:
        </div>
        {headerData[pi][ti].headers[ci] &&
          headerData[pi][ti].headers[ci].skipRowForValue &&
          headerData[pi][ti].headers[ci].skipRowForValue.map((val, i) => {
            return (
              <div
                key={"skipRow-" + pi + "-" + ti + "-" + ri + "-" + ci + "-" + i}
                style={{ marginBottom: "10px" }}
              >
                <input
                  type="text"
                  name={
                    "skipRow-" + pi + "-" + ti + "-" + ri + "-" + ci + "-" + i
                  }
                  defaultValue={
                    typeof excludedValues[
                      "skipRow-" + pi + "-" + ti + "-" + ri + "-" + ci + "-" + i
                    ] !== "undefined"
                      ? excludedValues[
                          "skipRow-" +
                            pi +
                            "-" +
                            ti +
                            "-" +
                            ri +
                            "-" +
                            ci +
                            "-" +
                            i
                        ]
                      : val
                  }
                  onChange={event => {
                    setExcludedValues({
                      ...excludedValues,
                      ["skipRow-" +
                      pi +
                      "-" +
                      ti +
                      "-" +
                      ri +
                      "-" +
                      ci +
                      "-" +
                      i]: event.target.value
                    });
                  }}
                />
              </div>
            );
          })}
        {!isUpdated && (
          <div
            style={{
              textAlign: "left"
            }}
          >
            <input
              type="text"
              name={
                "skipRow-" +
                pi +
                "-" +
                ti +
                "-" +
                ri +
                "-" +
                ci +
                "-" +
                (headerData[pi][ti].headers[ci] &&
                headerData[pi][ti].headers[ci].skipRowForValue
                  ? headerData[pi][ti].headers[ci].skipRowForValue.length
                  : 0)
              }
              defaultValue={""}
              onChange={event => {
                setExcludedValues({
                  ...excludedValues,
                  ["skipRow-" +
                  pi +
                  "-" +
                  ti +
                  "-" +
                  ri +
                  "-" +
                  ci +
                  "-" +
                  (headerData[pi][ti].headers[ci] &&
                  headerData[pi][ti].headers[ci].skipRowForValue
                    ? headerData[pi][ti].headers[ci].skipRowForValue.length
                    : 0)]: event.target.value
                });
              }}
            />
            <button
              className={cx("Button__blackButton", "CustomColumnsForm__newRow")}
              style={{
                marginRight: "10px",
                cursor: "pointer",
                marginTop: "8px"
              }}
              onClick={event => {
                event.preventDefault();
                handleNewExclusion(event, headerData);
              }}
            >
              +
            </button>
          </div>
        )}
      </div>
      <div className="EditModal__verify">
        <div
          className={cx("Button", "Button__blackButton")}
          onClick={() => {
            cancelAndClosePopover(headerData);
          }}
        >
          Cancel
        </div>
        {isSaving ? (
          <Button
            className={cx("Button", "Button__blackButton")}
            disabled={true}
            style={{ marginLeft: "5px" }}
          >
            Updating...
          </Button>
        ) : (
          <div
            className={cx("Button", "Button__blueButton")}
            style={{ marginLeft: "5px" }}
            onClick={() => {
              setSaving(true);
              setUpdated(true);
              updateHeader(
                pi,
                ti,
                ri,
                excludedValues["rowHeader-" + pi + "-" + ti]
                  ? excludedValues["rowHeader-" + pi + "-" + ti].row
                  : headerData[pi][ti].rowIds
                  ? headerData[pi][ti].rowIds
                  : [headerData[pi][ti].rowIndex]
              );
            }}
          >
            Update
          </div>
        )}
      </div>
    </Popup>
  );
};

ExcludedValuesPopover.propTypes = {
  cellData: PropTypes.object,
  fieldNamesMappingForDropDown: PropTypes.object,
  headerData: PropTypes.array,
  location: PropTypes.object,
  updateHeader: PropTypes.func,
  isExcel: PropTypes.bool,
  selectedProperty: PropTypes.object,
  multiLineHeader: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  headerKey: PropTypes.string,
  rentRollDocument: PropTypes.object,
  documentMetadata: PropTypes.array,
  currentHeaderIndex: PropTypes.number,
  setHeaderData: PropTypes.func,
  refreshData: PropTypes.func,
  rentRoll: PropTypes.any
};

export default connect(null)(ExcludedValuesPopover);
