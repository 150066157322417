import { useRef } from "react";
import PropTypes from "prop-types";
import Popup from "reactjs-popup";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import cx from "classnames";

import {
  fetchRentRollByDocId,
  fetchRentRollDocument,
  predefSugestionCleared
} from "../../../../../store/actions/rentRoll";

import CellEditPopoverBody from "./CellEditPopoverBody";
import { isEntityMappingEnabled } from "./helpers/predef";

const CellEditPopover = ({
  cellData,
  isMapped,
  location,
  cache,
  predefSugestionCleared,
  documentId,
  isExcel,
  clearSelectedCell,
  handleScrollToCell,
  container,
  setPredefModal,
  fetchRentRollDocument,
  propertyType,
  headerData,
  widgetAuth,
  userCompanyEntitySources
}) => {
  const ref = useRef();
  const closePopover = () => ref.current.close();
  const openPopover = () => ref.current.open();
  const { pi, ti, ci, ri } = location;
  let field;

  if (
    headerData[pi] &&
    headerData[pi][ti] &&
    headerData[pi][ti].headers &&
    headerData[pi][ti].headers[ci]
  ) {
    field = headerData[pi][ti].headers[ci].id;
  }
  return (
    <Popup
      ref={ref}
      trigger={
        <div
          className={cx(
            {
              ["RentRollDocumentView__cellVarOpacity"]: !isExcel && isMapped
            },
            {
              ["RentRollDocumentView__cellNotMapped"]: !isExcel && !isMapped
            }
          )}
          style={{
            cursor: "default",
            width: "100%",
            height: isExcel
              ? "100%"
              : `${cellData.geometry.boundingBox.Height * 100}%`
          }}
          onClick={() => {
            openPopover();
          }}
        >
          {isExcel && cellData.text}
        </div>
      }
      position={[
        "bottom right",
        "top right",
        "bottom left",
        "top left",
        "right center",
        "left center"
      ]}
      modal={cellData.predef}
      closeOnDocumentClick
      keepTooltipInside={container}
      arrow={!cellData.predef}
      onClose={() => {
        clearSelectedCell();
        if (cellData.predef) {
          (async () => {
            await fetchRentRollByDocId(documentId, widgetAuth);
            await fetchRentRollDocument(documentId, widgetAuth);
          })();
        }
        if (
          !isExcel &&
          cellData.predef &&
          isEntityMappingEnabled(propertyType, field, userCompanyEntitySources)
        ) {
          setPredefModal(false);
        }
        closePopover();
      }}
      onOpen={() => {
        predefSugestionCleared();
        handleScrollToCell("to_rent_roll", `${pi}-${ti}-${ri}-${ci}`);
        if (
          !isExcel &&
          cellData.predef &&
          isEntityMappingEnabled(propertyType, field, userCompanyEntitySources)
        ) {
          setPredefModal(true);
        }
      }}
      className="Popover"
      contentStyle={
        cellData.predef &&
        isEntityMappingEnabled(
          propertyType,
          field,
          userCompanyEntitySources
        ) && {
          width: "600px",
          height: "700px",
          overflow: "hidden"
        }
      }
    >
      <>
        {cellData.predef &&
        isEntityMappingEnabled(
          propertyType,
          field,
          userCompanyEntitySources
        ) ? (
          <img
            src={require("ui/images/icon-x.svg")}
            style={{ float: "right", cursor: "pointer" }}
            onClick={() => closePopover()}
          />
        ) : (
          <div className="Popover__popupHeader">Edit Cell</div>
        )}
        <div className="Popover__body">
          <CellEditPopoverBody
            cellData={cellData}
            closePopover={closePopover}
            cache={cache}
            location={location}
            documentId={documentId}
            propertyType={propertyType}
            headerData={headerData}
            widgetAuth={widgetAuth}
            userCompanyEntitySources={userCompanyEntitySources}
          />
        </div>
      </>
    </Popup>
  );
};

CellEditPopover.defaultProps = {
  widgetAuth: {}
};

CellEditPopover.propTypes = {
  documentId: PropTypes.number,
  tableException: PropTypes.object,
  cellData: PropTypes.object,
  isMapped: PropTypes.object,
  headerOuter: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  fieldNames: PropTypes.object,
  headerData: PropTypes.array,
  location: PropTypes.object,
  cache: PropTypes.object,
  predefSugestionCleared: PropTypes.func,
  isExcel: PropTypes.bool,
  clearSelectedCell: PropTypes.func,
  handleScrollToCell: PropTypes.func,
  fetchRentRollByDocId: PropTypes.func,
  setPredefModal: PropTypes.func,
  fetchRentRollDocument: PropTypes.func,
  container: PropTypes.string,
  propertyType: PropTypes.string,
  widgetAuth: PropTypes.object,
  userCompanyEntitySources: PropTypes.object
};

function mapStateToProps({ currentUser }) {
  return {
    widgetAuth: currentUser.widgetAuth,
    userCompanyEntitySources: currentUser.company.entity_sources
  };
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { predefSugestionCleared, fetchRentRollByDocId, fetchRentRollDocument },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CellEditPopover);
