import { Component } from "react";
import { logoutQuiqProperty } from "ui/store/actions/accountMenu";

export default class AskPermission extends Component {
  componentDidMount() {
    setTimeout(function() {
      logoutQuiqProperty();
    }, 15000);
  }
  render() {
    return (
      <div className="AskPermission">
        <h1 className="AskPermission__error">
          Error: Your account has not yet been activated
        </h1>
        <p className="AskPermission__message-one">
          Authentication was successful, but your QUIQProperty account has not
          yet been activated
        </p>
        <p className="AskPermission__message-two">
          Please contact the QUIQProperty support team to activate your account
        </p>
      </div>
    );
  }
}
