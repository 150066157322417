const alphabet = {
  A: 0,
  B: 1,
  C: 2,
  D: 3,
  E: 4,
  F: 5,
  G: 6,
  H: 7,
  I: 8,
  J: 9,
  K: 10,
  L: 11,
  M: 12,
  N: 13,
  O: 14,
  P: 15,
  Q: 16,
  R: 17,
  S: 18,
  T: 19,
  U: 20,
  V: 21,
  W: 22,
  X: 23,
  Y: 24,
  Z: 25
};

export function withinTable(row, col, maxRow, maxCol) {
  return row <= maxRow && col <= maxCol;
}

export function convertExcelLetterToCol(val) {
  let base = Object.keys(alphabet),
    i,
    j,
    result = 0;

  for (i = 0, j = val.length - 1; i < val.length; i += 1, j -= 1) {
    result += Math.pow(base.length, j) * (base.indexOf(val[i]) + 1);
  }

  return result - 1;
}

export function toColumnLetter(num) {
  for (var ret = "", a = 1, b = 26; (num -= a) >= 0; a = b, b *= 26) {
    ret = String.fromCharCode(parseInt((num % b) / a) + 65) + ret;
  }
  return ret;
}

export function positionToExcelPosition(row, col) {
  const excelCellRow = row + 1;
  const excelCellCol = col + 1;
  const excelPosition = toColumnLetter(excelCellCol) + excelCellRow;
  return excelPosition;
}

export function getExcelRentRollColumns(companyName = null) {
  const excel_rentroll_columns = [
    { label: "QP Property Name", key: "property_name" },
    { label: "QP Property Address", key: "property_address" },
    { label: "QP Effective Date", key: "effective_date", format: "date" },
    { label: "QP Property Type", key: "property_property_type" },
    {
      label: "REIS Property REIS Surveyed Rent",
      key: "property_one_year_asking_rent",
      format: "currency"
    },
    {
      label: "REIS Property One Year Vacancy",
      key: "property_one_year_vacancy",
      format: "percent"
    },
    { label: "REIS Parcel Number", key: "property_parcel_number" },
    { label: "REIS Year Built", key: "property_year_built" },
    { label: "REIS Property Size Lot Sqft", key: "property_size_lot_sqft" },
    { label: "QP Property Size Sqft", key: "property_size_sqft" },
    { label: "QP Street 1", key: "property_street_1" },
    { label: "QP Street 2", key: "property_street_2" },
    { label: "QP County", key: "property_county" },
    { label: "QP City", key: "property_city" },
    { label: "QP State", key: "property_state" },
    { label: "QP Postal Code", key: "property_postal_code" },
    { label: "QP Country", key: "property_country" },
    { label: "REIS Property Floors", key: "property_floors" },
    { label: "REIS Latitude", key: "property_latitude" },
    { label: "REIS Longitude", key: "property_longitude" },
    { label: "REIS ID", key: "property_reis_id" },
    { label: "QP Tenant Name", key: "tenant" },
    { label: "QP Is Vacant", key: "is_vacant", format: "yn" },
    { label: "QP Is Occupied", key: "is_occupied", format: "yn" },
    { label: "QP Is Primary", key: "is_primary", format: "yn" },
    {
      label: "QP Vacancy (Unit)",
      key: "property_vacancy_units",
      format: "percent_tenths"
    },
    {
      label: "QP Vacancy (SF)",
      key: "property_vacancy_sqft",
      format: "percent_tenths"
    },
    { label: "QP Tenant ID", key: "rent_roll_id" },
    { label: "QP Unit Name", key: "unit_name" },
    { label: "QP Space Type", key: "space_type" },
    { label: "QP Annual Rent", key: "annual_rent", format: "currency" },
    { label: "QP Monthly Rent", key: "monthly_rent", format: "currency" },
    {
      label: "QP Annual Rent Per Sqft",
      key: "annual_rent_per_sqft",
      format: "currency"
    },
    {
      label: "QP Monthly Rent Per Sqft",
      key: "monthly_rent_per_sqft",
      format: "currency"
    },
    { label: "QP Square Footage", key: "square_footage", format: "size" },
    {
      label: "QP Lease Start Date",
      key: "lease_start_date",
      format: "date"
    },
    { label: "QP Lease End Date", key: "lease_end_date", format: "date" },
    {
      label: "QP Percent Of Property Rent",
      key: "percent_of_property_rent",
      format: "percent"
    },
    {
      label: "QP Percent Of Property Space",
      key: "percent_of_property_space",
      format: "percent"
    },
    { label: "QP Lease Term", key: "lease_term" },
    { label: "QP Remaining Term", key: "remaining_term" },
    { label: "BvD ID", key: "bvd_id" },
    { label: "BvD Address", key: "bvd_address" },
    { label: "BvD Industry", key: "bvd_industry" },
    { label: "BvD Ownership Name", key: "bvd_ownershipName" },
    { label: "BvD Ownership Id", key: "bvd_ownershipId" },
    { label: "Cortera ID", key: "cortera_id" },
    { label: "Cortera Address", key: "cortera_address" },
    { label: "Cortera Industry", key: "cortera_industry" },
    { label: "Cortera Ownership Name", key: "cortera_ownershipName" },
    { label: "Cortera Position Type", key: "cortera_position_type" },
    { label: "Cortera Employee Range", key: "cortera_employee_range" },
    { label: "Cortera Sales Range", key: "cortera_sales_range" },
    { label: "Cortera Ultimate Name", key: "cortera_ultimate_name" },
    { label: "Safegraph ID", key: "safegraph_id" },
    { label: "Safegraph Address", key: "safegraph_address" },
    { label: "Safegraph Industry", key: "safegraph_industry" },
    { label: "Safegraph Sub Industry", key: "safegraph_sub_category" }
  ];
  if (companyName) {
    excel_rentroll_columns.unshift(
      { label: "Company ID", key: "property_company_id" },
      { label: "Company Name", key: "property_company_name" }
    );
  }
  return excel_rentroll_columns;
}
