export function hasAdminAccess(role) {
  return role === "admin" || role === "superadmin";
}

export function hasExclusiveAdminAccess(role) {
  return role === "admin";
}

export function hasSuperAdminAccess(role) {
  return role === "superadmin";
}

export function hasUserAccess(role) {
  return role === "user";
}

export function hasAccess(role) {
  return role === "user" || role === "admin" || role === "superadmin";
}

export function creationAllowed(access, roleToCreate) {
  return hasAdminAccess(access) && ["admin", "user"].includes(roleToCreate);
}
