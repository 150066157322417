import PropTypes from "prop-types";
import { useState } from "react";
import cx from "classnames";

const CompaniesWarningPopup = ({ submitEdits, closePopup }) => {
  const [value, setValue] = useState("");
  const [inputTextIsValid, setInputTextIsValid] = useState(false);

  const validText = "UPDATE GROUP DATA";

  const handleChange = e => {
    setValue(e.target.value);
    setInputTextIsValid(e.target.value === validText);
  };

  const handleSubmit = () => {
    if (!inputTextIsValid) return;

    submitEdits();
  };

  const handleClickOutside = e => {
    if (e.target.className === "CompaniesTable-warning-popup") {
      closePopup();
    }
  };

  return (
    <div onClick={handleClickOutside} className="CompaniesTable-warning-popup">
      <div className="CompaniesTable-warning-popup-content">
        <div className="CompaniesTable-warning-popup-header">WARNING:</div>
        <div className="CompaniesTable-warning-popup-message">
          Changing company group data will affect how document data is
          processed. If you sure you want to make this edit, enter the following
          text below and click Submit:
        </div>
        <div className="CompaniesTable-warning-popup-label">
          <div style={{ userSelect: "none" }}>UPDATE GROUP DATA</div>
          <input
            className="CompaniesTable-warning-popup-input"
            type="text"
            value={value}
            onChange={handleChange}
          />
        </div>
        <div className="CompaniesTable-warning-popup-buttons">
          <button
            className="CompaniesTable-warning-popup-button"
            onClick={closePopup}
            style={{ color: "gray" }}
          >
            Cancel
          </button>
          <button
            className={cx("CompaniesTable-warning-popup-button", {
              "CompaniesTable-warning-popup-button-enabled": inputTextIsValid
            })}
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

CompaniesWarningPopup.propTypes = {
  submitEdits: PropTypes.func,
  closePopup: PropTypes.func
};

export default CompaniesWarningPopup;
