import _ from "lodash";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import OpexPage from "../../../OpexPage/OpexPage";
import OpexPageControls from "../../../OpexPageControls/OpexPageControls";
import OpexDocument from "../../../../modelOpexDocument";
import CellPolygon from "../../../CellPolygon/CellPolygon";
import {
  DEFAULT_ZOOM,
  scrollCellIntoView,
  getClassNameForCellScrollIntoView
} from "ui/components/opex/shared";

const ColumnSideBar = ({ document, activeCellLocation, activeRowLocation }) => {
  // css selector to the <OpexPage> cell that is currently selected
  const CLASS_OF_SIDEBAR_IMAGE_CELL = ".OpexPanelView__OpexPage .cell.selected";

  const documentModel = new OpexDocument(document);
  const [currentPage, setCurrentPage] = useState(activeCellLocation?.pi || 0);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [zoom, setZoom] = useState(DEFAULT_ZOOM);

  function handleOnChangePage(pageIndex) {
    let nextPage = pageIndex;
    if (nextPage < 0) {
      nextPage = 0;
    }
    if (nextPage >= documentModel.countPages()) {
      nextPage = documentModel.countPages() - 1;
    }
    setCurrentPage(nextPage);
    setIsPageLoading(true);
  }

  function handleOnImageLoad() {
    setIsPageLoading(false);
    scrollCellIntoView(CLASS_OF_SIDEBAR_IMAGE_CELL);
  }

  useEffect(() => {
    if (activeCellLocation) {
      // Only change page if necessary.
      if (activeCellLocation.pi !== currentPage) {
        setCurrentPage(activeCellLocation.pi);
        setIsPageLoading(true);
      } else {
        // We are on the correct page, scroll the cell into view.
        scrollCellIntoView(CLASS_OF_SIDEBAR_IMAGE_CELL);
      }
    }
  }, [activeCellLocation]);

  const style = {
    zoom
  };

  return (
    <div className="OpexPanelView__DocumentView__Pane">
      <div className="pane-header">
        <OpexPageControls
          initialZoom={zoom}
          currentPage={currentPage}
          totalPages={documentModel.countPages()}
          onChangePage={handleOnChangePage}
          onChangeZoom={setZoom}
          pageNumberEditable={true}
        />
      </div>

      <div className="pane-content" style={style}>
        <OpexPage
          views={["rows", "cells"]}
          document={document}
          pageIndex={currentPage}
          useFixedWidthHeightFromImage={true}
          selectedCells={[activeCellLocation]}
          selectedRows={[activeRowLocation]}
          showLoadingSpinner={isPageLoading}
          onImageLoad={handleOnImageLoad}
          cellPolygonRenderer={CellPolygon}
        />
      </div>
    </div>
  );
};

export default ColumnSideBar;

ColumnSideBar.propTypes = {
  document: PropTypes.object,
  tables: PropTypes.array,
  currentTable: PropTypes.number,
  activeCellLocation: PropTypes.object, // { pi, ti, ci, ri }
  activeRowLocation: PropTypes.number // ri
};
