export const scrollToCellOnRentRoll = (key, tableRefs) => {
  const selected = tableRefs.current[key];
  if (!selected) return;
  selected.scrollIntoView({
    block: "center",
    behavior: "smooth"
  });
};

export const scrollToCellOnDocument = (key, isExcel, tableRefs) => {
  if (isExcel) {
    const selected = tableRefs[key];
    if (!selected) return;
    selected.scrollIntoView({
      block: "center",
      behavior: "smooth"
    });
  } else {
    const element = document.getElementById(key);
    if (!element) return;
    element.scrollIntoView({ block: "center", behavior: "smooth" });
  }
};

export const clearAllSelected = (rentRollToDocument, tableRefs) => {
  const rentRowCellKeys = Object.keys(rentRollToDocument);
  const documentCellKeys = Object.values(rentRollToDocument);

  rentRowCellKeys.forEach(cellKey => {
    tableRefs.current[cellKey].classList.remove("SplitPanel--cellFocused");
  });

  documentCellKeys.forEach(docKey => {
    const element = document.getElementById(docKey);
    if (element !== null) {
      element.firstChild.classList.remove("SplitPanel--docFocused");
    }
  });
};
