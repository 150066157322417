import { Component } from "react";
import { connect } from "react-redux";
import AdminLayout from "./AdminLayout";
import PropTypes from "prop-types";

import { hasAdminAccess, hasSuperAdminAccess } from "helpers/authorization";

import AdminTool from "ui/components/admin/AdminTool";

import AuthenticatedRoute from "ui/components/routing/AuthenticatedRoute";

class AdminHomePage extends Component {
  render() {
    const { company, role, email } = this.props.currentUser;
    const companyName = company === null ? "" : `: ${company.company_name}`;
    return (
      <AdminLayout
        className="AdminHomePage"
        title={`Settings Dashboard${companyName}: ${email}`}
      >
        <div className="AdminHomePage__header">Administrative Options</div>
        <div className="AdminHomePage__tools">
          {hasAdminAccess(role) && (
            <AdminTool
              label="Manage Users"
              className="users"
              to="/admin/users"
            />
          )}
          {hasSuperAdminAccess(role) && (
            <AdminTool
              label="Companies"
              className="users"
              to="/admin/companies"
            />
          )}
          {hasSuperAdminAccess(role) && (
            <AdminTool label="Data" className="users" to="/admin/data" />
          )}
          {hasAdminAccess(role) && (
            <AdminTool
              label="Rent Roll"
              className="users"
              to="/admin/rent-roll/fieldsTable"
            />
          )}
          {hasAdminAccess(role) && (
            <AdminTool
              label="Unit Types"
              className="users"
              to="/admin/predef-values"
            />
          )}
          {hasSuperAdminAccess(role) && (
            <AdminTool label="Usage" className="users" to="/admin/analytics" />
          )}
          {hasSuperAdminAccess(role) && (
            <AdminTool
              label="OpEx Templates"
              className="templates"
              to="/admin/templates"
            />
          )}
        </div>
      </AdminLayout>
    );
  }
}

AdminHomePage.propTypes = {
  currentUser: PropTypes.object
};

function mapStateToProps({ currentUser }) {
  return {
    currentUser
  };
}
export default AuthenticatedRoute(connect(mapStateToProps)(AdminHomePage));
