import { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import FORM_STATES from "../../helpers/formStates";
import Tabs from "./components/Tabs/Tabs";
import Actions from "./components/Actions/Actions";
import HeaderStep from "./components/HeaderStep/HeaderStep";
import DataStep from "./components/DataStep/DataStep";
import useRentRollColumns from "../../hooks/useRentRollColumns";
import { AdvancedRulesPopover } from "ui/components/rentRoll/splitPanelV2/popovers/advancedRules/AdvancedRulesPopover";
import { scrollToCellOnRentRoll } from "../../helpers/navigation";
import CellUpdateValue from "./components/DataStep/Popover/PredefCellUpdateVal";
import useSplitPanelV2Context from "../../hooks/useSplitPanelV2Context";

export default function RentRoll({
  activeDocumentTables,
  documentMetadata,
  setDocumentMetadata,
  errors,
  setRequiredFields,
  currentPageIndex,
  formState,
  goToPrevStep,
  hasNextActiveTable,
  hasPrevActiveTable,
  onSelectedDataCell,
  onSelectedHeaderCell,
  property,
  propertyData,
  saveAndContinue,
  selected,
  setHeaderData,
  currentHeaderIndex,
  rentRoll,
  refreshData,
  document,
  selectedRentRollCell,
  setSelectedRentRollCell,
  handleRef,
  handleScrollToCell,
  documentTables,
  exceptions,
  rentRollFields,
  userRole,
  completeDoc,
  isSubmitting,
  groupName,
  disablePublishButton
}) {
  const { rentRollColumns } = useRentRollColumns();
  const {
    ui: { rentRollHeight }
  } = useSplitPanelV2Context();
  const [isSaving, setIsSaving] = useState(false);
  const [showPredefModal, setShowPredefModal] = useState(false);
  const [predefModalValue, setPredefModalValue] = useState("");
  const [predefModalInfo, setPredefModalInfo] = useState({
    pageIndex: "",
    tableIndex: "",
    rowIndex: "",
    columnIndex: "",
    dataKey: ""
  });
  const [addingRow, setAddingRow] = useState(false);
  const [columnInfo, setColumnInfo] = useState({});
  const [advancedRulesOpen, setAdvancedRulesOpen] = useState(false);
  const tableRefs = useRef({});

  const findOriginalCellText = (dataKey, predefModalValue, location = {}) => {
    if (dataKey === "tenant_name" || dataKey === "space_type") {
      let found;
      for (const row of rentRoll) {
        if (!row.doc_data_json || !row.doc_data_json.row) {
          continue;
        }
        found = row.doc_data_json.row.find(r => {
          return (
            r.id === dataKey &&
            r.docPosition?.page === location.pi &&
            r.docPosition?.table === location.ti &&
            r.docPosition?.row === location.ri &&
            r.docPosition?.col === location.ci
          );
        });
        if (found) break;
      }
      return found.value || null;
    } else {
      return null;
    }
  };

  function updatePredefModalInfo(
    cellVal,
    pageIndex,
    tableIndex,
    rowIndex,
    columnIndex,
    dataKey
  ) {
    setPredefModalValue(cellVal);
    setPredefModalInfo({
      pageIndex,
      tableIndex,
      rowIndex,
      columnIndex,
      dataKey,
      originalCellText: findOriginalCellText(dataKey, cellVal, {
        pi: pageIndex,
        ti: tableIndex,
        ri: rowIndex,
        ci: columnIndex
      })
    });
  }

  function getColumnDataInfo() {
    setColumnInfo(rentRollColumns);
  }

  async function handleNextClick() {
    setIsSaving(true);
    await saveAndContinue();
    setIsSaving(false);
  }

  useEffect(() => {
    handleRef(tableRefs);
  });

  useEffect(() => {
    if (rentRollColumns) getColumnDataInfo();
  }, [rentRollColumns]);

  return (
    <div
      className="SplitPanelV2__RentRoll"
      style={{ height: `${rentRollHeight}%` }}
    >
      <Tabs formState={formState} />
      <div className="SplitPanelV2__RentRoll-body">
        <div
          className={
            formState === FORM_STATES.HEADERS
              ? "SplitPanelV2__RentRoll-body-content-scroll"
              : "SplitPanelV2__RentRoll-body-content"
          }
        >
          {formState === FORM_STATES.HEADERS && (
            <HeaderStep
              rentRoll={rentRoll}
              refreshData={refreshData}
              errors={errors}
              activeDocumentTables={activeDocumentTables}
              currentPageIndex={currentPageIndex}
              documentTables={documentTables}
              onSelected={onSelectedHeaderCell}
              selected={selected}
              currentHeaderIndex={currentHeaderIndex}
              documentMetadata={documentMetadata}
              document={document}
              setDocumentMetadata={setDocumentMetadata}
              rentRollDocument={document}
              setHeaderData={setHeaderData}
              propertyType={property.property_type}
              setRequiredFields={setRequiredFields}
              setAdvancedRulesOpen={setAdvancedRulesOpen}
            />
          )}
          {formState === FORM_STATES.DATA && (
            <DataStep
              columnInfo={columnInfo}
              currentHeaderIndex={currentHeaderIndex}
              handleScrollToCell={handleScrollToCell}
              tableRefs={tableRefs}
              document={document}
              documentTables={documentTables}
              exceptions={exceptions}
              onSelectedCell={onSelectedDataCell}
              propertyData={propertyData}
              propertyType={property.property_type}
              rentRoll={rentRoll}
              rentRollFields={rentRollFields}
              selected={selected}
              selectedRentRollCell={selectedRentRollCell}
              scrollToCellOnRentRoll={scrollToCellOnRentRoll}
              setSelectedRentRollCell={setSelectedRentRollCell}
              setPredefModalInfo={updatePredefModalInfo}
              openPopover={() => {
                setShowPredefModal(true);
              }}
              setAddingRow={setAddingRow}
              addingRow={addingRow}
              currentPageIndex={currentPageIndex}
            />
          )}
          {showPredefModal && (
            <CellUpdateValue
              closePopover={() => {
                setShowPredefModal(false);
              }}
              predefModalValue={predefModalValue}
              {...predefModalInfo}
            />
          )}
          {activeDocumentTables[currentHeaderIndex] && (
            <div className="SplitPanelV2__AdvancedRulesPopover">
              <AdvancedRulesPopover
                advancedRulesOpen={advancedRulesOpen}
                setAdvancedRulesOpen={setAdvancedRulesOpen}
                cache={{}}
                rentRollDocument={document}
                rows={rentRoll}
                location={{ pi: currentPageIndex, ti: 0 }}
                userRole={userRole}
                refreshData={refreshData}
                documentMetadata={documentMetadata}
                setDocumentMetadata={setDocumentMetadata}
                currentHeaderIndex={currentHeaderIndex}
                rentRoll={rentRoll}
              />
            </div>
          )}
        </div>
      </div>
      <Actions
        disabled={isSaving || !!errors.length}
        formState={formState}
        handleNextClick={handleNextClick}
        handlePrevClick={goToPrevStep}
        hasNextActiveTable={hasNextActiveTable}
        hasPrevActiveTable={hasPrevActiveTable}
        isSaving={isSaving}
        completeDoc={completeDoc}
        isSubmitting={isSubmitting}
        docStatus={document.status}
        setAddingRow={setAddingRow}
        addingRow={addingRow}
        groupName={groupName}
        disablePublishButton={disablePublishButton}
      />
    </div>
  );
}

RentRoll.propTypes = {
  setRequiredFields: PropTypes.func.isRequired,
  setDocumentMetadata: PropTypes.func.isRequired,
  errors: PropTypes.array,
  formState: PropTypes.string.isRequired,
  goToPrevStep: PropTypes.func.isRequired,
  handleRef: PropTypes.func,
  handleScrollToCell: PropTypes.func,
  hasNextActiveTable: PropTypes.bool.isRequired,
  hasPrevActiveTable: PropTypes.bool.isRequired,
  onSelectedDataCell: PropTypes.func.isRequired,
  onSelectedHeaderCell: PropTypes.func.isRequired,
  property: PropTypes.object.isRequired,
  propertyData: PropTypes.object.isRequired,
  saveAndContinue: PropTypes.func.isRequired,
  selected: PropTypes.string,
  selectedRentRollCell: PropTypes.string,
  setSelectedRentRollCell: PropTypes.func.isRequired,
  setPropertyData: PropTypes.func.isRequired,
  currentHeaderIndex: PropTypes.number,
  setHeaderData: PropTypes.func,
  rentRoll: PropTypes.array,
  exceptions: PropTypes.array,
  widgetAuth: PropTypes.object,
  documentTables: PropTypes.array.isRequired,
  activeDocumentTables: PropTypes.array,
  currentPageIndex: PropTypes.number.isRequired,
  document: PropTypes.object,
  rentRollFields: PropTypes.array,
  cache: PropTypes.object,
  userRole: PropTypes.string,
  documentMetadata: PropTypes.array,
  refreshData: PropTypes.func,
  completeDoc: PropTypes.func,
  isSubmitting: PropTypes.bool,
  groupName: PropTypes.string,
  disablePublishButton: PropTypes.string
};
