export const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD"
});

export const compactCurrencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  notation: "compact"
});

export const squareFootageFormatter = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});

export const compactNumberFormatter = new Intl.NumberFormat("en-US", {
  notation: "compact"
});

export const percentFormatter = new Intl.NumberFormat("en-US", {
  style: "percent",
  minimumFractionDigits: 1,
  maximumFractionDigits: 1
});

export const booleanFormatter = {
  format: function(b) {
    const yn =
      typeof b === "boolean" ? b : b && b.trim().toLowerCase() === "true";
    return yn ? "Y" : "N";
  }
};
