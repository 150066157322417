import PropTypes from "prop-types";
import cx from "classnames";

export default function TablePill({ isActive, isSelected }) {
  return (
    <div
      className={cx("SplitPanelV2__LeftSidebar__TablePill", {
        ["SplitPanelV2__LeftSidebar__TablePill--not-active"]: !isActive,
        ["SplitPanelV2__LeftSidebar__TablePill--selected"]: isSelected
      })}
    />
  );
}

TablePill.propTypes = {
  isActive: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired
};
