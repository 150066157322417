import { useRef } from "react";
import Popup from "reactjs-popup";

import StatusBar from "./StatusBar";
import DocumentsPanel from "./DocumentsPanel";

const DocumentsPopover = () => {
  const ref = useRef();
  const closePopover = () => ref.current.close();
  return (
    <div
      style={{
        alignSelf: "flex-end"
      }}
    >
      <Popup
        ref={ref}
        trigger={() => (
          <div>
            <StatusBar />
          </div>
        )}
        arrow={false}
        closeOnDocumentClick
        contentStyle={{
          marginRight: "15px",
          width: "0",
          padding: "0",
          top: "60px",
          right: "630px"
        }}
      >
        <DocumentsPanel closePopover={closePopover} />
      </Popup>
    </div>
  );
};

export default DocumentsPopover;
