import { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import DropDown from "ui/components/shared/dropdowns/DropDown";
import {
  officePropertyType,
  industrialPropertyType,
  getPropertyValues
} from "ui/components/properties/propertiesUtil";

import cx from "classnames";

import SpiralSpinner from "ui/components/spinners/SpiralSpinner";

import ItemForm from "./ItemForm";
import AutocompleteForm from "ui/components/shared/AutoComplete";

import Button from "ui/components/shared/Button";
import { resetSearch } from "ui/store/actions/properties";
import { isBlank } from "helpers/presence";
import { isCreditLensByGroupName } from "ui/components/opex/shared";

const AddPropertyFormBody = ({
  setForm,
  formData,
  selCountry,
  selState,
  selectCountry,
  selectRegion,
  selZip,
  selectZip,
  childToEditProperty,
  changePage,
  canUpdatePropertyType,
  closePopover,
  onSubmitAttachDoc,
  onSubmit,
  properties,
  widgetAuth,
  groupName
}) => {
  const {
    street_1,
    street_2,
    city,
    property_type,
    parcel_number,
    floors,
    year_built,
    size_sqft,
    name,
    latitude,
    longitude
  } = formData;
  const [isSubmit, setIsSubmit] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [propertyType, setPropertyType] = useState("");
  const labelStyle = { textTransform: "none", letterSpacing: "0" };
  let isCL = isCreditLensByGroupName(groupName);
  const propertyTypes = getPropertyValues(isCL);
  const getPropertyTypeName = id => {
    const PropertyType = propertyTypes.filter(item => item.id === id);
    return PropertyType[0] ? PropertyType[0].name : id;
  };

  function getFullAddress(data) {
    return `${!isBlank(data.street_1) ? data.street_1 : ""}${
      !isBlank(data.city) ? ", " + data.city : ""
    }${!isBlank(data.state) ? ", " + data.state : ""}${
      !isBlank(data.postal_code) ? " " + data.postal_code : ""
    }`;
  }
  function getPartialAddress(data) {
    return `${!isBlank(data.street_1) ? data.street_1 : ""}${
      !isBlank(data.city) ? ", " + data.city : ""
    }`;
  }

  function isDuplicateProperty(formData) {
    const inputAddress = getPartialAddress(formData);
    for (let i = 0; i < properties.length; i++) {
      const existingAddress = getPartialAddress(properties[i]);
      if (
        inputAddress == existingAddress &&
        formData.property_type == properties[i].property_type
      ) {
        setPropertyType(formData.property_type);
        return true;
      }
    }
    return false;
  }

  function onSubmitClick() {
    if (isDuplicateProperty(formData)) {
      setShowWarning(true);
    } else {
      if (childToEditProperty) {
        setIsSubmit(true);
        onSubmitAttachDoc(formData);
      } else {
        setIsSubmit(true);
        onSubmit(formData);
      }
    }
  }

  function updateForm(address) {
    formData.street_1 = address[0] ? address[0] : formData.street_1;
    formData.city = address[1] ? address[1] : formData.city;
    formData.latitude = address[4] ? address[4] : formData.latitude;
    formData.longitude = address[5] ? address[5] : formData.longitude;

    if (address[2]) {
      const stateAndZip = address[2].split(" ");
      if (stateAndZip.length == 2) {
        selectRegion(stateAndZip[0]);
        selectZip(stateAndZip[1]);
      } else {
        selectRegion(address[2]);
      }
    }
  }

  return (
    <div className="AddPropertyFormBody__section">
      <div className="AddPropertyFormBody__header">
        Add property
        <img
          src={require("ui/images/x-icon.svg")}
          style={{ height: "20px", float: "right", cursor: "pointer" }}
          onClick={() => closePopover()}
        />
      </div>
      <form>
        <div className="AddPropertyFormBody__fillable">
          {showWarning && (
            <div className="AddPropertyFormBody__duplicate-warning">
              {propertyType == industrialPropertyType.id ||
              propertyType == officePropertyType.id
                ? "An "
                : "A "}
              {getPropertyTypeName(propertyType) + " "} property with this
              address already exists.
            </div>
          )}
          <div className="AddPropertyFormBody__full_grid">
            <ItemForm
              type="custom"
              labelStyle={labelStyle}
              itemStyle={{ width: "100%" }}
            >
              <AutocompleteForm
                OnReset={resetSearch}
                onMenuSelect={updateForm}
                inputClass="SearchForm__input"
                placeholder="Start typing an address..."
                name="query"
                fullAddress={getFullAddress(formData)}
                isGooglePlaces={true}
                widgetAuth={widgetAuth}
              />
            </ItemForm>
          </div>
          <div className="AddPropertyFormBody__full_grid">
            <ItemForm
              label="Street"
              value={street_1}
              name="street_1"
              onChange={setForm}
              placeholder="Street Address"
              labelStyle={labelStyle}
              itemStyle={{ width: "100%" }}
            />

            <ItemForm
              label="Apt, floor, etc"
              name="street_2"
              value={street_2}
              onChange={setForm}
              placeholder="Optional"
              labelStyle={labelStyle}
              itemStyle={{ width: "100%" }}
            />
          </div>
          <div className="AddPropertyFormBody__city_state_zip_grid">
            <ItemForm
              label="City"
              name="city"
              value={city}
              onChange={setForm}
              placeholder="City"
              labelStyle={labelStyle}
              itemStyle={{ width: "100%" }}
            />
            <ItemForm
              label="State"
              type="custom"
              labelStyle={labelStyle}
              itemStyle={{ width: "100%" }}
            >
              <RegionDropdown
                defaultOptionLabel="State"
                country={formData.country}
                value={selState}
                labelType="short"
                valueType="short"
                onChange={val => selectRegion(val)}
                name="state"
                style={{
                  width: "100%",
                  height: "38px",
                  borderColor: "#FFFFFF"
                }}
              />
            </ItemForm>
            <ItemForm
              label="Zip Code"
              name="postal_code"
              value={selZip}
              onChange={setForm}
              placeholder="Zip Code"
              labelStyle={labelStyle}
              itemStyle={{ width: "100%" }}
            />
          </div>
          <div className="AddPropertyFormBody__full_grid">
            <ItemForm
              label="Country"
              type="custom"
              labelStyle={labelStyle}
              itemStyle={{ width: "100%" }}
            >
              <CountryDropdown
                name="country"
                showDefaultOption={true}
                value={selCountry}
                onChange={val => selectCountry(val)}
                style={{
                  borderColor: "#FFFFFF",
                  width: "100%",
                  height: "38px"
                }}
              />
            </ItemForm>
          </div>
          <div className="AddPropertyFormBody__full_grid">
            <ItemForm
              label="Name"
              name="name"
              value={name}
              onChange={setForm}
              placeholder="Property Name"
              itemStyle={{ width: "100%" }}
              labelStyle={labelStyle}
            />
          </div>
          <div className="AddPropertyFormBody__types_sqft_year_grid">
            <ItemForm
              label="Property Type *"
              type="custom"
              labelStyle={labelStyle}
              itemStyle={{ width: "100%" }}
            >
              <DropDown
                label="Property Type"
                name="property_type"
                value={property_type}
                values={getPropertyValues(isCL)}
                onChange={setForm}
                defaultValue={formData.property_type}
                disabled={!canUpdatePropertyType}
              />
            </ItemForm>
            <ItemForm
              label="Square Footage"
              name="size_sqft"
              value={size_sqft}
              onChange={setForm}
              type="number"
              placeholder="Sq. footage"
              labelStyle={labelStyle}
              itemStyle={{ width: "100%" }}
            />
            <ItemForm
              label="Year Built"
              name="year_built"
              value={year_built}
              onChange={setForm}
              type="number"
              placeholder="Year"
              itemStyle={{ width: "100%" }}
              labelStyle={labelStyle}
            />
          </div>
          <div className="AddPropertyFormBody__details">
            <ItemForm
              label="Floors"
              name="floors"
              value={floors}
              onChange={setForm}
              type="number"
              placeholder="# Floors"
              labelStyle={labelStyle}
              itemStyle={{ width: "100%" }}
            />
            <ItemForm
              label="Parcel Number"
              name="parcel_number"
              value={parcel_number}
              onChange={setForm}
              placeholder="Ex. 555000555"
              labelStyle={labelStyle}
              itemStyle={{ width: "100%" }}
            />
          </div>
        </div>
        <div className="AddPropertyFormBody__footer" style={{ float: "right" }}>
          {childToEditProperty && (
            <Button
              className={cx("Button__whiteBackground-cancel")}
              style={{
                marginRight: "10px",
                textTransform: "none",
                letterSpacing: 0,
                fontWeight: "normal"
              }}
              onClick={() => changePage("main")}
            >
              Back
            </Button>
          )}
          {isSubmit ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <SpiralSpinner style={{ marginRight: "5px" }} />
              Updating
            </div>
          ) : (
            formData.property_type && (
              <Button
                className={cx("Button__blueBackground")}
                style={{
                  border: "0px",
                  marginLeft: "0px",
                  padding: "8px 20px"
                }}
                onClick={async e => {
                  e.preventDefault();
                  await onSubmitClick();
                }}
              >
                Submit
              </Button>
            )
          )}
        </div>
      </form>
    </div>
  );
};

AddPropertyFormBody.propTypes = {
  setForm: PropTypes.func,
  formData: PropTypes.object,
  navigation: PropTypes.object,
  closePopover: PropTypes.func,
  onSubmitAttachDoc: PropTypes.func,
  onSubmit: PropTypes.func,
  selCountry: PropTypes.string,
  selState: PropTypes.string,
  selZip: PropTypes.string,
  selectCountry: PropTypes.func,
  selectRegion: PropTypes.func,
  selectZip: PropTypes.func,
  setRecommendedPropertySelected: PropTypes.func,
  childToEditProperty: PropTypes.bool,
  changePage: PropTypes.func,
  canUpdatePropertyType: PropTypes.bool,
  properties: PropTypes.array,
  widgetAuth: PropTypes.object,
  groupName: PropTypes.string
};
const mapStateToProps = ({ currentUser }) => ({
  groupName: currentUser.company.group_name
});

export default connect(mapStateToProps)(AddPropertyFormBody);
