import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { StickyTable, Row, Cell } from "react-sticky-table";
import DownloadExcel from "ui/components/shared/DownloadExcel";
import {
  mapUnnormalizedFieldsFromRentRoll,
  getExcelDownloadHeadersForSelectedProperty
} from "../rentRoll/property/helpers/documents";
import { withRouter } from "react-router";
import { apiFetch } from "ui/store/actions/apiClient";
import memoize from "memoize-one";
import cx from "classnames";

import {
  fields,
  formatDate,
  formatTimestamp,
  parseAddress,
  documentStatus,
  documentType,
  documentStatusStyle,
  columnDetails
} from "./helpers/manageFileListView";
import { documentStatus as DocumentStatus } from "server/middleware/api/helpers/documents";
import { documentType as DocumentType } from "server/middleware/api/helpers/documents";
import { exportExcel } from "services/downloadExcel";
import { exportOpexExcel } from "server/services/opex/exportOpexExcel";

import {
  deleteDocumentsCompany,
  fetchCompanyDocuments,
  fetchNeedAttentionDocuments
} from "ui/store/actions/rentRoll";

import { getRentRollFieldsForDropDown } from "ui/store/reducers/helpers/rentroll";
import { rentroll_columns } from "ui/components/helpers/excelColumns";

import {
  alignStickyTableHeader,
  alignStickyTableCell
} from "../rentRoll/property/helpers/documents";

import ThreeBounceSpinner from "ui/components/spinners/ThreeBounceSpinner";
import Button from "ui/components/shared/Button";
import Checkbox from "ui/components/shared/Checkbox2";
import EditDataPopup from "./editData/EditDataPopup";
import EditEffectiveDateModal from "./editEffectiveDate/EditEffectiveDateModal";
import SearchSortImage from "../shared/SearchSortImage";
import SearchPagination from "ui/components/shared/SearchPagination";
import { getPropertyValues } from "ui/components/properties/propertiesUtil";
import { isCreditLensByGroupName } from "ui/components/opex/shared";

const moment = require("moment");

class ManageFilesListView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: {},
      sortColumnName: "",
      sortOrder: "",
      pageIndex: 0,
      requestRefreshAction: false,
      filterObject: {},
      firstLoad: true
    };
  }
  componentDidMount() {
    const { fetchCompanyDocuments, widgetAuth } = this.props;
    const sortName = columnDetails["Last Updated"].sortName;
    const sortOrder = "desc";
    this.setState({
      sortColumnName: "Last Updated",
      sortOrder: sortOrder
    });
    fetchCompanyDocuments(0, 100, `${sortName}%7C${sortOrder}`, widgetAuth);
  }

  componentDidUpdate(prevProps) {
    const { uploadedDocs, analyzingDocs } = this.props;
    if (
      (prevProps.uploadedDocs !== uploadedDocs ||
        prevProps.analyzingDocs !== analyzingDocs) &&
      ((uploadedDocs && uploadedDocs.length > 0) ||
        (analyzingDocs && analyzingDocs.length > 0))
    ) {
      this.setState({
        requestRefreshAction: true,
        sortColumnName: "Last Updated",
        sortOrder: "desc"
      });
    }
  }

  handleRequestRefresh = () => {
    const { fetchCompanyDocuments, widgetAuth } = this.props;
    const { sortColumnName, sortOrder, pageIndex, filterObject } = this.state;
    const sortName = columnDetails[sortColumnName]
      ? columnDetails[sortColumnName].sortName
      : undefined;
    fetchCompanyDocuments(
      pageIndex,
      100,
      sortName ? `${sortName}%7C${sortOrder}` : "",
      widgetAuth,
      filterObject
    );
    this.setState({ requestRefreshAction: false });
  };

  handleToggle = e => {
    const name = parseInt(e.target.name);
    if (this.state.checked[name] === true) {
      this.setState({
        checked: { ...this.state.checked, [name]: false }
      });
    } else {
      this.setState({
        checked: { ...this.state.checked, [name]: true }
      });
    }
  };

  isDeleteButtonDisabled = () => {
    let disabled = true;
    Object.keys(this.state.checked).forEach(key => {
      if (this.state.checked[key]) {
        disabled = false;
      }
    });
    return disabled;
  };

  handleToggleAll = _ => {
    const { documents } = this.props;
    const docsChecked = {};
    documents.forEach(doc => {
      docsChecked[doc.id] = true;
    });
    if (this.state.checked["all"] === true) {
      documents.forEach(doc => {
        docsChecked[doc.id] = false;
      });
      this.setState({
        checked: { all: false, ...docsChecked }
      });
    } else {
      documents.forEach(doc => {
        docsChecked[doc.id] = true;
      });
      this.setState({
        checked: { all: true, ...docsChecked }
      });
    }
  };

  handleDeleteClick = async deleteML => {
    const {
      deleteDocumentsCompany,
      onDeleteCheckCurrentDoc,
      currentDocument,
      fetchNeedAttentionDocuments,
      widgetAuth,
      match: {
        params: { propertyId }
      }
    } = this.props;
    const docIdsToDelete = [];
    Object.keys(this.state.checked).forEach(key => {
      if (key === "all") {
        return;
      }
      if (this.state.checked[key] === true) {
        const docId = key;
        docIdsToDelete.push(docId);
      }
    });
    await deleteDocumentsCompany(
      propertyId,
      docIdsToDelete,
      deleteML,
      widgetAuth
    );
    await fetchNeedAttentionDocuments(widgetAuth);
    this.setState({ checked: {} });

    if (
      currentDocument &&
      docIdsToDelete.includes(currentDocument.id.toString())
    ) {
      onDeleteCheckCurrentDoc();
    }
  };

  handleSortClick = columnName => {
    const { fetchCompanyDocuments, widgetAuth } = this.props;
    const { sortOrder, pageIndex, filterObject } = this.state;
    const sortName = columnDetails[columnName].sortName;
    if (sortName === undefined || sortName.length === 0) {
      return;
    }
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    this.setState({
      sortColumnName: columnName,
      sortOrder: newSortOrder
    });
    fetchCompanyDocuments(
      pageIndex,
      100,
      sortName ? `${sortName}%7C${newSortOrder}` : "",
      widgetAuth,
      filterObject
    );
  };

  handlePaginationClick = newPageIdx => {
    const { fetchCompanyDocuments, widgetAuth } = this.props;
    const { sortColumnName, sortOrder, filterObject } = this.state;
    const sortName = columnDetails[sortColumnName]
      ? columnDetails[sortColumnName].sortName
      : undefined;
    fetchCompanyDocuments(
      newPageIdx,
      100,
      sortName ? `${sortName}%7C${sortOrder}` : "",
      widgetAuth,
      filterObject
    );
    this.setState({ pageIndex: newPageIdx });
  };

  getDocumentDownload = async doc => {
    const { widgetAuth } = this.props;
    const response = await apiFetch(
      `/api/rent-roll/download-document/${doc.id}`,
      {
        method: "get"
      },
      widgetAuth
    );
    const responseBody = await response.json();

    if (!response.ok) {
      throw new Error(responseBody.error);
    } else {
      const link = document.createElement("a");
      link.href = responseBody;
      link.download = doc.originalFilename;
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  };

  documentsFilter = memoize(
    async (
      propertyTypeFilter,
      documentTypeFilter,
      propertyListFilter,
      documentStatusFilter
    ) => {
      const { fetchCompanyDocuments, widgetAuth } = this.props;
      const { sortColumnName, sortOrder } = this.state;
      const sortName = columnDetails[sortColumnName]
        ? columnDetails[sortColumnName].sortName
        : undefined;
      const { pageIndex } = this.state;
      this.setState({ firstLoad: false });
      const propertyTypeFilterValues = Object.keys(propertyTypeFilter).filter(
        k => propertyTypeFilter[k] === true
      );
      const documentTypeFilterValues = Object.keys(documentTypeFilter).filter(
        k => documentTypeFilter[k] === true
      );
      const propertyListFilterValues = Object.keys(propertyListFilter).filter(
        k => propertyListFilter[k] === true
      );
      const documentStatusFilterValues = Object.keys(
        documentStatusFilter
      ).filter(k => documentStatusFilter[k] === true);
      const filterObject = {
        propertyType: propertyTypeFilterValues,
        docType: documentTypeFilterValues,
        propertyId: propertyListFilterValues,
        docStatus: documentStatusFilterValues
      };
      this.setState({ filterObject: filterObject });
      const updatedDocs = await fetchCompanyDocuments(
        pageIndex,
        100,
        sortName ? `${sortName}%7C${sortOrder}` : "",
        widgetAuth,
        filterObject
      );
      return updatedDocs;
    }
  );

  showDeleteButtons() {
    return Object.values(this.state.checked).some(Boolean);
  }

  getDownloadHeader = async selectedProperty => {
    const { widgetAuth } = this.props;
    const templateApiOptions = {
      method: "get"
    };
    const response = await apiFetch(
      `/api/rent-roll/fields`,
      templateApiOptions,
      widgetAuth
    );
    const responseBody = await response.json();
    const fieldsByPropertyType = responseBody.fieldsByPropertyType;
    const fieldNamesMappingForDropDown = getRentRollFieldsForDropDown(
      fieldsByPropertyType
    );

    return getExcelDownloadHeadersForSelectedProperty(
      selectedProperty,
      fieldNamesMappingForDropDown,
      rentroll_columns
    );
  };

  getDownloadRentRolls = async propertyId => {
    const { widgetAuth } = this.props;
    const response = await apiFetch(
      `/api/rent-roll/get-all-rent-rolls/${propertyId}`,
      {
        method: "get"
      },
      widgetAuth
    );
    if (!response.ok) {
      const responseBody = await response.json();
      throw new Error(responseBody.error);
    }
    const responseBody = await response.json();
    const allRentRolls = responseBody.allRentRolls;
    let allRentRollsForExcel = [...allRentRolls];

    return mapUnnormalizedFieldsFromRentRoll(allRentRollsForExcel);
  };

  downloadRentRollExcel = async doc => {
    const dataset = await this.getDownloadRentRolls(doc.propertyId);
    const filename = `rentroll-${moment().format()}.xlsx`;
    const headers = await this.getDownloadHeader(doc);
    exportExcel(dataset, filename, headers);
  };

  getDownloadOpexData = async documentId => {
    const { widgetAuth } = this.props;
    const response = await apiFetch(
      `/api/opex/get-opex/${documentId}`,
      {
        method: "get"
      },
      widgetAuth
    );
    if (!response.ok) {
      const responseBody = await response.json();
      throw new Error(responseBody.error);
    }
    const responseBody = await response.json();
    return responseBody.opex;
  };

  downloadOpexExcel = async doc => {
    const { groupName } = this.props;
    const data = await this.getDownloadOpexData(doc.id);
    const filename = `op-stmt-${moment().format()}.xlsx`;
    exportOpexExcel(data, filename, groupName, doc, true);
  };

  render() {
    const {
      documents,
      documentsGetStatus,
      userRole,
      totalPages,
      widgetAuth,
      propertyTypeFilter,
      documentTypeFilter,
      propertyListFilter,
      documentStatusFilter,
      groupName
    } = this.props;
    const { sortColumnName, sortOrder, pageIndex, filterObject } = this.state;
    let isCL = isCreditLensByGroupName(groupName);
    const propertyTypes = getPropertyValues(isCL);
    const getPropertyTypeName = id => {
      const PropertyType = propertyTypes.filter(item => item.id === id);
      return PropertyType[0] ? PropertyType[0].name : id;
    };
    const filteredDocuments = this.documentsFilter(
      propertyTypeFilter,
      documentTypeFilter,
      propertyListFilter,
      documentStatusFilter
    );

    const displayDocuments = this.state.firstLoad
      ? documents
      : filteredDocuments.length > 0
      ? filteredDocuments
      : documents;

    return (
      <div>
        {this.state.requestRefreshAction && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBottom: "0.5rem"
            }}
          >
            New documents available:
            <button
              className={cx("Button", "Button__blueButton")}
              style={{ cursor: "pointer", marginLeft: "5px" }}
              onClick={() => this.handleRequestRefresh()}
            >
              REFRESH
            </button>
          </div>
        )}
        <div className="ManageFilesList">
          <div>
            <SearchPagination
              offOne={true}
              pageIdx={pageIndex}
              totalPages={totalPages}
              updatePagination={this.handlePaginationClick}
            />
          </div>
          <StickyTable
            borderWidth={"1px"}
            style={{
              zIndex: 0,
              maxHeight: "calc(100vh - 230px)",
              overflow: "auto"
            }}
            stickyHeaderCount={1}
          >
            <Row className={cx("sticky-table-header", "row-header")}>
              <Cell
                key={`checkbox_all`}
                style={{
                  backgroundColor: "#F9F8F8",
                  verticalAlign: "middle"
                }}
              >
                <Checkbox
                  key={`checkbox_row_all`}
                  name={"all"}
                  checked={this.state.checked["all"] === true}
                  toggleCheck={this.handleToggleAll}
                />
              </Cell>
              {fields.map(column_name => (
                <Cell
                  className={cx(alignStickyTableHeader(column_name))}
                  style={{
                    backgroundColor: "#F9F8F8",
                    verticalAlign: "middle",
                    cursor: "pointer",
                    color: "#212121"
                  }}
                  key={column_name}
                  onClick={() => this.handleSortClick(column_name)}
                >
                  {column_name}
                  {column_name !== "Review Document" && column_name !== "" && (
                    <SearchSortImage
                      selectedColumnName={sortColumnName}
                      sortOrder={sortOrder}
                      columnName={column_name}
                      columnDetails={columnDetails}
                      isGray={true}
                    />
                  )}
                </Cell>
              ))}
            </Row>
            {documentsGetStatus === "FETCHING" ? (
              <ThreeBounceSpinner />
            ) : (
              displayDocuments &&
              displayDocuments.map((doc, index) => {
                const docTypePath =
                  doc.documentType === DocumentType.RENT_ROLL
                    ? "rent-roll"
                    : "opex";
                return (
                  <Row key={index}>
                    <Cell key={`checkbox_${index}`}>
                      <Checkbox
                        key={`checkbox_row_${index}`}
                        className={cx(
                          "sticky-table__cell",
                          "sticky-table__cell--small"
                        )}
                        name={doc.id.toString()}
                        checked={this.state.checked[doc.id] === true}
                        toggleCheck={this.handleToggle}
                      />
                    </Cell>
                    <Cell
                      key="file_name"
                      className={cx(
                        "sticky-table__cell",
                        alignStickyTableCell("File Name")
                      )}
                      style={{
                        color: "#212121"
                      }}
                    >
                      <div
                        className="ManageFilesList__doc-filename"
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "normal",
                          maxWidth: "200px"
                        }}
                      >
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => this.getDocumentDownload(doc)}
                        >
                          {doc.originalFilename}
                        </span>
                      </div>
                      <div className="ManageFilesList__doc-date">
                        {formatDate(doc.createdAt)}
                      </div>
                      <div className="ManageFilesList__doc-username">
                        {doc.userEmail}
                      </div>
                    </Cell>
                    <Cell
                      style={{ color: "#212121" }}
                      key="last_updated"
                      className={cx(
                        "sticky-table__cell",
                        alignStickyTableCell("Last Updated")
                      )}
                    >
                      <div
                        className="ManageFilesList__doc-date"
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "normal",
                          maxWidth: "200px"
                        }}
                      >
                        {formatTimestamp(doc.updatedAt)}
                      </div>
                    </Cell>
                    <Cell
                      style={{
                        color: "#212121",
                        overflowWrap: "break-word",
                        whiteSpace: "normal",
                        maxWidth: "200px"
                      }}
                      key="portfolio"
                      className={cx(
                        "sticky-table__cell",
                        alignStickyTableCell("User Name")
                      )}
                    >
                      {doc.userEmail}
                    </Cell>
                    <Cell
                      key="effective-date"
                      className={cx(
                        "sticky-table__cell",
                        alignStickyTableCell("Effective Date")
                      )}
                      style={{ color: "#212121" }}
                    >
                      <div className="ManageFilesList__doc-effective-date">
                        {formatDate(doc.effectiveDate)}
                        {doc.propertyId &&
                          doc.status != DocumentStatus.ANALYZING && (
                            <EditEffectiveDateModal
                              currentEffectiveDate={doc.effectiveDate}
                              documentId={doc.id}
                              widgetAuth={widgetAuth}
                              filterObject={filterObject}
                              setSortByLastUpdated={() =>
                                this.setState({
                                  sortColumnName: "Last Updated",
                                  sortOrder: "desc",
                                  pageIndex: 0
                                })
                              }
                            />
                          )}
                      </div>
                    </Cell>
                    <Cell
                      key="property"
                      className={cx(
                        "sticky-table__cell",
                        alignStickyTableCell("Status")
                      )}
                      style={{
                        color: "#212121",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "normal",
                        maxWidth: "200px"
                      }}
                    >
                      {doc.propertyId ? (
                        <div>
                          <div className="ManageFilesList__doc-property-name">
                            {doc.propertyName}
                          </div>
                          <div className="ManageFilesList__doc-address">
                            {parseAddress(doc)}
                          </div>
                        </div>
                      ) : (
                        doc.status != DocumentStatus.ANALYZING && (
                          <EditDataPopup
                            portfolioId={doc.portfolioId}
                            documentId={doc.id}
                            originalFilename={doc.originalFilename}
                            widgetAuth={widgetAuth}
                            filterObject={filterObject}
                            setSortByLastUpdated={() =>
                              this.setState({
                                sortColumnName: "Last Updated",
                                sortOrder: "desc",
                                pageIndex: 0
                              })
                            }
                          />
                        )
                      )}
                    </Cell>
                    <Cell
                      key="type"
                      className={cx(
                        "sticky-table__cell",
                        alignStickyTableCell("Status")
                      )}
                      style={{ color: "#212121" }}
                    >
                      {doc.propertyType ? (
                        <div className="ManageFilesList__doc-propertytype">
                          {doc.propertyType &&
                            getPropertyTypeName(doc.propertyType)}
                        </div>
                      ) : (
                        ""
                      )}
                    </Cell>
                    <Cell
                      key="document"
                      className={cx(
                        "sticky-table__cell",
                        alignStickyTableCell("Status")
                      )}
                      style={{ color: "#212121" }}
                    >
                      <div className="ManageFilesList__doc-doctype">
                        {documentType[doc.documentType]}
                      </div>
                    </Cell>
                    <Cell
                      key="status"
                      className={cx(
                        "sticky-table__cell",
                        alignStickyTableCell("Status")
                      )}
                      style={{ color: "#212121" }}
                    >
                      <div
                        className={
                          "ManageFilesList__" + documentStatusStyle[doc.status]
                        }
                      >
                        {documentStatus[doc.status]}
                      </div>
                    </Cell>
                    <Cell
                      key="actions"
                      className={cx(
                        "sticky-table__cell",
                        alignStickyTableCell()
                      )}
                      style={{ color: "#212121" }}
                    >
                      <div className="ManageFilesList__action">
                        {doc.status === DocumentStatus.COMPLETED && (
                          <DownloadExcel
                            isManageFilesPage={true}
                            overrideFunction={
                              doc.documentType === DocumentType.RENT_ROLL
                                ? () => this.downloadRentRollExcel(doc)
                                : () => this.downloadOpexExcel(doc)
                            }
                          />
                        )}
                        {(doc.status != DocumentStatus.TEXTRACT_TIMEOUT ||
                          doc.status != DocumentStatus.FAILED) && (
                          <Button
                            className={
                              doc.status === DocumentStatus.COMPLETED
                                ? "NavLink__secondary NavLink__completed-doc"
                                : doc.status == DocumentStatus.PENDING_REVIEW
                                ? "NavLink__primary"
                                : "NavLink__disabled"
                            }
                            pathname={`/${docTypePath}/split-panel/property/${
                              doc.propertyId
                            }/document/${doc.id}${
                              doc.portfolioId
                                ? `/portfolio/${doc.portfolioId}`
                                : ""
                            }`}
                          >
                            {doc.status === DocumentStatus.COMPLETED
                              ? "Edit"
                              : "Open"}
                          </Button>
                        )}
                      </div>
                    </Cell>
                  </Row>
                );
              })
            )}
          </StickyTable>
          {this.showDeleteButtons() && (
            <>
              <Button
                disabled={this.isDeleteButtonDisabled()}
                onClick={() => this.handleDeleteClick(false)}
                className="Button__deleteButton"
              >
                Delete
              </Button>
              {userRole === "superadmin" && (
                <Button
                  disabled={this.isDeleteButtonDisabled()}
                  onClick={() => this.handleDeleteClick(true)}
                  className="Button__deleteButton"
                >
                  Delete with ML
                </Button>
              )}
            </>
          )}
          <div>
            <SearchPagination
              offOne={true}
              pageIdx={pageIndex}
              totalPages={totalPages}
              updatePagination={this.handlePaginationClick}
            />
          </div>
        </div>
      </div>
    );
  }
}

ManageFilesListView.propTypes = {
  rentRoll: PropTypes.object,
  propertyTypeFilter: PropTypes.object,
  documentTypeFilter: PropTypes.object,
  propertyListFilter: PropTypes.object,
  documentStatusFilter: PropTypes.object,
  documents: PropTypes.array,
  uploadedDocs: PropTypes.array,
  analyzingDocs: PropTypes.array,
  match: PropTypes.object,
  deleteDocumentsCompany: PropTypes.func,
  fetchCompanyDocuments: PropTypes.func,
  documentsGetStatus: PropTypes.string,
  userRole: PropTypes.string,
  onDeleteCheckCurrentDoc: PropTypes.func,
  fetchNeedAttentionDocuments: PropTypes.func,
  exportExcel: PropTypes.func,
  currentDocument: PropTypes.object,
  totalPages: PropTypes.number,
  widgetAuth: PropTypes.object,
  groupName: PropTypes.string
};

function mapStateToProps(state) {
  return {
    rentRoll: state.rentRoll,
    documents: state.rentRoll.documents,
    documentsGetStatus: state.rentRoll.status,
    userRole: state.currentUser.role,
    totalPages: state.rentRoll.totalPages,
    uploadedDocs: state.rentRoll.uploadingDocs,
    analyzingDocs: state.rentRoll.analyzingDocs,
    widgetAuth: state.currentUser.widgetAuth,
    groupName: state.currentUser.company.group_name
  };
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      deleteDocumentsCompany,
      fetchNeedAttentionDocuments,
      fetchCompanyDocuments,
      exportExcel
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ManageFilesListView)
);
