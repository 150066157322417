import PropTypes from "prop-types";
import cx from "classnames";
import NavLink from "./NavLink";

export default function Button({
  children,
  className,
  style,
  disabled,
  onClick,
  pathname
}) {
  return (
    <>
      {pathname ? (
        <NavLink to={{ pathname }} className={cx("NavLink", className)}>
          {children}
        </NavLink>
      ) : (
        <button
          className={cx("Button", className)}
          disabled={disabled}
          onClick={onClick}
          style={style}
        >
          {children}
        </button>
      )}
    </>
  );
}

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  pathname: PropTypes.string
};

Button.defaultProps = {
  disabled: false
};
