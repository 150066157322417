import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import { runFullUsageImport } from "ui/store/actions/data";

import { hasSuperAdminAccess } from "helpers/authorization";

import AdminLayout from "../AdminLayout";
import Button from "ui/components/shared/Button";
import AuthenticateAdmin from "ui/components/routing/AuthenticateAdmin";
import { showFlash } from "ui/store/actions/flash";

const DataDashboard = ({ runFullUsageImport, showFlash }) => (
  <AdminLayout
    className="RentRollDashboard"
    breadcrumbs={[{ label: "Dashboard", path: "/admin" }]}
    title="Manage QUIQ property data"
  >
    {hasSuperAdminAccess && (
      <div style={{ margin: "20px" }}>
        {/* <Button
          className="RentRollDashboard__button"
          onClick={e => {
            if (
              confirm("Are you sure you want to reimport elasticsearch index?")
            ) {
              runFullESImport();
              showFlash({
                type: "success",
                message: `Starting ElasticSearch Full Import...`
              });
            } else {
              e.preventDefault();
            }
          }}
        >
          Full ES Import
        </Button> */}
        <br />
        {hasSuperAdminAccess && (
          <Button
            className="RentRollDashboard__button"
            onClick={e => {
              if (
                confirm(
                  "Are you sure you want to start a retroactive usage report?"
                )
              ) {
                runFullUsageImport();
                showFlash({
                  type: "success",
                  message: `Starting Retroactive Usage Data Import...`
                });
              } else {
                e.preventDefault();
              }
            }}
          >
            Full Retroactive Usage Data
          </Button>
        )}
      </div>
    )}
  </AdminLayout>
);

DataDashboard.propTypes = {
  // runFullESImport: PropTypes.func,
  showFlash: PropTypes.func,
  runFullUsageImport: PropTypes.func
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ runFullUsageImport, showFlash }, dispatch);
}

const AuthDataDashboard = AuthenticateAdmin(DataDashboard);

export default connect(null, mapDispatchToProps)(AuthDataDashboard);
