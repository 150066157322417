import PropTypes from "prop-types";
import cx from "classnames";
import _ from "lodash";
import { useState } from "react";
import AccountsBaseTable from "./components/AccountsBaseTable";
import CompletedStatus from "../../../CompletedStatus/CompletedStatus";
import { FilterPopup } from "../../../FilterPopup/FilterPopup";
import { TemplatePopup } from "./components/TemplatePopup";
import OpexDocument from "../../../../modelOpexDocument";
import useDocument from "ui/components/opex/hooks/useDocument";
import ToggleSwitch from "ui/components/apps/ToggleSwitch";
import {
  isCellConfirmed,
  isCellCorrected,
  isConfidenceNeedsReview,
  isConfidenceUnmapped,
  isCPMByGroupName,
  CELL_STATUS_CORRECTED,
  CONFIDENCE_CONFIRMED,
  CONFIDENCE_NEEDS_REVIEW,
  CONFIDENCE_UNMAPPED,
  CONFIDENCE_NEEDS_REVIEW_COLOR,
  CONFIDENCE_UNMAPPED_COLOR,
  CONFIDENCE_CONFIRMED_COLOR,
  CELL_STATUS_CORRECTED_COLOR
} from "ui/components/opex/shared";

const AccountsLeftPanel = ({
  tables,
  currentTable,
  rawDataJson,
  onUpdateRawDataRows,
  metaDataJson,
  toggleExcludedRow,
  templates,
  template,
  onChangeTemplate,
  onClickRowName,
  setStatusHeaderCounters
}) => {
  const { document, groupName } = useDocument();
  const [filterValues, setFilterValues] = useState([]);
  const [hideExcludedRows, setHideExcludedRows] = useState(false);
  const [filterDisabled, setFilterDisabled] = useState(false);
  const documentModel = new OpexDocument(document);
  const filterOptions = [
    {
      label: "Medium confidence",
      value: CONFIDENCE_NEEDS_REVIEW,
      squareColor: CONFIDENCE_NEEDS_REVIEW_COLOR
    },
    {
      label: "Needs review",
      value: CONFIDENCE_UNMAPPED,
      squareColor: CONFIDENCE_UNMAPPED_COLOR
    },
    {
      label: "Confirmed by user",
      value: CONFIDENCE_CONFIRMED,
      squareColor: CONFIDENCE_CONFIRMED_COLOR
    },
    {
      label: "Changed by user",
      value: CELL_STATUS_CORRECTED,
      squareColor: CELL_STATUS_CORRECTED_COLOR
    }
  ];

  const filterTableAndData = (
    tables,
    rawDataJson,
    metaDataJson,
    hideExcludedRows
  ) => {
    const filteredTablesData = _.cloneDeep(tables);
    const filteredRawDataJson = _.cloneDeep(rawDataJson);
    const filteredMetaDataJson = _.cloneDeep(metaDataJson);

    filteredTablesData.forEach(table => {
      const tableIndex = table.tableIndex;
      const currentPageIndex = table.pageIndex;
      const metaDataIndex = table.metaDataIndex;
      const usesExcludedRows =
        metaDataJson?.headers?.[metaDataIndex]?.excludedRows;

      if (usesExcludedRows) {
        filteredMetaDataJson.headers[metaDataIndex].excludedRows = !filterValues.length
          ? metaDataJson?.headers?.[metaDataIndex]?.excludedRows
          : [];
      }

      if (filterValues.length) {
        table.tableData = table.tableData.filter((row, rowIndex) => {
          const confidence = row[table.chartsOfAccountsIndex]?.predef?.confidence || 0;
          const corrected = row[table.chartsOfAccountsIndex]?.corrected;
          const included =
            (isCellCorrected(corrected) &&
              filterValues.some(filterV => filterV === CELL_STATUS_CORRECTED)) ||
            (isCellConfirmed(corrected) &&
              filterValues.some(filterV => filterV === CONFIDENCE_CONFIRMED)) ||
            (isConfidenceNeedsReview(confidence) &&
              filterValues.some(
                filterV => filterV === CONFIDENCE_NEEDS_REVIEW
              )) ||
            (isConfidenceUnmapped(confidence) &&
              filterValues.some(filterV => filterV === CONFIDENCE_UNMAPPED));

          if (included && usesExcludedRows) {
            filteredMetaDataJson.headers[metaDataIndex].excludedRows.push(
              metaDataJson.headers[metaDataIndex].excludedRows[rowIndex]
            );
          }

          return included;
        });
        filteredRawDataJson.pages[currentPageIndex].tableData[
          table.tableIndex
        ] = table.tableData;
      }

      if (usesExcludedRows && hideExcludedRows) {
        table.tableData = table.tableData.filter((row, rowIndex) =>
          !filteredMetaDataJson.headers[metaDataIndex].excludedRows[rowIndex]
        );
        filteredRawDataJson.pages[currentPageIndex].tableData[
          tableIndex
        ] = filteredRawDataJson.pages[currentPageIndex].tableData[
          tableIndex
        ].filter((row, rowIndex) =>
          !filteredMetaDataJson.headers[metaDataIndex].excludedRows[rowIndex]
        );
        filteredMetaDataJson.headers[metaDataIndex].excludedRows = [];
      }
    })

    return [filteredTablesData, filteredRawDataJson, filteredMetaDataJson];
  };
  const [
    filteredTablesData,
    filteredRawDataJson,
    filteredMetaDataJson
  ] = filterTableAndData(tables, rawDataJson, metaDataJson, hideExcludedRows);

  const handleOptionCount = filterOption => {
    let count = 0;
    tables.forEach((table) => {
      count = count + table.tableData.filter((row, rowIndex) => {
        const confidence = row[table.chartsOfAccountsIndex]?.predef?.confidence || 0;
        const corrected = row[table.chartsOfAccountsIndex]?.corrected;
        const metaDataIndex = table.metaDataIndex;
        const isExcluded = _.get(
          metaDataJson,
          `headers[${metaDataIndex}].excludedRows[${rowIndex}]`,
          false
        );

        if (isExcluded) {
          return false;
        }

        switch (filterOption) {
          case CONFIDENCE_NEEDS_REVIEW:
            return isConfidenceNeedsReview(confidence);
          case CONFIDENCE_UNMAPPED:
            return isConfidenceUnmapped(confidence);
          case CONFIDENCE_CONFIRMED:
            return isCellConfirmed(corrected);
          case CELL_STATUS_CORRECTED:
            return corrected;
          default:
            return false;
        }
      }).length;
    });

    setStatusHeaderCounters(counter => (
      (counter[filterOption] === count) ?
        counter :
        { ...counter, [filterOption]: count }
    ))

    return count;
  };

  const shouldShowPublishedDate =
    isCPMByGroupName(groupName) && documentModel.isStatusCompleted();

  const gridClassName = cx(
    "accounts-grid",
    shouldShowPublishedDate ? "cpm-completed" : ""
  );

  return (
    <div className={gridClassName}>
      {shouldShowPublishedDate && (
        <div className="accounts-published">
          <CompletedStatus
            date={documentModel.getLastPublishedDate()}
            text="Published on"
            type="singleline"
          />
        </div>
      )}
      <div className="OpenPanelView__AccountsLeftPanel--actions accounts-actions">
        <div
          id="AccountsLeftPanelFilter"
          className="OpenPanelView__AccountsLeftPanel--filter"
        >
          <FilterPopup
            options={filterOptions}
            disabled={filterDisabled}
            getOptionCount={handleOptionCount}
            onClearFilter={() => setFilterValues([])}
            onFilter={setFilterValues}
          />
        </div>
        {isCPMByGroupName(groupName) && (
          <div className="OpenPanelView__AccountsLeftPanel--template">
            <TemplatePopup
              value={template}
              templates={templates}
              onChange={onChangeTemplate}
            />
          </div>
        )}
        <div className="OpenPanelView__AccountsLeftPanel--toggle">
          <span>Hide excluded rows</span>
          <ToggleSwitch
            isOn={hideExcludedRows}
            className="ExcludedRowsToggle"
            handleToggle={() => setHideExcludedRows(currentVal => !currentVal)}
          />
        </div>
      </div>
      <div className="accounts-content">
        <AccountsBaseTable
          template={template}
          currentTable={currentTable}
          tables={filteredTablesData}
          rawDataJson={filteredRawDataJson}
          onUpdateRawDataRows={onUpdateRawDataRows}
          metaDataJson={filteredMetaDataJson}
          toggleExcludedRow={toggleExcludedRow}
          disableFilter={setFilterDisabled}
          onClickRowName={onClickRowName}
        />
      </div>
    </div>
  );
};

export default AccountsLeftPanel;

AccountsLeftPanel.propTypes = {
  tables: PropTypes.array,
  currentTable: PropTypes.number,
  rawDataJson: PropTypes.object,
  onUpdateRawDataRows: PropTypes.func,
  metaDataJson: PropTypes.object,
  toggleExcludedRow: PropTypes.func,
  onClickRowName: PropTypes.func,
  setStatusHeaderCounters: PropTypes.func
};
