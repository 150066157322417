import PropTypes from "prop-types";

export default function Cell({
  displayText,
  id,
  onClick,
  rowIndex,
  columnIndex
}) {
  function handleClick() {
    onClick(id, rowIndex, columnIndex);
  }

  return (
    <div className="ExcelTable__Cell" onClick={handleClick}>
      {displayText}
    </div>
  );
}

Cell.propTypes = {
  displayText: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  rowIndex: PropTypes.number.isRequired,
  columnIndex: PropTypes.number.isRequired
};
