import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  updateUploadStatus,
  fetchUploadingDocuments,
  fetchNeedAttentionDocuments
} from "ui/store/actions/rentRoll";
import { setIntervalRunning, setIntervalId } from "ui/store/actions/statusBar";
import { showFlash, clearFlash } from "ui/store/actions/flash";

export class UploadStatusBar extends Component {
  interval = null;

  componentDidMount() {
    const {
      fetchUploadingDocuments,
      fetchNeedAttentionDocuments,
      intervalRunning,
      intervalId,
      widgetAuth
    } = this.props;

    fetchUploadingDocuments(widgetAuth);
    fetchNeedAttentionDocuments(widgetAuth);

    if (intervalRunning === false) {
      clearInterval(intervalId);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      uploadedDocs,
      analyzingDocs,
      totalCountNeedAttentionDocs,
      needAttentionDocs,
      updateUploadStatus,
      fetchUploadingDocuments,
      fetchNeedAttentionDocuments,
      setIntervalRunning,
      intervalRunning,
      setIntervalId,
      intervalId,
      showFlash,
      clearFlash,
      widgetAuth
    } = this.props;

    if (
      prevProps.uploadedDocs !== uploadedDocs ||
      prevProps.analyzingDocs !== analyzingDocs ||
      prevProps.needAttentionDocs !== needAttentionDocs ||
      prevProps.totalCountNeedAttentionDocs !== totalCountNeedAttentionDocs
    ) {
      if (uploadedDocs.length > 0) {
        showFlash({
          type: "upload-warning",
          message: "Don't leave the page during upload.",
          close: false
        });
        updateUploadStatus("uploading");
        setIntervalRunning(true);
      } else if (analyzingDocs.length > 0 && uploadedDocs.length === 0) {
        showFlash({
          type: "upload-success",
          message: "Upload finished."
        });
        updateUploadStatus("analyzing");
        setIntervalRunning(true);
      } else if (
        uploadedDocs.length === 0 &&
        analyzingDocs.length === 0 &&
        totalCountNeedAttentionDocs > 0
      ) {
        clearFlash();
        updateUploadStatus("processed");
        setIntervalRunning(false);
      } else if (
        uploadedDocs.length === 0 &&
        analyzingDocs.length === 0 &&
        totalCountNeedAttentionDocs === 0
      ) {
        clearFlash();
        updateUploadStatus("");
      }
    }

    if (
      prevProps.intervalRunning !== intervalRunning &&
      prevProps.intervalRunning === false &&
      intervalRunning === true
    ) {
      this.interval = setInterval(() => {
        fetchUploadingDocuments(widgetAuth);
        fetchNeedAttentionDocuments(widgetAuth);
      }, 5000);
      setIntervalId(this.interval);
    } else if (
      prevProps.intervalRunning !== intervalRunning &&
      intervalRunning === false
    ) {
      clearInterval(intervalId);
    }
  }

  render() {
    return (
      <div
        className="UploadStatusBar__placeholder"
        style={{ cursor: "pointer", width: "100%", padding: "0 10px" }}
      ></div>
    );
  }
}

UploadStatusBar.propTypes = {
  uploadStatus: PropTypes.string,
  uploadedDocs: PropTypes.array,
  analyzingDocs: PropTypes.array,
  totalCountNeedAttentionDocs: PropTypes.number,
  needAttentionDocs: PropTypes.array,
  updateUploadStatus: PropTypes.func,
  fetchUploadingDocuments: PropTypes.func,
  fetchNeedAttentionDocuments: PropTypes.func,
  setIntervalRunning: PropTypes.func,
  intervalRunning: PropTypes.bool,
  setIntervalId: PropTypes.func,
  showFlash: PropTypes.func,
  clearFlash: PropTypes.func,
  intervalId: PropTypes.any,
  widgetAuth: PropTypes.object
};

function mapStateToProps(state) {
  return {
    uploadedDocs: state.rentRoll.uploadingDocs,
    analyzingDocs: state.rentRoll.analyzingDocs,
    totalCountNeedAttentionDocs: state.rentRoll.totalCountNeedAttentionDocs,
    needAttentionDocs: state.rentRoll.needAttentionDocs,
    numUploadsInProgress: state.rentRoll.numUploadsInProgress,
    uploadStatus: state.rentRoll.uploadStatus,
    intervalRunning: state.statusBar.intervalRunning,
    intervalId: state.statusBar.intervalId,
    widgetAuth: state.currentUser.widgetAuth
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateUploadStatus,
      fetchUploadingDocuments,
      fetchNeedAttentionDocuments,
      setIntervalRunning,
      setIntervalId,
      showFlash,
      clearFlash
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadStatusBar);
