import { Component } from "react";
import AdminLayout from "../AdminLayout";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import TemplateCard from "ui/components/admin/templates/TemplateCard";
import {
  clearCompanyTemplatesStore,
  fetchCompanyTemplates
} from "ui/store/actions/companyTemplates";
import AuthenticateAdmin from "ui/components/routing/AuthenticateAdmin";
import Button from "ui/components/shared/Button";
import { apiFetch } from "ui/store/actions/apiClient";
import { TemplateStatus } from "helpers/opex";
import {
  DUPLICATE,
  DELETE
} from "ui/components/admin/templates/shared/constants";
import NotificationToast from "./shared/NotificationToast";
import { isCreditLensByGroupName } from "ui/components/opex/shared";

class TemplatesDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userCompany: null,
      isCL: false
    };
  }
  componentWillUnmount() {
    const { clearTemplatesStoreArray } = this.props;
    clearTemplatesStoreArray();
  }

  componentDidMount() {
    const { loadTemplates, currentUser } = this.props;
    if (currentUser) {
      this.setState({
        userCompany: currentUser.company,
        isCL: isCreditLensByGroupName(currentUser.company.group_name)
      });
    }

    loadTemplates();
  }

  numberStartsAt(name) {
    let nameArray = name.split("").reverse();
    for (let i = 0; i < nameArray.length; i++) {
      if (isNaN(nameArray[i])) return nameArray.length - i + 1;
    }
    return nameArray.length - 1;
  }

  updateTemplate = async (templateId, type) => {
    switch (type) {
      case TemplateStatus.UNPUBLISHED:
      case TemplateStatus.PUBLISHED:
      case TemplateStatus.ARCHIVED:
        {
          const resp = await apiFetch(`/api/opex/templates/${templateId}`, {
            method: "put",
            body: JSON.stringify({ status: type })
          });
          const responseBody = await resp.json();
          if (!resp.ok) {
            NotificationToast(
              "Error",
              `An error occurred updating template status: ${responseBody.error}`
            );
            throw new Error(responseBody.error);
          } else {
            this.props.loadTemplates();
          }
        }
        break;
      case DUPLICATE: {
        let template = this.props.templates.find(t => t.id === templateId);
        let newName = `Copy of ${template.name}`;
        const templateNameWithoutNumbers = template.name.substring(
          0,
          this.numberStartsAt(template.name)
        );
        let numberOfCopies = 0;
        this.props.templates.forEach(t => {
          let numberStartsAt = this.numberStartsAt(t.name);
          if (t.name.startsWith("Copy of ")) {
            if (
              t.name.substring(8, numberStartsAt).trim() ===
              templateNameWithoutNumbers
            ) {
              numberOfCopies += 1;
              newName = `${t.name} ${numberOfCopies + 1}`;
            }
          }
        });

        const resp = await apiFetch(`/api/opex/templates/${templateId}`, {
          method: "post",
          body: JSON.stringify({ name: newName })
        });
        const responseBody = await resp.json();
        if (!resp.ok) {
          NotificationToast(
            "Error",
            `An error occurred making a copy of the template:  ${responseBody.error}`
          );
          throw new Error(responseBody.error);
        } else {
          NotificationToast("Success", "Template copied successfully.");
          this.props.loadTemplates();
        }
        break;
      }
      case DELETE: {
        const response = await apiFetch(`/api/opex/templates/${templateId}`, {
          method: "delete"
        });
        if (!response.ok) {
          NotificationToast(
            "Error",
            `An error occurred deleting the template: ${response.statusText}`
          );
        } else {
          NotificationToast("Success", "Template deleted successfully.");
          this.props.loadTemplates();
        }
        break;
      }
    }
  };
  handleOnChange = async e => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    const response = await apiFetch(`/api/opex/templates/import`, {
      method: "post",
      "Content-Type": "multipart/form-data",
      body: formData
    });
    if (!response.ok) {
      NotificationToast(
        "Error",
        `An error occurred importing the template: ${response.statusText}`
      );
    } else {
      NotificationToast("Success", "Template imported successfully.");
      this.props.loadTemplates();
    }
  };

  render() {
    const companyName =
      this.state.userCompany === null
        ? ""
        : `: ${this.state.userCompany.company_name}`;

    return (
      <AdminLayout
        className="AdminTemplatesDashboard"
        breadcrumbs={[{ label: "Dashboard", path: "/admin" }]}
        title={`Manage Templates${companyName}`}
      >
        <div className="AdminTemplatesDashboard__body">
          <h1 className="AdminTemplatesDashboard-title main-title">
            CoA Template
          </h1>
          <div className="AdminTemplatesDashboard__body-creationPanel">
            <h1 className="AdminTemplatesDashboard__body-creationPanel-title">
              Create new chart of accounts
            </h1>
            <p className="AdminTemplatesDashboard__body-creationPanel-description">
              Create a new template for your organization. Decide the
              categories, sub-categories and accounts you need to map from your
              documents.
            </p>
            <div className="AdminTemplatesDashboard__body-creationPanel-buttons">
              {this.state.isCL && (
                <span className="BrowseUpload Button Button__blueButton">
                  <form className="BrowseUpload__form">
                    <input
                      id="file"
                      className="BrowseUpload__input"
                      type="file"
                      onChange={e => this.handleOnChange(e)}
                      onClick={e => (e.target.value = null)}
                    />
                    <label htmlFor="file">Import file</label>
                  </form>
                </span>
              )}
              {!this.state.isCL && (
                <Button
                  className={"Button Button__blueBackground"}
                  onClick={() => this.props.history.push("templates/new")}
                >
                  Create
                </Button>
              )}
            </div>
          </div>
          {this.props.templates &&
            Object.entries(
              this.props.templates.reduce((prev, curr) => {
                prev[curr.status] = prev[curr.status] || [];
                prev[curr.status].push(curr);
                return prev;
              }, Object.create(null))
            )
              .sort(a => {
                if (a[0] === "PUBLISHED") {
                  return -1;
                }
              })
              .map(templates => (
                <div
                  key={templates[0]}
                  className="AdminTemplatesDashboard__body-cardsContainer"
                >
                  <h1 className="AdminTemplatesDashboard-title">
                    {`${templates[0].charAt(0)}${templates[0]
                      .slice(1)
                      .toLocaleLowerCase()}
                       templates`}
                  </h1>
                  <div className="AdminTemplatesDashboard__body-cardsContainer-section">
                    {templates[1].map(template => (
                      <TemplateCard
                        key={template.id}
                        name={template.name}
                        description={template.description}
                        externalRefId={template.ext_ref_id}
                        types={template.property_types}
                        created={template.created_at}
                        createdBy={template.created_by.email}
                        updated={template.updated_at}
                        updatedBy={template.updated_by.email}
                        pathname={`/admin/templates/${template.id}`}
                        inUse={template.document_ids.length > 0}
                        isPublished={
                          template.status === TemplateStatus.PUBLISHED
                        }
                        isArchived={template.status === TemplateStatus.ARCHIVED}
                        isCL={this.state.isCL}
                        templateId={template.id}
                        updateTemplate={this.updateTemplate}
                      />
                    ))}
                  </div>
                </div>
              ))}
        </div>
      </AdminLayout>
    );
  }
}

TemplatesDashboard.propTypes = {
  currentUser: PropTypes.object,
  company: PropTypes.object,
  templates: PropTypes.array,
  clearTemplatesStoreArray: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  loadTemplates: PropTypes.func
};

function mapStateToProps({ currentUser, companyTemplates }) {
  return {
    currentUser,
    templates: companyTemplates.templates
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearTemplatesStoreArray: () => dispatch(clearCompanyTemplatesStore()),
    loadTemplates: () => dispatch(fetchCompanyTemplates())
  };
}

const TemplatesDashboardComponent = AuthenticateAdmin(TemplatesDashboard);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TemplatesDashboardComponent);
