export const UPDATE_FILTER_PANEL = "UPDATE_FILTER_PANEL";
export const UPDATE_SELECTED_FILTER = "UPDATE_SELECTED_FILTER";
export const UPDATE_FILTER = "UPDATE_FILTER";
export const SET_FILTERS = "SET_FILTERS";
export const RESET_FILTERS = "RESET_FILTERS";
export const ADD_TO_ACTIVE_FILTERS = "ADD_TO_ACTIVE_FILTERS";
export const REMOVE_FROM_ACTIVE_FILTERS = "REMOVE_FROM_ACTIVE_FILTERS";
export const CLEAR_FILTER_LIST = "CLEAR_FILTER_LIST";
export const SET_SEARCHED_FILTERS = "SET_SEARCHED_FILTERS";
export const UPDATE_ELASTIC_SEARCH_METRICS = "UPDATE_ELASTIC_SEARCH_METRICS";
export const SET_SEARCH_INPUT = "SET_SEARCH_INPUT";
export const UPDATE_ACTIVE_SEARCH_INPUT = "UPDATE_ACTIVE_SEARCH_INPUT";

export const updateIsFilterOpen = isFilterOpen => ({
  type: UPDATE_FILTER_PANEL,
  isFilterOpen
});

export const updateSelectedFilter = selected => ({
  type: UPDATE_SELECTED_FILTER,
  selected
});

export const updateFilter = (filterType, filterVal, subfilter) => ({
  type: UPDATE_FILTER,
  filterType,
  subfilter,
  filterVal
});

export const resetFilters = () => ({
  type: RESET_FILTERS
});

export function setFiltersFromElasticSearchResult(metrics) {
  return function(dispatch) {
    const propertyTypes = {};
    const cities = {};
    const tenants = {};
    const postalCode = {};
    const propertyName = {};
    metrics.facet_property_type.facet.details.buckets.forEach(bucket => {
      propertyTypes[bucket.key] = false;
    });
    metrics.facet_city.facet.details.buckets.forEach(bucket => {
      cities[bucket.key] = false;
    });
    metrics.facet_tenant.facet.details.buckets.forEach(bucket => {
      tenants[bucket.key] = false;
    });
    metrics.facet_postal_code.facet.details.buckets.forEach(bucket => {
      postalCode[bucket.key] = false;
    });
    metrics.facet_property.facet.details.buckets.forEach(bucket => {
      propertyName[bucket.key] = false;
    });
    dispatch(
      setFilters(cities, propertyTypes, tenants, postalCode, propertyName)
    );
  };
}

export const setFilters = (
  cities,
  propertyTypes,
  tenants,
  postalCode,
  propertyName
) => ({
  type: SET_FILTERS,
  cities,
  propertyTypes,
  tenants,
  postalCode,
  propertyName
});

export const setSearchInput = searchInput => ({
  type: SET_SEARCH_INPUT,
  searchInput
});

export const addToActiveFilters = (filterType, filterValue) => ({
  type: ADD_TO_ACTIVE_FILTERS,
  filterType,
  filterValue
});

export const removeFromActiveFilters = (filterType, filterValue) => ({
  type: REMOVE_FROM_ACTIVE_FILTERS,
  filterType,
  filterValue
});

export const updateActiveSearchInput = string => ({
  type: UPDATE_ACTIVE_SEARCH_INPUT,
  string
});

export const clearFilterList = () => ({
  type: CLEAR_FILTER_LIST
});

export const updateElasticSearchMetrics = metrics => ({
  type: UPDATE_ELASTIC_SEARCH_METRICS,
  metrics
});
