import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const NavLink = ({ to, children, className }) => (
  <Link
    to={to}
    className={className}
    style={{
      textDecoration: "none"
    }}
  >
    {children}
  </Link>
);

export default NavLink;

NavLink.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  children: PropTypes.node,
  className: PropTypes.string
};
