import { useEffect, useMemo, useState } from "react";
import useDocument from "./useDocument";

export default function useDocumentTableData() {
  const { document } = useDocument();

  const [activeDocumentTables, setActiveDocumentTables] = useState([]);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [currentHeaderIndex, setCurrentHeaderIndex] = useState(0);

  const documentTables = useMemo(() => computeDocumentTables(document), [
    document
  ]);

  useEffect(() => {
    if (documentTables.length === 0) setActiveDocumentTables([]);
    if (!documentTables.length || activeDocumentTables.length) return;

    const tables = computeActiveDocumentTables(documentTables);
    const firstActiveTableIndex = tables.findIndex(Boolean);
    if (documentTables[firstActiveTableIndex] !== undefined) {
      const { headerIndex, pageIndex } = documentTables[firstActiveTableIndex];
      setCurrentHeaderIndex(headerIndex);
      setCurrentPageIndex(pageIndex);
    }
    setActiveDocumentTables(tables);
  }, [documentTables, setActiveDocumentTables]);

  const hasNextActiveTable =
    activeDocumentTables.findIndex(
      (active, n) => active && n > currentHeaderIndex
    ) !== -1;

  const hasPrevActiveTable =
    activeDocumentTables.slice(0, currentHeaderIndex).lastIndexOf(true) !== -1;

  return {
    activeDocumentTables,
    documentTables,
    currentHeaderIndex,
    currentPageIndex,
    hasNextActiveTable,
    hasPrevActiveTable,
    setActiveDocumentTables,
    setCurrentHeaderIndex,
    setCurrentPageIndex
  };
}

export function computeActiveDocumentTables(documentTables) {
  if (!documentTables) return [];

  return documentTables.map(
    ({ isMapped, qualified, doNotUseTable }) =>
      isMapped && qualified && !doNotUseTable
  );
}

export function computeDocumentTables(document) {
  if (
    !document ||
    !document.raw_data_json ||
    !document.raw_data_json.pages ||
    !document.meta_data_json ||
    !document.meta_data_json.headers
  )
    return [];

  return document.raw_data_json.pages.reduce((acc, page, pageIndex) => {
    if (!page.tableData.length) return acc;

    page.tableData.forEach((table, tableIndex) => {
      const headerIndex = acc.length;
      const { headers, rowIds, rowIndex, qualified, metaName } =
        document.meta_data_json.headers[headerIndex] || {};

      const doNotUseTable =
        document.raw_data_json.notUsedTables &&
        document.raw_data_json.notUsedTables.includes(headerIndex)
          ? true
          : false;

      const headerLength = (rowIds || []).length;
      const isMapped = (headers || []).some(header => header.isMapped);

      /*Do not automatically group tables, they will need manual verification
      if (rowIndex === -1) {
        const lastTable = acc[acc.length - 1];
        acc[acc.length - 1] = {
          ...lastTable,
          rowCount: lastTable.rowCount + table.length,
          pageIndexes: [...lastTable.pageIndexes, pageIndex],
          tableIndexes: [...lastTable.pageIndexes, tableIndex]
        };
      } else {*/
      acc.push({
        columnCount: (table[0] || []).length,
        pageIndex,
        pageIndexes: [pageIndex],
        rowCount: table.length - headerLength,
        tableIndexes: [tableIndex],
        sheetName: document.excel_workbook
          ? document.excel_workbook.SheetNames[headerIndex]
          : null,
        headerIndex,
        tableIndex,
        metaName,
        isMapped,
        qualified,
        doNotUseTable
      });
      //}
    });
    return acc;
  }, []);
}
