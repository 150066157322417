import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { documentFileType } from "server/middleware/api/helpers/documents.js";
import useDocument from "../../hooks/useDocument";
import PdfDocument from "./components/PdfDocument/PdfDocument";
import ExcelDocument from "./components/ExcelDocument/ExcelDocument";
export default function DocumentView({
  onGotoPage,
  onSelectTable,
  activeDocumentTables,
  currentPageIndex,
  currentHeaderIndex,
  documentTables,
  documentMetadata,
  formState,
  setDocumentMetadata,
  selectedRentRollCell,
  setSelectedRentRollCell,
  onSelectedCell,
  rentRoll,
  refreshData,
  selected
}) {
  const [tableActive, setTableActive] = useState(true);

  const {
    document: {
      file_extension: extension,
      raw_data_json: { pages }
    }
  } = useDocument();

  useEffect(() => {
    if (
      activeDocumentTables &&
      documentTables &&
      documentTables[currentPageIndex]
    ) {
      if (activeDocumentTables[documentTables[currentPageIndex].headerIndex]) {
        setTableActive(true);
      } else {
        setTableActive(false);
      }
    } else {
      setTableActive(false);
    }
  }, [activeDocumentTables, currentPageIndex, documentTables]);

  if (isPdfDocument(extension)) {
    return (
      <PdfDocument
        onSelectTable={onSelectTable}
        onGotoPage={onGotoPage}
        tableActive={tableActive}
        currentPageIndex={currentPageIndex}
        currentHeaderIndex={currentHeaderIndex}
        activeDocumentTables={activeDocumentTables}
        documentMetadata={documentMetadata}
        documentTables={documentTables}
        numPages={pages.length}
        formState={formState}
        rentRoll={rentRoll}
        onSelectedCell={onSelectedCell}
        setDocumentMetadata={setDocumentMetadata}
        selected={selected}
        selectedRentRollCell={selectedRentRollCell}
        refreshData={refreshData}
        setSelectedRentRollCell={setSelectedRentRollCell}
      />
    );
  }

  if (isExcelDocument(extension) && documentMetadata) {
    return (
      <ExcelDocument
        rentRoll={rentRoll}
        tableActive={tableActive}
        currentPageIndex={currentPageIndex}
        currentHeaderIndex={currentHeaderIndex}
        documentMetadata={documentMetadata}
        documentTables={documentTables}
        formState={formState}
        setSelectedRentRollCell={setSelectedRentRollCell}
        onSelectedCell={onSelectedCell}
        setDocumentMetadata={setDocumentMetadata}
        selected={selected}
      />
    );
  }

  // eslint-disable-next-line no-console
  console.error(`Invalid file extension: ${extension}`);
  return null;
}

function isPdfDocument(fileType) {
  return [
    documentFileType.PDF,
    documentFileType.PNG,
    documentFileType.JPG,
    documentFileType.JPEG
  ].includes(fileType);
}

function isExcelDocument(fileType) {
  return [
    documentFileType.XLS,
    documentFileType.XLSX,
    documentFileType.XLSM,
    documentFileType.CSV
  ].includes(fileType);
}

DocumentView.propTypes = {
  onGotoPage: PropTypes.func.isRequired,
  onSelectTable: PropTypes.func.isRequired,
  activeDocumentTables: PropTypes.array,
  currentPageIndex: PropTypes.number.isRequired,
  currentHeaderIndex: PropTypes.number,
  documentTables: PropTypes.array.isRequired,
  documentMetadata: PropTypes.any,
  formState: PropTypes.string.isRequired,
  setDocumentMetadata: PropTypes.func.isRequired,
  onSelectedCell: PropTypes.func.isRequired,
  rentRoll: PropTypes.array,
  refreshData: PropTypes.func,
  selectedRentRollCell: PropTypes.string,
  setSelectedRentRollCell: PropTypes.func.isRequired,
  selected: PropTypes.string
};
