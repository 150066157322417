import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export const PERIOD_DATE_FORMAT = "MM/DD/YYYY";
export const MDYYYY_DATE_FORMAT = "M/D/YYYY";
export const MDDYYYY_DATE_FORMAT = "M/DD/YYYY";
export const MMDYYYY_DATE_FORMAT = "MM/D/YYYY";

export const MDYY_DATE_FORMAT = "M/D/YY";
export const MDDYY_DATE_FORMAT = "M/DD/YY";
export const MMDDYY_DATE_FORMAT = "MM/DD/YY";
export const NO_DATES_TEXT = "MM/DD/YYYY - MM/DD/YYYY";
export const NO_DATE_TEXT = "MM/DD/YYYY";

export default dayjs;
