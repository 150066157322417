import PropTypes from "prop-types";
import cx from "classnames";

const ToggleSwitch = ({ id, handleToggle, isOn, className, disabled }) => {
  const classes = cx(
    "ToggleSwitch",
    {
      "ToggleSwitch--on": isOn,
      "ToggleSwitch--off": !isOn
    },
    {
      "ToggleSwitch--on--disabled": isOn && disabled,
      "ToggleSwitch--off--disabled": !isOn && disabled
    },
    className
  );

  return (
    <div id={id} className={classes} onClick={handleToggle}>
      <div className="ToggleSwitch__knob" />
    </div>
  );
};

ToggleSwitch.propTypes = {
  className: PropTypes.string,
  handleToggle: PropTypes.func.isRequired,
  isOn: PropTypes.bool.isRequired,
  id: PropTypes.string,
  disabled: PropTypes.bool
};

export default ToggleSwitch;
