import PropTypes from "prop-types";
import _ from "lodash";
import { useState } from "react";
import OpexTable from "../OpexTable/OpexTable";
import OpexImage from "../OpexImage/OpexImage";
import OpexSheet from "../OpexSheet/OpexSheet";
import OpexDocument from "../../modelOpexDocument";

const OpexPage = ({
  document,
  pageIndex,
  views,
  selectedTables,
  selectedCells,
  selectedRows,
  activeTables,
  onTableClick,
  onColumnClick,
  onImageLoad,
  onMouseEnterCell,
  onMouseLeaveCell,
  showLoadingSpinner,
  useFixedWidthHeightFromImage,
  cellPolygonRenderer,
  shouldUseCellPolygonRenderer
}) => {
  const documentModel = new OpexDocument(document);
  const page = documentModel.getPageByPageIndex(pageIndex);
  const pageSelectedTables = !_.isEmpty(selectedTables)
    ? selectedTables
    : documentModel.getSelectedTablesByPageIndex(pageIndex);

  const pageActiveTables = !_.isEmpty(activeTables)
    ? activeTables
    : documentModel.getSelectedTablesByPageIndex(pageIndex);

  // The current svg rendering technique depends on knowing the height and width
  // of the parent container
  const [contentHeight, setContentHeight] = useState(0);
  const [contentWidth, setContentWidth] = useState(0);
  const [boundingBoxes, setBoundingBoxes] = useState(null);

  const { imageUrl, tableData } = page;

  const isColumnView = () => _.includes(views, "columns");

  const isRowsView = () => _.includes(views, "rows");

  const isCellsView = () => _.includes(views, "cells");

  const renderTables = () => {
    if (documentModel.isExcel() && !boundingBoxes) {
      return null;
    }

    const components = _.map(tableData, (table, tableIndex) => {
      const isSelected = _.includes(pageSelectedTables, tableIndex);
      const isActive = _.includes(pageActiveTables, tableIndex);
      const chartOfAccounts = documentModel.getChartOfAccountsColumnIndexByPageIndexTableIndex(
        pageIndex,
        tableIndex
      );

      const coaTooltipOffset = documentModel.isExcel()
        ? { top: 40, left: 100 }
        : null;

      if ((isColumnView() || isRowsView() || isCellsView()) && !isActive) {
        return null;
      }

      const data = documentModel.getTableDataForOpexTableByPageIndexTableIndex(
        pageIndex,
        tableIndex,
        { boundingBoxes }
      );

      return (
        <OpexTable
          key={tableIndex}
          // @ts-ignore
          tableIndex={tableIndex}
          imageHeight={contentHeight}
          imageWidth={contentWidth}
          views={views}
          data={data}
          selected={isSelected}
          selectedColumns={[chartOfAccounts]}
          selectedRows={selectedRows}
          selectedCells={selectedCells}
          cellPolygonRenderer={cellPolygonRenderer}
          shouldUseCellPolygonRenderer={shouldUseCellPolygonRenderer}
          coaTooltipOffset={coaTooltipOffset}
          onClick={() => {
            onTableClick(tableIndex);
          }}
          onColumnClick={columnIndex => {
            onColumnClick(tableIndex, columnIndex);
          }}
          onMouseEnterCell={location => {
            const nextLocation = { ...location, pi: pageIndex };
            if (onMouseEnterCell) {
              onMouseEnterCell(nextLocation);
            }
          }}
          onMouseLeaveCell={location => {
            const nextLocation = { ...location, pi: pageIndex };
            if (onMouseLeaveCell) {
              onMouseLeaveCell(nextLocation);
            }
          }}
          shouldShowCOATooltip={isColumnView()}
        />
      );
    });

    return components;
  };

  const handleOnImageResize = (height, width) => {
    setContentHeight(height);
    setContentWidth(width);
  };

  const handleOnSheetMount = (height, width, boundingBoxes) => {
    setContentHeight(height);
    setContentWidth(width);
    setBoundingBoxes(boundingBoxes);
  };

  let style = {};
  let className = "OpexPanelView__OpexPage";
  if (useFixedWidthHeightFromImage) {
    style.height = contentHeight;
    style.width = contentWidth;
  }

  if (documentModel.isExcel()) {
    className += " OpexPageSheet";
  }

  const renderSvg = () => {
    if (_.includes(views, "page") === false) {
      return <svg className="OpexPageTables">{renderTables()}</svg>;
    }
  };

  return (
    <div className={className} style={style}>
      {documentModel.isPdf() && (
        <>
          <OpexImage
            url={imageUrl}
            onResize={handleOnImageResize}
            showLoadingSpinner={showLoadingSpinner}
            onImageLoad={onImageLoad}
          />
          {renderSvg()}
        </>
      )}

      {documentModel.isExcel() && (
        <OpexSheet
          data={documentModel.getExcelSheet(pageIndex)}
          onMount={handleOnSheetMount}
        >
          {renderSvg()}
        </OpexSheet>
      )}
    </div>
  );
};

export default OpexPage;

OpexPage.propTypes = {
  document: PropTypes.object.isRequired, // the parent document that the page came from, needed so that column metadata can be fished out
  pageIndex: PropTypes.number.isRequired,
  views: PropTypes.arrayOf(
    PropTypes.oneOf(["page", "tables", "columns", "rows", "cells"])
  ).isRequired, // render polygons for tables, columns, rows, or cells
  selectedTables: PropTypes.array, // indexs of tables currently selected, ie. highlighted
  activeTables: PropTypes.array, // indexs of tables to actually draw. ie. if a page has 3 tables and you only want 1 & 3 to be selectable, you can put those indexes here
  selectedCells: PropTypes.array, // array of location `{ pi, ti, ci, ri }` of cells currently selected.
  selectedRows: PropTypes.array, // indexes of rows currently selected, ie. highlighted
  onTableClick: PropTypes.func,
  onColumnClick: PropTypes.func,
  onMouseEnterCell: PropTypes.func,
  onMouseLeaveCell: PropTypes.func,
  onImageLoad: PropTypes.func,
  useFixedWidthHeightFromImage: PropTypes.bool, // default false, true if you want the page container to use a fixed width/height based on the image size
  showLoadingSpinner: PropTypes.bool, // default false, if true, will show a spinner while loading the image
  cellPolygonRenderer: PropTypes.elementType,
  shouldUseCellPolygonRenderer: PropTypes.func
};
