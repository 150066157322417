export const UPDATE_HEADER_TAB_INDEX = "UPDATE_HEADER_TAB_INDEX";
export const UPDATE_PANEL_TAB_INDEX = "UPDATE_PANEL_TAB_INDEX";
export const UPDATE_SELECTED_DOC_ID = "UPDATE_SELECTED_DOC_ID";
export const RESET_UI_TO_DEFAULT = "RESET_UI_TO_DEFAULT";

export const updatePanelTabIndex = panelTabIndex => ({
  type: UPDATE_PANEL_TAB_INDEX,
  panelTabIndex
});

export const updateHeaderTabIndex = headerTabIndex => ({
  type: UPDATE_HEADER_TAB_INDEX,
  headerTabIndex
});

export const updateSelectedDocId = docId => ({
  type: UPDATE_SELECTED_DOC_ID,
  docId
});

export const resetUIToDefault = () => ({
  type: RESET_UI_TO_DEFAULT
});
