import PropTypes from "prop-types";
import { withRouter } from "react-router";
import CurrentStep from "./components/CurrentStep";
import BottomNav from "./components/BottomNav";
import ManageFilesButton from "./components/ManageFilesButton";

const BottomBar = ({
  template,
  currentWorkflowStatus,
  saveAndContinue,
  goToPreviousStep
}) => {
  return (
    <div className="OpexPanelView__BottomBar">
      <ManageFilesButton />
      <CurrentStep currentWorkflowStatus={currentWorkflowStatus} />
      <BottomNav
        template={template}
        currentWorkflowStatus={currentWorkflowStatus}
        saveAndContinue={saveAndContinue}
        goToPreviousStep={goToPreviousStep}
      />
    </div>
  );
};

export default withRouter(BottomBar);

BottomBar.propTypes = {
  groupName: PropTypes.string,
  template: PropTypes.object,
  currentWorkflowStatus: PropTypes.string,
  saveAndContinue: PropTypes.func,
  goToPreviousStep: PropTypes.func
};
