import PropTypes from "prop-types";
import cx from "classnames";

import { Cell } from "react-sticky-table";

const ActionCellForDocRow = ({
  documentId,
  dataRow,
  className,
  undoRemoveRowFromRentRoll,
  removeRowFromRentRoll,
  widgetAuth
}) => (
  <Cell className={className}>
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-evenly"
      }}
    >
      {dataRow.status === "DOCUMENT_DEL" ? (
        <img
          className={cx("PreviewPaneTable__actionIcon", "Cells__icon-undo")}
          src={require("ui/images/icon-undo.png")}
          onClick={() => {
            undoRemoveRowFromRentRoll(documentId, dataRow.rowId, widgetAuth);
          }}
        />
      ) : (
        <img
          className="PreviewPaneTable__actionIcon"
          src={require("ui/images/icon-trash.png")}
          onClick={() => {
            removeRowFromRentRoll(
              documentId,
              dataRow.rowId,
              dataRow.status,
              widgetAuth
            );
          }}
          style={{ cursor: "pointer" }}
        />
      )}
    </div>
  </Cell>
);

ActionCellForDocRow.propTypes = {
  previewTableConfig: PropTypes.array,
  documentId: PropTypes.number,
  className: PropTypes.string,
  dataRow: PropTypes.object,
  undoRemoveRowFromRentRoll: PropTypes.func,
  removeRowFromRentRoll: PropTypes.func,
  widgetAuth: PropTypes.object
};

export default ActionCellForDocRow;
