import { useState } from "react";
import PropTypes from "prop-types";
import PredefTenantOption from "./PredefTenantOption";
import Spinner from "ui/components/shared/Spinner";

const TenantSelection = ({
  predefValues,
  setPredefVals,
  predefModalValue,
  updateInputVal,
  handleInputUpdate,
  selected,
  updateSelected,
  showSpinner,
  setShowSpinner,
  showSaving,
  closePopover,
  updateValue,
  originalTenantName,
  searchVal,
  setSearchVal,
}) => {
  // selected radio button
  if (selected === undefined) {
    if(predefValues && predefValues.length){
      for(let i=0; i < predefValues.length; i++){
        if(predefValues[i].value === predefModalValue && predefValues[i].id){
          updateSelected(i)
        }
      }
    }
  }

  const handleOnChange = e => {
    if (e.target.value === ""){
      setSearchVal("")
    } else {
      setSearchVal(e.target.value);
    }
  };

  return (
    <div
      style={{
        position: "fixed",
        zIndex: 10000,
        backgroundColor: "#F0F0F0",
        marginLeft: "15%",
        padding: "35px",
        paddingBottom: "10px",
        border: "solid black 2px",
        borderRadius: "5px",
        minWidth: "209px",
        maxWidth: "550px",
        top: "13%"
      }}
    >
      <h1 className={"DataCell__Popover__EntityTitle"}>{originalTenantName}</h1>
      <div className="DataCell__Popover__popupHeader">
        Search Term: (please edit and press enter for different results)
      </div>
      <div
        style={{
          marginBottom: "15px"
        }}
      >
        <input
          className="DataCell__Popover__input"
          type="text"
          value={searchVal}
          onKeyDown={handleInputUpdate}
          onChange={handleOnChange}
        />
        <br />
      </div>

      {showSpinner ? (
        <>
          <div style={{ textAlign: "center" }}>
            {showSaving ? "Updating Cell..." : ""}
          </div>
          <Spinner
            type="ThreeDots"
            classname="DataCell__Popover__loadingContent"
            color="#00BFFF"
            height={40}
            width={40}
          />
        </>
      ) : predefValues.length > 0 ? (
        <div
          style={{
            marginBottom: "15px",
            overflowX: "auto",
            overflowY: "scroll",
            maxHeight: "350px",
            minHeight: "150px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "right"
          }}
        >
          {predefValues.map((value, i) => (
            <PredefTenantOption
              key={i + value.value}
              idx={i}
              value={value}
              selected={selected}
              updateSelected={updateSelected}
              updateInputVal={updateInputVal}
              setShowSpinner={setShowSpinner}
              setPredefVals={setPredefVals}
            />
          ))}
        </div>
      ) : (
        <div className="DataCell__Popover__loadingContent">No results</div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          justifyContent: "space-between",
          marginTop: "10px"
        }}
      >
        <button
          className="DataCell__Popover__updateButton"
          disabled={showSaving || showSpinner}
          onClick={() => {
            updateValue();
          }}
        >
          Update
        </button>
        <button
          className="DataCell__Popover__updateButton"
          style={{ marginLeft: "5px", color: "gray" }}
          onClick={() => {
            closePopover();
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

TenantSelection.propTypes = {
  predefValues: PropTypes.array,
  setPredefVals: PropTypes.func,
  predefModalValue: PropTypes.string,
  inputVal: PropTypes.any,
  updateInputVal: PropTypes.func,
  handleInputUpdate: PropTypes.func,
  selected: PropTypes.number,
  updateSelected: PropTypes.func,
  showSpinner: PropTypes.bool,
  showSaving: PropTypes.bool,
  setShowSpinner: PropTypes.func,
  closePopover: PropTypes.func,
  updateValue: PropTypes.func,
  pageIndex: PropTypes.number,
  tableIndex: PropTypes.number,
  rowIndex: PropTypes.number,
  columnIndex: PropTypes.number,
  searchVal: PropTypes.string,
  setSearchVal: PropTypes.func,
  mutatePreDefSuggestions: PropTypes.any
};

export default TenantSelection;
