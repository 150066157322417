import PropTypes from "prop-types";

const OpexButton = ({
  onClick,
  variant,
  children,
  disabled,
  className = ""
}) => {
  const nextVariant = `OpexButton-${variant}`; // add a prefix just to scope the css
  const disabledClass = disabled ? "disabled" : "";

  const handleClick = () => {
    if (!disabled && onClick) {
      onClick();
    }
  };

  return (
    <div
      role="button"
      onClick={handleClick}
      className={`OpexButton ${nextVariant} ${disabledClass} ${className}`}
    >
      {children}
    </div>
  );
};

export default OpexButton;

OpexButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
  variant: PropTypes.oneOf(["primary", "secondary"]),
  disabled: PropTypes.bool,
  className: PropTypes.string
};
