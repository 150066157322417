import { apiFetch } from "./apiClient";
import { showFlash } from "./flash";
import { stopSubmit } from "redux-form";
import { validEmail, validRole } from "ui/helpers/users";

import { stringify } from "query-string";

export const USERS_FETCHING = "USERS_FETCHING";
export const USERS_FETCH_SUCCESSFUL = "USERS_FETCH_SUCCESSFUL";
export const USERS_FETCH_SUCCESSFUL_NO_PAGINATION =
  "USERS_FETCH_SUCCESSFUL_NO_PAGINATION";
export const USERS_FETCH_FAILED = "USERS_FETCH_FAILED";
export const USERS_STATUS_UPDATING = "USERS_STATUS_UPDATING";
export const USERS_STATUS_UPDATE_SUCCESSFUL = "USERS_STATUS_UPDATE_SUCCESSFUL";
export const USER_DELETING = "USER_DELETING";
export const USER_DELETE_SUCCESSFUL = "USER_DELETE_SUCCESSFUL";
export const FILTER_USERS_BY_COMPANY = "FILTER_USERS_BY_COMPANY";
export const FILTER_USERS_BY_ROLE = "FILTER_USERS_BY_ROLE";
export const FILTER_USERS_BY_STATUS = "FILTER_USERS_BY_STATUS";
export const UPDATE_USER_CHECKBOX = "UPDATE_USER_CHECKBOX";
export const CLEAR_USER_STORE = "CLEAR_USER_STORE";

export const REMOVE_USER_FILTER = "REMOVE_USER_FILTER";
export const UPDATE_USER_FILTER = "UPDATE_USER_FILTER";
export const CLEAR_USER_FILTER = "CLEAR_USER_FILTER";
export const UPDATE_ALPHA_FILTER = "UPDATE_ALPHA_FILTER";
export const USER_UPDATING = "USER_UPDATING";
export const USER_UPDATE_SUCCESSFUL = "USER_UPDATE_SUCCESSFUL";

export function updateAlphaFilter(filterType) {
  return {
    filterType,
    type: UPDATE_ALPHA_FILTER
  };
}

export function removeFilter(filterType) {
  return {
    filterType,
    type: REMOVE_USER_FILTER
  };
}

export function updateFilter(filterType, val) {
  return {
    filterType,
    val,
    type: UPDATE_USER_FILTER
  };
}

export function clearAllFilters() {
  return {
    type: CLEAR_USER_FILTER
  };
}

export function fetchUsers(destPage) {
  return async function(dispatch, getState) {
    let goToPage;
    let { currentUser, users } = getState();
    dispatch(usersFetching());
    try {
      goToPage = isNaN(destPage) ? undefined : destPage;
      let query = users.filter;
      if (goToPage) {
        query["page"] = goToPage;
      }
      query.alpha = users.alpha;

      const response = await apiFetch(
        `/api/users${query ? `?${stringify(query)}` : ``}`
      );
      const responseBody = await response.json();
      if (!response.ok) {
        throw new Error(responseBody.error.message);
      }
      dispatch(usersFetchSuccessful(responseBody, currentUser));
    } catch (error) {
      dispatch(usersFetchFailed(error));
    }
  };
}

export function previousUserPagination() {
  return async function(dispatch, getState) {
    dispatch(usersFetching());
    try {
      let goToPage;
      let {
        users: { page, filter, alpha },
        currentUser
      } = getState();

      goToPage = parseInt(page) > 1 ? parseInt(page) - 1 : 1;
      let query = filter;
      query.page = goToPage;
      query.alpha = alpha;

      const response = await apiFetch(`/api/users?${stringify(query)}`);
      const responseBody = await response.json();
      if (!response.ok) {
        throw new Error(responseBody.error.message);
      }
      dispatch(usersFetchSuccessful(responseBody, currentUser));
    } catch (error) {
      dispatch(usersFetchFailed(error));
    }
  };
}

export function nextUserPagination() {
  return async function(dispatch, getState) {
    dispatch(usersFetching());
    try {
      let goToPage;
      let {
        users: { totalPages, page, filter, alpha },
        currentUser
      } = getState();

      goToPage = totalPages > parseInt(page) ? parseInt(page) + 1 : totalPages;
      let query = filter;
      query.page = goToPage;
      query.alpha = alpha;

      const response = await apiFetch(`/api/users?${stringify(query)}`);
      const responseBody = await response.json();
      if (!response.ok) {
        throw new Error(responseBody.error.message);
      }
      dispatch(usersFetchSuccessful(responseBody, currentUser));
    } catch (error) {
      dispatch(usersFetchFailed(error));
    }
  };
}
export function clearUserStore() {
  return {
    type: CLEAR_USER_STORE
  };
}

export function toggleUserStatus(userId) {
  return async function(dispatch) {
    dispatch(userStatusUpdating());

    try {
      const reqBody = { userId };
      const response = await apiFetch("/api/users/toggleStatus", {
        method: "put",
        body: JSON.stringify(reqBody)
      });

      if (!response.ok) {
        const responseBody = await response.json();
        throw new Error(responseBody.error);
      }
      dispatch(userStatusUpdateSuccessful());
      dispatch(fetchUsers());
    } catch (e) {
      dispatch(
        showFlash({
          type: "error",
          message: `${e}`
        })
      );
    }
  };
}

function userStatusUpdating() {
  return {
    type: USERS_STATUS_UPDATING
  };
}

function userStatusUpdateSuccessful() {
  return {
    type: USERS_STATUS_UPDATE_SUCCESSFUL
  };
}

function usersFetching() {
  return {
    type: USERS_FETCHING
  };
}

function usersFetchSuccessful(paginatedUsers, currentUser) {
  return {
    paginatedUsers,
    currentUser,
    type: USERS_FETCH_SUCCESSFUL
  };
}

// function usersFetchSuccessfulNoPagination(users, currentUser) {
//   return {
//     users,
//     currentUser,
//     type: USERS_FETCH_SUCCESSFUL_NO_PAGINATION
//   };
// }

function usersFetchFailed(error) {
  return {
    error,
    type: USERS_FETCH_FAILED
  };
}

export function filterUsersByCompany(companyId) {
  return {
    companyId,
    type: FILTER_USERS_BY_COMPANY
  };
}

export function filterUsersByRole(role) {
  return {
    role,
    type: FILTER_USERS_BY_ROLE
  };
}

export function filterUsersByStatus(status) {
  return {
    status,
    type: FILTER_USERS_BY_STATUS
  };
}

export function createNewUser(params, history, access) {
  const { email, companyName, role } = params;
  const userBody = {
    email,
    companyName,
    role
  };
  if (!validEmail(email)) {
    return stopSubmit("newUser", {
      _error: { email: "Please use a valid email." }
    });
  }

  if (!validRole(userBody.role, access)) {
    return stopSubmit("newUser", {
      _error: { role: "Please select a role." }
    });
  }

  return async function(dispatch) {
    try {
      const response = await apiFetch("/api/users/new", {
        method: "post",
        body: JSON.stringify(userBody)
      });
      const responseBody = await response.json();
      if (!response.ok) {
        throw new Error(responseBody.error);
      }
      history.push("/admin/users");
      dispatch(
        showFlash({
          type: "success",
          message: `User ${email} was created.`
        })
      );
      if (responseBody.frontendPasswordForDevs) {
        alert(
          `Password for user in development is ${responseBody.frontendPasswordForDevs}`
        );
      }
    } catch (e) {
      dispatch(
        showFlash({
          type: "error",
          message: `${e}`
        })
      );
    }
  };
}

export function deleteUser(user) {
  return async function(dispatch) {
    const reqBody = { userId: user.id };
    dispatch(userDeleting());
    try {
      const response = await apiFetch("/api/users/delete", {
        method: "delete",
        body: JSON.stringify(reqBody)
      });
      if (!response.ok) {
        const responseBody = await response.json();
        throw new Error(responseBody.error.message);
      }
      dispatch(userDeleteSuccessful());
      dispatch(
        showFlash({
          type: "success",
          message: `${user.email} has been deleted`
        })
      );
      dispatch(fetchUsers());
    } catch (e) {
      dispatch(
        showFlash({
          type: "error",
          message: `${e}`
        })
      );
    }
  };
}

export function requestPasswordChange(email) {
  return async function(dispatch) {
    const reqBody = { email };
    try {
      const response = await apiFetch("/api/requestPassChange", {
        method: "post",
        body: JSON.stringify(reqBody)
      });
      const responseBody = await response.json();
      if (!response.ok) {
        throw new Error(responseBody.error);
      }
      dispatch(
        showFlash({
          type: "success",
          message: responseBody.message
        })
      );
      setTimeout(() => window.close(), 1500);
    } catch (e) {
      dispatch(
        showFlash({
          type: "error",
          message: `${e}`
        })
      );
    }
  };
}

export function userDeleting() {
  return {
    type: USER_DELETING
  };
}

export function userDeleteSuccessful() {
  return {
    type: USER_DELETE_SUCCESSFUL
  };
}

export function updateUserRole(userId, role) {
  return async function(dispatch) {
    dispatch(userUpdating());

    try {
      const reqBody = { userId, role };
      const response = await apiFetch("/api/users/updateRole", {
        method: "put",
        body: JSON.stringify(reqBody)
      });

      if (!response.ok) {
        const responseBody = await response.json();
        throw new Error(responseBody.error);
      }
      dispatch(userUpdateSuccessful());
      dispatch(fetchUsers());
    } catch (e) {
      dispatch(
        showFlash({
          type: "error",
          message: `${e}`
        })
      );
    }
  };
}
export function updateUserCompany(userId, companyId) {
  return async function(dispatch) {
    dispatch(userUpdating());

    try {
      const reqBody = { userId, companyId };
      const response = await apiFetch("/api/users/updateCompany", {
        method: "put",
        body: JSON.stringify(reqBody)
      });

      if (!response.ok) {
        const responseBody = await response.json();
        throw new Error(responseBody.error);
      }
      dispatch(userUpdateSuccessful());
      dispatch(fetchUsers());
    } catch (e) {
      dispatch(
        showFlash({
          type: "error",
          message: `${e}`
        })
      );
    }
  };
}

function userUpdating() {
  return {
    type: USER_UPDATING
  };
}

function userUpdateSuccessful() {
  return {
    type: USER_UPDATE_SUCCESSFUL
  };
}
