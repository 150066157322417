export const filterList = {
  cities: {
    header: "City",
    filterKey: "cities",
    bucketKey: "facet_city"
  },
  postalCode: {
    header: "Zip",
    filterKey: "postalCode",
    bucketKey: "facet_postal_code"
  },
  propertyTypes: {
    header: "Sector",
    filterKey: "propertyTypes",
    bucketKey: "facet_property_type"
  },
  propertyName: {
    header: "Property",
    filterKey: "propertyName",
    bucketKey: "facet_property"
  },
  tenants: {
    header: "Tenant",
    filterKey: "tenants",
    bucketKey: "facet_tenant"
  },
  searchInput: {
    header: "Search"
  }
};

export const promp = "Please select ";

export function returnFilterHeader(key) {
  const names = Object.keys(filterList);
  if (names.includes(key)) {
    return filterList[key];
  } else {
    return "";
  }
}

export function returnTypeCount(metrics, key) {
  return metrics[filterList[key].bucketKey].facet.total_count.value;
}

export function returnTypeBucket(metrics, key) {
  const buckets = metrics[filterList[key].bucketKey].facet.details.buckets;
  return buckets.map(bucket => bucket.key);
}
