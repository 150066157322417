import { useRef } from "react";
import PropTypes from "prop-types";
import Popup from "reactjs-popup";

import Button from "ui/components/shared/Button";

const WarningMessage = ({ blockingExceptions }) => {
  const ref = useRef();
  const closePopover = () => ref.current.close();
  let message =
    "Effective Date is needed before marking this document as complete.";
  if (blockingExceptions > 0) {
    message =
      "Resolving blocking exceptions is needed before marking this document as complete.";
  }
  return (
    <Popup
      ref={ref}
      trigger={
        <Button className="Button__greenButton">MARK AS COMPLETE</Button>
      }
      modal
      contentStyle={{
        width: "300px",
        backgroundColor: "white",
        padding: "15px"
      }}
    >
      <div>
        <div
          style={{ padding: "10px 0", fontSize: "16px", lineHeight: "21px" }}
        >
          {message}
        </div>
        <div style={{ display: "flex", flexDirection: "row-reverse" }}>
          <Button
            className="Button__blackButton"
            onClick={() => closePopover()}
          >
            Ok
          </Button>
        </div>
      </div>
    </Popup>
  );
};

WarningMessage.propTypes = {
  blockingExceptions: PropTypes.number
};

export default WarningMessage;
