import { useState, useEffect, createRef } from "react";
import PropTypes from "prop-types";
import BaseTable from "react-base-table";
import EditableCell from "./EditableCell";
import { SortableContainer } from "react-sortable-hoc";
import {
  getItemByIndex,
  refreshOrderValues,
  rowProps,
  sortChildren
} from "./utils";
import { SUBCATEGORY } from "server/middleware/api/helpers/opex";

const DraggableContainer = SortableContainer(({ children }) => children);

export default function Items(props) {
  const [itemsTemp, setItemsTemp] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);

  const { items, updateRowsData, canEdit, addCoaItemIdToDelete } = props;
  useEffect(() => {
    if (items && items.length > 0) setItemsTemp(items);
  }, [items]);

  useEffect(() => {
    updateRowsData(itemsTemp);
  }, [itemsTemp]);

  const table = createRef();

  const getContainer = () => {
    return table.current.getDOMNode().querySelector(".BaseTable__body");
  };

  const getHelperContainer = () => {
    return table.current.getDOMNode().querySelector(".BaseTable__table");
  };

  const handleSortEnd = ({ newIndex, oldIndex }) => {
    let draggedSubCat = getItemByIndex(oldIndex, itemsTemp, expandedRows);
    let affectedItem = getItemByIndex(newIndex, itemsTemp, expandedRows);

    let sortedItemsTemp = itemsTemp.map(cat => {
      //if both sub-categories are siblings
      if (
        cat.id === draggedSubCat.parentId &&
        cat.id === affectedItem.parentId
      ) {
        let { children } = cat;
        children = sortChildren(
          children,
          draggedSubCat.order,
          affectedItem.order
        );
        Object.assign(cat, { children });
        //if the sub-category was dragged to another category we add it to destination
      } else if (
        (cat.id === affectedItem.id || cat.id === affectedItem.parentId) &&
        (affectedItem.id !== draggedSubCat.parentId ||
          affectedItem.parentId !== draggedSubCat.parentId)
      ) {
        cat.children.push(
          Object.assign({}, draggedSubCat, {
            order: cat.children.length,
            parentId: cat.id
          })
        );
        cat.children = sortChildren(
          cat.children,
          cat.children.length - 1,
          affectedItem.order
        );
        //if sub-category was dragged to another category we remove it from source
      } else if (
        cat.id === draggedSubCat.parentId &&
        affectedItem.parentId !== draggedSubCat.parentId &&
        affectedItem.id !== draggedSubCat.parentId
      ) {
        cat.children.splice(
          cat.children.findIndex(subCat => subCat.id === draggedSubCat.id),
          1
        );
        cat.children = refreshOrderValues(cat.children);
      }
      cat.rows = [...cat.children];
      return cat;
    });

    setItemsTemp(sortedItemsTemp);
  };

  const rowUpdate = updatedRow => {
    setItemsTemp(current => {
      return updateDeleteRows(current, updatedRow);
    });
  };

  const rowDelete = deletedRow => {
    setItemsTemp(current => {
      if (
        current.find(c => c.id === deletedRow.parentId).children.length === 1
      ) {
        handleRowExpand(deletedRow.parentId);
      }
      return [...updateDeleteRows(current, deletedRow, true)];
    });
    //If the row is not a new one, then we will send it to the API
    //After user clicks save so it gets removed from database as well
    if (!deletedRow.isNew) addCoaItemIdToDelete(deletedRow.id);
  };

  const rowAdd = categoryId => {
    setItemsTemp(current => {
      return [...addNewSubCategory(current, categoryId)];
    });

    handleRowExpand(categoryId, false);
  };

  //This function updates and deletes COA items (rows)
  //It supports two levels deep for subcategories and accounts
  //It loops through the given rows and look for the matching updated row
  //If it doesn't find it there it will look in its children
  //Passing toDelete = true deletes the row instead of updating its data
  const updateDeleteRows = (allRows, updatedRow, toDelete = false) => {
    for (let index1 = 0; index1 < allRows.length; index1++) {
      if (allRows[index1].id === updatedRow.id) {
        if (!toDelete) allRows[index1] = updatedRow;
        else allRows.splice(index1, 1);
        return allRows;
      }
      let currentChildren = allRows[index1].children;
      for (let index2 = 0; index2 < currentChildren.length; index2++) {
        if (currentChildren[index2].id === updatedRow.id) {
          if (!toDelete)
            currentChildren[index2] = Object.assign({}, updatedRow, {
              justAdded: false
            });
          else currentChildren.splice(index2, 1);
          allRows[index1].children = currentChildren;
          allRows[index1].rows = currentChildren;
          return allRows;
        }
      }
    }
    return allRows;
  };

  //We need a tempId to handle added rows before they get sent to API
  const getTempId = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };

  //Adds a sub-category below the passed category id
  const addNewSubCategory = (categories, categoryId) => {
    for (let index = 0; index < categories.length; index++) {
      let category = categories[index];
      if (category.id === categoryId) {
        let tempSubCategory = {
          id: getTempId(),
          name: "",
          parentId: categoryId,
          justAdded: true,
          isNew: true,
          type: SUBCATEGORY,
          order: category?.children.length + 1 || 1
        };
        //Need to update both children (for BaseTable) and rows (for API)
        category.children = [...(category?.children || []), tempSubCategory];
        category.rows = [...(category?.rows || []), tempSubCategory];
        return categories;
      }
    }
  };

  const handleRowExpand = (id, toggle = true) => {
    setExpandedRows(current => {
      if (current.some(rId => rId === id)) {
        if (toggle) current.splice(current.indexOf(id), 1);
        return [...current];
      }
      return [...current, id];
    });
  };

  const columns = [
    {
      dataKey: "name",
      key: "name",
      title: "Name",
      width: 600
    },
    {
      dataKey: "id",
      key: "id",
      title: "ID",
      width: 0
    }
  ];

  //Here we tell BaseTable we want to use a custom cell renderer
  columns[0].cellRenderer = EditableCell;
  //Here we pass the event functions to be accessible inside the EditableCell
  columns[0].rowUpdate = rowUpdate;
  columns[0].rowDelete = rowDelete;
  columns[0].rowAdd = rowAdd;
  columns[0].canEdit = canEdit;

  return (
    <DraggableContainer
      useDragHandle
      onSortEnd={handleSortEnd}
      getContainer={getContainer}
      helperContainer={getHelperContainer}
      lockAxis="y"
    >
      <BaseTable
        data={itemsTemp}
        width={600}
        maxHeight={600}
        headerHeight={0}
        columns={columns}
        expandColumnKey={"name"}
        expandedRowKeys={expandedRows}
        // @ts-ignore
        onRowExpand={({ rowData }) => handleRowExpand(rowData.id)}
        rowProps={rowProps}
        {...props}
        ref={table}
      />
    </DraggableContainer>
  );
}

Items.propTypes = {
  items: PropTypes.array,
  updateRowsData: PropTypes.func,
  canEdit: PropTypes.bool,
  addCoaItemIdToDelete: PropTypes.func
};
