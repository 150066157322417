import { useRef, useState } from "react";
import PropTypes from "prop-types";
import Popup from "reactjs-popup";

import Button from "ui/components/shared/Button";

const WarningMessage = ({ warning, open, setWarning, setWarningOpen }) => {
  const [showDetails, setShowDetails] = useState(false);

  const closePopover = () => {
    setWarning({ message: "", details: [] });
    setWarningOpen(false);
  };

  const { message, details } = warning;

  return (
    <Popup
      open={open}
      onClose={closePopover}
      modal
      contentStyle={{
        width: "500px",
        backgroundColor: "white",
        padding: "15px"
      }}
    >
      <div>
        <div
          style={{ padding: "10px 0", fontSize: "16px", lineHeight: "21px" }}
        >
          {message}
        </div>
        {details.length > 0 && (
          <div>
            <div style={{ marginBottom: ".5rem" }}>
              Details
              <span
                style={{ cursor: "pointer" }}
                onClick={() => setShowDetails(prev => !prev)}
              >
                {showDetails ? " (-)" : " (+)"}
              </span>
            </div>
            <div
              style={{
                marginBottom: "1rem",
                overflowY: "auto",
                maxHeight: "250px"
              }}
            >
              {showDetails &&
                details.map((detail, i) => (
                  <div
                    key={i}
                    style={{
                      marginBottom: ".5rem",
                      backgroundColor: "#FFE0E0",
                      color: "#FA393B",
                      padding: ".5rem"
                    }}
                  >
                    <div>{detail.instancePath}:</div>
                    <div>{detail.message}</div>
                  </div>
                ))}
            </div>
          </div>
        )}
        <div style={{ display: "flex", flexDirection: "row-reverse" }}>
          <Button
            className="Button__blackButton"
            onClick={() => {
              setShowDetails(false);
              closePopover();
            }}
          >
            Ok
          </Button>
        </div>
      </div>
    </Popup>
  );
};

WarningMessage.propTypes = {
  warning: PropTypes.object,
  open: PropTypes.bool,
  setWarning: PropTypes.func,
  setWarningOpen: PropTypes.func
};

export default WarningMessage;
