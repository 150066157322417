import { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import cx from "classnames";

import {
  updateDocumentTemplate,
  updateDocumentField,
  updateEditHeader,
  clearEditHeader,
  updateEditText,
  clearEditText,
  clearCache,
  updateCache
} from "ui/store/actions/rentRoll";

import CellEditPopover from "./popovers/cell/CellEditPopover";
import HeaderEditPopover from "./popovers/header/HeaderEditPopover";
import AdvancedRulesPopover from "./popovers/advancedRules/AdvancedRulesPopover";

import { rowOuter } from "./helpers/mask";

import {
  organizeHeaderData,
  getDocData,
  getTemplateObject,
  getMultiLineHeader
} from "./helpers/processing";
import {
  isLeaseStartDateAfterEnd,
  isLeaseLongerThanTenYears,
  isCellValueRequired
} from "./helpers/exceptions";

class PdfDocumentMarkup extends Component {
  state = { selectedCell: "" };

  updateSelectedCell = selectedCell => {
    this.setState({ selectedCell });
  };

  clearSelectedCell = () => {
    this.setState({ selectedCell: "" });
  };

  editHeader = (page, table, row, col) => {
    const { updateEditHeader } = this.props;
    updateEditHeader({
      page,
      table,
      row,
      col
    });
  };

  rowHeader = (page, table, row) => {
    const { updateCache, cache } = this.props;
    const rowHeaderKey = `rowHeader-${page}-${table}`;

    if (cache && cache[rowHeaderKey]) {
      if (cache[rowHeaderKey].row.includes(row)) {
        updateCache(rowHeaderKey, {
          row: [row]
        });
      } else if (
        !cache[rowHeaderKey].row.includes(row) &&
        cache[rowHeaderKey].row.length === 1 &&
        (cache[rowHeaderKey].row[0] + 1 === row ||
          cache[rowHeaderKey].row[0] - 1 === row)
      ) {
        updateCache(rowHeaderKey, {
          row: [cache[rowHeaderKey].row[0], row].sort()
        });
      } else if (
        !cache[rowHeaderKey].row.includes(row) &&
        cache[rowHeaderKey].row.length === 2 &&
        (cache[rowHeaderKey].row[1] + 1 === row ||
          cache[rowHeaderKey].row[0] - 1 === row)
      ) {
        updateCache(rowHeaderKey, {
          row: [...cache[rowHeaderKey].row, row].sort()
        });
      } else if (
        !cache[rowHeaderKey].row.includes(row) &&
        cache[rowHeaderKey].row.length === 3
      ) {
        updateCache(rowHeaderKey, {
          row: [row].sort()
        });
      } else if (
        !cache[rowHeaderKey].row.includes(row) &&
        cache[rowHeaderKey].row.length === 1 &&
        !(
          cache[rowHeaderKey].row[0] + 1 === row ||
          cache[rowHeaderKey].row[0] - 1 === row
        )
      ) {
        updateCache(rowHeaderKey, {
          row: [row].sort()
        });
      } else if (
        !cache[rowHeaderKey].row.includes(row) &&
        cache[rowHeaderKey].row.length === 2 &&
        !(
          cache[rowHeaderKey].row[1] + 1 === row ||
          cache[rowHeaderKey].row[0] - 1 === row
        )
      ) {
        updateCache(rowHeaderKey, {
          row: [row].sort()
        });
      }
    } else {
      updateCache(rowHeaderKey, { row: [row] });
    }
  };

  closeHeaderEditor = () => {
    const { clearEditHeader } = this.props;
    clearEditHeader();
  };

  updateHeader = (pi, ti, ri, rowIds) => {
    const {
      clearEditHeader,
      cache,
      updateDocumentTemplate,
      document,
      widgetAuth
    } = this.props;

    const headerData = organizeHeaderData(document);

    const templateObj = getTemplateObject(
      document,
      headerData,
      { pi, ti, ri },
      cache,
      rowIds
    );

    updateDocumentTemplate(
      templateObj,
      headerData[pi][ti].metaName,
      headerData[pi][ti].headerIndex,
      document.id,
      headerData[pi][ti].multipleLineGrouping,
      headerData[pi][ti].advancedRules,
      widgetAuth
    );

    clearEditHeader();
  };

  editText = (page, table, row, col) => {
    const { updateEditText } = this.props;
    updateEditText({
      page,
      table,
      row,
      col
    });
  };

  clearEditText = () => {
    const { clearEditText } = this.props;
    clearEditText();
  };

  inputChanged = event => {
    const { updateCache } = this.props;
    updateCache(event.target.name, event.target.value);
  };

  handleInputKeyDown = event => {
    if (event.keyCode === 13) {
      const { updateDocumentField, document, widgetAuth } = this.props;
      const fieldPosition = event.target.name.split("-");
      updateDocumentField(
        {
          page: parseInt(fieldPosition[1]),
          table: parseInt(fieldPosition[2]),
          row: parseInt(fieldPosition[3]),
          col: parseInt(fieldPosition[4]),
          text: event.target.value
        },
        document.id,
        widgetAuth
      );
    }
  };

  checkboxChanged = event => {
    const { updateCache } = this.props;
    updateCache(event.target.name, event.target.checked);
  };
  render() {
    const {
      page,
      pi,
      cache,
      document,
      rentRoll,
      editHeader,
      exceptions,
      fieldNamesMappingForDropDown,
      selectedProperty,
      deletedRows,
      updateCache,
      handleScrollToCell,
      selectedDocumentCell,
      setPredefModal,
      propertyType,
      widgetAuth
    } = this.props;

    const headerData = organizeHeaderData(document);
    const docData = getDocData(rentRoll);
    const documentId = document.id;

    return (
      <svg
        id="svg-markup"
        height="100%"
        width="100%"
        ref={this.svgMarkupRef}
        style={{ overflow: "visible" }}
      >
        <mask id={"greyMask_" + pi}>
          <rect width="100%" height="100%" fill="white"></rect>
          {page.tableData.map((table, _) => {
            return table.map((row, ri) => {
              return row.map((col, ci) => {
                return (
                  <rect
                    key={ri + "-" + ci}
                    width={col.geometry.boundingBox.Width * 100 + "%"}
                    height={col.geometry.boundingBox.Height * 100 + "%"}
                    x={col.geometry.boundingBox.Left * 100 + "%"}
                    y={col.geometry.boundingBox.Top * 100 + "%"}
                    fill="black"
                  />
                );
              });
            });
          })}
        </mask>
        <rect
          className="RentRollDocumentView__mask"
          mask={"url(#greyMask_" + pi + ")"}
          style={{ opacity: 0.5 }}
        ></rect>

        {page.tableData.map((table, ti) => {
          const cells = table.map((row, ri) => {
            let headerOuter = null;
            if (
              cache["rowHeader-" + pi + "-" + ti] &&
              cache["rowHeader-" + pi + "-" + ti].row.includes(ri)
            ) {
              if (cache["rowHeader-" + pi + "-" + ti].row.length === 3) {
                let row1 = table[cache["rowHeader-" + pi + "-" + ti].row[0]];
                let row2 = table[cache["rowHeader-" + pi + "-" + ti].row[1]];
                let row3 = table[cache["rowHeader-" + pi + "-" + ti].row[2]];
                headerOuter = [rowOuter(row1), rowOuter(row2), rowOuter(row3)];
              } else if (cache["rowHeader-" + pi + "-" + ti].row.length === 2) {
                let row1 = table[cache["rowHeader-" + pi + "-" + ti].row[0]];
                let row2 = table[cache["rowHeader-" + pi + "-" + ti].row[1]];
                headerOuter = [rowOuter(row1), rowOuter(row2)];
              } else if (cache["rowHeader-" + pi + "-" + ti].row.length === 1) {
                let row = table[cache["rowHeader-" + pi + "-" + ti].row[0]];
                headerOuter = rowOuter(row);
              }
            } else if (
              !cache["rowHeader-" + pi + "-" + ti] &&
              headerData[pi][ti] &&
              headerData[pi][ti].rowIds &&
              headerData[pi][ti].rowIds[0] === ri
            ) {
              if (headerData[pi][ti].rowIds.length === 3) {
                let row1 = table[headerData[pi][ti].rowIds[0]];
                let row2 = table[headerData[pi][ti].rowIds[1]];
                let row3 = table[headerData[pi][ti].rowIds[2]];
                headerOuter = [rowOuter(row1), rowOuter(row2), rowOuter(row3)];
              } else if (headerData[pi][ti].rowIds.length === 2) {
                let row1 = table[headerData[pi][ti].rowIds[0]];
                let row2 = table[headerData[pi][ti].rowIds[1]];
                headerOuter = [rowOuter(row1), rowOuter(row2)];
              } else {
                headerOuter = rowOuter(row);
              }
            } else if (
              !cache["rowHeader-" + pi + "-" + ti] &&
              headerData[pi][ti] &&
              !headerData[pi][ti].rowIds &&
              headerData[pi][ti].rowIndex &&
              headerData[pi][ti].rowIndex === ri
            ) {
              headerOuter = rowOuter(row);
            }

            const cells = row.map((col, ci) => {
              const rowIds = headerData[pi][ti] && headerData[pi][ti].rowIds;

              const isEditableHeaderCell =
                editHeader &&
                editHeader.page === pi &&
                editHeader.table === ti &&
                editHeader.row === ri &&
                editHeader.col === ci;

              const isMapped =
                (docData[pi + "-" + ti + "-" + ri + "-" + ci] || headerOuter) &&
                headerData[pi][ti].headers[ci];

              const corrected = isMapped && col.corrected;

              const lowPredefConfidence =
                isMapped &&
                !corrected &&
                col.predef &&
                col.predef.confidence < 90;

              const noPredefMapping =
                isMapped && !corrected && col.predef && !col.predef.id;

              const lowConfidence =
                isMapped && !corrected && col.confidence < 90;

              const leaseStartDateAfterEnd = isLeaseStartDateAfterEnd(
                exceptions,
                { pi, ti, ri, ci }
              );

              const leaseDateLongerThanTenYears = isLeaseLongerThanTenYears(
                exceptions,
                { pi, ti, ri, ci }
              );

              const cellValueRequied = isCellValueRequired(exceptions, {
                pi,
                ti,
                ri,
                ci
              });

              let cell = [];
              if (!isEditableHeaderCell) {
                if (
                  cache["rowHeader-" + pi + "-" + ti]
                    ? cache["rowHeader-" + pi + "-" + ti].row.includes(ri)
                    : (rowIds && rowIds.includes(ri)) ||
                      (headerData[pi][ti] && headerData[pi][ti].rowIndex === ri)
                ) {
                  cell.push(
                    <svg
                      key={pi + "-" + ti + "-" + ri + "-" + ci}
                      id={pi + "-" + ti + "-" + ri + "-" + ci}
                      width={col.geometry.boundingBox.Width * 100 + "%"}
                      height={col.geometry.boundingBox.Height * 100 + "%"}
                      x={col.geometry.boundingBox.Left * 100 + "%"}
                      y={col.geometry.boundingBox.Top * 100 + "%"}
                    >
                      <rect
                        x="0"
                        y="0"
                        width="100%"
                        height="100%"
                        className={cx("RentRollDocumentView__cell")}
                        style={{ fill: "rgba(0,0,0,0)" }}
                      />
                      <foreignObject
                        x="0"
                        y="0"
                        width="100%"
                        height="100%"
                        style={{ opacity: "0.0" }}
                      >
                        <HeaderEditPopover
                          multiLineHeader={getMultiLineHeader(
                            cache,
                            page,
                            { pi, ti, ci },
                            rowIds
                          )}
                          cellData={col}
                          isMapped={isMapped}
                          headerOuter={headerOuter}
                          fieldNames={fieldNamesMappingForDropDown}
                          headerData={headerData}
                          location={{ pi, ti, ri, ci }}
                          cache={cache}
                          updateCache={updateCache}
                          updateHeader={this.updateHeader}
                          selectedProperty={selectedProperty}
                        />
                      </foreignObject>
                    </svg>
                  );
                } else {
                  cell.push(
                    <svg
                      key={pi + "-" + ti + "-" + ri + "-" + ci}
                      id={pi + "-" + ti + "-" + ri + "-" + ci}
                      width={col.geometry.boundingBox.Width * 100 + "%"}
                      height={col.geometry.boundingBox.Height * 100 + "%"}
                      x={col.geometry.boundingBox.Left * 100 + "%"}
                      y={col.geometry.boundingBox.Top * 100 + "%"}
                    >
                      {deletedRows.find(
                        row => row.ri === ri && row.pi === pi
                      ) && (
                        <line
                          x1="0"
                          y1="50%"
                          x2="100%"
                          y2="50%"
                          style={{
                            stroke: "rgb(0,0,0)",
                            strokeWidth: 1
                          }}
                        />
                      )}
                      <rect
                        x="0"
                        y="0"
                        width="100%"
                        height="100%"
                        className={cx(
                          "RentRollDocumentView__cell",
                          {
                            ["Popover--isSelected"]:
                              selectedDocumentCell ===
                              pi + "-" + ti + "-" + ri + "-" + ci
                          },
                          {
                            ["RentRollDocumentView__cellMapped"]:
                              isMapped || headerOuter
                          },
                          {
                            ["RentRollDocumentView__cellLowConfidence"]: lowConfidence
                          },
                          {
                            ["RentRollDocumentView__cellCorrected"]:
                              corrected &&
                              !leaseStartDateAfterEnd &&
                              !leaseDateLongerThanTenYears
                          },
                          {
                            ["RentRollDocumentView__cellLowPredefConfidence"]: lowPredefConfidence
                          },
                          {
                            ["RentRollDocumentView__cellNoPredefMapping"]: noPredefMapping
                          },
                          {
                            ["RentRollDocumentView__cellValueRequied"]: cellValueRequied
                          },
                          {
                            ["RentRollDocumentView__leaseStartDateError"]: leaseStartDateAfterEnd
                          },
                          {
                            ["RentRollDocumentView__leaseStartDateWarning"]:
                              !leaseStartDateAfterEnd &&
                              leaseDateLongerThanTenYears
                          }
                        )}
                      />
                      <foreignObject
                        x="0"
                        y="0"
                        width="100%"
                        height="100%"
                        style={{ opacity: "0.0" }}
                      >
                        <CellEditPopover
                          cellData={col}
                          isMapped={isMapped}
                          headerOuter={headerOuter}
                          fieldNames={fieldNamesMappingForDropDown}
                          location={{ pi, ti, ri, ci }}
                          cache={cache}
                          documentId={documentId}
                          container={`.HeaderWithSidebar__body`}
                          updateSelectedCell={this.updateSelectedCell.bind(
                            this,
                            pi + "-" + ti + "-" + ri + "-" + ci
                          )}
                          clearSelectedCell={this.clearSelectedCell}
                          handleScrollToCell={handleScrollToCell}
                          setPredefModal={setPredefModal}
                          propertyType={propertyType}
                          headerData={headerData}
                        />
                      </foreignObject>
                    </svg>
                  );
                }
              }
              const rowMarker =
                ci === 0
                  ? [
                      <svg
                        key={"rowMarker-" + ri}
                        width="20"
                        height="20"
                        x={col.geometry.boundingBox.Left * 100 - 1 + "%"}
                        y={col.geometry.boundingBox.Top * 100 + "%"}
                        viewBox="0 0 100 100"
                        onClick={() => {
                          this.rowHeader(pi, ti, ri);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <path
                          d="M 0 30 L 60 50 L 0 70 z"
                          fill={
                            cache["rowHeader-" + pi + "-" + ti]
                              ? cache["rowHeader-" + pi + "-" + ti] &&
                                cache[
                                  "rowHeader-" + pi + "-" + ti
                                ].row.includes(ri)
                                ? "Blue"
                                : "Black"
                              : (headerData[pi][ti] &&
                                  headerData[pi][ti].rowIds &&
                                  headerData[pi][ti].rowIds.includes(ri)) ||
                                (headerData[pi][ti] &&
                                  headerData[pi][ti].rowIndex === ri)
                              ? "Blue"
                              : "Black"
                          }
                        />
                      </svg>
                    ]
                  : [];

              return [...rowMarker, cell];
            });

            let header;

            if (
              cache[`rowHeader-${pi}-${ti}`]
                ? cache[`rowHeader-${pi}-${ti}`] &&
                  cache[`rowHeader-${pi}-${ti}`].row.length > 1 &&
                  cache[`rowHeader-${pi}-${ti}`].row[0] === ri
                : headerData[pi][ti] && headerData[pi][ti].rowIds
                ? headerData[pi][ti].rowIds.length > 1 &&
                  headerData[pi][ti].rowIds[0] === ri
                : !(
                    headerData[pi][ti] &&
                    headerData[pi][ti].rowIndex &&
                    headerData[pi][ti].rowIndex === ri
                  )
            ) {
              header = [
                headerOuter && (
                  <svg
                    id="header"
                    key="header"
                    height="100%"
                    width="100%"
                    x="0"
                    y="0"
                    viewBox="0 0 100 100"
                    preserveAspectRatio="none"
                  >
                    <polygon
                      points={
                        headerOuter[0].x1 +
                        "," +
                        headerOuter[0].y1 +
                        " " +
                        headerOuter[0].x2 +
                        "," +
                        headerOuter[0].y2 +
                        " " +
                        headerOuter[headerOuter.length - 1].x3 +
                        "," +
                        headerOuter[headerOuter.length - 1].y3 +
                        " " +
                        headerOuter[headerOuter.length - 1].x4 +
                        "," +
                        headerOuter[headerOuter.length - 1].y4 +
                        " "
                      }
                      className="RentRollDocumentView__cellHeader"
                    />
                  </svg>
                )
              ];
            } else {
              header = [
                headerOuter && (
                  <svg
                    key="header"
                    height="100%"
                    width="100%"
                    x="0"
                    y="0"
                    viewBox="0 0 100 100"
                    preserveAspectRatio="none"
                  >
                    <polygon
                      points={
                        headerOuter.x1 +
                        "," +
                        headerOuter.y1 +
                        " " +
                        headerOuter.x2 +
                        "," +
                        headerOuter.y2 +
                        " " +
                        headerOuter.x3 +
                        "," +
                        headerOuter.y3 +
                        " " +
                        headerOuter.x4 +
                        "," +
                        headerOuter.y4
                      }
                      className="RentRollDocumentView__cellHeader"
                    />
                  </svg>
                )
              ];
            }

            return header.concat(cells);
          });

          return (
            <svg
              key={`${pi}-${ti}`}
              id={`${pi}-${ti}`}
              className={cx("tooltipBoundary")}
              style={{ overflow: "visible" }}
            >
              {headerData[pi][ti] && (
                <svg
                  id={`advanced-controls`}
                  x={table[0][0].geometry.boundingBox.Left * 100 + "%"}
                  y={table[0][0].geometry.boundingBox.Top * 100 - 2 + "%"}
                  style={{ cursor: "pointer" }}
                >
                  <foreignObject x="0" y="0" width="18px" height="18px">
                    <AdvancedRulesPopover
                      rows={rentRoll}
                      location={{ pi, ti }}
                    />
                  </foreignObject>
                </svg>
              )}
              {cells}
            </svg>
          );
        })}
      </svg>
    );
  }
}

PdfDocumentMarkup.defaultProps = {
  widgetAuth: {}
};

PdfDocumentMarkup.propTypes = {
  page: PropTypes.object,
  pi: PropTypes.number,
  rentRoll: PropTypes.array,
  document: PropTypes.object,
  updateDocumentTemplate: PropTypes.func,
  updateDocumentField: PropTypes.func,
  editText: PropTypes.object,
  editHeader: PropTypes.object,
  updateEditHeader: PropTypes.func,
  clearEditHeader: PropTypes.func,
  updateEditText: PropTypes.func,
  clearEditText: PropTypes.func,
  clearCache: PropTypes.func,
  updateCache: PropTypes.func,
  cache: PropTypes.object,
  exceptionSidebarIsActive: PropTypes.bool,
  pageIndex: PropTypes.number,
  documentMarkupRendered: PropTypes.func,
  exceptions: PropTypes.array,
  fieldNamesMappingForDropDown: PropTypes.object,
  selectedProperty: PropTypes.object,
  deletedRows: PropTypes.array,
  handleScrollToCell: PropTypes.func,
  setPredefModal: PropTypes.func,
  selectedDocumentCell: PropTypes.string,
  propertyType: PropTypes.string,
  widgetAuth: PropTypes.object
};

const mapStateToProps = ({ rentRoll, properties, currentUser }) => ({
  document: rentRoll.document,
  rentRoll: rentRoll.rentRoll,
  editHeader: rentRoll.editHeader,
  editText: rentRoll.editText,
  cache: rentRoll.cache,
  exceptionSidebarIsActive: rentRoll.exceptionSidebarIsActive,
  pageIndex: rentRoll.pageIndex,
  exceptions: rentRoll.exceptions,
  fieldNamesMappingForDropDown: rentRoll.fieldNamesMappingForDropDown,
  selectedProperty: properties.selectedProperty,
  widgetAuth: currentUser.widgetAuth
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateDocumentTemplate,
      updateDocumentField,
      updateEditHeader,
      clearEditHeader,
      updateEditText,
      clearEditText,
      updateCache,
      clearCache
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PdfDocumentMarkup);
