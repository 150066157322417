import useSWR from "swr";
import fetchData from "../helpers/fetchData";
import useSplitPanelV2Context from "./useSplitPanelV2Context";

export default function useRentRollColumns() {
  const { widgetAuth } = useSplitPanelV2Context();

  const { data, error, mutate } = useSWR(
    `/api/rent-roll/get-columns`,
    fetchData(widgetAuth)
  );

  return {
    rentRollColumns: data,
    error,
    mutate
  };
}
