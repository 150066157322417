import PropTypes from "prop-types";
import cx from "classnames";
import { hasSuperAdminAccess } from "helpers/authorization";

import ToggleSwitch from "ui/components/apps/ToggleSwitch";

function UserRow({
  user,
  role,
  toggleStatus,
  updateUserRole,
  updateUserCompany,
  currentUserId,
  currentUserRole,
  companies
}) {
  function handleToggle(userId) {
    if (currentUserId !== userId) {
      toggleStatus(userId);
    }
  }

  return (
    <div className={`UserRow${hasSuperAdminAccess(role) ? "-superadmin" : ""}`}>
      <div
        className={cx(
          "UserRow__details",
          {
            inactive: user.status === "inactive"
          },
          "ms-column-1"
        )}
      >
        {user.email}
      </div>
      <div
        className={cx(
          "UsersTable__filter-superadmin__columnName",
          "ms-column-2"
        )}
      >
        {hasSuperAdminAccess(user.role) || currentUserId === user.id ? (
          user.role
        ) : (
          <select
            type="select"
            onChange={e => updateUserRole(user.id, e.target.value)}
          >
            <option selected={user.role === "admin"} value="admin">
              admin
            </option>
            <option selected={user.role === "user"} value="user">
              user
            </option>
          </select>
        )}
      </div>
      {hasSuperAdminAccess(currentUserRole) && (
        <div
          className={cx(
            "UsersTable__filter-superadmin__columnName",
            "ms-column-3"
          )}
        >
          <select
            type="select"
            disabled={hasSuperAdminAccess(user.role)}
            onChange={e => updateUserCompany(user.id, e.target.value)}
          >
            {companies.map((company, idx) => {
              const { company_name, id } = company;
              return (
                <option
                  selected={user.company_name === company.company_name}
                  key={`company-change-option-${idx}`}
                  value={id}
                >
                  {company_name}
                </option>
              );
            })}
          </select>
        </div>
      )}
      <div
        className={cx(
          "UserRow-__details",
          { "ms-column-4": hasSuperAdminAccess(role) },
          { "ms-column-3": !hasSuperAdminAccess(role) },
          {
            "fill-width": currentUserId === user.id
          }
        )}
      >
        {currentUserId !== user.id && !hasSuperAdminAccess(user.role) && (
          <ToggleSwitch
            className="center-by-margin"
            handleToggle={handleToggle.bind(this, user.id)}
            isOn={user.status === "active"}
          />
        )}
      </div>
      {hasSuperAdminAccess(currentUserRole) && (
        <div
          className={cx("UserRow__details", "ms-column-5", {
            "fill-width": !user.okta_user_input_company
          })}
        >
          {user.okta_user_input_company}
        </div>
      )}
    </div>
  );
}

UserRow.propTypes = {
  user: PropTypes.object,
  role: PropTypes.string,
  currentUserId: PropTypes.number,
  toggleStatus: PropTypes.func,
  updateUserRole: PropTypes.func,
  updateUserCompany: PropTypes.func,
  currentUserRole: PropTypes.string,
  companies: PropTypes.array
};

export default UserRow;
