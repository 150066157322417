import ManageFilesListView from "./ManageFilesListView";
import AuthenticatedRoute from "ui/components/routing/AuthenticatedRoute";
import UploadPopoverBlue from "ui/components/main/upload/UploadPopoverBlue";
import FilterDocuments from "ui/components/filters/FilterDocuments";
import HeaderWithSidebar from "ui/components/shared/HeaderWithSidebar";

import cx from "classnames";
import UploadStatusBar from "ui/components/main/documents/UploadStatusBar";
import Flash from "ui/components/notification/Flash";
import { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const ManageFilesView = ({ widgetAuth }) => {
  const [propertyTypeFilter, setPropertyTypeFilter] = useState({});
  const [documentTypeFilter, setDocumentTypeFilter] = useState({});
  const [propertyListFilter, setPropertyListFilter] = useState({});
  const [documentStatusFilter, setDocumentStatusFilter] = useState({});
  const isWidgetAuthorized = widgetAuth && widgetAuth.authorized;

  return isWidgetAuthorized ? (
    <>
      <div className={cx("ManageFiles__subHeader")}>
        <div
          className="ManageFiles__subHeader-title"
          style={{ display: "flex", alignItems: "center" }}
        >
          <h1 className="ManageFiles__title">Manage Files</h1>
        </div>
        <UploadStatusBar />
        <Flash />
        <div className="ManageFiles__filter-upload">
          <FilterDocuments
            propertyTypeFilter={propertyTypeFilter}
            setPropertyTypeFilter={setPropertyTypeFilter}
            documentTypeFilter={documentTypeFilter}
            setDocumentTypeFilter={setDocumentTypeFilter}
            setPropertyListFilter={setPropertyListFilter}
            setDocumentStatusFilter={setDocumentStatusFilter}
          />
          <UploadPopoverBlue
            style={{ marginRight: "0px", marginLeft: "auto" }}
          />
        </div>
      </div>
      <div className="ManageFiles__list-view">
        <ManageFilesListView
          propertyTypeFilter={propertyTypeFilter}
          documentTypeFilter={documentTypeFilter}
          propertyListFilter={propertyListFilter}
          documentStatusFilter={documentStatusFilter}
        />
      </div>
    </>
  ) : (
    <HeaderWithSidebar className="ManageFiles__Header" includeSubHeader={false}>
      <div className="ManageFiles__body">
        <div className={cx("ManageFiles__subHeader")}>
          <div
            className="ManageFiles__subHeader-title"
            style={{ display: "flex", alignItems: "center" }}
          >
            <h1 className="ManageFiles__title">Manage Files</h1>
          </div>
          <UploadStatusBar />
          <Flash />
          <div className="ManageFiles__filter-upload">
            <FilterDocuments
              propertyTypeFilter={propertyTypeFilter}
              setPropertyTypeFilter={setPropertyTypeFilter}
              documentTypeFilter={documentTypeFilter}
              setDocumentTypeFilter={setDocumentTypeFilter}
              setPropertyListFilter={setPropertyListFilter}
              setDocumentStatusFilter={setDocumentStatusFilter}
            />
          </div>
        </div>
        <div className="ManageFiles__list-view">
          <ManageFilesListView
            propertyTypeFilter={propertyTypeFilter}
            documentTypeFilter={documentTypeFilter}
            propertyListFilter={propertyListFilter}
            documentStatusFilter={documentStatusFilter}
          />
        </div>
      </div>
    </HeaderWithSidebar>
  );
};

ManageFilesView.propTypes = {
  widgetAuth: PropTypes.object
};

function mapStateToProps({ currentUser }) {
  return {
    widgetAuth: currentUser.widgetAuth
  };
}

export default AuthenticatedRoute(connect(mapStateToProps)(ManageFilesView));
