export const onMouseDown = (e, rowData) => {
  const { baseTableRowId, isExcluded, mappedAccount } = rowData;
  const { rowToCopyId, setRowToCopyId, draggingStartingPosition, setDraggingStartingPosition, setCopyModeTimer, setCopyCategoryToElements } = rowData.copyPasteState;
  const canBeCopied = !isExcluded && !!mappedAccount && mappedAccount !== "-";
  if (!!rowToCopyId && baseTableRowId !== rowToCopyId) {
    setRowToCopyId(null);
    setCopyCategoryToElements([]);
    setDraggingStartingPosition(null);

    return;
  }
  if (!draggingStartingPosition) setDraggingStartingPosition(e.pageY);
  if (canBeCopied) setCopyModeTimer(setTimeout(() => setRowToCopyId(baseTableRowId), 500));
};

export const onMouseUp = (e, rowData) => {
  const { copyModeTimer, setIsDragging } = rowData.copyPasteState;
  setIsDragging(false);
  clearTimeout(copyModeTimer);
};

export const onMouseEnter = (e, rowData, setRowHoverIndex, rowIndex, autocompleteElements, baseTableData) => {
  const { baseTableRowId } = rowData;
  const { isDragging, draggingStartingPosition, rowToCopyId, setCopyCategoryToElements } = rowData.copyPasteState;
  setRowHoverIndex(rowIndex);
  if (isDragging && rowToCopyId && rowToCopyId !== baseTableRowId) {
    const autoCompleteMissingElement = autocompleteElements(rowData, rowToCopyId, baseTableData, e.pageY > draggingStartingPosition);
    setCopyCategoryToElements(autoCompleteMissingElement);
  } else if (isDragging && rowToCopyId && rowToCopyId === baseTableRowId) {
    setCopyCategoryToElements([]);
  }
};

export const onMouseLeave = (e, rowData, setRowHoverIndex) => {
  const { rowToCopyId, copyModeTimer } = rowData.copyPasteState;
  setRowHoverIndex(null);
  if (rowToCopyId) clearTimeout(copyModeTimer);
};

export const onMouseMove = (e, rowData) => {
  const { isDragging, draggingStartingPosition, draggingBelowRowToCopy, setDraggingBelowRowToCopy } = rowData.copyPasteState;
  if (isDragging) {
    const newPosition = e.pageY > draggingStartingPosition;
    if (draggingBelowRowToCopy !== newPosition) {
      setDraggingBelowRowToCopy(newPosition);
    }
  }
};
