import PropTypes from "prop-types";
import TableSelection from "./components/TableSelection/TableSelection";
import ChartOfAccountSelection from "./components/ChartOfAccountsSelection/ChartOfAccountSelection";
import Accounts from "./components/Accounts/Accounts";
import Columns from "./components/Columns/Columns";
import {
  WORKFLOW_TABLE_SELECTION,
  WORKFLOW_CHART_OF_ACCOUNTS_SELECTION,
  WORKFLOW_ACCOUNT_MAPPING,
  WORKFLOW_COLUMN_PERIODIZATION
} from "ui/components/opex/helpers/opex";
import {
  CELL_STATUS_CORRECTED,
  CONFIDENCE_CONFIRMED,
  CONFIDENCE_NEEDS_REVIEW,
  CONFIDENCE_UNMAPPED
} from "ui/components/opex/shared";
import Spinner from "ui/components/shared/Spinner";
import StatusHeader from "./components/StatusHeader/StatusHeader";
import { useState } from "react";

const DocumentView = ({
  groupName,
  currentWorkflowStatus,
  currentTable,
  setCurrentTable,
  currentPage,
  setCurrentPage,
  document,
  isLoading,
  isMapping,
  refreshData,
  rawDataJson,
  setRawDataJson,
  onUpdateRawDataRows,
  metaDataJson,
  setMetaDataJson,
  onChangeTableSelection,
  onChangeChartOfAccount,
  onUpdateMetaDataJson,
  onUpdateDocumentTemplate,
  onToggleSelectAllTables,
  widgetAuth
}) => {
  const [statusHeaderCounters, setStatusHeaderCounters] = useState({
    [CELL_STATUS_CORRECTED]: 0,
    [CONFIDENCE_CONFIRMED]: 0,
    [CONFIDENCE_NEEDS_REVIEW]: 0,
    [CONFIDENCE_UNMAPPED]: 0
  });
  return !isLoading ? (
    <>
      <StatusHeader
        document={document}
        currentTable={currentTable}
        setCurrentTable={setCurrentTable}
        currentWorkflowStatus={currentWorkflowStatus}
        statusHeaderCounters={statusHeaderCounters}
        onToggleSelectAllTables={onToggleSelectAllTables}
      />
      <div className="OpexPanelView__DocumentView">
        {currentWorkflowStatus === WORKFLOW_TABLE_SELECTION && (
          <TableSelection
            document={document}
            onChange={onChangeTableSelection}
          />
        )}
        {currentWorkflowStatus === WORKFLOW_CHART_OF_ACCOUNTS_SELECTION && (
          <ChartOfAccountSelection
            document={document}
            onChange={onChangeChartOfAccount}
            isMapping={isMapping}
          />
        )}
        {currentWorkflowStatus === WORKFLOW_ACCOUNT_MAPPING && (
          <Accounts
            groupName={groupName}
            document={document}
            currentTable={currentTable}
            setCurrentTable={setCurrentTable}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            refreshData={refreshData}
            rawDataJson={rawDataJson}
            setRawDataJson={setRawDataJson}
            onUpdateRawDataRows={onUpdateRawDataRows}
            metaDataJson={metaDataJson}
            setMetaDataJson={setMetaDataJson}
            onUpdateMetaDataJson={onUpdateMetaDataJson}
            onUpdateDocumentTemplate={onUpdateDocumentTemplate}
            setStatusHeaderCounters={setStatusHeaderCounters}
          />
        )}
        {currentWorkflowStatus === WORKFLOW_COLUMN_PERIODIZATION && (
          <Columns
            document={document}
            currentTable={currentTable}
            setStatusHeaderCounters={setStatusHeaderCounters}
            widgetAuth={widgetAuth}
          />
        )}
      </div>
    </>
  ) : (
    <div className="OpexPanelView__DocumentView__Spinner-Wrapper">
      <Spinner type="ThreeDots" color="#009bdf" height={40} width={40} />
    </div>
  );
};

export default DocumentView;

DocumentView.propTypes = {
  groupName: PropTypes.string,
  currentWorkflowStatus: PropTypes.string,
  currentTable: PropTypes.number,
  setCurrentTable: PropTypes.func,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  document: PropTypes.object,
  opex: PropTypes.object,
  isLoading: PropTypes.bool,
  isMapping: PropTypes.bool,
  refreshData: PropTypes.func,
  rawDataJson: PropTypes.object,
  setRawDataJson: PropTypes.func,
  onUpdateRawDataRows: PropTypes.func,
  metaDataJson: PropTypes.object,
  setMetaDataJson: PropTypes.func,
  onChangeTableSelection: PropTypes.func,
  onChangeChartOfAccount: PropTypes.func,
  onUpdateMetaDataJson: PropTypes.func,
  onUpdateDocumentTemplate: PropTypes.func,
  onToggleSelectAllTables: PropTypes.func
};
