import { apiFetch } from "./apiFetch";

export default async function updateData({ path, method, data, widgetAuth }) {
  const response = await apiFetch(
    path,
    {
      method,
      body: JSON.stringify(data)
    },
    widgetAuth
  );

  if (!response.ok) {
    const responseBody = await response.json();
    throw new Error(responseBody.error, { cause: responseBody.error });
  }
}
