import { merge } from "ramda";
import {
  expandTenantProperties,
  collapseTenantProperties,
  addTenantMetricsToTenantRow
} from "./helpers/tenants";

import {
  FETCHING_TENANTS,
  FETCHING_TENANTS_SUCCESSFUL,
  FETCHING_TENANT_PROPERTIES_SUCCESSFUL,
  COLLAPSE_TENANT_PROPERTIES,
  EXPAND_TENANT_PROPERTIES,
  FETCHING_EXCEL_RENT_ROLLS_SUCCESSFUL,
  CLEAR_SELECTED_PORTFOLIO_TENANT,
  CLEAR_METRICS_TENANT,
  CLEAR_TENANTS
} from "ui/store/actions/tenants.js";

export const FETCHING = "FETCHING";
export const CREATING = "CREATING";
export const LOADED = "LOADED";

export default function tenants(
  state = {
    tenants: [],
    selectedPortfolio: {},
    status: FETCHING,
    metrics: {},
    tenantsAnalysisTenants: [],
    tenantsAnalysisMetrics: {},
    excelRentRolls: []
  },
  action
) {
  switch (action.type) {
    case FETCHING_TENANTS: {
      return merge(state, { status: FETCHING });
    }
    case FETCHING_TENANTS_SUCCESSFUL: {
      const { tenants, selectedPortfolio, metrics } = action;
      return merge(state, {
        tenants: tenants.map(tenant => {
          return addTenantMetricsToTenantRow(tenant);
        }),
        selectedPortfolio,
        metrics,
        status: LOADED
      });
    }
    case FETCHING_TENANT_PROPERTIES_SUCCESSFUL: {
      const { tenants, selectedPortfolio, metrics } = action;
      // we'll do metrics on this later
      return merge(state, {
        tenantsAnalysisTenants: tenants,
        tenantsAnalysisMetrics: metrics,
        selectedPortfolio,
        status: LOADED
      });
    }
    case FETCHING_EXCEL_RENT_ROLLS_SUCCESSFUL: {
      const { excelRentRolls } = action;
      return merge(state, {
        excelRentRolls
      });
    }
    case EXPAND_TENANT_PROPERTIES: {
      const { tenant, tenants, selectedPortfolio, metrics } = action;
      // we'll do metrics on this later
      return merge(state, {
        tenants: expandTenantProperties(tenant, state.tenants, tenants),
        selectedPortfolio
      });
    }
    case COLLAPSE_TENANT_PROPERTIES: {
      const { tenant } = action;
      return merge(state, {
        tenants: collapseTenantProperties(state.tenants, tenant)
      });
    }
    case CLEAR_SELECTED_PORTFOLIO_TENANT: {
      return { ...state, selectedPortfolio: {} };
    }
    case CLEAR_METRICS_TENANT: {
      return { ...state, metrics: {} };
    }
    case CLEAR_TENANTS: {
      return { ...state, tenants: [] };
    }
    default: {
      return state;
    }
  }
}
