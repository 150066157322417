import PropTypes from "prop-types";
import moment from "moment";
import { BsFillCheckCircleFill } from "react-icons/bs";

const GreenTick = require("ui/images/green-tick.svg");

const CompletedStatus = ({ text, date, type }) => {
  const momentDate = moment(date);

  const dateElement = (
    <span>
      {`${text} ${momentDate.format("HH:mm:ss A")} on ${momentDate.format(
        "MMMM DD, YYYY"
      )}`}
    </span>
  );

  if (type === "multiline") {
    return (
      <div className="Opex__CompletedStatus">
        <div className={"SplitPanelV2__LeftSidebar-completed-on-timestamp"}>
          <img src={GreenTick} />
          {dateElement}
        </div>
      </div>
    );
  }

  // singleline
  return (
    <div className="Opex__CompletedStatus singleline">
      <BsFillCheckCircleFill /> {dateElement}
    </div>
  );
};

export default CompletedStatus;

CompletedStatus.propTypes = {
  text: PropTypes.string,
  type: PropTypes.oneOf(["multiline", "singleline"]).isRequired, // render the tables, or the columns
  date: PropTypes.string
};
