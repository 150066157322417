export default function filterExceptions({
  rowIndex = undefined,
  pageIndexes = [],
  tableIndexes = [],
  exceptions = []
}) {
  return exceptions.filter(exception => {
    const { location } = exception;

    return (
      exception &&
      pageIndexFilter({ pageIndexes, location }) &&
      tableIndexFilter({ tableIndexes, location }) &&
      rowIndexFilter({ rowIndex, location })
    );
  });
}

function pageIndexFilter({ pageIndexes = [], location: { pi, start, end } }) {
  if (!pageIndexes.length) return true;

  return (
    (pi !== undefined && pageIndexes.includes(pi)) ||
    (start && pageIndexes.includes(start.pi)) ||
    (end && pageIndexes.includes(end.pi))
  );
}

function tableIndexFilter({ tableIndexes = [], location: { ti, start, end } }) {
  if (!tableIndexes.length) return true;

  return (
    (ti !== undefined && tableIndexes.includes(ti)) ||
    (start && tableIndexes.includes(start.ti)) ||
    (end && tableIndexes.includes(end.ti))
  );
}

function rowIndexFilter({ rowIndex, location: { ri, start, end } }) {
  if (rowIndex === undefined) return true;

  return (
    rowIndex === ri ||
    (start && start.ri === rowIndex) ||
    (end && end.ri === rowIndex)
  );
}
