import { useState, useMemo } from "react";
import PropTypes from "prop-types";
import IconButton from "ui/components/shared/IconButton";
import { DatePicker } from "antd";
import dayjs from "dayjs";

export default function EffectiveDatePicker({
  effectiveDate,
  close,
  handleSaveEffectiveDate,
  initializedVacantFlag
}) {
  const value = useMemo(
    () =>
      effectiveDate
        ? dayjs(effectiveDate).startOf("day")
        : dayjs().startOf("day"),
    [effectiveDate]
  );
  const [date, setDate] = useState(value);
  const [isSaving, setIsSaving] = useState(false);
  const PERIOD_DATE_FORMAT = "MM/DD/YYYY";

  function handleDateChange(value) {
    if (!value) return;
    setDate(value);
  }

  async function handleSaveClick() {
    setIsSaving(true);
    await handleSaveEffectiveDate(date);
    await initializedVacantFlag();
  }

  return (
    <div className="SplitPanelV2__LeftSidebar__EffectiveDatePicker">
      <DatePicker
        id="effective_date_picker"
        style={{ margin: "0px 15px" }}
        format={PERIOD_DATE_FORMAT}
        onChange={handleDateChange}
        value={date}
      />
      <button
        className="SplitPanelV2__LeftSidebar__EffectiveDatePicker-save-btn"
        onClick={handleSaveClick}
        disabled={isSaving}
      >
        Save
      </button>
      <IconButton
        onClick={close}
        src={require("ui/images/x-icon.svg")}
        disabled={isSaving}
      />
    </div>
  );
}

EffectiveDatePicker.propTypes = {
  close: PropTypes.func.isRequired,
  effectiveDate: PropTypes.string,
  handleSaveEffectiveDate: PropTypes.func.isRequired,
  initializedVacantFlag: PropTypes.func
};
