import { Component } from "react";
import AdminLayout from "../AdminLayout";
import { connect } from "react-redux";
import NavLink from "ui/components/shared/NavLink";
import UsersTable from "./UsersTable";
import PropTypes from "prop-types";
import CsvDownload from "react-json-to-csv";
import moment from "moment";
// import { omit, map } from "ramda";
import Sticky from "react-sticky-el";

import { clearUserStore } from "ui/store/actions/users";

import AuthenticateAdmin from "ui/components/routing/AuthenticateAdmin";
import PaginationNavigation from "./PaginationNavigation";
import UsersTableHeader from "./UsersTableHeader";
import UsersTableFilters from "./UsersTableFilters";

class UsersDashboard extends Component {
  componentWillUnmount() {
    const { clearUserStoreArray } = this.props;
    clearUserStoreArray();
  }

  render() {
    let company, role;
    const { currentUser, users } = this.props;

    if (!currentUser) {
      company = undefined;
      role = undefined;
    } else {
      company = currentUser.company;
      role = currentUser.role;
    }

    const companyName = company === null ? "" : `: ${company.company_name}`;

    return (
      <AdminLayout
        className="UsersDashboard"
        breadcrumbs={[{ label: "Dashboard", path: "/admin" }]}
        title={`Manage Users${companyName}`}
      >
        <Sticky
          scrollElement=".AdminLayout__body"
          stickyStyle={{ zIndex: "2" }}
        >
          <div className="UsersDashboard__new-user-button-container">
            <PaginationNavigation />
            <NavLink
              className="UsersDashboard__new-user-button Button Button--action"
              to="/admin/users/new"
            >
              Add New User
            </NavLink>
            <CsvDownload
              data={users}
              filename={`users-${moment().format()}.csv`}
              style={{
                boxShadow: "none",
                border: "none",
                background: "#1565c0",
                color: "white",
                fontFamily: "Roboto",
                padding: "7px 10px 7px",
                marginLeft: "10px",
                fontSize: "13px"
              }}
            >
              Download CSV
            </CsvDownload>
          </div>
          <UsersTableHeader role={role} />
          <UsersTableFilters />
        </Sticky>
        <UsersTable />
      </AdminLayout>
    );
  }
}

UsersDashboard.propTypes = {
  currentUser: PropTypes.object,
  company: PropTypes.object,
  users: PropTypes.array,
  filteredResults: PropTypes.array,
  clearUserStoreArray: PropTypes.func
};

function mapStateToProps({ currentUser, users }) {
  return {
    currentUser,
    filteredResults: users.filteredResults,
    users: users.users
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearUserStoreArray: () => dispatch(clearUserStore())
  };
}

const UsersDashboardComponent = AuthenticateAdmin(UsersDashboard);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersDashboardComponent);
