import { useRef } from "react";
import Popup from "reactjs-popup";
import PropTypes from "prop-types";

import EditDataBody from "./EditDataBody";
import { columnDetails } from "ui/components/manageFiles/helpers/manageFileListView";

const EditDataPopup = ({
  portfolioId,
  documentId,
  originalFilename,
  widgetAuth,
  setSortByLastUpdated = () => {},
  filterObject = {}
}) => {
  const ref = useRef();
  const closePopover = () => ref.current.close();
  const sortName = columnDetails["Last Updated"].sortName;
  const sortOrder = "desc";
  const fileExtensionRegex = /[^.]+$/;
  const fileExtension =
    fileExtensionRegex.exec(originalFilename).length > 0
      ? fileExtensionRegex.exec(originalFilename)[0]
      : "";

  return (
    <Popup
      ref={ref}
      trigger={
        <div className="ManageFilesList__required-warning">
          <img
            src={require("ui/images/warning-triangle.svg")}
            className="ManageFilesList__required-img"
          />
          Required
        </div>
      }
      position="center center"
      modal
      closeOnDocumentClick
      className="centered-popover"
    >
      <EditDataBody
        closePopover={closePopover}
        portfolioId={portfolioId}
        documentId={documentId}
        widgetAuth={widgetAuth}
        sortOrder={sortOrder}
        sortName={sortName}
        filterObject={filterObject}
        setSortByLastUpdated={setSortByLastUpdated}
        fileExtension={fileExtension}
      />
    </Popup>
  );
};

EditDataPopup.propTypes = {
  portfolioId: PropTypes.number,
  documentId: PropTypes.number,
  manageFilesView: PropTypes.bool,
  widgetAuth: PropTypes.object,
  filterObject: PropTypes.object,
  setSortByLastUpdated: PropTypes.func,
  originalFilename: PropTypes.string
};

export default EditDataPopup;
