import PropTypes from "prop-types";

import { getColorFromPctComplete } from "./helpers/processing";

import ProgressBar from "ui/components/shared/progressBar/ProgressBar";

const PercentComplete = ({ totalPctComplete }) => {
  const color = getColorFromPctComplete(totalPctComplete);
  return (
    <div className="SplitPanel__percentComplete">
      <div className="SplitPanel__percentComplete__label" style={{ color }}>
        {`${totalPctComplete}% complete`}
      </div>
      <ProgressBar percent={totalPctComplete} color={color} />
    </div>
  );
};

PercentComplete.propTypes = {
  totalPctComplete: PropTypes.number
};

export default PercentComplete;
