export default function colOuter(colIndex, table) {
  const firstRow = table[0][colIndex];
  const lastRow = table[table.length - 1][colIndex];
  return {
    x1: firstRow.geometry.boundingBox.Left * 100,
    y1: firstRow.geometry.boundingBox.Top * 100,
    x2:
      (firstRow.geometry.boundingBox.Left +
        firstRow.geometry.boundingBox.Width) *
      100,
    y2: firstRow.geometry.boundingBox.Top * 100,
    x3:
      (lastRow.geometry.boundingBox.Left + lastRow.geometry.boundingBox.Width) *
      100,
    y3:
      (lastRow.geometry.boundingBox.Top + lastRow.geometry.boundingBox.Height) *
      100,
    x4: lastRow.geometry.boundingBox.Left * 100,
    y4:
      (lastRow.geometry.boundingBox.Top + lastRow.geometry.boundingBox.Height) *
      100
  };
}
