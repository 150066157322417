export const scrollToCellOnRentRoll = (tableRefs, selectedCell) => {
  const selectedParsed = selectedCell.split("-");
  if (
    !tableRefs ||
    !tableRefs.current ||
    !Object.keys(tableRefs.current).length
  )
    return;

  const selected = tableRefs.current;

  selected.scrollToRow(selectedParsed[0], "center");
  const columnManager = selected.columnManager;
  const columns = columnManager.getColumns();
  const leftFrozenColumnsWidth = columnManager.getLeftFrozenColumnsWidth();
  const rightFrozenColumnsWidth = columnManager.getRightFrozenColumnsWidth();
  const scrollLeft = selected.getDOMNode().querySelector(".BaseTable__body")
    .scrollLeft;
  let w = columns
    .slice(0, Number(selectedParsed[1]))
    .reduce((t, c) => t + c.width, 0);

  let clientW = selected.getDOMNode().clientWidth;

  // rentroll table only passes in 'row-col'
  if (selectedParsed.length === 2) {
    let r = rightFrozenColumnsWidth;
    let l = rightFrozenColumnsWidth; // wrong value but scrolling works as is???
    let viewport = clientW - r - l; //<- width of whole table minus the fixed cols
    let cellWidth = columns[selectedParsed[1]].width; //<- width of selected cell

    // w = total column widths Minus Selected Cell
    let distance2CellEnd = w + cellWidth;
    let distance2ViewportEnd = r + viewport;
    let distanceBeyondViewport = distance2CellEnd - distance2ViewportEnd;

    // only scroll if selected cell is outside veiwport
    if (distanceBeyondViewport > 0) {
      // 100 is added on to center the cell more
      selected.scrollToLeft(distanceBeyondViewport + r + 100);
    }
    return;
  }
  if (leftFrozenColumnsWidth) {
    w -= leftFrozenColumnsWidth;
  }
  if (rightFrozenColumnsWidth) {
    w -= rightFrozenColumnsWidth;
  }
  if (w > clientW || w < scrollLeft) {
    selected.scrollToLeft(w);
  }
};

export const scrollToCellOnDocument = (key, isExcel, selected) => {
  if (isExcel) {
    if (!selected) return;
    selected.scrollIntoView({
      block: "center",
      behavior: "smooth"
    });
  } else {
    const element = document.getElementById(key);
    if (!element) return;

    const scrollParent = document.querySelector(".SplitPanelV2__PdfDocument");
    const offsetTop = document.querySelector(".SplitPanelV2__PageNavigation")
      ? document.querySelector(".SplitPanelV2__PageNavigation").clientHeight
      : 0;
    const boundingRect = element.getBoundingClientRect();
    // need offset to account for elements above the scrollparent since getBoundingClientRect() is relative to the viewport and not the scroll parent
    const relativeTopPos = boundingRect.top - scrollParent.offsetTop;

    if (
      relativeTopPos < 0 ||
      scrollParent.clientHeight < relativeTopPos + boundingRect.height
    ) {
      scrollParent.scrollTo({
        top: scrollParent.scrollTop + relativeTopPos - offsetTop,
        behavior: "smooth"
      });
    }
  }
};
