import { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import AdminLayout from "../AdminLayout";
import RentRollFieldsTable from "./RentRollFieldsTable";
import CustomColumnsForm from "./CustomColumnsForm";

import { hasExclusiveAdminAccess } from "helpers/authorization";
import {
  getRentRollFields,
  setRentRollFields
} from "ui/store/actions/rentRoll";

import Button from "ui/components/shared/Button";

import AuthenticateAdmin from "ui/components/routing/AuthenticateAdmin";
import Flash from "ui/components/notification/Flash";

class RentRollDashboard extends Component {
  componentDidMount() {
    const { getRentFollFields } = this.props;
    getRentFollFields();
  }

  render() {
    const {
      currentUser,
      companyName,
      submitUpdatedRentRollFields,
      fieldsByPropertyType
    } = this.props;

    const role = currentUser.role;

    return (
      <AdminLayout
        className="RentRollDashboard"
        breadcrumbs={[{ label: "Dashboard", path: "/admin" }]}
        title="Manage Rent Roll Fields"
      >
        <Flash />
        {hasExclusiveAdminAccess(role) && (
          <h3 className="RentRollDashboard__company-name">{companyName}</h3>
        )}
        {/* <div className="RentRollDashboard__submit-button-div">
          <Button
            className="RentRollDashboard__button"
            onClick={() => submitUpdatedRentRollFields(fieldsByPropertyType)}
          >
            {hasSuperAdminAccess(role)
              ? "Update Default Rent Roll Fields"
              : "Update Rent Roll Fields"}
          </Button>
        </div>
        <RentRollFieldsTable /> */}
        <CustomColumnsForm />
      </AdminLayout>
    );
  }
}

RentRollDashboard.propTypes = {
  submitUpdatedRentRollFields: PropTypes.func,
  getRentFollFields: PropTypes.func,
  role: PropTypes.string,
  companyName: PropTypes.string,
  currentUser: PropTypes.object
};

function mapDispatchToProps(dispatch) {
  return {
    submitUpdatedRentRollFields: fieldsByPropertyType =>
      dispatch(setRentRollFields(fieldsByPropertyType)),
    getRentFollFields: () => dispatch(getRentRollFields())
  };
}

function mapStateToProps({ rentRoll, currentUser }) {
  return {
    currentUser,
    role: currentUser.role,
    companyName:
      currentUser.company === null ? "" : currentUser.company.company_name,
    fieldsByPropertyType: rentRoll.fieldsByPropertyType
  };
}

const AuthRentRollDashboard = AuthenticateAdmin(RentRollDashboard);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthRentRollDashboard);
