import PropTypes from "prop-types";
import { TemplateStatus } from "helpers/opex";
import DeleteConfirmPopup from "ui/components/admin/templates/shared/DeleteConfirmPopup";
import showOption from "ui/components/admin/templates/shared/showOptions";
import {
  DUPLICATE,
  DELETE,
  ARCHIVE,
  PUBLISH,
  UNPUBLISH
} from "ui/components/admin/templates/shared/constants";

const ActionsDropDown = ({
  inUse,
  isPublished,
  isArchived,
  isCL,
  templateId,
  updateTemplate
}) => {
  const statuses = { isPublished, isArchived, inUse, isCL };
  return (
    <div className="TemplateCard_MenuDropDown">
      <ul>
        {showOption(DUPLICATE, statuses) && (
          <li>
            <span
              onClick={() => updateTemplate(templateId, DUPLICATE)}
              className="TemplateCard_MenuDropDown__item"
            >
              Make a copy
            </span>
          </li>
        )}
        {showOption(DELETE, statuses) && (
          <li>
            <DeleteConfirmPopup
              description="Are you sure you want to delete this template?"
              deleteTemplate={updateTemplate}
              templateId={templateId}
              trigger={
                <span className="TemplateCard_MenuDropDown__item">Delete</span>
              }
            />
          </li>
        )}
        {showOption(ARCHIVE, statuses) && (
          <li>
            <span
              onClick={() =>
                updateTemplate(templateId, TemplateStatus.ARCHIVED)
              }
              className="TemplateCard_MenuDropDown__item"
            >
              Archive
            </span>
          </li>
        )}
        {showOption(UNPUBLISH, statuses) && (
          <li>
            <span
              onClick={() =>
                updateTemplate(templateId, TemplateStatus.UNPUBLISHED)
              }
              className="TemplateCard_MenuDropDown__item"
            >
              Unpublish
            </span>
          </li>
        )}
        {showOption(PUBLISH, statuses) && (
          <li>
            <span
              onClick={() =>
                updateTemplate(templateId, TemplateStatus.PUBLISHED)
              }
              className="TemplateCard_MenuDropDown__item"
            >
              Publish
            </span>
          </li>
        )}
      </ul>
    </div>
  );
};

ActionsDropDown.propTypes = {
  inUse: PropTypes.bool,
  isPublished: PropTypes.bool,
  isArchived: PropTypes.bool,
  isCL: PropTypes.bool,
  templateId: PropTypes.number,
  updateTemplate: PropTypes.func
};

export default ActionsDropDown;
