import PropTypes from "prop-types";
// import classNames from "classnames";

const SpiralSpinner = ({ style }) => (
  <img
    style={style}
    src={require("ui/images/icon-spinnerx24.svg")}
    className="SpiralSpinner"
  />
);

SpiralSpinner.propTypes = {
  style: PropTypes.object
};

export default SpiralSpinner;
