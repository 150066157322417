import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useForm, useStep } from "react-hooks-helper";
import PropertiesFirstForm from "ui/components/properties/PropertiesFirstForm";
import PropertiesSecondForm from "ui/components/properties/PropertiesSecondForm";
import Spinner from "ui/components/shared/Spinner";
const steps = [{ id: "firstform" }, { id: "secondform" }];
import { getColumns } from "ui/components/properties/propertiesUtil";
import { propertyActions } from "ui/components/properties/helpers/propertiesList";

const MultiStepForm = ({
  onNext,
  onSubmit,
  propertyData,
  isLoadingReisProperty,
  isModalOpen,
  resetSearch,
  childToEditProperty,
  changePage,
  onSubmitAttachDoc,
  action,
  canUpdatePropertyType
}) => {
  const [formData, setForm] = useForm(getColumns());
  const { step, navigation } = useStep({ initialStep: 0, steps });
  const { id } = step;
  const [selCountry, setSelCountry] = useState("United States");
  const [selState, setSelState] = useState(formData.state);
  const [selPortfolio, setSelPortfolio] = useState();
  const [
    isRecommendedPropertySelected,
    setRecommendedPropertySelected
  ] = useState(false);
  formData.country = selCountry;

  useEffect(() => {
    formData.portfolio = selPortfolio;
  }, [selPortfolio]);

  useEffect(() => {
    for (const [key, value] of Object.entries(propertyData)) {
      setForm({
        target: {
          name: key,
          value: value
        }
      });
    }
  }, [propertyData]);

  useEffect(() => {
    if (action !== propertyActions.edit && !isModalOpen) {
      // Reset form Data
      for (const [key, _value] of Object.entries(propertyData)) {
        setForm({
          target: {
            name: key,
            value: ""
          }
        });
      }
      resetSearch();
    }
  }, [isModalOpen]);

  function selectPortfolio(val) {
    setSelPortfolio(val);
    formData.portfolio = val;
  }

  function selectCountry(val) {
    setSelCountry(val);
    formData.country = val;
  }

  function selectRegion(val) {
    setSelState(val);
    formData.state = val;
  }

  const props = {
    formData,
    setForm,
    navigation,
    onNext,
    onSubmit,
    propertyData,
    resetSearch,
    selectCountry,
    selectRegion,
    isRecommendedPropertySelected,
    setRecommendedPropertySelected,
    selCountry,
    selState,
    childToEditProperty,
    changePage,
    selPortfolio,
    selectPortfolio,
    onSubmitAttachDoc,
    action,
    canUpdatePropertyType
  };

  switch (id) {
    case "firstform":
      return <PropertiesFirstForm {...props} />;
    case "secondform": {
      if (isLoadingReisProperty) {
        return (
          <Spinner type="ThreeDots" color="#00BFFF" height={80} width={80} />
        );
      } else {
        return <PropertiesSecondForm {...props} />;
      }
    }
    default:
      return null;
  }
};

MultiStepForm.defaultProps = {
  canUpdatePropertyType: true
};

MultiStepForm.propTypes = {
  onNext: PropTypes.func,
  onSubmit: PropTypes.func,
  propertyData: PropTypes.object,
  isLoadingReisProperty: PropTypes.bool,
  isModalOpen: PropTypes.bool,
  resetSearch: PropTypes.func,
  childToEditProperty: PropTypes.func,
  changePage: PropTypes.func,
  onSubmitAttachDoc: PropTypes.func,
  action: PropTypes.string,
  canUpdatePropertyType: PropTypes.bool
};

export default MultiStepForm;
