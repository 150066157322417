import {
  SET_INTERVAL_RUNNING,
  SET_INTERVAL_ID,
  CLEAR_INTERVAL_ID
} from "../actions/statusBar";

export default function statusBar(
  state = {
    intervalRunning: false,
    intervalId: null
  },
  action
) {
  switch (action.type) {
    case SET_INTERVAL_RUNNING: {
      const { intervalRunning } = action;
      return { ...state, intervalRunning };
    }
    case SET_INTERVAL_ID: {
      const { intervalId } = action;
      return { ...state, intervalId };
    }
    case CLEAR_INTERVAL_ID: {
      return { ...state, intervalId: null };
    }
    default: {
      return state;
    }
  }
}
