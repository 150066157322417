import { apiFetch } from "./apiClient";

export const API_SIGNATURE_GENERATED =
  "s2sAuthentication/API_SIGNATURE_GENERATED";

export function generateApiSignature(params) {
  const { companyId, apiPath, apiRequestDate } = params;
  const companyObj = {
    companyId,
    apiPath,
    apiRequestDate
  };

  return async function(dispatch) {
    try {
      const response = await apiFetch("/api/companies/generate-api-signature", {
        method: "post",
        body: JSON.stringify(companyObj)
      });
      const responseBody = await response.json();
      if (!response.ok) {
        throw new Error(responseBody.error);
      }
      dispatch(apiSignatureGenerated(responseBody));
    } catch (e) {
      console.log("Error generating signature");
      console.log(e);

      dispatch(apiSignatureGenerated({ error: "Error generating signature" }));
    }
  };
}

function apiSignatureGenerated(responseDetails) {
  const {
    apiKey,
    secretKey,
    apiSignature,
    apiRequestDate,
    apiHeader,
    error
  } = responseDetails;
  return {
    apiPublicKey: apiKey,
    secretKey,
    apiSignature,
    apiRequestDate,
    apiAuthenticationHeader: apiHeader,
    error,
    type: API_SIGNATURE_GENERATED
  };
}
