import { Component } from "react";
import PropTypes from "prop-types";

export default class SelectFile extends Component {
  handleOnChange = e => {
    this.props.handleImageChange(e);
  };
  render() {
    return (
      <div className="SelectFile">
        <form className="SelectFile__form">
          <input
            id="file"
            className="inputfile"
            type="file"
            onChange={e => this.handleOnChange(e)}
          />
          <label htmlFor="file" style={{}}>
            Select a file
          </label>
        </form>
      </div>
    );
  }
}

SelectFile.propTypes = {
  handleImageChange: PropTypes.func,
  disableButton: PropTypes.bool
};
