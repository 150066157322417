import PropTypes from "prop-types";

const OpexPageNumber = ({ pageIndex }) => (
  <div className="OpexPanelView__PageNumber">Page {pageIndex + 1}</div>
);

export default OpexPageNumber;

OpexPageNumber.propTypes = {
  pageIndex: PropTypes.number
};
