import moment from "moment";

export const fields = [
  "File Name",
  "Type",
  "Status",
  "Last Update",
  "By",
  "Actions"
];

export function getEffectiveDate(effectiveDate) {
  if (!effectiveDate) return "-";
  return moment(effectiveDate)
    .utc()
    .format("MMM DD, YYYY")
    .toUpperCase();
}

export const alignStickyTableHeader = name => {
  if (
    "File Name" === name ||
    "Status" === name ||
    "By" === name ||
    "Portfolio" === name ||
    "Property" === name ||
    "Type" === name ||
    "Document" === name ||
    "Last Update" === name
  ) {
    return "sticky-table__cell--left";
  } else {
    return "sticky-table__cell--center";
  }
};

export const alignStickyTableCell = name => {
  if (
    "File Name" === name ||
    "Status" === name ||
    "By" === name ||
    "Type" === name ||
    "Last Update" === name
  ) {
    return "sticky-table__cell--left";
  } else {
    return "sticky-table__cell--center";
  }
};

export const getPublishedDate = document => {
  if (!document) return null;
  return document.last_published_at === null
    ? moment(document.updated_at)
    : moment(document.last_published_at);
};

export const displayDocumentType = {
  RENT_ROLL: "Rent Roll",
  OPERATING_STATEMENT: "Operating Statement"
};

export function mapUnnormalizedFieldsFromRentRoll(allRentRolls) {
  allRentRolls.forEach(rentRoll => {
    const unnormalizedFields = rentRoll.mapped_unnormalized_fields;
    if (unnormalizedFields) {
      for (const [key, value] of Object.entries(unnormalizedFields)) {
        rentRoll[key] = value;
      }
    }
  });
  return allRentRolls;
}

export function getExcelDownloadHeadersForSelectedProperty(
  selectedProperty,
  fieldNamesMappingForDropDown,
  rentRollColumns
) {
  const headers = [];
  headers.push(...rentRollColumns);

  const fieldMapping = {};
  if (selectedProperty && selectedProperty.property_type) {
    const tempFieldMapping =
      fieldNamesMappingForDropDown[
        selectedProperty.property_type.toLowerCase()
      ];
    for (const key in tempFieldMapping) {
      fieldMapping[key] = `QP ${tempFieldMapping[key]}`; //add prefix
    }
  }
  let fieldMappingForExcel = {};
  if (fieldMapping) {
    fieldMappingForExcel = { ...fieldMapping };
    const rentroll_columns_for_excel = [...rentRollColumns];
    rentroll_columns_for_excel.forEach(value => {
      const currentKey = value.key;
      let currentLabel = fieldMappingForExcel[currentKey];
      if (currentLabel && value.label !== currentLabel) {
        value.label = currentLabel;
      }
      delete fieldMappingForExcel[currentKey];
    });
  }

  const excludeFromExcel = ["actions", "lease_dates"];
  for (const [key, value] of Object.entries(fieldMappingForExcel)) {
    if (!excludeFromExcel.includes(key)) {
      headers.push({ label: `${value}`, key: `${key}` });
    }
  }
  return headers;
}
