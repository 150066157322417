const moment = require("moment");
import { useMemo } from "react";
import { MANUAL_ADD } from "services/helpers/rentRoll";
import {
  parseDataType,
  parseValue
} from "ui/components/rentRoll/splitPanel/helpers/processing";
import computeCellFlags from "../helpers/computeCellFlags";
import filterExceptions from "../helpers/filterExceptions";
import { generateTableKey } from "../helpers/tableKeys";
import useDocument from "./useDocument";
import useDocumentTables from "./useDocumentTables";
import useExceptions from "./useExceptions";
import useProperty from "./useProperty";
import useRentRoll from "./useRentRoll";
import useRentRollFieldsByPropertyType from "./useRentRollFieldsByPropertyType";

export const TENANT_KEY = "tenant_name";
export const ACTIONS_KEY = "actions";
export const VACANCY_KEY = "is_vacant";
export const DELETED_STATUS = "DOCUMENT_DEL";

export default function useRentRollTableData() {
  const { document } = useDocument();
  const { documentTables } = useDocumentTables();
  const { exceptions } = useExceptions();
  const { property } = useProperty();
  const { rentRoll } = useRentRoll();
  const { rentRollFieldsByPropertyType } = useRentRollFieldsByPropertyType();

  const fields = useMemo(
    () => computePropertyFields({ property, rentRollFieldsByPropertyType }),
    [property, rentRollFieldsByPropertyType]
  );
  return useMemo(
    () =>
      computeDocumentTablesData({
        document,
        documentTables,
        exceptions,
        fields,
        rentRoll
      }),
    [document, documentTables, exceptions, fields, rentRoll]
  );
}

export function computePropertyFields({
  property,
  rentRollFieldsByPropertyType
}) {
  if (!property || !property.property_type || !rentRollFieldsByPropertyType)
    return [];

  const propertyType = property.property_type.toLowerCase();

  if (
    !rentRollFieldsByPropertyType[propertyType] ||
    !rentRollFieldsByPropertyType[propertyType].fields
  )
    return [];

  const { fields } = rentRollFieldsByPropertyType[propertyType];

  return fields;
}

export function computeDocumentTablesData({
  document,
  exceptions,
  fields,
  rentRoll,
  documentTables = []
}) {
  if (!documentTables || !documentTables.length) return [];

  return documentTables.map(
    ({ headerIndex, pageIndex, pageIndexes, tableIndex, tableIndexes }) => {
      const headers = computeTableHeaders({
        headerIndex,
        pageIndex,
        tableIndex,
        document,
        fields
      });

      const tableData = computeTableData({
        headers,
        rentRoll,
        exceptions,
        pageIndexes,
        tableIndexes,
        fields,
        document,
        headerIndex
      });

      const columnWidths = computeTableColumnWidths({ headers, tableData });

      const columns = computeTableColumns({ columnWidths, headers });

      return { columns, headers, tableData, fields };
    }
  );
}

export function computeTableHeaders({
  headerIndex,
  pageIndex,
  tableIndex,
  document,
  fields
}) {
  if (!document || !fields) return [];

  const metaData = document.meta_data_json.headers[headerIndex];
  if (!metaData) return [];
  if (!metaData.headers || !metaData.headers.length) {
    // If no headers are detected, set the headers to the first row
    metaData.headers = document.raw_data_json.pages[pageIndex].tableData[
      tableIndex
    ][0].map(h => ({
      ...h,
      isMapped: false
    }));
  }

  const advancedRulesHeaders = computeAdvancedRulesHeaders({
    metaData,
    fields,
    headerIndex,
    pageIndex,
    tableIndex
  });

  const {
    tenantHeader,
    mappedAndRequired,
    mappedAndNotRequired,
    notMapped
  } = computeAggregatedHeaderTypes({
    metaData,
    headerIndex,
    pageIndex,
    tableIndex,
    fields,
    advancedRulesHeaders
  });

  const vacancyHeader = advancedRulesHeaders.find(
    header => header.key === VACANCY_KEY
  )
    ? null
    : {
        headerIndex,
        pageIndex,
        tableIndex,
        columnIndex: -1,
        key: VACANCY_KEY,
        text: "Is Vacant"
      };

  const actionsHeader = {
    headerIndex,
    pageIndex,
    tableIndex,
    columnIndex: -1,
    key: ACTIONS_KEY,
    text: "Actions"
  };

  return [
    tenantHeader,
    ...mappedAndRequired,
    ...mappedAndNotRequired,
    ...advancedRulesHeaders,
    vacancyHeader,
    ...notMapped,
    actionsHeader
  ].filter(Boolean);
}

export function computeAdvancedRulesHeaders({
  metaData,
  fields,
  headerIndex,
  pageIndex,
  tableIndex
}) {
  if (!metaData.advancedRules || !metaData.advancedRules.length) return [];
  /** Assume all the possible matches for the fields */
  const genericAdvancedRulesMatches = {
    monthly_rent: ["amount"]
  };

  return metaData.advancedRules.map(({ field: key }) => {
    const returnData = {
      columnIndex: -1,
      headerIndex,
      isMapped: true,
      key,
      pageIndex,
      tableIndex,
      text: computeHeaderCellDisplayText({ key, fields, defaultValue: key })
    };
    const commonHeaders = metaData.headers;
    const genericHeaders = genericAdvancedRulesMatches[key] || [];
    let ruleMatchedColumn;
    if (genericHeaders.length > 0) {
      ruleMatchedColumn = commonHeaders.find(({ key: matchedHeader }) => {
        return genericHeaders.find(value => matchedHeader === value);
      });
      if (ruleMatchedColumn?.columnIndex) {
        returnData.ruleColumnIndex = ruleMatchedColumn?.columnIndex;
      }
    }

    return returnData;
  });
}

export function computeAggregatedHeaderTypes({
  metaData,
  headerIndex,
  pageIndex,
  tableIndex,
  fields,
  advancedRulesHeaders
}) {
  const advancedRuleHeaderKeys = advancedRulesHeaders.reduce((obj, rule) => {
    obj[rule.key] = 1;
    return obj;
  }, {});

  return metaData.headers.reduce(
    (
      acc,
      { id: key, isMapped, required: isRequired, ...props },
      columnIndex
    ) => {
      const header = {
        ...props,
        id: !fields.find(f => f.term === key) ? "" : key,
        key,
        required: !!isRequired,
        columnIndex,
        headerIndex,
        isMapped,
        pageIndex,
        tableIndex,
        text: computeHeaderCellDisplayText({ key, fields, defaultValue: key })
      };

      const isTenantHeader = key === TENANT_KEY;
      const isMappedAndRequired = isMapped && isRequired;
      const isMappedAndNotRequired = isMapped && !isRequired;
      if (advancedRuleHeaderKeys[header.key]) {
        return acc;
      }
      if (acc.processed[header.key]) {
        acc.processed[header.key]++;
        header.key = `${header.key}_${acc.processed[header.key]}`;
      }

      acc.processed[header.key] = acc.processed[header.key] || 0;
      acc.processed[header.key]++;

      if (isTenantHeader) {
        acc.tenantHeader = header;
      } else if (isMappedAndRequired) {
        acc.mappedAndRequired.push(header);
      } else if (isMappedAndNotRequired) {
        acc.mappedAndNotRequired.push(header);
      } else {
        acc.notMapped.push(header);
      }

      return acc;
    },
    {
      tenantHeader: null,
      mappedAndRequired: [],
      mappedAndNotRequired: [],
      notMapped: [],
      processed: {
        [ACTIONS_KEY]: 1,
        [VACANCY_KEY]: 1
      }
    }
  );
}

export function computeHeaderCellDisplayText({ defaultValue, fields, key }) {
  const field = fields.find(field => field.term === key);
  if (!field || !field.displayTerm) return defaultValue;

  return field.displayTerm;
}

export function computeTableData({
  document,
  pageIndexes = [],
  tableIndexes = [],
  headers = [],
  rentRoll = [],
  exceptions = [],
  fields = [],
  headerIndex
}) {
  if (
    !document ||
    !headers.length ||
    !fields.length ||
    !rentRoll ||
    !rentRoll.length
  )
    return [];

  const tableExceptions = filterExceptions({
    exceptions,
    pageIndexes,
    tableIndexes
  });

  return rentRoll
    .filter(({ doc_page_id, doc_table_id }) =>
      pageIndexes.some(
        (index, n) => doc_page_id === index && doc_table_id === tableIndexes[n]
      )
    )
    .map((rowData, rentRollRowIndex) => {
      const pageIndex = rowData.doc_page_id;
      const tableIndex = rowData.doc_table_id;
      let docRowIndex = (rowData.doc_row_ids || [])[0];

      return headers.reduce((acc, headerData, rentRollColumnIndex) => {
        const { key, isMapped, columnIndex: columnIndexOrigin } = headerData;
        let columnIndex = columnIndexOrigin;
        // MANUAL_ADD row fails here because its not in document
        let confidence = {};
        let corrected = {};
        let predef = {};

        if (columnIndexOrigin < 0 && headerData.ruleColumnIndex >= 0) {
          columnIndex = headerData.ruleColumnIndex;
          docRowIndex = (rowData.doc_row_ids || []).slice(-1)[0];
        }

        if (
          document.raw_data_json.pages[pageIndex].tableData[tableIndex][
            docRowIndex
          ]
        ) {
          ({ confidence, corrected, predef } =
            document.raw_data_json.pages[pageIndex].tableData[tableIndex][
              docRowIndex
            ][columnIndex] || {});
        }
        if (
          docRowIndex <
            document.raw_data_json.pages[pageIndex].tableData[tableIndex]
              .length ||
          document.raw_data_json.pages[pageIndex].tableData[tableIndex][
            docRowIndex
          ] === undefined
        ) {
          const { hasWarning, hasError, isCorrected } = computeCellFlags({
            confidence,
            corrected,
            isMapped,
            predef,
            exceptions: tableExceptions,
            location: {
              columnIndex,
              pageIndex,
              rowIndex: docRowIndex,
              tableIndex,
              headerIndex
            }
          });
          let rowDataValue =
            rowData[key] ||
            (rowData.mapped_unnormalized_fields &&
              rowData.mapped_unnormalized_fields[key]);
          if (key === "lease_dates") {
            rowDataValue =
              rowData["lease_start_date"] && rowData["lease_end_date"]
                ? [rowData["lease_start_date"], rowData["lease_end_date"]]
                : null;
          }

          // Force data for select fields in new rows that arent displaying
          // comments, status(rent_status), deposit
          // rowDataValue = null BUT rowData.data_jsonb[key] = "500"
          if (
            rowData?.status === MANUAL_ADD &&
            rowData?.data_jsonb &&
            rowData?.data_jsonb[key] &&
            !rowDataValue
          ) {
            rowDataValue = rowData.data_jsonb[key];
          }

          acc[key] = {
            columnIndex,
            hasWarning,
            hasError,
            headerIndex,
            id: rowData.id,
            documentKey: generateTableKey({
              headerIndex,
              pageIndex,
              tableIndex,
              rowIndex: docRowIndex,
              columnIndex
            }),
            rentRollKey: `${rentRollRowIndex}-${rentRollColumnIndex}`,
            isCorrected,
            isDeleted: rowData.status === DELETED_STATUS,
            status: rowData.status,
            isMapped,
            key,
            pageIndex,
            rowIndex: docRowIndex,
            tableIndex,
            text:
              key !== "lease_dates"
                ? computeDataDisplayText({ key, fields, text: rowDataValue })
                : computeLeaseDates(rowDataValue)
          };

          acc.id = rowData.id;
          acc.rowId = rowData.id;
        }
        return acc;
      }, {});
    });
}

function computeLeaseDates(rowDataValue) {
  if (rowDataValue) {
    const startDate = new Date(rowDataValue[0]);
    const endDate = new Date(rowDataValue[1]);
    return (
      moment.utc(startDate).format("L") + "-" + moment.utc(endDate).format("L")
    );
  } else {
    return "";
  }
}

export function computeDataDisplayText({ fields, key, text }) {
  const field = fields.find(field => field.term === key);

  return field && field.dataType
    ? parseDataType({ [key]: text }, key, field.dataType)
    : parseValue({ [key]: text }, key);
}

export function computeTableColumnWidths({ headers = [], tableData = [] }) {
  if (!headers.length && !tableData.length) return {};
  const FONT_SIZE = 13;
  const PADDING = 24;
  const ACTIONS_WIDTH = 72;
  const EMPTY_TABLE_CELL_WIDTH = 110;
  if (headers.length && !tableData.length) {
    return headers.reduce((acc, { key }, n) => {
      acc[key] = width =>
        width
          ? (width - ACTIONS_WIDTH) / headers.length - 1
          : EMPTY_TABLE_CELL_WIDTH;
      return acc;
    }, {});
  }

  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  ctx.font = ctx.font.replace(/\d+px/, `${FONT_SIZE}px`);

  const computedWidths = headers.map(({ key, text }) =>
    Math.max(
      ...[text]
        .concat(
          tableData.map(row => {
            if (row[key] !== undefined) return row[key].text;
            else return;
          })
        )
        .filter(Boolean)
        .map(text => ctx.measureText(text).width + PADDING)
    )
  );

  const totalComputedWidth = computedWidths.reduce(
    (acc, curr) => acc + curr,
    0
  );

  const greatestComputedWidth = Math.max(...computedWidths);

  return headers.reduce((acc, { key }, n) => {
    acc[key] = width => {
      const evenSpacing = (width - ACTIONS_WIDTH) / headers.length - 1;

      if (key === ACTIONS_KEY) return ACTIONS_WIDTH;
      if (totalComputedWidth < width && evenSpacing > greatestComputedWidth)
        return evenSpacing;

      return computedWidths[n];
    };
    return acc;
  }, {});
}

export function computeTableColumns({ columnWidths = {}, headers = [] }) {
  if (!headers.length || !Object.keys(columnWidths).length) return [];

  return headers.map(({ key, text, isMapped }) => {
    const isFirst = key === TENANT_KEY;
    const isLast = key === ACTIONS_KEY;

    return {
      key,
      dataKey: key,
      text,
      computeWidth: columnWidths[key],
      isMapped,
      align: isFirst ? "left" : isLast ? "center" : "right",
      frozen: isFirst ? "left" : isLast ? "right" : null
    };
  });
}
