import { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Button from "ui/components/shared/Button";

import {
  previousUserPagination,
  nextUserPagination,
  fetchUsers
} from "ui/store/actions/users";

class PaginationNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageTo: props.page === 0 ? 1 : props.page
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.page !== this.props.page) {
      this.setState({ pageTo: this.props.page });
    }
  }

  prevPage = () => {
    const { prevPagination } = this.props;
    prevPagination();
  };

  nextPage = () => {
    const { nextPagination } = this.props;
    nextPagination();
  };

  selectPage = e => {
    const { selectPagination } = this.props;
    selectPagination(e.target.value);
    this.setState({ pageTo: e.target.value });
  };

  render() {
    const { totalPages } = this.props;
    return (
      <div className="PaginationNavigation">
        <Button
          className="PaginationNavigation__Button"
          onClick={this.prevPage}
        >{`<`}</Button>
        <div className="PaginationNavigation__pageNav">
          <input
            className="PaginationNavigation__pageNav__input"
            type="number"
            value={this.state.pageTo}
            onChange={this.selectPage}
            min={1}
            max={this.props.totalPages}
          />
          <div className="PaginationNavigation__pageNav__totalPages">
            {totalPages}
          </div>
        </div>
        <Button
          className="PaginationNavigation__Button"
          onClick={this.nextPage}
        >{`>`}</Button>
      </div>
    );
  }
}

PaginationNavigation.propTypes = {
  totalPages: PropTypes.number,
  page: PropTypes.number,
  prevPagination: PropTypes.func,
  nextPagination: PropTypes.func,
  selectPagination: PropTypes.func
};

function mapStateToProps({ users }) {
  return {
    totalPages: users.totalPages,
    page: users.page
  };
}

function mapDispatchToProps(dispatch) {
  return {
    prevPagination: () => dispatch(previousUserPagination()),
    nextPagination: () => dispatch(nextUserPagination()),
    selectPagination: toPage => dispatch(fetchUsers(toPage))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaginationNavigation);
