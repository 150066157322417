import PropTypes from "prop-types";
import cx from "classnames";

export default function Toggle({ activate, deactivate, isActive, isSelected }) {
  function toggle(event) {
    event.stopPropagation();

    isActive ? deactivate() : activate();
  }

  return (
    <div
      className={cx("SplitPanelV2__Toggle", {
        ["SplitPanelV2__Toggle--on"]: isActive,
        ["SplitPanelV2__Toggle--off"]: !isActive,
        ["SplitPanelV2__Toggle--not-selected"]: !isSelected
      })}
      onClick={toggle}
    >
      <div />
    </div>
  );
}

Toggle.propTypes = {
  activate: PropTypes.func.isRequired,
  deactivate: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired
};
