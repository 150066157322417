import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import { reactLocalStorage } from "reactjs-localstorage";

import {
  markAsComplete,
  fetchRentRollDocsByPropId
} from "ui/store/actions/rentRoll";
import {
  clearCompletedAt,
  updateCompletedAt
} from "ui/store/actions/splitScreen";

import Button from "ui/components/shared/Button";
import PercentComplete from "./PercentComplete";
import WarningMessage from "ui/components/rentRoll/splitPanel/popovers/notification/WarningMessage";

const BottomStatusBar = ({
  totalPctComplete,
  rentRollDocument,
  markAsComplete,
  totalExceptions,
  dateUpdatedAt,
  clearCompletedAt,
  propertyId,
  fetchRentRollDocsByPropId,
  widgetAuth
}) => {
  const [complete, setComplete] = useState(false);
  useEffect(() => {
    if (
      rentRollDocument.status === "COMPLETED" &&
      rentRollDocument.updated_at
    ) {
      updateCompletedAt(rentRollDocument.updated_at);
      setComplete(true);
    }
    return () => {
      clearCompletedAt();
    };
  }, [rentRollDocument]);
  const allExceptions = totalExceptions.total;
  const blockingExceptions = totalExceptions.blocking;

  const documentStatusActions = () => {
    if (complete) {
      return (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <img src={require("ui/images/green-tick.svg")} />
          <div className="SplitPanelSubHeader__completed-on-timestamp">
            {`Completed on ${moment(dateUpdatedAt).format("MMMM DD, YYYY")}`}
          </div>
        </div>
      );
    } else {
      if (rentRollDocument.effective_date && blockingExceptions === 0) {
        return (
          <Button
            className="Button__greenButton"
            onClick={async () => {
              await markAsComplete(rentRollDocument.id, widgetAuth);
              await setComplete(true);
              await fetchRentRollDocsByPropId(
                propertyId,
                widgetAuth && !widgetAuth.authorized ? true : false,
                widgetAuth
              );
              if (widgetAuth && widgetAuth.authorized) {
                const message = JSON.stringify({
                  event_type: "MARKED_AS_COMPLETE",
                  document_id: rentRollDocument.id,
                  property_id: rentRollDocument.property_id
                });
                window.parent.postMessage(message, "*");
              }
              reactLocalStorage.set("documentId", rentRollDocument.id);
            }}
          >
            MARK AS COMPLETE
          </Button>
        );
      } else {
        return <WarningMessage blockingExceptions={blockingExceptions} />;
      }
    }
  };
  return (
    <div className="SplitPanel__bottomStatusBar">
      <div className="SplitPanel__bottomStatusBar__left">
        <PercentComplete totalPctComplete={totalPctComplete} />
      </div>
      <div className="SplitPanel__bottomStatusBar__right">
        {allExceptions > 0 && (
          <div
            style={{ fontSize: "14px", fontWeight: "700", marginRight: "15px" }}
          >{`${allExceptions} items to review`}</div>
        )}
        {documentStatusActions()}
      </div>
    </div>
  );
};

BottomStatusBar.propTypes = {
  propertyId: PropTypes.string,
  totalPctComplete: PropTypes.number,
  totalExceptions: PropTypes.object,
  markAsComplete: PropTypes.func,
  rentRollDocument: PropTypes.object,
  dateUpdatedAt: PropTypes.string,
  clearCompletedAt: PropTypes.func,
  fetchRentRollDocsByPropId: PropTypes.func,
  widgetAuth: PropTypes.object
};

const mapStateToProps = ({ rentRoll, splitScreen }) => ({
  rentRollDocument: rentRoll.document,
  dateUpdatedAt: splitScreen.dateUpdatedAt
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      markAsComplete,
      clearCompletedAt,
      updateCompletedAt,
      fetchRentRollDocsByPropId
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(BottomStatusBar);
