import PropTypes from "prop-types";

import {
  ENTITY_BADGE_BVD,
  ENTITY_BADGE_EXISTING,
  ENTITY_BADGE_USER_DEFINED,
  ENTITY_BADGE_CORTERA,
  ENTITY_BADGE_SAFEGRAPH
} from "./helpers/util";

const entityBadgeConfig = {
  [ENTITY_BADGE_EXISTING]: {
    class: "EntityResolution__greyBadge",
    text: "Existing"
  },
  [ENTITY_BADGE_BVD]: {
    class: "EntityResolution__clearBadge",
    text: "BvD"
  },
  [ENTITY_BADGE_USER_DEFINED]: {
    class: "EntityResolution__clearBadge",
    text: "User-defined"
  },
  [ENTITY_BADGE_CORTERA]: {
    class: "EntityResolution__clearBadge",
    text: "Cortera"
  },
  [ENTITY_BADGE_SAFEGRAPH]: {
    class: "EntityResolution__clearBadge",
    text: "Safegraph"
  }
};

const selectEntityBadgeText = (s, type) => {
  if (type) {
    switch (type) {
      case ENTITY_BADGE_BVD:
        return entityBadgeConfig[ENTITY_BADGE_BVD].text;
      case ENTITY_BADGE_EXISTING:
        return entityBadgeConfig[ENTITY_BADGE_EXISTING].text;
      case ENTITY_BADGE_USER_DEFINED:
        return entityBadgeConfig[ENTITY_BADGE_USER_DEFINED].text;
      case ENTITY_BADGE_CORTERA:
        return entityBadgeConfig[ENTITY_BADGE_CORTERA].text;
      case ENTITY_BADGE_SAFEGRAPH:
        return entityBadgeConfig[ENTITY_BADGE_SAFEGRAPH].text;
      default:
        return;
    }
  } else {
    if (s.id && s.ref && (s.ref.bvdid || s.ref.cortera || s.ref.safegraph)) {
      return entityBadgeConfig[ENTITY_BADGE_EXISTING].text;
    } else if (s.ref && s.ref.bvdid) {
      return entityBadgeConfig[ENTITY_BADGE_BVD].text;
    } else if (s.ref && s.ref.cortera) {
      return entityBadgeConfig[ENTITY_BADGE_CORTERA].text;
    } else if (s.ref && s.ref.safegraph) {
      return entityBadgeConfig[ENTITY_BADGE_SAFEGRAPH].text;
    } else {
      return entityBadgeConfig[ENTITY_BADGE_USER_DEFINED].text;
    }
  }
};

const selectEntityBadgeClass = (s, type) => {
  if (type) {
    switch (type) {
      case ENTITY_BADGE_BVD:
        return entityBadgeConfig[ENTITY_BADGE_EXISTING].class;
      case ENTITY_BADGE_CORTERA:
        return entityBadgeConfig[ENTITY_BADGE_EXISTING].class;
      case ENTITY_BADGE_SAFEGRAPH:
        return entityBadgeConfig[ENTITY_BADGE_EXISTING].class;
      case ENTITY_BADGE_EXISTING:
        return entityBadgeConfig[ENTITY_BADGE_EXISTING].class;
      case ENTITY_BADGE_USER_DEFINED:
        return entityBadgeConfig[ENTITY_BADGE_BVD].class;
      default:
        return;
    }
  } else {
    return s.id && s.ref && (s.ref.bvdid || s.ref.cortera || s.ref.safegraph)
      ? entityBadgeConfig[ENTITY_BADGE_EXISTING].class
      : entityBadgeConfig[ENTITY_BADGE_BVD].class;
  }
};
const EntityBadge = ({ s, type }) => (
  <span className={selectEntityBadgeClass(s, type)}>
    {selectEntityBadgeText(s, type)}
  </span>
);

EntityBadge.propTypes = {
  s: PropTypes.object,
  type: PropTypes.string
};

export default EntityBadge;
