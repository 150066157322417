import { useEffect, useState } from "react";
import PropTypes from "prop-types";

export default function SearchPagination({
  pageIdx,
  totalPages,
  updatePagination,
  offOne = false
}) {
  const [page, setPage] = useState();
  useEffect(() => {
    if (offOne) {
      const newSetPage = pageIdx + 1;
      setPage(newSetPage);
    }
  });
  const incPaginationShow =
    (offOne && page < totalPages) || (!offOne && pageIdx < totalPages);
  const dscPaginationShow = (offOne && page > 1) || (!offOne && pageIdx > 1);
  return (
    <div className="FilterBody__paginationControl">
      {dscPaginationShow && (
        <img
          src={require("ui/images/icon-chevron-right.svg")}
          style={{
            transform: "rotate(180deg)",
            cursor: "pointer"
          }}
          onClick={async () => {
            await updatePagination(pageIdx - 1);
          }}
        />
      )}
      {totalPages > 1 && `Page ${offOne ? page : pageIdx} of ${totalPages}`}
      {incPaginationShow && (
        <img
          src={require("ui/images/icon-chevron-right.svg")}
          style={{ cursor: "pointer" }}
          onClick={async () => {
            await updatePagination(pageIdx + 1);
          }}
        />
      )}
    </div>
  );
}

SearchPagination.propTypes = {
  pageIdx: PropTypes.number,
  totalPages: PropTypes.number,
  updatePagination: PropTypes.func,
  offOne: PropTypes.bool
};

SearchPagination.defaultProps = {};
