import PropTypes from "prop-types";
import AccountMenu from "ui/components/account/AccountMenu";
import NavLink from "ui/components/shared/NavLink.jsx";
import { connect } from "react-redux";

import UploadPopover from "ui/components/main/upload/UploadPopover";
import DocumentsPopover from "ui/components/main/documents/DocumentsPopover";
import Flash from "ui/components/notification/Flash";
import Subheader from "./Subheader";

const HeaderWithSidebar = ({
  className,
  subHeader,
  button,
  children,
  includeSubHeader = true
}) => (
  <div className="HeaderWithSidebar">
    <div className="HeaderWithSidebar__header">
      <NavLink to={{ pathname: "/manage-files-view" }}>
        <img
          className="HeaderWithSidebar__headerMainLogo"
          src={require("ui/images/quiq-property-logo-white-256px.svg")}
          alt="logo"
        />
      </NavLink>
      <div className="HeaderWithSidebar__upload-popover">
        <DocumentsPopover />
        <UploadPopover />
      </div>
      <div className="HeaderWithSidebar__header-account-details">
        <div className="HeaderWithSidebar__header-account-details-user">
          <AccountMenu className="MainHeader__menu" inAdminView={false} />
        </div>
      </div>
    </div>
    <Flash />
    {includeSubHeader ? (
      <Subheader className={className} subHeader={subHeader} button={button}>
        {children}
      </Subheader>
    ) : (
      <div>{children}</div>
    )}
  </div>
);

HeaderWithSidebar.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  subHeader: PropTypes.any,
  button: PropTypes.any,
  includeSubHeader: PropTypes.bool
};

function mapStateToProps(state) {
  return {
    sidebarTabSelected: state.headerSidebar.sidebarTabSelected,
    exceptionSidebarIsActive: state.rentRoll.exceptionSidebarIsActive
  };
}

export default connect(mapStateToProps)(HeaderWithSidebar);
