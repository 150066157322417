import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import SourceColumns from "./components/SourceColumns/SourceColumns";
import { getPageTableData } from "ui/components/rentRoll/splitPanelV2/hooks/usePageData";
import useRentRollTableData, {
  ACTIONS_KEY,
  VACANCY_KEY
} from "ui/components/rentRoll/splitPanelV2/hooks/useRentRollTableData";
import { isBrokenTable } from "ui/components/rentRoll/splitPanelV2/helpers/tableKeys";

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export default function HeaderStep({
  document,
  currentPageIndex,
  setRequiredFields,
  activeDocumentTables,
  documentMetadata,
  onSelected,
  setHeaderData,
  documentTables,
  currentHeaderIndex,
  refreshData,
  setDocumentMetadata,
  rentRollDocument,
  rentRoll,
  setAdvancedRulesOpen
}) {
  if (documentTables.length === 0) return null;

  const [allTableHeaders, setAllTableHeaders] = useState({
    mapped: [],
    unmapped: []
  });
  const prevDocumentTables = usePrevious(documentTables);
  const [messageStatus, setMessageStatus] = useState("noRequiredFields");
  const documentHeaderData = getPageTableData(currentPageIndex);
  const tableIndex = documentTables[currentHeaderIndex].tableIndex || 0;
  const headerRows =
    (documentMetadata &&
      documentMetadata[currentHeaderIndex] &&
      documentMetadata[currentHeaderIndex].rowIds) ||
    [];
  const headerRowIndex =
    (documentMetadata &&
      documentMetadata[currentHeaderIndex] &&
      documentMetadata[currentHeaderIndex].rowIndex) ||
    0;
  const rentRollTableData = useRentRollTableData();

  const messageOptions = {
    tableContinuation:
      "Table continues, columns are already mapped, please verify.",
    noRequiredFields: "We did most of the work for you, please verify.",
    tableNotActive: "This table is currently not active."
  };

  const fields = [
    {
      displayTerm: "",
      required: false,
      status: "show",
      term: ""
    },
    ...rentRollTableData[currentHeaderIndex].fields.filter(
      f => f.status === "show"
    )
  ];
  const deleteHeader = headerColumnIndex => {
    if (rentRollTableData.length) {
      const headerIndex = allTableHeaders.mapped.findIndex(
        h => h.columnIndex === headerColumnIndex
      );
      const header = allTableHeaders.mapped.splice(headerIndex, 1)[0];
      header.isMapped = false;
      allTableHeaders.unmapped.unshift(header);
      documentMetadata[currentHeaderIndex].headers[
        headerColumnIndex
      ].isMapped = false;
      setAllTableHeaders({ ...allTableHeaders });
      setDocumentMetadata([...documentMetadata]);
    }
  };

  const addHeader = headerColumnIndex => {
    if (rentRollTableData.length) {
      const headerIndex = allTableHeaders.unmapped.findIndex(
        h => h.columnIndex === headerColumnIndex
      );
      const header = allTableHeaders.unmapped.splice(headerIndex, 1)[0];
      // set id="" if the id is set to an invalid option
      if (!fields.find(f => f.term === header.id)) {
        header.id = "";
      }
      header.isMapped = true;
      allTableHeaders.mapped.push(header);
      if (header.id !== "") {
        documentMetadata[currentHeaderIndex].headers[
          headerColumnIndex
        ].isMapped = true;
        setDocumentMetadata([...documentMetadata]);
      }
      setAllTableHeaders({ ...allTableHeaders });
    }
  };
  const setTableHeaderList = () => {
    // This function transforms the headers into the expected format for the api request
    //   {
    //     "id": "monthly_rent_per_sqft",
    //     "headerNames": [
    //         "Unit Type" <-- concatenation of selected header rows
    //     ],
    //     "simScore": 0.9,
    //     "isMapped": true,
    //     "skipRowForValue": [],
    //     "columnIndex": 0, <-- this is for sorting before sending the payload
    //     "key": "key" <-- original key, as the id is realted to mapping value
    // }[]
    const headers = { mapped: [], unmapped: [] };
    const _documentHeaderData = showHeaders(documentHeaderData);
    rentRollTableData[currentHeaderIndex].headers.forEach(
      ({ columnIndex, ...header }) => {
        if ([ACTIONS_KEY, VACANCY_KEY].includes(header.key)) {
          return;
        }
        const headerNames = _documentHeaderData
          .map(d => (d[columnIndex] ? d[columnIndex].text.trim() : ""))
          .join(" ")
          .trim();
        const formattedHeader = {
          ...header,
          columnIndex
        };
        if (headerNames) {
          formattedHeader.headerNames = [headerNames];
        }
        if (formattedHeader.isMapped) {
          headers.mapped.push(formattedHeader);
        } else {
          headers.unmapped.push(formattedHeader);
        }
      }
    );
    setAllTableHeaders(headers);
  };

  useEffect(() => {
    setAllTableHeaders({
      mapped: [],
      unmapped: []
    });
  }, [currentHeaderIndex]);

  useEffect(() => {
    setTableHeaderList();
  }, [
    currentHeaderIndex,
    activeDocumentTables,
    JSON.stringify(headerRows),
    JSON.stringify(rentRollTableData)
  ]);

  useEffect(() => {
    if (JSON.stringify(documentTables) !== JSON.stringify(prevDocumentTables)) {
      setTableHeaderList();
    }
  }, [documentTables]);

  useEffect(() => {
    if (isBrokenTable(headerRowIndex)) {
      if (activeDocumentTables && !activeDocumentTables[currentHeaderIndex]) {
        setMessageStatus("tableNotActive");
      } else {
        setMessageStatus("tableContinuation");
      }
    } else if (
      activeDocumentTables &&
      documentTables &&
      documentTables[currentPageIndex]
    ) {
      if (!activeDocumentTables[currentHeaderIndex]) {
        setMessageStatus("tableNotActive");
      } else {
        setMessageStatus("noRequiredFields");
      }
    } else {
      setMessageStatus("noRequiredFields");
    }
  }, [activeDocumentTables, currentPageIndex, documentTables, headerRowIndex]);

  const showHeaders = headers => {
    if (headers.tableData.length) {
      if (headerRows.length && headers) {
        // check for multiple row headers
        if (headerRows.length > 1) {
          return headers.tableData[tableIndex].slice(
            headerRows[0],
            headerRows[headerRows.length - 1] + 1
          );
        } else {
          return [headers.tableData[tableIndex][headerRows[0]]];
        }
      } else {
        return headers.tableData[tableIndex][0];
      }
    }
    return [];
  };

  return (
    <div className="SplitPanelV2__RentRoll-HeaderStep">
      <div className="SplitPanelV2__RentRoll-HeaderStep--message">
        <p>{messageOptions[messageStatus]}</p>
      </div>
      <div className="SplitPanelV2__RentRoll-HeaderStep-content">
        <div>
          {activeDocumentTables[currentHeaderIndex] && (
            <SourceColumns
              setAdvancedRulesOpen={setAdvancedRulesOpen}
              refreshData={refreshData}
              setRequiredFields={setRequiredFields}
              setHeaderData={setHeaderData}
              currentHeaderIndex={currentHeaderIndex}
              tableIndex={tableIndex}
              activeDocumentTables={activeDocumentTables}
              headerSelectOptions={fields}
              headerRows={headerRows}
              tableData={
                document.raw_data_json.pages[currentPageIndex].tableData[
                  tableIndex
                ]
              }
              rentRoll={rentRoll}
              documentTables={documentTables}
              headerData={allTableHeaders}
              onSelected={onSelected}
              deleteHeader={deleteHeader}
              addHeader={addHeader}
              currentPageIndex={currentPageIndex}
              rentRollDocument={rentRollDocument}
              documentMetadata={documentMetadata}
              setDocumentMetadata={setDocumentMetadata}
              fields={fields}
            />
          )}
        </div>
      </div>
    </div>
  );
}

HeaderStep.propTypes = {
  setDocumentMetadata: PropTypes.func.isRequired,
  setRequiredFields: PropTypes.func.isRequired,
  rentRoll: PropTypes.array,
  document: PropTypes.object,
  activeDocumentTables: PropTypes.array,
  currentPageIndex: PropTypes.number,
  onSelected: PropTypes.func.isRequired,
  selected: PropTypes.string,
  currentHeaderIndex: PropTypes.number,
  setHeaderData: PropTypes.func.isRequired,
  documentTables: PropTypes.array.isRequired,
  documentMetadata: PropTypes.array,
  rentRollDocument: PropTypes.object,
  refreshData: PropTypes.func,
  setAdvancedRulesOpen: PropTypes.func
};
