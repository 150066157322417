const DEFAULT_TEMPLATE_VALUES = {
  name: "",
  description: "",
  property_types: [],
  status: "UNPUBLISHED",
  rows: [
    {
      id: 1,
      name: "Income",
      code: "1000",
      type: "category",
      order: 0,
      is_credit: true,
      name_idx: "income"
    },
    {
      id: 2,
      name: "Operating Expenses",
      code: "2000",
      type: "category",
      order: 1,
      is_credit: false,
      name_idx: "operating-expenses"
    },
    {
      id: 3,
      name: "Non-Operating",
      code: "3000",
      type: "category",
      order: 2,
      is_credit: false,
      name_idx: "non-operating"
    },
    {
      id: 4,
      name: "Capital Expenditures",
      code: "4000",
      type: "category",
      order: 3,
      is_credit: false,
      name_idx: "capital-expenditures"
    },
    {
      id: 5,
      name: "Debt Servicing",
      code: "5000",
      type: "category",
      order: 4,
      is_credit: false,
      name_idx: "debt-servicing"
    }
  ]
};

export default DEFAULT_TEMPLATE_VALUES;
