import { Component } from "react";
import PropTypes from "prop-types";
import SelectFile from "./SelectFile";
// import ThreeBounceSpinner from "ui/components/spinners/ThreeBounceSpinner";
import DragAndDrop from "./DragAndDrop";
import { isEmpty } from "ramda";
import axios from "axios";
import Cookies from "js-cookie";
import {
  fetchRentRollDocsByPropId,
  fetchUploadingDocuments
} from "ui/store/actions/rentRoll";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Button from "ui/components/shared/Button.jsx";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { PERIOD_DATE_FORMAT } from "ui/components/helpers/dayjs";
import { documentType } from "ui/components/helpers/documents";

import { setIntervalRunning } from "ui/store/actions/statusBar";
import { hasSuperAdminAccess } from "helpers/authorization";

class FileUploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: "",
      imagePreviewUrl: "",
      documentType: documentType.RENT_ROLL
    };
  }

  componentDidUpdate(nextProps) {
    if (
      !this.state.imagePreviewURL &&
      nextProps.documents[0] &&
      nextProps.documents[0].imageUrls &&
      nextProps.documents[0].imageUrls[0]
    ) {
      this.setState({
        imagePreviewURL: nextProps.documents[0].imageUrls[0]
      });
    }
  }

  submitSelectedFile = e => {
    this.submitFile(e, this.state.file, this.state.documentType);
  };

  submitFile = (event, file, docType) => {
    const {
      getUploadingDocuments,
      changeIntervalRunning,
      match: {
        params: { propertyId }
      }
    } = this.props;

    const urlPath = docType === documentType.RENT_ROLL ? "rent-roll" : "opex";

    const jwt = Cookies.get("quiq-property-jwt");
    const formData = new FormData();
    const effectiveDate = this.state.effective_date;

    if (effectiveDate) {
      effectiveDate.utc().set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      });
      formData.append("effective_date", effectiveDate.toISOString());
    }
    formData.append("file", file);

    axios
      .post(`/api/${urlPath}/upload-document/${propertyId}`, formData, {
        headers: {
          Authorization: `Bearer ${jwt}`,
          "Content-Type": "multipart/form-data"
        }
      })
      .then(() => {
        this.props.getRentRollDocsByPropId(propertyId);
        getUploadingDocuments();
        changeIntervalRunning(true);
      });
  };

  saveDroppedFile = file => {
    this.setState({
      file: file
    });
  };

  handleImageChange = e => {
    e.preventDefault();
    let reader = new FileReader();
    const { files } = e.target;
    let file = files[0];
    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result
      });
    };
    reader.readAsDataURL(file);
  };

  clearFile = () => {
    this.setState({ file: "" });
  };

  generateImagePreview = () => {
    let { imagePreviewUrl } = this.state;
    // TODO ENABLE POST MERGE DEVELOP
    const stringURL = imagePreviewUrl.toString();

    if (imagePreviewUrl) {
      return stringURL.includes("image/png") ? (
        <img className="FileUploader__image-preview" src={imagePreviewUrl} />
      ) : null;
    }
  };

  confirmSubmit = e => {
    e.preventDefault();
    this.props.beginUploadingFile();
    this.submitSelectedFile(e);
    this.clearFile();
    this.props.OnUploadClose && this.props.OnUploadClose();
  };

  setDocumentType = e => {
    this.setState({
      documentType: e.target.value
    });
  };

  render() {
    const { isUploading, isProcessing, currentUser } = this.props;
    return (
      <div className="FileUploader">
        <DragAndDrop
          saveDroppedFile={this.saveDroppedFile}
          beginUploadingFile={this.props.beginUploadingFile}
        >
          {isEmpty(this.state.file) ? (
            <SelectFile
              handleImageChange={this.handleImageChange}
              disableButton={isUploading}
            />
          ) : (
            <div className="FileUploader__feedback">
              <div className="FileUploader__preview-container">
                {this.generateImagePreview()}
                <div className="FileUploader__file-names-preview">
                  {this.state.file.name}
                </div>
              </div>
              <span
                className="FileUploader__clear-button"
                onClick={this.clearFile}
              >
                Clear
              </span>
              <div
                style={{
                  marginTop: "1rem",
                  textAlign: "center",
                  fontSize: "13px"
                }}
                onChange={this.setDocumentType}
              >
                <div>Select Document Type:</div>
                <label style={{ fontSize: "13px" }}>
                  Rent Roll
                  <input
                    type="radio"
                    value={documentType.RENT_ROLL}
                    name="docType"
                    checked={this.state.documentType === documentType.RENT_ROLL}
                  />
                </label>
                <label style={{ fontSize: "13px" }}>
                  Operating Statement
                  <input
                    type="radio"
                    value={documentType.OPERATING_STATEMENT}
                    name="docType"
                    checked={
                      this.state.documentType ===
                      documentType.OPERATING_STATEMENT
                    }
                    disabled={!hasSuperAdminAccess(currentUser.role)}
                  />
                </label>
              </div>
            </div>
          )}
        </DragAndDrop>
        <br />
        <br />

        {/* <div className="FileUploader__spinner">
          {(isUploading || isProcessing) && (
            <ThreeBounceSpinner type={isProcessing ? "light-grey" : ""} />
          )}
        </div> */}

        <div className="FileUploader__effective-date-upload-section">
          {this.state.documentType === "RENT_ROLL" && (
            <>
              <label>Effective Date</label>
              <DatePicker
                defaultValue={this.state.effective_date}
                format={PERIOD_DATE_FORMAT}
                onChange={date =>
                  this.setState({
                    effective_date: date
                  })
                }
                disabledDate={date => date.isAfter(dayjs().add(0, "day"))}
              />
            </>
          )}

          <Button
            className="FileUploader__confirm-upload-button"
            type="submit"
            onClick={e => this.confirmSubmit(e)}
            style={{ marginLeft: "5px" }}
          >
            Upload
          </Button>
        </div>
      </div>
    );
  }
}

FileUploader.propTypes = {
  match: PropTypes.object,
  currentUser: PropTypes.object,
  beginUploadingFile: PropTypes.func,
  isUploading: PropTypes.bool,
  isProcessing: PropTypes.bool,
  getRentRollDocsByPropId: PropTypes.func,
  submitFileByPropId: PropTypes.func,
  documents: PropTypes.array,
  OnUploadClose: PropTypes.func,
  getUploadingDocuments: PropTypes.func,
  changeIntervalRunning: PropTypes.func
};

function mapStateToProps(state) {
  return {
    documents: state.rentRoll.documents
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getRentRollDocsByPropId: function(propertyId) {
      dispatch(fetchRentRollDocsByPropId(propertyId));
    },
    getUploadingDocuments: () => dispatch(fetchUploadingDocuments()),
    changeIntervalRunning: bool => dispatch(setIntervalRunning(bool))
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FileUploader)
);
