// data returned from get-opex
import _ from "lodash";
import OpexDocument from "./modelOpexDocument";

import {
  isCellCorrected,
  isConfidenceNeedsReview,
  isConfidenceUnmapped,
  PERIOD_STATUS_IGNORED
} from "ui/components/opex/shared";

function getItemsByType(startNode, type) {
  if (!startNode) return [];

  let result = [];
  _.each(startNode, item => {
    if (item.type === type) {
      result.push(item);
    }
    result = result.concat(getItemsByType(item.children, type));
  });

  return result;
}
class OpexData extends OpexDocument {
  constructor({ document, opex }) {
    super(document);
    this.opex = opex;
  }

  getItemsByType(startNode, type) {
    if (!startNode) return [];

    let result = [];
    _.each(startNode, item => {
      if (item.type === type) {
        result.push(item);
      }
      result = result.concat(getItemsByType(item.children, type));
    });

    return result;
  }

  getPeriods() {
    return _.get(this.opex, "periods");
  }

  getGroups() {
    return _.get(this.opex, "tableGroups");
  }

  getPeriodsByGroupIndex(groupIndex) {
    const periods = this.getPeriods();
    const periodsByGroupIndex = _.filter(periods, { groupIndex });
    return periodsByGroupIndex;
  }

  getDataByGroupIndex(groupIndex) {
    const groups = this.getGroups();
    return _.find(groups, { groupIndex }); // note this is `find` and not `filter`
  }

  isPeriodIgnored(periodId) {
    const periods = this.getPeriods();
    const period = _.find(periods, { id: periodId });
    const { periodStatus } = period;
    return periodStatus === PERIOD_STATUS_IGNORED;
  }

  getActivePeriods() {
    const periods = this.getPeriods();
    return (
      periods?.filter(
        period => period.periodStatus !== PERIOD_STATUS_IGNORED
      ) || []
    );
  }

  getDataRowsByGroupIndex(groupIndex) {
    const group = this.getDataByGroupIndex(groupIndex);
    const { tableData } = group;
    const accounts = getItemsByType(tableData, "account");
    return accounts;
  }

  getDocumentCellByOpexValue(value) {
    const { location } = value;
    const { pi, ti, ci, ri } = location;
    const valuesInRawDataJson = this.getRawDataJsonCellByPageIndexTableIndexRowIndexColumnIndex(
      pi,
      ti,
      ri,
      ci
    );
    return valuesInRawDataJson;
  }

  countCorrectedValuesByGroupIndex(groupIndex, corrected) {
    const accounts = this.getDataRowsByGroupIndex(groupIndex);
    const count = accounts.reduce(
      (accum, account) =>
        account.values.filter(value => {
          const valuesInRawDataJson = this.getDocumentCellByOpexValue(value);
          return valuesInRawDataJson.corrected === corrected;
        }).length + accum,
      0
    );
    return count;
  }

  countConfirmedCellsByGroupIndex(groupIndex) {
    return this.countCorrectedValuesByGroupIndex(groupIndex, false);
  }

  countCorrectedCellsByGroupIndex(groupIndex) {
    return this.countCorrectedValuesByGroupIndex(groupIndex, true);
  }

  countNeedsReviewCellsByGroupIndex(groupIndex) {
    let count = 0;
    const accounts = this.getDataRowsByGroupIndex(groupIndex);
    _.each(accounts, item => {
      _.each(item.values, value => {
        const valuesInRawDataJson = this.getDocumentCellByOpexValue(value);
        const confidence = valuesInRawDataJson.confidence;
        if (isConfidenceNeedsReview(confidence)) {
          count = count + 1;
        }
      });
    });
    return count;
  }

  countUnmappedCellsByGroupIndex(groupIndex) {
    let count = 0;
    const accounts = this.getDataRowsByGroupIndex(groupIndex);
    _.each(accounts, item => {
      _.each(item.values, value => {
        const valuesInRawDataJson = this.getDocumentCellByOpexValue(value);
        const confidence = valuesInRawDataJson.confidence;
        if (isConfidenceUnmapped(confidence)) {
          count = count + 1;
        }
      });
    });
    return count;
  }

  hasGroups() {
    return _.size(this.getGroups()) > 0;
  }

  hasAllActivePeriodsSet() {
    const activePeriods = this.getActivePeriods();
    const requiredKeys = [
      "startDate",
      "endDate",
      "methodPrepared",
      "statementType"
    ];

    return activePeriods.every(period =>
      requiredKeys.every(key => !!period[key])
    );
  }
}

export default OpexData;
