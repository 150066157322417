import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import FORM_STATES from "ui/components/rentRoll/splitPanelV2/helpers/formStates";

export default function ExceptionSubsection({
  title,
  exceptionType,
  exceptions,
  countColor,
  handleScrollToCell,
  expandErrors,
  collapseErrorList,
  formState
}) {
  const [isVisible, setIsVisible] = useState(expandErrors);
  useEffect(() => {
    if (expandErrors) setIsVisible(expandErrors);
  }, [expandErrors]);
  return (
    <>
      <div
        className="SplitPanelV2__ExceptionsSubsection__subHeader"
        onClick={() => {
          if (exceptions) {
            setIsVisible(!isVisible);
          }
          if (exceptionType === "blocking") {
            collapseErrorList();
          }
        }}
      >
        {`${title} `}
        <span
          className={cx(
            "SplitPanelV2__ExceptionsSubsection__subHeader__count",
            "SplitPanelV2__ExceptionsSubsection__" +
              (countColor ? countColor : "red")
          )}
        >{` (${exceptions ? exceptions.length : 0}) `}</span>
        <span className="SplitPanelV2__ExceptionsSubsection__subHeader__plusMinus">{` ${
          isVisible ? "-" : "+"
        }`}</span>
      </div>
      {isVisible && exceptions && (
        <div>
          {exceptions.map(exception => {
            const { pi, ti } = exception.location.start
              ? exception.location.start
              : exception.location;
            return (
              <div
                key={exception.id}
                className="SplitPanelV2__ExceptionsSubsection__exception"
              >
                {formState === FORM_STATES.HEADERS ? (
                  <div className="SplitPanelV2__ExceptionsSubsection__exception__header">
                    {exception.label}{" "}
                    <span className="SplitPanelV2__ExceptionsSubsection__exception__header__sub">
                      (Page {pi + 1}, Table {ti + 1})
                    </span>
                  </div>
                ) : (
                  <div
                    className="SplitPanelV2__ExceptionsSubsection__exception__headerLink"
                    onClick={() => {
                      if (!exception.documentKey || !exception.rentRollKey)
                        return;
                      handleScrollToCell(
                        "exception",
                        exception.documentKey,
                        exception.rentRollKey
                      );
                    }}
                  >
                    {exception.label}
                  </div>
                )}
                <div className="SplitPanelV2__ExceptionsSubsection__exception__body">
                  {exception.description}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}

ExceptionSubsection.propTypes = {
  title: PropTypes.string,
  exceptionType: PropTypes.string,
  exceptions: PropTypes.array,
  countColor: PropTypes.string,
  handleScrollToCell: PropTypes.func,
  expandErrors: PropTypes.bool,
  collapseErrorList: PropTypes.func,
  formState: PropTypes.string.isRequired
};
