import PropTypes from "prop-types";
import cx from "classnames";

export default function DropDown({
  className,
  values,
  defaultValue,
  disabled,
  ...otherProps
}) {
  const currentValue = defaultValue !== "" ? defaultValue : "";
  return (
    <div className={cx("drop-down")}>
      <select
        className={className}
        name={className}
        id={className}
        value={currentValue}
        {...otherProps}
        required
        disabled={disabled}
      >
        <option className="select-items" value="" disabled>
          select an option
        </option>
        {values &&
          values.map((item, index) => {
            return (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            );
          })}
      </select>
    </div>
  );
}

DropDown.propTypes = {
  values: PropTypes.array,
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool
};
