import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import Terms from "ui/components/terms/Terms";
import NotAuthorized from "ui/components/routing/NotAuthorized";
import { statusActive, companyTermActive } from "helpers/auth";

const AuthenticatedRoute = ({
  component: Component,
  authenticated,
  currentUser,
  ...rest
}) => {
  if (
    authenticated &&
    currentUser.termsAcceptedAt &&
    statusActive(currentUser.status) &&
    companyTermActive(currentUser.company)
  ) {
    if (currentUser.widgetAuth && !currentUser.widgetAuth.authorized) {
      return <NotAuthorized />;
    } else {
      return <Component {...rest} />;
    }
  } else if (
    authenticated &&
    statusActive(currentUser.status) &&
    companyTermActive(currentUser.company)
  ) {
    return <Terms />;
  } else {
    return <Redirect to="/login" />;
  }
};

AuthenticatedRoute.propTypes = {
  component: PropTypes.func.isRequired,
  authenticated: PropTypes.bool.isRequired,
  currentUser: PropTypes.any,
  termsAcceptedAt: PropTypes.number,
  company: PropTypes.any,
  status: PropTypes.string
};

function mapStateToProps({ authenticated, currentUser }) {
  return { authenticated, currentUser };
}

const ConnectedAuthenticateRoute = connect(mapStateToProps)(AuthenticatedRoute);

export default function requiresAuthentication(component) {
  return function _requiresAuthentication(props) {
    return <ConnectedAuthenticateRoute {...props} component={component} />;
  };
}
