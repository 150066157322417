import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { getPredefsResolved } from "./helpers/util";
import { organizeHeaderData } from "../../../helpers/processing";
import { fetchPredefsByIds, predefsByIdsListSize } from "./helpers/api";
import { hasAdminAccess } from "helpers/authorization";

import RadioLabel from "./RadioLabel";

const Resolved = ({
  rentRollDocument,
  setFieldParams,
  changeView,
  widgetAuth,
  userRole
}) => {
  const ref = useRef();
  const [predefs, setPredefs] = useState([]);
  const [pageIdx, setPageIdx] = useState(0);

  useEffect(() => {
    (async () => {
      const headerData = organizeHeaderData(rentRollDocument);
      const predefsResolved = getPredefsResolved(rentRollDocument, headerData);
      const predefIds = predefsResolved.map(cell => cell.predef.id);
      const predefsByIds = await fetchPredefsByIds(predefIds, 0, widgetAuth);
      const mergedData = predefsResolved.map((cell, idx) => ({
        ...cell,
        predefData: predefsByIds[idx]
      }));
      setPredefs(mergedData);
      setPageIdx(0);
    })();
  }, [rentRollDocument]);

  const handleScroll = e => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      const headerData = organizeHeaderData(rentRollDocument);
      const predefsResolved = getPredefsResolved(rentRollDocument, headerData);
      const predefIds = predefsResolved.map(cell => cell.predef.id);
      const endIdx = Math.round(predefIds.length / predefsByIdsListSize);
      const nextPageIdx = pageIdx + 1;
      if (pageIdx < endIdx) {
        (async () => {
          const predefsByIds = await fetchPredefsByIds(
            predefIds,
            nextPageIdx,
            widgetAuth
          );
          const mergedData = predefsResolved.map((cell, idx) => {
            if (idx >= nextPageIdx * predefsByIdsListSize) {
              return {
                ...cell,
                predefData:
                  predefsByIds[idx - nextPageIdx * predefsByIdsListSize]
              };
            } else {
              return { ...predefs[idx] };
            }
          });
          setPredefs([...mergedData]);
          setPageIdx(nextPageIdx);
        })();
      }
    }
  };

  return (
    <div className="EntityResolution__container">
      <div
        ref={ref}
        className="EntityResolution__listContainer"
        onScroll={handleScroll}
      >
        {predefs && predefs.length > 0 ? (
          predefs.map(cell => (
            <div key={cell.text} style={{ marginBottom: "20px" }}>
              <div className="EntityResolution__initialValue">{cell.text}</div>
              {cell && cell.predefData == null && (
                <div className="EntityResolution__issue">
                  {cell.predef.value}
                </div>
              )}
              {cell && cell.predefData != null && (
                <RadioLabel
                  selectedField={cell.predef.value}
                  predef={cell.predefData}
                  entityBadgeData={cell.predef}
                  noIndent={true}
                />
              )}
              <div
                className="EntityResolution__searchLink"
                onClick={() => {
                  setFieldParams(cell.text, cell.predef.field, cell.location);
                  changeView("search-entity-view", 1);
                }}
              >
                Search {hasAdminAccess(userRole) ? "or Create New" : ""}
              </div>
            </div>
          ))
        ) : (
          <div style={{ marginTop: "20px" }}>
            No issues have been resolved yet.
          </div>
        )}
      </div>
    </div>
  );
};

Resolved.defaultProps = {
  widgetAuth: {}
};

Resolved.propTypes = {
  rentRollDocument: PropTypes.object,
  headerData: PropTypes.array,
  changeView: PropTypes.func,
  setFieldParams: PropTypes.func,
  widgetAuth: PropTypes.object,
  userRole: PropTypes.string
};

const mapStateToProps = ({ rentRoll, currentUser }) => ({
  rentRollDocument: rentRoll.document,
  widgetAuth: currentUser.widgetAuth,
  userRole: currentUser.role
});

export default connect(mapStateToProps)(Resolved);
