import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { documentType as DocumentType } from "server/middleware/api/helpers/documents";

const ActionTrigger = ({ doc, editProperty, setEditProperty }) => {
  const docTypePath =
    doc.document_type === DocumentType.RENT_ROLL ? "rent-roll" : "opex";
  return (
    <div>
      {doc.status === "PROPERTY_INFO_NEEDED" ? (
        <img
          src={require("ui/images/icon-reviewx24.svg")}
          style={{ cursor: "pointer", height: "30px" }}
          onClick={() => {
            if (editProperty) {
              setEditProperty(false);
            } else {
              setEditProperty(true);
            }
          }}
        />
      ) : (
        <NavLink
          to={{
            pathname: `/${docTypePath}/split-panel/property/${
              doc.property_id
            }/document/${doc.id}${
              doc.portfolio_id ? `/portfolio/${doc.portfolio_id}` : ""
            }`
          }}
        >
          <img
            src={require("ui/images/icon-reviewx24.svg")}
            style={{ cursor: "pointer", height: "30px" }}
          />
        </NavLink>
      )}
    </div>
  );
};

ActionTrigger.propTypes = {
  doc: PropTypes.object,
  editProperty: PropTypes.bool,
  setEditProperty: PropTypes.func
};

export default ActionTrigger;
