import PropTypes from "prop-types";
import OpexCellContainer from "../OpexCellContainer/OpexCellContainer";
// a cell for basetable that lets you supply a renderer in the props.
// we need this because basetable only allows you to pick 1 cell renderer for every column,
// so we use this cell. but in the actual cell data, we can supply a different renderer using
// the `triageCellRenderer` prop, so that different rows can render different things.
// This behavior needed for the 2nd and 3rd row of the table, which are the status/stats rows

const CellTriage = props => {
  const TriageCellRenderer = props.cellData?.triageCellRenderer;
  if (!TriageCellRenderer) {
    return null;
  }

  const { confidence, corrected } = props.cellData.valuesInRawDataJson || {};
  return (
    <OpexCellContainer confidence={confidence} corrected={corrected}>
      <TriageCellRenderer {...props} />
    </OpexCellContainer>
  );
};

export default CellTriage;

CellTriage.propTypes = {
  cellData: PropTypes.shape({
    triageCellRenderer: PropTypes.func,
    valuesInRawDataJson: PropTypes.object
  })
};
