import { Component } from "react";
import PropTypes from "prop-types";

import FormPageChanger from "./FormPageChanger";
import ExcelPageChanger from "./ExcelPageChanger";
import { documentFileType } from "server/middleware/api/helpers/documents.js";

class PageChanger extends Component {
  render() {
    const { fileExtension } = this.props;
    switch (fileExtension) {
      case documentFileType.PDF:
      case documentFileType.PNG:
      case documentFileType.JPG:
      case documentFileType.JPEG:
        return <FormPageChanger />;
      case documentFileType.XLS:
      case documentFileType.XLSX:
      case documentFileType.XLSM:
      case documentFileType.CSV:
        return <ExcelPageChanger />;
      default:
        console.error("Invalid file extension");
        return null;
    }
  }
}

PageChanger.propTypes = {
  fileExtension: PropTypes.string
};

export default PageChanger;
