import { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";

import {
  fetchPortfoliosByCompanyId,
  clearPortfolios
} from "ui/store/actions/portfolios";

import ItemForm from "./ItemForm";
import AutocompleteForm from "ui/components/shared/AutoComplete";
import Button from "ui/components/shared/Button";
import { resetSearch } from "ui/store/actions/properties";
import { bindActionCreators } from "redux";
import { isBlank } from "helpers/presence";
import { propertyActions } from "ui/components/properties/helpers/propertiesList";

const PropertiesFirstForm = ({
  setForm,
  formData,
  navigation,
  onNext,
  isRecommendedPropertySelected,
  selCountry,
  selState,
  selectCountry,
  selectRegion,
  setRecommendedPropertySelected,
  childToEditProperty,
  changePage,
  fetchPortfoliosByCompanyId,
  selPortfolio,
  selectPortfolio,
  clearPortfolios,
  portfolios,
  action,
  canUpdatePropertyType
}) => {
  const { street_2, city, postal_code } = formData;
  const fullAddress = `${!isBlank(formData.street_1) ? formData.street_1 : ""}${
    !isBlank(formData.city) ? ", " + formData.city : ""
  }${!isBlank(formData.state) ? ", " + formData.state : ""}${
    !isBlank(formData.postal_code) ? " " + formData.postal_code : ""
  }`;
  const { next } = navigation;
  useEffect(() => {
    fetchPortfoliosByCompanyId();

    return () => {
      clearPortfolios();
    };
  }, []);

  function encodeParams(params) {
    return Object.entries(params)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value.trim())}`
      )
      .join("&");
  }

  function updateForm(address, isRecommendedProperty = false) {
    setRecommendedPropertySelected(isRecommendedProperty);
    formData.street_1 = address[0] ? address[0] : formData.street_1;
    formData.city = address[1] ? address[1] : formData.city;
    if (address[2]) {
      const stateAndZip = address[2].split(" ");
      if (stateAndZip.length == 2) {
        selectRegion(stateAndZip[0]);
        formData.postal_code = stateAndZip[1];
      } else {
        selectRegion(address[2]);
      }
    }
  }

  function clickNext() {
    if (isRecommendedPropertySelected) {
      const location = {
        street: formData.street_1,
        city: formData.city,
        state: formData.state,
        zip_code: formData.postal_code
      };
      const params = encodeParams(location);
      onNext(params);
      next();
    } else {
      next();
    }
  }

  const showAddDetails =
    (childToEditProperty && selPortfolio) || !childToEditProperty;

  return (
    <div className="Properties-first-form__section">
      <div className="Properties-first-form__header">
        {action === propertyActions.edit ? "Edit " : "Add "} property
      </div>
      <form style={{ paddingTop: "25px" }}>
        <div className="Properties-first-form__fillable">
          {childToEditProperty && (
            <ItemForm label="Portfolio" type="custom">
              <select
                style={{
                  borderColor: "#FFFFFF",
                  width: "100%",
                  height: "38px"
                }}
                value={selPortfolio}
                onChange={e => {
                  selectPortfolio(e.target.value);
                }}
              >
                <option key="blank" value="">
                  Select a Portfolio
                </option>
                {portfolios.map(portfolio => (
                  <option key={portfolio.name} value={portfolio.id}>
                    {portfolio.name}
                  </option>
                ))}
              </select>
            </ItemForm>
          )}
          <ItemForm label="Address Line 1" type="custom">
            <AutocompleteForm
              OnReset={resetSearch}
              onMenuSelect={updateForm}
              inputClass="SearchForm__input"
              placeholder="Start typing an address..."
              name="query"
              fullAddress={fullAddress}
              isGooglePlaces={false}
              widgetAuth={{}}
            />
          </ItemForm>
          <ItemForm
            label="Address Line 2"
            name="street_2"
            value={street_2}
            onChange={setForm}
            placeholder="Apt, floor, etc"
          />
          <div className="Properties-first-form__city_state_zip_grid">
            <ItemForm
              label="City"
              name="city"
              value={city}
              onChange={setForm}
              placeholder="City"
            />
            <ItemForm label="State" type="custom">
              <RegionDropdown
                defaultOptionLabel="State"
                country={formData.country}
                value={selState}
                labelType="short"
                valueType="short"
                onChange={val => selectRegion(val)}
                name="state"
                style={{
                  width: "100%",
                  height: "38px",
                  borderColor: "#FFFFFF"
                }}
              />
            </ItemForm>

            <ItemForm
              label="Zip Code"
              name="postal_code"
              value={postal_code}
              onChange={setForm}
              placeholder="Zip Code"
            />
          </div>
          <ItemForm label="Country" type="custom">
            <CountryDropdown
              name="country"
              showDefaultOption={true}
              value={selCountry}
              onChange={val => selectCountry(val)}
              style={{
                borderColor: "#FFFFFF",
                width: "100%",
                height: "38px"
              }}
            />
          </ItemForm>
        </div>
        <div style={{ float: "right" }}>
          {childToEditProperty && (
            <Button
              className="Button__blackButton"
              style={{ marginRight: "10px" }}
              onClick={() => changePage("main")}
            >
              Cancel
            </Button>
          )}
          {showAddDetails && (
            <Button
              className="Button__blueSolidButton"
              onClick={e => {
                e.preventDefault();
                clickNext();
              }}
            >
              Next
            </Button>
          )}
        </div>
      </form>
    </div>
  );
};

PropertiesFirstForm.propTypes = {
  setForm: PropTypes.func,
  formData: PropTypes.object,
  navigation: PropTypes.object,
  onNext: PropTypes.func,
  isRecommendedPropertySelected: PropTypes.bool,
  selCountry: PropTypes.string,
  selState: PropTypes.string,
  selectCountry: PropTypes.func,
  selectRegion: PropTypes.func,
  setRecommendedPropertySelected: PropTypes.func,
  childToEditProperty: PropTypes.bool,
  changePage: PropTypes.func,
  fetchPortfoliosByCompanyId: PropTypes.func,
  selPortfolio: PropTypes.object,
  selectPortfolio: PropTypes.func,
  clearPortfolios: PropTypes.func,
  portfolios: PropTypes.array,
  action: PropTypes.string,
  canUpdatePropertyType: PropTypes.bool
};

const mapStateToProps = ({ portfolios }) => ({
  portfolios: portfolios.portfolios
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ fetchPortfoliosByCompanyId, clearPortfolios }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PropertiesFirstForm);
