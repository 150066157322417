import { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { updatePdfMarkdownLoading } from "ui/store/actions/rentRoll";

const ExceptionSubsection = ({
  title,
  exceptions,
  countColor,
  handleScrollToCell
}) => {
  const [isVisible, updateIsVisible] = useState(false);
  return (
    <>
      <div
        className="ExceptionSidebar__subHeader"
        onClick={() => {
          if (exceptions) {
            updateIsVisible(!isVisible);
          }
        }}
      >
        {`${title} `}
        <span
          className="ExceptionSidebar__subHeader__count"
          style={{ color: countColor ? countColor : "red" }}
        >{` (${exceptions ? exceptions.length : 0}) `}</span>
        <span className="ExceptionSidebar__subHeader__plusMinus">{` ${
          isVisible ? "-" : "+"
        }`}</span>
      </div>
      {isVisible && exceptions && (
        <div>
          {exceptions.map(exception => {
            const { pi, ti, ri, ci } = exception.location.start
              ? exception.location.start
              : exception.location;
            const location = `${pi}-${ti}-${ri}-${ci}`;
            return (
              <div key={exception.id} className="ExceptionSidebar__exception">
                <div
                  className="ExceptionSidebar__exception__header"
                  onClick={() => {
                    handleScrollToCell("exception", location);
                  }}
                >
                  {exception.label}
                </div>
                <div className="ExceptionSidebar__exception__body">
                  {exception.description}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

ExceptionSubsection.propTypes = {
  title: PropTypes.string,
  document: PropTypes.object,
  exceptions: PropTypes.array,
  countColor: PropTypes.string,
  handleScrollToCell: PropTypes.func,
  updatePdfMarkdownLoading: PropTypes.func
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updatePdfMarkdownLoading }, dispatch);

export default connect(null, mapDispatchToProps)(ExceptionSubsection);
