import _ from "lodash";
import { useState } from "react";
import PropTypes from "prop-types";
import { BsCheck, BsX } from "react-icons/bs";
import OpexCellValueText from "./OpexCellValueText";

import {
  OPEX_VALUE_TYPE_AMOUNT,
  OPEX_VALUE_TYPE_NEGATIVE_AMOUNT,
  getClassNameForCellScrollIntoView,
  OPEX_VALUE_TYPE_INTEGER,
  OPEX_VALUE_TYPE_PERCENT
} from "ui/components/opex/shared";
const EditableCell = ({ cellData }) => {
  const {
    onClick,
    onSubmit,
    onCancel,
    onMouseEnterCell,
    onMouseLeaveCell,
    value,
    valueType,
    disabled,
    location,
    activeCellLocation
  } = cellData;

  const forceNegative = number => {
    return Math.abs(parseFloat(number)) * -1;
  };

  // Remove extra decimal places from sever response.
  const valueForDefaultState = value => {
    if (
      valueType === OPEX_VALUE_TYPE_AMOUNT ||
      valueType === OPEX_VALUE_TYPE_NEGATIVE_AMOUNT
    ) {
      return parseFloat(value).toFixed(2);
    } else if (valueType === OPEX_VALUE_TYPE_INTEGER) {
      return parseInt(value);
    } else if (valueType === OPEX_VALUE_TYPE_PERCENT) {
      return `${parseFloat(value).toFixed(2)}`;
    } else {
      return value;
    }
  };

  const editing =
    JSON.stringify(location) === JSON.stringify(activeCellLocation);

  // The default value in the input field. Changes to input
  // are managed here too and this value is sent back on submit.
  const [nextValue, setNextValue] = useState(valueForDefaultState(value));

  // The value to display on the UI. This does not reflect any changes
  // made by typing in the input.
  const [displayValue, setDisplayValue] = useState(valueForDefaultState(value));

  function handleSubmit() {
    // If user enters no input and tries to submit, just reset the field.
    if (_.trim(nextValue) === "") {
      return resetEditing();
    }

    setDisplayValue(valueForDefaultState(nextValue));
    onSubmit(
      valueType === OPEX_VALUE_TYPE_NEGATIVE_AMOUNT
        ? forceNegative(nextValue).toFixed(2)
        : nextValue,
      cellData
    );
  }

  function handleCancel() {
    resetEditing();
    if (onCancel) {
      onCancel();
    }
  }

  function handleOnClick() {
    onClick(cellData);
  }

  function resetEditing() {
    setNextValue(displayValue);
  }

  function handleOnChange(e) {
    let nextValue = e.target.value;
    setNextValue(nextValue);
  }

  function handleOnKeyDown(e) {
    // <input type="number"> still allows e, and +
    // but we do not want them
    const disallowedKeys = ["e", "+"];
    if (_.includes(disallowedKeys, e.key)) {
      e.preventDefault();
      return false;
    }

    if (e.key === "Enter") {
      handleSubmit();
    }
    if (e.key === "Escape") {
      resetEditing();
    }
  }

  const handleOnMouseEnter = () => {
    if (onMouseEnterCell) {
      onMouseEnterCell(cellData);
    }
  };

  const handleOnMouseLeave = () => {
    if (onMouseLeaveCell) {
      onMouseLeaveCell(null);
    }
  };

  const renderViewComponents = () => {
    // If the cell is disabled we want to prevent handling the onClick
    // event, and we want to supply it a different classname so it renders
    // greyed out.
    const className = disabled ? "cellDisabled" : "cellValue";
    const onClick = disabled ? null : handleOnClick;
    return (
      <div className={className} onClick={onClick}>
        <OpexCellValueText value={displayValue} type={cellData.valueType} />
      </div>
    );
  };

  // the sidebar uses this classname to known where to scrollIntoView
  // when you hover on the pdf image on the right bar
  const classNameToSyncWithSidebar = getClassNameForCellScrollIntoView(
    location
  );

  return (
    <div className="OpexBaseTable--EditableCell">
      <div
        className={`cellContent ${classNameToSyncWithSidebar}`}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      >
        {editing && (
          <div className="cellInput">
            <input
              autoFocus
              type="number"
              value={nextValue}
              onChange={handleOnChange}
              onKeyDown={handleOnKeyDown}
            />
            <div className="cellInputAction" onClick={handleCancel}>
              <BsX color="#BA0B2F" size="2em" />
            </div>
            <div className="cellInputAction" onClick={handleSubmit}>
              <BsCheck color="#1464fa" size="2em" />
            </div>
          </div>
        )}
        {!editing && renderViewComponents()}
      </div>
    </div>
  );
};

export default EditableCell;

EditableCell.propTypes = {
  cellData: PropTypes.shape({
    value: PropTypes.string,
    valuesInRawDataJson: PropTypes.object,
    valueType: PropTypes.string,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    onClick: PropTypes.func,
    onHover: PropTypes.func,
    onMouseEnterCell: PropTypes.func,
    onMouseLeaveCell: PropTypes.func,
    disabled: PropTypes.bool
  })
};
