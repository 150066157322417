export const alignStickyTableHeader = name => {
  if (
    "NAME" === name ||
    "ADDRESS" === name ||
    "SECTOR" === name ||
    "CITY" === name ||
    "STATE" === name ||
    "ZIP CODE" === name
  ) {
    return "left";
  } else {
    return "right";
  }
};

export const alignStickyTableCell = name => {
  if (
    "NAME" === name ||
    "ADDRESS" === name ||
    "SECTOR" === name ||
    "CITY" === name ||
    "STATE" === name ||
    "ZIP CODE" === name
  ) {
    return "flexboxgrid__header-cell--left";
  } else {
    return "flexboxgrid__header-cell--right";
  }
};

/**
 * Object to map column name visible in UI with corresponding sort name in ES
 */
export const columnDetails = {
  property: { columnName: "NAME", sortName: "_key" },
  sector: { columnName: "SECTOR" },
  units: {
    columnName: "UNITS",
    sortName: "total_units"
  },
  sqft: { columnName: "SQFT", sortName: "total_square_footage" },
  totalMonthlyRent: {
    columnName: "TOTAL MONTHLY RENT",
    sortName: "total_monthly_rent"
  },
  avgRentPerSqft: {
    columnName: "AVG RENT PER SQFT",
    sortName: "average_rent_per_sqft"
  },
  vacancyUnit: {
    columnName: "VACANCY (UNITS)",
    sortName: "vacancy_unit"
  },
  vacancySqft: {
    columnName: "VACANCY (SF)",
    sortName: "vacancy_sqft"
  }
};

export const propertyActions = {
  add: "ADD",
  edit: "EDIT"
};
