import { merge } from "ramda";
import { getRentRollFieldsForDropDown } from "./helpers/rentroll";

import {
  FETCHING_DOCUMENTS,
  FETCHING_DOCUMENT,
  FETCHING_RENT_ROLL,
  FETCHED_DOCUMENTS_SUCCESSFUL,
  FETCHED_DOCUMENT_SUCCESSFUL,
  FETCHED_RENT_ROLL_SUCCESSFUL,
  FETCHED_RENT_ROLL_BY_DOCID_SUCCESSFUL,
  UPDATE_UPLOAD_STATUS,
  UPDATED_DOCUMENT_TEMPLATE_SUCCESSFUL,
  UPDATING_DOCUMENT_TEMPLATE,
  UPDATED_DOCUMENT_FIELD_SUCCESSFUL,
  UPDATING_UPLOADED_DOCS,
  UPDATED_UPLOADED_DOCS_SUCCESSFUL,
  REMOVE_UPLOAD_DOC_BY_ID,
  PROCESSING_DOCUMENT,
  UPDATE_EDIT_HEADER,
  CLEAR_EDIT_HEADER,
  UPDATE_EDIT_TEXT,
  CLEAR_EDIT_TEXT,
  UPDATE_DOCUMENT_CHECK,
  UPDATE_CACHE,
  CLEAR_CACHE,
  UPDATE_EXCEPTION_SIDEBAR,
  UPDATE_PAGE_INDEX,
  UPDATE_PDF_MARKDOWN_LOADING,
  UPDATE_PDF_MARKDOWN_LOADED,
  EXCEPTIONS_LOADED,
  TABLE_STATUS_UPDATED,
  UPDATING_RENT_ROLL_FIELDS,
  UPDATE_RENT_ROLL_FIELDS_SUCCESSFUL,
  FETCHING_RENT_ROLL_FIELDS,
  FETCH_RENT_ROLL_FIELDS_SUCCESSFUL,
  PREDEF_SUGESTION_UPDATED,
  PREDEF_SUGESTION_CLEARED,
  PREDEF_SUGESTION_LOADING,
  FETCHED_RENT_ROLL_COMPOSITION_SUCCESSFUL,
  FETCHED_RENT_ROLL_ANALYTICS_SUCCESSFUL,
  ADDED_ROW_TO_RENT_ROLL,
  REMOVED_ROW_FROM_RENT_ROLL,
  UNDO_REMOVE_ROW_FROM_RENT_ROLL_SUCCESSFUL,
  REMOVED_RENT_ROLL_ROW_EXCEPTIONS,
  REINSTATE_RENT_ROLL_ROW_EXCEPTIONS_SUCCESSFUL,
  UPDATED_DOCUMENT_SUCCESSFUL,
  UPDATING_DOCUMENT,
  SPLIT_PANEL_REFRESHED,
  CLEAR_SPLIT_PANEL,
  FETCHING_DOCUMENT_PDF,
  FETCHING_DOCUMENT_URL_SUCCESSFUL,
  REFRESHING_SPLIT_PANEL,
  EXCEL_MARKDOWN_LOADING,
  EXCEL_MARKDOWN_LOADED,
  UPDATE_DOCUMENT_TEMPLATE_SUCCESSFUL,
  FETCHED_ALL_RENT_ROLLS_SUCCESSFUL,
  FETCHING_COMPANY_DOCUMENTS,
  FETCHED_COMPANY_DOCUMENTS_SUCCESSFULLY,
  FETCH_UPLOADING_DOCUMENTS_SUCCESSFUL,
  FETCH_NEED_ATTENTION_DOCUMENTS_SUCCESSFUL,
  DELETING_DOCUMENTS_COMPANY_SUCCESSFUL,
  DELETING_DOCUMENTS_COMPANY,
  CLEAR_DOCUMENTS,
  CLEAR_RENT_ROLL,
  CLEAR_ANALYTICS
} from "ui/store/actions/rentRoll";

export const FETCHING = "FETCHING";
export const UPDATING = "UPDATING";
export const UPDATED = "UPDATED";
export const LOADING = "LOADING";
export const LOADED = "LOADED";

export default function rentRoll(
  state = {
    documents: [],
    document: {},
    analytics: {},
    rentRoll: [],
    uploadingDocs: [],
    analyzingDocs: [],
    needAttentionDocs: [],
    allRentRolls: [],
    selectedProperty: {},
    status: FETCHING,
    uploadStatus: "",
    resultStatus: LOADING,
    pdfMarkdownStatus: LOADING,
    excelMarkdownStatus: LOADING,
    rentRollFieldsStatus: FETCHING,
    editHeader: null,
    editText: null,
    editDocumentCheck: false,
    cache: {},
    exceptionSidebarIsActive: false,
    pageIndex: 0,
    totalPages: 0,
    exceptions: [],
    fieldsByPropertyType: {},
    fieldNamesMappingForDropDown: {},
    predefValues: [],
    fileUrl: ""
  },
  action
) {
  switch (action.type) {
    case FETCHING_DOCUMENTS: {
      return merge(state, { status: FETCHING });
    }
    case FETCHING_DOCUMENT: {
      return merge(state, { status: FETCHING });
    }
    case FETCHED_DOCUMENTS_SUCCESSFUL: {
      const { documents } = action;
      return merge(state, { documents, status: LOADED });
    }
    case FETCHED_DOCUMENT_SUCCESSFUL: {
      const { document } = action;
      return merge(state, { document, status: LOADED });
    }
    case UPDATE_UPLOAD_STATUS: {
      const { uploadStatus } = action;
      return merge(state, {
        uploadStatus: uploadStatus
      });
    }
    case REMOVE_UPLOAD_DOC_BY_ID: {
      const { documentId } = action;
      return merge(state, {
        uploadingDocs: state.uploadingDocs.filter(doc => doc.id !== documentId)
      });
    }
    case FETCHED_RENT_ROLL_COMPOSITION_SUCCESSFUL: {
      const { metrics } = action;
      return merge(state, { metrics, status: LOADED });
    }
    case FETCHED_RENT_ROLL_ANALYTICS_SUCCESSFUL: {
      const { analytics } = action;
      return merge(state, { analytics, status: LOADED });
    }
    case FETCHING_RENT_ROLL: {
      return merge(state, { status: FETCHING });
    }
    case FETCHED_RENT_ROLL_SUCCESSFUL: {
      const { rentRoll } = action;
      const { selectedProperty } = action;
      return merge(state, { rentRoll, selectedProperty, status: LOADED });
    }
    case FETCHED_RENT_ROLL_BY_DOCID_SUCCESSFUL: {
      const { rentRoll } = action;
      return merge(state, { rentRoll, status: LOADED });
    }
    case FETCHED_ALL_RENT_ROLLS_SUCCESSFUL: {
      const { allRentRolls } = action;
      return merge(state, { allRentRolls, status: LOADED });
    }
    case UPDATED_DOCUMENT_TEMPLATE_SUCCESSFUL: {
      return merge(state, { status: LOADED });
    }
    case UPDATING_UPLOADED_DOCS: {
      return merge(state, { status: UPDATING });
    }
    case UPDATED_UPLOADED_DOCS_SUCCESSFUL: {
      const { fileObj } = action;
      return merge(state, {
        uploadingDocs: [...state.uploadingDocs, fileObj],
        status: LOADED
      });
    }
    case UPDATING_DOCUMENT_TEMPLATE: {
      return merge(state, { status: UPDATING });
    }
    case UPDATED_DOCUMENT_FIELD_SUCCESSFUL: {
      const { document, rentRoll, exceptions } = action;
      return merge(state, {
        document,
        rentRoll,
        exceptions,
        status: LOADED
        // pdfMarkdownStatus: LOADING
      });
    }
    // case UPDATING_DOCUMENT_FIELD: {
    //   return merge(state, { status: UPDATING });
    // }
    case PROCESSING_DOCUMENT: {
      return merge(state, { status: FETCHING });
    }
    case UPDATE_EDIT_HEADER: {
      const { obj } = action;
      return merge(state, { editHeader: obj });
    }
    case CLEAR_EDIT_HEADER: {
      return merge(state, { editHeader: null });
    }
    case UPDATE_EDIT_TEXT: {
      const { obj } = action;
      return merge(state, { editText: obj });
    }
    case CLEAR_EDIT_TEXT: {
      return merge(state, { editText: null });
    }
    case UPDATE_DOCUMENT_CHECK: {
      const { bool } = action;
      return merge(state, { editDocumentCheck: bool });
    }
    case UPDATE_CACHE: {
      const { id, val } = action;
      return { ...state, cache: { ...state.cache, [id]: val } };
    }
    case CLEAR_CACHE: {
      return {
        ...state,
        cache: {}
      };
    }
    case UPDATE_EXCEPTION_SIDEBAR: {
      const { val } = action;
      return {
        ...state,
        exceptionSidebarIsActive: val
      };
    }
    case UPDATE_PAGE_INDEX: {
      const { pageIndex } = action;
      return {
        ...state,
        pageIndex
      };
    }
    case UPDATE_PDF_MARKDOWN_LOADING: {
      return {
        ...state,
        pdfMarkdownStatus: LOADING
      };
    }
    case UPDATE_PDF_MARKDOWN_LOADED: {
      return {
        ...state,
        pdfMarkdownStatus: LOADED
      };
    }
    case UPDATING_RENT_ROLL_FIELDS: {
      return {
        ...state,
        rentRollFieldsStatus: UPDATING
      };
    }
    case UPDATE_RENT_ROLL_FIELDS_SUCCESSFUL: {
      const { fieldsByPropertyType } = action;
      return {
        ...state,
        fieldsByPropertyType,
        fieldNamesMappingForDropDown: getRentRollFieldsForDropDown(
          fieldsByPropertyType
        ),
        rentRollFieldsStatus: UPDATED
      };
    }
    case FETCHING_RENT_ROLL_FIELDS: {
      return {
        ...state,
        rentRollFieldsStatus: FETCHING
      };
    }
    case FETCH_RENT_ROLL_FIELDS_SUCCESSFUL: {
      const { fieldsByPropertyType } = action;
      return {
        ...state,
        rentRollFieldsStatus: LOADED,
        fieldsByPropertyType,
        fieldNamesMappingForDropDown: getRentRollFieldsForDropDown(
          fieldsByPropertyType
        )
      };
    }
    case EXCEPTIONS_LOADED: {
      const { exceptions } = action;
      return {
        ...state,
        exceptions
      };
    }
    case TABLE_STATUS_UPDATED: {
      return {
        ...state,
        pdfMarkdownStatus: LOADING
      };
    }
    case PREDEF_SUGESTION_UPDATED: {
      const { predefValues, isPredefLoading } = action;
      return {
        ...state,
        predefValues,
        isPredefLoading
      };
    }
    case PREDEF_SUGESTION_CLEARED: {
      return {
        ...state,
        predefValues: []
      };
    }
    case PREDEF_SUGESTION_LOADING: {
      const { isPredefLoading } = action;
      return {
        ...state,
        isPredefLoading
      };
    }
    case ADDED_ROW_TO_RENT_ROLL: {
      const { rentRoll } = action;
      return {
        ...state,
        rentRoll
      };
    }
    case REMOVED_ROW_FROM_RENT_ROLL: {
      const { rentRoll } = action;
      return {
        ...state,
        rentRoll
      };
    }
    case UNDO_REMOVE_ROW_FROM_RENT_ROLL_SUCCESSFUL: {
      const { rentRoll } = action;
      return {
        ...state,
        rentRoll
      };
    }
    case REMOVED_RENT_ROLL_ROW_EXCEPTIONS: {
      const removedExceptionIds = action.exceptions.map(ex => ex.id);
      return merge(state, {
        exceptions: state.exceptions.filter(
          ex => !removedExceptionIds.includes(ex.id)
        )
      });
    }
    case REINSTATE_RENT_ROLL_ROW_EXCEPTIONS_SUCCESSFUL: {
      const { exceptions } = action;
      return merge(state, { exceptions: [...state.exceptions, ...exceptions] });
    }
    case UPDATING_DOCUMENT: {
      return merge(state, { status: UPDATING });
    }
    case UPDATED_DOCUMENT_SUCCESSFUL: {
      const { document } = action;
      return merge(state, { document, status: LOADED });
    }
    case REFRESHING_SPLIT_PANEL: {
      return merge(state, { document, excelMarkdownStatus: LOADING });
    }
    case SPLIT_PANEL_REFRESHED: {
      const { document, rentRoll, exceptions } = action;
      return merge(state, {
        document,
        rentRoll,
        exceptions,
        excelMarkdownStatus: LOADED
      });
    }
    case CLEAR_SPLIT_PANEL: {
      return {
        ...state,
        document: {},
        rentRoll: [],
        exceptions: [],
        pageIndex: 0
      };
    }
    case EXCEL_MARKDOWN_LOADING: {
      return {
        ...state,
        excelMarkdownStatus: LOADING
      };
    }
    case EXCEL_MARKDOWN_LOADED: {
      return {
        ...state,
        excelMarkdownStatus: LOADED
      };
    }
    case FETCHING_DOCUMENT_PDF: {
      return merge(state, { status: FETCHING });
    }
    case FETCHING_DOCUMENT_URL_SUCCESSFUL: {
      const { fileUrl } = action;
      return merge(state, { fileUrl, status: LOADED });
    }
    case UPDATE_DOCUMENT_TEMPLATE_SUCCESSFUL: {
      const { document, rentRoll, exceptions } = action;
      return merge(state, { document, rentRoll, exceptions, cache: {} });
    }
    case FETCHING_COMPANY_DOCUMENTS: {
      return merge(state, { status: FETCHING });
    }
    case FETCHED_COMPANY_DOCUMENTS_SUCCESSFULLY: {
      const { documents, totalPages } = action;
      return merge(state, { documents, totalPages, status: LOADED });
    }
    case FETCH_UPLOADING_DOCUMENTS_SUCCESSFUL: {
      const { uploadingDocs } = action;
      const analyzingDocs = uploadingDocs.filter(doc =>
        ["PROCESSING_IMAGES", "ANALYZING", "ANALYSIS_QUEUED"].includes(
          doc.status
        )
      );
      const uploading = uploadingDocs.filter(doc =>
        ["CREATED"].includes(doc.status)
      );
      return merge(state, {
        uploadingDocs: [...uploading],
        analyzingDocs: [...analyzingDocs],
        status: LOADED
      });
    }
    case FETCH_NEED_ATTENTION_DOCUMENTS_SUCCESSFUL: {
      const { totalCountNeedAttentionDocs, needAttentionDocs } = action;
      return merge(state, {
        totalCountNeedAttentionDocs,
        needAttentionDocs,
        status: LOADED
      });
    }
    case DELETING_DOCUMENTS_COMPANY: {
      return merge(state, { status: FETCHING });
    }
    case DELETING_DOCUMENTS_COMPANY_SUCCESSFUL: {
      const { documents } = action;
      return merge(state, { documents, status: LOADED });
    }
    case CLEAR_DOCUMENTS: {
      return merge(state, { documents: [] });
    }
    case CLEAR_RENT_ROLL: {
      return merge(state, { rentRoll: [] });
    }
    case CLEAR_ANALYTICS: {
      return {
        ...state,
        analytics: {
          current: {
            agg_field: null,
            agg_value: null,
            agg_value_ref: null,
            gross_annual_rent: null,
            gross_annual_rent_per_sqft: null,
            gross_annual_rent_per_unit: null,
            gross_monthly_rent: null,
            gross_monthly_rent_per_sqft: null,
            occupied_sqft: null,
            occupied_units: null,
            total_sqft: null,
            total_tenants: null,
            total_units: null,
            vacancy_rate_sqft: null,
            vacancy_rate_unit: null
          },
          previous: {
            agg_field: null,
            agg_value: null,
            agg_value_ref: null,
            gross_annual_rent: null,
            gross_annual_rent_per_sqft: null,
            gross_annual_rent_per_unit: null,
            gross_monthly_rent: null,
            gross_monthly_rent_per_sqft: null,
            occupied_sqft: null,
            occupied_units: null,
            total_sqft: null,
            total_tenants: null,
            total_units: null,
            vacancy_rate_sqft: null,
            vacancy_rate_unit: null
          }
        }
      };
    }
    default: {
      return state;
    }
  }
}
