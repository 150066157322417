import { useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import CustomSelectGroup from "./CustomSelectGroup";
import CustomOption from "./CustomOption";

const AccountSelectionWrapper = ({
  documentRowIndex,
  pageIndex,
  tableIndex,
  columnIndex,
  mappedAccount,
  optionGroups,
  isExcluded,
  isDisabled,
  bgColor = "white",
  onChange,
}) => {
  const [templateCategorySearch, setTemplateCategorySearch] = useState("");

  const handleChange = selected =>
    onChange(selected, documentRowIndex, columnIndex, pageIndex, tableIndex, mappedAccount);

  const styles = {
    singleValue: base => ({
      ...base,
      color: isExcluded ? "lightgray" : "inherit"
    }),
    control: base => ({
      ...base,
      border: 0,
      boxShadow: "none",
      cursor: "pointer",
      backgroundColor: bgColor
    }),
    groupHeading: base => ({
      ...base,
      color: "inherit",
      fontFamily: "inherit",
      fontSize: "inherit",
      textTransform: "none",
      padding: "1rem 0 1rem 1rem"
    }),
    option: base => ({
      ...base,
      color: "#343441",
      backgroundColor: "white",
      padding: "1rem 0 1rem 2.5rem",
      "&:hover": {
        backgroundColor: "#e6e6e6"
      }
    }),
    menuPortal: base => ({
      ...base,
      zIndex: 5
    })
  };
  return (
    <Select
      value={{ label: `${mappedAccount}` }}
      options={optionGroups}
      styles={styles}
      onChange={handleChange}
      menuPlacement="auto"
      menuPortalTarget={document.body}
      components={{
        IndicatorSeparator: () => null,
        Group: CustomSelectGroup,
        Option: props => (
          <CustomOption filterValue={templateCategorySearch} {...props} />
        )
      }}
      filterOption={(option, input) => {
        setTimeout(() => setTemplateCategorySearch(input));
        const isIncluded = (option, input) =>
          option.label?.toLowerCase().includes(input.toLowerCase()) ||
          (!!option.data?.options?.length &&
            option.data.options.some(opt => isIncluded(opt, input)));

        return isIncluded(option, input);
      }}
      isSearchable
      isDisabled={isDisabled}
    />
  );
};

export default AccountSelectionWrapper;

AccountSelectionWrapper.propTypes = {
  documentRowIndex: PropTypes.number,
  columnIndex: PropTypes.number,
  pageIndex: PropTypes.number,
  tableIndex: PropTypes.number,
  mappedAccount: PropTypes.string,
  optionGroups: PropTypes.array,
  isExcluded: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
};
