import { Component } from "react";
import { Grid, Row, Col } from "react-styled-flexboxgrid";
import PropTypes from "prop-types";
import cx from "classnames";
import { connect } from "react-redux";

import {
  currencyFormatter,
  squareFootageFormatter,
  booleanFormatter
} from "../../helpers/metrics";

import { getRentRollFields } from "ui/store/actions/rentRoll";

import NavLink from "ui/components/shared/NavLink";
import DownloadExcel from "ui/components/shared/DownloadExcel";
import { rentroll_columns } from "ui/components/helpers/excelColumns";
import {
  mapUnnormalizedFieldsFromRentRoll,
  getExcelDownloadHeadersForSelectedProperty
} from "ui/components/rentRoll/property/helpers/documents";
import { bindActionCreators } from "redux";

const moment = require("moment");

class RentRoll extends Component {
  componentDidMount() {
    this.fetchRentRollFields();
  }

  fetchRentRollFields = async () => {
    const { getRentRollFields } = this.props;
    await getRentRollFields();
  };

  getDownloadHeader() {
    const { selectedProperty, fieldNamesMappingForDropDown } = this.props;
    return getExcelDownloadHeadersForSelectedProperty(
      selectedProperty,
      fieldNamesMappingForDropDown,
      rentroll_columns
    );
  }

  getDownloadRentRolls() {
    const { allRentRolls } = this.props;
    let allRentRollsForExcel = [...allRentRolls];
    return mapUnnormalizedFieldsFromRentRoll(allRentRollsForExcel);
  }

  render() {
    const {
      rentRoll,
      portfolioId,
      selectedProperty,
      fieldNamesMappingForDropDown
    } = this.props;

    const headerFieldsAvailable =
      selectedProperty &&
      selectedProperty.property_type &&
      fieldNamesMappingForDropDown[
        selectedProperty.property_type.toLowerCase()
      ];

    return (
      <div className="RentRoll__section">
        <div className="RentRoll__header-section">
          <div
            style={{
              margin: "10px 0 0 auto"
            }}
          >
            <DownloadExcel
              dataset={this.getDownloadRentRolls()}
              filename={`rentroll-${moment().format()}.xlsx`}
              headers={this.getDownloadHeader()}
              text="Download"
            />
          </div>
        </div>
        {rentRoll.propertyName && (
          <h1 className="RentRoll__subheader">{rentRoll.propertyName}</h1>
        )}
        <Grid className="RentRoll__grid" fluid>
          <Row className="row-header">
            <Col xs={4} md={2} className={cx("flexboxgrid__header-cell--left")}>
              {/* Tenant Name */}
              {headerFieldsAvailable
                ? fieldNamesMappingForDropDown[
                    selectedProperty.property_type.toLowerCase()
                  ]["tenant_name"]
                : ""}
            </Col>
            <Col xs={2} md={1} className={cx("flexboxgrid__header-cell--left")}>
              {/* Unit Name */}
              {headerFieldsAvailable
                ? fieldNamesMappingForDropDown[
                    selectedProperty.property_type.toLowerCase()
                  ]["unit_name"]
                : ""}
            </Col>
            <Col
              xs={2}
              md={1}
              className={cx("flexboxgrid__header-cell--right")}
            >
              {/* Square Footage */}
              {headerFieldsAvailable
                ? fieldNamesMappingForDropDown[
                    selectedProperty.property_type.toLowerCase()
                  ]["size_sqft"]
                : ""}
            </Col>
            <Col
              xs={2}
              md={1}
              className={cx("flexboxgrid__header-cell--right")}
            >
              {/* Monthly Rent */}
              {headerFieldsAvailable
                ? fieldNamesMappingForDropDown[
                    selectedProperty.property_type.toLowerCase()
                  ]["monthly_rent"]
                : ""}
            </Col>
            <Col
              xs={2}
              md={1}
              className={cx("flexboxgrid__header-cell--right")}
            >
              {/* Annual Rent */}
              {headerFieldsAvailable
                ? fieldNamesMappingForDropDown[
                    selectedProperty.property_type.toLowerCase()
                  ]["annual_rent"]
                : ""}
            </Col>
            <Col
              xs={2}
              md={1}
              className={cx("flexboxgrid__header-cell--right")}
            >
              {/* Start Date */}
              {headerFieldsAvailable
                ? fieldNamesMappingForDropDown[
                    selectedProperty.property_type.toLowerCase()
                  ]["lease_start_date"]
                : ""}
            </Col>
            <Col
              xs={2}
              md={1}
              className={cx("flexboxgrid__header-cell--right")}
            >
              {/* End Date */}
              {headerFieldsAvailable
                ? fieldNamesMappingForDropDown[
                    selectedProperty.property_type.toLowerCase()
                  ]["lease_end_date"]
                : ""}
            </Col>
            <Col
              xs={2}
              md={1}
              className={cx("flexboxgrid__header-cell--right")}
            >
              Is Vacant
            </Col>
            <Col
              xs={2}
              md={1}
              className={cx("flexboxgrid__header-cell--right")}
            >
              Is Occupied
            </Col>
          </Row>
          {rentRoll &&
            rentRoll
              .filter(item => item.status !== "DOCUMENT_DEL")
              .map((item, index) => {
                // const findRentResult = item.doc_data_json.row.filter(dataProp => {
                //   if ("rent" === dataProp.id) {
                //     return true;
                //   }
                // });

                // const annualRentValue = findRentResult[0]
                //   ? findRentResult[0].value
                //   : null;
                // const findEndDateResult = item.doc_data_json.row.filter(
                //   dataProp => {
                //     if ("lease_end_date" === dataProp.id) {
                //       return true;
                //     }
                //   }
                // );
                // const leaseEndDateValue = findEndDateResult[0]
                //   ? findEndDateResult[0].value
                //   : null;
                // const findStartDateResult = item.doc_data_json.row.filter(
                //   dataProp => {
                //     if ("lease_start_date" === dataProp.id) {
                //       return true;
                //     }
                //   }
                // );
                // const leaseStartDateValue = findStartDateResult[0]
                //   ? findStartDateResult[0].value
                //   : null;

                return (
                  <Row
                    key={index}
                    className={`${
                      index % 2 === 0 ? "row-body" : "row-body row-odd"
                    }
                      ${item.is_vacant ? "RentRoll__tenant-vacant" : ""} `}
                  >
                    <Col
                      xs={4}
                      md={2}
                      key="-1"
                      className={cx(
                        "flexboxgrid__cell",
                        "flexboxgrid__header-cell--left"
                      )}
                    >
                      <NavLink
                        className="PropertiesList__rentroll-nav"
                        to={`/portfolios/tenant-analysis/${encodeURIComponent(
                          item.tenant_name
                        )}?portfolioId=${portfolioId}`}
                      >
                        {item.tenant_name}
                      </NavLink>
                    </Col>
                    <Col
                      xs={2}
                      md={1}
                      key={0}
                      id="unit_name"
                      className={cx(
                        "flexboxgrid__cell",
                        "flexboxgrid__header-cell--left"
                      )}
                    >
                      {item.unit_name}
                    </Col>
                    <Col
                      xs={2}
                      md={1}
                      key={1}
                      id="size_sqft"
                      // style={{ textAlign: "right" }}
                      className={cx(
                        "flexboxgrid__cell",
                        "flexboxgrid__header-cell--right"
                      )}
                    >
                      {item.size_sqft &&
                        `${squareFootageFormatter.format(
                          Math.round(+item.size_sqft)
                        )} sq ft`}
                    </Col>
                    <Col
                      xs={2}
                      md={1}
                      key={2}
                      id="monthly_rent"
                      // style={{ textAlign: "right" }}
                      className={cx(
                        "flexboxgrid__cell",
                        "flexboxgrid__header-cell--right",
                        item.is_vacant ? "RentRoll__tenant-vacant-rent" : ""
                      )}
                    >
                      {item.monthly_rent &&
                        currencyFormatter.format(item.monthly_rent)}
                    </Col>
                    <Col
                      xs={2}
                      md={1}
                      key={3}
                      id="annual_rent"
                      // style={{ textAlign: "right" }}
                      className={cx(
                        "flexboxgrid__cell",
                        "flexboxgrid__header-cell--right"
                      )}
                    >
                      {item.annual_rent &&
                        currencyFormatter.format(item.annual_rent)}
                    </Col>
                    <Col
                      xs={2}
                      md={1}
                      key={4}
                      id="lease_start_date"
                      className={cx(
                        "flexboxgrid__cell",
                        "flexboxgrid__header-cell--right"
                      )}
                    >
                      {item.lease_start_date &&
                        moment(item.lease_start_date)
                          .utc()
                          .format("MM/DD/YYYY")}
                    </Col>
                    <Col
                      xs={2}
                      md={1}
                      key={5}
                      id="lease_end_date"
                      className={cx(
                        "flexboxgrid__cell",
                        "flexboxgrid__header-cell--right"
                      )}
                    >
                      {item.lease_end_date &&
                        moment(item.lease_end_date)
                          .utc()
                          .format("MM/DD/YYYY")}
                    </Col>
                    <Col
                      xs={2}
                      md={1}
                      key={6}
                      id="is_vacant"
                      className={cx(
                        "flexboxgrid__cell",
                        "flexboxgrid__header-cell--right"
                      )}
                    >
                      {booleanFormatter.format(item.is_vacant)}
                    </Col>
                    <Col
                      xs={2}
                      md={1}
                      key={6}
                      id="is_occupied"
                      className={cx(
                        "flexboxgrid__cell",
                        "flexboxgrid__header-cell--right"
                      )}
                    >
                      {booleanFormatter.format(!item.is_vacant)}
                    </Col>
                  </Row>
                );
              })}
        </Grid>
      </div>
    );
  }
}

RentRoll.propTypes = {
  rentRoll: PropTypes.array,
  allRentRolls: PropTypes.array,
  portfolioId: PropTypes.number,
  fieldNamesMappingForDropDown: PropTypes.object,
  selectedProperty: PropTypes.object,
  getRentRollFields: PropTypes.func,
  currDocId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

const mapStateToProps = ({ rentRoll, propertyRentRollViewUI }) => ({
  selectedProperty: rentRoll.selectedProperty,
  fieldNamesMappingForDropDown: rentRoll.fieldNamesMappingForDropDown,
  currDocId: propertyRentRollViewUI.docId
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getRentRollFields }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RentRoll);
