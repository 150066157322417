import PropTypes from "prop-types";
import cx from "classnames";

const industryLabel = "Industry: ";
const RadioLabelDetail = ({ predefValue, noIndent, addIndustryLabel }) => (
  <div
    className={cx("EntityResolution__radioLabelDetail", {
      EntityResolution__noIndent: noIndent
    })}
  >
    {addIndustryLabel && <span>{industryLabel}</span>} {predefValue}
  </div>
);

RadioLabelDetail.defaultProps = {
  addIndustryLabel: false
};

RadioLabelDetail.propTypes = {
  predefValue: PropTypes.string,
  noIndent: PropTypes.bool,
  addIndustryLabel: PropTypes.bool
};

export default RadioLabelDetail;
