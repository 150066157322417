import { Component } from "react";
import { Grid, Row, Col } from "react-styled-flexboxgrid";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { RadialChart } from "react-vis";
import { fetchRentRollCompositionByDocId } from "ui/store/actions/rentRoll";
import { multiFamilyPropertyType } from "ui/components/properties/propertiesUtil";
import { fieldNames } from "ui/components/rentRoll/splitPanel/helpers/dictionaries";
import DownloadExcel from "ui/components/shared/DownloadExcel";
import { rentroll_composition_columns } from "ui/components/helpers/excelColumns";
import moment from "moment";
import {
  currencyFormatter,
  compactCurrencyFormatter,
  squareFootageFormatter,
  percentFormatter
} from "../../helpers/metrics";

const colors = [
  "#e6194b",
  "#3cb44b",
  "#ffe119",
  "#4363d8",
  "#f58231",
  "#911eb4",
  "#46f0f0",
  "#f032e6",
  "#bcf60c",
  "#fabebe",
  "#008080",
  "#e6beff",
  "#9a6324",
  "#fffac8",
  "#800000",
  "#aaffc3",
  "#808000",
  "#ffd8b1",
  "#000075",
  "#808080",
  "#ffffff",
  "#000000"
];

class RentRollComposition extends Component {
  constructor() {
    super();
    this.state = {
      aggField: "gross_monthly_rent"
    };
  }

  componentDidMount() {
    this.refreshPreview();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.document.id !== this.props.document.id) {
      this.refreshPreview();
    }
  }

  refreshPreview = () => {
    const { fetchRentRollCompositionByDocId, document, property } = this.props;

    fetchRentRollCompositionByDocId(document.id, this.getField(property));
  };

  getField(property) {
    return property.property_type === multiFamilyPropertyType.id
      ? "space_type"
      : "tenant_name";
  }

  getColor(colorNum) {
    return colors[colorNum % colors.length];
  }

  setAggField(aggField, e) {
    e.preventDefault();
    this.setState({ aggField });
  }

  // Reordering to alternate big and tiny angles
  reorderData(chartData) {
    const data = [...chartData];
    const reorderedData = [];
    data.sort((a, b) => (a.angle > b.angle ? 1 : b.angle > a.angle ? -1 : 0));
    for (let i = 0; i < chartData.length; i++) {
      reorderedData.push(i % 2 == 0 ? data.pop() : data.splice(0, 1)[0]);
    }
    return reorderedData;
  }

  getDownloadHeader() {
    const { property } = this.props;
    const headers = [
      { label: fieldNames[this.getField(property)], key: "agg_value" }
    ];
    headers.push(...rentroll_composition_columns);
    return headers;
  }

  // getDownloadDataset() {
  //   const { metrics } = this.props;
  //   const dataset = metrics.current ? Array.from(metrics.current) : [];
  //   dataset.map(x => x["vacancy_rate_unit"] = percentFormatter.format(x.vacancy_rate_unit));
  //   return dataset;
  // }

  render() {
    const { metrics, property } = this.props;
    const field = this.getField(property);
    let chartData = [];
    const aggField = this.state.aggField;
    if (metrics) {
      const total = metrics.current.reduce((sum, item) => {
        return sum + parseInt(item[aggField] || 0);
      }, 0);

      chartData = metrics.current
        .map((item, index) => {
          const percent = parseInt(item[aggField] || 0) / total;
          let subLabel = "";
          if (aggField === "gross_monthly_rent") {
            subLabel = compactCurrencyFormatter.format(item[aggField]);
          } else if (aggField === "total_sqft") {
            subLabel =
              squareFootageFormatter.format(Math.round(+item[aggField])) +
              " sq ft";
          } else if (aggField === "total_units") {
            subLabel = parseInt(item[aggField]) + " units";
          }
          return {
            label: percentFormatter.format(percent),
            subLabel,
            color: this.getColor(index),
            angle: percent * 2 * Math.PI
          };
        })
        .filter(item => item.angle > 0);
    }

    return metrics ? (
      <div
        className="RentRoll__section"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <div className="RentRoll__header-section">
            <div style={{ paddingLeft: "60px" }}>
              <span>
                {aggField === "gross_monthly_rent" ? (
                  <span style={{ fontWeight: "bold" }}>% Rent </span>
                ) : (
                  <a
                    href="#"
                    onClick={this.setAggField.bind(this, "gross_monthly_rent")}
                  >
                    <span>% Rent</span>
                  </a>
                )}{" "}
                |{" "}
                {aggField === "total_sqft" ? (
                  <span style={{ fontWeight: "bold" }}>% GLA </span>
                ) : (
                  <a
                    href="#"
                    onClick={this.setAggField.bind(this, "total_sqft")}
                  >
                    <span>% GLA</span>
                  </a>
                )}{" "}
                |{" "}
                {aggField === "total_units" ? (
                  <span style={{ fontWeight: "bold" }}>% Units </span>
                ) : (
                  <a
                    href="#"
                    onClick={this.setAggField.bind(this, "total_units")}
                  >
                    <span>% Units</span>
                  </a>
                )}
              </span>
            </div>
            <div>
              <DownloadExcel
                dataset={metrics.current ? metrics.current : []}
                filename={`Rentroll-composition-${moment().format()}.xlsx`}
                headers={this.getDownloadHeader()}
                text="Download"
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <RadialChart
              animation
              data={this.reorderData(chartData)}
              innerRadius={30}
              radius={100}
              height={300}
              width={300}
              padAngle={0.02}
              labelsRadiusMultiplier={1.4}
              showLabels={true}
              colorType="literal"
              labelsStyle={{ margin: "10px" }}
            />
            <Grid
              fluid
              style={{
                margin: "0",
                width: "100%"
              }}
            >
              <Row className="row-header">
                <Col xs={1} md={0}></Col>
                <Col xs={4} md={2}>
                  {fieldNames[field]}
                </Col>
                <Col xs={2} md={1} style={{ textAlign: "right" }}>
                  Total Monthly Rent
                </Col>
                <Col xs={4} md={2} style={{ textAlign: "right" }}>
                  Total Sqft
                </Col>
                <Col xs={2} md={1} style={{ textAlign: "right" }}>
                  Occupied Units
                </Col>
                <Col xs={2} md={1} style={{ textAlign: "right" }}>
                  Total Units
                </Col>
                <Col xs={4} md={2} style={{ textAlign: "right" }}>
                  Avg Monthly Rent per unit
                </Col>
                <Col xs={2} md={1} style={{ textAlign: "right" }}>
                  Avg Sqft per Unit
                </Col>
                <Col xs={2} md={1} style={{ textAlign: "right" }}>
                  % Vacant
                </Col>
              </Row>
              {metrics &&
                metrics.current &&
                metrics.current.map((item, index) => {
                  return (
                    <Row
                      key={`row-${aggField}-${index}`}
                      className={
                        index % 2 === 0 ? "row-body" : "row-body row-odd"
                      }
                    >
                      <Col xs={1} md={0} key={aggField + "-" + "index"}>
                        <div
                          style={{
                            width: "14px",
                            height: "14px",
                            backgroundColor: this.getColor(index)
                          }}
                        />
                      </Col>
                      <Col
                        xs={4}
                        md={2}
                        key={aggField + "-" + index + "agg_value"}
                        id="agg_value"
                      >
                        {item.agg_value}
                      </Col>
                      <Col
                        xs={2}
                        md={1}
                        key={aggField + "-" + index + "gross_monthly_rent"}
                        id="gross_monthly_rent"
                        style={{ textAlign: "right" }}
                      >
                        {item.gross_monthly_rent_per_unit &&
                          currencyFormatter.format(item.gross_monthly_rent)}
                      </Col>
                      <Col
                        xs={4}
                        md={2}
                        key={aggField + "-" + index + "total_sqft"}
                        id="total_sqft"
                        style={{ textAlign: "right" }}
                      >
                        {item.total_sqft
                          ? squareFootageFormatter.format(
                              Math.round(+item.total_sqft)
                            ) + " sq ft"
                          : "-"}
                      </Col>
                      <Col
                        xs={2}
                        md={1}
                        key={aggField + "-" + index + "occupied_units"}
                        id="occupied_units"
                        style={{ textAlign: "right" }}
                      >
                        {item.occupied_units}
                      </Col>
                      <Col
                        xs={2}
                        md={1}
                        key={aggField + "-" + index + "total_units"}
                        id="total_units"
                        style={{ textAlign: "right" }}
                      >
                        {item.total_units}
                      </Col>
                      <Col
                        xs={4}
                        md={2}
                        key={
                          aggField + "-" + index + "gross_monthly_rent_per_unit"
                        }
                        id="gross_monthly_rent_per_unit"
                        style={{ textAlign: "right" }}
                      >
                        {item.gross_monthly_rent_per_unit &&
                          currencyFormatter.format(
                            item.gross_monthly_rent_per_unit
                          )}
                      </Col>
                      <Col
                        xs={2}
                        md={1}
                        key={aggField + "-" + index + "avg_sqft_per_unit"}
                        id="avg_sqft_per_unit"
                        style={{ textAlign: "right" }}
                      >
                        {item.total_sqft
                          ? squareFootageFormatter.format(
                              Math.round(+item.total_sqft) / item.total_units
                            ) + " sq ft"
                          : "-"}
                      </Col>
                      <Col
                        xs={2}
                        md={1}
                        key={aggField + "-" + index + "vacancy_rate_unit"}
                        id="vacancy_rate_unit"
                        style={{ textAlign: "right" }}
                      >
                        {item.vacancy_rate_unit &&
                          percentFormatter.format(item.vacancy_rate_unit)}
                      </Col>
                    </Row>
                  );
                })}
            </Grid>
          </div>
        </div>
      </div>
    ) : (
      "Metrics not available"
    );
  }
}

RentRollComposition.propTypes = {
  fetchRentRollCompositionByDocId: PropTypes.func,
  property: PropTypes.object,
  document: PropTypes.object,
  metrics: PropTypes.object
};

function mapStateToProps(state) {
  return {
    metrics: state.rentRoll.metrics
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchRentRollCompositionByDocId
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RentRollComposition);
