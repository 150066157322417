export const SET_INTERVAL_RUNNING = "statusBar/SET_INTERVAL_RUNNING";
export const SET_INTERVAL_ID = "statusBar/SET_INTERVAL_ID";
export const CLEAR_INTERVAL_ID = "statusBar/CLEAR_INTERVAL_ID";

export function setIntervalRunning(intervalRunning) {
  return {
    intervalRunning,
    type: SET_INTERVAL_RUNNING
  };
}

export function setIntervalId(intervalId) {
  return {
    intervalId,
    type: SET_INTERVAL_ID
  };
}

export function clearIntervalId() {
  return {
    type: CLEAR_INTERVAL_ID
  };
}
