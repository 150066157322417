import PropTypes from "prop-types";
import Popup from "reactjs-popup";

const OpexDropdown = ({
  disabled,
  className,
  popupContentClassName,
  position,
  triggerComponent,
  popupContent
}) => {
  const popupPosition = position || "bottom left";
  const popupClassname = `OpexDropdown--dropdownContainer ${popupContentClassName ||
    ""}`;
  return (
    <section className={`OpexDropdown ${className || ""}`}>
      <Popup
        closeOnDocumentClick
        disabled={disabled}
        position={popupPosition}
        contentStyle={{ width: "auto" }}
        trigger={triggerComponent}
        nested
      >
        {close => (
          <section className={popupClassname}>{popupContent(close)}</section>
        )}
      </Popup>
    </section>
  );
};

export default OpexDropdown;

OpexDropdown.propTypes = {
  disabled: PropTypes.bool,
  className: PropTypes.string,
  popupContentClassName: PropTypes.string,
  position: PropTypes.string,
  triggerComponent: PropTypes.element,
  popupContent: PropTypes.func
};
