import { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import BaseTable, { Column, AutoResizer } from "react-base-table";
import { computeIndexesFromKey } from "../../../../helpers/tableKeys";
import useExcelTableData from "./hooks/useExcelTableData";
import CellRenderer from "./components/CellRenderer/CellRenderer";
import HeaderLegendPopup from "../../../../popovers/headerLegend/HeaderLegendPopup";
import { reactLocalStorage } from "reactjs-localstorage";
import { useLocalStorage } from "ui/hooks/useLocalStorage";
import { maxHeaderRows } from "../../../../helpers/constants";
import useSplitPanelV2Context from "ui/components/rentRoll/splitPanelV2/hooks/useSplitPanelV2Context";
import { canCellBeMarkedAsMapped } from "ui/components/rentRoll/splitPanelV2/helpers/canCellBeMarkedAsMapped";
import useRentRollTableData from "../../../../hooks/useRentRollTableData";
import FORM_STATES from "../../../../helpers/formStates";
export default function ExcelDocument({
  currentPageIndex,
  currentHeaderIndex,
  tableActive,
  documentMetadata,
  documentTables,
  formState,
  onSelectedCell,
  setSelectedRentRollCell,
  selected,
  setDocumentMetadata,
  rentRoll
}) {
  const [isOpen, setIsOpen] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const [hidePopup] = useLocalStorage("hidePopup", hidePopup);
  const rentRollTableData = useRentRollTableData();
  const rentRolls = rentRoll.filter(
    ({ doc_page_id }) => doc_page_id === currentPageIndex
  );
  const {
    ui: { rentRollHeight }
  } = useSplitPanelV2Context();
  const handleClose = () => {
    setIsOpen(!isOpen);
    reactLocalStorage.set("hidePopup", isChecked);
  };
  const handleCheckbox = e => {
    setIsChecked(e.target.checked);
  };

  const excelTableData = useExcelTableData(documentTables);
  const { tableData, columns } = excelTableData[currentPageIndex] || {};

  const selectedHeaderColumnIndex = useMemo(() => {
    if (!selected || !tableData || !tableData.length || !documentMetadata)
      return;

    const { headerIndex, rowIndex, columnIndex } = computeIndexesFromKey(
      selected
    );

    const isHeader =
      Array.isArray(documentMetadata[headerIndex].rowIds) &&
      documentMetadata[headerIndex].rowIds.includes(rowIndex);

    return isHeader ? columnIndex : null;
  }, [selected, documentMetadata]);

  function setHeaderRowIndex({ headerIndex, rowIndex }) {
    documentMetadata[headerIndex].rowIds = [rowIndex];
    setDocumentMetadata([...documentMetadata]);
  }

  function addHeaderRowIndex({ headerIndex, rowIndex }) {
    const rowIds = documentMetadata[headerIndex].rowIds;
    if (rowIds.length < maxHeaderRows) {
      rowIds.push(rowIndex);
      rowIds.sort();
      setDocumentMetadata([...documentMetadata]);
    }
  }

  useEffect(() => {
    // scroll to selected. highlight row and column if selected is header
  }, [selected]);
  return (
    <div
      className="SplitPanelV2__Document SplitPanelV2__ExcelDocument"
      style={{ height: tableActive ? `${100 - rentRollHeight}%` : "100%" }}
    >
      {tableActive && isOpen && !hidePopup && (
        <HeaderLegendPopup
          header={<b>We detected a table header</b>}
          content={
            <>
              <p>
                Use the following controls to adjust and select up to 3 rows as
                the header.
              </p>
              <button className="HeaderLegendPopup__body-expand-btn">
                &#43;
              </button>{" "}
              <span>Expand header</span>
              <button className="HeaderLegendPopup__body-set-btn">
                &gt;
              </button>{" "}
              <span>Select new header</span>
            </>
          }
          handleClose={handleClose}
          handleCheckbox={handleCheckbox}
        />
      )}
      <div className="SplitPanelV2__ExcelDocument-inner">
        <AutoResizer>
          {({ height, width }) => (
            <BaseTable
              ignoreFunctionInColumnCompare={false}
              classPrefix="ExcelTable"
              className={tableActive ? "ExcelTable__active" : ""}
              data={tableData}
              fixed={true}
              headerHeight={0}
              height={height}
              estimatedRowHeight={32}
              width={width}
            >
              {columns.map(column => {
                const { headerIndex } = computeIndexesFromKey(column.key);
                return (
                  <Column
                    key={column.key}
                    dataKey={column.key}
                    addHeaderRowIndex={addHeaderRowIndex}
                    align="center"
                    cellRenderer={CellRenderer}
                    className={props =>
                      cellClassName({
                        headers: documentMetadata[headerIndex].headers,
                        currentPageIndex,
                        currentHeaderIndex,
                        documentMetadata,
                        formState,
                        ...props
                      })
                    }
                    tableActive={tableActive}
                    formState={formState}
                    headerRowIndexes={
                      documentMetadata[headerIndex].rowIds || []
                    }
                    headers={documentMetadata[headerIndex].headers}
                    onClick={(documentKey, rowIndex, columnIndex) => {
                      const rentRollHeaders = rentRollTableData[
                        currentPageIndex
                      ].headers.filter(h => h.columnIndex > -1);
                      if (
                        rentRollHeaders.length &&
                        formState === FORM_STATES.DATA
                      ) {
                        const rentRollColumnIndex = rentRollHeaders.findIndex(
                          h =>
                            [
                              documentMetadata[headerIndex].headers[columnIndex]
                                .id,
                              documentMetadata[headerIndex].headers[columnIndex]
                                .key
                            ].includes(h.key) && h.isMapped
                        );
                        const rentRollRowIndex = rentRolls.findIndex(
                          ({ doc_row_ids }) => doc_row_ids[0] === rowIndex
                        );
                        if (rentRollRowIndex > -1 && rentRollColumnIndex > -1) {
                          setSelectedRentRollCell(
                            `${rentRollRowIndex}-${rentRollColumnIndex}`
                          );
                          onSelectedCell(documentKey);
                        }
                      }
                    }}
                    selectedCell={selected}
                    selectedHeaderColumnIndex={selectedHeaderColumnIndex}
                    setHeaderRowIndex={setHeaderRowIndex}
                    width={column.computeWidth(width)}
                    {...column}
                  />
                );
              })}
            </BaseTable>
          )}
        </AutoResizer>
      </div>
    </div>
  );
}

ExcelDocument.propTypes = {
  setSelectedRentRollCell: PropTypes.func.isRequired,
  currentPageIndex: PropTypes.number.isRequired,
  currentHeaderIndex: PropTypes.number.isRequired,
  tableActive: PropTypes.bool,
  documentMetadata: PropTypes.arrayOf(
    PropTypes.shape({
      rowIds: PropTypes.arrayOf(PropTypes.number).isRequired,
      headers: PropTypes.array.isRequired
    }).isRequired
  ),
  documentTables: PropTypes.arrayOf(
    PropTypes.shape({
      headerIndex: PropTypes.number.isRequired,
      pageIndex: PropTypes.number.isRequired,
      tableIndex: PropTypes.number.isRequired
    })
  ),
  formState: PropTypes.string.isRequired,
  onSelectedCell: PropTypes.func.isRequired,
  selected: PropTypes.string,
  setDocumentMetadata: PropTypes.func.isRequired,
  rentRoll: PropTypes.array.isRequired
};

function cellClassName({
  documentMetadata,
  currentHeaderIndex,
  rowData,
  column: { columnIndex, selectedCell, headerRowIndexes },
  formState
}) {
  if (columnIndex === -1) {
    return "ExcelTable__row-cell--header-actions";
  }

  const { hasWarning, key, hasError, isCorrected } = rowData.data[columnIndex];

  const { rowIndex } = computeIndexesFromKey(key);
  const isHeader = headerRowIndexes.includes(rowIndex);
  // we want isMapped to be based on the rent roll value on the review data tab
  // and canCellBeMarkedAsMapped on the map header tab
  const isMapped =
    formState === FORM_STATES.HEADERS
      ? canCellBeMarkedAsMapped(
          rowData.data[columnIndex].isMapped,
          documentMetadata[currentHeaderIndex],
          columnIndex,
          rowIndex
        )
      : rowData.data[columnIndex].isMapped;

  return cx({
    "ExcelTable__row-cell--warning": hasWarning,
    "ExcelTable__row-cell--error": hasError,
    "ExcelTable__row-cell--corrected": isCorrected,
    "ExcelTable__row-cell--mapped": isMapped,
    "ExcelTable__row-cell--selected": key === selectedCell,
    "ExcelTable__row-cell--header": isHeader,
    "ExcelTable__row-cell--column-highlighted": documentMetadata[
      currentHeaderIndex
    ].selectedColumns.has(columnIndex)
  });
}
