import moment from "moment";
import { path } from "ramda";
import { parseLeaseDate } from "server/middleware/api/helpers/parsingHelper";

// Exception types
export const OCR_WARNING = "OCR_WARNING";
export const LOW_PREDEF_CONFIDENCE = "LOW_PREDEF_CONFIDENCE";
export const NO_PREDEF_MAPPING = "NO_PREDEF_MAPPING";
export const LEASE_START_DATE_BEFORE_END_DATE =
  "LEASE_START_DATE_BEFORE_END_DATE";
export const LEASE_DATE_LONGER_THAN_TEN = "LEASE_DATE_LONGER_THAN_TEN";
export const EFFECTIVE_DATE = "EFFECTIVE_DATE";
export const CELL_VALUE_REQUIRED = "CELL_VALUE_REQUIRED";
export const INTEGRATION_DATA_INVALID = "INTEGRATION_DATA_INVALID";

// Exception status
export const DELETED = "DELETED";

// Effective Date status
export const MANUAL_EFFECTIVE_DATE = "MANUAL_EFFECTIVE_DATE";
export const IMPLICIT_EFFECTIVE_DATE = "IMPLICIT_EFFECTIVE_DATE";
export const NO_GUESS_EFFECTIVE_DATE = "NO_GUESS_EFFECTIVE_DATE";

export const exceptionsConfig = {
  [OCR_WARNING]: { label: "Value is Unclear" },
  [LOW_PREDEF_CONFIDENCE]: { label: "Predefined Value is Unclear" },
  [NO_PREDEF_MAPPING]: { label: "No Predefined Value Found" },
  [LEASE_START_DATE_BEFORE_END_DATE]: {
    label: "The Lease Start Date should be set before the Lease End Date"
  },
  [LEASE_DATE_LONGER_THAN_TEN]: {
    label: "The Lease is longer than 10 years"
  },
  [CELL_VALUE_REQUIRED]: { label: "Value is Required" },
  [INTEGRATION_DATA_INVALID]: {
    label: "Data does not match formatting guidelines"
  }
};

export const exceptionsDescription = (type, value, pi, predef, fieldLabel) => {
  switch (type) {
    case OCR_WARNING:
      return `A cell with ${
        value ? `the value ${value}` : `an undefined value`
      } on page ${pi + 1} needs verification.`;
    case LOW_PREDEF_CONFIDENCE:
      return `We need to verify that ${
        value ? `the value "${value}"` : `an undefined value`
      } on page ${pi + 1} matches the predefined value ${
        predef ? `"${predef}".` : `.`
      }`;
    case NO_PREDEF_MAPPING:
      return `A cell with ${
        value ? `the value "${value}"` : `an undefined value`
      } on page ${pi + 1} needs a predefined value.`;
    case LEASE_START_DATE_BEFORE_END_DATE:
      return `The Lease Start Date should be set before the Lease End Date.`;
    case LEASE_DATE_LONGER_THAN_TEN:
      return `The Lease is longer than 10 Years.`;
    case CELL_VALUE_REQUIRED:
      return `Value in "${fieldLabel}" is required.`;
    case INTEGRATION_DATA_INVALID:
      return `A cell with ${
        value ? `the value "${value}"` : `an undefined value`
      } on page ${pi + 1} does not match formatting guidelines.`;
    default:
      return;
  }
};

export function doesExceptionExist(currentKey, currentExceptionsByLocation) {
  return (
    currentExceptionsByLocation[currentKey] &&
    currentExceptionsByLocation[currentKey].status !== DELETED
  );
}

export function currentExceptionIsDeleted(
  currentKey,
  currentExceptionsByLocation
) {
  return (
    currentExceptionsByLocation[currentKey] &&
    currentExceptionsByLocation[currentKey].status === DELETED
  );
}

export function isExcludedRow(row, excludedRows, skipRowsWithBlank) {
  for (let ci = 0; ci < excludedRows.length; ci++) {
    if (
      excludedRows[ci] &&
      excludedRows[ci].length > 0 &&
      (excludedRows[ci].includes(row[ci].text.toLowerCase()) ||
        excludedRows[ci].includes(row[ci].text) ||
        excludedRows[ci].some(excludedValue => {
          return row[ci].text
            .trim()
            .toLowerCase()
            .includes(excludedValue);
        }))
    ) {
      return true;
    } else if (
      excludedRows[ci] &&
      excludedRows[ci].length === 0 &&
      skipRowsWithBlank[ci] &&
      row[ci].text === ""
    ) {
      return true;
    }
  }

  return false;
}

export function exceptionDoesNotExist(
  currentKey,
  currentExceptionsByLocation,
  isMapped,
  row,
  excludedRows,
  skipRowsWithBlank
) {
  return (
    !doesExceptionExist(currentKey, currentExceptionsByLocation) &&
    isMapped &&
    !isExcludedRow(row, excludedRows, skipRowsWithBlank)
  );
}

export function exceptionExistsAsDeleted(
  currentKey,
  currentExceptionsByLocation,
  isMapped,
  row,
  excludedRows,
  skipRowsWithBlank
) {
  return (
    currentExceptionIsDeleted(currentKey, currentExceptionsByLocation) &&
    isMapped &&
    !isExcludedRow(row, excludedRows, skipRowsWithBlank)
  );
}

export function exceptionIsNowUnmaped(
  currentKey,
  currentExceptionsByLocation,
  isMapped,
  row,
  excludedRows,
  skipRowsWithBlank
) {
  return (
    doesExceptionExist(currentKey, currentExceptionsByLocation) &&
    (!isMapped || isExcludedRow(row, excludedRows, skipRowsWithBlank))
  );
}

export function startLeaseDateIsAfterEndLeaseDate(row, headers) {
  const headerObjs = headers.headers;
  const headerArray = headerObjs.map(header => header.id);

  if (
    headerArray.includes("lease_start_date") &&
    headerArray.includes("lease_end_date")
  ) {
    const startIdx = headerArray.indexOf("lease_start_date");
    const endIdx = headerArray.indexOf("lease_end_date");

    const start = moment(row[startIdx].text);
    const end = moment(row[endIdx].text);

    return end.diff(start) < 0 && !isNaN(end.diff(start));
  }

  return false;
}

export function leaseDateIsLongerThanTenYears(row, headers) {
  const headerObjs = headers.headers;
  const headerArray = headerObjs.map(header => header.id);

  if (
    headerArray.includes("lease_start_date") &&
    headerArray.includes("lease_end_date") &&
    !startLeaseDateIsAfterEndLeaseDate(row, headers)
  ) {
    const startIdx = headerArray.indexOf("lease_start_date");
    const endIdx = headerArray.indexOf("lease_end_date");

    const start = moment(row[startIdx].text);
    const end = moment(row[endIdx].text);

    const expectedTenYear = start.add(10, "year");
    return end.isAfter(expectedTenYear);
  }

  return false;
}

export function getLeaseStartDateIndex(headers) {
  const headerObjs = headers.headers;
  const headerArray = headerObjs.map(header => header.id);

  return headerArray.includes("lease_start_date")
    ? headerArray.indexOf("lease_start_date")
    : undefined;
}

export function getLeaseEndDateIndex(headers) {
  const headerObjs = headers.headers;
  const headerArray = headerObjs.map(header => header.id);

  return headerArray.includes("lease_end_date")
    ? headerArray.indexOf("lease_end_date")
    : undefined;
}

export function getCurrentExceptionsByLocation(existingExceptions) {
  let currentExceptionsByLocation = {};

  for (let exceptionIdx in existingExceptions) {
    const { location } = existingExceptions[exceptionIdx];
    if (location) {
      let locationString;
      if (location.start) {
        locationString = `${location.start.pi}-${location.start.ti}-${location.start.ri}-${location.start.ci}`;
      } else {
        locationString = `${location.pi}-${location.ti}-${location.ri}-${location.ci}`;
      }
      currentExceptionsByLocation[locationString] =
        existingExceptions[exceptionIdx];
    }
  }

  return currentExceptionsByLocation;
}

export function doesExceptionExistByType(
  currentExceptionsByLocation,
  location,
  type
) {
  const { pi, ti, ri, ci } = location;
  return (
    currentExceptionsByLocation &&
    currentExceptionsByLocation[`${pi}-${ti}-${ri}-${ci}`] &&
    currentExceptionsByLocation[`${pi}-${ti}-${ri}-${ci}`].type === type
  );
}

export function extendExceptionProps(exception) {
  return {
    ...exception,
    label: exceptionsConfig[exception.type].label,
    description: exceptionsDescription(
      exception.type,
      exception.value ? path(["value", "value"], exception) : undefined,
      exception.location.pi,
      exception.value ? path(["value", "predef"], exception) : undefined,
      exception.value ? path(["value", "fieldLabel"], exception) : undefined
    )
  };
}

export function isFieldValueValid(fieldName, fieldValue) {
  if (["lease_end_date", "lease_start_date"].includes(fieldName)) {
    const formatedValue = parseLeaseDate(fieldValue);
    if (formatedValue) {
      return true;
    } else {
      return false;
    }
  }
  return true;
}
