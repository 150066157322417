import { Component } from "react";
import PropTypes from "prop-types";

import PdfDocumentView from "./PdfDocumentView";
import ExcelDocumentView from "./ExcelDocumentView";
import { documentFileType } from "server/middleware/api/helpers/documents.js";

class DocumentView extends Component {
  render() {
    const {
      fileExtension,
      deletedRows,
      tableRefs,
      handleScrollToCell,
      selectedDocumentCell,
      updateSelectedDocumentCell,
      updateSelectedRentRollCell,
      propertyType
    } = this.props;
    switch (fileExtension) {
      case documentFileType.PDF:
      case documentFileType.PNG:
      case documentFileType.JPG:
      case documentFileType.JPEG:
        return (
          <PdfDocumentView
            deletedRows={deletedRows}
            tableRefs={tableRefs}
            handleScrollToCell={handleScrollToCell}
            selectedDocumentCell={selectedDocumentCell}
            updateSelectedDocumentCell={updateSelectedDocumentCell}
            updateSelectedRentRollCell={updateSelectedRentRollCell}
            propertyType={propertyType}
          />
        );
      case documentFileType.XLS:
      case documentFileType.XLSX:
      case documentFileType.XLSM:
      case documentFileType.CSV:
        return (
          <ExcelDocumentView
            deletedRows={deletedRows}
            handleScrollToCell={handleScrollToCell}
            selectedDocumentCell={selectedDocumentCell}
            propertyType={propertyType}
          />
        );
      default:
        // eslint-disable-next-line no-console
        console.error("Invalid file extension");
        return null;
    }
  }
}

DocumentView.propTypes = {
  fileExtension: PropTypes.string,
  deletedRows: PropTypes.array,
  tableRefs: PropTypes.object,
  handleScrollToCell: PropTypes.func,
  selectedDocumentCell: PropTypes.string,
  updateSelectedDocumentCell: PropTypes.func,
  updateSelectedRentRollCell: PropTypes.func,
  propertyType: PropTypes.string
};

export default DocumentView;
