import useSWR from "swr";
import updateData from "../helpers/updateData";
import queryString from "query-string";
import fetchData from "../helpers/fetchData";
import useSplitPanelV2Context from "./useSplitPanelV2Context";
import useDocument from "./useDocument";

export default function usePredefSuggestions(value, dbfield) {
  const { documentId, widgetAuth } = useSplitPanelV2Context();
  const { updateDocumentCell } = useDocument();

  // field = space_type || tenent_name
  const query = { value: value, field: dbfield };

  const { data, error, mutate } = useSWR(
    `/api/predef-fields/suggest/${documentId}?${queryString.stringify(query)}`,
    fetchData(widgetAuth)
  );

  async function addNewPredefAndUpdateField(
    initialValue,
    fieldType,
    fieldLocation
  ) {
    const body = { initialValue, fieldType };

    const updatedFieldInfo = await updateData({
      path: `/api/predef-fields/create/${documentId}`,
      method: "post",
      data: { body },
      widgetAuth
    });

    const { value, id } = updatedFieldInfo.predefValue;

    const field = {
      ...fieldLocation,
      text: value,
      predefValueId: id
    };

    await updateDocumentCell(field);
  }

  return {
    predefSuggestions: data ? data.predefValues : null,
    addNewPredefAndUpdateField,
    error,
    mutate,
    isLoading: !error && !data
  };
}
