import { useState } from "react";
import { Dropdown, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";

export default function VacantCell(props) {
  const {
    column: { dataKey, updateVacantFlagCell },
    rowData
  } = props;
  const [selectedValue, setSelectedValue] = useState(
    () => rowData[dataKey]?.text || "N"
  );
  const { columnIndex, pageIndex, rowIndex, tableIndex } =
    rowData[dataKey] || {};
  const [open, setOpen] = useState(false);

  const options = [
    { key: "Y", label: "Yes" },
    { key: "N", label: "No" }
  ];

  const optionsObj = {
    Y: "Yes",
    N: "No"
  };

  const handleChange = async e => {
    if (e.key === selectedValue) {
      return;
    }
    const field = {
      page: pageIndex,
      table: tableIndex,
      row: rowIndex,
      col: columnIndex,
      rowId: rowData.rowId,
      value: e.key
    };
    setSelectedValue(e.key);
    setOpen(false);
    await updateVacantFlagCell(field);
  };

  return (
    <Dropdown
      menu={{
        items: options,
        onClick: handleChange
      }}
      trigger={["click"]}
      onOpenChange={flag => setOpen(flag)}
      open={open}
    >
      <Space>
        {optionsObj[selectedValue]}
        <DownOutlined />
      </Space>
    </Dropdown>
  );
}
