import IconButton from "ui/components/shared/IconButton";
import PropTypes from "prop-types";

export const ExcelPopup = props => {
  return (
    <div className="HeaderLegendPopup__popup-box">
      <div className="HeaderLegendPopup__box">
        <IconButton
          onClick={props.handleClose}
          src={require("ui/images/x-icon.svg")}
          className="HeaderLegendPopup__close-icon"
        />
        <div className="HeaderLegendPopup__header">{props.header}</div>
        <div className="HeaderLegendPopup__body">{props.content}</div>
        <div className="HeaderLegendPopup__checkbox">
          <input type="checkbox" onChange={props.handleCheckbox} />
          <span>Don't show me again</span>
        </div>
      </div>
    </div>
  );
};

ExcelPopup.propTypes = {
  handleClose: PropTypes.func,
  header: PropTypes.object,
  content: PropTypes.object,
  handleCheckbox: PropTypes.func
};

export default ExcelPopup;
