const fields = [
  {
    tag: "name",
    display_name: "Name",
    type: "text",
    step: 2
  },
  {
    tag: "property_type",
    display_name: "Property Type",
    type: "text",
    step: 2
  },
  {
    tag: "one_year_asking_rent",
    display_name: "One Year Rent",
    type: "number",
    step: 2
  },
  {
    tag: "one_year_vacancy",
    display_name: "One Year Vacancy",
    type: "number",
    step: 2
  },
  {
    tag: "parcel_number",
    display_name: "Parcel Number",
    type: "text",
    step: 2
  },
  {
    tag: "street_1",
    display_name: "Street 1",
    type: "text",
    step: 1
  },
  {
    tag: "street_2",
    display_name: "Street 2",
    type: "text",
    step: 1
  },
  {
    tag: "county",
    display_name: "County",
    type: "text",
    step: 1
  },
  {
    tag: "city",
    display_name: "City",
    type: "text",
    step: 1
  },
  {
    tag: "state",
    display_name: "State",
    type: "state",
    step: 1
  },
  {
    tag: "postal_code",
    display_name: "Postal Code",
    type: "text",
    step: 1
  },
  {
    tag: "country",
    display_name: "Country",
    type: "country",
    step: 1
  },
  {
    tag: "portfolio",
    display_name: "Portfolio",
    type: "portfolio",
    step: 1
  },
  {
    tag: "year_built",
    display_name: "Year Built",
    type: "number",
    step: 2
  },

  {
    tag: "size_sqft",
    display_name: "Square Footage",
    type: "number",
    step: 2
  },

  {
    tag: "floors",
    display_name: "Floors",
    type: "number",
    step: 2
  }
];

export const industrialPropertyType = { id: "INDUSTRIAL", name: "Industrial" };
export const multiFamilyPropertyType = {
  id: "MULTI_FAMILY",
  name: "Multi-Family"
};
export const officePropertyType = { id: "OFFICE", name: "Office" };
export const retailPropertyType = { id: "RETAIL", name: "Retail" };
export const hotelPropertyType = { id: "HOTEL", name: "Hotel" };
export const affordableHousingType = {
  id: "AFFORDABLE_HOUSING",
  name: "Affordable Housing"
};

export const propertyTypes = [
  industrialPropertyType,
  multiFamilyPropertyType,
  officePropertyType,
  retailPropertyType
];

export const propertyTypesCL = [
  industrialPropertyType,
  multiFamilyPropertyType,
  officePropertyType,
  retailPropertyType,
  hotelPropertyType,
  affordableHousingType
];

export function getPropertyValues(isCL = false) {
  return isCL ? propertyTypesCL : propertyTypes;
}

export function getColumns() {
  let columns = {};
  fields.map(field => (columns[field.tag] = ""));
  return columns;
}

export function getColumnObj() {
  return fields;
}

export function getLocationFields() {
  let columns = [];
  fields.map(field => {
    if (field.step === 1) {
      columns.push(field);
    }
  });

  return columns;
}
