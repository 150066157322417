export const WORKFLOW_TABLE_SELECTION = "TABLE_SELECTION";
export const WORKFLOW_CHART_OF_ACCOUNTS_SELECTION =
  "CHART_OF_ACCOUNTS_SELECTION";
export const WORKFLOW_ACCOUNT_MAPPING = "ACCOUNT_MAPPING";
export const WORKFLOW_COLUMN_PERIODIZATION = "COLUMN_PERIODIZATION";
export const WORKFLOW_COMPLETE = "COMPLETE";
export const OPERATING_STATEMENT = "OPERATING_STATEMENT";

export const convertTemplateToSelectOptions = template => {
  if (!template.rows?.length) return;

  return template.rows.map(tempRow =>
    tempRow.rows?.length
      ? {
          label: tempRow.name,
          options: convertTemplateToSelectOptions(tempRow)
        }
      : {
          ...tempRow,
          label: tempRow.name,
          nameIdx: tempRow.name_idx,
          category: tempRow.name_idx
        }
  );
};
