import { useEffect, useMemo, useRef, useState } from "react";
import cx from "classnames";
import moment from "moment";
import AuthenticatedRoute from "ui/components/routing/AuthenticatedRoute";
import useSplitPanelV2Context from "./hooks/useSplitPanelV2Context";
import Header from "./components/Header/Header";
import LeftSidebar from "./components/LeftSidebar/LeftSidebar";
import Document from "./components/Document/Document";
import RentRoll from "./components/RentRoll/RentRoll";
import LoadingSkeleton from "./components/LoadingSkeleton/LoadingSkeleton";
import useProperty from "./hooks/useProperty";
import useDocument from "./hooks/useDocument";
import useExceptions from "./hooks/useExceptions";
import useRentRoll from "./hooks/useRentRoll";
import useRentRollFieldsByPropertyType from "./hooks/useRentRollFieldsByPropertyType";
import useDocumentTables from "./hooks/useDocumentTables";
import useDocumentMetadata from "./hooks/useDocumentMetadata";
import FORM_STATES from "./helpers/formStates";
import { scrollToCellOnRentRoll } from "./helpers/navigation";
import ExceptionsSidebar from "./components/ExceptionsSidebar/ExceptionsSidebar";
import IgnoreTablePopup, {
  ALL_TABLES,
  ONE_TABLE
} from "./popovers/ignoreTable/IgnoreTablePopup";
import {
  configureExceptions,
  getTotalExceptions
} from "ui/components/rentRoll/splitPanel/helpers/exceptions";
import { exceptionErrorMessages } from "helpers/exceptions";
import WarningMessage from "./popovers/warningMessage/WarningMessage";
import rentRollHasManualAddedRow from "./helpers/rentRollHasManualAddedRow";

function SplitPanelV2() {
  const {
    isWidgetAuthorized,
    userRole,
    groupName,
    documentId
  } = useSplitPanelV2Context();
  const [docId, saveDocId] = useState(documentId);
  const [propertyData, setPropertyData] = useState({});
  // NOTE: these requests will quite likely need to be paginated, as the document data can get quite large.
  // this will depend on the page of the doc, and there is not yet an api to support this.
  const {
    document,
    updateTemplate,
    ignoreSingleTable,
    isLoading: isDocumentLoading,
    mutate: mutateDocument
  } = useDocument();
  const {
    exceptions,
    isLoading: isExceptionsLoading,
    mutate: mutateExceptions
  } = useExceptions();
  const {
    property,
    isLoading: isPropertyLoading,
    mutate: mutateProperty
  } = useProperty();
  const {
    rentRoll,
    isLoading: isRentRollLoading,
    mutate: mutateRentRoll,
    markAsComplete
  } = useRentRoll();
  const {
    isLoading: isRentRollFieldsByPropertyTypeLoading,
    mutate: mutateRentRollFieldsByPropertyType
  } = useRentRollFieldsByPropertyType();

  async function refreshData() {
    await Promise.all([
      mutateDocument(),
      mutateExceptions(),
      mutateProperty(),
      mutateRentRoll(),
      mutateRentRollFieldsByPropertyType()
    ]);
  }

  const [errors, setErrors] = useState([]);
  const [requiredFields, setRequiredFields] = useState([]);
  const [formState, setFormState] = useState(FORM_STATES.HEADERS);
  const [selected, setSelected] = useState(null);
  const [selectedRentRollCell, setSelectedRentRollCell] = useState("0-0");
  const [headerData, setHeaderData] = useState([]);
  const [tableRef, setTableRef] = useState(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [isExceptionsSidebarOpen, setIsExceptionsSidebarOpen] = useState(false);
  const [expandErrorList, setExpandErrorList] = useState(false);
  const [isIgnoreTablePopupOpen, setIsIgnoreTablePopupOpen] = useState(false);
  const [ignoreTablePopupOption, setIgnoreTablePopupOption] = useState(null);
  const [warning, setWarning] = useState({ message: "", details: [] });
  const [warningOpen, setWarningOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const initialHeaderData = useRef([]);
  const [lastHeaderMapping, setLastHeaderMapping] = useState([]);
  const [headersUnchanged, setHeadersUnchanged] = useState(true);
  const [disablePublishButton, setDisablePublishButton] = useState(false);
  const {
    documentMetadata,
    resetDocumentMetadata,
    setDocumentMetadata
  } = useDocumentMetadata();
  const {
    activeDocumentTables,
    documentTables,
    currentHeaderIndex,
    currentPageIndex,
    hasNextActiveTable,
    hasPrevActiveTable,
    setActiveDocumentTables,
    setCurrentHeaderIndex,
    setCurrentPageIndex
  } = useDocumentTables();

  const isLoading =
    isDocumentLoading ||
    isExceptionsLoading ||
    isPropertyLoading ||
    isRentRollFieldsByPropertyTypeLoading ||
    isRentRollLoading;

  async function completeDoc() {
    const exceptionsConfig = configureExceptions(
      exceptions,
      document,
      rentRoll,
      groupName,
      property
    );
    const totalExceptions = getTotalExceptions(exceptionsConfig);

    if (totalExceptions.blocking > 0) {
      setWarning({
        ...warning,
        message: exceptionErrorMessages.RESOLVE_ERRORS,
        details: []
      });
      setIsExceptionsSidebarOpen(true);
      setExpandErrorList(true);
      setWarningOpen(true);
      return;
    }

    if (!document.effective_date) {
      setWarning({
        ...warning,
        message: exceptionErrorMessages.EFFECTIVE_DATE_MISSING,
        details: []
      });
      setWarningOpen(true);
      return;
    }
    setIsSubmitting(true);

    try {
      await markAsComplete(document.id, document.property_id);
    } catch (error) {
      if (error.cause.message === exceptionErrorMessages.CPM_DATA_INVALID) {
        const message = error.cause.message;
        const details = JSON.parse(error.cause.details);
        setWarning({ message, details });
      } else {
        setWarning({ message: "An Error Occured", details: [] });
      }
      setWarningOpen(true);
    }

    await refreshData();
    setIsSubmitting(false);
  }

  async function saveAndContinue() {
    switch (formState) {
      case FORM_STATES.HEADERS:
        return saveHeadersStep();
      case FORM_STATES.DATA:
        return saveDataStep();
    }
  }

  const activeDocumentTable = useMemo(
    () => activeDocumentTables[currentHeaderIndex],
    [currentHeaderIndex, activeDocumentTables]
  );

  useEffect(() => {
    initialHeaderData.current = [];
  }, [currentHeaderIndex]);

  useEffect(() => {
    if (!headerData.length) return;
    if (docId !== documentId) {
      saveDocId(documentId);
      initialHeaderData.current = [];
    }
    if (!initialHeaderData.current.length) {
      initialHeaderData.current = headerData.slice().map(h => ({ ...h }));
    }
    if (!checkHeaderMappingUnchanged()) {
      setHasUnsavedChanges(true);
    } else if (hasUnsavedChanges) {
      setHasUnsavedChanges(false);
    }
    validateHeaderData(headerData); //->validate on every change
    setHeadersUnchanged(checkHeaderMappingUnchanged());
  }, [headerData, docId]);

  useEffect(() => {
    documentMetadata &&
      currentHeaderIndex >= 0 &&
      documentMetadata[currentHeaderIndex].selectedColumns.clear();
  }, [currentHeaderIndex, formState]);

  useEffect(() => {
    if (formState === FORM_STATES.HEADERS) {
      setSelected(null);
    } else {
      setDocumentMetadata([...documentMetadata]);
    }
  }, [formState]);

  useEffect(() => {
    if (document?.status === "COMPLETED") {
      checkForUpdateSincePublished();
    }
  }, [document, rentRoll]);

  const checkForUpdateSincePublished = () => {
    const lastUpdated = moment(document?.updated_at);
    const lastPublished = moment(document?.last_published_at);
    setDisablePublishButton(lastUpdated.isSame(lastPublished));
  };

  const validateHeaderData = () => {
    const errors = [];
    const seen = new Set();
    const required = [];
    requiredFields.forEach(field => required.push(field.key));
    let containsMappings = false;
    headerData.forEach((header, headerIndex) => {
      if (header.isMapped) {
        containsMappings = true;
      }

      if (header.isMapped && header.id === "") {
        // If the mapping is blank
        errors.push({
          headerIndex,
          message: "Please select a value or unmap this header"
        });
      }
      if (header.id && header.isMapped) {
        if (seen.has(header.id)) {
          // If there is a duplicate value
          errors.push({
            headerIndex,
            message: `Duplicate value for ${header.id}`
          });
        }
        seen.add(header.id);
      }
    });

    // if any required fields not in seen, push error
    required.forEach(field => {
      if (field === "lease_start_date" || field === "lease_end_date") {
        if (!seen.has(field) && !seen.has("lease_dates")) {
          errors.push({
            message: `Missing required field mapping: ${field}`
          });
        }
      } else if (!seen.has(field)) {
        errors.push({ message: `Missing required field mapping: ${field}` });
      }
    });

    if (!containsMappings && activeDocumentTable) {
      errors.push({
        message: `No header mappings found`
      });
    }
    setErrors(errors);
  };

  /**Checks Initial or Last header against current mappings for changes, returns true/unchanged false/changed*/
  const checkHeaderMappingUnchanged = () => {
    let unchanged = true;

    if (lastHeaderMapping.length > 0) {
      unchanged = lastHeaderMapping.every(lhd => {
        let header = headerData.find(hd => hd.columnIndex === lhd.columnIndex);
        if (!header) return true;
        // Return early if mappingas are both false, otherwise check
        // cover edge case where you mapped a unmapped header, change the mapping, then remove that mapping
        // isMapped wil be false for both but id will be different
        return header.isMapped === false && lhd.isMapped === false
          ? true
          : header.isMapped === lhd.isMapped && lhd.id === header.id;
      });
    } else if (initialHeaderData?.current.length > 0) {
      unchanged = initialHeaderData.current.every(initHeader => {
        let header = headerData.find(
          hd => hd.columnIndex === initHeader.columnIndex
        );
        if (!header) return true;

        return header.isMapped === false && initHeader.isMapped === false
          ? true
          : header.isMapped === initHeader.isMapped &&
              initHeader.id === header.id;
      });
    }

    return unchanged;
  };

  async function saveHeadersStep() {
    //double check if table is still considered active
    //if not -> user should be directed to the next active table
    if (document.status === "COMPLETED" && headersUnchanged) {
      setFormState(FORM_STATES.DATA);
      return;
    }

    if (!activeDocumentTable) {
      setFormState(FORM_STATES.HEADERS);
      setHasUnsavedChanges(false);
      goToNextActiveTable();
      return;
    }

    if (errors.length) {
      return;
    }

    const { multipleLineGrouping, advancedRules } = (documentMetadata &&
      currentHeaderIndex >= 0 &&
      documentMetadata[currentHeaderIndex]) || {
      multipleLineGrouping: null,
      advancedRules: null
    };

    const hasManualRow = rentRollHasManualAddedRow(rentRoll);
    // headerData.map( h => { return {...h} })
    // This method is used to break JS copy by reference

    if (headersUnchanged && hasManualRow) {
      setLastHeaderMapping(
        headerData.map(h => {
          return { ...h };
        })
      );
      setHasUnsavedChanges(false);
      setFormState(FORM_STATES.DATA);
    } else {
      let confirmed = true;
      confirmed = hasManualRow
        ? window.confirm(
            "Header mappings have changed. Any manually added rows will be removed."
          )
        : false;

      if (hasManualRow && confirmed) {
        setLastHeaderMapping(
          headerData.map(h => {
            return { ...h };
          })
        );
        await updateTemplate({
          templateData: headerData,
          metaName:
            currentHeaderIndex >= 0 &&
            documentTables[currentHeaderIndex].metaName,
          headerIndex: currentHeaderIndex,
          multipleLineGrouping,
          advancedRules
        });

        await refreshData();

        setFormState(FORM_STATES.DATA);
        setHasUnsavedChanges(false);
      } else if (!hasManualRow) {
        setLastHeaderMapping(
          headerData.map(h => {
            return { ...h };
          })
        );
        await updateTemplate({
          templateData: headerData,
          metaName:
            currentHeaderIndex >= 0 &&
            documentTables[currentHeaderIndex].metaName,
          headerIndex: currentHeaderIndex,
          multipleLineGrouping,
          advancedRules
        });

        await refreshData();

        setHasUnsavedChanges(false);
        setFormState(FORM_STATES.DATA);
      }
    }
  }

  async function saveDataStep() {
    // validate and complete table data
    setHasUnsavedChanges(false);
    setFormState(FORM_STATES.HEADERS);
    goToNextActiveTable();
    setLastHeaderMapping([]); // -> not sure if this is the right move
  }

  const handleTableRef = ref => {
    setTableRef(ref);
  };

  const handleScrollToCell = (type, documentKey, rentRollKey, tableRefs) => {
    switch (type) {
      case "exception": {
        const [headerIndex, exceptionPageIndex] = documentKey
          .split("-")
          .map(Number);
        //set active table based on exception location
        if (headerIndex !== currentHeaderIndex) {
          setCurrentPageIndex(exceptionPageIndex);
          setCurrentHeaderIndex(headerIndex);
        }
        setSelected(documentKey);
        setSelectedRentRollCell(rentRollKey);

        break;
      }
      case "to_rent_roll": {
        scrollToCellOnRentRoll(tableRefs, documentKey);
        setSelected(documentKey);
        setSelectedRentRollCell(rentRollKey);

        break;
      }
      default: {
        // eslint-disable-next-line no-console console.error("unrecognized scroll to type");
        break;
      }
    }
  };

  function goToNextOrPreviousActiveTable() {
    hasNextActiveTable ? goToNextActiveTable() : goToPreviousActiveTable();
  }

  function goToNextActiveTable() {
    if (!hasNextActiveTable) return;

    const nextActiveHeaderIndex = activeDocumentTables.findIndex(
      (active, n) => active && n > currentHeaderIndex
    );

    const { headerIndex, pageIndex } = documentTables[nextActiveHeaderIndex];
    setCurrentHeaderIndex(headerIndex);
    setCurrentPageIndex(pageIndex);
  }

  function goToPreviousActiveTable() {
    if (!hasPrevActiveTable) return;

    const prevActiveHeaderIndex = activeDocumentTables
      .slice(0, currentHeaderIndex)
      .lastIndexOf(true);

    const { headerIndex, pageIndex } = documentTables[prevActiveHeaderIndex];
    setCurrentHeaderIndex(headerIndex);
    setCurrentPageIndex(pageIndex);
  }

  function flagUnsavedChanges(callback) {
    return args => {
      setHasUnsavedChanges(true);
      callback(args);
    };
  }

  function confirmUnsavedChanges(callback) {
    return args => {
      if (!hasUnsavedChanges) return callback(args);
      // THis will likely need to be its own confirmation dialog, rather than the browser default
      const confirmText =
        "You have unsaved changes. Press OK to discard changes and continue";
      if (!window.confirm(confirmText)) return;
      clearUnsavedChanges();
      setHasUnsavedChanges(false);
      return callback(args);
    };
  }

  function clearUnsavedChanges() {
    resetDocumentMetadata();
  }

  function goToPrevStep() {
    switch (formState) {
      case FORM_STATES.HEADERS:
        setFormState(FORM_STATES.DATA);
        goToPreviousActiveTable();
        return;
      case FORM_STATES.DATA:
        return setFormState(FORM_STATES.HEADERS);
    }
  }

  // NOTE: for these selected handlers, there is a high possibility that the page will have to be updated as well
  function handleSelectedTable({ headerIndex, pageIndex }) {
    setFormState(FORM_STATES.HEADERS);
    setCurrentPageIndex(pageIndex);
    setCurrentHeaderIndex(headerIndex);
  }

  function handleGotoPage(pageIndex) {
    setFormState(FORM_STATES.HEADERS);
    setCurrentPageIndex(pageIndex);
    setCurrentHeaderIndex(undefined);
  }

  async function checkTablesUsingTheSameTemplate(headerIndex) {
    if (documentTables[headerIndex] === undefined) return;
    const { metaName } = documentTables[headerIndex] || {};
    const tablesUsingTheSameMetaName = [];
    documentTables.forEach((obj, index) => {
      if (
        obj.metaName === metaName &&
        !obj.doNotUseTable &&
        !activeDocumentTables[index] &&
        index !== headerIndex
      ) {
        tablesUsingTheSameMetaName.push(index);
      }
    });

    if (tablesUsingTheSameMetaName.length > 1) {
      alert(
        "This table structure is used also elsewhere in the document. Other tables with this structure will also be activated."
      );

      setActiveDocumentTables(state =>
        state.map((value, index) =>
          tablesUsingTheSameMetaName.includes(index) ? true : value
        )
      );
    }
  }

  async function confirmRemovingHeaderMapping({ headerIndex }) {
    if (documentTables[headerIndex] === undefined) return;
    const { metaName } = documentTables[headerIndex] || {};

    const willUnMapHeaders = headerData.some(
      header => header.isMapped && header.id !== ""
    );

    const tablesUsingTheSameMetaName = documentTables.filter(
      obj => obj.metaName === metaName
    ).length;

    if (tablesUsingTheSameMetaName === 1) {
      setIgnoreTablePopupOption({ option: ALL_TABLES, headerIndex, metaName });
    } else {
      setIgnoreTablePopupOption({ option: ONE_TABLE, headerIndex, metaName });
    }
    if (willUnMapHeaders) {
      setIsIgnoreTablePopupOpen(true);
    } else {
      if (tablesUsingTheSameMetaName === 1) {
        deactivateTablesByMetaName({ headerIndex, metaName });
      } else {
        deactivateTablesByMetaName({ headerIndex });
      }
      setFormState(FORM_STATES.HEADERS);
      goToNextOrPreviousActiveTable();
    }

    return;
  }

  async function removeHeaderMapping(option, headerIndex, metaName) {
    if (option === ALL_TABLES) {
      deactivateTablesByMetaName({ headerIndex, metaName });
      const templateData = headerData.map(header => ({
        ...header,
        id: "none",
        isMapped: false,
        skipRowForValue: []
      }));

      setHeaderData(templateData);

      await updateTemplate({
        templateData,
        metaName,
        headerIndex,
        multipleLineGrouping: null,
        advancedRules: null
      });
    } else {
      deactivateTableByHeaderIndex({ headerIndex });
      const templateData = headerData.map(header => ({
        ...header
      }));
      setHeaderData(templateData);
      await ignoreSingleTable({ metaName, headerIndex });
    }

    if (activeDocumentTables.length - 1 === headerIndex) {
      goToPreviousActiveTable();
    }
    await refreshData();
  }

  function deactivateTablesByMetaName({ headerIndex, metaName }) {
    const indexesToChange = metaName
      ? documentTables
          .filter(table => table.metaName === metaName)
          .map(table => table.headerIndex)
      : [headerIndex];

    setActiveDocumentTables(state =>
      state.map((value, index) =>
        indexesToChange.includes(index) ? false : value
      )
    );
    setFormState(FORM_STATES.HEADERS);
  }

  function deactivateTableByHeaderIndex({ headerIndex }) {
    setActiveDocumentTables(state =>
      state.map((value, index) => (headerIndex === index ? false : value))
    );
  }

  function handleSetActiveTable({ active, headerIndex }) {
    const activate = setActiveDocumentTables(state =>
      state.map((value, index) => (index === headerIndex ? true : value))
    );
    if (active) {
      checkTablesUsingTheSameTemplate(headerIndex);
    }
    return active ? activate : confirmRemovingHeaderMapping({ headerIndex });
  }

  function handleSelectedHeaderCell(_) {
    setSelected(prev => prev);
  }

  function toggleExceptionsSidebar() {
    setIsExceptionsSidebarOpen(prev => !prev);
    setExpandErrorList(false);
  }

  function collapseErrorList() {
    setExpandErrorList(false);
  }

  if (isLoading)
    return <LoadingSkeleton isWidgetAuthorized={isWidgetAuthorized} />;

  return (
    <>
      {!isWidgetAuthorized && <Header />}
      <div
        className={cx("SplitPanelV2", {
          ["SplitPanelV2-widget"]: isWidgetAuthorized
        })}
      >
        <LeftSidebar
          activeDocumentTables={activeDocumentTables}
          currentHeaderIndex={currentHeaderIndex}
          documentTables={documentTables}
          effectiveDate={document.effective_date}
          onEffectiveDateUpdated={refreshData}
          onSelectTable={confirmUnsavedChanges(handleSelectedTable)}
          onSetActiveTable={confirmUnsavedChanges(handleSetActiveTable)}
          groupName={groupName}
          isSubmitting={isSubmitting}
        />
        <div className="SplitPanelV2__contents">
          {isIgnoreTablePopupOpen && (
            <IgnoreTablePopup
              ignoreTablePopupOption={ignoreTablePopupOption}
              setIsIgnoreTablePopupOpen={setIsIgnoreTablePopupOpen}
              removeHeaderMapping={function() {
                setFormState(FORM_STATES.HEADERS);
                goToNextOrPreviousActiveTable();
                return removeHeaderMapping(...arguments);
              }}
            />
          )}
          <Document
            onGotoPage={confirmUnsavedChanges(handleGotoPage)}
            onSelectTable={handleSelectedTable}
            activeDocumentTables={activeDocumentTables}
            currentPageIndex={currentPageIndex}
            currentHeaderIndex={currentHeaderIndex}
            selectedRentRollCell={selectedRentRollCell}
            setSelectedRentRollCell={setSelectedRentRollCell}
            documentTables={documentTables}
            formState={formState}
            onSelectedCell={setSelected}
            tableRef={tableRef}
            selected={selected}
            documentMetadata={documentMetadata}
            refreshData={refreshData}
            rentRoll={rentRoll}
            setDocumentMetadata={flagUnsavedChanges(setDocumentMetadata)}
            isSubmitting={isSubmitting}
          />
          <WarningMessage
            warning={warning}
            open={warningOpen}
            setWarning={setWarning}
            setWarningOpen={setWarningOpen}
          />
          {activeDocumentTable && (
            <RentRoll
              errors={errors}
              activeDocumentTables={activeDocumentTables}
              exceptions={exceptions}
              formState={formState}
              goToPrevStep={confirmUnsavedChanges(goToPrevStep)}
              hasNextActiveTable={hasNextActiveTable}
              hasPrevActiveTable={hasPrevActiveTable}
              setHeaderData={setHeaderData}
              rentRoll={rentRoll}
              documentMetadata={documentMetadata}
              setDocumentMetadata={setDocumentMetadata}
              onSelectedDataCell={setSelected}
              onSelectedHeaderCell={handleSelectedHeaderCell}
              property={property}
              propertyData={propertyData}
              handleScrollToCell={handleScrollToCell}
              currentHeaderIndex={currentHeaderIndex}
              currentPageIndex={currentPageIndex}
              document={document}
              documentTables={documentTables}
              saveAndContinue={saveAndContinue}
              selected={selected}
              selectedRentRollCell={selectedRentRollCell}
              setSelectedRentRollCell={setSelectedRentRollCell}
              handleRef={handleTableRef}
              setPropertyData={flagUnsavedChanges(setPropertyData)}
              userRole={userRole}
              refreshData={refreshData}
              setRequiredFields={setRequiredFields}
              completeDoc={completeDoc}
              isSubmitting={isSubmitting}
              groupName={groupName}
              disablePublishButton={disablePublishButton}
            />
          )}
        </div>
        <ExceptionsSidebar
          isOpen={isExceptionsSidebarOpen}
          expandErrorList={expandErrorList}
          collapseErrorList={collapseErrorList}
          handleToggleExceptionsSidebar={toggleExceptionsSidebar}
          exceptions={exceptions}
          document={document}
          rentRoll={rentRoll}
          handleScrollToCell={handleScrollToCell}
          formState={formState}
          groupName={groupName}
          property={property}
        />
      </div>
    </>
  );
}

export default AuthenticatedRoute(SplitPanelV2);
