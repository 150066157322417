import {
  CELL_STATUS_CORRECTED_COLOR,
  CONFIDENCE_CONFIRMED_COLOR,
  CONFIDENCE_NEEDS_REVIEW_COLOR,
  CONFIDENCE_UNMAPPED_COLOR,
  CELL_STATUS_CORRECTED,
  CONFIDENCE_NEEDS_REVIEW,
  CONFIDENCE_UNMAPPED,
  CONFIDENCE_CONFIRMED
} from "ui/components/opex/shared";
import PropTypes from "prop-types";
import SquareColorIcon from "../../DocumentView/components/SquareColorIcon/SquareColorIcon";
import { WORKFLOW_COLUMN_PERIODIZATION } from "ui/components/opex/helpers/opex";

const StatusLegend = ({ step, statusHeaderCounters }) => (
  <div className={`OpexPanelView__StatusLegend ${(step === WORKFLOW_COLUMN_PERIODIZATION) ? "BorderLeft" : ""}`}>
    <div className="Item">
      <SquareColorIcon color={CONFIDENCE_UNMAPPED_COLOR} style={{ marginLeft: ".5rem" }} />
      <span>
        Needs review {
          statusHeaderCounters[CONFIDENCE_UNMAPPED] > 0 ?
            `(${statusHeaderCounters[CONFIDENCE_UNMAPPED]})` : ""
        }
      </span>
    </div>
    <div className="Item">
      <SquareColorIcon color={CONFIDENCE_NEEDS_REVIEW_COLOR} />
      <span>
        Medium Confidence {
          statusHeaderCounters[CONFIDENCE_NEEDS_REVIEW] > 0 ?
            `(${statusHeaderCounters[CONFIDENCE_NEEDS_REVIEW]})` : ""
        }
      </span>
    </div>
    <div className="Item">
      <SquareColorIcon color={CONFIDENCE_CONFIRMED_COLOR} />
      <span>
        Confirmed by user {
          statusHeaderCounters[CONFIDENCE_CONFIRMED] > 0 ?
            `(${statusHeaderCounters[CONFIDENCE_CONFIRMED]})` : ""
        }
      </span>
    </div>
    <div className="Item">
      <SquareColorIcon color={CELL_STATUS_CORRECTED_COLOR} />
      <span>
        Changed by user {
          statusHeaderCounters[CELL_STATUS_CORRECTED] > 0 ?
            `(${statusHeaderCounters[CELL_STATUS_CORRECTED]})` : ""
        }
      </span>
    </div>
  </div>
);

export default StatusLegend;

StatusLegend.propTypes = {
  step: PropTypes.string,
  statusHeaderCounters: PropTypes.object
}
