import { useState } from "react";
import { components } from "react-select";
import CustomDropdownLabel from "./CustomDropdownLabel";

const CustomOption = props => {
  const [expanded, setExpanded] = useState(true);

  const {
    data,
    className,
    cx,
    label,
    children,
    currentMargin,
    innerProps,
    filterValue
  } = props;
  const marginLeft = currentMargin ? currentMargin + 1 : 1;
  const options = data.options;

  if (options) {
    return (
      <div
        style={{ marginLeft: `${marginLeft}rem` }}
        className={cx({ group: true }, className)}
      >
        <div>
          <CustomDropdownLabel
            label={label}
            expanded={expanded}
            onClick={setExpanded}
          />
        </div>
        <div style={{ display: expanded ? "block" : "none" }}>
          {options.map(option => (
            <CustomOption
              {...props}
              key={option.id}
              data={option}
              label={option.label}
              children={option.label}
            />
          ))}
        </div>
      </div>
    );
  }

  return !!filterValue &&
    !label.toLowerCase().includes(filterValue.toLowerCase()) ? null : (
    <components.Option
      {...props}
      data={data}
      label={label}
      innerProps={{ ...innerProps, onClick: () => props.selectOption(data) }}
    >
      {children}
    </components.Option>
  );
};

export default CustomOption;
