const BORDER_ALL = {
  border: {
    top: { style: "thin", color: { rgb: "000000" } },
    bottom: { style: "thin", color: { rgb: "000000" } }
  }
};
export const subcategoryColor = { fgColor: { rgb: "EEEEEE" } };
export const whiteFillColor = { fgColor: { rgb: "FFFFFF" } };
export const singleTab = `          `;
export const doubleTab = `                    `;
export const tripleTab = `                              `;

export const getFormattedRow = (value, fillColor, format) => {
  if (format === "metricName") {
    return {
      v: value,
      t: "s",
      s: {
        fill: fillColor,
        alignment: { horizontal: "left" },
        ...BORDER_ALL
      }
    };
  } else if (format === "numericValue") {
    return {
      v: value,
      t: "n",
      s: {
        fill: fillColor,
        numFmt: "#,##0.00",
        alignment: { horizontal: "right" },
        ...BORDER_ALL
      }
    };
  } else if (format === "boldNumericValue") {
    return {
      v: value,
      t: "n",
      s: {
        font: { bold: true },
        fill: fillColor,
        numFmt: "#,##0.00",
        alignment: { horizontal: "right" },
        ...BORDER_ALL
      }
    };
  } else if (format === "stringValue") {
    return {
      v: value,
      t: "s",
      s: {
        fill: fillColor,
        alignment: { horizontal: "right" },
        ...BORDER_ALL
      }
    };
  } else if (format === "nameNoBorders") {
    return {
      v: value,
      t: "s",
      s: {
        alignment: { horizontal: "left" }
      }
    };
  } else if (format === "numericValueNoBorders") {
    return {
      v: value,
      t: "n",
      s: {
        numFmt: "#,##0.00",
        alignment: { horizontal: "right" }
      }
    };
  } else if (format === "boldItalicized") {
    return {
      v: value,
      t: "s",
      s: {
        alignment: { horizontal: "left" },
        font: { bold: true, italic: true }
      }
    };
  } else if (format === "bold") {
    return {
      v: value,
      t: "s",
      s: {
        font: { bold: true },
        fill: fillColor,
        alignment: { horizontal: "left" },
        ...BORDER_ALL
      }
    };
  } else if (format === "boldNoBorders") {
    return {
      v: value,
      t: "s",
      s: {
        font: { bold: true },
        fill: fillColor,
        alignment: { horizontal: "left" }
      }
    };
  } else if (format === "boldNumericNoBorders") {
    return {
      v: value,
      t: "n",
      s: {
        font: { bold: true },
        fill: fillColor,
        numFmt: "#,##0.00",
        alignment: { horizontal: "right" }
      }
    };
  }
};
