import useSWR from "swr";
import fetchData from "../helpers/fetchData";
import useSplitPanelV2Context from "./useSplitPanelV2Context";

export default function useProperty() {
  const { propertyId, widgetAuth } = useSplitPanelV2Context();

  const { data, error, mutate } = useSWR(
    `/api/properties/${propertyId}`,
    fetchData(widgetAuth)
  );

  return {
    property: data ? data.property : null,
    error,
    mutate,
    isLoading: !error && !data
  };
}
