import _ from "lodash";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  BsArrowUpShort,
  BsArrowDownShort,
  BsZoomIn,
  BsZoomOut
} from "react-icons/bs";

const OpexPageControls = ({
  controls,
  currentPage,
  totalPages,
  onChangePage,
  onChangeZoom,
  initialZoom,
  pageNumberEditable
}) => {
  const [nextPageValue, setNextPageValue] = useState(currentPage + 1);
  const [zoom, setZoom] = useState(initialZoom || 1);
  const visibleControls = controls || ["next", "prev", "zoomin", "zoomout"];

  useEffect(() => {
    onChangeZoom(zoom);
  }, [zoom]);

  useEffect(() => {
    setNextPageValue(currentPage + 1);
  }, [currentPage]);

  function handleSubmit() {
    const nextPage = parseInt(nextPageValue);
    // If user enters text or nil and tries to submit, just reset the field.
    if (isNaN(nextPage)) {
      return setNextPageValue(currentPage + 1);
    }

    if (nextPage < 0) {
      return setNextPageValue(currentPage + 1);
    }

    onChangePage(nextPage - 1);
  }

  function handleOnKeyDown(e) {
    if (e.key === "Enter") {
      handleSubmit();
    }
  }

  function handleOnChange(e) {
    const nextPageValue = e.target.value;
    setNextPageValue(nextPageValue);
  }

  return (
    <div className="OpexPageControls">
      <div className="pageControlLabel">Page</div>

      {_.includes(visibleControls, "prev") && (
        <div
          className="pageControlButton prevPage"
          onClick={() => {
            onChangePage(currentPage - 1);
          }}
        >
          <BsArrowDownShort size="1.5em" />
        </div>
      )}

      {_.includes(visibleControls, "next") && (
        <div
          className="pageControlButton nextPage"
          onClick={() => {
            onChangePage(currentPage + 1);
          }}
        >
          <BsArrowUpShort size="1.5em" />
        </div>
      )}

      <div className="pageNumber">
        {pageNumberEditable && (
          <input
            className="pageNumberInput"
            type="text"
            value={nextPageValue}
            onChange={handleOnChange}
            onKeyDown={handleOnKeyDown}
            onBlur={handleSubmit}
          />
        )}
        {!pageNumberEditable && nextPageValue} / {totalPages}
      </div>

      {_.includes(visibleControls, "zoomin") && (
        <div
          className="pageControlButton zoomControl"
          onClick={() => {
            setZoom(zoom + 0.25);
          }}
        >
          <BsZoomIn size="1em" />
        </div>
      )}
      {_.includes(visibleControls, "zoomout") && (
        <div
          className="pageControlButton zoomControl"
          onClick={() => {
            // disallow zoom to 0
            if (zoom >= .5) {
              setZoom(zoom - 0.25);
            }
          }}
        >
          <BsZoomOut size="1em" />
        </div>
      )}
    </div>
  );
};

export default OpexPageControls;

OpexPageControls.propTypes = {
  controls: PropTypes.array, // if you only want to show specific controls, pass them here ie. ["next", "prev", "zoomin", "zoomout"]
  currentPage: PropTypes.number, // zero indexed
  totalPages: PropTypes.number,
  onChangePage: PropTypes.func,
  onChangeZoom: PropTypes.func,
  pageNumberEditable: PropTypes.bool,
  initialZoom: PropTypes.number
};
