const moment = require("moment");
const Sugar = require("sugar-date");
const chrono = require("chrono-node");

export const parseLeaseDate = leaseDate => {
  let formattedDate;
  try {
    let parsedLeaseDate = moment(Sugar.Date.create(leaseDate));

    if (!parsedLeaseDate.isValid()) {
      const chronoDate = chrono.parse(leaseDate);
      parsedLeaseDate = moment(
        chronoDate[0].start.get("year") +
          "-" +
          chronoDate[0].start.get("month") +
          "-" +
          chronoDate[0].start.get("day"),
        "YYYY-MM-DD"
      );
    }
    if (parsedLeaseDate.isValid()) {
      formattedDate = parsedLeaseDate.utcOffset(0, true).format();
    }
    return formattedDate;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log("Unable to parse lease date " + leaseDate);
  }
};

export const parseLeaseDates = leaseDates => {
  let leaseStartDate;
  let leaseEndDate;
  try {
    const dates = chrono.parse(leaseDates);
    if (dates[0] && dates[0].end && dates[1] && dates[1].start) {
      const leaseStart = moment(
        dates[0].start.get("year") +
          "-" +
          dates[0].start.get("month") +
          "-" +
          dates[0].start.get("day"),
        "YYYY-MM-DD"
      );
      if (leaseStart.isValid()) {
        leaseStartDate = leaseStart.utcOffset(0, true).format();
      }
      const chronoEndDate = dates[0].end ? dates[0].end : dates[1].start;
      if (chronoEndDate) {
        const leaseEnd = moment(
          chronoEndDate.get("year") +
            "-" +
            chronoEndDate.get("month") +
            "-" +
            chronoEndDate.get("day"),
          "YYYY-MM-DD"
        );
        if (leaseEnd.isValid()) {
          leaseEndDate = leaseEnd.utcOffset(0, true).format();
        }
      }
    } else {
      // find regex matches for the MM/DD/YYYY or M/D/YYYY or M/D/YY format
      let dateRegex = /(0?[1-9]|1[012])[- \/.](0?[1-9]|[12][0-9]|3[01])[- \/.](19|20)?\d\d/g;
      let matches = leaseDates.match(dateRegex);
      if (matches) {
        const leaseStart = moment(Sugar.Date.create(matches[0]));
        if (leaseStart.isValid()) {
          leaseStartDate = leaseStart.utcOffset(0, true).format();
        }
        const leaseEnd = moment(Sugar.Date.create(matches[1]));
        if (leaseEnd.isValid()) {
          leaseEndDate = leaseEnd.utcOffset(0, true).format();
        }
      } else {
        // regex matches for MM/YYYY format
        dateRegex = /(0?[1-9]|1[012])[- \/.](19|20)?\d\d/g;
        matches = leaseDates.match(dateRegex);
        if (matches) {
          const leaseStart = moment(Sugar.Date.create(matches[0]));
          if (leaseStart.isValid()) {
            leaseStartDate = leaseStart.utcOffset(0, true).format();
          }
          const leaseEnd = moment(Sugar.Date.create(matches[1]));
          if (leaseEnd.isValid()) {
            leaseEndDate = leaseEnd.utcOffset(0, true).format();
          }
        }
      }
    }
    return { leaseStartDate, leaseEndDate };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log("Unable to parse lease dates " + leaseDates);
    return { leaseStartDate, leaseEndDate };
  }
};
