import PropTypes from "prop-types";
import NewDataActionsCell from "./NewDataActionsCell";

export default function ActionsCell({
  column: {
    dataKey,
    removeRentRollRowAndExceptions,
    undoRemoveRentRollRowAndExceptions,
    mutateRentRoll,
    mutateExceptions,
    mutateDocument,
    ...restColumnProps
  },
  rowData
}) {
  const { isDeleted, isNewRow, id, status, rentRollKey } = rowData[dataKey] || {};
  if (isNewRow){
    return (
      <NewDataActionsCell
        column={{ dataKey, ...restColumnProps }}
        rentRollKey={rentRollKey}
      />
    );
  }

  const handleActionIconClick = async () => {
    if (isDeleted) {
      await undoRemoveRentRollRowAndExceptions(id);
    } else {
      await removeRentRollRowAndExceptions(id, status);
    }
    await Promise.all([mutateRentRoll(), mutateExceptions(), mutateDocument()]);
  };

  return (
    <div>
      <img
        src={
          isDeleted
            ? require("ui/images/icon-undo.png")
            : require("ui/images/icon-trash.png")
        }
        onClick={handleActionIconClick}
        style={{ cursor: "pointer" }}
      />
    </div>
  );
}

ActionsCell.propTypes = {
  rowData: PropTypes.object,
  column: PropTypes.shape({
    dataKey: PropTypes.string,
    removeRentRollRowAndExceptions: PropTypes.func,
    undoRemoveRentRollRowAndExceptions: PropTypes.func,
  })
};
