import PropTypes from "prop-types";
import FORM_STATES from "ui/components/rentRoll/splitPanelV2/helpers/formStates";
import HeaderActionsCell from "../HeaderActionsCell/HeaderActionsCell";
import Cell from "../Cell/Cell";
import { maxHeaderRows } from "../../../../../../helpers/constants";

export default function CellRenderer({ column, rowData }) {
  const { headerIndex, rowIndex } = rowData;
  if (column.columnIndex === -1) {
    const {
      formState,
      setHeaderRowIndex,
      addHeaderRowIndex,
      headerRowIndexes,
      tableActive
    } = column;

    if (formState !== FORM_STATES.HEADERS || !tableActive) return null;

    const isHeader = headerRowIndexes.includes(rowIndex);
    const prevRowIsHeader = headerRowIndexes.includes(rowIndex - 1);
    const nextRowIsHeader = headerRowIndexes.includes(rowIndex + 1);
    const showExpandHeaderButton =
      !isHeader &&
      (prevRowIsHeader || nextRowIsHeader) &&
      [...headerRowIndexes, rowIndex].length <= maxHeaderRows;

    return (
      <HeaderActionsCell
        isHeader={isHeader}
        location={{ headerIndex, rowIndex }}
        showExpandHeaderButton={showExpandHeaderButton}
        setHeaderRowIndex={setHeaderRowIndex}
        addHeaderRowIndex={addHeaderRowIndex}
      />
    );
  }

  const { columnIndex, onClick } = column;
  const { key, text } = rowData.data[columnIndex];

  return (
    <Cell
      displayText={text}
      id={key}
      onClick={onClick}
      rowIndex={rowIndex}
      columnIndex={columnIndex}
    />
  );
}

CellRenderer.propTypes = {
  column: PropTypes.shape({
    columnIndex: PropTypes.number.isRequired,
    formState: PropTypes.string.isRequired,
    setHeaderRowIndex: PropTypes.func.isRequired,
    addHeaderRowIndex: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    headerRowIndexes: PropTypes.arrayOf(PropTypes.number).isRequired,
    headers: PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.string.isRequired })
    )
  }),
  rowData: PropTypes.shape({
    headerIndex: PropTypes.number.isRequired,
    rowIndex: PropTypes.number.isRequired,
    data: PropTypes.object.isRequired
  })
};
