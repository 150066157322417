import { Component } from "react";
import PropTypes from "prop-types";
import {
  autocomplete,
  autocompleteGooglePlaces,
  findGooglePlaces,
  findGooglePlacesById,
  handleTextChange
} from "ui/store/actions/properties";
import { connect } from "react-redux";
import Autocomplete from "react-autocomplete";
import { withRouter } from "react-router";

class AutocompleteForm extends Component {
  constructor(props) {
    super(props);
    this.autocompleteScheduler = [];
    this.state = {
      value: ""
    };
  }

  componentDidMount() {
    if (this.props.fullAddress) {
      this.onSelect(this.props.fullAddress);
    }
  }

  getLatLong = geometry => {
    const lat = geometry?.location?.lat;
    const long = geometry?.location?.lng;
    return { lat, long };
  };

  componentDidUpdate(prevProps) {
    if (this.props.fullAddress !== prevProps.fullAddress) {
      this.onSelect(this.props.fullAddress);
    }
    if (
      this.props.isGooglePlaces &&
      this.props.formattedAddress &&
      this.props.formattedAddress !== "" &&
      this.props.formattedAddress !== prevProps.formattedAddress
    ) {
      setTimeout(() => {
        let queryArr = this.props.formattedAddress.split(", ");
        const { lat, long } = this.getLatLong(this.props.geometry);
        if (lat && long) queryArr.push(lat, long);
        if (queryArr && queryArr.length > 0) {
          handleTextChange(queryArr[0]);
          this.props.onMenuSelect(queryArr, true);
          this.setState({ value: this.props.name });
        }
      }, 350);
    }
  }

  handleChange = e => {
    this.setState({ value: "" });
    const {
      autocomplete,
      autocompleteGooglePlaces,
      handleTextChange,
      isGooglePlaces,
      widgetAuth
    } = this.props;
    const text = e.target.value;
    this.props.onMenuSelect([text]);
    const lastSchedule = this.autocompleteScheduler.pop();
    lastSchedule && clearTimeout(lastSchedule);
    if (isGooglePlaces) {
      this.autocompleteScheduler.push(
        setTimeout(() => {
          text && autocompleteGooglePlaces(text, widgetAuth);
        }, 350)
      );
    } else {
      this.autocompleteScheduler.push(
        setTimeout(() => {
          text && autocomplete(text);
        }, 350)
      );
    }
    handleTextChange(text);
  };

  onSelect = async query => {
    const {
      handleTextChange,
      isGooglePlaces,
      findGooglePlacesById,
      widgetAuth
    } = this.props;
    if (isGooglePlaces) {
      await findGooglePlacesById(query, widgetAuth);
    } else {
      const queryArr = query.split(", ");
      if (queryArr && queryArr.length > 0) {
        handleTextChange(queryArr[0]);
        this.props.onMenuSelect(queryArr, true);
        this.setState({ value: queryArr[0] });
      }
    }
  };

  render() {
    const {
      recommendedQueries,
      addresses,
      inputClass,
      placeholder,
      name,
      setInputField,
      query,
      isGooglePlaces
    } = this.props;

    let usRegex = /.*USA$/;
    let usAddresses = [];
    addresses.forEach(address => {
      if (address.description.match(usRegex)) usAddresses.push(address);
    });

    return (
      <Autocomplete
        style={{ border: "none" }}
        wrapperProps={{ style: { width: "100%" } }}
        inputProps={{ type: "text", placeholder, name, className: inputClass }}
        getItemValue={item => (item && item.place_id ? item.place_id : item)}
        renderItem={(item, isHighlighted) => (
          <div
            key={item && item.description ? item.description : item}
            style={{
              padding: "7px",
              background: isHighlighted ? "#2196f3" : "white",
              color: isHighlighted ? "white" : "black",
              cursor: "default",
              fontFamily: "Roboto, Arial, Helvetica, sans-serif",
              fontSize: "13px",
              fontWeight: "normal",
              lineHeight: "18px"
            }}
          >
            {item && item.description ? item.description : item}
          </div>
        )}
        onSelect={this.onSelect}
        onChange={this.handleChange}
        value={this.state.value == "" ? query : this.state.value}
        items={isGooglePlaces ? usAddresses : recommendedQueries.slice(0, 5)}
        ref={setInputField}
        autoHighlight={false}
        renderMenu={(items, value, style) => {
          if (items.length === 0) {
            return <div style={{ display: "none" }} />;
          }
          return (
            <div
              style={{
                ...style,
                ...{
                  borderRadius: "3px",
                  boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
                  background: "rgba(255, 255, 255, 0.9)",
                  padding: "2px 0",
                  fontSize: "90%",
                  position: "fixed",
                  overflow: "auto",
                  maxHeight: "50%",
                  width: "200px",
                  zIndex: "100"
                }
              }}
            >
              {items}
            </div>
          );
        }}
        renderInput={props => {
          return <input {...props} autoComplete="new-value" type="text" />;
        }}
      />
    );
  }
}

AutocompleteForm.propTypes = {
  recommendedQueries: PropTypes.array,
  addresses: PropTypes.array,
  autocomplete: PropTypes.func,
  autocompleteGooglePlaces: PropTypes.func,
  findGooglePlaces: PropTypes.func,
  findGooglePlacesById: PropTypes.func,
  formattedAddress: PropTypes.string,
  geometry: PropTypes.object,
  renderInput: PropTypes.func,
  setInputField: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  inputClass: PropTypes.string,
  onMenuSelect: PropTypes.func,
  handleTextChange: PropTypes.func,
  query: PropTypes.string,
  authenticated: PropTypes.bool,
  location: PropTypes.object,
  fullAddress: PropTypes.string,
  isGooglePlaces: PropTypes.bool,
  widgetAuth: PropTypes.object
};

function mapStateToProps(state) {
  return {
    recommendedQueries: state.properties.recommendedQueries,
    formattedAddress: state.properties.formatted_address,
    geometry: state.properties.geometry,
    name: state.properties.name,
    addresses: state.properties.addresses,
    query: state.properties.q,
    authenticated: state.authenticated
  };
}

function mapDispatchToProps(dispatch) {
  return {
    autocomplete: function(query) {
      dispatch(autocomplete(query));
    },
    autocompleteGooglePlaces: function(query, widgetAuth) {
      dispatch(autocompleteGooglePlaces(query, widgetAuth));
    },
    findGooglePlaces: function(query, widgetAuth) {
      dispatch(findGooglePlaces(query, widgetAuth));
    },
    findGooglePlacesById: function(query, widgetAuth) {
      dispatch(findGooglePlacesById(query, widgetAuth));
    },
    handleTextChange: function(text) {
      dispatch(handleTextChange(text));
    }
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AutocompleteForm)
);
