const moment = require("moment");
import dayjs from "dayjs";
import { documentDrill } from "helpers/document";
import {
  currencyFormatter,
  squareFootageFormatter,
  booleanFormatter
} from "ui/components/helpers/metrics";
import {
  isLeaseLongerThanTenYears,
  isLeaseStartDateAfterEnd,
  isCellValueRequired
} from "./exceptions";

const fieldFormatters = {
  annual_rent: currencyFormatter,
  monthly_rent: currencyFormatter,
  annual_rent_per_sqft: currencyFormatter,
  monthly_rent_per_sqft: currencyFormatter,
  size_sqft: squareFootageFormatter,
  is_vacant: booleanFormatter,
  deposit: currencyFormatter
}; // We need to add those formatters to the master list of fields

export function createPaginationArray(document) {
  if (!document) return [];
  if (!document.raw_data_json) return [];
  if (!document.meta_data_json) return [];
  if (!document.raw_data_json.pages) return [];
  if (!document.meta_data_json.headers) return [];
  if (document.raw_data_json.pages.length === 0) return [];
  if (document.meta_data_json.headers.length === 0) return [];

  const pageArray = document.raw_data_json.pages;
  const headerArray = [...document.meta_data_json.headers];

  let headerArrayIdx = 0;
  const paginationArray = pageArray.map((page, pi) => {
    if (page.tableData.length > 0 && headerArray.length > 0) {
      let tableDataArray = [];
      page.tableData.forEach((table, ti) => {
        tableDataArray.push({
          ti,
          tableData: table,
          headers: headerArray[headerArrayIdx]
        });
        headerArrayIdx++;
      });
      return {
        pi,
        tableData: tableDataArray
      };
    } else {
      return {
        pi,
        tableData: []
      };
    }
  });
  return paginationArray;
}

export function mapRentRollToDocument(rentRoll, document, previewTableConfig) {
  if (!rentRoll) return {};
  if (!document) return {};
  if (!previewTableConfig) return {};
  if (rentRoll.length === 0) return {};
  if (previewTableConfig.length === 0) return {};

  const headerData = organizeHeaderData(document);

  if (!headerData) return {};
  if (headerData.length === 0) return {};

  const headerKeys = previewTableConfig.map(header => header.dataIndex);
  const documentRows = rentRoll.filter(row => row.status !== "MANUAL_ADD");
  const mapped = {};

  for (let rowIdx = 0; rowIdx < documentRows.length; rowIdx++) {
    if (
      headerData[documentRows[rowIdx].doc_page_id][
        documentRows[rowIdx].doc_table_id
      ] !== undefined
    ) {
      for (let headerIdx = 0; headerIdx < headerKeys.length; headerIdx++) {
        const docHeaderIdx = headerData[documentRows[rowIdx].doc_page_id][
          documentRows[rowIdx].doc_table_id
        ].headers.findIndex(header => header.id === headerKeys[headerIdx]);

        const advancedRulesFields =
          headerData[documentRows[rowIdx].doc_page_id][
            documentRows[rowIdx].doc_table_id
          ].advancedRules &&
          headerData[documentRows[rowIdx].doc_page_id][
            documentRows[rowIdx].doc_table_id
          ].advancedRules.length > 0
            ? headerData[documentRows[rowIdx].doc_page_id][
                documentRows[rowIdx].doc_table_id
              ].advancedRules.map(rule => rule.field)
            : [];

        if (advancedRulesFields.includes(headerKeys[headerIdx])) {
          const docIds = documentRows[rowIdx].doc_data_json.row.map(
            header => header.id
          );
          const docIdIdx = docIds.indexOf(headerKeys[headerIdx]);
          if (docIdIdx > -1) {
            const { docPosition } = documentRows[rowIdx].doc_data_json.row[
              docIdIdx
            ];
            if (docPosition) {
              const { col, row, page, table } = docPosition;
              mapped[
                `${rowIdx}-${headerIdx}`
              ] = `${page}-${table}-${row}-${col}`;
            }
          }
        } else if (docHeaderIdx > -1) {
          mapped[
            `${rowIdx}-${headerIdx}`
          ] = `${documentRows[rowIdx].doc_page_id}-${documentRows[rowIdx].doc_table_id}-${documentRows[rowIdx].doc_row_ids[0]}-${docHeaderIdx}`;
        }
      }
    }
  }

  return mapped;
}

export function organizeHeaderData(document) {
  if (!document) return [];
  let headerData = [];

  if (document.raw_data_json && document.raw_data_json.pages) {
    let hi = 0;
    for (let pi = 0; pi < document.raw_data_json.pages.length; pi++) {
      headerData[pi] = [];
      for (
        let ti = 0;
        ti < document.raw_data_json.pages[pi].tableData.length;
        ti++
      ) {
        headerData[pi][ti] = document.meta_data_json.headers[hi];
        headerData[pi][ti].headerIndex = hi;
        hi++;
      }
    }
  }

  return headerData;
}

export function getDocData(rentRoll) {
  if (rentRoll.length === 0) return {};
  const rentRollNoManual = rentRoll.filter(row => row.status !== "MANUAL_ADD");
  return rentRollNoManual.reduce((rowAcc, row) => {
    const rowData = row.doc_data_json.row.reduce((colAcc, col) => {
      if (col.isMapped && col.docPosition) {
        colAcc[
          col.docPosition.page +
            "-" +
            col.docPosition.table +
            "-" +
            col.docPosition.row +
            "-" +
            col.docPosition.col
        ] = { id: col.id, value: col.value };
      }
      return colAcc;
    }, {});
    return { ...rowAcc, ...rowData };
  }, {});
}

export function configurePreviewPaneHeader(document, rentRollColumns) {
  if (!document) {
    return {
      rawDataConfig: [],
      previewTableConfig: [],
      unfilteredRawDataConfig: []
    };
  }

  const pagination = createPaginationArray(document);

  if (pagination.length === 0) {
    return {
      rawDataConfig: [],
      previewTableConfig: [],
      unfilteredRawDataConfig: []
    };
  }

  let qualifiedHeaderConfigArray = [];
  pagination.forEach((page, pi) => {
    if (page.tableData.length > 0) {
      page.tableData.forEach((table, ti) => {
        if (table.headers.qualified) {
          qualifiedHeaderConfigArray = [
            ...qualifiedHeaderConfigArray,
            { ...table.headers, ti, pi }
          ];
        }
      });
    }
  });

  let mappedAndRequiredHeaders = [];
  let tenantName = [];
  let advancedRules = [];
  let notMappedAndRequired = [];
  let notMappedAndNotRequired = [];

  qualifiedHeaderConfigArray.forEach(config => {
    if (config.advancedRules && config.advancedRules.length > 0) {
      config.advancedRules.forEach(rule => {
        advancedRules.push({ id: rule.field, adv: true });
      });
    }
    config.headers.forEach(headerObj => {
      if (headerObj.id === "tenant_name") {
        tenantName.push({
          ...headerObj,
          pi: config.pi,
          ti: config.ti,
          ri: config.rowIndex
        });
      } else if (
        headerObj.isMapped &&
        headerObj.required &&
        headerObj.id !== "tenant_name"
      ) {
        mappedAndRequiredHeaders = [
          ...mappedAndRequiredHeaders,
          { ...headerObj, pi: config.pi, ti: config.ti, ri: config.rowIndex }
        ];
      } else if (
        headerObj.isMapped &&
        !headerObj.required &&
        headerObj.id !== "tenant_name"
      ) {
        notMappedAndRequired = [
          ...notMappedAndRequired,
          { ...headerObj, pi: config.pi, ti: config.ti, ri: config.rowIndex }
        ];
      }
    });
  });

  const mappedAndRequiredHeadersKey = mappedAndRequiredHeaders.map(
    header => header.id
  );
  const advancedRulesKey = advancedRules.map(header => header.id);

  const notMappedAndRequiredKey = notMappedAndRequired.map(header => header.id);
  qualifiedHeaderConfigArray.forEach(config => {
    config.headers.forEach(headerObj => {
      if (
        !advancedRulesKey.includes(headerObj.id) &&
        !mappedAndRequiredHeadersKey.includes(headerObj.id) &&
        !notMappedAndRequiredKey.includes(headerObj.id) &&
        !notMappedAndNotRequired
          .map(header => header.id)
          .includes(headerObj.id) &&
        headerObj.id !== "tenant_name"
      ) {
        notMappedAndNotRequired = [
          ...notMappedAndNotRequired,
          { ...headerObj, pi: config.pi, ti: config.ti, ri: config.rowIndex }
        ];
      }
    });
  });

  const unfilteredRawDataConfig = [
    ...tenantName,
    ...notMappedAndRequired,
    ...notMappedAndNotRequired
  ];

  const orderedHeadersWithConfig = [
    ...tenantName,
    ...mappedAndRequiredHeaders,
    ...notMappedAndRequired,
    ...advancedRules,
    { id: "is_vacant" },
    ...notMappedAndNotRequired
  ];
  const headersObj = orderedHeadersWithConfig.map((header, hi) => {
    const key = `header-${hi}-${header.id}-${header.pi}-${header.ti}`;

    let headerObj = {};

    headerObj = {
      title: header.id,
      dataIndex: header.id,
      key,
      opaque:
        !advancedRulesKey.includes(header.id) &&
        !mappedAndRequiredHeadersKey.includes(header.id) &&
        !notMappedAndRequiredKey.includes(header.id),
      className: "PreviewPaneTable__middleColumn"
    };

    if (hi === 0) {
      headerObj.opaque = false;
      headerObj["className"] = "PreviewPaneTable__firstColumn";
    } else if (header.id === "is_vacant") {
      headerObj.opaque = false;
    }

    return headerObj;
  });

  const previewHeaders = Array.from(
    new Set(headersObj.map(header => header.dataIndex))
  ).map(id => {
    const previewHeader = {
      title: id,
      dataIndex: id,
      key: headersObj.find(header => header.dataIndex === id).key,
      opaque: headersObj.find(header => header.dataIndex === id).opaque,
      className: headersObj.find(header => header.dataIndex === id).className
    };

    if (
      rentRollColumns &&
      rentRollColumns.fields &&
      rentRollColumns.fields.length > 0 &&
      rentRollColumns.fields.find(
        column => column.term === previewHeader.title
      ) &&
      rentRollColumns.fields.find(column => column.term === previewHeader.title)
        .dataType
    ) {
      const foundColumn = rentRollColumns.fields.find(
        column => column.term === previewHeader.title
      );
      previewHeader.dataType = foundColumn.dataType;
    }

    return previewHeader;
  });

  return {
    unfilteredRawDataConfig,
    rawDataConfig: orderedHeadersWithConfig,
    previewTableConfig: [
      ...previewHeaders,
      {
        title: "Actions",
        dataIndex: "actions",
        key: `header-${headersObj.length}-actions`,
        opaque: false,
        className: "PreviewPaneTable__lastColumn"
      }
    ]
  };
}

export function mapRentRollData(rentRoll, document, previewTableConfig) {
  if (!rentRoll) return [];
  if (!document) return [];
  if (!previewTableConfig) return [];
  if (rentRoll.length === 0) return [];
  if (previewTableConfig.length === 0) return [];

  const headerData = organizeHeaderData(document);

  if (!headerData) return [];
  if (headerData.length === 0) return [];

  const rentRollToDocument = mapRentRollToDocument(
    rentRoll,
    document,
    previewTableConfig
  );

  const headerKeys = previewTableConfig.map(header => header.dataIndex);

  const manuallyAddedRows = rentRoll.filter(row => row.status === "MANUAL_ADD");
  const documentAddedRows = rentRoll.filter(row => row.status !== "MANUAL_ADD");

  const manualConfigRows = manuallyAddedRows.map((row, ri) => {
    let configRow = {};
    headerKeys.forEach((headerKey, headerIdx) => {
      configRow[headerKey] = row[headerKey]
        ? row[headerKey]
        : row.data_jsonb[headerKey];
      configRow[`key-${headerKey}`] = `manual-${ri}-${headerIdx}`;
    });

    configRow.key = `manual-row-${ri}`;
    configRow.rowId = row.id;
    configRow.status = row.status;

    return configRow;
  });

  const documentConfigRows = documentAddedRows.map((row, ri) => {
    let configRow = {};

    headerKeys.forEach((headerKey, headerIdx) => {
      configRow[headerKey] = row[headerKey]
        ? row[headerKey]
        : row.data_jsonb[headerKey];
      configRow[`key-${headerKey}`] = `${ri}-${headerIdx}`;
      configRow[`dockey-${headerKey}`] =
        rentRollToDocument[`${ri}-${headerIdx}`];
    });

    configRow.key = `row-${ri}`;
    configRow.rowId = row.id;
    configRow.status = row.status;

    return configRow;
  });

  return [...manualConfigRows, ...documentConfigRows];
}

export function configureTableMarkup(document, exceptions) {
  if (!document) return [];

  const pagination = createPaginationArray(document);

  if (!pagination) return [];
  if (pagination.length === 0) return [];

  const headersConfig = organizeHeaderData(document);

  if (!headersConfig) return [];
  if (headersConfig.length === 0) return [];

  let markupConfig = [];

  pagination.forEach((page, pi) => {
    page.tableData.forEach((tableConfig, ti) => {
      const headers = headersConfig[pi][ti].headers;
      if (headers && headers.length > 0) {
        tableConfig.tableData.forEach((row, ri) => {
          const tenantNameIdx = headers.findIndex(
            header => header.id === "tenant_name"
          );

          if (tenantNameIdx !== -1) {
            row.forEach((cell, ci) => {
              let key = `${pi}-${ti}-${ri}-${ci}`;
              const leaseStartDateAfterEnd = isLeaseStartDateAfterEnd(
                exceptions,
                { pi, ti, ri, ci }
              );
              const leaseLongerThanTenYears = isLeaseLongerThanTenYears(
                exceptions,
                { pi, ti, ri, ci }
              );
              const hasCellValueRequired = isCellValueRequired(exceptions, {
                pi,
                ti,
                ri,
                ci
              });

              if (
                cell.corrected &&
                !leaseStartDateAfterEnd &&
                !leaseLongerThanTenYears
              ) {
                markupConfig.push({ key, type: "corrected" });
              } else if (leaseStartDateAfterEnd || hasCellValueRequired) {
                markupConfig.push({
                  key,
                  type: "error",
                  rawPageIdx: pi,
                  rawTableIdx: ti,
                  rawRowIdx: ri,
                  rawCellIdx: ci
                });
              } else if (leaseLongerThanTenYears || leaseStartDateAfterEnd) {
                markupConfig.push({
                  key,
                  type: "warning",
                  rawPageIdx: pi,
                  rawTableIdx: ti,
                  rawRowIdx: ri,
                  rawCellIdx: ci
                });
              } else if (cell.confidence < 90) {
                markupConfig.push({
                  key,
                  type: "warning",
                  rawPageIdx: pi,
                  rawTableIdx: ti,
                  rawRowIdx: ri,
                  rawCellIdx: ci
                });
              }
            });
          }
        });
      }
    });
  });

  return markupConfig;
}

export function markupCell(markupConfig, dockeyHeader) {
  if (!markupConfig) return "";

  const markupKeyArray = markupConfig.map(config => config.key);

  if (markupKeyArray.includes(dockeyHeader)) {
    const idx = markupKeyArray.indexOf(dockeyHeader);
    switch (markupConfig[idx].type) {
      case "corrected":
        return "PreviewPaneTable__cell--corrected ";
      case "warning":
        return "PreviewPaneTable__cell--warning";
      case "error":
        return "PreviewPaneTable__cell--error";
      default:
        return " ";
    }
  }
}

export function parseValue(dataRow, header) {
  if (header === "is_vacant") {
    return fieldFormatters.is_vacant.format(dataRow[header]);
  } else if (header === "deposit") {
    if (!dataRow[header]) {
      return undefined;
    } else if (isNaN(dataRow[header])) {
      return dataRow[header];
    } else {
      return fieldFormatters.deposit.format(
        parseFloat(dataRow[header].replace(/,/g, ""))
      );
    }
  } else if (
    dataRow[header] &&
    (header === "lease_end_date" || header === "lease_start_date")
  ) {
    const date = new Date(dataRow[header]);
    return moment.utc(date).format("L");
  } else if (dataRow[header] && fieldFormatters[header]) {
    return fieldFormatters[header].format(dataRow[header]);
  } else {
    return dataRow[header];
  }
}

export function parseDataType(dataRow, header, type) {
  const value = dataRow[header];
  switch (type) {
    case "number":
      return value && !isNaN(value.replace(/,/g, ""))
        ? parseFloat(value.replace(/,/g, "")).toFixed(2)
        : (0).toFixed(2);
    case "currency-usd":
      if (
        value &&
        /^\$/.test(value) &&
        !isNaN(value.replace(/\$/g, "").replace(/,/g, ""))
      ) {
        const vals = value.replace(/\$/g, "").split(".");
        if (vals[0].length >= 5) {
          vals[0] = vals[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
        }
        if (vals[1].length > 2) {
          vals[1] = String(
            parseFloat(Number(vals[1]) / Math.pow(10, vals[1].length)).toFixed(
              2
            )
          ).split(".")[1];
        }
        return String(`$${vals.join(".")}`);
      } else if (value && !isNaN(value.replace(/\$/g, "").replace(/,/g, ""))) {
        return String(
          `$${parseFloat(value.replace(/\$/g, "").replace(/,/g, "")).toFixed(
            2
          )}`
        );
      } else {
        return "invalid fmt";
      }
    case "string":
      return String(value);
    case "date": {
      const date = new Date(dataRow[header]);
      if (isNaN(date.getTime())) {
        return "invalid fmt";
      }
      return moment.utc(date).format("L");
    }
    default:
      return "value";
  }
}

export function getTotalPages(document) {
  if (!document) return undefined;
  if (!document.raw_data_json) return undefined;
  if (document.raw_data_json.pages.length === 0) return undefined;

  return document.raw_data_json.pages.length;
}

export function getPageByIndex(document, idx) {
  if (!document) return undefined;
  if (!document.raw_data_json) return undefined;
  if (document.raw_data_json.pages.length === 0) return undefined;

  return document.raw_data_json.pages[idx];
}

export function processExcelCellToDocData(document, sheetIndex) {
  const map = {};
  documentDrill(document, function({
    data,
    pageIndex,
    tableIndex,
    rowIndex,
    columnIndex
  }) {
    if (sheetIndex === pageIndex) {
      const { excelCell } = data.position;
      map[excelCell] = {
        docLocationFromExcelCell: [
          pageIndex,
          tableIndex,
          rowIndex,
          columnIndex
        ],
        docDataFromExcelCell: data
      };
    }
  });
  return map;
}

export function getRentRollKeyFromDocument(rentRoll, document, documentKey) {
  if (!document) return "";
  if (!rentRoll) return "";
  if (rentRoll.length === 0) return "";

  const { previewTableConfig } = configurePreviewPaneHeader(document);

  if (!previewTableConfig) return "";

  const rentRollToDocument = mapRentRollToDocument(
    rentRoll,
    document,
    previewTableConfig
  );

  const rentRollKey = Object.keys(rentRollToDocument).find(
    key => rentRollToDocument[key] === documentKey
  );

  return rentRollKey;
}

export const getSheetDataFromDocument = (document, pageIndex) => {
  if (
    document &&
    document.raw_data_json &&
    document.raw_data_json.pages[pageIndex] &&
    document.raw_data_json.pages[pageIndex].tableData
  ) {
    return document.raw_data_json.pages[pageIndex].tableData[0];
  } else {
    return [];
  }
};

export const getTotalPercentageComplete = (rentRollDocument, rentRoll) => {
  if (!rentRollDocument) return 0;
  if (!rentRollDocument.raw_data_json) return 0;

  const headerData = organizeHeaderData(rentRollDocument);
  let totalCount = 0;
  let completeCount = 0;

  const deletedRentRollRows = rentRoll.filter(
    row => row.status === "DOCUMENT_DEL"
  );

  const isADeletedRentRollRow = (deletedRentRollRows, location) => {
    for (let i = 0; i < deletedRentRollRows.length; i++) {
      if (
        deletedRentRollRows[i].doc_page_id === location.pi &&
        deletedRentRollRows[i].doc_table_id === location.ti &&
        deletedRentRollRows[i].doc_row_ids.includes(location.ri)
      ) {
        return true;
      }
    }

    return false;
  };

  const isHeaderUnmapedAndUnqualified = headerData => {
    return headerData && !headerData.metaName && !headerData.qualified;
  };

  const isBlankRow = (row, requiredCols, headerData) => {
    if (isHeaderUnmapedAndUnqualified(headerData)) {
      return false;
    }

    const isBlankArray = new Array(requiredCols.length);
    for (let i = 0; i < row.length; i++) {
      isBlankArray[i] = row[i].text ? false : true;
    }
    return !isBlankArray.includes(false);
  };

  const isExcludedRow = (row, exclRows, requiredCols) => {
    if (isBlankRow(row, requiredCols)) {
      return true;
    }

    for (let ci = 0; ci < exclRows.length; ci++) {
      if (
        exclRows[ci] &&
        exclRows[ci].length > 0 &&
        (exclRows[ci].includes(row[ci].text.toLowerCase()) ||
          exclRows[ci].includes(row[ci].text) ||
          exclRows[ci].some(excludedValue => {
            return row[ci].text
              .trim()
              .toLowerCase()
              .includes(excludedValue);
          }))
      ) {
        return true;
      } else if (
        exclRows[ci] &&
        exclRows[ci].length === 0 &&
        requiredCols[ci] &&
        row[ci].text === ""
      ) {
        return true;
      }
    }

    return false;
  };

  // Calculate total count
  rentRollDocument.raw_data_json.pages.forEach((page, pi) => {
    page.tableData.forEach((table, ti) => {
      if (
        headerData[pi][ti] &&
        headerData[pi][ti].headers &&
        headerData[pi][ti].headers.length > 0
      ) {
        let exclCols = [];
        let exclRows = new Array(headerData[pi][ti].headers.length);
        let requiredCols = new Array(headerData[pi][ti].headers.length);
        headerData[pi][ti].headers.forEach((header, hi) => {
          if (!header.isMapped) exclCols.push(hi);
          if (header.skipRowForValue) exclRows[hi] = header.skipRowForValue;
          requiredCols[hi] = header.required ? header.required : false;
        });
        table.forEach((row, ri) => {
          if (
            headerData[pi][ti].rowIndex < ri &&
            !isADeletedRentRollRow(deletedRentRollRows, { pi, ti, ri }) &&
            !isExcludedRow(row, exclRows, requiredCols)
          ) {
            if (!headerData[pi][ti].metaName) {
              totalCount += row.length;
            } else {
              row.forEach((_, ci) => {
                if (!exclCols.includes(ci)) {
                  totalCount++;
                }
              });
            }
          }
        });
      }
    });
  });

  // Calculate complete count
  rentRollDocument.raw_data_json.pages.forEach((page, pi) => {
    page.tableData.forEach((table, ti) => {
      if (
        headerData[pi][ti] &&
        headerData[pi][ti].headers &&
        headerData[pi][ti].headers.length > 0
      ) {
        let exclCols = [];
        let exclRows = new Array(headerData[pi][ti].headers.length);
        let requiredCols = new Array(headerData[pi][ti].headers.length);
        headerData[pi][ti].headers.forEach((header, hi) => {
          if (!header.isMapped) exclCols.push(hi);
          if (header.skipRowForValue) exclRows[hi] = header.skipRowForValue;
          requiredCols[hi] = header.required ? header.required : false;
        });
        table.forEach((row, ri) => {
          if (
            !isADeletedRentRollRow(deletedRentRollRows, { pi, ti, ri }) &&
            !isExcludedRow(row, exclRows, requiredCols)
          ) {
            if (headerData[pi][ti].rowIndex < ri) {
              row.forEach((col, ci) => {
                if (!exclCols.includes(ci)) {
                  if (col.predef && col.predef.id) {
                    completeCount++;
                  } else if (!col.predef) {
                    completeCount++;
                  }
                }
              });
            }
          }
        });
      }
    });
  });

  return totalCount !== 0 ? Math.floor((completeCount / totalCount) * 100) : 0;
};

export const getColorFromPctComplete = totalPctComplete => {
  if (totalPctComplete <= 60) {
    return "red";
  } else if (totalPctComplete >= 60 && totalPctComplete < 90) {
    return "orange";
  } else {
    return "green";
  }
};

export const getCurrentEffectiveDate = (newEffectiveDate, effective_date) => {
  if (!newEffectiveDate && !effective_date) return "";
  return newEffectiveDate
    ? dayjs(newEffectiveDate).format("MMMM DD, YYYY")
    : dayjs(effective_date).format("MMMM DD, YYYY");
};

export const getTemplateObject = (
  document,
  headerData,
  location,
  cache,
  rowIds
) => {
  const { pi, ti, ri } = location;

  let templateObj =
    headerData[pi][ti].headers.length > 0
      ? headerData[pi][ti].headers
      : Array.apply(
          null,
          Array(document.raw_data_json.pages[pi].tableData[ti][ri].length)
        ).map(_ => {
          return {};
        });
  templateObj.forEach((header, ci) => {
    let id = cache["headerSelect-" + pi + "-" + ti + "-" + ri + "-" + ci];
    let required =
      cache["headerRequired-" + pi + "-" + ti + "-" + ri + "-" + ci];
    let headerName =
      rowIds && rowIds.length > 1
        ? rowIds.length === 2
          ? `${
              document.raw_data_json.pages[pi].tableData[ti][rowIds[0]][ci].text
            }${
              document.raw_data_json.pages[pi].tableData[ti][rowIds[1]][ci].text
                ? ` ${
                    document.raw_data_json.pages[pi].tableData[ti][rowIds[1]][
                      ci
                    ].text
                  }`
                : ""
            }`
          : `${
              document.raw_data_json.pages[pi].tableData[ti][rowIds[0]][ci].text
            }${
              document.raw_data_json.pages[pi].tableData[ti][rowIds[1]][ci].text
                ? ` ${
                    document.raw_data_json.pages[pi].tableData[ti][rowIds[1]][
                      ci
                    ].text
                  }`
                : ""
            }${
              document.raw_data_json.pages[pi].tableData[ti][rowIds[2]][ci].text
                ? ` ${
                    document.raw_data_json.pages[pi].tableData[ti][rowIds[2]][
                      ci
                    ].text
                  }`
                : ""
            }`
        : document.raw_data_json.pages[pi].tableData[ti][ri][ci].text;
    let skipPos = 0;
    let skipVal =
      cache["skipRow-" + pi + "-" + ti + "-" + ri + "-" + ci + "-" + skipPos];

    if (header.skipRowForValue == null) {
      header.skipRowForValue = [];
    }

    while (skipVal != null || header.skipRowForValue[skipPos] != null) {
      if (skipVal != null) {
        if (header.skipRowForValue[skipPos] != null) {
          header.skipRowForValue[skipPos] = skipVal;
        } else {
          header.skipRowForValue.push(skipVal);
        }
      }
      skipPos++;
      skipVal =
        cache["skipRow-" + pi + "-" + ti + "-" + ri + "-" + ci + "-" + skipPos];
    }

    header.skipRowForValue = header.skipRowForValue.filter(
      skip => skip.length > 0
    );

    if (id) {
      header.id = id;
      header.isMapped = id !== "none";
    }

    if (typeof required !== "undefined") {
      header.required = required;
    }

    if (headerName) {
      header.headerNames = [headerName];
    } else {
      header.headerNames = [""];
    }
    header.simScore = 0.9;
    delete header.score;
  });

  return templateObj;
};

export const getMultiLineHeader = (cache, page, location, rowIds) => {
  const { pi, ti, ci } = location;
  return cache["rowHeader-" + pi + "-" + ti]
    ? cache["rowHeader-" + pi + "-" + ti].row.length > 1
      ? cache["rowHeader-" + pi + "-" + ti].row.length === 2
        ? `${
            page.tableData[ti][cache["rowHeader-" + pi + "-" + ti].row[0]][ci]
              .text
          } ${
            page.tableData[ti][cache["rowHeader-" + pi + "-" + ti].row[1]][ci]
              .text
          }`
        : `${
            page.tableData[ti][cache["rowHeader-" + pi + "-" + ti].row[0]][ci]
              .text
          }${
            page.tableData[ti][cache["rowHeader-" + pi + "-" + ti].row[1]][ci]
              .text
              ? ` ${
                  page.tableData[ti][
                    cache["rowHeader-" + pi + "-" + ti].row[1]
                  ][ci].text
                }`
              : ""
          }${
            page.tableData[ti][cache["rowHeader-" + pi + "-" + ti].row[2]][ci]
              .text
              ? ` ${
                  page.tableData[ti][
                    cache["rowHeader-" + pi + "-" + ti].row[2]
                  ][ci].text
                }`
              : ""
          }`
      : undefined
    : rowIds && rowIds.length > 1
    ? rowIds.length === 2
      ? `${page.tableData[ti][rowIds[0]][ci].text} ${
          page.tableData[ti][rowIds[1]][ci].text
        }`
      : `${page.tableData[ti][rowIds[0]][ci].text}${
          page.tableData[ti][rowIds[1]][ci].text
            ? ` ${page.tableData[ti][rowIds[1]][ci].text}`
            : ""
        }${
          page.tableData[ti][rowIds[2]][ci].text
            ? ` ${page.tableData[ti][rowIds[2]][ci].text}`
            : ""
        }`
    : undefined;
};
