import { Component } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import Button from "ui/components/shared/Button";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import CompaniesWarningPopup from "./CompaniesWarningPopup";
import { companyGroupNames } from "helpers/companies";
import { PERIOD_DATE_FORMAT } from "ui/components/helpers/dayjs";

export default class CompanyRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editable: false,
      showApiKeys: false,
      showExternalId: false,
      editExternalId: false,
      groupName: props.groupName,
      groupInfoEdited: false,
      externalId: props.externalId,
      maxActiveUsers: props.maxActiveUsers,
      endDate: dayjs(props.expiresAt),
      showWarning: false
    };
  }

  handleChange = (e, field) => {
    const groupInfoEdited = e.target.value === this.props[field] ? false : true;
    this.setState({ [field]: e.target.value, groupInfoEdited });
  };

  renderGroupName = groupName => {
    if (this.state.editable) {
      return (
        <select
          value={this.state.groupName}
          onChange={e => this.handleChange(e, "groupName")}
        >
          {Object.keys(companyGroupNames).map(groupName => (
            <option key={groupName} value={companyGroupNames[groupName].id}>
              {companyGroupNames[groupName].label}
            </option>
          ))}
        </select>
      );
    }
    return groupName ? (
      <span title={companyGroupNames[groupName].label}>{groupName}</span>
    ) : (
      groupName
    );
  };

  renderExternalId = externalId => {
    if (this.state.editable) {
      return (
        <input
          type="text"
          value={this.state.externalId}
          onChange={e => this.handleChange(e, "externalId")}
        ></input>
      );
    }

    if (this.state.showExternalId) {
      return (
        <div className="CompanyRow__external_id">
          <span style={{ marginRight: ".5rem" }}>{this.props.externalId}</span>
          <Button
            className="hide_external_id"
            onClick={() => this.setState({ showExternalId: false })}
          >
            Hide
          </Button>
        </div>
      );
    }

    if (externalId) {
      return (
        <div>
          <Button
            className="toggle_external_id"
            onClick={() => this.setState({ showExternalId: true })}
          >
            Show
          </Button>
        </div>
      );
    }
  };

  renderEditOrSubmit = () => {
    if (this.state.editable) {
      return (
        <div className="CompanyRow__edit">
          <Button className="edit__submit" onClick={this.onSubmit}>
            Submit
          </Button>
          <Button
            className="edit__cancel"
            onClick={() =>
              this.setState({
                editable: false,
                groupInfoEdited: false,
                groupName: this.props.groupName,
                externalId: this.props.externalId
              })
            }
          >
            Cancel
          </Button>
        </div>
      );
    }
    return (
      <Button
        className="toggle_edit"
        onClick={() => this.setState({ editable: true })}
      >
        Edit
      </Button>
    );
  };

  renderAPIKeys = () => {
    if (this.state.showApiKeys) {
      const { apiPublicKey, secretKey } = this.props;
      return (
        <div className="CompanyRow__edit">
          <div>
            <div>
              <span>Public Key:</span> {apiPublicKey}
            </div>
            <div>
              <span>Secret Key:</span> {decodeURIComponent(secretKey)}
            </div>
          </div>
          <Button
            className="edit__submit"
            onClick={e => {
              if (confirm("Are you sure you want to regenerate API keys?")) {
                this.generateAPIKeys();
              } else {
                e.preventDefault();
              }
            }}
          >
            Generate
          </Button>
          <Button
            className="edit__cancel"
            onClick={() => this.setState({ showApiKeys: false })}
          >
            Hide
          </Button>
        </div>
      );
    }
    return (
      <>
        <Button className="toggle_edit" onClick={this.getAPIKeys}>
          Show
        </Button>
      </>
    );
  };

  loginOnBehalfOfCompany = id => {
    const { loginOnBehalf } = this.props;
    (async () => {
      await loginOnBehalf(id);
      setTimeout(function() {
        window.location.href = window.location.href;
      }, 10);
    })();
  };

  generateAPIKeys = () => {
    const { id, generateCompanyApiKeys } = this.props;
    this.setState({ showApiKeys: false });
    (async () => {
      await generateCompanyApiKeys(id);
    })();
  };

  getAPIKeys = () => {
    const { id, getCompanyApiKeys } = this.props;
    this.setState({ showApiKeys: true });
    (async () => {
      await getCompanyApiKeys(id);
    })();
  };

  onSubmit = e => {
    e.preventDefault();
    if (this.state.groupInfoEdited) this.setState({ showWarning: true });
    else this.submitEdits();
  };

  closePopup = () => {
    this.setState({
      showWarning: false,
      editable: false,
      groupName: this.props.groupName,
      groupInfoEdited: false,
      externalId: this.props.externalId
    });
  };

  submitEdits = () => {
    const { id, updateCompany } = this.props;
    const { endDate, maxActiveUsers, groupName, externalId } = this.state;
    this.setState({ showWarning: false, editable: false });
    updateCompany(
      id,
      endDate.valueOf(),
      parseInt(maxActiveUsers),
      groupName,
      externalId
    );
  };

  renderMaxActiveUsers = maxActiveUsers => {
    if (this.state.editable) {
      return (
        <input
          className="edit__active__users"
          onChange={e => this.setState({ maxActiveUsers: e.target.value })}
          type="number"
          value={this.state.maxActiveUsers}
        />
      );
    }
    return maxActiveUsers;
  };

  renderExpiresAt = expiresAt => {
    if (this.state.editable) {
      return (
        <DatePicker
          defaultValue={this.state.endDate}
          format={PERIOD_DATE_FORMAT}
          onChange={endDate => this.setState({ endDate })}
        />
      );
    }
    return dayjs(expiresAt).format("MMM Do YYYY");
  };

  render() {
    const {
      id,
      name,
      groupName,
      externalId,
      maxActiveUsers,
      activeUsers,
      createdAt,
      expiresAt
    } = this.props;
    return (
      <>
        <form
          className={cx("CompanyRow", {
            "reached-max-users": activeUsers === maxActiveUsers
          })}
        >
          <div className={cx("CompanyRow__details", "ms-column-1")}>{id}</div>
          <div className={cx("CompanyRow__details", "ms-column-2")}>{name}</div>
          <div className={cx("CompanyRow__details", "ms-column-3")}>
            {this.renderGroupName(groupName)}
          </div>
          <div className={cx("CompanyRow__details", "ms-column-4")}>
            {this.renderExternalId(externalId)}
          </div>
          <div className={cx("CompanyRow__details", "ms-column-5")}>
            {activeUsers}
          </div>
          <div className={cx("CompanyRow__details", "ms-column-6")}>
            {this.renderMaxActiveUsers(maxActiveUsers)}
          </div>
          <div className={cx("CompanyRow__details", "ms-column-7")}>
            {dayjs(createdAt).format("MMM Do YYYY")}
          </div>
          <div className={cx("CompanyRow__details", "ms-column-8")}>
            {this.renderExpiresAt(expiresAt)}
          </div>
          <div className={cx("CompanyRow__details", "ms-column-9")}>
            {this.renderEditOrSubmit()}
          </div>
          <div className={cx("CompanyRow__details", "ms-column-10")}>
            {this.renderAPIKeys()}
          </div>
          <div className={cx("CompanyRow__details", "ms-column-11")}>
            <Button
              className="edit__submit"
              onClick={e => {
                if (
                  confirm(
                    `Are you sure you want to login on behalf of company '${name}'?`
                  )
                ) {
                  this.loginOnBehalfOfCompany(id);
                }
                e.preventDefault();
              }}
            >
              Login on behalf
            </Button>
          </div>
        </form>
        {this.state.showWarning && (
          <CompaniesWarningPopup
            submitEdits={this.submitEdits}
            closePopup={this.closePopup}
          />
        )}
      </>
    );
  }
}

CompanyRow.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  groupName: PropTypes.string,
  externalId: PropTypes.string,
  maxActiveUsers: PropTypes.number,
  activeUsers: PropTypes.number,
  createdAt: PropTypes.string,
  expiresAt: PropTypes.string,
  updateCompany: PropTypes.func,
  generateCompanyApiKeys: PropTypes.func,
  apiPublicKey: PropTypes.string,
  secretKey: PropTypes.string,
  getCompanyApiKeys: PropTypes.func,
  loginOnBehalf: PropTypes.func
};
