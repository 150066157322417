import XLSX from "xlsx";
import moment from "moment";
import { booleanFormatter } from "ui/components/helpers/metrics";

export function exportExcel(data, fileName, headers) {
  try {
    const headerList = headers.map(field => field.label);
    const ws = XLSX.utils.book_new();
    const processed_data = processData(headers, data);
    XLSX.utils.sheet_add_aoa(ws, [headerList]);
    XLSX.utils.sheet_add_json(ws, processed_data, {
      origin: "A2",
      skipHeader: true,
      dateNF: "YYYY-MM-DD",
      cellDates: true
    });

    formatSheet(ws, headers);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    XLSX.writeFile(wb, fileName);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("Error while downloading excel - " + e);
  }
}

function processData(headers, data) {
  const headerKeys = headers.map(column => column.key);
  const processed_data = data.map(x => {
    let obj = {};
    headerKeys.forEach(header => {
      if (x[header]) {
        if (moment.isMoment(x[header])) {
          obj[header] = x[header].utc().format();
        } else {
          obj[header] = x[header];
        }
      } else if (header === "is_vacant") {
        obj[header] = false;
      } else if (header === "is_occupied") {
        if (x["is_vacant"]) {
          obj[header] = !x["is_vacant"];
        } else {
          obj[header] = true;
        }
      } else if (header === "is_primary") {
        if (!x["is_primary"]) obj[header] = false;
      } else {
        obj[header] = null;
      }
    });
    return obj;
  });
  return processed_data;
}

function formatSheet(ws, data) {
  const range = XLSX.utils.decode_range(ws["!ref"]);
  if (data) {
    data.forEach((item, index) => {
      if (item.format) {
        if (item.format === "percent") {
          const fmt = "0.00%";
          const type = "n";
          formatColumn(ws, range, index, fmt, type);
        } else if (item.format === "percent_tenths") {
          const fmt = "0.0%";
          const type = "n";
          formatColumn(ws, range, index, fmt, type);
        } else if (item.format === "currency") {
          const fmt = '"$"#,##0.00';
          const type = "n";
          formatColumn(ws, range, index, fmt, type);
        } else if (item.format === "date") {
          const fmt = "yyyy-mm-dd";
          const type = "d";
          formatColumn(ws, range, index, fmt, type);
        } else if (item.format === "size") {
          const fmt = '#,### "sq ft"';
          const type = "n";
          formatColumn(ws, range, index, fmt, type);
        } else if (item.format === "yn") {
          const fmt = "w";
          const type = "s";
          formatColumn(ws, range, index, fmt, type);
        }
      }
    });
  }
  return ws;
}

function formatColumn(ws, range, index, fmt, type) {
  //formats column to numeric column of 'fmt' format
  for (let i = range.s.r + 1; i <= range.e.r; ++i) {
    let cell = ws[XLSX.utils.encode_cell({ r: i, c: index })];
    if (!cell || cell.t == "z") continue;
    cell.t = type;
    if (cell.t === "d") {
      // assumption: raw date format is timestamp
      const dateArr = cell.v.split("T")[0].split("-"); //[year, month, date]
      cell.v = dateArr[0] + "/" + dateArr[1] + "/" + dateArr[2];
    }
    if (cell.t === "s") {
      cell.v = booleanFormatter.format(cell.v);
    }
    cell.z = fmt;
  }
}
