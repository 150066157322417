export const predefConfig = {
  search_for_value:
    "Type a word and press enter to search for a predefined value.",
  select_a_value: "Select a value below, then click update.",
  create_new_predef:
    "To create a new predefined value, type it in above and click update."
};

export const isEntityMappingEnabled = (
  propertyType,
  field,
  userCompanyEntitySources
) => {
  if (
    userCompanyEntitySources !== null &&
    propertyType !== "MULTI_FAMILY" &&
    field === "tenant_name" &&
    propertyType in userCompanyEntitySources
  ) {
    return true;
  }
  return false;
};
