import { Component } from "react";
import PropTypes from "prop-types";
import Color from "./Color";
import Checkbox from "./Checkbox2";

class CheckBoxTableHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: {}
    };
  }

  handleToggle = e => {
    const headerName = e.target.name;
    if (this.state.checked[headerName] === true) {
      this.setState({
        checked: { [headerName]: false }
      });
    } else {
      this.setState({
        checked: { [headerName]: true }
      });
    }
    this.props.handleToggle(headerName);
  };

  determineChecked = headerName => {
    return this.state[headerName] === true;
  };

  render() {
    const { headerNames, title, colordeterminators } = this.props;
    const determineChecked =
      this.props.determineChecked || this.determineChecked;
    return (
      <div className="CheckBoxTableHeader__header">
        <div className="CheckBoxTableHeader__name-label">{title}</div>
        {headerNames.map((headerName, i) => (
          <div
            className="CheckBoxTableHeader"
            key={`checkbox_header_${headerName}`}
          >
            <Color
              className="CheckBoxTableHeader__input-container"
              headerName={headerName}
              colordeterminator={colordeterminators[i]}
            >
              <Checkbox
                name={headerName}
                checked={determineChecked(headerName)}
                toggleCheck={this.handleToggle}
              />
            </Color>
          </div>
        ))}
      </div>
    );
  }
}

CheckBoxTableHeader.propTypes = {
  headerNames: PropTypes.array,
  title: PropTypes.string,
  colordeterminators: PropTypes.array,
  determineChecked: PropTypes.func
};

export default CheckBoxTableHeader;
