import { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { apiFetch } from "ui/store/actions/apiClient";
import AuthenticateAdmin from "ui/components/routing/AuthenticateAdmin";
import { unflatten } from "react-base-table";
import Info from "./details/Info";
import Items from "./details/Items";
import { FaArrowLeft, FaTrash, FaUserFriends, FaArchive } from "react-icons/fa";
import AdminLayout from "../AdminLayout";
import Button from "ui/components/shared/Button";
import DEFAULT_TEMPLATE_VALUES from "./newTemplateDefaults";
import { SUBCATEGORY } from "server/middleware/api/helpers/opex";
import { TemplateStatus } from "helpers/opex";
import DeleteConfirmPopup from "ui/components/admin/templates/shared/DeleteConfirmPopup";
import NotificationToast from "./shared/NotificationToast";
import { isCreditLensByGroupName } from "ui/components/opex/shared";

class TemplateDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowsData: [],
      updatedRowsData: [],
      templateDetails: {},
      isPublished: false,
      deletedCoaItemsIds: [],
      createMode: false,
      inUse: false,
      isCL: false,
      showForbiddenError: false
    };
  }

  componentDidMount() {
    const { currentUser } = this.props;
    if (currentUser) {
      let isCL = isCreditLensByGroupName(currentUser.company.group_name);
      this.setState({
        userCompany: currentUser.company,
        isCL
      });
      this.loadDetails(isCL);
    }
  }

  //Here we format the rows received from the API to a format BaseTable can handle
  //First we add the current row to our queue (acc)
  //Second we check if the row has more rows embedded, and if so we make sure to
  //include a parentId property so BaseTable knows where it belongs to when use the
  //unflatten built in function
  formatData(data) {
    return (
      data?.length &&
      data.reduce((acc, curr) => {
        acc.push(curr);
        if (curr.rows && curr.rows.length) {
          curr.rows.forEach((row, index) => {
            acc.push({
              ...row,
              parentId: curr.id,
              order: index,
              type: SUBCATEGORY
            });
          });
        }
        return acc;
      }, [])
    );
  }

  async loadDetails(isCL) {
    const templateId = location.href.split("templates/")[1];
    if (templateId !== "new") {
      const response = await apiFetch(`/api/opex/templates/${templateId}`);
      const responseBody = await response.json();
      if (!response.ok) {
        throw new Error(responseBody.error.message);
      }
      this.setState({
        rowsData: unflatten(this.formatData(responseBody.rows)),
        templateDetails: responseBody,
        isPublished: responseBody.status === "PUBLISHED",
        createMode: false,
        inUse: responseBody.document_ids.length > 0
      });
    } else {
      if (isCL) this.setState({ showForbiddenError: true });
      else this.loadNewTemplateDefaults();
    }
  }
  loadNewTemplateDefaults = () => {
    this.setState({
      rowsData: unflatten(this.formatData(DEFAULT_TEMPLATE_VALUES.rows)),
      templateDetails: DEFAULT_TEMPLATE_VALUES,
      isPublished: false,
      createMode: true
    });
  };
  updateDetails = async info => {
    let proceedToUpdateTemplate = !this.state.createMode;
    //if info is empty we send current state which means
    //we're not modifying the template details
    //that also means we can now save coa items
    if (!info.id && !this.state.createMode) {
      Object.assign(info, this.state.templateDetails);
      //we update coa items before updating the template itself
      //because if something failed but template gets published
      //user won't be able to update the coa items anymore
      proceedToUpdateTemplate = (await this.updateCoaItems()).ok;
      if (this.state.deletedCoaItemsIds.length > 0) await this.deleteCoaItems();
    }
    if (proceedToUpdateTemplate) {
      const templateId = info.id;
      const response = await apiFetch(`/api/opex/templates/${templateId}`, {
        method: "put",
        body: JSON.stringify(info)
      });
      const responseBody = await response.json();
      if (!response.ok) {
        NotificationToast("Error", "An error occurred: " + responseBody.error);
        throw new Error(responseBody.error);
      } else {
        this.setState({
          templateDetails: Object.assign({}, responseBody),
          rowsData: unflatten(this.formatData(responseBody.rows))
        });
        NotificationToast("Success", "Template saved successfully!");
        if (info.status === "PUBLISHED") this.setState({ isPublished: true });
      }
    } else
      this.setState({
        templateDetails: info
      });
  };

  changeStatus = async newStatus => {
    let info = this.state.templateDetails;
    const templateId = info.id;
    const response = await apiFetch(`/api/opex/templates/${templateId}`, {
      method: "put",
      body: JSON.stringify({ status: newStatus })
    });
    const responseBody = await response.json();
    if (!response.ok) {
      NotificationToast("Error", "An error occurred: " + responseBody.error);
      throw new Error(responseBody.error);
    } else {
      this.setState({
        templateDetails: Object.assign({}, this.state.templateDetails, {
          status: newStatus
        }),
        isPublished: newStatus === TemplateStatus.PUBLISHED
      });
      NotificationToast(
        "Success",
        `Template ${newStatus.toLowerCase()} successfully!`
      );
    }
  };

  createTemplate = async () => {
    let { name, property_types } = this.state.templateDetails;
    if (name && property_types && property_types.length > 0) {
      const response = await apiFetch(`/api/opex/templates`, {
        method: "post",
        body: JSON.stringify(
          Object.assign({}, this.state.templateDetails, {
            rows: this.state.rowsData
          })
        )
      });
      const responseBody = await response.json();
      if (!response.ok) {
        NotificationToast("Error", "An error occurred: " + responseBody.error);
        throw new Error(responseBody.error);
      } else {
        this.props.history.push(`/admin/templates/${responseBody.id}`);
        this.loadDetails(false);
      }
    } else {
      NotificationToast("Error", this.buildErrorMessage(name, property_types));
    }
  };

  buildErrorMessage = (name, propertyTypes) => {
    const hasAnyPropertyTypes = propertyTypes && propertyTypes.length > 0;
    if (!name)
      if (!hasAnyPropertyTypes)
        return "A name and at least one property type is required to create this template.";
      else return "A name is required to create this template.";
    else
      return "At least one property type is required to create this template.";
  };

  deleteTemplate = async () => {
    const templateId = this.state.templateDetails.id;
    const response = await apiFetch(`/api/opex/templates/${templateId}`, {
      method: "delete"
    });
    if (!response.ok) {
      NotificationToast("Error", "An error occurred deleting the template.");
    } else {
      // eslint-disable-next-line react/prop-types
      this.props.history.push(`/admin/templates`);
    }
  };

  updateCoaItems = async () => {
    let formattedCoaItems = this.formatCoaItems();
    const templateId = this.state.templateDetails.id;
    const response = await apiFetch(
      `/api/opex/templates/${templateId}/coa_items`,
      {
        method: "put",
        body: JSON.stringify(formattedCoaItems)
      }
    );
    const responseBody = await response.json();
    if (!response.ok) {
      NotificationToast(
        "Error",
        "An error occurred saving the COA Items: " + responseBody.error
      );
      throw new Error(responseBody.error);
    }
    return response;
  };

  deleteCoaItems = async () => {
    let coaItemsToDelete = { rows: this.state.deletedCoaItemsIds };
    const templateId = this.state.templateDetails.id;
    await apiFetch(`/api/opex/templates/${templateId}/coa_items`, {
      method: "delete",
      body: JSON.stringify(coaItemsToDelete)
    })
      .then(_ => this.setState({ coaItemsToDelete: [] }))
      .catch(error => {
        NotificationToast(
          "Error",
          "An error occurred deleting the COA items: " + error
        );
      });
  };

  updateRowsData = data => {
    this.setState({ updatedRowsData: data });
  };

  addCoaItemIdToDelete = id => {
    this.setState({
      deletedCoaItemsIds: [...this.state.deletedCoaItemsIds, id]
    });
  };

  formatCoaItems = () => {
    return {
      rows: this.state.updatedRowsData
        .map(row => (row.rows ? { rows: row.rows, id: row.id } : undefined))
        .filter(row => row !== undefined)
        .map(({ rows, id }) => {
          return {
            id,
            rows: rows
              .map((row, index) => {
                if (row.name) {
                  if (row.isNew) delete row.id;
                  return Object.assign({}, row, {
                    parent_id: row.parentId,
                    order: index
                  });
                }
              })
              .filter(row => row !== undefined)
          };
        })
    };
  };

  render() {
    return (
      <>
        <AdminLayout className="TemplatesDashboard">
          <div className="AdminTemplatePage">
            <div className="AdminTemplatePage-header">
              <FaArrowLeft
                className="AdminTemplatePage-header-backButton"
                // eslint-disable-next-line react/prop-types
                onClick={() => this.props.history.push(`/admin/templates`)}
              />
              <p>Manage templates</p>
            </div>
            {!this.state.showForbiddenError ? (
              <div className="AdminTemplatePage-body">
                <div className="AdminTemplatePage-body-left-column">
                  <Items
                    items={this.state.rowsData}
                    updateRowsData={this.updateRowsData}
                    canEdit={!this.state.isPublished && !this.state.isCL}
                    addCoaItemIdToDelete={this.addCoaItemIdToDelete}
                  />
                </div>
                <div className="AdminTemplatePage-body-right-column">
                  <Info
                    info={this.state.templateDetails}
                    updateInfo={this.updateDetails}
                    isCL={this.state.isCL}
                  />
                </div>
              </div>
            ) : (
              <div className="NewTemplateErrorMessage">
                <div>
                  Creating new Credit Lens templates is currently unavailable.
                  <br />
                  <Button
                    className="Button Button__blueBackground"
                    onClick={() => this.props.history.push(`/admin/templates`)}
                  >
                    Go back
                  </Button>
                </div>
              </div>
            )}
          </div>
        </AdminLayout>
        {!this.state.showForbiddenError && (
          <div className="AdminTemplatePage-footer">
            <div className="AdminTemplatePage-footer-left">
              {!this.state.createMode && (
                <>
                  {!this.state.inUse && (
                    <DeleteConfirmPopup
                      deleteTemplate={this.deleteTemplate}
                      description="Are you sure you want to delete this template?"
                      trigger={
                        <Button className={"Button Button__blueButton"}>
                          <FaTrash /> Delete
                        </Button>
                      }
                    />
                  )}
                  {this.state.isPublished && this.state.inUse && (
                    <Button
                      className={"Button Button__blueButton"}
                      onClick={() => this.changeStatus(TemplateStatus.ARCHIVED)}
                    >
                      <FaArchive />
                      Archive
                    </Button>
                  )}
                  {!this.state.isPublished && (
                    <Button
                      className={"Button Button__blueButton"}
                      onClick={() =>
                        this.changeStatus(TemplateStatus.PUBLISHED)
                      }
                    >
                      <FaUserFriends />
                      Publish
                    </Button>
                  )}
                  {this.state.isPublished && !this.state.inUse && (
                    <Button
                      className={"Button Button__blueButton"}
                      onClick={() =>
                        this.changeStatus(TemplateStatus.UNPUBLISHED)
                      }
                    >
                      Unpublish
                    </Button>
                  )}
                </>
              )}
            </div>
            <div className="AdminTemplatePage-footer-right">
              {!this.state.isCL && (
                <Button
                  className={"Button Button__blueBackground"}
                  onClick={() => {
                    if (!this.state.createMode) this.updateDetails({});
                    else this.createTemplate();
                  }}
                >
                  Save
                </Button>
              )}
            </div>
          </div>
        )}
      </>
    );
  }
}

TemplateDetails.propTypes = {
  currentUser: PropTypes.object,
  company: PropTypes.object,
  clearTemplatesStoreArray: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func
  })
};

function mapStateToProps({ currentUser }) {
  return { currentUser };
}

const TemplateDetailsComponent = AuthenticateAdmin(TemplateDetails);

export default connect(mapStateToProps, null)(TemplateDetailsComponent);
