import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "ui/components/shared/Button";
import Select from "react-select";
import {
  propertyTypes as PROPERTY_TYPES,
  getPropertyValues
} from "ui/components/properties/propertiesUtil";

function EditInfo(props) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [selectedPropertyTypes, setSelectedPropertyTypes] = useState([]);

  useEffect(() => {
    setName(props.info.name);
    setDescription(props.info.description || "");
    setSelectedPropertyTypes(props.info.property_types);
  }, []);

  const closeHandler = () => {
    props.onClose();
  };

  const saveHandler = () => {
    if (isValid()) {
      props.onSave({
        ...props.info,
        name,
        description,
        property_types: selectedPropertyTypes
      });
      props.onClose();
    }
  };
  const isValid = () =>
    name && selectedPropertyTypes && selectedPropertyTypes.length > 0;

  const formatPropertyTypes = ptypes => {
    if (!ptypes) return [];
    return ptypes.map(ptype => ({
      value: ptype.id || ptype,
      label:
        ptype.name ||
        getPropertyValues(props.isCL).find(pt => pt.id === ptype).name
    }));
  };

  const handleSelectProperty = data => {
    setSelectedPropertyTypes(data.map(({ value }) => value));
  };

  return (
    <div className="editinfo__popup">
      <div className="editinfo__popup__header">
        <h2>Template details</h2>
      </div>
      <div className="editinfo__popup__body">
        <p className="editinfo__popup__body__title">
          Name<span>*</span>
        </p>
        <input
          className="editinfo__popup__body__textbox"
          value={name}
          onChange={e => setName(e.target.value)}
          type="text"
        ></input>
        <p className="editinfo__popup__body__title">
          Property type<span>*</span>
        </p>
        <Select
          isMulti
          options={formatPropertyTypes(PROPERTY_TYPES)}
          defaultValue={formatPropertyTypes(props.info.property_types)}
          backspaceRemovesValue
          onChange={handleSelectProperty}
          styles={{
            control: styles => ({
              ...styles,
              marginBottom: "5px",
              borderColor: "#989898"
            })
          }}
        />
        <p className="editinfo__popup__body__title">Description</p>
        <textarea
          className="editinfo__popup__body__textbox"
          cols={40}
          rows={5}
          value={description}
          onChange={e => setDescription(e.target.value)}
        ></textarea>
      </div>
      <div className="editinfo__popup__footer">
        <Button
          className={"Button Button__blueBackground"}
          onClick={saveHandler}
          disabled={!isValid()}
        >
          Save
        </Button>
        <Button className={"Button Button__blueButton"} onClick={closeHandler}>
          Cancel
        </Button>
      </div>
    </div>
  );
}

EditInfo.propTypes = {
  info: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  isCL: PropTypes.bool
};

export default EditInfo;
