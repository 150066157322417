export const isFieldMapped = (field, mappings) => {
  // assumes mappings contains mapped fields only
  // if a mapping is lease_dates/lease_term, then if the field is start date or end date, it should be mapped
  const matchIndex = mappings.findIndex(mapping => mapping.id === field.key);
  if (field.key === "lease_start_date" || field.key === "lease_end_date") {
    if (matchIndex !== -1) {
      return true;
    } else {
      return mappings.findIndex(mapping => mapping.id === "lease_dates") !== -1;
    }
  } else {
    return matchIndex !== -1;
  }
};

export const displayRequiredFields = (requiredFields, mapped) => {
  const hasRequiredFields = requiredFields.length > 0;

  if (hasRequiredFields) {
    let count = 0;
    requiredFields.forEach(field => {
      if (isFieldMapped(field, mapped)) {
        count += 1;
      }
    });
    return (
      <div className="SourceColumns--input-block-required">
        <label className="RequiredFields-count" htmlFor="RequiredFields-count">
          {count}/{requiredFields.length} Mapped
        </label>
        <label htmlFor="RequiredFields">Required Fields Reconciliation</label>
        {requiredFields.map(item =>
          isFieldMapped(item, mapped) ? (
            <input
              name="SourceColumnRequiredFields-mapped"
              className="SourceColumnRequiredFields-mapped"
              key={item.key}
              defaultValue={item.value}
              disabled
            />
          ) : (
            <input
              name="SourceColumnRequiredFields-unmapped"
              className="SourceColumnRequiredFields-unmapped"
              key={item.key}
              defaultValue={item.value}
              disabled
            />
          )
        )}
      </div>
    );
  }
};

export const displaySelectInput = (currentValue, options) => {
  return options
    .filter(option => option.status == "show")
    .map(option => {
      if (option.displayTerm == currentValue) {
        return (
          <option value={option.term} key={option.term} selected>
            {option.displayTerm}
          </option>
        );
      } else {
        return (
          <option value={option.term} key={option.term}>
            {option.displayTerm}
          </option>
        );
      }
    });
};
