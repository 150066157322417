import { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useLocalStorage } from "ui/hooks/useLocalStorage";
import useHistory from "ui/components/rentRoll/splitPanelV2/hooks/useHistory";
import cx from "classnames";
import useSplitPanelV2Context from "ui/components/rentRoll/splitPanelV2/hooks/useSplitPanelV2Context";
import { apiFetch } from "ui/store/actions/apiClient";
import PdfEditorControls from "./PdfEditorControls";
import PdfEditorCanvas from "./PdfEditorCanvas";
import "./PdfEditor.scss";
import PdfEditorPopup from "./PdfEditorPopup";

const PdfEditor = ({ currentPageIndex, closeEditor, refreshData }) => {
  const { documentId, widgetAuth } = useSplitPanelV2Context();

  const [lines, setLines, undo, redo] = useHistory([]);
  const [hideSubmitWarning, setHideSubmitWarning] = useLocalStorage(
    "hideSubmitWarning",
    false
  );

  const [currentPage, setCurrentPage] = useState(currentPageIndex);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [image, setImage] = useState(null);

  const [drawingMode, setDrawingMode] = useState("line");
  const [isDrawing, setIsDrawing] = useState(false);
  const [boxCounter, setBoxCounter] = useState(0);

  const [strokeStyle, setStrokeStyle] = useState("black");
  const [lineWidth, setLineWidth] = useState(2);
  const [gridRows, setGridRows] = useState(0);

  const [selectedLine, setSelectedLine] = useState(null);

  const [editsMade, setEditsMade] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [showSubmitWarning, setShowSubmitWarning] = useState(false);
  const [showSaveWarning, setShowSaveWarning] = useState(false);
  const [showPrevPageWarning, setShowPrevPageWarning] = useState(false);
  const [showNextPageWarning, setShowNextPageWarning] = useState(false);
  const [showGridNumberPrompt, setShowGridNumberPrompt] = useState(false);

  const canvasRef = useRef(null);
  const contextRef = useRef(null);
  const canvasContainerRef = useRef(null);
  const imageDimensions = useRef({});

  const previousDisabled = currentPage > 0 ? false : true;
  const nextDisabled = currentPage < numberOfPages - 1 ? false : true;

  useEffect(() => {
    if (!isProcessing) {
      setMessage(`Loading Page ${currentPage + 1}`);
      getOriginalImages();
    }
  }, [currentPage, isProcessing]);

  useEffect(async () => {
    if (image) {
      buildCanvas();
    }
  }, [image]);

  useEffect(async () => {
    reDrawCanvas(image, lines);
  }, [lines]);

  const getOriginalImages = async () => {
    const response = await apiFetch(
      `/api/pdf-editor/get-original-images/${documentId}`,
      { method: "get" },
      widgetAuth
    );
    const originalImages = await response.json();
    setNumberOfPages(originalImages.length);
    const img = new Image();
    img.onload = async () => {
      setMessage("");
      setImage(img);
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      contextRef.current = ctx;
    };
    img.src = originalImages[currentPage];
  };

  const scaleLinesToFitCanvas = lines => {
    return lines.map(line => {
      const { start, end } = line;
      const scale = canvasRef.current.width / line.canvasWidth;
      const scaledStart = { x: start.x * scale, y: start.y * scale };
      const scaledEnd = { x: end.x * scale, y: end.y * scale };

      return {
        ...line,
        start: scaledStart,
        end: scaledEnd,
        canvasWidth: canvasRef.current.width,
        imageWidth: image.naturalWidth
      };
    });
  };

  const buildCanvas = async () => {
    if (image) {
      let imageHeight = image.naturalHeight;
      let imageWidth = image.naturalWidth;
      const canvasMaxWidth = canvasContainerRef.current.clientWidth;
      if (imageWidth > canvasMaxWidth) {
        const aspectRatio = image.naturalHeight / image.naturalWidth;

        imageHeight = Math.round(canvasMaxWidth * aspectRatio);
        imageWidth = canvasMaxWidth;
      }

      imageDimensions.current = {
        ...imageDimensions.current,
        height: imageHeight,
        width: imageWidth
      };

      canvasRef.current.height = imageHeight;
      canvasRef.current.width = imageWidth;

      const { lines } = await getDrawingData();
      const scaledLines = scaleLinesToFitCanvas(lines);
      setLines(scaledLines, true);
    }
  };

  const clearCanvas = () => {
    if (contextRef.current !== null) {
      contextRef.current.clearRect(
        0,
        0,
        canvasRef.current.width,
        canvasRef.current.height
      );
    }
  };

  const adjustLineCoordinates = line => {
    const { start, end } = line;

    if (start.x < end.x || (start.x === end.x && start.y < end.y)) {
      return { start, end };
    } else {
      return { start: end, end: start };
    }
  };

  const reDrawCanvas = (image, lines) => {
    clearCanvas();
    if (contextRef.current !== null) {
      contextRef.current.drawImage(
        image,
        0,
        0,
        canvasRef.current.width,
        canvasRef.current.height
      );
      lines.forEach(line => {
        if (line.page === currentPage) drawLine(line);
      });
    }
  };

  const drawLine = line => {
    const ctx = contextRef.current;
    ctx.lineWidth = line.lineWidth;
    ctx.lineCap = "round";
    ctx.strokeStyle = line.strokeStyle;

    ctx.beginPath();
    ctx.moveTo(line.start.x, line.start.y);
    ctx.lineTo(line.end.x, line.end.y);
    ctx.stroke();
  };

  const createLine = (id, start, end, color, width, type) => {
    const line = {
      id,
      start,
      end,
      lineWidth: width,
      page: currentPage,
      strokeStyle: color,
      canvasWidth: canvasRef.current.width,
      imageWidth: image.naturalWidth,
      type
    };
    return line;
  };

  const getMousePosition = ({ nativeEvent }) => {
    const { offsetX, offsetY } = nativeEvent;
    const position = { x: offsetX, y: offsetY };
    return position;
  };

  const distance = (a, b) =>
    Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2));

  const nearPoint = (x, y, x1, y1, name) => {
    return Math.abs(x - x1) < 5 && Math.abs(y - y1) < 5 ? name : null;
  };

  const positionWithinLine = (position, line) => {
    const a = line.start;
    const b = line.end;
    const c = position;
    const offset = distance(a, b) - (distance(a, c) + distance(b, c));
    const start = nearPoint(position.x, position.y, a.x, a.y, "start");
    const end = nearPoint(position.x, position.y, b.x, b.y, "end");
    const inside = Math.abs(offset) < 0.4 ? "inside" : null;
    return start || end || inside;
  };

  const getElementAtPosition = (position, lines) => {
    return lines
      .map(line => ({ ...line, position: positionWithinLine(position, line) }))
      .find(line => line.position !== null);
  };

  const updateLine = (id, start, end, color, width, type, lines) => {
    const line = createLine(id, start, end, color, width, type);

    const linesCopy = [...lines];
    linesCopy[id] = line;
    setLines(linesCopy, true);
    setEditsMade(true);
  };

  const updateGrid = (id, x1, x2, y1, y2, lines) => {
    const type = "grid";
    let index = id;
    let step = (y2 - y1) / gridRows;

    const gridLines = [];

    for (let i = 0; i < gridRows - 1; i++) {
      const gridLineStart = { x: x1, y: y1 + step * (i + 1) };
      const gridLineEnd = { x: x2, y: y1 + step * (i + 1) };
      const gridLine = createLine(
        index,
        gridLineStart,
        gridLineEnd,
        strokeStyle,
        lineWidth,
        type,
        lines
      );
      gridLines.push(gridLine);
      index++;
    }
    index--;

    const rectangleCoordinates = [
      { start: { x: x1, y: y1 }, end: { x: x1, y: y2 } },
      { start: { x: x1, y: y2 }, end: { x: x2, y: y2 } },
      { start: { x: x2, y: y2 }, end: { x: x2, y: y1 } },
      { start: { x: x2, y: y1 }, end: { x: x1, y: y1 } }
    ];
    const newRectangleLines = rectangleCoordinates.map((line, i) => {
      const type = i % 2 === 0 ? "line" : "grid";
      const { start, end } = line;
      index++;
      return createLine(index, start, end, strokeStyle, lineWidth, type, lines);
    });

    const linesCopy = [...lines];
    linesCopy.splice(lines.length - (gridRows + 3), gridRows + 3);

    setLines([...linesCopy, ...gridLines, ...newRectangleLines], true);
  };

  const cursorForPosition = (position, type) => {
    if (position === "start" || position === "end") return "nwse-resize";
    return type === "grid" ? "row-resize" : "move";
  };

  const handleDrawing = event => {
    if (drawingMode === "box") {
      if (boxCounter <= 2) {
        setIsDrawing(true);
        const mouseDownPosition = getMousePosition(event);

        const line = createLine(
          lines.length - 1,
          mouseDownPosition,
          mouseDownPosition,
          strokeStyle,
          lineWidth,
          drawingMode
        );

        setLines(prevState => [...prevState, line]);
        setBoxCounter(prevState => prevState + 1);
      } else {
        if (boxCounter === 3) {
          const index = lines.length - 1;
          const lastLine = lines[index];
          const firstLine = lines[index - 2];

          const line = createLine(
            index + 1,
            lastLine.end,
            firstLine.start,
            strokeStyle,
            lineWidth,
            drawingMode
          );

          setLines([...lines, line]);
          setBoxCounter(0);
        }

        setBoxCounter(0);
        setIsDrawing(false);
        setEditsMade(true);
      }
    }
    if (drawingMode === "line" || drawingMode === "eraser") {
      if (isDrawing === false) {
        setIsDrawing(true);
        const mouseDownPosition = getMousePosition(event);
        const width = drawingMode === "eraser" ? lineWidth * 2 : lineWidth;
        const line = createLine(
          lines.length - 1,
          mouseDownPosition,
          mouseDownPosition,
          strokeStyle,
          width,
          drawingMode
        );

        setLines(prevState => [...prevState, line]);
      } else {
        setIsDrawing(false);
        setEditsMade(true);
        const index = lines.length - 1;
        const { id, strokeStyle, lineWidth, type } = lines[index];

        const { start, end } = adjustLineCoordinates(lines[index]);
        updateLine(id, start, end, strokeStyle, lineWidth, type, lines);
      }
    }
  };

  const updateIds = lines => {
    return lines.map((line, index) => {
      if (line.id !== index) line.id = index;
      return line;
    });
  };

  const handleMouseDown = event => {
    if (drawingMode === "select") {
      const position = getMousePosition(event);
      const line = getElementAtPosition(position, lines);
      if (line) {
        const offsetX = position.x - line.start.x;
        const offsetY = position.y - line.start.y;
        setSelectedLine({ ...line, offsetX, offsetY });
        setLines(prevState => prevState);
      }
    }
    if (drawingMode === "delete") {
      const position = getMousePosition(event);
      const line = getElementAtPosition(position, lines);
      if (line) {
        setSelectedLine(line);
      }
    }
    if (drawingMode === "grid") {
      const startingCorner = getMousePosition(event);
      let id = lines.length;

      const gridLines = [];
      for (let i = 0; i <= gridRows + 2; i++) {
        const line = createLine(
          id,
          startingCorner,
          startingCorner,
          strokeStyle,
          lineWidth,
          drawingMode
        );
        gridLines.push(line);
        id++;
      }
      setLines([...lines, ...gridLines]);
      setIsDrawing(true);
    }
  };

  const setCursor = (mode, event) => {
    if (mode === "delete") return "default";
    if (mode === "select") {
      const line = getElementAtPosition(getMousePosition(event), lines);
      return line ? cursorForPosition(line.position, line.type) : "default";
    }
    return "crosshair";
  };

  const movePosition = event => {
    event.target.style.cursor = setCursor(drawingMode, event);

    if (drawingMode === "select") {
      if (selectedLine && selectedLine.position === "inside") {
        const { id, start, end, strokeStyle, lineWidth, type } = selectedLine;
        const width = end.x - start.x;
        const height = end.y - start.y;
        const position = getMousePosition(event);
        const newX = position.x - selectedLine.offsetX;
        const newY = position.y - selectedLine.offsetY;

        updateLine(
          id,
          { x: type === "grid" ? start.x : newX, y: newY },
          { x: type === "grid" ? end.x : newX + width, y: newY + height },
          strokeStyle,
          lineWidth,
          type,
          lines
        );
      }

      if (selectedLine && selectedLine.position === "start") {
        const { id, end, strokeStyle, lineWidth, type } = selectedLine;
        const newStart = getMousePosition(event);
        updateLine(id, newStart, end, strokeStyle, lineWidth, type, lines);
      }

      if (selectedLine && selectedLine.position === "end") {
        const { id, start, strokeStyle, lineWidth, type } = selectedLine;
        const newEnd = getMousePosition(event);
        updateLine(id, start, newEnd, strokeStyle, lineWidth, type, lines);
      }
    } else {
      if (isDrawing) {
        const index = lines.length - 1;
        const { start, strokeStyle, lineWidth, type } = lines[index];

        updateLine(
          index,
          start,
          getMousePosition(event),
          strokeStyle,
          lineWidth,
          type,
          lines
        );
      }
      if (drawingMode === "grid" && isDrawing) {
        let id = lines.length - (gridRows + 3);
        const x1 = lines[lines.length - 4].start.x;
        const y1 = lines[lines.length - 4].start.y;
        const x2 = getMousePosition(event).x;
        const y2 = getMousePosition(event).y;

        updateGrid(id, x1, x2, y1, y2, lines);
      }
    }
  };

  const handleMouseUp = () => {
    if (drawingMode === "select") setSelectedLine(null);
    if (drawingMode === "grid") {
      setIsDrawing(false);
    }
    if (selectedLine && drawingMode === "delete") {
      const { id } = selectedLine;
      const linesCopy = [...lines];
      linesCopy.splice(id, 1);
      const updatedLines = updateIds(linesCopy);
      setLines(updatedLines);
      setSelectedLine(null);
      setEditsMade(true);
    }
  };

  const handleSettingDrawingMode = (mode, color) => {
    setDrawingMode(mode);
    setStrokeStyle(color);
    if (mode === "grid") {
      setShowGridNumberPrompt(true);
    }
  };

  const handleSettingLineWidth = width => setLineWidth(width);

  const goToNextPageClick = () => {
    if (editsMade) {
      setShowNextPageWarning(true);
    } else {
      goToNextPage();
    }
  };

  const goToNextPage = () => {
    setShowNextPageWarning(false);
    clearCanvas();
    setImage(null);
    setCurrentPage(currentPage + 1);
    setEditsMade(false);
  };

  const goToPrevPageClick = () => {
    if (editsMade) {
      setShowPrevPageWarning(true);
    } else {
      goToPrevPage();
    }
  };

  const goToPrevPage = () => {
    setShowPrevPageWarning(false);
    clearCanvas();
    setImage(null);
    setCurrentPage(currentPage - 1);
    setEditsMade(false);
  };

  const undoChange = () => {
    setEditsMade(true);
    undo();
  };

  const redoChange = () => {
    setEditsMade(true);
    redo();
  };

  const clearAll = () => {
    setEditsMade(true);
    const newLines = lines.filter(line => line.page !== currentPage);
    setLines(newLines);
    reDrawCanvas(image, newLines);
  };

  const closeModal = () => {
    if (editsMade) setShowSaveWarning(true);
    else closeEditor();
  };

  const getDrawingData = async () => {
    try {
      const response = await apiFetch(
        `/api/pdf-editor/retrieve-drawing-edit-data/${documentId}`,
        { method: "get" },
        widgetAuth
      );
      const responseBody = await response.json();
      if (!response.ok) {
        throw new Error(responseBody.error.message);
      }
      return responseBody;
    } catch (error) {
      const { status, statusText, data } = error.response;
      setErrorMessage(`${status}: ${statusText} - "${data}"`);
    }
  };

  const saveDrawingData = async () => {
    setEditsMade(false);
    const drawingData = {
      lines
    };
    try {
      await apiFetch(
        `/api/pdf-editor/store-drawing-edit-data/${documentId}`,
        {
          method: "put",
          body: JSON.stringify({ drawingData })
        },
        widgetAuth
      );
    } catch (error) {
      const { status, statusText, data } = error.response;
      setErrorMessage(`${status}: ${statusText} - "${data}"`);
    }
  };

  const submitEditedImage = async () => {
    try {
      setIsProcessing(true);
      await saveDrawingData();
      await apiFetch(
        `/api/pdf-editor/submit-drawing/${documentId}/${currentPage}`,
        { method: "post" },
        widgetAuth
      );
      await refreshData();
      if (numberOfPages === 1) closeEditor();
      else {
        setIsProcessing(false);
      }
    } catch (error) {
      setIsProcessing(false);
      if (error.response) {
        const { status, statusText, data } = error.response;
        setErrorMessage(`${status}: ${statusText} - "${data}"`);
      }
    }
  };

  const handleSubmitEditedImage = (isChecked = false) => {
    if (isChecked) setHideSubmitWarning(true);
    closeSubmitWarningPopup();
    submitEditedImage();
  };

  const handleSubmitButtonClick = () => {
    if (hideSubmitWarning) submitEditedImage();
    else setShowSubmitWarning(true);
  };

  const closeSubmitWarningPopup = () => {
    setShowSubmitWarning(false);
  };

  const closeSaveWarningPopup = () => {
    setShowSaveWarning(false);
  };

  const closeGridNumberPrompt = () => {
    setShowGridNumberPrompt(false);
  };

  const closeNextPageWarning = () => {
    setShowNextPageWarning(false);
  };

  const closePrevPageWarning = () => {
    setShowPrevPageWarning(false);
  };

  const handleGridNumberPromptSubmit = numberOfRows => {
    setGridRows(+numberOfRows);
    closeGridNumberPrompt();
  };

  return (
    <div
      className={cx("SplitPanelV2__PdfEditor", {
        "SplitPanelV2__PdfEditor-processing": isProcessing,
        SplitPanelV2__PdfEditor__widget: widgetAuth
      })}
    >
      {!isProcessing && (
        <PdfEditorControls
          numberOfPages={numberOfPages}
          previousDisabled={previousDisabled}
          nextDisabled={nextDisabled}
          goToNextPageClick={goToNextPageClick}
          goToPrevPageClick={goToPrevPageClick}
          handleSettingDrawingMode={handleSettingDrawingMode}
          drawingMode={drawingMode}
          handleSettingLineWidth={handleSettingLineWidth}
          lineWidth={lineWidth}
          clearAll={clearAll}
          undoChange={undoChange}
          redoChange={redoChange}
          saveDrawingData={saveDrawingData}
          editsMade={editsMade}
          handleSubmitButtonClick={handleSubmitButtonClick}
          closeModal={closeModal}
        />
      )}
      <PdfEditorCanvas
        message={message}
        image={image}
        isProcessing={isProcessing}
        currentPage={currentPage}
        canvasRef={canvasRef}
        handleDrawing={handleDrawing}
        handleMouseDown={handleMouseDown}
        handleMouseUp={handleMouseUp}
        movePosition={movePosition}
        canvasContainerRef={canvasContainerRef}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />
      {showSubmitWarning && (
        <PdfEditorPopup
          header="WARNING:"
          message="Editing the source document will reset the document ingestion process, and any previous work done to map fields + manually edited data will be lost. Do you wish to continue?"
          submitButtonText={"Submit Edits"}
          handleSubmit={handleSubmitEditedImage}
          closePopup={closeSubmitWarningPopup}
          inputUsed={false}
          warningPrompt={true}
        />
      )}
      {showSaveWarning && (
        <PdfEditorPopup
          header="WARNING:"
          message="You have unsaved edits. Click OK to disregard edits and close the editor."
          submitButtonText={"OK"}
          handleSubmit={closeEditor}
          closePopup={closeSaveWarningPopup}
          inputUsed={false}
        />
      )}
      {showGridNumberPrompt && (
        <PdfEditorPopup
          header="GRID ROWS:"
          message="How many rows are needed for the grid?"
          submitButtonText={"Submit"}
          handleSubmit={handleGridNumberPromptSubmit}
          closePopup={closeGridNumberPrompt}
          inputUsed={true}
        />
      )}
      {showNextPageWarning && (
        <PdfEditorPopup
          header="WARNING:"
          message="You have unsaved edits. Click OK to disregard edits and move to next page."
          submitButtonText={"OK"}
          handleSubmit={goToNextPage}
          closePopup={closeNextPageWarning}
          inputUsed={false}
        />
      )}
      {showPrevPageWarning && (
        <PdfEditorPopup
          header="WARNING:"
          message="You have unsaved edits. Click OK to disregard edits and move to previous page."
          submitButtonText={"OK"}
          handleSubmit={goToPrevPage}
          closePopup={closePrevPageWarning}
          inputUsed={false}
        />
      )}
    </div>
  );
};

PdfEditor.propTypes = {
  pageImage: PropTypes.string,
  currentPageIndex: PropTypes.number,
  closeEditor: PropTypes.func,
  refreshData: PropTypes.func
};

export default PdfEditor;
