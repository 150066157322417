import PropTypes from "prop-types";
import cx from "classnames";
import Header from "../Header/Header";
import Tabs from "../RentRoll/components/Tabs/Tabs";
import Actions from "../RentRoll/components/Actions/Actions";
import FORM_STATES from "../../helpers/formStates";

export default function LoadingSkeleton({ isWidgetAuthorized }) {
  return (
    <>
      {!isWidgetAuthorized && <Header />}
      <div
        className={cx("SplitPanelV2 SplitPanelV2__Skeleton", {
          ["SplitPanelV2-widget"]: isWidgetAuthorized
        })}
      >
        <div className="SplitPanelV2__LeftSidebar SplitPanelV2__LeftSidebar-open" />
        <div className="SplitPanelV2__contents">
          <div className="SplitPanelV2__Document" />
          <div className="SplitPanelV2__RentRoll">
            <Tabs
              formState={FORM_STATES.HEADERS}
              toggleExceptionsSidebar={() => {}}
            />
            <div className="SplitPanelV2__RentRoll-body" />
            <Actions
              formState=""
              isSaving={false}
              disabled={true}
              handleNextClick={() => {}}
              handlePrevClick={() => {}}
              hasNextActiveTable={true}
              hasPrevActiveTable={false}
            />
          </div>
        </div>
      </div>
    </>
  );
}

LoadingSkeleton.propTypes = {
  isWidgetAuthorized: PropTypes.bool.isRequired
};
