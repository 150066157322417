import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Cell } from "react-sticky-table";

const NonEditableHeader = ({
  column,
  tableRefs,
  className,
  selectedProperty,
  fieldNamesMappingForDropDown
}) => (
  <>
    <Cell
      ref={ref => (tableRefs.current[column.key] = ref)}
      className={className}
    >
      {fieldNamesMappingForDropDown[
        selectedProperty.property_type.toLowerCase()
      ][column.dataIndex]
        ? fieldNamesMappingForDropDown[
            selectedProperty.property_type.toLowerCase()
          ][column.dataIndex]
        : column.dataIndex === "is_vacant"
        ? " Is Vacant"
        : column.dataIndex}
    </Cell>
  </>
);

NonEditableHeader.propTypes = {
  column: PropTypes.object,
  fieldNamesMappingForDropDown: PropTypes.object,
  selectedProperty: PropTypes.object,
  tableRefs: PropTypes.object,
  className: PropTypes.string
};

const mapStateToProps = ({ properties, rentRoll }) => ({
  selectedProperty: properties.selectedProperty,
  fieldNamesMappingForDropDown: rentRoll.fieldNamesMappingForDropDown
});

export default connect(mapStateToProps)(NonEditableHeader);
