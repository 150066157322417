import { apiFetch } from "ui/store/actions/apiClient";

export async function fetchFieldsByPropertyType(propertyType, widgetAuth = {}) {
  try {
    const response = await apiFetch(
      `/api/rent-roll/fields/property-type/${propertyType}`,
      {},
      widgetAuth
    );
    if (!response.ok) {
      const responseBody = await response.json();
      throw new Error(responseBody.error);
    }
    const responseBody = await response.json();
    return responseBody;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export async function updateFieldsByPropertyType(
  propertyType,
  newFields,
  widgetAuth = {}
) {
  try {
    const response = await apiFetch(
      `/api/rent-roll/fields/property-type/${propertyType}`,
      { method: "put", body: JSON.stringify({ fields: newFields }) },
      widgetAuth
    );
    if (!response.ok) {
      const responseBody = await response.json();
      throw new Error(responseBody.error);
    }
    const responseBody = await response.json();
    return responseBody;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export async function fetchRentRollColumns() {
  try {
    const response = await apiFetch(`/api/rent-roll/get-columns`);
    if (!response.ok) {
      const responseBody = await response.json();
      throw new Error(responseBody.error);
    }
    const responseBody = await response.json();
    return responseBody;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}
