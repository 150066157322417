import useSWR from "swr";
import updateData from "../../../helpers/updateData";
import fetchData from "../../../helpers/fetchData";
import useSplitPanelV2Context from "../../../hooks/useSplitPanelV2Context";

export default function useRentRoll() {
  const { documentId, widgetAuth } = useSplitPanelV2Context();

  const { data, error, mutate } = useSWR(
    `/api/rent-roll/effective-date-exception/${documentId}`,
    fetchData(widgetAuth)
  );

  async function update(data) {
    await updateData({
      path: `/api/rent-roll/effective-date-exception/${documentId}`,
      method: "post",
      data,
      widgetAuth
    });
  }

  return {
    effectiveDateStatus:
      data && data.exception && data.exception.length
        ? data.exception[0].status
        : null,
    error,
    mutate,
    isLoading: !error && !data,
    update
  };
}
