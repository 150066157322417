import PropTypes from "prop-types";
import cx from "classnames";
import FORM_STATES from "../../../../helpers/formStates";
import { companyGroupNames } from "helpers/companies";
import useDocument from "ui/components/rentRoll/splitPanelV2/hooks/useDocument";
import { getPublishedDate } from "ui/components/rentRoll/property/helpers/documents";

export default function Actions({
  disabled,
  formState,
  handleNextClick,
  handlePrevClick,
  hasNextActiveTable,
  hasPrevActiveTable,
  isSaving,
  completeDoc,
  isSubmitting,
  docStatus,
  setAddingRow,
  addingRow,
  groupName,
  disablePublishButton
}) {
  const { document } = useDocument();

  const isHeaderStep = formState === FORM_STATES.HEADERS;
  const isDataStep = formState === FORM_STATES.DATA;
  const isFirstStep = !hasPrevActiveTable && isHeaderStep;
  const isLastStep = !hasNextActiveTable && isDataStep;
  const isComplete = docStatus === "COMPLETED";
  const isCpmCompany = groupName === companyGroupNames.CPM.id;
  const actionDescription =
    groupName === companyGroupNames.CPM.id ? "Published" : "Completed";
  const publishedDate = getPublishedDate(document);

  return (
    <div className="SplitPanelV2__RentRoll__Actions">
      {isFirstStep ? (
        <span />
      ) : (
        <>
          <button
            onClick={handlePrevClick}
            className="SplitPanelV2__RentRoll__Actions-button"
            disabled={disabled || isSaving}
          >
            Back
          </button>
        </>
      )}
      {!isHeaderStep && isDataStep ? (
        <button
          className={"SplitPanelV2__RentRoll__Actions-button"}
          onClick={() => setAddingRow(true)}
          disabled={addingRow}
        >
          Add Row
        </button>
      ) : (
        ""
      )}
      {!isFirstStep && isDataStep && isComplete && (
        <div
          className={cx("SplitPanelV2__LeftSidebar-completed-on-timestamp", {
            "SplitPanelV2__LeftSidebar-completed-on-timestamp--hidden": isSubmitting
          })}
        >
          <img src={require("ui/images/green-tick.svg")} />
          <span>
            {`${actionDescription} at ${publishedDate.format(
              "HH:mm:ss A"
            )} on ${publishedDate.format("MMMM DD, YYYY")}`}
          </span>
        </div>
      )}
      {isLastStep && isComplete && !isCpmCompany ? null : (
        <button
          onClick={isLastStep ? completeDoc : handleNextClick}
          disabled={
            disabled ||
            isSaving ||
            isSubmitting ||
            (disablePublishButton && isLastStep)
          }
          className={cx("SplitPanelV2__RentRoll__Actions-button", {
            ["SplitPanelV2__RentRoll__Actions-button--saving"]: isSaving
          })}
        >
          {isSaving
            ? "Saving..."
            : isLastStep
            ? isSubmitting
              ? "Submitting..."
              : isCpmCompany
              ? "Publish"
              : "Mark As Complete"
            : "Save & Continue"}
        </button>
      )}
    </div>
  );
}

Actions.propTypes = {
  disabled: PropTypes.bool.isRequired,
  formState: PropTypes.string.isRequired,
  handleNextClick: PropTypes.func.isRequired,
  handlePrevClick: PropTypes.func.isRequired,
  hasNextActiveTable: PropTypes.bool.isRequired,
  hasPrevActiveTable: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  completeDoc: PropTypes.func,
  isSubmitting: PropTypes.bool,
  docStatus: PropTypes.string,
  setAddingRow: PropTypes.func,
  addingRow: PropTypes.bool,
  groupName: PropTypes.string,
  disablePublishButton: PropTypes.string
};
