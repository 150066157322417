import PropTypes from "prop-types";
import { connect } from "react-redux";

import HeaderWithSlidebar from "ui/components/shared/HeaderWithSidebar";
import Subheader from "ui/components/shared/Subheader";

const SplitPanelHeader = ({
  className,
  subHeader,
  button,
  children,
  includeTopNav
}) => {
  return includeTopNav ? (
    <HeaderWithSlidebar
      className={className}
      subHeader={subHeader}
      button={button}
    >
      {children}
    </HeaderWithSlidebar>
  ) : (
    <Subheader className={className} subHeader={subHeader} button={button}>
      {children}
    </Subheader>
  );
};

SplitPanelHeader.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  subHeader: PropTypes.any,
  button: PropTypes.any,
  includeTopNav: PropTypes.bool
};

function mapStateToProps(state) {
  return {
    sidebarTabSelected: state.headerSidebar.sidebarTabSelected,
    exceptionSidebarIsActive: state.rentRoll.exceptionSidebarIsActive
  };
}

export default connect(mapStateToProps)(SplitPanelHeader);
