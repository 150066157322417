export const ENTITY_BADGE_BVD = "bvd";
export const ENTITY_BADGE_EXISTING = "existing";
export const ENTITY_BADGE_USER_DEFINED = "userDefined";
export const ENTITY_BADGE_CORTERA = "cortera";
export const ENTITY_BADGE_SAFEGRAPH = "safegraph";
const ENTITY_MAPPING_FIELD_NAME = "tenant_name";

export function getPredefsNeedAttention(
  rentRollDocument,
  rentRoll,
  fieldName = ENTITY_MAPPING_FIELD_NAME
) {
  const rawData = rentRollDocument.raw_data_json;
  let predefsNeedAttention = [];

  for (let pi = 0; pi < rawData.pages.length; pi++) {
    for (let ti = 0; ti < rawData.pages[pi].tableData.length; ti++) {
      for (let ri = 0; ri < rawData.pages[pi].tableData[ti].length; ri++) {
        for (
          let ci = 0;
          ci < rawData.pages[pi].tableData[ti][ri].length;
          ci++
        ) {
          if (
            rawData.pages[pi].tableData[ti][ri][ci].predef &&
            !rawData.pages[pi].tableData[ti][ri][ci].predef.id &&
            rawData.pages[pi].tableData[ti][ri][ci].predef.field &&
            rawData.pages[pi].tableData[ti][ri][ci].predef.field === fieldName
          ) {
            //get corresponding rentRoll object and check its status
            const rentRollObject = rentRoll.find(
              o =>
                o.doc_page_id === pi &&
                o.doc_table_id === ti &&
                o.doc_row_ids.includes(ri)
            );
            if (rentRollObject && rentRollObject.status !== "DOCUMENT_DEL") {
              predefsNeedAttention.push({
                text: rawData.pages[pi].tableData[ti][ri][ci].text,
                location: { pi, ti, ri, ci },
                predef: rawData.pages[pi].tableData[ti][ri][ci].predef
              });
            }
          }
        }
      }
    }
  }
  let flags = {};
  return predefsNeedAttention.filter(predef => {
    if (flags[predef.text]) {
      return false;
    }
    flags[predef.text] = true;
    return true;
  });
}

export function getPredefsResolved(rentRollDocument, headerData) {
  const rawData = rentRollDocument.raw_data_json;
  let predefsNeedAttention = [];
  let pages = [];

  for (let pi = 0; pi < rawData.pages.length; pi++) {
    pages.push([]);
    for (let ti = 0; ti < headerData[pi].length; ti++) {
      pages[pi].push([]);
      for (let ci = 0; ci < headerData[pi][ti].headers.length; ci++) {
        if (headerData[pi][ti].headers[ci].id === ENTITY_MAPPING_FIELD_NAME) {
          pages[pi][ti] = ci;
        }
      }
    }
  }

  const tenantNameLocation = pages;

  for (let pi = 0; pi < rawData.pages.length; pi++) {
    for (let ti = 0; ti < rawData.pages[pi].tableData.length; ti++) {
      for (let ri = 0; ri < rawData.pages[pi].tableData[ti].length; ri++) {
        for (
          let ci = 0;
          ci < rawData.pages[pi].tableData[ti][ri].length;
          ci++
        ) {
          if (
            rawData.pages[pi].tableData[ti][ri][ci].predef &&
            rawData.pages[pi].tableData[ti][ri][ci].predef.id &&
            tenantNameLocation[pi][ti] === ci
          ) {
            predefsNeedAttention.push({
              text: rawData.pages[pi].tableData[ti][ri][ci].text,
              location: { pi, ti, ri, ci },
              predef: rawData.pages[pi].tableData[ti][ri][ci].predef
            });
          }
        }
      }
    }
  }
  let flags = {};
  return predefsNeedAttention.filter(predef => {
    if (flags[predef.text]) {
      return false;
    }
    flags[predef.text] = true;
    return true;
  });
}

export function getPredefField(
  headerData,
  fieldName = ENTITY_MAPPING_FIELD_NAME
) {
  let field;

  for (let p = 0; p < headerData.length; p++) {
    for (let t = 0; t < headerData[p].length; t++) {
      for (let c = 0; c < headerData[p][t].headers.length; c++) {
        if (fieldName === headerData[p][t].headers[c].id) {
          field = headerData[p][t].headers[c].id;
          break;
        }
      }
    }
  }

  return field;
}

export function setPredefMetadata(suggestion) {
  let field = {};
  if (suggestion && suggestion.metadata) {
    field.metadata = { ...suggestion.metadata };
  } else {
    if (suggestion && suggestion.address) {
      field.metadata = { address: suggestion.address };
    }
    if (suggestion && suggestion.industry) {
      field.metadata = { ...field.metadata, industry: suggestion.industry };
    }
    if (suggestion && suggestion.ownershipName) {
      field.metadata = {
        ...field.metadata,
        ownershipName: suggestion.ownershipName
      };
    }
    if (suggestion && suggestion.ownershipId) {
      field.metadata = {
        ...field.metadata,
        ownershipId: suggestion.ownershipId
      };
    }
    if (suggestion && suggestion.position_type) {
      field.metadata = {
        ...field.metadata,
        position_type: suggestion.position_type
      };
    }
    if (suggestion && suggestion.employee_range) {
      field.metadata = {
        ...field.metadata,
        employee_range: suggestion.employee_range
      };
    }
    if (suggestion && suggestion.sales_range) {
      field.metadata = {
        ...field.metadata,
        sales_range: suggestion.sales_range
      };
    }
    if (suggestion && suggestion.ultimate_name) {
      field.metadata = {
        ...field.metadata,
        ultimate_name: suggestion.ultimate_name
      };
    }
    if (suggestion && suggestion.sub_category) {
      field.metadata = {
        ...field.metadata,
        sub_category: suggestion.sub_category
      };
    }
  }
  return field;
}
