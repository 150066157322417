import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { updatePageIndex } from "ui/store/actions/rentRoll";

const ExcelPageChanger = ({ rentRollDocument, pageIndex, updatePageIndex }) => {
  const [selected, setSelected] = useState(pageIndex);

  useEffect(() => {
    setSelected(pageIndex);
  }, [pageIndex]);

  return (
    <>
      {rentRollDocument.excel_workbook.SheetNames.map((sheet, idx) => (
        <div
          key={`sheet-${idx}`}
          style={{
            cursor: "pointer",
            fontWeight: idx === selected ? "bold" : "normal",
            marginRight: "5px"
          }}
          onClick={() => {
            setSelected(idx);
            updatePageIndex(idx);
          }}
        >
          {sheet}
        </div>
      ))}
    </>
  );
};

ExcelPageChanger.propTypes = {
  updatePageIndex: PropTypes.func,
  rentRollDocument: PropTypes.object,
  pageIndex: PropTypes.number
};

const mapStateToProps = ({ rentRoll }) => ({
  rentRollDocument: rentRoll.document,
  pageIndex: rentRoll.pageIndex
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updatePageIndex }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ExcelPageChanger);
