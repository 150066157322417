import {
  UPDATE_HEADER_TAB_INDEX,
  UPDATE_PANEL_TAB_INDEX,
  UPDATE_SELECTED_DOC_ID,
  RESET_UI_TO_DEFAULT
} from "../actions/propertyRentRollViewUI";

export default function propertyRentRollViewUI(
  state = {
    headerTabIndex: 0,
    panelTabIndex: 0,
    docId: undefined
  },
  action
) {
  switch (action.type) {
    case UPDATE_HEADER_TAB_INDEX: {
      const { headerTabIndex } = action;
      return {
        ...state,
        headerTabIndex
      };
    }
    case UPDATE_PANEL_TAB_INDEX: {
      const { panelTabIndex } = action;
      return {
        ...state,
        panelTabIndex
      };
    }
    case UPDATE_SELECTED_DOC_ID: {
      const { docId } = action;
      return {
        ...state,
        docId
      };
    }
    case RESET_UI_TO_DEFAULT: {
      const newState = state;
      delete newState.docId;
      return {
        ...newState,
        headerTabIndex: 0,
        panelTabIndex: 0
      };
    }
    default: {
      return state;
    }
  }
}
