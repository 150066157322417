import PropTypes from "prop-types";
import { GoTriangleRight, GoTriangleDown } from "react-icons/go";

const CustomDropdownLabel = ({ expanded, label, onClick }) => (
  <div
    width="100%"
    style={{
      padding: "1rem 0 1rem 1rem",
      display: "flex",
      alignItems: "center",
      cursor: "pointer"
    }}
    onClick={() => onClick(prev => !prev)}
  >
    <span style={{ marginRight: ".3rem", fontWeight: "bold" }}>
      {expanded ? <GoTriangleDown /> : <GoTriangleRight />}
    </span>
    <span style={{ fontWeight: "bold" }}>{label}</span>
  </div>
);

export default CustomDropdownLabel;

CustomDropdownLabel.propTypes = {
  label: PropTypes.string,
  expanded: PropTypes.bool,
  onClick: PropTypes.func
};
