import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "ui/components/shared/Button";
import moment from "moment";
import { getPropertyValues } from "ui/components/properties/propertiesUtil";
import StatusPill from "ui/components/admin/templates/shared/StatusPill";
import { FaEllipsisV } from "react-icons/fa";
import ActionsDropDown from "ui/components/admin/templates/shared/ActionsDropDown";

function TemplateCard({
  name,
  description,
  externalRefId,
  types,
  created,
  createdBy,
  updated,
  updatedBy,
  pathname,
  inUse,
  isPublished,
  isArchived,
  isCL,
  templateId,
  updateTemplate
}) {
  const [showMenu, setShowMenu] = useState(false);
  useEffect(() => {
    const handler = () => setShowMenu(false);
    window.addEventListener("click", handler);
    return () => {
      window.removeEventListener("click", handler);
    };
  });
  const propertyTypes = getPropertyValues(isCL);

  const handleOptionsButtonClick = e => {
    e.stopPropagation();
    setShowMenu(!showMenu);
  };

  return (
    <div className="TemplateCard">
      <div className="TemplateCard-header">
        <div className="TemplateCard-header__Name">
          {name}
          {inUse && <StatusPill />}
        </div>
        <div className="TemplateCard-header-options">
          <Button
            className={"Button Button__blueBackground"}
            pathname={pathname}
            style={{ margin: "0px", height: "30px" }}
          >
            Open
          </Button>
          <span
            className="TemplateCard-header-options__MoreButton"
            onClick={handleOptionsButtonClick}
          >
            <FaEllipsisV />
            {showMenu && (
              <ActionsDropDown
                inUse={inUse}
                isPublished={isPublished}
                templateId={templateId}
                updateTemplate={updateTemplate}
                isArchived={isArchived}
                isCL={isCL}
              />
            )}
          </span>
        </div>
      </div>
      <div className="TemplateCard-body">
        <div className="TemplateCard-body-description">{`${description} ${
          externalRefId ? `(${externalRefId})` : ""
        }`}</div>
        <div className="TemplateCard-body-tags">
          {types &&
            types.map(type => (
              <div key={type} className="TemplateCard-body-tags-tag">
                {propertyTypes.find(ptype => ptype.id === type).name}
              </div>
            ))}
        </div>
      </div>
      <hr className="TemplateCard__separator" />
      <div className="TemplateCard-footer">
        <div className="TemplateCard-footer-left">
          <div className="TemplateCard-footer-left__created">
            Created on {moment(created).format("MM/DD/YYYY")}
          </div>
          <br />
          <div className="TemplateCard-footer-left__createdBy">
            By {createdBy}
          </div>
        </div>
        <div className="TemplateCard-footer-right">
          <div className="TemplateCard-footer-right__created">
            Updated on {moment(updated).format("MM/DD/YYYY")}
          </div>
          <br />
          <span className="TemplateCard-footer-right__createdBy">
            By {updatedBy}
          </span>
        </div>
      </div>
    </div>
  );
}

TemplateCard.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  externalRefId: PropTypes.string,
  types: PropTypes.array,
  created: PropTypes.string,
  createdBy: PropTypes.string,
  updated: PropTypes.string,
  updatedBy: PropTypes.string,
  pathname: PropTypes.string,
  inUse: PropTypes.bool,
  isPublished: PropTypes.bool,
  isArchived: PropTypes.bool,
  isCL: PropTypes.bool,
  templateId: PropTypes.number,
  updateTemplate: PropTypes.func
};

export default TemplateCard;
