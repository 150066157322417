import { useState } from "react";
import PropTypes from "prop-types";

const ALL_TABLES = "ALL";
const ONE_TABLE = "ONE";
export const IgnoreTablePopup = props => {
  const [selectedValue, setSelectedValue] = useState(ALL_TABLES);

  return (
    <div>
      <div className="IgnoreTablePopup__popup-box">
        <div className="IgnoreTablePopup__box">
          {props.ignoreTablePopupOption &&
          props.ignoreTablePopupOption.option === ALL_TABLES ? (
            <div>
              This will remove any current header mappings for this table
            </div>
          ) : (
            <>
              <div>This table structure is used elsewhere in the document</div>
              <div>
                <input
                  type="radio"
                  name="table-structure"
                  onClick={() => setSelectedValue(ONE_TABLE)}
                />
                ignore just this table
              </div>
              <div>
                <input
                  type="radio"
                  name="table-structure"
                  checked="checked"
                  onClick={() => setSelectedValue(ALL_TABLES)}
                />
                always ignore this type of table
              </div>
            </>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              justifyContent: "space-between",
              marginTop: "10px"
            }}
          >
            <button
              className="IgnoreTablePopup__updateButton"
              onClick={async () => {
                props.setIsIgnoreTablePopupOpen(false);
                await props.removeHeaderMapping(
                  selectedValue,
                  props.ignoreTablePopupOption.headerIndex,
                  props.ignoreTablePopupOption.metaName
                );
              }}
            >
              Update
            </button>
            <button
              className="IgnoreTablePopup__updateButton"
              style={{ marginLeft: "5px", color: "gray" }}
              onClick={() => {
                props.setIsIgnoreTablePopupOpen(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
      <div
        className="IgnoreTablePopup__popupOverlay"
        onClick={() => {
          props.setIsIgnoreTablePopupOpen(false);
        }}
      ></div>
    </div>
  );
};

IgnoreTablePopup.propTypes = {
  setIsIgnoreTablePopupOpen: PropTypes.func,
  removeHeaderMapping: PropTypes.func,
  ignoreTablePopupOption: PropTypes.object
};

export default IgnoreTablePopup;
export { ALL_TABLES, ONE_TABLE };
