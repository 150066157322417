import { apiFetch } from "./apiClient";

export const COMPANY_TEMPLATES_FETCHING = "COMPANY_TEMPLATES_FETCHING";
export const COMPANY_TEMPLATES_FETCH_SUCCESSFUL = "COMPANY_TEMPLATES_FETCH_SUCCESSFUL";
export const COMPANY_TEMPLATES_FETCH_FAILED = "COMPANY_TEMPLATES_FETCH_FAILED";
export const CLEAR_COMPANY_TEMPLATES_STORE = "CLEAR_COMPANY_TEMPLATES_STORE";

export function clearCompanyTemplatesStore() {
    return {
        type: CLEAR_COMPANY_TEMPLATES_STORE
    };
}

function companyTemplatesFetching() {
    return {
        type: COMPANY_TEMPLATES_FETCHING
    };
}

function companyTemplatesFetchFailed(error) {
    return {
        error,
        type: COMPANY_TEMPLATES_FETCH_FAILED
    };
}
function companyTemplatesFetchSuccessful(templates) {
    return {
        templates,
        type: COMPANY_TEMPLATES_FETCH_SUCCESSFUL
    };
}
export function fetchCompanyTemplates() {
    return async function (dispatch) {
        dispatch(companyTemplatesFetching());
        try {
            const response = await apiFetch(`/api/opex/templates`);
            const responseBody = await response.json();
            if (!response.ok) {
                throw new Error(responseBody.error.message);
            }
            dispatch(companyTemplatesFetchSuccessful(responseBody));
        } catch (error) {
            dispatch(companyTemplatesFetchFailed(error));
        }
    };
}