import { Button } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isCPMByGroupName } from "ui/components/opex/shared";
import NavLink from "ui/components/shared/NavLink";

const ManageFilesButton = ({ currentUser }) =>
  !!currentUser?.widgetAuth?.authorized &&
  isCPMByGroupName(currentUser.company.group_name) && (
    <div className="ManageFilesButton">
      <NavLink className="action-nav" to={{ pathname: "/manage-files-view" }}>
        <Button className="ManageFilesButton--button">
          <img src={require("ui/images/icon-arrow-back.svg")} />
          <span>Manage Files</span>
        </Button>
      </NavLink>
    </div>
  );

ManageFilesButton.propTypes = {
  currentUser: PropTypes.shape({
    company: PropTypes.shape({ group_name: PropTypes.string }),
    widgetAuth: PropTypes.shape({ token: PropTypes.string })
  })
};

export default connect(state => ({
  currentUser: state.currentUser
}))(ManageFilesButton);
