import Status from "ui/components/routing/Status";

export default function NotFound() {
  return (
    <Status code={404}>
      <div style={{ textAlign: "center", margin: "50px" }}>
        <div style={{ fontSize: "48px", lineHeight: "150%" }}>404</div>
        <div style={{ fontSize: "32px", lineHeight: "150%" }}>Not Found</div>
        <div style={{ fontSize: "18px", lineHeight: "150%" }}>
          The requested resource could not be found.
        </div>
      </div>
    </Status>
  );
}
