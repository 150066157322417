import { blankRow, getItemsByType, subcategoryNames } from "./processRows";
import { deepCopy } from "services/helpers/objectUtils";
import { isEmpty } from "ramda";
import { getFormattedRow } from "./excelFormatting";
import { singleTab, whiteFillColor, subcategoryColor } from "./excelFormatting";

export const processSubcategoryViewCL = (
  opexTableData,
  ignoredColumnsIdx,
  NoiAndNcf
) => {
  const subcategoryViewNoiAndNcf = deepCopy(NoiAndNcf);
  const subcategories = getItemsByType(opexTableData, "subcategory");
  const subcategoriesBeyondLvlTwo = subcategories.filter(subcategory => {
    return subcategory.beyondLvlTwo === true;
  });
  const subcategoriesLvlOne = subcategories.filter(subcategory => {
    return subcategory.beyondLvlTwo !== true;
  });
  const subcategoriesHeader = deepCopy(subcategoriesLvlOne);

  const filterIgnoredAccountsAndTotals = columns => {
    return columns.reduce((arr, column, index) => {
      if (!ignoredColumnsIdx.includes(index))
        arr.push(parseFloat(column.value));
      return arr;
    }, []);
  };

  const getFormattedTotalsAndValues = (name, values, fillColor) => {
    return [getFormattedRow(name, fillColor, "bold")].concat(
      filterIgnoredAccountsAndTotals(values).map(value => {
        return getFormattedRow(value, fillColor, "boldNumericValue");
      })
    );
  };

  const getFormattedSubcategoriesAndValues = (name, values, fillColor) => {
    return [getFormattedRow(name, fillColor, "nameNoBorders")].concat(
      filterIgnoredAccountsAndTotals(values).map(value => {
        return getFormattedRow(value, fillColor, "numericValueNoBorders");
      })
    );
  };

  const exportedRows = [];
  let subcategoryLvlTwoPrevParentId = subcategoriesBeyondLvlTwo[0].parentId;
  let subcategoryLvlOne;
  // pop off the first subcategory header row
  let subcategoryHeader = subcategoriesHeader.shift().name;
  exportedRows.push([
    getFormattedRow(subcategoryHeader, null, "boldNoBorders")
  ]);

  for (let k = 0; k < subcategoriesBeyondLvlTwo.length; k += 1) {
    // if subcategories share a parent subcategory, keep pushing them
    if (
      subcategoryLvlTwoPrevParentId === subcategoriesBeyondLvlTwo[k].parentId
    ) {
      exportedRows.push([
        ...getFormattedSubcategoriesAndValues(
          singleTab + subcategoriesBeyondLvlTwo[k].name,
          subcategoriesBeyondLvlTwo[k].totals,
          whiteFillColor
        )
      ]);
      subcategoryLvlTwoPrevParentId = subcategoriesBeyondLvlTwo[k].parentId;
    } else if (
      subcategoriesBeyondLvlTwo[k].parentId !== subcategoryLvlTwoPrevParentId &&
      !isEmpty(subcategoriesLvlOne)
    ) {
      subcategoryLvlOne = subcategoriesLvlOne.shift();
      let dontSkip = subcategoryLvlOne.totals || false;
      if (dontSkip) {
        exportedRows.push([
          ...getFormattedTotalsAndValues(
            `Total ${subcategoryLvlOne.name}`,
            subcategoryLvlOne.totals,
            subcategoryColor
          )
        ]);
        if (
          (subcategoryLvlOne.name ===
            subcategoryNames.VARIABLE_OPERATING_EXPENSES ||
            subcategoryLvlOne.name ===
              subcategoryNames.FIXED_OPERATING_EXPENSES) &&
          subcategoryNames.NOI in subcategoryViewNoiAndNcf
        ) {
          exportedRows.push(blankRow, subcategoryViewNoiAndNcf.NOI, blankRow);
          delete subcategoryViewNoiAndNcf.NOI;
        } else if (
          subcategoryLvlOne.name === subcategoryNames.OTHER_EXPENSES &&
          subcategoryNames.NCF in subcategoryViewNoiAndNcf
        ) {
          exportedRows.push(blankRow, subcategoryViewNoiAndNcf.NCF, blankRow);
          delete subcategoryViewNoiAndNcf.NCF;
        }
      }
      subcategoryHeader = subcategoriesHeader.shift().name;
      exportedRows.push([
        getFormattedRow(subcategoryHeader, null, "boldNoBorders")
      ]);

      exportedRows.push([
        ...getFormattedSubcategoriesAndValues(
          singleTab + subcategoriesBeyondLvlTwo[k].name,
          subcategoriesBeyondLvlTwo[k].totals,
          whiteFillColor
        )
      ]);
      subcategoryLvlTwoPrevParentId = subcategoriesBeyondLvlTwo[k].parentId;
    }
    // final iteration, we'll push the last subcategory total
    if (k === subcategoriesBeyondLvlTwo.length - 1) {
      if (!isEmpty(subcategoriesLvlOne)) {
        subcategoryLvlOne = subcategoriesLvlOne.shift();
        let dontSkip = subcategoryLvlOne.totals || false;
        if (dontSkip) {
          exportedRows.push([
            ...getFormattedTotalsAndValues(
              `Total ${subcategoryLvlOne.name}`,
              subcategoryLvlOne.totals || [
                {
                  value: 0
                }
              ],
              subcategoryColor
            )
          ]);
          if (
            (subcategoryLvlOne.name ===
              subcategoryNames.VARIABLE_OPERATING_EXPENSES ||
              subcategoryLvlOne.name ===
                subcategoryNames.FIXED_OPERATING_EXPENSES) &&
            subcategoryNames.NOI in subcategoryViewNoiAndNcf
          ) {
            exportedRows.push(blankRow, subcategoryViewNoiAndNcf.NOI, blankRow);
            delete subcategoryViewNoiAndNcf.NOI;
          } else if (
            subcategoryLvlOne.name === subcategoryNames.OTHER_EXPENSES &&
            subcategoryNames.NCF in subcategoryViewNoiAndNcf
          ) {
            exportedRows.push(blankRow, subcategoryViewNoiAndNcf.NCF, blankRow);
            delete subcategoryViewNoiAndNcf.NCF;
          }
        }
      }
    }
  }
  return exportedRows;
};
