import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import cx from "classnames";
import { hasAdminAccess } from "helpers/authorization";

import {
  createNewPredefAndUpdateField,
  fetchPredefSugestions,
  predefSugestionCleared,
  updateDocumentField,
  deletePredefValue
} from "ui/store/actions/rentRoll";
import { setPredefMetadata } from "./helpers/util";

import ThreeBounceSpinner from "ui/components/spinners/ThreeBounceSpinner";
import RadioLabel from "./RadioLabel";

const EntitySearch = ({
  rentRollDocument,
  selectedField,
  selectedValue,
  selectedLocation,
  selectedTab,
  changeView,
  fetchPredefSugestions,
  predefSugestionCleared,
  predefValues,
  updateDocumentField,
  createNewPredefAndUpdateField,
  widgetAuth,
  userRole,
  deletePredefValue
}) => {
  const documentId = rentRollDocument.id;
  const { pi, ti, ri, ci } = selectedLocation;
  const [createNew, setCreateNew] = useState(false);
  const [searching, setSearching] = useState(false);
  const [createdNewPredef, setCreatedNewPredef] = useState(false);
  const [selectedPredef, setSelectedPredef] = useState();

  useEffect(() => {
    if (
      rentRollDocument &&
      rentRollDocument.raw_data_json &&
      rentRollDocument.raw_data_json.pages
    ) {
      const cell =
        rentRollDocument.raw_data_json.pages[pi].tableData[ti][ri][ci];
      if (cell && cell.predef) {
        setSelectedPredef(cell.predef.id);
      }
    }

    return () => {
      setSelectedPredef();
    };
  }, [rentRollDocument]);

  useEffect(() => {
    (async () => {
      setSearching(true);
      await fetchPredefSugestions(
        documentId,
        selectedValue,
        selectedField,
        widgetAuth
      );
      setSearching(false);
    })();
    return () => {
      predefSugestionCleared();
    };
  }, []);

  const handleInputUpdate = async e => {
    if (e.key === "Enter" && e.target.value) {
      setSearching(true);
      await fetchPredefSugestions(
        documentId,
        e.target.value,
        selectedField,
        widgetAuth
      );
      setSearching(false);
    }
  };

  const handleSubmitNewPredef = e => {
    if (e.target["create-new-predef"].value !== "") {
      (async () => {
        await createNewPredefAndUpdateField(
          e.target["create-new-predef"].value,
          selectedField,
          documentId,
          {
            page: pi,
            table: ti,
            row: ri,
            col: ci
          },
          widgetAuth
        );
      })();
    }
  };

  const handleClick = async (e, s) => {
    const { pi, ti, ri, ci } = selectedLocation;
    const field = {
      page: pi,
      table: ti,
      row: ri,
      col: ci,
      text: s.value,
      predefValueId: s.id,
      ref: s.ref
    };
    field.metadata = { ...setPredefMetadata(s).metadata };
    await updateDocumentField(field, documentId, widgetAuth);
  };

  const clearSearchInput = inputId => {
    document.getElementById(inputId).value = "";
  };

  const removePredefValue = async (e, predefId, predefValue) => {
    if (confirm(`Are you sure you want to delete '${predefValue}'?`)) {
      await deletePredefValue(predefId);
      setSearching(true);
      await fetchPredefSugestions(
        documentId,
        selectedValue,
        selectedField,
        widgetAuth
      );
      setSearching(false);
    } else {
      e.preventDefault();
    }
  };

  return (
    <div>
      <div className="EntitySearch__header">
        <div className="EntitySearch__selectedValue">
          <img
            src={require("ui/images/icon-arrow-back.svg")}
            style={{ cursor: "pointer" }}
            onClick={() => changeView("entity-view", selectedTab)}
          />
          <div style={{ marginLeft: "10px" }}>{selectedValue}</div>
        </div>
        <div style={{ marginLeft: "35px" }}>{`Issue:`}</div>
      </div>
      <div className="EntitySearch__inputContainer">
        <div className="EntitySearch__inputLabel">
          Search Term: (please edit for different results)
        </div>
        <div className="EntitySearch__inputDiv">
          <input
            id="search-input"
            className="EntitySearch__input"
            type="text"
            defaultValue={selectedValue}
            onKeyDown={handleInputUpdate}
          />
          <img
            src={require("ui/images/x-icon.svg")}
            style={{ cursor: "pointer" }}
            onClick={() => clearSearchInput("search-input")}
          />
        </div>
      </div>
      <div className="EntitySearch__listContainer">
        {searching ? (
          <ThreeBounceSpinner />
        ) : predefValues && predefValues.length > 0 ? (
          predefValues.map(predef => (
            <div key={`${selectedField}-${predef.value}`}>
              <input
                type="radio"
                name={selectedField}
                defaultChecked={selectedPredef && selectedPredef === predef.id}
                onClick={e => handleClick(e, predef)}
              />
              <RadioLabel
                selectedField={selectedField}
                predef={predef}
                userRole={userRole}
                isRadioSelected={selectedPredef && selectedPredef === predef.id}
                removePredefValue={removePredefValue}
              />
            </div>
          ))
        ) : (
          <div style={{ marginTop: "15px" }}>No enttities found.</div>
        )}
      </div>
      {hasAdminAccess(userRole) ? (
        !createNew ? (
          <div style={{ padding: "20px 0" }}>
            If no results match, click:{" "}
            <button
              className={cx("Button", "Button__blueButton")}
              onClick={() => setCreateNew(true)}
            >
              Create New
            </button>
          </div>
        ) : !createdNewPredef ? (
          <form
            style={{ display: "flex", flexDirection: "column" }}
            onSubmit={e => {
              e.preventDefault();
              handleSubmitNewPredef(e);
              setCreatedNewPredef(true);
            }}
          >
            <label
              htmlFor="create-new-predef"
              style={{ fontSize: "14px", marginBottom: "5px" }}
            >
              Type in new Entity Name:{" "}
            </label>
            <div className="EntitySearch__inputDiv">
              <input
                id="create-new-predef"
                className="EntitySearch__input"
                type="text"
                defaultValue={selectedValue}
                name="create-new-predef"
              />
              <img
                src={require("ui/images/x-icon.svg")}
                style={{ cursor: "pointer" }}
                onClick={() => clearSearchInput("create-new-predef")}
              />
            </div>
            <div style={{ marginTop: "10px" }}>
              <button
                className={cx("Button", "Button__blackButton")}
                style={{ marginRight: "5px" }}
                onClick={() => setCreateNew(false)}
              >
                Cancel
              </button>
              <input
                type="submit"
                className={cx("Button", "Button__blueButton")}
                value="Create New"
              />
            </div>
          </form>
        ) : (
          <div>Created new Entity</div>
        )
      ) : (
        ""
      )}
    </div>
  );
};

EntitySearch.defaultProps = {
  widgetAuth: {}
};

EntitySearch.propTypes = {
  selectedField: PropTypes.string,
  selectedValue: PropTypes.string,
  selectedTab: PropTypes.number,
  fetchPredefSugestions: PropTypes.func,
  predefSugestionCleared: PropTypes.func,
  updateDocumentField: PropTypes.func,
  changeView: PropTypes.func,
  createNewPredefAndUpdateField: PropTypes.func,
  rentRollDocument: PropTypes.object,
  predefValues: PropTypes.array,
  selectedLocation: PropTypes.object,
  widgetAuth: PropTypes.object,
  userRole: PropTypes.string,
  deletePredefValue: PropTypes.func
};

const mapStateToProps = ({ rentRoll, currentUser }) => ({
  rentRollDocument: rentRoll.document,
  predefValues: rentRoll.predefValues,
  isPredefLoading: rentRoll.isPredefLoading,
  widgetAuth: currentUser.widgetAuth,
  userRole: currentUser.role
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createNewPredefAndUpdateField,
      fetchPredefSugestions,
      predefSugestionCleared,
      updateDocumentField,
      deletePredefValue
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(EntitySearch);
