import PropTypes from "prop-types";
import _ from "lodash";

const OpexSelect = ({ onChange, value, options, undefinedOption }) => {
  let arrayOfOptions = [];

  if (undefinedOption) {
    arrayOfOptions.push(
      <option key="undefined" value="undefined">
        &mdash;
      </option>
    );
  }

  arrayOfOptions = arrayOfOptions.concat(
    _.map(options, option => (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    ))
  );

  return (
    <select
      className="OpexSelect"
      onChange={e => {
        let value = e.target.value;
        if (value === "undefined") {
          value = undefined;
        }
        onChange(value);
      }}
      value={value === undefined ? "undefined" : value}
    >
      {arrayOfOptions}
    </select>
  );
};

export default OpexSelect;

OpexSelect.propTypes = {
  onChange: PropTypes.func,
  undefinedOption: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  )
};
