export function documentDrill(document, cb) {
  document.raw_data_json.pages.forEach((page, pageIndex) => {
    page.tableData.forEach((table, tableIndex) => {
      table.forEach((row, rowIndex) => {
        row.forEach((data, columnIndex) => {
          cb({
            page,
            pageIndex,
            table,
            tableIndex,
            row,
            rowIndex,
            data,
            columnIndex
          });
        });
      });
    });
  });
}
