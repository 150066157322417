import { Component, createRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import cx from "classnames";
import { bindActionCreators } from "redux";

import { uploadRentRollDocumentWithoutProperty } from "ui/store/actions/companies";
import { setIntervalRunning } from "ui/store/actions/statusBar";

import DragPanel from "ui/components/main/upload/DragPanel";
import StatusTable from "ui/components/main/upload/StatusTable";
import Button from "ui/components/shared/Button";
import NavLink from "ui/components/shared/NavLink.jsx";

export class UploadPanel extends Component {
  constructor(props) {
    super(props);
    this.panelRef = createRef();
    this.manageFilesView = props.manageFilesView;
  }

  componentDidMount() {
    window.addEventListener("click", this.closeOutsideDiv);
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.closeOutsideDiv);
  }

  closeOutsideDiv = e => {
    const { closePopover } = this.props;
    if (e.target !== this.panelRef) {
      closePopover();
    }
  };

  submitFile = file => {
    const {
      uploadRentRollDocumentWithoutProperty,
      setIntervalRunning,
      widgetAuth
    } = this.props;
    uploadRentRollDocumentWithoutProperty(file, widgetAuth);
    setIntervalRunning(true);
  };

  render() {
    return (
      <div className="UploadPanel__panel" ref={this.panelRef}>
        <DragPanel saveDroppedFile={this.submitFile} />
        <StatusTable />
        <div className="UploadPanel__bottomBar">
          <NavLink to={{ pathname: "/manage-files-view" }}>
            <Button
              className={cx("UploadPanel__bottomButton", "Button__blueButton")}
              style={{
                fontFamily: "Roboto",
                fontWeight: "normal",
                fontSize: "12px",
                borderRadius: "3px",
                textTransform: "capitalize",
                height: "70%"
              }}
            >
              Manage All Documents
            </Button>
          </NavLink>
        </div>
      </div>
    );
  }
}

UploadPanel.propTypes = {
  uploadRentRollDocumentWithoutProperty: PropTypes.func,
  closePopover: PropTypes.func,
  setIntervalRunning: PropTypes.func,
  widgetAuth: PropTypes.object,
  manageFilesView: PropTypes.bool
};

function mapStateToProps(state) {
  return {
    uploadingDocs: state.rentRoll.uploadingDocs,
    widgetAuth: state.currentUser.widgetAuth
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { uploadRentRollDocumentWithoutProperty, setIntervalRunning },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadPanel);
