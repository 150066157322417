import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { reducer as reduxAsyncConnect } from "redux-connect";

import authenticated from "ui/store/reducers/authenticated";
import flash from "ui/store/reducers/flash";
import currentUser from "ui/store/reducers/currentUser";
import users from "ui/store/reducers/users";
import loginError from "ui/store/reducers/loginError";
import companies from "ui/store/reducers/companies";
import mainLoginPage from "ui/store/reducers/mainLoginPage";
import rentRoll from "ui/store/reducers/rentRoll";
import portfolios from "ui/store/reducers/portfolios";
import properties from "ui/store/reducers/properties";
import accountMenu from "ui/store/reducers/accountMenu";
import tenants from "ui/store/reducers/tenants";
import headerSidebar from "ui/store/reducers/headerSidebar";
import splitScreen from "ui/store/reducers/splitScreen";
import filters from "ui/store/reducers/filters";
import propertyRentRollViewUI from "ui/store/reducers/propertyRentRollViewUI";
import statusBar from "ui/store/reducers/statusBar";
import s2sAuthentication from "ui/store/reducers/s2sAuthentication";
import usageAnalytics from "ui/store/reducers/usageAnalytics";
import companyTemplates from "ui/store/reducers/companyTemplates"

const rootReducer = combineReducers({
  reduxAsyncConnect,
  authenticated,
  loginError,
  flash,
  currentUser,
  users,
  companies,
  mainLoginPage,
  rentRoll,
  properties,
  tenants,
  portfolios,
  accountMenu,
  headerSidebar,
  splitScreen,
  filters,
  propertyRentRollViewUI,
  statusBar,
  s2sAuthentication,
  usageAnalytics,
  companyTemplates,
  form: formReducer
});

export default rootReducer;
