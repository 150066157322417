import { apiFetch } from "./apiClient";
import queryString from "query-string";
import axios from "axios";
import Cookies from "js-cookie";
import { stringify } from "query-string";

import { updateCompletedAt } from "./splitScreen";

export const FETCHING_DOCUMENTS = "FETCHING_DOCUMENTS";
export const FETCHING_DOCUMENT = "FETCHING_DOCUMENT";
export const FETCHING_RENT_ROLL = "FETCHING_RENT_ROLL";
export const FETCHED_DOCUMENTS_SUCCESSFUL = "FETCHED_DOCUMENTS_SUCCESSFUL";
export const FETCHED_RENT_ROLL_SUCCESSFUL = "FETCHED_RENT_ROLL_SUCCESSFUL";
export const FETCHED_RENT_ROLL_BY_DOCID_SUCCESSFUL =
  "FETCHED_RENT_ROLL_BY_DOCID_SUCCESSFUL";
export const REMOVE_UPLOAD_DOC_BY_ID = "REMOVE_UPLOAD_DOC_BY_ID";
export const UPDATE_UPLOAD_STATUS = "UPDATE_UPLOAD_STATUS";
export const FETCHED_ALL_RENT_ROLLS_SUCCESSFUL =
  "FETCHED_ALL_RENT_ROLLS_SUCCESSFUL";
export const FETCHED_DOCUMENT_SUCCESSFUL = "FETCHED_DOCUMENT_SUCCESSFUL";
export const FETCHED_UPLOAD_DOCUMENT_BY_DOCID_SUCCESSFUL =
  "FETCHED_UPLOAD_DOCUMENT_BY_DOCID_SUCCESSFUL";
export const UPDATED_DOCUMENT_TEMPLATE_SUCCESSFUL =
  "UPDATED_DOCUMENT_TEMPLATE_SUCCESSFUL";
export const UPDATING_DOCUMENT_TEMPLATE = "UPDATING_DOCUMENT_TEMPLATE";
export const UPDATED_DOCUMENT_FIELD_SUCCESSFUL =
  "UPDATED_DOCUMENT_FIELD_SUCCESSFUL";
export const FETCHED_RENT_ROLL_COMPOSITION_SUCCESSFUL =
  "FETCHED_RENT_ROLL_COMPOSITION_SUCCESSFUL";
export const FETCHED_RENT_ROLL_ANALYTICS_SUCCESSFUL =
  "FETCHED_RENT_ROLL_ANALYTICS_SUCCESSFUL";
export const UPDATING_DOCUMENT_FIELD = "UPDATING_DOCUMENT_FIELD";
export const PROCESSING_DOCUMENT = "PROCESSING_DOCUMENT";
export const UPDATE_EDIT_HEADER = "UPDATE_EDIT_HEADER";
export const CLEAR_EDIT_HEADER = "CLEAR_EDIT_HEADER";
export const UPDATE_EDIT_TEXT = "UPDATE_EDIT_TEXT";
export const CLEAR_EDIT_TEXT = "CLEAR_EDIT_TEXT";
export const UPDATE_DOCUMENT_CHECK = "UPDATE_DOCUMENT_CHECK";
export const UPDATE_CACHE = "UPDATE_CACHE";
export const CLEAR_CACHE = "CLEAR_CACHE";

export const UPDATE_EXCEPTION_SIDEBAR = "UPDATE_EXCEPTION_SIDEBAR";
export const UPDATE_PAGE_INDEX = "UPDATE_PAGE_INDEX";
export const UPDATE_PDF_MARKDOWN_LOADING = "UPDATE_PDF_MARKDOWN_LOADING";
export const UPDATE_PDF_MARKDOWN_LOADED = "UPDATE_PDF_MARKDOWN_LOADED";

export const EXCEPTIONS_LOADING = "EXCEPTIONS_LOADING";
export const EXCEPTIONS_LOADED = "EXCEPTIONS_LOADED";
export const UPDATING_TABLE_STATUS = "UPDATING_TABLE_STATUS";
export const TABLE_STATUS_UPDATED = "TABLE_STATUS_UPDATED";
export const UPDATING_HEADER_STATUS = "UPDATING_HEADER_STATUS";
export const HEADER_STATUS_UPDATED = "HEADER_STATUS_UPDATED";
export const UPDATING_RENT_ROLL_FIELDS = "UPDATING_RENT_ROLL_FIELDS";
export const UPDATE_RENT_ROLL_FIELDS_SUCCESSFUL =
  "UPDATE_RENT_ROLL_FIELDS_SUCCESSFUL";
export const FETCHING_RENT_ROLL_FIELDS = "FETCHING_RENT_ROLL_FIELDS";
export const FETCH_RENT_ROLL_FIELDS_SUCCESSFUL =
  "FETCH_RENT_ROLL_FIELDS_SUCCESSFUL";
export const MARK_AS_COMPLETE = "MARK_AS_COMPLETE";

export const PREDEF_SUGESTION_LOADING = "PREDEF_SUGESTION_LOADING";
export const PREDEF_SUGESTION_UPDATED = "PREDEF_SUGESTION_UPDATED";
export const PREDEF_SUGESTION_CLEARED = "PREDEF_SUGESTION_CLEARED";

export const NEW_PREDEF_SUGESTION_CREATING = "PREDEF_SUGESTION_CREATING";
export const NEW_PREDEF_SUGESTION_CREATED = "PREDEF_SUGESTION_CREATED";

export const UPDATING_DOCUMENT = "UPDATING_DOCUMENT";
export const UPDATED_DOCUMENT_SUCCESSFUL = "UPDATED_DOCUMENT_SUCCESSFUL";
export const UPDATED_DOCUMENT_SUCCESSFUL_NO_STORE =
  "UPDATED_DOCUMENT_SUCCESSFUL_NO_STORE";

export const UPDATING_UPLOADED_DOCS = "UPDATING_UPLOADED_DOCS";
export const UPDATED_UPLOADED_DOCS_SUCCESSFUL =
  "UPDATED_UPLOADED_DOCS_SUCCESSFUL";
export const UPDATE_NUM_UPLOADS_IN_PROGRESS = "UPDATE_NUM_UPLOADS_IN_PROGRESS";

export const ADDING_ROW_TO_RENT_ROLL = "ADDING_ROW_TO_RENT_ROLL";
export const ADDED_ROW_TO_RENT_ROLL = "ADDED_ROW_TO_RENT_ROLL";

export const REMOVE_ROW_FROM_RENT_ROLL = "REMOVE_ROW_FROM_RENT_ROLL";
export const REMOVED_ROW_FROM_RENT_ROLL = "REMOVED_ROW_FROM_RENT_ROLL";

export const UNDO_REMOVE_ROW_FROM_RENT_ROLL = "UNDO_REMOVE_ROW_FROM_RENT_ROLL";
export const UNDO_REMOVE_ROW_FROM_RENT_ROLL_SUCCESSFUL =
  "UNDO_REMOVE_ROW_FROM_RENT_ROLL_SUCCESSFUL";
export const REMOVED_RENT_ROLL_ROW_EXCEPTIONS =
  "REMOVED_RENT_ROLL_ROW_EXCEPTIONS";
export const REMOVE_RENT_ROLL_ROW_EXCEPTIONS =
  "REMOVE_RENT_ROLL_ROW_EXCEPTIONS";
export const REINSTATE_RENT_ROLL_ROW_EXCEPTIONS =
  "REINSTATE_RENT_ROLL_ROW_EXCEPTIONS";
export const REINSTATE_RENT_ROLL_ROW_EXCEPTIONS_SUCCESSFUL =
  "REINSATE_RENT_ROLL_ROW_EXCEPTIONS_SUCCESSFUL";

export const REFRESH_SPLIT_PANEL = "REFRESH_SPLIT_PANEL";
export const REFRESHING_SPLIT_PANEL = "REFRESHING_SPLIT_PANEL";
export const SPLIT_PANEL_REFRESHED = "SPLIT_PANEL_REFRESHED";
export const CLEAR_SPLIT_PANEL = "CLEAR_SPLIT_PANEL";

export const FETCHING_DOCUMENT_PDF = "FETCHING_DOCUMENT_PDF";
export const FETCHING_DOCUMENT_URL_SUCCESSFUL =
  "FETCHING_DOCUMENT_URL_SUCCESSFUL";
export const EXCEL_MARKDOWN_LOADING = "EXCEL_MARKDOWN_LOADING";
export const EXCEL_MARKDOWN_LOADED = "EXCEL_MARKDOWN_LOADED";

export const UPDATE_DOCUMENT_TEMPLATE_SUCCESSFUL =
  "UPDATE_DOCUMENT_TEMPLATE_SUCCESSFUL";

export const FETCHED_COMPANY_DOCUMENTS_SUCCESSFULLY =
  "rentRoll/FETCHED_COMPANY_DOCUMENTS_SUCCESSFULLY";

export const FETCHING_COMPANY_DOCUMENTS = "rentRoll/FETCHING_COMPANY_DOCUMENTS";

export const ASSIGNING_PROPERTY_TO_DOCUMENT =
  "rentRoll/ASSIGNING_PROPERTY_TO_DOCUMENT";

export const ASSIGNED_PROPERTY_TO_DOCUMENT =
  "rentRoll/ASSIGNED_PROPERTY_TO_DOCUMENT";

export const ASSIGNING_EXCEPTION_TO_EFFECTIVE_DATE =
  "ASSIGNING_EXCEPTION_TO_EFFECTIVE_DATE";
export const ASSIGNED_EXCEPTION_TO_EFFECTIVE_DATE =
  "ASSIGNED_EXCEPTION_TO_EFFECTIVE_DATE";

export const DELETING_PREDEF_VALUE = "DELETING_PREDEF_VALUE";
export const DELETED_PREDEF_VALUE = "DELETED_PREDEF_VALUE";

export const CLEAR_DOCUMENTS = "rentRoll/CLEAR_DOCUMENTS";
export const CLEAR_RENT_ROLL = "rentRoll/CLEAR_RENT_ROLL";
export const CLEAR_ANALYTICS = "rentRoll/CLEAR_ANALYTICS";

export const clearDocuments = () => ({ type: CLEAR_DOCUMENTS });
export const clearRentRoll = () => ({ type: CLEAR_RENT_ROLL });
export const clearAnalytics = () => ({ type: CLEAR_ANALYTICS });

export const excelMarkdownIsLoading = () => ({ type: EXCEL_MARKDOWN_LOADING });
export const excelMarkdownHasLoaded = () => ({ type: EXCEL_MARKDOWN_LOADED });

export const clearSplitPanel = () => ({ type: CLEAR_SPLIT_PANEL });

export const FETCHING_UPLOADING_DOCUMENTS =
  "rentRoll/FETCHING_UPLOADING_DOCUMENTS";
export const FETCH_UPLOADING_DOCUMENTS_SUCCESSFUL =
  "rentRoll/FETCH_UPLOADING_DOCUMENTS_SUCCESSFUL";

export const FETCHING_NEED_ATTENTION_DOCUMENTS =
  "rentRoll/FETCHING_NEED_ATTENTION_DOCUMENTS";
export const FETCH_NEED_ATTENTION_DOCUMENTS_SUCCESSFUL =
  "rentRoll/FETCH_NEED_ATTENTION_DOCUMENTS_SUCCESSFUL";

export const DELETING_DOCUMENTS_COMPANY = "rentRoll/DELETING_DOCUMENTS_COMPANY";
export const DELETING_DOCUMENTS_COMPANY_SUCCESSFUL =
  "rentRoll/DELETING_DOCUMENTS_COMPANY_SUCCESSFUL";

export const refreshingSplitPanel = () => ({
  type: REFRESHING_SPLIT_PANEL
});

export const splitPanelRefreshed = (
  property,
  document,
  rentRoll,
  exceptions
) => ({
  property,
  document,
  rentRoll,
  exceptions,
  type: SPLIT_PANEL_REFRESHED
});

export const uploadRentRollDocumentSuccessful = fileObj => ({
  type: UPDATED_UPLOADED_DOCS_SUCCESSFUL,
  fileObj
});
export const uploadingRentRollDocument = () => ({
  type: UPDATING_UPLOADED_DOCS
});

export const updateUploadStatus = uploadStatus => ({
  type: UPDATE_UPLOAD_STATUS,
  uploadStatus
});

export const uploadRentRollDocument = (file, propertyId) => {
  return async function(dispatch) {
    try {
      dispatch(uploadingRentRollDocument());
      const jwt = Cookies.get("quiq-property-jwt");
      const formData = new FormData();
      formData.append("file", file);

      axios
        .post(`/api/rent-roll/upload-document/${propertyId}`, formData, {
          headers: {
            Authorization: `Bearer ${jwt}`,
            "Content-Type": "multipart/form-data"
          }
        })
        .then(() => {
          dispatch(fetchUploadingDocuments());
        });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };
};

export const refreshSplitPanel = (propertyId, documentId, widgetAuth = {}) => {
  return async function(dispatch) {
    dispatch(refreshingSplitPanel());
    try {
      const propertyResponse = await apiFetch(
        `/api/properties/${propertyId}`,
        {
          method: "get"
        },
        widgetAuth
      );

      if (!propertyResponse.ok) {
        const responseBody = await propertyResponse.json();
        throw new Error(responseBody.error);
      }

      const propertyBody = await propertyResponse.json();

      const documentResponse = await apiFetch(
        `/api/get-document/${documentId}`,
        {
          method: "get"
        },
        widgetAuth
      );

      if (!documentResponse.ok) {
        const responseBody = await documentResponse.json();
        throw new Error(responseBody.error);
      }

      const documentBody = await documentResponse.json();

      const rentRollResponse = await apiFetch(
        `/api/rent-roll/get-rent-roll-by-doc-id/${documentId}`,
        {
          method: "get"
        },
        widgetAuth
      );

      if (!rentRollResponse.ok) {
        const responseBody = await rentRollResponse.json();
        throw new Error(responseBody.error);
      }

      const rentRollBody = await rentRollResponse.json();

      const exceptionsResponse = await apiFetch(
        `/api/rent-roll/exceptions/${documentId}`,
        {
          method: "get"
        },
        widgetAuth
      );

      if (!exceptionsResponse.ok) {
        const responseBody = await exceptionsResponse.json();
        throw new Error(responseBody.error);
      }

      const exceptionsBody = await exceptionsResponse.json();

      dispatch(
        splitPanelRefreshed(
          propertyBody.property,
          documentBody.document,
          rentRollBody.rentRoll,
          exceptionsBody.exceptions
        )
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };
};

export const undoingRemoveRowFromRentRoll = () => ({
  type: UNDO_REMOVE_ROW_FROM_RENT_ROLL
});

export const undoRemoveRowFromRentRollSuccessful = rentRoll => ({
  type: UNDO_REMOVE_ROW_FROM_RENT_ROLL_SUCCESSFUL,
  rentRoll
});

export function undoRemoveRowFromRentRoll(documentId, rowId, widgetAuth) {
  return async function(dispatch) {
    dispatch(undoingRemoveRowFromRentRoll());
    try {
      const response = await apiFetch(
        `/api/rent-roll/undo-remove-row/${documentId}`,
        {
          method: "put",
          body: JSON.stringify({ rowId })
        },
        widgetAuth
      );
      if (!response.ok) {
        const responseBody = await response.json();
        throw new Error(responseBody.error);
      }
      const responseBody = await response.json();
      const { rentRoll } = responseBody;
      dispatch(undoRemoveRowFromRentRollSuccessful(rentRoll));
      dispatch(
        reinstateRentRollRowExceptions({ documentId, rowId, widgetAuth })
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };
}

export const removingRowFromRentRoll = () => ({
  type: REMOVE_ROW_FROM_RENT_ROLL
});

export const removedRowFromRentRoll = rentRoll => ({
  type: REMOVED_ROW_FROM_RENT_ROLL,
  rentRoll
});

export function removeRowFromRentRoll(
  documentId,
  rowId,
  status,
  widgetAuth = {}
) {
  return async function(dispatch) {
    dispatch(removingRowFromRentRoll());
    try {
      const response = await apiFetch(
        `/api/rent-roll/remove-row/${documentId}`,
        {
          method: "put",
          body: JSON.stringify({ rowId: parseInt(rowId), status })
        },
        widgetAuth
      );
      if (!response.ok) {
        const responseBody = await response.json();
        throw new Error(responseBody.error);
      }
      const responseBody = await response.json();
      const { rentRoll } = responseBody;
      dispatch(removedRowFromRentRoll(rentRoll));
      dispatch(removeRentRollRowExceptions({ rowId, documentId, widgetAuth }));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };
}

export const removingRentRollRowExceptions = () => ({
  type: REMOVE_RENT_ROLL_ROW_EXCEPTIONS
});

export const removedRentRollRowExceptions = exceptions => ({
  type: REMOVED_RENT_ROLL_ROW_EXCEPTIONS,
  exceptions
});

export function removeRentRollRowExceptions({ rowId, documentId, widgetAuth }) {
  return async function(dispatch) {
    try {
      const response = await apiFetch(
        `/api/rent-roll/remove-rent-roll-exceptions/${documentId}/`,
        {
          method: "put",
          body: JSON.stringify({ rowId: parseInt(rowId, 10) })
        },
        widgetAuth
      );
      if (!response.ok) {
        const responseBody = await response.json();
        throw new Error(responseBody.error);
      }
      const responseBody = await response.json();
      const { exceptions } = responseBody;
      dispatch(removedRentRollRowExceptions(exceptions));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };
}

export const reinstatingRentRollRowExceptions = () => ({
  type: REINSTATE_RENT_ROLL_ROW_EXCEPTIONS
});

export const reinstatedRentRollRowExceptions = exceptions => ({
  type: REINSTATE_RENT_ROLL_ROW_EXCEPTIONS_SUCCESSFUL,
  exceptions
});

export function reinstateRentRollRowExceptions({
  rowId,
  documentId,
  widgetAuth
}) {
  return async function(dispatch) {
    dispatch(reinstatingRentRollRowExceptions());

    try {
      const response = await apiFetch(
        `/api/rent-roll/reinstate-rent-roll-exceptions/${documentId}`,
        {
          method: "put",
          body: JSON.stringify({ rowId })
        },
        widgetAuth
      );

      if (!response.ok) {
        const responseBody = await response.json();
        throw new Error(responseBody);
      }

      const responseBody = await response.json();
      const { exceptions } = responseBody;
      dispatch(reinstatedRentRollRowExceptions(exceptions));
    } catch (error) {
      console.log(error);
    }
  };
}

export const addingRowToRentRoll = () => ({
  type: ADDING_ROW_TO_RENT_ROLL
});

export const addedRowToRentRoll = rentRoll => ({
  type: ADDED_ROW_TO_RENT_ROLL,
  rentRoll
});

export function addNewRowToRentRoll(documentId, data, widgetAuth) {
  return async function(dispatch) {
    dispatch(addingRowToRentRoll());
    try {
      const response = await apiFetch(
        `/api/rent-roll/add-row/${documentId}`,
        {
          method: "put",
          body: JSON.stringify({ data })
        },
        widgetAuth
      );
      if (!response.ok) {
        const responseBody = await response.json();
        throw new Error(responseBody.error);
      }
      const responseBody = await response.json();
      const { rentRoll } = responseBody;
      dispatch(addedRowToRentRoll(rentRoll));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };
}

export const newPredefSugestionCreating = () => ({
  type: NEW_PREDEF_SUGESTION_CREATING
});

export const newPredefSugestionCreated = () => ({
  type: NEW_PREDEF_SUGESTION_CREATED
});

export function createNewPredefAndUpdateField(
  value,
  field,
  documentId,
  fieldLocation,
  widgetAuth = {}
) {
  const body = { value, field };
  return async function(dispatch) {
    dispatch(newPredefSugestionCreating());
    try {
      const response = await apiFetch(
        `/api/predef-fields/create/${documentId}`,
        {
          method: "post",
          body: JSON.stringify(body)
        },
        widgetAuth
      );
      if (!response.ok) {
        const responseBody = await response.json();
        throw new Error(responseBody.error);
      }
      const responseBody = await response.json();
      // dispatch(newPredefSugestionCreated());
      const { value, id } = responseBody.predefValue;
      const field = {
        ...fieldLocation,
        text: value,
        predefValueId: id
      };
      // await dispatch(updateDocumentField(field, documentId));
      // dispatch(updatingDocumentField());
      let fieldApiOptions = {
        method: "put",
        body: JSON.stringify({ field })
      };

      const response2 = await apiFetch(
        `/api/rent-roll/update-field/${documentId}`,
        fieldApiOptions,
        widgetAuth
      );
      const responseBody2 = await response2.json();
      if (!response.ok) {
        throw new Error(responseBody2.error);
      }
      await dispatch(
        updatedDocumentFieldSuccessful(
          responseBody2.document,
          responseBody2.rentRoll,
          responseBody2.exceptions
        )
      );
      // await dispatch(fetchExceptionsByDocId(documentId));
      await dispatch(updatePdfMarkdownLoading());
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };
}

export const predefSugestionLoading = () => ({
  isPredefLoading: true,
  type: PREDEF_SUGESTION_LOADING
});

export const predefSugestionCleared = () => ({
  type: PREDEF_SUGESTION_CLEARED
});

export const predefSugestionUpdated = predefValues => ({
  predefValues,
  isPredefLoading: false,
  type: PREDEF_SUGESTION_UPDATED
});

export function fetchPredefSugestions(
  documentId,
  value,
  field,
  widgetAuth = {}
) {
  return async function(dispatch) {
    dispatch(predefSugestionLoading());
    try {
      const query = { value, field };
      const response = await apiFetch(
        `/api/predef-fields/suggest/${documentId}?${queryString.stringify(
          query
        )}`,
        {
          method: "get"
        },
        widgetAuth
      );
      if (!response.ok) {
        const responseBody = await response.json();
        throw new Error(responseBody.error);
      }
      const responseBody = await response.json();
      const { predefValues } = responseBody;
      dispatch(predefSugestionUpdated(predefValues));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export const fetchExceptionsLoading = () => ({ type: EXCEPTIONS_LOADING });

export const fetchExceptionsLoaded = exceptions => ({
  exceptions,
  type: EXCEPTIONS_LOADED
});

export function fetchExceptionsByDocId(documentId) {
  return async function(dispatch) {
    dispatch(fetchExceptionsLoading());
    try {
      const response = await apiFetch(
        `/api/rent-roll/exceptions/${documentId}`,
        {
          method: "get"
        }
      );
      if (!response.ok) {
        const responseBody = await response.json();
        throw new Error(responseBody.error);
      }
      const responseBody = await response.json();
      dispatch(fetchExceptionsLoaded(responseBody.exceptions));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export const updatePdfMarkdownLoading = () => ({
  type: UPDATE_PDF_MARKDOWN_LOADING
});

export const updatePdfMarkdownLoaded = () => ({
  type: UPDATE_PDF_MARKDOWN_LOADED
});

export const updatePageIndex = pageIndex => ({
  pageIndex,
  type: UPDATE_PAGE_INDEX
});

export function fetchUploadDocumentByDocIdSuccessful(document) {
  return { document, type: FETCHED_UPLOAD_DOCUMENT_BY_DOCID_SUCCESSFUL };
}

export function removeUploadDocumentByDocId(documentId) {
  return { documentId, type: REMOVE_UPLOAD_DOC_BY_ID };
}

export function fetchUploadDocumentByDocId(documentId) {
  return async function(dispatch) {
    dispatch(fetchingRentRollDocument(documentId));

    try {
      const response = await apiFetch(`/api/get-document/${documentId}`, {
        method: "get"
      });
      if (!response.ok) {
        const responseBody = await response.json();
        throw new Error(responseBody.error);
      }
      const responseBody = await response.json();
      const doc = responseBody.document;
      const updateDate = new Date(doc.updated_at);
      const fileObj = {
        id: doc.id,
        filename: doc.original_filename,
        property: "",
        date:
          updateDate.getMonth() +
          1 +
          "/" +
          updateDate.getDate() +
          "/" +
          updateDate.getFullYear(),
        status: doc.status,
        action: ""
      };
      await dispatch(removeUploadDocumentByDocId(doc.id));
      await dispatch(uploadRentRollDocumentSuccessful(fileObj));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export function fetchRentRollByPropId(propertyId) {
  return async function(dispatch) {
    dispatch(fetchingRentRoll(propertyId));

    try {
      const response = await apiFetch(
        `/api/rent-roll/get-rent-roll/${propertyId}`,
        {
          method: "get"
        }
      );
      if (!response.ok) {
        const responseBody = await response.json();
        throw new Error(responseBody.error);
      }
      const responseBody = await response.json();
      dispatch(
        fetchRentRollSuccessful(
          responseBody.rentRoll,
          responseBody.selectedProperty
        )
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export function fetchingRentRoll(propertyId) {
  return { propertyId, type: FETCHING_RENT_ROLL };
}

export function fetchRentRollSuccessful(rentRoll, selectedProperty) {
  return { rentRoll, selectedProperty, type: FETCHED_RENT_ROLL_SUCCESSFUL };
}

export function fetchRentRollByDocIdSuccessful(rentRoll) {
  return { rentRoll, type: FETCHED_RENT_ROLL_BY_DOCID_SUCCESSFUL };
}

export function fetchRentRollDocsByPropId(
  propertyId,
  updateStatusBarCount = false,
  widgetAuth = {}
) {
  return async function(dispatch) {
    try {
      const response = await apiFetch(
        `/api/rent-roll/get-documents/${propertyId}`,
        {
          method: "get"
        },
        widgetAuth
      );
      if (!response.ok) {
        const responseBody = await response.json();
        throw new Error(responseBody.error);
      }
      const responseBody = await response.json();
      dispatch(fetchRentRollDocumentsSuccessful(responseBody.documents));
      if (updateStatusBarCount) {
        dispatch(fetchNeedAttentionDocuments(widgetAuth));
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export function fetchingRentRollDocuments(propertyId) {
  return { propertyId, type: FETCHING_DOCUMENTS };
}

export function fetchRentRollDocumentsSuccessful(documents) {
  return { documents, type: FETCHED_DOCUMENTS_SUCCESSFUL };
}

export function fetchRentRollCompositionSuccessful(metrics) {
  return { metrics, type: FETCHED_RENT_ROLL_COMPOSITION_SUCCESSFUL };
}

export function fetchRentRollAnalyticsSuccessful(analytics) {
  return { analytics, type: FETCHED_RENT_ROLL_ANALYTICS_SUCCESSFUL };
}

export function fetchRentRollByDocId(documentId, widgetAuth = {}) {
  return async function(dispatch) {
    dispatch(fetchingRentRoll(documentId));

    try {
      const response = await apiFetch(
        `/api/rent-roll/get-rent-roll-by-doc-id/${documentId}`,
        {
          method: "get"
        },
        widgetAuth
      );
      if (!response.ok) {
        const responseBody = await response.json();
        throw new Error(responseBody.error);
      }
      const responseBody = await response.json();
      dispatch(fetchRentRollByDocIdSuccessful(responseBody.rentRoll));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export function fetchRentRollDocument(documentId, widgetAuth = {}) {
  return async function(dispatch) {
    dispatch(fetchingRentRollDocument(documentId));

    try {
      const response = await apiFetch(
        `/api/get-document/${documentId}`,
        {
          method: "get"
        },
        widgetAuth
      );
      if (!response.ok) {
        const responseBody = await response.json();
        throw new Error(responseBody.error);
      }
      const responseBody = await response.json();
      dispatch(fetchRentRollDocumentSuccessful(responseBody.document));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export function fetchingRentRollDocument(documentId) {
  return { documentId, type: FETCHING_DOCUMENT };
}

export function fetchRentRollDocumentSuccessful(document) {
  return { document, type: FETCHED_DOCUMENT_SUCCESSFUL };
}

export function fetchAllRentRollsSuccessful(allRentRolls) {
  return { allRentRolls, type: FETCHED_ALL_RENT_ROLLS_SUCCESSFUL };
}

export function updateDocumentTemplateSuccessful(
  document,
  rentRoll,
  exceptions
) {
  return {
    document,
    rentRoll,
    exceptions,
    type: UPDATE_DOCUMENT_TEMPLATE_SUCCESSFUL
  };
}

export function updateDocumentTemplate(
  templateData,
  metaName,
  headerIndex,
  documentId,
  multipleLineGrouping,
  advancedRules,
  widgetAuth = {}
) {
  return async function(dispatch) {
    dispatch(updatingDocumentTemplate());

    let templateApiOptions = {
      method: "put",
      body: JSON.stringify({
        templateData,
        metaName,
        headerIndex,
        multipleLineGrouping,
        advancedRules
      })
    };

    try {
      const response = await apiFetch(
        `/api/rent-roll/update-template/${documentId}`,
        templateApiOptions,
        widgetAuth
      );

      const responseBody = await response.json();
      if (!response.ok) {
        throw new Error(responseBody.error);
      }

      dispatch(
        updateDocumentTemplateSuccessful(
          responseBody.document,
          responseBody.rentRoll,
          responseBody.exceptions
        )
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export function setRentRollFields(fieldsByPropertyType) {
  return async function(dispatch) {
    dispatch(updatingRentRollFields());
    let templateApiOptions = {
      method: "put",
      body: JSON.stringify({ fieldsByPropertyType })
    };

    try {
      const response = await apiFetch(
        `/api/rent-roll/fields`,
        templateApiOptions
      );

      const responseBody = await response.json();
      if (!response.ok) {
        throw new Error(responseBody.error);
      }

      dispatch(updateRentRollFieldsSuccessful(fieldsByPropertyType));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export function getRentRollFields(widgetAuth = {}) {
  return async function(dispatch) {
    dispatch(fetchingRentRollFields());

    let templateApiOptions = {
      method: "get"
    };

    try {
      const response = await apiFetch(
        `/api/rent-roll/fields`,
        templateApiOptions,
        widgetAuth
      );

      const responseBody = await response.json();
      if (!response.ok) {
        throw new Error(responseBody.error);
      }
      dispatch(
        fetchRentRollFieldsSuccessful(responseBody.fieldsByPropertyType)
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export function updateDocumentField(field, documentId, widgetAuth = {}) {
  return async function(dispatch) {
    dispatch(updatingDocumentField());
    let fieldApiOptions = {
      method: "put",
      body: JSON.stringify({ field })
    };
    try {
      const response = await apiFetch(
        `/api/rent-roll/update-field/${documentId}`,
        fieldApiOptions,
        widgetAuth
      );
      const responseBody = await response.json();
      if (!response.ok) {
        throw new Error(responseBody.error);
      }
      dispatch(
        updatedDocumentFieldSuccessful(
          responseBody.document,
          responseBody.rentRoll,
          responseBody.exceptions
        )
      );
      // await dispatch(fetchExceptionsByDocId(documentId));
      // dispatch(updatePdfMarkdownLoading());
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export function deleteDocuments(propertyId, documentIds, deleteML = false) {
  return async function(dispatch) {
    dispatch(fetchingRentRollDocuments(propertyId));
    try {
      const response = await apiFetch(
        `/api/documents/delete-documents/${propertyId}`,
        {
          method: "delete",
          body: JSON.stringify({ documentIds, deleteML })
        }
      );
      const responseBody = await response.json();
      if (!response.ok) {
        throw new Error(responseBody.error.message);
      }
      dispatch(
        fetchRentRollDocumentsSuccessful(responseBody.documentsRemaining)
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export function deleteDocumentsCompany(
  propertyId,
  documentIds,
  deleteML = false,
  widgetAuth = {}
) {
  return async function(dispatch) {
    dispatch(deletingDocumentsCompany());
    try {
      const response = await apiFetch(
        `/api/documents/delete-documents`,
        {
          method: "delete",
          body: JSON.stringify({ documentIds, deleteML })
        },
        widgetAuth
      );
      const responseBody = await response.json();
      if (!response.ok) {
        throw new Error(responseBody.error.message);
      }
      dispatch(
        deletedDocumentsCompanySuccessful(responseBody.documentsRemaining)
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export function deletingDocumentsCompany() {
  return { type: DELETING_DOCUMENTS_COMPANY };
}

export function deletedDocumentsCompanySuccessful(documents) {
  return { documents, type: DELETING_DOCUMENTS_COMPANY_SUCCESSFUL };
}

export function updatingDocumentField() {
  return { type: UPDATING_DOCUMENT_FIELD };
}

export function updatedDocumentFieldSuccessful(document, rentRoll, exceptions) {
  return {
    document,
    rentRoll,
    exceptions,
    type: UPDATED_DOCUMENT_FIELD_SUCCESSFUL
  };
}

export function updatingDocumentTemplate() {
  return { type: UPDATING_DOCUMENT_TEMPLATE };
}

export function updatedDocumentTemplateSuccessful(documentId) {
  return { documentId, type: UPDATED_DOCUMENT_TEMPLATE_SUCCESSFUL };
}

export function processingDocument(documentId) {
  return { documentId, type: PROCESSING_DOCUMENT };
}

export function updateEditHeader(obj) {
  return { obj, type: UPDATE_EDIT_HEADER };
}

export function clearEditHeader(obj) {
  return { obj, type: CLEAR_EDIT_HEADER };
}

export function updateEditText(obj) {
  return { obj, type: UPDATE_EDIT_TEXT };
}

export function clearEditText(obj) {
  return { obj, type: CLEAR_EDIT_TEXT };
}

export function updateDocumentCheck(bool) {
  return { bool, type: UPDATE_DOCUMENT_CHECK };
}

export function updateCache(id, val) {
  return { id, val, type: UPDATE_CACHE };
}

export function clearCache() {
  return { type: CLEAR_CACHE };
}

export function updateExceptionSidebar(val) {
  return { val, type: UPDATE_EXCEPTION_SIDEBAR };
}

export function updatingRentRollFields() {
  return { type: UPDATING_RENT_ROLL_FIELDS };
}

export function updateRentRollFieldsSuccessful(fieldsByPropertyType) {
  return { type: UPDATE_RENT_ROLL_FIELDS_SUCCESSFUL, fieldsByPropertyType };
}

export function fetchingRentRollFields() {
  return { type: FETCHING_RENT_ROLL_FIELDS };
}

export function fetchRentRollFieldsSuccessful(fieldsByPropertyType) {
  return { type: FETCH_RENT_ROLL_FIELDS_SUCCESSFUL, fieldsByPropertyType };
}

export function updatingDocument() {
  return { type: UPDATING_DOCUMENT };
}

export function updateDocumentSuccessful(document) {
  return { document, type: UPDATED_DOCUMENT_SUCCESSFUL };
}

export function updateDocumentSuccessfulNoStore() {
  return { type: UPDATED_DOCUMENT_SUCCESSFUL_NO_STORE };
}

export function markAsComplete(documentId, widgetAuth = {}) {
  return async function(dispatch) {
    try {
      const response = await apiFetch(
        `/api/rent-roll/mark-as-complete/${documentId}`,
        {
          method: "put"
        },
        widgetAuth
      );
      const responseBody = await response.json();
      if (!response.ok) {
        throw new Error(responseBody.error);
      }
      dispatch(updateCompletedAt(responseBody.document.updated_at));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}
export function fetchRentRollCompositionByDocId(documentId, field) {
  return async function(dispatch) {
    try {
      const response = await apiFetch(
        `/api/metrics/document/${field}/${documentId}`,
        {
          method: "get"
        }
      );
      const responseBody = await response.json();
      if (!response.ok) {
        throw new Error(responseBody.error);
      }
      dispatch(fetchRentRollCompositionSuccessful(responseBody.metrics));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export function fetchAllRentRollsByPropId(propertyId) {
  return async function(dispatch) {
    dispatch(fetchingRentRoll(propertyId));

    try {
      const response = await apiFetch(
        `/api/rent-roll/get-all-rent-rolls/${propertyId}`,
        {
          method: "get"
        }
      );
      if (!response.ok) {
        const responseBody = await response.json();
        throw new Error(responseBody.error);
      }
      const responseBody = await response.json();
      dispatch(fetchAllRentRollsSuccessful(responseBody.allRentRolls));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export function fetchRentRollAnalyticsByDocId(documentId) {
  return async function(dispatch) {
    try {
      if (documentId) {
        const response = await apiFetch(
          `/api/metrics/document/overall/${documentId}`,
          {
            method: "get"
          }
        );
        const responseBody = await response.json();
        if (!response.ok) {
          throw new Error(responseBody.error);
        }
        dispatch(fetchRentRollAnalyticsSuccessful(responseBody.metrics));
      } else {
        dispatch(fetchRentRollAnalyticsSuccessful(null));
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export function updateDocument(
  documentId,
  fields,
  widgetAuth = {},
  dontUpdateStore
) {
  return async function(dispatch) {
    dispatch(updatingDocument());
    try {
      const response = await apiFetch(
        `/api/documents/update/${documentId}`,
        {
          method: "put",
          body: JSON.stringify({ fields })
        },
        widgetAuth
      );

      const responseBody = await response.json();
      if (!response.ok) {
        throw new Error(responseBody.error);
      }

      if (dontUpdateStore) {
        dispatch(updateDocumentSuccessfulNoStore());
      } else {
        dispatch(updateDocumentSuccessful(responseBody.document));
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export function getRentrollDocumentPdf(filename) {
  return async function(dispatch) {
    dispatch(fetchingDocumentPdf());
    try {
      const response = await apiFetch(
        `/api/rent-roll/source-file?filename=${filename}`,
        {
          method: "get"
        }
      );
      const responseBody = await response.json();
      if (!response.ok) {
        throw new Error(responseBody.error);
      }
      dispatch(fetchingDocumentUrlSuccessful(responseBody.fileUrl));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export function fetchingDocumentPdf() {
  return { type: FETCHING_DOCUMENT_PDF };
}

export function fetchingDocumentUrlSuccessful(fileUrl) {
  return { fileUrl, type: FETCHING_DOCUMENT_URL_SUCCESSFUL };
}

export function fetchCompanyDocuments(
  page,
  size,
  orderBy,
  widgetAuth = {},
  filterObject = {}
) {
  return async function(dispatch, getState) {
    dispatch(fetchingCompanyDocuments());
    const companyId = getState().currentUser.company.id;
    try {
      const optionalFilterTypes = stringify(filterObject);
      const response = await apiFetch(
        `/api/documents/${companyId}/page/${page}/size/${size}/${orderBy}?${optionalFilterTypes}`,
        {
          method: "get"
        },
        widgetAuth
      );
      const responseBody = await response.json();
      if (!response.ok) {
        throw new Error(responseBody.error);
      }
      dispatch(
        fetchCompanyDocumentsSuccessful(
          responseBody.documents,
          responseBody.totalPages
        )
      );
      return responseBody.documents;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export function fetchingCompanyDocuments() {
  return { type: FETCHING_COMPANY_DOCUMENTS };
}

export function fetchCompanyDocumentsSuccessful(documents, totalPages) {
  return {
    documents,
    totalPages,
    type: FETCHED_COMPANY_DOCUMENTS_SUCCESSFULLY
  };
}

export function fetchUploadingDocuments(widgetAuth = {}) {
  return async function(dispatch) {
    dispatch(fetchingUploadingDocuments());
    try {
      const response = await apiFetch(
        `/api/documents/uploading`,
        {
          method: "get"
        },
        widgetAuth
      );
      const responseBody = await response.json();
      if (!response.ok) {
        throw new Error(responseBody.error);
      }
      dispatch(fetchUploadingDocumentsSuccessful(responseBody.uploadingDocs));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export function fetchingUploadingDocuments() {
  return { type: FETCHING_UPLOADING_DOCUMENTS };
}

export function fetchUploadingDocumentsSuccessful(uploadingDocs) {
  return { uploadingDocs, type: FETCH_UPLOADING_DOCUMENTS_SUCCESSFUL };
}

export function fetchNeedAttentionDocuments(widgetAuth = {}) {
  return async function(dispatch) {
    dispatch(fetchingNeedAttentionDocuments());
    try {
      const response = await apiFetch(
        `/api/documents/need-attention`,
        {
          method: "get"
        },
        widgetAuth
      );
      const responseBody = await response.json();
      if (!response.ok) {
        throw new Error(responseBody.error);
      }
      const { totalCountNeedAttentionDocs, needAttentionDocs } = responseBody;
      dispatch(
        fetchNeedAttentionDocumentsSuccessful(
          totalCountNeedAttentionDocs,
          needAttentionDocs
        )
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export function fetchingNeedAttentionDocuments() {
  return { type: FETCHING_NEED_ATTENTION_DOCUMENTS };
}

export function fetchNeedAttentionDocumentsSuccessful(
  totalCountNeedAttentionDocs,
  needAttentionDocs
) {
  return {
    totalCountNeedAttentionDocs,
    needAttentionDocs,
    type: FETCH_NEED_ATTENTION_DOCUMENTS_SUCCESSFUL
  };
}

export function assignPropertyToDocument(
  documentId,
  propertyId,
  widgetAuth = {}
) {
  return async function(dispatch) {
    try {
      dispatch(assigningPropertyToDocument());
      const response = await apiFetch(
        `/api/documents/${documentId}/assign-property/${propertyId}`,
        { method: "post" },
        widgetAuth
      );
      if (!response.ok) {
        const responseBody = await response.json();
        throw new Error(responseBody.error);
      }
      dispatch(assignedPropertyToDocument());
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export function assigningPropertyToDocument() {
  return { type: ASSIGNING_PROPERTY_TO_DOCUMENT };
}

export function assignedPropertyToDocument() {
  return { type: ASSIGNED_PROPERTY_TO_DOCUMENT };
}

export function assignExceptionToEffectiveDate(
  documentId,
  status,
  widgetAuth = {}
) {
  return async function(dispatch) {
    try {
      dispatch(assigningExceptionToEffectiveDate());
      const response = await apiFetch(
        `/api/rent-roll/effective-date-exception/${documentId}`,
        { method: "post", body: JSON.stringify({ status: status }) },
        widgetAuth
      );
      if (!response.ok) {
        const responseBody = await response.json();
        throw new Error(responseBody.error);
      }
      dispatch(assignedExceptionToEffectivedate());
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export function assigningExceptionToEffectiveDate() {
  return { type: ASSIGNING_EXCEPTION_TO_EFFECTIVE_DATE };
}

export function assignedExceptionToEffectivedate() {
  return { type: ASSIGNED_EXCEPTION_TO_EFFECTIVE_DATE };
}

export function deletePredefValue(predefValueId, widgetAuth) {
  return async function(dispatch) {
    try {
      dispatch(deletingPredefValue());
      const response = await apiFetch(
        `/api/predef-values/delete/${predefValueId}`,
        { method: "delete" },
        widgetAuth
      );
      if (!response.ok) {
        const responseBody = await response.json();
        throw new Error(responseBody.error);
      }
      dispatch(deletedPredefValue());
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export function deletingPredefValue() {
  return { type: DELETING_PREDEF_VALUE };
}

export function deletedPredefValue() {
  return { type: DELETED_PREDEF_VALUE };
}
