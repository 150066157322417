import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import ToggleSwitch from "ui/components/apps/ToggleSwitch";

const CustomColumnsRow = ({
  row,
  idx,
  newRow = false,
  handleDeleteRow,
  rentRollColumns
}) => {
  const { term, displayTerm, dataType, status, id, name, required } = row;
  const [toggleOn, setToggleOn] = useState(false);
  const [disableInput, setDisableInput] = useState(false);
  const [requiredToggle, setRequiredToggle] = useState(false);

  useEffect(() => {
    setToggleOn(status === "show");
  }, [status]);

  useEffect(() => {
    setRequiredToggle(required === true);
  }, [required]);

  useEffect(() => {
    if (!newRow) {
      setDisableInput(rentRollColumns.includes(term));
    }
  }, [rentRollColumns]);

  const handleToggle = () => {
    setToggleOn(!toggleOn);
  };
  const handleToggleRequired = () => {
    setRequiredToggle(!requiredToggle);
  };
  return (
    <div className="CustomColumnsRow">
      <label htmlFor={`${id}-term`}>Term:</label>
      <input
        type="text"
        id={`${idx}-${id}-term`}
        key={`${idx}-${id}-term`}
        className="CustomColumnsRow__term"
        name={`${idx}-${name}-term`}
        defaultValue={term}
        disabled={disableInput}
      />
      <label htmlFor={`${id}-displayTerm`}>Display Term:</label>
      <input
        type="text"
        id={`${idx}-${id}-displayTerm`}
        key={`${idx}-${id}-displayTerm`}
        className="CustomColumnsRow__display-term"
        name={`${idx}-${name}-displayTerm`}
        defaultValue={displayTerm}
      />
      {!disableInput && <label htmlFor={`${id}-dataType`}>Data Type:</label>}
      {!disableInput && (
        <select
          id={`${idx}-${id}-dataType`}
          key={`${idx}-${id}-dataType`}
          className="CustomColumnsRow__display-term"
          name={`${idx}-${name}-dataType`}
          defaultValue={dataType}
        >
          <option value={"number"}>Number</option>
          <option value={"currency-usd"}>Currency - USD</option>
          <option value={"string"}>String</option>
          <option value={"date"}>Date</option>
        </select>
      )}
      <label htmlFor={`${idx}-${id}-show`}>Show Term:</label>
      <ToggleSwitch
        handleToggle={() => handleToggle()}
        isOn={toggleOn}
        id={`${idx}-${id}-status`}
        key={`${idx}-${id}-status`}
      />
      <label htmlFor={`${idx}-${id}-required`}>Required:</label>
      <ToggleSwitch
        handleToggle={() => handleToggleRequired()}
        isOn={requiredToggle}
        id={`${idx}-${id}-isRequired`}
        key={`${idx}-${id}-isRequired`}
      />
      {!disableInput && (
        <img
          src={require("ui/images/icon-x.svg")}
          style={{ marginLeft: "15px", cursor: "pointer" }}
          onClick={() => handleDeleteRow(idx, newRow)}
        />
      )}
    </div>
  );
};

CustomColumnsRow.propTypes = {
  row: PropTypes.object,
  idx: PropTypes.number,
  newRow: PropTypes.bool,
  handleDeleteRow: PropTypes.func,
  rentRollColumns: PropTypes.array
};

export default CustomColumnsRow;
