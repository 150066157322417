import PropTypes from "prop-types";
import cx from "classnames";

const ProgressBar = ({ percent, color }) => {
  return (
    <div className="ProgressBar">
      <div
        className={cx("ProgressBar__fill", `ProgressBar__fill--${color}`)}
        style={{
          width: `${percent}%`
        }}
      />
    </div>
  );
};

ProgressBar.propTypes = {
  percent: PropTypes.number,
  color: PropTypes.string
};

export default ProgressBar;
