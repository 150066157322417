import { merge } from "ramda";

import {
  FETCHING_PROPERTY,
  FETCHING_PROPERTIES,
  FETCHING_PROPERTIES_SUCCESSFUL,
  FETCH_PROPERTY_SUCCESSFUL,
  CREATING_PROPERTY,
  CREATING_PROPERTY_SUCCESSFUL,
  FETCHING_PROPERTY_FROM_REIS_NETWORK,
  FETCHING_PROPERTY_FROM_REIS_NETWORK_SUCCESSFUL,
  AUTOCOMPLETE_SUCCESSFUL,
  HANDLE_TEXT_CHANGE,
  RESET_SEARCH,
  SUGGESTIONS_SUCCESSFUL,
  HANDLE_SUGGESTIONS_TEXT_CHANGE,
  RESET_SUGGESTIONS,
  CLEAR_SELECTED_PORTFOLIO,
  CLEAR_METRICS,
  CLEAR_PROPERTIES,
  FETCH_PROPERTIES_BY_COMPANY_SUCCESFULLY,
  PROPERTIES_SUCCESS_FETCH_TO_SELECT,
  CLEAR_PROPERTIES_SELECT,
  AUTOCOMPLETE_PROPERTY_SEARCH_SUCCESSFUL,
  AUTOCOMPLETE_GOOGLE_PLACES_SUCCESSFUL,
  FIND_GOOGLE_PLACES_SUCCESSFUL,
  FIND_GOOGLE_PLACES_BY_ID_SUCCESSFUL
} from "ui/store/actions/properties.js";

import { SPLIT_PANEL_REFRESHED } from "ui/store/actions/rentRoll";

export const FETCHING = "FETCHING";
export const CREATING = "CREATING";
export const LOADED = "LOADED";

export default function properties(
  state = {
    properties: [],
    selectedPortfolio: {},
    reisProperty: {},
    isLoadingReisProperty: false,
    status: FETCHING,
    resultStatus: LOADED,
    cache: {},
    exceptionSidebarIsActive: false,
    suggestedProperties: [],
    recommendedQueries: [],
    recommendedSuggestions: [],
    q: "",
    metrics: {},
    propertiesSelect: [],
    addresses: [],
    formatted_address: "",
    geometry: {},
    name: {},
    types: []
  },
  action
) {
  switch (action.type) {
    case FETCHING_PROPERTIES: {
      return merge(state, { status: FETCHING });
    }
    case FETCHING_PROPERTIES_SUCCESSFUL: {
      const { properties, selectedPortfolio, metrics } = action;
      return merge(state, {
        properties,
        selectedPortfolio,
        metrics,
        status: LOADED
      });
    }
    case CREATING_PROPERTY: {
      return merge(state, { status: CREATING });
    }
    case CREATING_PROPERTY_SUCCESSFUL: {
      const { property } = action;
      return merge(state, {
        properties: [...state.properties, ...property],
        status: LOADED
      });
    }
    case FETCHING_PROPERTY_FROM_REIS_NETWORK: {
      return merge(state, {
        isLoadingReisProperty: true,
        status: FETCHING
      });
    }
    case FETCHING_PROPERTY_FROM_REIS_NETWORK_SUCCESSFUL: {
      const reisProperty = action.property;
      return merge(state, {
        reisProperty,
        isLoadingReisProperty: false,
        status: LOADED
      });
    }
    case AUTOCOMPLETE_SUCCESSFUL: {
      const { recommendedQueries } = action;
      return { ...state, recommendedQueries };
    }
    case SUGGESTIONS_SUCCESSFUL: {
      const { recommendedSuggestions } = action;
      return { ...state, recommendedSuggestions };
    }
    case AUTOCOMPLETE_PROPERTY_SEARCH_SUCCESSFUL: {
      const { suggestedProperties } = action;
      return { ...state, suggestedProperties };
    }
    case AUTOCOMPLETE_GOOGLE_PLACES_SUCCESSFUL: {
      const { addresses } = action.addresses;
      return { ...state, addresses };
    }
    case FIND_GOOGLE_PLACES_SUCCESSFUL: {
      const { formatted_address, geometry, name, types } = action.addresses;
      return { ...state, formatted_address, geometry, name, types };
    }
    case FIND_GOOGLE_PLACES_BY_ID_SUCCESSFUL: {
      const { formatted_address, geometry, name, types } = action.addresses;
      return { ...state, formatted_address, geometry, name, types };
    }
    case HANDLE_TEXT_CHANGE:
      return {
        ...state,
        q: action.text
      };
    case HANDLE_SUGGESTIONS_TEXT_CHANGE:
      return {
        ...state,
        q: action.text
      };
    case RESET_SEARCH:
      return {
        ...state,
        q: "",
        recommendedQueries: []
      };
    case RESET_SUGGESTIONS:
      return {
        ...state,
        q: "",
        recommendedSuggestions: []
      };
    case FETCHING_PROPERTY: {
      return merge(state, { status: FETCHING });
    }
    case FETCH_PROPERTY_SUCCESSFUL: {
      const { property } = action;
      return merge(state, {
        selectedProperty: property,
        status: LOADED
      });
    }
    case SPLIT_PANEL_REFRESHED: {
      const { property } = action;
      return merge(state, {
        selectedProperty: property,
        status: LOADED
      });
    }
    case CLEAR_SELECTED_PORTFOLIO: {
      return { ...state, selectedPortfolio: {} };
    }
    case CLEAR_METRICS: {
      return { ...state, metrics: {} };
    }
    case CLEAR_PROPERTIES: {
      return { ...state, properties: [] };
    }
    case FETCH_PROPERTIES_BY_COMPANY_SUCCESFULLY: {
      const { properties } = action;
      return { ...state, properties };
    }
    case PROPERTIES_SUCCESS_FETCH_TO_SELECT: {
      const { propertiesSelect } = action;
      return { ...state, propertiesSelect };
    }
    case CLEAR_PROPERTIES_SELECT: {
      return { ...state, propertiesSelect: [] };
    }
    default: {
      return state;
    }
  }
}
