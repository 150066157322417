import { useRef } from "react";
import { connect } from "react-redux";
import Popup from "reactjs-popup";
import cx from "classnames";

import Button from "ui/components/shared/Button";
import UploadPanel from "ui/components/main/upload/UploadPanel";

const UploadPopover = () => {
  const ref = useRef();
  const closePopover = () => ref.current.close();
  return (
    <div
      style={{
        alignSelf: "flex-end"
      }}
    >
      <Popup
        ref={ref}
        trigger={
          <Button
            className={cx("Button__blueButton")}
            style={{
              display: "inline-flex",
              cursor: "pointer",
              border: "1px solid #FFFFFF",
              marginLeft: "10px",
              marginRight: "auto"
            }}
          >
            + Upload Documents
          </Button>
        }
        arrow={false}
        closeOnDocumentClick={true}
        contentStyle={{
          marginRight: "15px",
          width: "0",
          padding: "0",
          top: "60px",
          right: "500px"
        }}
      >
        <UploadPanel manageFilesView={false} closePopover={closePopover} />
      </Popup>
    </div>
  );
};

export default connect()(UploadPopover);
