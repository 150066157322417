import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Button from "ui/components/shared/Button";
import IconButton from "ui/components/shared/IconButton";
import cx from "classnames";

export class FilterButton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { numSubFilters = 0, filterHeader, onClick } = this.props;
    return (
      <Button
        className="Button__filterButton"
        key={filterHeader}
        onClick={onClick}
      >
        <div style={{ alignSelf: "flex-start" }}>{filterHeader}</div>
        <div className="FilterButton__expandButton">
          {numSubFilters}
          <IconButton
            className={cx("arrow-button", "FilterButton__rightArrow")}
            src={require("ui/components/shared/images/arrows/arrow-right-blue.png")}
            style={{ paddingLeft: "4px" }}
          />
        </div>
      </Button>
    );
  }
}

FilterButton.propTypes = {
  filterHeader: PropTypes.string,
  onClick: PropTypes.func,
  numSubFilters: PropTypes.number
};

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps)(FilterButton);
