import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  updateFilter,
  removeFromActiveFilters,
  setSearchInput
} from "ui/store/actions/filters";

import { filterList } from "ui/components/filters/filterUtils";

const FilterBreadCrumb = ({
  filterType,
  filterValue,
  updateFilter,
  removeFromActiveFilters,
  updateCanSearch,
  setSearchInput
}) => {
  const onCloseFilterBreadCrumb = () => {
    updateCanSearch(true);
    if (filterType === "searchInput") {
      setSearchInput(null);
      removeFromActiveFilters(filterType, filterValue);
    } else {
      updateFilter(filterType, false, filterValue);
      removeFromActiveFilters(filterType, filterValue);
    }
  };
  return (
    <div className="FilterBreadCrumb">
      {`${filterList[filterType].header}: ${
        filterType === "tenants" ? filterValue.toUpperCase() : filterValue
      }`}
      <span
        className="FilterBreadCrumb__span"
        onClick={() => onCloseFilterBreadCrumb()}
      >
        x
      </span>
    </div>
  );
};

FilterBreadCrumb.propTypes = {
  filterType: PropTypes.string,
  filterValue: PropTypes.string,
  updateFilter: PropTypes.func,
  removeFromActiveFilters: PropTypes.func,
  updateCanSearch: PropTypes.func,
  setSearchInput: PropTypes.func
};

const mapStateToProps = ({ filters }) => ({
  allFilters: filters.filters
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { updateFilter, removeFromActiveFilters, setSearchInput },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(FilterBreadCrumb);
