import {
  DUPLICATE,
  DELETE,
  ARCHIVE,
  PUBLISH,
  UNPUBLISH,
  CREATE,
  EDIT
} from "ui/components/admin/templates/shared/constants";

const showOption = (option, statuses) => {
  const { isPublished, isArchived, inUse, isCL } = statuses;
  switch (option) {
    case DELETE:
      return (!isPublished && !isArchived) || (isPublished && !inUse);
    case DUPLICATE:
      return !isCL;
    case ARCHIVE:
      return isPublished && inUse;
    case UNPUBLISH:
      return !inUse && isPublished;
    case PUBLISH:
      return !isPublished || isArchived;
    case CREATE:
      return !isCL;
    case EDIT:
      return !isCL;
    default:
      return false;
  }
};

export default showOption;
