import { useMemo, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import useSplitPanelV2Context from "../../hooks/useSplitPanelV2Context";
import useDocument from "../../hooks/useDocument";
import useProperty from "../../hooks/useProperty";
import useEffectiveDateStatus from "./hooks/useEffectiveDateStatus";
import EffectiveDatePicker from "./components/EffectiveDatePicker/EffectiveDatePicker";
import TablePill from "./components/TablePill/TablePill";
import TableInfo from "./components/TableInfo/TableInfo";
import NavLink from "ui/components/shared/NavLink";
import { getPublishedDate } from "ui/components/rentRoll/property/helpers/documents";
import { companyGroupNames } from "helpers/companies";
import dayjs from "dayjs";

const IMPLICIT_EFFECTIVE_DATE = "IMPLICIT_EFFECTIVE_DATE";
const NO_GUESS_EFFECTIVE_DATE = "NO_GUESS_EFFECTIVE_DATE";
export default function LeftSidebar({
  activeDocumentTables,
  currentHeaderIndex,
  documentTables,
  effectiveDate,
  onEffectiveDateUpdated,
  onSelectTable,
  onSetActiveTable,
  groupName,
  isSubmitting
}) {
  const { isWidgetAuthorized } = useSplitPanelV2Context();

  const totalRows = documentTables.reduce(
    (acc, curr) => acc + curr.rowCount,
    0
  );
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const {
    effectiveDateStatus,
    update: updateEffectiveDateStatus,
    mutate: mutateEffectiveDateStatus
  } = useEffectiveDateStatus();
  const { property } = useProperty();
  const {
    document,
    update: updateDocument,
    initializedVacantFlag
  } = useDocument();

  const effectiveDateDisplayValue = useMemo(
    () =>
      effectiveDate
        ? dayjs(effectiveDate)
            .startOf("day")
            .format("MMMM DD, YYYY")
        : "",
    [effectiveDate]
  );

  const actionDescription =
    groupName === companyGroupNames.CPM.id ? "Published" : "Completed";

  const publishedDate = getPublishedDate(document);

  function openDatePicker() {
    setIsDatePickerOpen(true);
  }

  function closeDatePicker() {
    setIsDatePickerOpen(false);
  }

  async function handleSaveEffectiveDate(date) {
    if (
      isWidgetAuthorized &&
      groupName &&
      groupName === companyGroupNames.CL.id
    )
      return;

    const effective_date = dayjs(date)
      .startOf("day")
      .toISOString();
    const status = "MANUAL_EFFECTIVE_DATE";

    await updateDocument({ effective_date });
    await updateEffectiveDateStatus({ status });
    await Promise.all([mutateEffectiveDateStatus(), onEffectiveDateUpdated()]);
    closeDatePicker();
  }

  function getBackNav(portfolioId, propertyId) {
    return (
      <div>
        <NavLink
          to={{
            pathname: `/rent-roll/property/${propertyId}${
              portfolioId ? `/portfolio/${portfolioId}` : ""
            }`
          }}
        >
          <img
            className="SplitPanelV2__LeftSidebar__back-button-img"
            src={require("ui/components/shared/images/arrows/LeftArrow.png")}
          />
        </NavLink>
      </div>
    );
  }

  return (
    <>
      <div className="SplitPanelV2__LeftSidebar__back">
        {!isWidgetAuthorized ? (
          getBackNav(property.portfolio_id, property.id)
        ) : groupName ? (
          groupName === companyGroupNames.CPM.id ? (
            <div>
              <NavLink
                to={{
                  pathname: `/manage-files-view`
                }}
              >
                <img
                  className="SplitPanelV2__LeftSidebar__back-button-img"
                  src={require("ui/components/shared/images/arrows/LeftArrow.png")}
                />
              </NavLink>
            </div>
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </div>

      <div className="SplitPanelV2__LeftSidebar">
        <div className="SplitPanelV2__LeftSidebar-overview">
          {(!isWidgetAuthorized ||
            (groupName && groupName === companyGroupNames.CPM.id)) && (
            <p className="SplitPanelV2__LeftSidebar-filename">
              {document.original_filename}
            </p>
          )}
          <p>
            <small>
              {documentTables.length} Tables / {totalRows} Rows
            </small>
          </p>

          <div className="SplitPanelV2__LeftSidebar-table-pills">
            {documentTables.map(({ headerIndex }) => (
              <TablePill
                key={`TablePill-${headerIndex}`}
                isActive={!!activeDocumentTables[headerIndex]}
                isSelected={headerIndex === currentHeaderIndex}
              />
            ))}
          </div>
        </div>

        <div className="SplitPanelV2__LeftSidebar-actions">
          {document && document.status === "COMPLETED" ? (
            <label>
              <div
                className={cx(
                  "SplitPanelV2__LeftSidebar-completed-on-timestamp",
                  {
                    "SplitPanelV2__LeftSidebar-completed-on-timestamp--hidden": isSubmitting
                  }
                )}
              >
                <img src={require("ui/images/green-tick.svg")} />
                <span>
                  {`${actionDescription} at ${publishedDate.format(
                    "HH:mm:ss A"
                  )} on ${publishedDate.format("MMMM DD, YYYY")}`}
                </span>
              </div>
            </label>
          ) : (
            ""
          )}
          {(!isWidgetAuthorized ||
            (groupName && groupName === companyGroupNames.CPM.id)) && (
            <>
              <label>
                Property
                <p>{property.name}</p>
              </label>
              <label>
                Property Type
                <p>{property.property_type}</p>
              </label>

              <label>
                Effective Date{" "}
                {effectiveDateStatus === IMPLICIT_EFFECTIVE_DATE ||
                effectiveDateStatus === NO_GUESS_EFFECTIVE_DATE
                  ? "(please confirm)"
                  : ""}
                {isDatePickerOpen ? (
                  <EffectiveDatePicker
                    close={closeDatePicker}
                    effectiveDate={effectiveDate}
                    handleSaveEffectiveDate={handleSaveEffectiveDate}
                    initializedVacantFlag={initializedVacantFlag}
                  />
                ) : (
                  <p
                    className={cx("SplitPanelV2__LeftSidebar-effective-date", {
                      ["SplitPanelV2__LeftSidebar-effective-date--implicit"]:
                        effectiveDateStatus === IMPLICIT_EFFECTIVE_DATE,
                      ["SplitPanelV2__LeftSidebar-effective-date--no-guess"]:
                        effectiveDateStatus === NO_GUESS_EFFECTIVE_DATE
                    })}
                    onClick={openDatePicker}
                  >
                    {effectiveDateDisplayValue}
                  </p>
                )}
              </label>
            </>
          )}
        </div>
        <div className="SplitPanelV2__LeftSidebar-table-list-outer">
          <h4>Tables</h4>
          <div className="SplitPanelV2__LeftSidebar-table-list">
            {documentTables.map(table => (
              <TableInfo
                key={`TableInfo-${table.headerIndex}`}
                isActive={!!activeDocumentTables[table.headerIndex]}
                isSelected={table.headerIndex === currentHeaderIndex}
                onSelectTable={onSelectTable}
                onSetActiveTable={onSetActiveTable}
                table={table}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

LeftSidebar.propTypes = {
  activeDocumentTables: PropTypes.arrayOf(PropTypes.bool).isRequired,
  currentHeaderIndex: PropTypes.number.isRequired,
  documentTables: PropTypes.arrayOf(PropTypes.object).isRequired,
  effectiveDate: PropTypes.string,
  onEffectiveDateUpdated: PropTypes.func.isRequired,
  onSelectTable: PropTypes.func.isRequired,
  onSetActiveTable: PropTypes.func.isRequired,
  groupName: PropTypes.string,
  isSubmitting: PropTypes.bool
};
