import PropTypes from "prop-types";
import cx from "classnames";
import Popup from "reactjs-popup";
import { connect } from "react-redux";

import AccountMenuDropDown from "./AccountMenuDropDown";

import { toggleDropDown } from "ui/store/actions/accountMenu";

function AccountMenu({
  className,
  userEmail,
  companyName,
  toggleAccountMenu,
  inAdminView,
  originalCompany
}) {
  return (
    <Popup
      trigger={
        <nav className={cx("AccountMenu", className)}>
          <div className="AccountMenu__user-details">
            <div className="AccountMenu__icon-container">
              <img
                src={require("../../images/icon-account-white.svg")}
                className="AccountMenu__icon"
              />
            </div>
            <div className="AccountMenu__details-container">
              <div className="AccountMenu__email">{userEmail}</div>
              <div
                className={
                  "AccountMenu__company-name" +
                  (originalCompany ? " AccountMenu__on-behalf-of-company" : "")
                }
              >
                {companyName}
              </div>
            </div>
            <div className="AccountMenu__inner" onClick={toggleAccountMenu}>
              <div className="HeaderWithSidebar__header-account-details-help-chevron" />
            </div>
          </div>
        </nav>
      }
      closeOnDocumentClick
      arrow={false}
      position="right center"
      contentStyle={{ margin: "0", width: "0", padding: "0" }}
    >
      <AccountMenuDropDown
        toggleClassName="AccountMenu__inner"
        inAdminView={inAdminView}
      />
    </Popup>
  );
}

AccountMenu.propTypes = {
  className: PropTypes.string,
  userEmail: PropTypes.string,
  companyName: PropTypes.string,
  toggleAccountMenu: PropTypes.func,
  showDropDown: PropTypes.bool,
  inAdminView: PropTypes.bool,
  originalCompany: PropTypes.string
};

function mapStateToProps({ currentUser, accountMenu }) {
  const { email } = currentUser;
  const { company_name } = currentUser.company;
  const { dropDown } = accountMenu;
  const originalCompany = currentUser.originalCompany
    ? currentUser.originalCompany.company_name
    : null;
  return {
    userEmail: email,
    companyName: company_name,
    showDropDown: dropDown,
    originalCompany: originalCompany
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleAccountMenu: function() {
      dispatch(toggleDropDown());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountMenu);
