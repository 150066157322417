import PropTypes from "prop-types";
import Split from "react-split";

const OpexSplitPanel = ({ children, ...props }) => (
  <Split
    className="OpexSplitPanel"
    sizes={[60, 40]}
    minSize={100}
    expandToMin={false}
    gutterSize={30}
    gutterAlign="center"
    snapOffset={30}
    dragInterval={1}
    cursor="col-resize"
    // if you want to overwrite any of the above defaults just pass it in
    {...props}
  >
    {children}
  </Split>
);

export default OpexSplitPanel;

OpexSplitPanel.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
};
