import AccountMenu from "ui/components/account/AccountMenu";
import NavLink from "ui/components/shared/NavLink.jsx";
import UploadPopover from "ui/components/main/upload/UploadPopover";
import DocumentsPopover from "ui/components/main/documents/DocumentsPopover";
import Flash from "ui/components/notification/Flash";

export default function Header() {
  return (
    <div className="SplitPanelV2__Header">
      <NavLink to={{ pathname: "/manage-files-view" }}>
        <img
          className="SplitPanelV2__Header-logo"
          src={require("ui/images/quiq-property-logo-white-256px.svg")}
          alt="logo"
        />
      </NavLink>
      <div className="SplitPanelV2__Header-actions">
        <div className="SplitPanelV2__Header-popover">
          <DocumentsPopover />
          <UploadPopover />
        </div>
        <div className="SplitPanelV2__Header-account-details">
          <div className="SplitPanelV2__Header-account-details-user">
            <AccountMenu className="" inAdminView={false} />
          </div>
        </div>
      </div>
      <Flash />
    </div>
  );
}
