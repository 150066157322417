import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { StickyTable, Row, Cell } from "react-sticky-table";
import { withRouter } from "react-router";
import NavLink from "ui/components/shared/NavLink";
import Button from "ui/components/shared/Button";
import Checkbox from "ui/components/shared/Checkbox2";
import { fields, getEffectiveDate } from "./helpers/documents";
import ThreeBounceSpinner from "ui/components/spinners/ThreeBounceSpinner";
import {
  deleteDocuments,
  fetchNeedAttentionDocuments,
  fetchRentRollDocsByPropId,
  fetchUploadingDocuments
} from "ui/store/actions/rentRoll";
import cx from "classnames";
import {
  alignStickyTableHeader,
  alignStickyTableCell,
  displayDocumentType
} from "./helpers/documents";
import { documentStatus } from "ui/components/manageFiles/helpers/manageFileList";
import { documentType } from "ui/components/helpers/documents";

class Documents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: {},
      fetchDocsInterval: null
    };
  }

  componentDidMount = () => {
    this.checkDocsInterval();
  };

  componentWillUnmount = () => {
    if (this.state.fetchDocsInterval) {
      clearInterval(this.state.fetchDocsInterval);
    }
  };

  checkDocsInterval = () => {
    const {
      fetchRentRollDocsByPropId,
      fetchUploadingDocuments,
      fetchNeedAttentionDocuments,
      documents,
      match: {
        params: { propertyId }
      }
    } = this.props;

    let hasProcessingDocs = false;
    documents.forEach(doc => {
      if (["CREATED", "PROCESSING_IMAGES", "ANALYZING"].includes(doc.status)) {
        hasProcessingDocs = true;
        return;
      }
    });

    if (hasProcessingDocs) {
      if (!this.state.fetchDocsInterval) {
        this.setState({
          fetchDocsInterval: setInterval(function() {
            fetchRentRollDocsByPropId(propertyId);
            fetchUploadingDocuments();
          }, 4000)
        });
      }
    } else if (this.state.fetchDocsInterval) {
      clearInterval(this.state.fetchDocsInterval);
      fetchUploadingDocuments();
      fetchNeedAttentionDocuments();
    }
  };

  componentDidUpdate = () => {
    this.checkDocsInterval();
  };

  handleToggle = e => {
    const name = parseInt(e.target.name);
    if (this.state.checked[name] === true) {
      this.setState({
        checked: { ...this.state.checked, [name]: false }
      });
    } else {
      this.setState({
        checked: { ...this.state.checked, [name]: true }
      });
    }
  };

  isDeleteButtonDisabled = () => {
    let disabled = true;
    Object.keys(this.state.checked).forEach(key => {
      if (this.state.checked[key]) {
        disabled = false;
      }
    });
    return disabled;
  };

  handleToggleAll = () => {
    const { documents } = this.props;
    const docsChecked = {};
    documents.forEach(doc => {
      docsChecked[doc.id] = true;
    });
    if (this.state.checked["all"] === true) {
      documents.forEach(doc => {
        docsChecked[doc.id] = false;
      });
      this.setState({
        checked: { all: false, ...docsChecked }
      });
    } else {
      documents.forEach(doc => {
        docsChecked[doc.id] = true;
      });
      this.setState({
        checked: { all: true, ...docsChecked }
      });
    }
  };
  getActionButton(status, docId, docType) {
    const {
      match: {
        params: { portfolioId, propertyId }
      }
    } = this.props;
    const docTypePath =
      docType === documentType.RENT_ROLL ? "rent-roll" : "opex";

    if (status === "PENDING_REVIEW") {
      return (
        <NavLink
          className="action-nav"
          to={{
            pathname: `/${docTypePath}/split-panel/property/${propertyId}/document/${docId}${
              portfolioId ? `/portfolio/${portfolioId}` : ""
            }`
          }}
        >
          <Button className="Button__blackButton">REVIEW</Button>
        </NavLink>
      );
    } else if (status === "COMPLETED") {
      return (
        <NavLink
          className="action-nav"
          to={{
            pathname: `/${docTypePath}/split-panel/property/${propertyId}/document/${docId}${
              portfolioId ? `/portfolio/${portfolioId}` : ""
            }`
          }}
        >
          <Button className="Button__blackButton">COMPLETED</Button>
        </NavLink>
      );
    } else {
      return null;
    }
  }

  handleDeleteClick = async deleteML => {
    const {
      deleteDocuments,
      onDeleteCheckCurrentDoc,
      currentDocument,
      match: {
        params: { propertyId }
      }
    } = this.props;
    const docIdsToDelete = [];
    Object.keys(this.state.checked).forEach(key => {
      if (key === "all") {
        return;
      }
      if (this.state.checked[key] === true) {
        const docId = key;
        docIdsToDelete.push(docId);
      }
    });
    await deleteDocuments(propertyId, docIdsToDelete, deleteML);
    this.setState({ checked: {} });

    if (
      currentDocument &&
      docIdsToDelete.includes(currentDocument.id.toString())
    ) {
      onDeleteCheckCurrentDoc();
    }
  };

  render() {
    const { documents, documentsGetStatus, userRole } = this.props;
    return (
      <div>
        <div className="Documents">
          <div style={{ fontSize: "20px" }}>Documents</div>
          <br />
          <StickyTable
            borderWidth={"1px"}
            style={{ zIndex: 0, height: "auto" }}
          >
            <Row className={cx("sticky-table-header", "row-header")}>
              <Cell
                key={`checkbox_all`}
                style={{
                  backgroundColor: "#403f3f",
                  verticalAlign: "middle"
                }}
              >
                <Checkbox
                  key={`checkbox_row_all`}
                  name={"all"}
                  checked={this.state.checked["all"] === true}
                  toggleCheck={this.handleToggleAll}
                />
              </Cell>
              {fields.map(column_name => (
                <Cell
                  className={cx(alignStickyTableHeader(column_name))}
                  style={{
                    backgroundColor: "#403f3f",
                    verticalAlign: "middle"
                  }}
                  key={column_name}
                >
                  {column_name}
                </Cell>
              ))}
            </Row>
            {documentsGetStatus === "FETCHING" ? (
              <ThreeBounceSpinner />
            ) : (
              documents &&
              documents.map((doc, index) => {
                return (
                  <Row
                    key={index}
                    className={
                      index % 2 === 0 ? "row-body" : "row-body row-odd"
                    }
                  >
                    <Cell key={`checkbox_${index}`}>
                      <Checkbox
                        key={`checkbox_row_${index}`}
                        className={cx(
                          "sticky-table__cell",
                          "sticky-table__cell--small",
                          { "sticky-table__cell--odd": index % 2 !== 0 }
                        )}
                        name={doc.id.toString()}
                        checked={this.state.checked[doc.id] === true}
                        toggleCheck={this.handleToggle}
                      />
                    </Cell>
                    <Cell
                      key="file_name"
                      className={cx(
                        "sticky-table__cell",
                        alignStickyTableCell("File Name"),
                        {
                          "sticky-table__cell--odd": index % 2 !== 0
                        }
                      )}
                    >
                      <div className="Documents__doc-title">
                        {getEffectiveDate(doc.effectiveDate)}
                      </div>
                      <div className="Documents__doc-filename">
                        {doc.originalFilename}
                      </div>
                    </Cell>
                    <Cell
                      key="type"
                      className={cx(
                        "sticky-table__cell",
                        alignStickyTableCell("Type"),
                        {
                          "sticky-table__cell--odd": index % 2 !== 0
                        }
                      )}
                    >
                      {displayDocumentType[doc.documentType]}
                    </Cell>
                    <Cell
                      key="status"
                      className={cx(
                        "sticky-table__cell",
                        alignStickyTableCell("Status"),
                        {
                          "sticky-table__cell--odd": index % 2 !== 0
                        }
                      )}
                    >
                      {documentStatus[doc.status]}
                    </Cell>
                    <Cell
                      key="updated_at"
                      className={cx(
                        "sticky-table__cell",
                        alignStickyTableCell("Last Update"),
                        {
                          "sticky-table__cell--odd": index % 2 !== 0
                        }
                      )}
                    >
                      {getFormattedDate(doc.updatedAt)}
                    </Cell>
                    <Cell
                      key="updated_by"
                      className={cx(
                        "sticky-table__cell",
                        alignStickyTableCell("By"),
                        {
                          "sticky-table__cell--odd": index % 2 !== 0
                        }
                      )}
                    >
                      {doc.userEmail}
                    </Cell>
                    <Cell
                      key="actions"
                      className={cx(
                        "sticky-table__cell",
                        alignStickyTableCell(),
                        {
                          "sticky-table__cell--odd": index % 2 !== 0
                        }
                      )}
                    >
                      {this.getActionButton(
                        doc.status,
                        doc.id,
                        doc.documentType
                      )}
                    </Cell>
                  </Row>
                );
              })
            )}
          </StickyTable>
          <Button
            disabled={this.isDeleteButtonDisabled()}
            onClick={() => this.handleDeleteClick(false)}
            className="Button__deleteButton"
          >
            Delete
          </Button>
          {userRole === "superadmin" && (
            <Button
              disabled={this.isDeleteButtonDisabled()}
              onClick={() => this.handleDeleteClick(true)}
              className="Button__deleteButton"
            >
              Delete with ML
            </Button>
          )}
        </div>
      </div>
    );
  }
}

Documents.propTypes = {
  documents: PropTypes.array,
  match: PropTypes.object,
  deleteDocuments: PropTypes.func,
  fetchRentRollDocsByPropId: PropTypes.func,
  documentsGetStatus: PropTypes.string,
  userRole: PropTypes.string,
  onDeleteCheckCurrentDoc: PropTypes.func,
  fetchUploadingDocuments: PropTypes.func,
  fetchNeedAttentionDocuments: PropTypes.func,
  currentDocument: PropTypes.object
};

function mapStateToProps(state) {
  return {
    documents: state.rentRoll.documents,
    documentsGetStatus: state.rentRoll.status,
    userRole: state.currentUser.role
  };
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      deleteDocuments,
      fetchRentRollDocsByPropId,
      fetchUploadingDocuments,
      fetchNeedAttentionDocuments
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Documents)
);

// TODO remove or add to helper file
function getFormattedDate(timestamp) {
  const date = new Date(timestamp);
  return date.toLocaleString([], { dateStyle: "short", timeStyle: "short" });
}
