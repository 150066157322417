import PropTypes from "prop-types";
import {
  configureExceptions,
  exceptionSidebarConfig,
  exceptionSidebarLabelDescription,
  getTotalExceptions
} from "ui/components/rentRoll/splitPanelV2/helpers/exceptions";
import { getTotalPercentageComplete } from "ui/components/rentRoll/splitPanelV2/helpers/processing";
import ExceptionSubsection from "./ExceptionSubsection";
import useDocumentTables from "../../hooks/useDocumentTables";

export default function ExceptionsSidebar({
  isOpen,
  handleToggleExceptionsSidebar,
  exceptions,
  expandErrorList,
  collapseErrorList,
  document,
  rentRoll,
  handleScrollToCell,
  formState,
  groupName,
  property
}) {
  const { documentTables } = useDocumentTables();
  const exceptionsConfig = configureExceptions(
    exceptions,
    document,
    rentRoll,
    documentTables,
    groupName,
    property
  );
  const totalExceptions = getTotalExceptions(exceptionsConfig);
  const totalPctComplete = getTotalPercentageComplete(document, rentRoll);

  if (!isOpen)
    return (
      <div className="SplitPanelV2__ExceptionsSidebar__triggerOpen">
        <img
          src={require("ui/images/arrow-left-blue.png")}
          onClick={() => handleToggleExceptionsSidebar()}
        />
        {totalExceptions.blocking > 0 && (
          <div className="SplitPanelV2__ExceptionsSidebar__badgeWrap">
            <span className="SplitPanelV2__ExceptionsSidebar__badge SplitPanelV2__ExceptionsSidebar__badgeErrors">
              {totalExceptions.blocking > 99 ? (
                <span>
                  99<sup>+</sup>
                </span>
              ) : (
                totalExceptions.blocking
              )}
            </span>
          </div>
        )}
        {totalExceptions.warnings > 0 && (
          <div className="SplitPanelV2__ExceptionsSidebar__badgeWrap">
            <span className="SplitPanelV2__ExceptionsSidebar__badge SplitPanelV2__ExceptionsSidebar__badgeWarnings">
              {totalExceptions.warnings > 99 ? (
                <span>
                  99<sup>+</sup>
                </span>
              ) : (
                totalExceptions.warnings
              )}
            </span>
          </div>
        )}
      </div>
    );

  return (
    <div className="SplitPanelV2__ExceptionsSidebar">
      <div>
        <img
          className="SplitPanelV2__ExceptionsSidebar__triggerClose"
          src={require("ui/images/arrow-right-blue.png")}
          onClick={() => handleToggleExceptionsSidebar()}
        />
        <div className="SplitPanelV2__ExceptionsSidebar__header__label">
          {exceptionSidebarConfig.label}
        </div>
      </div>
      <div>
        {totalPctComplete
          ? exceptionSidebarLabelDescription(100)
          : exceptionSidebarLabelDescription(totalPctComplete)}
      </div>
      <div className="SplitPanelV2__ExceptionsSidebar__body">
        {exceptionsConfig
          ? ["blocking", "warnings", "resolved"].map(key => {
              let expandErrors = false;
              if (key === "blocking") {
                expandErrors = expandErrorList;
              }
              if (exceptionsConfig[key] && exceptionsConfig[key].length === 0) {
                return null;
              }
              return (
                <ExceptionSubsection
                  key={key}
                  exceptionType={key}
                  title={exceptionSidebarConfig[key].subHeader}
                  exceptions={exceptionsConfig[key]}
                  expandErrors={expandErrors}
                  collapseErrorList={collapseErrorList}
                  countColor={exceptionSidebarConfig[key].countColor}
                  handleScrollToCell={handleScrollToCell}
                  formState={formState}
                />
              );
            })
          : null}
      </div>
    </div>
  );
}

ExceptionsSidebar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleToggleExceptionsSidebar: PropTypes.func.isRequired,
  exceptions: PropTypes.array,
  expandErrorList: PropTypes.bool,
  collapseErrorList: PropTypes.func,
  document: PropTypes.object,
  rentRoll: PropTypes.array,
  handleScrollToCell: PropTypes.func.isRequired,
  formState: PropTypes.string.isRequired,
  groupName: PropTypes.string,
  property: PropTypes.object
};
