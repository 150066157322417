import PropTypes from "prop-types";
import cx from "classnames";

import EntityBadge from "./EntityBadge";
import RadioLabelDetail from "./RadioLabelDetail";

import {
  ENTITY_BADGE_BVD,
  ENTITY_BADGE_CORTERA,
  ENTITY_BADGE_SAFEGRAPH
} from "./helpers/util";
import { hasAdminAccess } from "helpers/authorization";

const RadioLabel = ({
  selectedField,
  predef,
  entityBadgeData,
  noIndent,
  userRole,
  isRadioSelected,
  removePredefValue
}) => {
  const entityData = entityBadgeData ? entityBadgeData : predef;

  return (
    <label
      className="EntityResolution__radioLabel"
      htmlFor={`${selectedField}-${predef.value}`}
    >
      <div
        className={cx("EntityResolution__radioLabelHeader", {
          EntityResolution__noIndent: noIndent
        })}
      >
        {predef.value}
        <EntityBadge s={entityBadgeData ? entityBadgeData : predef} />
        {((predef.id && predef.ref && predef.ref.bvdid) ||
          (entityBadgeData &&
            entityBadgeData.id &&
            entityBadgeData.ref &&
            entityBadgeData.ref.bvdid)) && (
          <EntityBadge type={ENTITY_BADGE_BVD} />
        )}
        {((predef.id && predef.ref && predef.ref.cortera) ||
          (entityBadgeData &&
            entityBadgeData.id &&
            entityBadgeData.ref &&
            entityBadgeData.ref.cortera)) && (
          <EntityBadge type={ENTITY_BADGE_CORTERA} />
        )}
        {((predef.id && predef.ref && predef.ref.safegraph) ||
          (entityBadgeData &&
            entityBadgeData.id &&
            entityBadgeData.ref &&
            entityBadgeData.ref.safegraph)) && (
          <EntityBadge type={ENTITY_BADGE_SAFEGRAPH} />
        )}
        {hasAdminAccess(userRole) &&
          entityData &&
          entityData.id &&
          (!entityData.ref ||
            (entityData.ref &&
              (entityData.ref.bvdid ||
                entityData.ref.cortera ||
                entityData.ref.safegraph))) &&
          !isRadioSelected && (
            <span>
              <img
                className="EntityResolution__deleteIcon"
                src={require("ui/images/icon-trash.png")}
                onClick={e => {
                  removePredefValue(e, entityData.id, predef.value);
                }}
              />
            </span>
          )}
      </div>
      {predef.ownershipName && (
        <RadioLabelDetail
          predefValue={predef.ownershipName}
          noIndent={noIndent}
        />
      )}
      {predef && predef.metadata && predef.metadata.ownershipName && (
        <RadioLabelDetail
          predefValue={predef.metadata.ownershipName}
          noIndent={noIndent}
        />
      )}
      {predef.ownershipId && (
        <RadioLabelDetail
          predefValue={predef.ownershipId}
          noIndent={noIndent}
        />
      )}
      {predef && predef.metadata && predef.metadata.ownershipId && (
        <RadioLabelDetail
          predefValue={predef.metadata.ownershipId}
          noIndent={noIndent}
        />
      )}
      {predef && predef.address && (
        <RadioLabelDetail predefValue={predef.address} noIndent={noIndent} />
      )}
      {predef && predef.metadata && predef.metadata.address && (
        <RadioLabelDetail
          predefValue={predef.metadata.address}
          noIndent={noIndent}
        />
      )}
      {predef.industry && (
        <RadioLabelDetail
          predefValue={predef.industry}
          noIndent={noIndent}
          addIndustryLabel={true}
        />
      )}
      {predef && predef.metadata && predef.metadata.industry && (
        <RadioLabelDetail
          predefValue={predef.metadata.industry}
          noIndent={noIndent}
          addIndustryLabel={true}
        />
      )}
    </label>
  );
};

RadioLabel.defaultProps = {
  isRadioSelected: false
};

RadioLabel.propTypes = {
  selectedField: PropTypes.string,
  predef: PropTypes.object,
  entityBadgeData: PropTypes.object,
  noIndent: PropTypes.bool,
  userRole: PropTypes.string,
  isRadioSelected: PropTypes.bool,
  removePredefValue: PropTypes.func
};

export default RadioLabel;
