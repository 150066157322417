import { Component } from "react";
import PropTypes from "prop-types";
import { Tab, Tabs, TabList } from "react-tabs";
import NavLink from "ui/components/shared/NavLink.jsx";
import cx from "classnames";

class HeaderNavTabs extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { portfolioId, selectedIndex } = this.props;
    return (
      <Tabs selectedIndex={selectedIndex}>
        <TabList>
          <NavLink
            className={cx("HeaderNavTabs__navLink")}
            to={{
              pathname: "/properties",
              search: `?portfolioId=${portfolioId}`
            }}
          >
            <Tab>PROPERTIES</Tab>
          </NavLink>
          <NavLink
            className={cx("HeaderNavTabs__navLink")}
            to={{
              pathname: "/tenants",
              search: `?portfolioId=${portfolioId}`
            }}
          >
            <Tab>TENANTS</Tab>
          </NavLink>
        </TabList>
      </Tabs>
    );
  }
}

HeaderNavTabs.propTypes = {
  portfolioId: PropTypes.string
};

export default HeaderNavTabs;
