import PropTypes from "prop-types";
import cx from "classnames";
import useRentRollTableData from "ui/components/rentRoll/splitPanelV2/hooks/useRentRollTableData";
import computeCellFlags from "ui/components/rentRoll/splitPanelV2/helpers/computeCellFlags";
import useExceptions from "ui/components/rentRoll/splitPanelV2/hooks/useExceptions";
import doesCellHaveRentRoll from "ui/components/rentRoll/splitPanelV2/helpers/doesCellHaveRentRoll";
import { canCellBeMarkedAsMapped } from "ui/components/rentRoll/splitPanelV2/helpers/canCellBeMarkedAsMapped";
import rowOuter from "ui/components/rentRoll/splitPanelV2/helpers/rowOuter";
import colOuter from "ui/components/rentRoll/splitPanelV2/helpers/colOuter";
import tableOuter from "ui/components/rentRoll/splitPanelV2/helpers/tableOuter";
import { maxHeaderRows } from "ui/components/rentRoll/splitPanelV2/helpers/constants";
import FORM_STATES from "ui/components/rentRoll/splitPanelV2/helpers/formStates";
import { isBrokenTable } from "ui/components/rentRoll/splitPanelV2/helpers/tableKeys";
import { generateTableKey } from "ui/components/rentRoll/splitPanelV2/helpers/tableKeys.js";
import { useEffect, useState } from "react";
export default function PdfMarkupView({
  onSelectTable,
  visible,
  setLeftMargin,
  onSelectedCell,
  activeDocumentTables,
  setSelectedRentRollCell,
  currentPageIndex,
  currentHeaderIndex,
  page,
  headers,
  selected,
  rentRoll,
  documentMetadata,
  setDocumentMetadata,
  documentTables,
  formState,
  parentElement
}) {
  let maxButtonSize = 0;
  const [key, setKey] = useState(0); // used to force a re-render on resize
  const [to, setTo] = useState(); // timeout for debouncing
  const { exceptions } = useExceptions();
  const rentRollTableData = useRentRollTableData();
  const currentTableMetadata =
    (documentMetadata && documentMetadata[currentHeaderIndex]) || {};
  const currentTable = documentTables[currentHeaderIndex] || {};
  const rowIds = (currentHeaderIndex >= 0 && currentTableMetadata.rowIds) || [];
  const headerRowIndex =
    (documentMetadata &&
      currentHeaderIndex >= 0 &&
      currentTableMetadata.rowIndex) ||
    0;

  const setSelectedCells = (documentKey, rentRollKey) => {
    onSelectedCell(documentKey);
    setSelectedRentRollCell(rentRollKey);
  };

  const isTableSelected = tableIndex =>
    currentHeaderIndex >= 0 && currentTable.tableIndex === tableIndex;

  useEffect(() => {
    if (
      currentHeaderIndex === undefined ||
      !parentElement ||
      currentTable.tableIndex === undefined
    ) {
      setLeftMargin(0);
      return;
    }
    if (page.tableData.length > 0) {
      const offsetLeft =
        parentElement.offsetLeft +
        page.tableData[currentTable.tableIndex][0][0].geometry.boundingBox
          .Left *
          parentElement.clientWidth;
      if (offsetLeft < maxButtonSize * 2) {
        setLeftMargin(maxButtonSize * 2);
      }
    } else {
      setLeftMargin(0);
    }
  }, [currentHeaderIndex, currentPageIndex, documentTables, key]);

  useEffect(() => {
    function handleResize() {
      clearTimeout(to);
      setTo(
        setTimeout(() => {
          setKey(key => key + 1);
        }, 100)
      );
    }
    new ResizeObserver(handleResize).observe(
      document.querySelector(".SplitPanelV2__contents")
    );
  }, []);

  function setHeaderRowIndex({ headerIndex, rowIndex }) {
    documentMetadata[headerIndex].rowIds = [rowIndex];
    setDocumentMetadata([...documentMetadata]);
  }

  function addHeaderRowIndex({ headerIndex, rowIndex }) {
    const rowIds = documentMetadata[headerIndex].rowIds;
    if (rowIds.length < maxHeaderRows) {
      rowIds.push(rowIndex);
      rowIds.sort();
      setDocumentMetadata([...documentMetadata]);
    }
  }

  function calcPositions(col) {
    const maxHeight = 40;
    let { Height: height, Left: left, Top: top } = col.geometry.boundingBox;
    const clientHeight = parentElement.clientHeight * height;
    const heightInPixels = clientHeight > maxHeight ? maxHeight : clientHeight;
    maxButtonSize = Math.max(maxButtonSize, heightInPixels);
    return {
      left: left * 100,
      height: heightInPixels,
      top:
        (top +
          (clientHeight - heightInPixels) / parentElement.clientHeight / 2) *
        100
    };
  }

  function isTableActive(tableIndex) {
    if (
      currentHeaderIndex === undefined ||
      currentTable.tableIndex === undefined ||
      currentTable.headerIndex === undefined
    )
      return;
    return (
      currentTable.tableIndex === tableIndex &&
      activeDocumentTables[currentTable.headerIndex]
    );
  }

  return (
    <svg
      id="svg-markup"
      key={key}
      height="100%"
      width="100%"
      style={{ overflow: "visible", opacity: !visible && "0" }}
    >
      <svg
        viewBox="0 0 100 100"
        width="100%"
        height="100%"
        preserveAspectRatio="none"
      >
        <defs>
          <mask id={"greyMask_" + currentPageIndex} width="100%" height="100%">
            <rect width="100%" height="100%" fill="white"></rect>
            {page.tableData.map((table, tableIndex) => {
              const points = tableOuter(table);
              return (
                <g key={`tableOuter-${tableIndex}`}>
                  <polygon
                    key={`tableOuter-${tableIndex}`}
                    points={`${points.x1},${points.y1} ${points.x2},${points.y2} ${points.x3},${points.y3}, ${points.x4},${points.y4}`}
                    fill={isTableActive(tableIndex) ? "black" : "white"}
                  />
                </g>
              );
            })}
          </mask>
        </defs>
        <rect
          className="PdfDocumentView__mask"
          mask={`url(#greyMask_${currentPageIndex})`}
          width="130%"
          height="130%"
        ></rect>
      </svg>
      {page.tableData.map((table, tableIndex) => {
        const rentRolls = rentRoll.filter(
          ({ doc_page_id, doc_table_id }) =>
            doc_page_id === currentPageIndex && doc_table_id === tableIndex
        );
        const rentRollHeaders =
          currentHeaderIndex >= 0 &&
          currentTable.tableIndex !== undefined &&
          rentRollTableData[currentTable.tableIndex] !== undefined
            ? rentRollTableData[currentTable.tableIndex].headers.filter(
                h => h.columnIndex > -1
              )
            : [];

        const rows = table.map((row, rowIndex) => {
          const lastHeaderRowIndex = rowIds[rowIds.length - 1];
          const isPrevAdjacentRow =
            rowIds[0] !== 0 && rowIndex === rowIds[0] - 1;
          const isNextAdjacentRow =
            lastHeaderRowIndex !== table.length - 1 &&
            rowIndex === lastHeaderRowIndex + 1;

          const AddHeaderButton = () => {
            if (
              rowIds.length >= maxHeaderRows ||
              !isTableActive(tableIndex) ||
              isBrokenTable(headerRowIndex) ||
              formState !== FORM_STATES.HEADERS ||
              !parentElement
            )
              return null;
            const { height, left, top } = calcPositions(row[0]);
            return (
              <svg
                onClick={() =>
                  addHeaderRowIndex({
                    headerIndex: currentHeaderIndex,
                    rowIndex
                  })
                }
                className="PDFDocumentView__AddHeaderButton"
                width={height}
                height={height}
                x={left + "%"}
                y={top + "%"}
                overflow="visible"
                viewBox={`0 0 14.2 14.2`}
                preserveAspectRatio="xMinYMid"
              >
                <g style={{ transform: "translateX(-210%)" }}>
                  <path
                    className="PDFDocumentView__HeaderActionsButton__tick"
                    d="M8,4.41V7.23H10.7V8H8v2.84H7.22V8H4.51V7.23H7.22V4.41Z"
                  />
                  <rect
                    className="PDFDocumentView__HeaderActionsButton__rect"
                    x="0.5"
                    y="0.5"
                    width="14.2"
                    height="14.2"
                  />
                </g>
              </svg>
            );
          };
          const SetHeaderButton = () => {
            if (
              !isTableActive(tableIndex) ||
              isBrokenTable(headerRowIndex) ||
              formState !== FORM_STATES.HEADERS ||
              !parentElement
            )
              return null;
            const { left, top, height } = calcPositions(row[0]);
            return (
              <svg
                onClick={() =>
                  setHeaderRowIndex({
                    headerIndex: currentHeaderIndex,
                    rowIndex
                  })
                }
                className={cx("PDFDocumentView__HeaderActionsButton", {
                  "PDFDocumentView__HeaderActionsButton--selected": rowIds.includes(
                    rowIndex
                  )
                })}
                overflow="visible"
                width={height}
                height={height}
                x={left + "%"}
                y={top + "%"}
                viewBox={`0 0 14.2 14.2`}
                preserveAspectRatio="xMinYMid"
              >
                <g style={{ transform: "translateX(-105%)" }}>
                  <rect
                    x="0.5"
                    y="0.5"
                    width="14.2"
                    height="14.2"
                    className="PDFDocumentView__HeaderActionsButton__rect"
                  />
                  <path
                    d="M10.38,7.94,4.82,10.79V10L9.54,7.61v0L4.82,5.24V4.41l5.56,2.86Z"
                    className="PDFDocumentView__HeaderActionsButton__tick"
                  />
                </g>
              </svg>
            );
          };
          const cols = row.map((col, columnIndex) => {
            let rentRollKey = "";
            const isCellHeader = rowIds.includes(rowIndex);
            const location = {
              columnIndex,
              pageIndex: currentPageIndex,
              rowIndex,
              tableIndex,
              headerIndex: currentHeaderIndex
            };
            let isMapped = false;
            let documentKey = generateTableKey(location);
            let hasWarning = false;
            let hasError = false;
            let isCorrected = false;
            if (currentHeaderIndex >= 0 && documentMetadata) {
              // we want isMapped to be based on the rent roll value on the review data tab
              // and canCellBeMarkedAsMapped on the map header tab
              isMapped =
                formState === FORM_STATES.HEADERS
                  ? canCellBeMarkedAsMapped(
                      doesCellHaveRentRoll({
                        rentRolls,
                        location
                      }),
                      currentTableMetadata,
                      columnIndex,
                      rowIndex
                    )
                  : doesCellHaveRentRoll({
                      rentRolls,
                      location
                    });
              const flags = computeCellFlags({
                ...col,
                isMapped,
                exceptions,
                location
              });
              hasWarning = flags.hasWarning;
              hasError = flags.hasError;
              isCorrected = flags.isCorrected;
            }

            const isSelected = selected === documentKey;
            if (headers.length && rentRollHeaders.length) {
              const docHeader = headers[columnIndex];
              const rentRollColumnIndex =
                docHeader &&
                rentRollHeaders.findIndex(h => h.key === docHeader.id);
              const rentRollRowIndex = rentRolls.findIndex(
                ({ doc_row_ids }) => doc_row_ids && doc_row_ids[0] === rowIndex
              );
              rentRollKey = `${rentRollRowIndex}-${rentRollColumnIndex}`;
            }

            return (
              <g key={documentKey}>
                <svg
                  key={documentKey}
                  style={{
                    opacity: "1",
                    cursor: "default"
                  }}
                  onClick={() => {
                    formState !== FORM_STATES.HEADERS &&
                      isMapped &&
                      isTableActive &&
                      setSelectedCells(documentKey, rentRollKey);
                  }}
                  id={documentKey}
                  width={col.geometry.boundingBox.Width * 100 + "%"}
                  height={col.geometry.boundingBox.Height * 100 + "%"}
                  x={col.geometry.boundingBox.Left * 100 + "%"}
                  y={col.geometry.boundingBox.Top * 100 + "%"}
                >
                  <rect
                    x="0"
                    y="0"
                    width="100%"
                    height="100%"
                    className={cx(
                      (isMapped || isCellHeader) && isTableActive(tableIndex)
                        ? "PdfDocumentView__cellMapped"
                        : "PdfDocumentView__cellNotMapped",
                      "PdfDocumentView__cell",
                      {
                        PdfDocumentView__cellCorrected: isCorrected
                      },
                      {
                        PdfDocumentView__cellWarning: hasWarning
                      },
                      {
                        PdfDocumentView__cellError: hasError
                      },
                      {
                        "PdfDocumentView__cell--selected": isSelected
                      }
                    )}
                  />
                  <foreignObject
                    x="0"
                    y="0"
                    width="100%"
                    height="100%"
                    key={documentKey}
                    style={{
                      opacity: "0.0"
                    }}
                    onClick={() => {
                      formState !== FORM_STATES.HEADERS &&
                        isMapped &&
                        isTableActive(tableIndex) &&
                        setSelectedCells(documentKey, rentRollKey);
                    }}
                  >
                    <div
                      className="PdfDocumentView__cellVarOpacity"
                      aria-describedby={documentKey}
                      id={documentKey}
                      style={{
                        cursor: "default",
                        width: "100%",
                        height: `${col.geometry.boundingBox.Height * 100}%`
                      }}
                    ></div>
                  </foreignObject>
                </svg>
              </g>
            );
          });

          return [
            isPrevAdjacentRow && (
              <AddHeaderButton key={`AddHeaderButton-${rowIndex - 1}`} />
            ),
            <SetHeaderButton key={`SetHeaderButton-${rowIndex}`} />,
            isNextAdjacentRow && (
              <AddHeaderButton key={`AddHeaderButton-${rowIndex + 1}`} />
            ),
            ...cols
          ];
        });

        let svgTableKey = `${currentPageIndex}-${tableIndex}`;

        return (
          <svg
            key={svgTableKey}
            id={svgTableKey}
            className={cx(
              "tooltipBoundary",
              `PdfDocumentView--table-${
                isTableActive(tableIndex) ? "active" : "inactive"
              }`,
              {
                "PdfDocumentView--table-selected": isTableSelected(tableIndex)
              }
            )}
            style={{ overflow: "visible" }}
          >
            {isTableActive(tableIndex) && (
              <HeaderOuter rowIds={rowIds} table={table} />
            )}
            {rows}
            {!isTableSelected(tableIndex) ? (
              <TableOverlay
                table={table}
                tableIndex={tableIndex}
                currentPageIndex={currentPageIndex}
                documentTables={documentTables}
                onSelectTable={onSelectTable}
              />
            ) : null}
          </svg>
        );
      })}
      {page.tableData.map((table, tableIndex) =>
        table[0].map(
          (_, columnIndex) =>
            headers[columnIndex] && (
              <SelectedColumn
                key={`selectedColumn-${tableIndex}-${columnIndex}`}
                page={page}
                tableIndex={tableIndex}
                columnIndex={columnIndex}
                isActive={
                  currentHeaderIndex >= 0 &&
                  currentTableMetadata.selectedColumns
                    ? currentTableMetadata.selectedColumns.has(columnIndex) &&
                      isTableActive(tableIndex)
                    : false
                }
              />
            )
        )
      )}
    </svg>
  );
}

PdfMarkupView.propTypes = {
  visible: PropTypes.bool.isRequired,
  onSelectTable: PropTypes.func.isRequired,
  setLeftMargin: PropTypes.func.isRequired,
  page: PropTypes.object.isRequired,
  headers: PropTypes.array.isRequired,
  selected: PropTypes.string,
  currentPageIndex: PropTypes.number.isRequired,
  currentHeaderIndex: PropTypes.number,
  onSelectedCell: PropTypes.func.isRequired,
  setSelectedRentRollCell: PropTypes.func.isRequired,
  activeDocumentTables: PropTypes.array,
  rentRoll: PropTypes.array.isRequired,
  documentMetadata: PropTypes.arrayOf(
    PropTypes.shape({
      rowIds: PropTypes.arrayOf(PropTypes.number),
      headers: PropTypes.array,
      selectedColumns: PropTypes.object,
      rowIndex: PropTypes.number
    })
  ),
  setDocumentMetadata: PropTypes.func.isRequired,
  documentTables: PropTypes.arrayOf(
    PropTypes.shape({
      headerIndex: PropTypes.number,
      pageIndex: PropTypes.number,
      tableIndex: PropTypes.number
    })
  ),
  formState: PropTypes.string.isRequired,
  parentElement: PropTypes.object
};

function HeaderOuter({ rowIds, table }) {
  if (!rowIds.length) return null;
  const points = [...rowIds.map(r => rowOuter(table[r]))];
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 100 100"
      preserveAspectRatio="none"
      className="PdfDocumentView__cellHeader"
    >
      <polygon
        points={
          points[0].x1 +
          "," +
          points[0].y1 +
          " " +
          points[0].x2 +
          "," +
          points[0].y2 +
          " " +
          points[points.length - 1].x3 +
          "," +
          points[points.length - 1].y3 +
          " " +
          points[points.length - 1].x4 +
          "," +
          points[points.length - 1].y4 +
          " "
        }
      />
    </svg>
  );
}

HeaderOuter.propTypes = {
  rowIds: PropTypes.array.isRequired,
  table: PropTypes.array.isRequired
};

function SelectedColumn({ columnIndex, page, tableIndex, isActive }) {
  const table = page.tableData[tableIndex];
  const points = colOuter(columnIndex, table);
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 100 100"
      preserveAspectRatio="none"
      className={
        isActive
          ? "PdfDocumentView__selectedColumn--active"
          : "PdfDocumentView__selectedColumn"
      }
    >
      <defs>
        <clipPath id={`clip-${columnIndex}-${tableIndex}`}>
          <polygon
            points={
              points.x1 +
              "," +
              points.y1 +
              " " +
              points.x2 +
              "," +
              points.y2 +
              " " +
              points.x3 +
              "," +
              points.y3 +
              " " +
              points.x4 +
              "," +
              points.y4 +
              " "
            }
          />
        </clipPath>
      </defs>
      <polygon
        clipPath={`url(#clip-${columnIndex}-${tableIndex})`}
        points={
          points.x1 +
          "," +
          points.y1 +
          " " +
          points.x2 +
          "," +
          points.y2 +
          " " +
          points.x3 +
          "," +
          points.y3 +
          " " +
          points.x4 +
          "," +
          points.y4 +
          " "
        }
      />
    </svg>
  );
}

SelectedColumn.propTypes = {
  columnIndex: PropTypes.number.isRequired,
  page: PropTypes.object.isRequired,
  tableIndex: PropTypes.number.isRequired,
  isActive: PropTypes.bool.isRequired
};

function TableOverlay({
  table,
  tableIndex,
  currentPageIndex,
  documentTables,
  onSelectTable
}) {
  const points = tableOuter(table);
  return (
    <svg
      title="Select table"
      key={`tableOverlay-${tableIndex}`}
      width="100%"
      height="100%"
      viewBox="0 0 100 100"
      preserveAspectRatio="none"
    >
      <polygon
        className="PdfDocumentView__table-overlay"
        points={`${points.x1},${points.y1} ${points.x2},${points.y2} ${points.x3},${points.y3}, ${points.x4},${points.y4}`}
        onClick={() =>
          onSelectTable({
            pageIndex: currentPageIndex,
            headerIndex: documentTables.find(
              t =>
                t.tableIndex === tableIndex && t.pageIndex === currentPageIndex
            ).headerIndex
          })
        }
      />
    </svg>
  );
}

TableOverlay.propTypes = {
  table: PropTypes.object.isRequired,
  tableIndex: PropTypes.number.isRequired,
  currentPageIndex: PropTypes.number.isRequired,
  documentTables: PropTypes.array.isRequired,
  onSelectTable: PropTypes.func.isRequired
};
