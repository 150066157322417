import { merge } from "ramda";

import {
  FETCHING_PORTFOLIOS,
  FETCHED_PORTFOLIOS_SUCCESSFUL,
  CREATING_PORTFOLIO,
  CREATING_PORTFOLIO_SUCCESSFUL,
  UPDATING_PORTFOLIOS,
  UPDATED_PORTFOLIOS_SUCCESSFUL,
  CLEAR_PORTFOLIOS
} from "ui/store/actions/portfolios.js";

export const FETCHING = "FETCHING";
export const LOADED = "LOADED";
export const CREATING = "CREATING";
export const UPDATING = "UPDATING";

export default function portfolios(
  state = {
    portfolios: [],
    status: FETCHING,
    resultStatus: LOADED,
    cache: {},
    exceptionSidebarIsActive: false
  },
  action
) {
  switch (action.type) {
    case FETCHING_PORTFOLIOS: {
      return merge(state, { status: FETCHING });
    }
    case FETCHED_PORTFOLIOS_SUCCESSFUL: {
      const { portfolios } = action;
      return merge(state, { portfolios, status: LOADED });
    }
    case CREATING_PORTFOLIO: {
      return merge(state, { status: CREATING });
    }
    case CREATING_PORTFOLIO_SUCCESSFUL: {
      const { portfolios } = action;
      return merge(state, { portfolios, status: LOADED });
    }
    case UPDATING_PORTFOLIOS: {
      return merge(state, { status: UPDATING });
    }
    case UPDATED_PORTFOLIOS_SUCCESSFUL: {
      const { portfolios } = action;
      return merge(state, { portfolios, status: LOADED });
    }
    case CLEAR_PORTFOLIOS: {
      return { ...state, portfolios: [] };
    }
    default: {
      return state;
    }
  }
}
