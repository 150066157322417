import { Component, createRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import cx from "classnames";

import { returnTypeCount } from "./filterUtils";

import FilterButton from "./FilterButton";
import { filterList } from "./filterUtils";
import FilterExpanded from "./FilterExpanded";
import SearchAutocomplete from "ui/components/shared/autocomplete/SearchAutocomplete";

export class FilterBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterOptions: [],
      expandedType: "",
      isExpanded: false,
      isLoading: true,
      searchPanelOn: false,
      searchString: ""
    };

    this.panelRef = createRef();
  }

  componentDidMount() {
    window.addEventListener("click", this.closeOutsideDiv);
    this.setState({
      filterOptions: Object.keys(filterList),
      isLoading: false
    });
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.closeOutsideDiv);
  }

  onSelectedFilterChange = filter => {
    const { expandedType, isExpanded } = this.state;
    // note: filter is a key from filterList
    if (filter && !isExpanded) {
      this.setState({ isExpanded: true });
    }
    if (filter !== expandedType) {
      this.setState({ expandedType: filter });
    }
  };

  onCloseSearchInputBar = () => {
    this.setState({ isExpanded: false, searchPanelOn: false });
  };

  closeOutsideDiv = e => {
    const { closePopover } = this.props;
    if (e.target !== this.panelRef) {
      closePopover();
    }
  };

  render() {
    const {
      filterOptions,
      expandedType,
      isExpanded,
      searchPanelOn
    } = this.state;
    const {
      onDone,
      removeFilter,
      applyFilter,
      portfolioId,
      updateCanSearch,
      metrics
    } = this.props;

    return (
      <div className="FilterBody__panel">
        <div className="FilterBody__body">
          <div className="FilterBody__filterSearchPanel">
            <div className="FilterBody__filterSearchBar">
              <div className="FilterBody__prompt">Search:</div>
              <SearchAutocomplete
                handleKeyDown={this.handleKeyDown}
                placeholder=""
                name="query"
                portfolioId={portfolioId}
                applyFilter={applyFilter}
                removeFilter={removeFilter}
              />
            </div>
            <div className="FilterBody__buttons">
              <div className="FilterBody__prompt">Add Filter:</div>
              {filterOptions.map(keyFilter => {
                if (keyFilter === "searchInput") return null;
                return (
                  <FilterButton
                    key={keyFilter}
                    filterHeader={filterList[keyFilter].header}
                    numSubFilters={returnTypeCount(metrics, keyFilter)}
                    onClick={() => this.onSelectedFilterChange(keyFilter)}
                  />
                );
              })}
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row-reverse",
                paddingBottom: "5px",
                paddingRight: "5px"
              }}
            >
              {!isExpanded && (
                <button
                  className={cx("Button", "Button__blackButton")}
                  style={{
                    cursor: "pointer",
                    marginRight: "5px"
                  }}
                  onClick={onDone}
                >
                  Done
                </button>
              )}
            </div>
          </div>
          {isExpanded && (
            <FilterExpanded
              updateCanSearch={updateCanSearch}
              expandedHeader={filterList[expandedType].header}
              expandedType={expandedType}
              isExpanded={isExpanded}
              searchPanelOn={searchPanelOn}
              onDone={onDone}
            />
          )}
        </div>
      </div>
    );
  }
}

FilterBody.propTypes = {
  onDone: PropTypes.func,
  allFilters: PropTypes.object,
  metrics: PropTypes.object,
  searchedFilters: PropTypes.object,
  removeFilter: PropTypes.func,
  applyFilter: PropTypes.func,
  searchProperties: PropTypes.func,
  updateCanSearch: PropTypes.func,
  closePopover: PropTypes.func,
  portfolioId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

function mapStateToProps(state) {
  return {
    allFilters: state.filters.filters,
    metrics: state.filters.metrics,
    searchedFilters: state.filters.searchedFilters
  };
}

export default connect(mapStateToProps)(FilterBody);
