import PropTypes from "prop-types";
import cx from "classnames";

export default function HeaderActionsCell({
  addHeaderRowIndex,
  isHeader,
  setHeaderRowIndex,
  showExpandHeaderButton,
  location
}) {
  function handleSetClick() {
    setHeaderRowIndex(location);
  }

  function handleAddClick() {
    addHeaderRowIndex(location);
  }

  return (
    <div
      className={cx("ExcelDocument__HeaderActionsCell", {
        ["ExcelDocument__HeaderActionsCell--selected"]: isHeader
      })}
    >
      {showExpandHeaderButton ? (
        <button
          className="ExcelDocument__HeaderActionsCell-expand-btn"
          onClick={handleAddClick}
        >
          &#43;
        </button>
      ) : null}
      <button
        className="ExcelDocument__HeaderActionsCell-set-btn"
        onClick={handleSetClick}
      >
        &gt;
      </button>
    </div>
  );
}

HeaderActionsCell.propTypes = {
  isHeader: PropTypes.bool.isRequired,
  showExpandHeaderButton: PropTypes.bool.isRequired,
  setHeaderRowIndex: PropTypes.func.isRequired,
  addHeaderRowIndex: PropTypes.func.isRequired,
  location: PropTypes.shape({
    headerIndex: PropTypes.number.isRequired,
    rowIndex: PropTypes.number.isRequired
  })
};
