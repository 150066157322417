import PropTypes from "prop-types";
import cx from "classnames";
import fetchData from "../../../../../helpers/fetchData";
import { apiFetch } from "ui/store/actions/apiClient";
import useSplitPanelV2Context from "ui/components/rentRoll/splitPanelV2/hooks/useSplitPanelV2Context";
import queryString from "query-string";
import { hasAdminAccess } from "helpers/authorization";

const PredefTenantOption = ({
  value,
  idx,
  selected,
  updateSelected,
  updateInputVal,
  setShowSpinner,
  setPredefVals
}) => {
  const { documentId, widgetAuth, userRole } = useSplitPanelV2Context();

  if(value.metadata){
    let tempMeta = value.metadata
    value = {...value,...tempMeta}
  }

  const removePredefValue = async (predefId, predefValue) => {
    if (confirm(`Are you sure you want to delete '${predefValue}'?`)) {
      setShowSpinner(true);
      // manually send delete, deletePredefValue not working
      let response = await apiFetch(`/api/predef-values/delete/${predefId}`, {
        method: "delete"
      });
      if (!response.ok) {
        const responseBody = await response.json();
        throw new Error(responseBody.error);
      }

      // refresh preDef Value List after Delete
      let body = {
        value: predefValue,
        field: "tenant_name"
      };
      const newData = await fetchData(widgetAuth)(
        `/api/predef-fields/suggest/${documentId}?${queryString.stringify(
          body
        )}`
      );
      setPredefVals(newData.predefValues);
      setShowSpinner(false);
    }
  };

  return (
    <div className={cx("DataCell__Popover__TenantOptions")}>
      <input
        type="radio"
        name={"tenant_name"}
        id={value.value + "-" + (value.ownershipName || value.value)}
        checked={selected === idx}
        onChange={() => (selected = idx)}
        onClick={() => {
          updateSelected(idx);
          updateInputVal(value.value);
        }}
      />
      <label
        htmlFor={value.value + "-" + value.ownershipName}
        className="DataCell__Popover__label"
      >
        <div className="DataCell__Popover__Name">
          {value.value}
          {value.ref !== null && value.id !== undefined ? (
            <span className="DataCell__Popover__refBadge badge_exsits">
              Existing
            </span>
          ) : (
            ""
          )}
        </div>
        {value.ownershipName ? (
          <div className="DataCell__Popover__Owner">{value.ownershipName}</div>
        ) : (
          ""
        )}
        {value.address ? (
          <div className="DataCell__Popover__Address">{value.address}</div>
        ) : (
          ""
        )}
        {value.industry ? (
          <div className="DataCell__Popover__Industry">
            industry: {value.industry}
          </div>
        ) : (
          ""
        )}
      </label>
      { hasAdminAccess(userRole) &&
        (value.ref !== null && value.id !== undefined || value.ref == null ) &&
        selected !== idx ? (
        <span
          onClick={() => {
            removePredefValue(value.id, value.value);
          }}
        >
          <img
            className="DataCell__Popover__deleteIcon"
            src={require("ui/images/icon-trash.png")}
          />
        </span>
      ) : (
        ""
      )}
    </div>
  );
};

PredefTenantOption.propTypes = {
  value: PropTypes.object,
  idx: PropTypes.number,
  selected: PropTypes.number,
  updateSelected: PropTypes.func,
  updateInputVal: PropTypes.func,
  setShowSpinner: PropTypes.func,
  setPredefVals: PropTypes.func
};

export default PredefTenantOption;
