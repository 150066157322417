import useSWR from "swr";
import fetchData from "../helpers/fetchData";
import updateData from "../helpers/updateData";
import useSplitPanelV2Context from "./useSplitPanelV2Context";
import { MANUAL_ADD } from "services/helpers/rentRoll";

export default function useRentRoll() {
  const { documentId, widgetAuth } = useSplitPanelV2Context();

  const { data, error, mutate } = useSWR(
    `/api/rent-roll/get-rent-roll-by-doc-id/${documentId}`,
    fetchData(widgetAuth)
  );

  async function markDeleted(rowId, status) {
    await updateData({
      path: `/api/rent-roll/remove-row/${documentId}`,
      method: "put",
      data: { rowId: parseInt(rowId), status },
      widgetAuth
    });
  }

  async function undoMarkDeleted(rowId) {
    await updateData({
      path: `/api/rent-roll/undo-remove-row/${documentId}`,
      method: "put",
      data: { rowId },
      widgetAuth
    });
  }

  async function removeRowExceptions(rowId) {
    await updateData({
      path: `/api/rent-roll/remove-rent-roll-exceptions/${documentId}/`,
      method: "put",
      data: { rowId: parseInt(rowId, 10) },
      widgetAuth
    });
  }

  async function reinstateRowExceptions(rowId) {
    await updateData({
      path: `/api/rent-roll/reinstate-rent-roll-exceptions/${documentId}`,
      method: "put",
      data: { rowId },
      widgetAuth
    });
  }

  async function removeRentRollRowAndExceptions(rowId, status) {
    await markDeleted(rowId, status);
    await removeRowExceptions(rowId);
  }

  async function undoRemoveRentRollRowAndExceptions(rowId) {
    await undoMarkDeleted(rowId);
    await reinstateRowExceptions(rowId);
  }

  async function markAsComplete(documentId, document_property_id) {
    await updateData({
      path: `/api/rent-roll/mark-as-complete/${documentId}`,
      method: "put",
      widgetAuth
    });
    if (widgetAuth && widgetAuth.authorized) {
      const message = JSON.stringify({
        event_type: "MARKED_AS_COMPLETE",
        document_id: documentId,
        property_id: document_property_id
      });
      window.parent.postMessage(message, "*");
    }
  }

  async function addNewRowToRentRoll(documentId, rowData) {
    return await updateData({
      path: `/api/rent-roll/add-row/${documentId}`,
      method: "put",
      data: {data: rowData},
      widgetAuth
    });
  }


  return {
    rentRoll: data ? data.rentRoll : null,
    error,
    mutate,
    isLoading: !error && !data,
    removeRentRollRowAndExceptions,
    undoRemoveRentRollRowAndExceptions,
    markAsComplete,
    addNewRowToRentRoll
  };
}

export function getDocumentData(rentRoll) {
  if (rentRoll.length === 0) return {};
  const rentRollNoManual = rentRoll.filter(row => row.status !== MANUAL_ADD);
  return rentRollNoManual.reduce((rowAcc, row) => {
    const rowData = row.doc_data_json.row.reduce((colAcc, col) => {
      if (col.isMapped && col.docPosition) {
        colAcc[
          col.docPosition.page +
            "-" +
            col.docPosition.table +
            "-" +
            col.docPosition.row +
            "-" +
            col.docPosition.col
        ] = { id: col.id, value: col.value };
      }
      return colAcc;
    }, {});
    return { ...rowAcc, ...rowData };
  }, {});
}
