import PropTypes from "prop-types";
import AccountSelectionWrapper from "../AccountSelectionWrapper";

const CategoriesCellRenderer = ({
  rowData,
  copyCategoryActive,
  onChangeAccount
}) => {
  const {
    isExcluded,
    bgColor,
    baseTableRowId,
    documentRowIndex,
    pageIndex,
    tableIndex,
    chartsOfAccountsIndex,
    mappedAccount,
    templateRowsOptions
  } = rowData;
  const {
    copyCategoryToElements,
    rowToCopyId,
    setIsDragging,
    draggingBelowRowToCopy
  } = rowData.copyPasteState;
  return !isExcluded &&
    copyCategoryToElements.every(
      data => data.baseTableRowId !== baseTableRowId
    ) &&
    rowToCopyId !== baseTableRowId ? (
    <AccountSelectionWrapper
      documentRowIndex={documentRowIndex}
      columnIndex={chartsOfAccountsIndex}
      pageIndex={pageIndex}
      tableIndex={tableIndex}
      mappedAccount={mappedAccount}
      optionGroups={templateRowsOptions || []}
      bgColor={bgColor}
      isExcluded={isExcluded}
      isDisabled={!!rowToCopyId}
      onChange={onChangeAccount}
    />
  ) : (!isExcluded &&
      copyCategoryToElements.some(
        data => data.baseTableRowId === baseTableRowId
      )) ||
    rowToCopyId === baseTableRowId ? (
    <>
      <span>{mappedAccount}</span>
      <span
        style={{
          display:
            copyCategoryActive(rowData) &&
            ((!copyCategoryToElements.length &&
              rowToCopyId === baseTableRowId) ||
              copyCategoryToElements.at(-1).baseTableRowId === baseTableRowId)
              ? "block"
              : "none",
          top:
            !draggingBelowRowToCopy && rowToCopyId !== baseTableRowId
              ? 0
              : "auto",
          bottom:
            draggingBelowRowToCopy || rowToCopyId === baseTableRowId
              ? 0
              : "auto"
        }}
        onMouseDown={() => setIsDragging(true)}
        onMouseUp={() => setIsDragging(false)}
        className="CopyPasteAnchor"
      />
    </>
  ) : null;
};

export default CategoriesCellRenderer;

CategoriesCellRenderer.propTypes = {
  rowData: PropTypes.object,
  copyCategoryActive: PropTypes.func,
  categorySelectHandler: PropTypes.func
};
