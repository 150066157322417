import { useEffect, useState } from "react";
import useDocument from "../../../../../hooks/useDocument";
import usePredefSuggestions from "../../../../../hooks/usePredefSuggestions";
import useSplitPanelV2Context from "ui/components/rentRoll/splitPanelV2/hooks/useSplitPanelV2Context";
import useRentRoll from "ui/components/rentRoll/splitPanelV2/hooks/useRentRoll";
import useExceptions from "ui/components/rentRoll/splitPanelV2/hooks/useExceptions";
import { predefConfig } from "../../../../../../splitPanel/popovers/cell/helpers/predef";
import Spinner from "ui/components/shared/Spinner";
import PropTypes from "prop-types";
import fetchData from "../../../../../helpers/fetchData";
import queryString from "query-string";
import PredefOption from "./PredefOption";
import TenantSelection from "./TenantSelection";

const CellUpdateValue = ({
  predefModalValue,
  pageIndex,
  tableIndex,
  rowIndex,
  columnIndex,
  dataKey,
  closePopover,
  originalCellText
}) => {
  const { updateDocumentCell, mutate: mutateDocument } = useDocument();
  const { documentId, widgetAuth } = useSplitPanelV2Context();
  const [step, updateStep] = useState(predefConfig.search_for_value);
  const [selected, updateSelected] = useState();
  const [inputVal, updateInputVal] = useState(originalCellText || "");
  const [searchVal, setSearchVal] = useState(originalCellText || "");
  const [showSpinner, setShowSpinner] = useState(true);
  const [showSaving, setShowSaving] = useState(false);
  const {
    predefSuggestions,
    mutate: mutatePreDefSuggestions
  } = usePredefSuggestions(predefModalValue, dataKey);
  const [predefValues, setPredefVals] = useState(predefSuggestions);

  const { mutate: mutateRentRoll } = useRentRoll();
  const { mutate: mutateExceptions } = useExceptions();

  useEffect(() => {
    if (!predefValues) {
      setPredefVals(predefSuggestions);
    } else if (predefValues.length <= 0) {
      setShowSpinner(false);
    } else {
      setShowSpinner(false);
    }
  }, [predefValues, predefSuggestions]);

  const updateValue = async () => {
    if (predefValues === null || predefValues[selected] === undefined) {
      closePopover();
      return;
    }
    setShowSaving(true);
    setShowSpinner(true);
    updateInputVal(predefValues[selected].value);

    // create metadata object to save
    let metadata = null;
    if (dataKey === "tenant_name") {
      metadata = predefValues[selected].metadata || {
        ...predefValues[selected]
      };
      delete metadata.confidence;
      delete metadata.ref;
      delete metadata.value;
    }

    const field = {
      page: pageIndex,
      table: tableIndex,
      row: rowIndex,
      col: columnIndex,
      text: predefValues[selected].value,
      predefValueId: predefValues[selected].id,
      ref: predefValues[selected].ref,
      metadata: metadata
    };

    await updateDocumentCell(field);
    await mutateExceptions();
    await mutateDocument();
    await mutateRentRoll();
    setShowSpinner(false);
    setShowSaving(false);
    closePopover();
  };

  const handleOnChange = e => {
    setSearchVal(e.target.value);
  };

  const handleInputUpdate = async e => {
    if (e.key === "Enter") {
      setShowSpinner(true);
      setSearchVal(e.target.value);
      const newData = await fetchData(widgetAuth)(
        `/api/predef-fields/suggest/${documentId}?${queryString.stringify({
          value: searchVal,
          field: dataKey
        })}`
      );
      setPredefVals(newData.predefValues);
      updateStep(predefConfig.select_a_value);
    }
  };

  return dataKey === "tenant_name" ? (
    <div>
      <TenantSelection
        closePopover={closePopover}
        handleInputUpdate={handleInputUpdate}
        inputVal={inputVal}
        searchVal={searchVal}
        setSearchVal={setSearchVal}
        predefValues={predefValues}
        setPredefVals={setPredefVals}
        predefModalValue={predefModalValue}
        showSpinner={showSpinner}
        setShowSpinner={setShowSpinner}
        showSaving={showSaving}
        setShowSaving={setShowSaving}
        updateValue={updateValue}
        updateInputVal={updateInputVal}
        selected={selected}
        updateSelected={updateSelected}
        pageIndex={pageIndex}
        tableIndex={tableIndex}
        rowIndex={rowIndex}
        columnIndex={columnIndex}
        originalTenantName={originalCellText}
        mutatePreDefSuggestions={mutatePreDefSuggestions}
      />
      <div
        className="DataCell__Popover__popupOverlay"
        onClick={() => {
          closePopover();
        }}
      ></div>
    </div>
  ) : (
    <div>
      <div
        style={{
          position: "fixed",
          zIndex: 10000,
          backgroundColor: "#F0F0F0",
          marginLeft: "19%",
          padding: "35px",
          paddingBottom: "10px",
          border: "solid black 2px",
          borderRadius: "5px",
          minWidth: "209px",
          top: "35%"
        }}
      >
        <div className="DataCell__Popover__popupHeader">Suggested Change</div>
        <div
          className="DataCell__Popover__changeDescription"
          style={{ width: "200px" }}
        >
          {step}
        </div>

        <input
          className="DataCell__Popover__input"
          type="text"
          value={searchVal}
          onKeyDown={handleInputUpdate}
          onChange={handleOnChange}
        />

        {showSpinner ? (
          <>
            <div style={{ textAlign: "center" }}>
              {showSaving ? "Updating Cell..." : ""}
            </div>
            <Spinner
              type="ThreeDots"
              classname="DataCell__Popover__loadingContent"
              color="#00BFFF"
              height={40}
              width={40}
            />
          </>
        ) : predefValues.length > 0 ? (
          <div style={{ marginBottom: "15px", maxHeight: "350px" }}>
            {predefValues.map((value, i) => (
              <PredefOption
                key={i + value.value}
                idx={i}
                value={value}
                selected={selected}
                predefModalValue={predefModalValue}
                updateSelected={updateSelected}
                updateInputVal={updateInputVal}
              />
            ))}
          </div>
        ) : (
          <div className="DataCell__Popover__loadingContent">No results</div>
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            justifyContent: "space-between",
            marginTop: "10px"
          }}
        >
          <button
            className="DataCell__Popover__updateButton"
            style={showSaving ? { color: "gray" } : {}}
            onClick={() => {
              updateValue();
            }}
            disabled={showSaving}
          >
            Update
          </button>
          <button
            className="DataCell__Popover__updateButton"
            style={{ marginLeft: "5px", color: "gray" }}
            onClick={() => {
              closePopover();
            }}
          >
            Cancel
          </button>
        </div>
      </div>
      <div
        className="DataCell__Popover__popupOverlay"
        onClick={() => {
          closePopover();
        }}
      ></div>
    </div>
  );
};

CellUpdateValue.propTypes = {
  cellValue: PropTypes.string,
  // updateValue: PropTypes.string,
  updateIsOpen: PropTypes.func,
  updateDocumentField: PropTypes.func,
  closePopover: PropTypes.func,
  predefModalValue: PropTypes.string,
  pageIndex: PropTypes.number,
  tableIndex: PropTypes.number,
  rowIndex: PropTypes.number,
  columnIndex: PropTypes.number,
  dataKey: PropTypes.string,
  column: PropTypes.object
};

export default CellUpdateValue;
