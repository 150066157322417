import * as _ from "lodash";
import PropTypes from "prop-types";
import BaseTable, { AutoResizer, Column } from "react-base-table";
import { convertTemplateToSelectOptions } from "ui/components/opex/helpers/opex";
import {
  CELL_STATUS_CORRECTED_COLOR,
  CONFIDENCE_CONFIRMED_COLOR,
  getConfidenceCSSClassByConfidence,
  isCellConfirmed
} from "ui/components/opex/shared";
import { useEffect, useRef, useState } from "react";
import AccountsCellRenderer from "./BaseTableRenderProps/AccountsCellRenderer";
import ExcludeButtonCellRenderer from "./BaseTableRenderProps/ExcludeButtonCellRenderer";
import CategoriesCellRenderer from "./BaseTableRenderProps/CategoriesCellRenderer";
import {
  onMouseDown,
  onMouseUp,
  onMouseEnter,
  onMouseLeave,
  onMouseMove
} from "./BaseTableRenderProps/CellPropsUtils";

const AccountsBaseTable = ({
  template,
  tables,
  rawDataJson,
  onUpdateRawDataRows,
  metaDataJson,
  toggleExcludedRow,
  disableFilter,
  onClickRowName,
  currentTable
}) => {
  const baseTableRef = useRef();
  // Hover Copy & Paste States
  const [rowToCopyId, setRowToCopyId] = useState(null);
  const [copyModeTimer, setCopyModeTimer] = useState(null);
  const [copyCategoryToElements, setCopyCategoryToElements] = useState([]);
  const [draggingBelowRowToCopy, setDraggingBelowRowToCopy] = useState(false);
  const [isDragging, setIsDragging] = useState(null);
  const [draggingStartingPosition, setDraggingStartingPosition] = useState(
    null
  );
  // Hover Shadow State
  const [rowHoverIndex, setRowHoverIndex] = useState(null);
  const templateRowsOptions = convertTemplateToSelectOptions(
    template?.rows?.length ? template : []
  );
  const getRowData = (
    rowData,
    table,
    rowIndex,
    tableIndex,
    rawDataJson,
    metaDataJson,
    chartsOfAccountsIndex
  ) => {
    const { pageIndex, metaDataIndex } = table;
    const currentRow =
      rawDataJson.pages[pageIndex].tableData[tableIndex][rowIndex][
        chartsOfAccountsIndex
      ];
    const corrected = currentRow?.corrected;
    const rowPredef = currentRow?.predef;
    const mappedAccount = !rowData?.predef?.value ? "-" : rowData.predef.value;
    const confidence = rowPredef?.confidence || 0;
    const isExcluded = _.get(
      metaDataJson,
      `headers[${metaDataIndex}].excludedRows[${rowIndex}]`,
      false
    );

    let bgColor = "";
    let color;
    if (corrected) {
      color = CELL_STATUS_CORRECTED_COLOR;
    } else if (isCellConfirmed(corrected)) {
      color = CONFIDENCE_CONFIRMED_COLOR;
    } else {
      bgColor = getConfidenceCSSClassByConfidence(confidence);
    }
    return {
      rowHoverIndex: rowHoverIndex,
      baseTableRowId: `${currentRow?.text}-${pageIndex}-${tableIndex}-${rowIndex}-${chartsOfAccountsIndex}`,
      currentRow,
      rowPredef,
      confidence,
      isExcluded,
      bgColor,
      color,
      mappedAccount,
      rowIndex,
      metaDataIndex,
      pageIndex,
      tableIndex,
      templateRowsOptions,
      onUpdateRawDataRows,
      chartsOfAccountsIndex,
      toggleExcludedRow
    };
  };
  const categorySelectHandler = (
    selected,
    documentRowIndex,
    columnIndex,
    pageIndex,
    tableIndex,
    currentValue
  ) =>
    onUpdateRawDataRows([
      {
        ...selected,
        corrected: currentValue !== selected.name,
        location: {
          pi: pageIndex,
          ti: tableIndex,
          ri: documentRowIndex,
          ci: columnIndex
        }
      }
    ]);
  const baseTableData = tables.reduce((rows, table) => {
    const tableD = table.tableData
      .map((row, rowIndex) => ({
        ...row[table.chartsOfAccountsIndex],
        ...getRowData(
          row[table.chartsOfAccountsIndex],
          table,
          rowIndex,
          table.tableIndex,
          rawDataJson,
          metaDataJson,
          table.chartsOfAccountsIndex
        ),
        currentTable: currentTable,
        copyPasteState: {
          rowToCopyId,
          setRowToCopyId,
          copyModeTimer,
          setCopyModeTimer,
          copyCategoryToElements,
          setCopyCategoryToElements,
          draggingBelowRowToCopy,
          setDraggingBelowRowToCopy,
          isDragging,
          setIsDragging,
          draggingStartingPosition,
          setDraggingStartingPosition,
          categorySelectHandler
        }
      }))
      .filter(row => row.text?.length);
    return [...rows, ...tableD];
  }, []);

  useEffect(() => {
    if (rowToCopyId) {
      window.addEventListener("click", deactivateCopyPasteMode);
    }
    disableFilter(!!rowToCopyId);
    setCopyModeTimer(null);
    setCopyCategoryToElements([]);
    setDraggingBelowRowToCopy(false);
    setIsDragging(null);
    setDraggingStartingPosition(null);
  }, [rowToCopyId]);

  useEffect(() => {
    if (!isDragging && copyCategoryToElements.length) {
      setRowToCopyId(null);
      setCopyCategoryToElements([]);
      setDraggingStartingPosition(null);
      const { rowPredef } = baseTableData.find(
        data => data.baseTableRowId === rowToCopyId
      );
      onUpdateRawDataRows(
        copyCategoryToElements
          .filter(row => !row.isExcluded)
          .map(row => ({
            id: rowPredef.id,
            name: rowPredef.value,
            code: rowPredef.ref.code,
            corrected: true,
            location: {
              pi: row.pageIndex,
              ti: row.tableIndex,
              ri: row.documentRowIndex,
              ci: row.chartsOfAccountsIndex
            }
          }))
      );
    }
  }, [isDragging, copyCategoryToElements]);

  const autocompleteElements = (
    newRowData,
    rowToCopyId,
    allElements,
    bellow
  ) => {
    const currentDataIndex = allElements.findIndex(
      data => data.baseTableRowId === newRowData.baseTableRowId
    );
    const copyRowElementIndex =
      allElements.findIndex(data => data.baseTableRowId === rowToCopyId) +
      (bellow ? 1 : -1);
    const firstIndex =
      currentDataIndex < copyRowElementIndex
        ? currentDataIndex
        : copyRowElementIndex;
    const secondIndex =
      currentDataIndex === firstIndex ? copyRowElementIndex : currentDataIndex;
    const currentSelectedElements = allElements.filter(
      (elm, i) => i >= firstIndex && i <= secondIndex
    );
    return bellow ? currentSelectedElements : currentSelectedElements.reverse();
  };
  const deactivateCopyPasteMode = e => {
    if (
      baseTableRef.current &&
      !baseTableRef.current.tableNode?.contains(e.target)
    ) {
      window.removeEventListener("click", deactivateCopyPasteMode);
      setRowToCopyId(null);
      setCopyCategoryToElements([]);
      setDraggingStartingPosition(null);
    }
  };
  const columns = [
    {
      key: "accounts",
      title: "ACCOUNTS",
      dataKey: "text",
      width: 0,
      flexGrow: 1,
      className: ({ rowData }) => (!rowData.isExcluded ? rowData.bgColor : ""),
      cellRenderer: ({ rowData }) => (
        <AccountsCellRenderer
          rowData={rowData}
          onClickRowName={onClickRowName}
        />
      )
    },
    {
      key: "exclude",
      width: 0,
      flexGrow: 1,
      align: Column.Alignment.RIGHT,
      className: ({ rowData }) => (!rowData.isExcluded ? rowData.bgColor : ""),
      cellRenderer: ({ rowData, rowIndex }) => {
        const {
          baseTableRowId,
          rowHoverIndex,
          isExcluded,
          metaDataIndex,
          documentRowIndex,
          color,
          toggleExcludedRow
        } = rowData;
        const { rowToCopyId, copyCategoryToElements } = rowData.copyPasteState;
        return (
          <ExcludeButtonCellRenderer
            copyCategoryToElements={copyCategoryToElements}
            baseTableRowId={baseTableRowId}
            rowToCopyId={rowToCopyId}
            rowHoverIndex={rowHoverIndex}
            rowIndex={rowIndex}
            isExcluded={isExcluded}
            toggleExcludedRow={toggleExcludedRow}
            metaDataIndex={metaDataIndex}
            documentRowIndex={documentRowIndex}
            color={color}
          />
        );
      }
    },
    {
      key: "categories",
      title: "CATEGORIES",
      width: 0,
      flexGrow: 1,
      flexShrink: 0,
      align: Column.Alignment.LEFT,
      className: ({ rowData }) =>
        `TableCell ${!rowData.isExcluded ? rowData.bgColor : ""}`,
      cellRenderer: ({ rowData }) => (
        <CategoriesCellRenderer
          rowData={rowData}
          copyCategoryActive={copyCategoryActive}
          onChangeAccount={rowData.copyPasteState.categorySelectHandler}
        />
      )
    }
  ];
  const cellProps = ({ rowIndex, rowData }) => ({
    onMouseDown: e => onMouseDown(e, rowData),
    onMouseUp: e => onMouseUp(e, rowData),
    onMouseEnter: e =>
      onMouseEnter(
        e,
        rowData,
        setRowHoverIndex,
        rowIndex,
        autocompleteElements,
        baseTableData
      ),
    onMouseLeave: e => onMouseLeave(e, rowData, setRowHoverIndex),
    onMouseMove: e => onMouseMove(e, rowData)
  });
  const copyCategoryActive = rowData => {
    const { baseTableRowId } = rowData;
    const { rowToCopyId, copyCategoryToElements } = rowData.copyPasteState;
    return (
      rowToCopyId === baseTableRowId ||
      copyCategoryToElements.some(
        data => data.baseTableRowId === baseTableRowId
      )
    );
  };
  const noHighLightBorderTop = rowData => {
    const { baseTableRowId } = rowData;
    const {
      copyCategoryToElements,
      rowToCopyId,
      draggingBelowRowToCopy
    } = rowData.copyPasteState;
    const rowDataIndex = copyCategoryToElements.findIndex(
      data => data.baseTableRowId === baseTableRowId
    );
    let result = false;
    if (rowDataIndex >= 0 || rowToCopyId === baseTableRowId) {
      result =
        copyCategoryActive(rowData) &&
        copyCategoryToElements.length > 0 &&
        // Going up
        ((!draggingBelowRowToCopy && rowToCopyId === baseTableRowId) ||
          (!draggingBelowRowToCopy &&
            rowDataIndex < copyCategoryToElements.length - 1) ||
          // Going down
          (draggingBelowRowToCopy && rowDataIndex >= 0));
    }
    return result;
  };
  const noHighLightBorderBottom = rowData => {
    const { baseTableRowId } = rowData;
    const {
      copyCategoryToElements,
      rowToCopyId,
      draggingBelowRowToCopy
    } = rowData.copyPasteState;
    const rowDataIndex = copyCategoryToElements.findIndex(
      data => data.baseTableRowId === baseTableRowId
    );
    let result = false;
    if (rowDataIndex >= 0 || rowToCopyId === baseTableRowId) {
      result =
        copyCategoryActive(rowData) &&
        copyCategoryToElements.length > 0 &&
        // Going down
        ((draggingBelowRowToCopy && rowData.rowToCopyId === baseTableRowId) ||
          (draggingBelowRowToCopy &&
            rowDataIndex < copyCategoryToElements.length - 1) ||
          // Going up
          (!draggingBelowRowToCopy && rowDataIndex >= 0));
    }
    return result;
  };

  return (
    <AutoResizer>
      {({ width, height }) => (
        <BaseTable
          ref={baseTableRef}
          rowKey="baseTableRowId"
          className="AccountsBaseTable"
          headerClassName="AccountsBaseTable__Header"
          height={height}
          width={width}
          columns={columns}
          data={baseTableData}
          cellProps={cellProps}
          rowClassName={({ rowData }) => `
            NoHighlight ${copyCategoryActive(rowData) ? "CopyActive" : ""} ${
            noHighLightBorderTop(rowData) ? "NoBorderTop" : ""
          } ${noHighLightBorderBottom(rowData) ? "NoBorderBottom" : ""}`}
        />
      )}
    </AutoResizer>
  );
};

export default AccountsBaseTable;

AccountsBaseTable.propTypes = {
  template: PropTypes.object,
  tables: PropTypes.array,
  pageIndex: PropTypes.number,
  tableIndex: PropTypes.number,
  metaDataIndex: PropTypes.number,
  rawDataJson: PropTypes.object,
  onUpdateRawDataRows: PropTypes.func,
  metaDataJson: PropTypes.object,
  toggleExcludedRow: PropTypes.func,
  disableFilter: PropTypes.func,
  onClickRowName: PropTypes.func,
  currentTable: PropTypes.number
};
