import { Component } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { formatAggregationMetrics } from "ui/helpers/metrics";

export default class PropertiesAnalytics extends Component {
  render() {
    const { filter, metrics } = this.props;
    const formattedMetrics = formatAggregationMetrics(metrics);
    return (
      <div className="PropertiesAnalytics__container">
        <div>
          <h1 className="PropertiesAnalytics__section-header">Income</h1>
          <div className="PropertiesAnalytics__section-data">
            <div
              className={cx(
                "PropertiesAnalytics__sub-section",
                "PropertiesAnalytics__sub-section-left"
              )}
            >
              <p className="PropertiesAnalytics__sub-section-value">
                {formattedMetrics.totalPerMonth}
              </p>
              <h1 className="PropertiesAnalytics__sub-section-title">
                Total Per Month
              </h1>
            </div>
            <div
              className={cx(
                "PropertiesAnalytics__sub-section",
                "PropertiesAnalytics__sub-section-right"
              )}
            >
              <p className="PropertiesAnalytics__sub-section-value">
                {formattedMetrics.averageAnnualRentPerSqft}
              </p>
              <h1 className="PropertiesAnalytics__sub-section-title">
                {filter ? "Avg rent per sf" : "Avg annual rent per sf"}
              </h1>
            </div>
          </div>
        </div>
        <div>
          <h1 className="PropertiesAnalytics__section-header">Assets</h1>
          <div className="PropertiesAnalytics__section-data">
            <div
              className={cx(
                "PropertiesAnalytics__sub-section",
                "PropertiesAnalytics__sub-section-left"
              )}
            >
              <p className="PropertiesAnalytics__sub-section-value">
                {formattedMetrics.totalUnits}
              </p>
              <h1 className="PropertiesAnalytics__sub-section-title">Units</h1>
            </div>
            <div
              className={cx(
                "PropertiesAnalytics__sub-section",
                "PropertiesAnalytics__sub-section-right"
              )}
            >
              <p className="PropertiesAnalytics__sub-section-value">
                {formattedMetrics.totalSquareFootage}
              </p>
              <h1 className="PropertiesAnalytics__sub-section-title">
                Gross Leasable Area
              </h1>
            </div>
          </div>
        </div>
        <div>
          <h1 className="PropertiesAnalytics__section-header">Vacancy</h1>
          <div className="PropertiesAnalytics__section-data">
            <div
              className={cx(
                "PropertiesAnalytics__sub-section",
                "PropertiesAnalytics__sub-section-left"
              )}
            >
              <p className="PropertiesAnalytics__sub-section-value">
                {formattedMetrics.vacancyRatePerUnit}
              </p>
              <h1 className="PropertiesAnalytics__sub-section-title">
                per unit basis
              </h1>
            </div>
            <div
              className={cx(
                "PropertiesAnalytics__sub-section",
                "PropertiesAnalytics__sub-section-right"
              )}
            >
              <p className="PropertiesAnalytics__sub-section-value">
                {formattedMetrics.vacancyRatePerSqft}
              </p>
              <h1 className="PropertiesAnalytics__sub-section-title">
                per sqft basis
              </h1>
            </div>
          </div>
        </div>
        <div>
          <h1 className="PropertiesAnalytics__section-header">Rollover</h1>
          <div className="PropertiesAnalytics__section-data">
            <div
              className={cx(
                "PropertiesAnalytics__sub-section",
                "PropertiesAnalytics__sub-section-left"
              )}
            >
              <p className="PropertiesAnalytics__sub-section-value">
                {formattedMetrics.averageLeaseTerm}
              </p>
              <h1 className="PropertiesAnalytics__sub-section-title">
                Avg Lease Term (yrs)
              </h1>
            </div>
            <div
              className={cx(
                "PropertiesAnalytics__sub-section",
                "PropertiesAnalytics__sub-section-right"
              )}
            >
              <p className="PropertiesAnalytics__sub-section-value">
                {formattedMetrics.averageLeaseRemaining}
              </p>
              <h1 className="PropertiesAnalytics__sub-section-title">
                Avg Lease Remaining (yrs)
              </h1>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PropertiesAnalytics.propTypes = {
  filter: PropTypes.bool,
  metrics: PropTypes.object
};
