import PropTypes from "prop-types";
import cx from "classnames";
import {
  WORKFLOW_TABLE_SELECTION,
  WORKFLOW_CHART_OF_ACCOUNTS_SELECTION,
  WORKFLOW_ACCOUNT_MAPPING,
  WORKFLOW_COLUMN_PERIODIZATION
} from "ui/components/opex/helpers/opex";
import { Divider } from "antd";
import React from "react";

const CurrentStep = ({ currentWorkflowStatus }) => {
  const stepNumberStyle = {
    marginRight: ".4rem",
    borderRadius: "50%",
    width: "1.7rem",
    height: "1.7rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  };
  const steps = [
    {
      number: 1,
      label: "Tables",
      isActive: currentWorkflowStatus === WORKFLOW_TABLE_SELECTION ||
        currentWorkflowStatus === WORKFLOW_CHART_OF_ACCOUNTS_SELECTION,
      isCompleted: currentWorkflowStatus === WORKFLOW_ACCOUNT_MAPPING ||
        currentWorkflowStatus === WORKFLOW_COLUMN_PERIODIZATION,
      isInactive: false
    },
    {
      number: 2,
      label: "Mapping",
      isActive: currentWorkflowStatus === WORKFLOW_ACCOUNT_MAPPING,
      isCompleted: currentWorkflowStatus === WORKFLOW_COLUMN_PERIODIZATION,
      isInactive: currentWorkflowStatus === WORKFLOW_TABLE_SELECTION ||
        currentWorkflowStatus === WORKFLOW_CHART_OF_ACCOUNTS_SELECTION
    },
    {
      number: 3,
      label: "Review",
      isActive: currentWorkflowStatus === WORKFLOW_COLUMN_PERIODIZATION,
      isCompleted: false,
      isInactive: currentWorkflowStatus !== WORKFLOW_COLUMN_PERIODIZATION
    }
  ]
  return (
    <div className="OpexPanelView__CurrentStep">
      {steps.map((step, index, arr) => (
        <React.Fragment key={`${index}-${step.number}-${step.label}`}>
          <div
            style={{
              display: "flex",
              margin: "0 1rem 0 1rem",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <div
              style={stepNumberStyle}
              className={cx(
                "OpexPanelView__CurrentStep--number",
                { "OpexPanelView__CurrentStep--active": step.isActive },
                { "OpexPanelView__CurrentStep--complete": step.isCompleted },
                { "OpexPanelView__CurrentStep--inactive": step.isInactive }
              )}
            >
              {
                step.isCompleted
                  ? <img
                    src={require("ui/images/icon-check.svg")}
                    className="StepCheckIcon"
                  />
                  : step.number
              }
            </div>
            {step.label}
          </div>
          {
            (index < arr.length - 1) &&
            <Divider className={cx("divider", { "completed": step.isCompleted })} />
          }
        </React.Fragment>
      ))}
    </div>
  );
};

export default CurrentStep;

CurrentStep.propTypes = {
  currentWorkflowStatus: PropTypes.string
};
