import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { StickyTable, Row, Cell } from "react-sticky-table";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import cx from "classnames";
import NavLink from "ui/components/shared/NavLink";
import {
  currencyFormatter,
  percentFormatter,
  squareFootageFormatter
} from "../helpers/metrics";
import { deleteProperties } from "ui/store/actions/properties";
import { getExcelRentRolls } from "ui/store/actions/tenants";
import {
  getPropertyValues
  // multiFamilyPropertyType,
  // retailPropertyType
} from "ui/components/properties/propertiesUtil";
import Button from "ui/components/shared/Button";
import Checkbox from "ui/components/shared/Checkbox2";
// import TenantsList from "ui/components/Tenants/TenantsList";
import DownloadExcel from "ui/components/shared/DownloadExcel";
import {
  alignStickyTableHeader,
  alignStickyTableCell,
  columnDetails
} from "./helpers/propertiesList";
import SearchSortImage from "ui/components/shared/SearchSortImage";
import ThreeBounceSpinner from "ui/components/spinners/ThreeBounceSpinner";
import {
  getESSortNameByColumnName,
  getColumnNameByESSortName
} from "ui/helpers/search";
import SearchPagination from "ui/components/shared/SearchPagination";
import { isCreditLensByGroupName } from "ui/components/opex/shared";

class PropertiesList extends Component {
  state = {
    filteredProperties: [],
    starbucksFilter: true,
    tenantAnalysis: false,
    checked: {},
    searchInput: "",
    showDatePicker: false,
    chosenDate: null,
    propertiesUpdate: false
  };

  componentDidMount() {
    const { properties } = this.props;
    this.setState({ propertiesUpdate: true }, () => {
      this.setState({ filteredProperties: [...properties] }, () => {
        this.setState({ propertiesUpdate: false });
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.properties !== this.props.properties) {
      this.setState({
        filteredProperties: [...this.props.properties]
      });
    }
  }

  handleToggle = e => {
    const name = parseInt(e.target.name);
    if (this.state.checked[name] === true) {
      this.setState({
        checked: { ...this.state.checked, [name]: false }
      });
    } else {
      this.setState({
        checked: { ...this.state.checked, [name]: true }
      });
    }
  };

  isDeleteButtonDisabled = () => {
    let disabled = true;
    Object.keys(this.state.checked).forEach(key => {
      if (this.state.checked[key]) {
        disabled = false;
      }
    });
    return disabled;
  };

  handleToggleAll = _e => {
    const { properties } = this.props;
    const propsChecked = {};
    properties.forEach(prop => {
      propsChecked[prop.id] = true;
    });
    if (this.state.checked["all"] === true) {
      properties.forEach(prop => {
        propsChecked[prop.id] = false;
      });
      this.setState({
        checked: { all: false, ...propsChecked }
      });
    } else {
      properties.forEach(prop => {
        propsChecked[prop.id] = true;
      });
      this.setState({
        checked: { all: true, ...propsChecked }
      });
    }
  };

  handleDeleteClick = () => {
    const { deleteProperties, portfolioId } = this.props;
    const propIdsToDelete = [];
    Object.keys(this.state.checked).forEach(key => {
      if (key === "all") {
        return;
      }
      if (this.state.checked[key] === true) {
        const propId = key;
        propIdsToDelete.push(propId);
      }
    });
    deleteProperties(portfolioId, propIdsToDelete);
    this.setState({ checked: {} });
  };

  toggleTenantAnalysis = () => {
    this.setState({
      tenantAnalysis: !this.state.tenantAnalysis
    });
  };

  setSearchInputValue = value => {
    this.setState({
      searchInput: value
    });
  };

  handleShowVacantToggle = () => {
    const { filters, removeFilter, applyFilter } = this.props;
    if (filters.showVacant === true) {
      removeFilter("showVacant");
    } else {
      applyFilter("showVacant", true);
    }
  };

  handleDateClick = (dateType, date) => {
    const { applyFilter } = this.props;
    this.setState({ showDatePicker: false });
    dateType && applyFilter("dateTypeForCalc", dateType);
    date && applyFilter("dateForCalc", date);
  };

  handleSortClick = (column, handleSortChange, sortOrder) => {
    let columnName = column.target.innerText;
    if (columnName.length === 0) {
      columnName = column.target.parentElement.parentElement.innerText;
    }
    const sortName = getESSortNameByColumnName(columnDetails, columnName);
    if (sortName === undefined || sortName.length === 0) {
      return;
    }
    if (sortOrder === "asc") {
      sortOrder = "desc";
    } else {
      sortOrder = "asc";
    }
    handleSortChange(sortName, sortOrder);
  };

  render() {
    const { filteredProperties } = this.state;
    const {
      // applyFilter,
      // removeFilter,
      filters,
      portfolioId,
      getExcelRentRolls,
      pageIdx,
      totalPages,
      handleSortChange,
      sortColumnName,
      sortOrder,
      status,
      userRole,
      groupName
    } = this.props;
    const columns = {
      fields: [
        columnDetails.property.columnName,
        columnDetails.sector.columnName,
        columnDetails.units.columnName,
        columnDetails.sqft.columnName,
        columnDetails.totalMonthlyRent.columnName,
        columnDetails.avgRentPerSqft.columnName,
        columnDetails.vacancyUnit.columnName,
        columnDetails.vacancySqft.columnName
      ]
      /*      fields: [
        "NAME",
        "ADDRESS",
        "SECTOR",
        "REIS SURVEYED RENT",
        "VACANCY",
        "CITY",
        "STATE",
        "ZIP CODE"
      ]
    */
    };
    if (status === "FETCHING" && this.state.propertiesUpdate) {
      return <ThreeBounceSpinner />;
    }
    let isCL = isCreditLensByGroupName(groupName);
    const propertyTypes = getPropertyValues(isCL);

    const getPropertyTypeName = id => {
      const PropertyType = propertyTypes.filter(item => item.id === id);
      return PropertyType[0] ? PropertyType[0].name : id;
    };

    const selectedColumnName = getColumnNameByESSortName(
      columnDetails,
      sortColumnName
    );

    return (
      <div className="PropertiesList__section">
        <div className="PropertiesList__header">
          <div className="PropertiesList__header__center">
            {/* <span>Show Vacant</span>
            <ToggleSwitch
              className="center-by-margin"
              handleToggle={this.handleShowVacantToggle}
              isOn={filters && filters.showVacant === true}
            />
            <input
              type="text"
              value={searchInput}
              placeholder="Search.."
              style={{ marginRight: "5px" }}
              onKeyDown={e => {
                if (e.key === "Enter") {
                  if (searchInput === "") {
                    removeFilter("searchInput");
                  } else {
                    applyFilter("searchInput", searchInput);
                  }
                }
              }}
              onChange={e => this.setSearchInputValue(e.target.value)}
            /> */}
            <div>
              <SearchPagination
                pageIdx={pageIdx}
                totalPages={totalPages}
                updatePagination={this.props.updatePagination}
              />
            </div>
            <div>
              <DownloadExcel
                overrideFunction={() => getExcelRentRolls(portfolioId, filters)}
                text="Download"
              />
            </div>
          </div>
        </div>
        <div className="PropertiesList__grid">
          <StickyTable borderWidth={"1px"} style={{ zIndex: 0 }}>
            <Row className={cx("sticky-table-header", "row-header")}>
              <Cell
                key={`checkbox_all`}
                style={{
                  backgroundColor: "#403f3f",
                  verticalAlign: "middle",
                  textAlign: "right"
                }}
              >
                <Checkbox
                  key={`checkbox_row_all`}
                  name={"all"}
                  checked={this.state.checked["all"] === true}
                  toggleCheck={this.handleToggleAll}
                />
              </Cell>
              {columns.fields.map(column_name => (
                <Cell
                  style={{
                    backgroundColor: "#403f3f",
                    verticalAlign: "middle",
                    textAlign: alignStickyTableHeader(column_name),
                    cursor:
                      column_name != columnDetails.sector.columnName
                        ? "pointer"
                        : "initail"
                  }}
                  key={column_name}
                  onClick={column =>
                    this.handleSortClick(column, handleSortChange, sortOrder)
                  }
                >
                  {column_name}
                  <SearchSortImage
                    sortOrder={sortOrder}
                    selectedColumnName={selectedColumnName}
                    columnName={column_name}
                    columnDetails={columnDetails}
                  />
                </Cell>
              ))}
            </Row>
            {filteredProperties &&
              filteredProperties.map((item, index) => {
                return (
                  <Row key={index} className={cx("sticky-table__body-row")}>
                    <Cell
                      key={`checkbox_${index}`}
                      className={cx(
                        "sticky-table__cell",
                        // alignStickyTableCell(),
                        "sticky-table__cell--small",
                        { "sticky-table__cell--odd": index % 2 !== 0 }
                      )}
                    >
                      <Checkbox
                        key={`checkbox_row_${index}`}
                        name={item.id.toString()}
                        checked={this.state.checked[item.id] === true}
                        toggleCheck={this.handleToggle}
                      />
                    </Cell>
                    <Cell
                      key="name"
                      className={cx(
                        "sticky-table__cell",
                        alignStickyTableCell("NAME"),
                        { "sticky-table__cell--odd": index % 2 !== 0 }
                      )}
                    >
                      <NavLink
                        className="PropertiesList__rentroll-nav"
                        to={`/rent-roll/property/${item.id}${
                          item.portfolio_id
                            ? `/portfolio/${item.portfolio_id}`
                            : ""
                        }`}
                      >
                        {item.name}
                      </NavLink>
                      <div> {item.address} </div>
                    </Cell>
                    <Cell
                      key="property_type"
                      className={cx(
                        "sticky-table__cell",
                        alignStickyTableCell("SECTOR"),
                        { "sticky-table__cell--odd": index % 2 !== 0 }
                      )}
                    >
                      {getPropertyTypeName(item.property_type)}
                    </Cell>
                    <Cell
                      key="units"
                      className={cx(
                        "sticky-table__cell",
                        alignStickyTableCell("UNITS"),
                        { "sticky-table__cell--odd": index % 2 !== 0 }
                      )}
                    >
                      {item.total_units ? item.total_units : "-"}
                    </Cell>
                    <Cell
                      key="sqft"
                      className={cx(
                        "sticky-table__cell",
                        alignStickyTableCell("SQFT"),
                        { "sticky-table__cell--odd": index % 2 !== 0 }
                      )}
                    >
                      {item.total_square_footage
                        ? squareFootageFormatter.format(
                            item.total_square_footage
                          ) + " sq ft"
                        : "-"}
                    </Cell>
                    <Cell
                      key="total_monthly_rent"
                      // style={{
                      //   textAlign: "right",
                      //   width: "150px",
                      //   paddingRight: "50px"
                      // }}
                      className={cx(
                        "sticky-table__cell",
                        alignStickyTableCell(),
                        { "sticky-table__cell--odd": index % 2 !== 0 }
                      )}
                    >
                      {item.total_monthly_rent
                        ? currencyFormatter.format(item.total_monthly_rent)
                        : "-"}
                    </Cell>
                    <Cell
                      key="avg_rent_per_sqft"
                      className={cx(
                        "sticky-table__cell",
                        alignStickyTableCell(),
                        { "sticky-table__cell--odd": index % 2 !== 0 }
                      )}
                    >
                      {item.occupied_total_annual_rent_with_sqft &&
                      item.occupied_total_sqft_with_annual_rent
                        ? currencyFormatter.format(
                            item.occupied_total_annual_rent_with_sqft /
                              item.occupied_total_sqft_with_annual_rent
                          )
                        : "-"}
                    </Cell>
                    <Cell
                      key="vacancy"
                      className={cx(
                        "sticky-table__cell",
                        alignStickyTableCell(),
                        { "sticky-table__cell--odd": index % 2 !== 0 }
                      )}
                    >
                      {item.total_units !== null &&
                      item.total_units > 0 &&
                      item.occupied_total_units !== null
                        ? percentFormatter.format(
                            1 - item.occupied_total_units / item.total_units
                          )
                        : "-"}
                    </Cell>
                    <Cell
                      key="vacancy_sqft"
                      className={cx(
                        "sticky-table__cell",
                        alignStickyTableCell(),
                        { "sticky-table__cell--odd": index % 2 !== 0 }
                      )}
                    >
                      {item.total_square_footage !== null &&
                      item.total_square_footage > 0 &&
                      item.occupied_total_square_footage
                        ? percentFormatter.format(
                            1 -
                              item.occupied_total_square_footage /
                                item.total_square_footage
                          )
                        : "-"}
                    </Cell>
                    {/*                    <Cell key="building_sqft">
                      {item.size_sqft &&
                        squareFootageFormatter.format(
                          parseInt(item.size_sqft)
                        ) + " sq ft"}
                    </Cell>

                    <Cell key="year_built">{item.year_built}</Cell>
                    <Cell key="floors">{item.floors} </Cell>
                    <Cell key="parcel_number">{item.parcel_number}</Cell>
                                          */}
                  </Row>
                );
              })}
          </StickyTable>
          <Button
            disabled={this.isDeleteButtonDisabled()}
            onClick={this.handleDeleteClick}
            className="Button__deleteButton"
          >
            Delete
          </Button>
        </div>
        <SearchPagination
          pageIdx={pageIdx}
          totalPages={totalPages}
          updatePagination={this.props.updatePagination}
        />
      </div>
    );
  }
}

PropertiesList.propTypes = {
  properties: PropTypes.array,
  deleteProperties: PropTypes.func,
  applyFilter: PropTypes.func,
  removeFilter: PropTypes.func,
  filters: PropTypes.object,
  getExcelRentRolls: PropTypes.func,
  excelTenants: PropTypes.array,
  pageIdx: PropTypes.number,
  totalPages: PropTypes.number,
  updatePagination: PropTypes.func,
  handleSortChange: PropTypes.func,
  sortColumnName: PropTypes.string,
  sortOrder: PropTypes.string,
  status: PropTypes.string,
  portfolioId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  userRole: PropTypes.string,
  groupName: PropTypes.string
};

const mapStateToProps = ({ currentUser }) => ({
  userRole: currentUser.role,
  groupName: currentUser.company.group_name
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      deleteProperties,
      getExcelRentRolls
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PropertiesList)
);
