import PropTypes from "prop-types";
import cx from "classnames";
import Button from "ui/components/shared/Button";
import {
  RiEraserLine,
  RiCheckboxBlankLine,
  RiDragMove2Fill,
  RiLayoutGridLine,
  RiDeleteBin7Line
} from "react-icons/ri";
import { AiOutlineLine } from "react-icons/ai";

const PdfEditorControls = ({
  numberOfPages,
  previousDisabled,
  nextDisabled,
  goToNextPageClick,
  goToPrevPageClick,
  handleSettingDrawingMode,
  drawingMode,
  handleSettingLineWidth,
  lineWidth,
  clearAll,
  undoChange,
  redoChange,
  saveDrawingData,
  editsMade,
  handleSubmitButtonClick,
  closeModal
}) => {
  return (
    <div className="SplitPanelV2__PdfEditor__controls">
      {numberOfPages > 1 && (
        <div>
          <Button
            className={"SplitPanelV2__PdfDocument__control-button"}
            disabled={nextDisabled}
            onClick={goToNextPageClick}
          >
            Next Page
          </Button>
          <Button
            className={"SplitPanelV2__PdfDocument__control-button"}
            disabled={previousDisabled}
            onClick={goToPrevPageClick}
          >
            Previous Page
          </Button>
        </div>
      )}
      <div>
        <div className={"SplitPanelV2__PdfDocument__controls-label-first"}>
          DRAWING MODE
        </div>
        <div className="SplitPanelV2__PdfDocument__mode-buttons-wrapper">
          <Button
            onClick={() => handleSettingDrawingMode("select", "black")}
            className={cx("SplitPanelV2__PdfDocument__mode-button", {
              "SplitPanelV2__PdfDocument__mode-button--active":
                drawingMode === "select"
            })}
          >
            <RiDragMove2Fill size={30} />
          </Button>
          <Button
            onClick={() => handleSettingDrawingMode("line", "black")}
            className={cx("SplitPanelV2__PdfDocument__mode-button", {
              "SplitPanelV2__PdfDocument__mode-button--active":
                drawingMode === "line"
            })}
          >
            <AiOutlineLine size={30} />
          </Button>
          <Button
            onClick={() => handleSettingDrawingMode("box", "black")}
            className={cx("SplitPanelV2__PdfDocument__mode-button", {
              "SplitPanelV2__PdfDocument__mode-button--active":
                drawingMode === "box"
            })}
          >
            <RiCheckboxBlankLine size={30} />
          </Button>
          <Button
            onClick={() => handleSettingDrawingMode("grid", "black")}
            className={cx("SplitPanelV2__PdfDocument__mode-button", {
              "SplitPanelV2__PdfDocument__mode-button--active":
                drawingMode === "grid"
            })}
          >
            <RiLayoutGridLine size={30} />
          </Button>
          <Button
            onClick={() => handleSettingDrawingMode("eraser", "white")}
            className={cx("SplitPanelV2__PdfDocument__mode-button", {
              "SplitPanelV2__PdfDocument__mode-button--active":
                drawingMode === "eraser"
            })}
          >
            <RiEraserLine size={30} />
          </Button>
          <Button
            onClick={() => handleSettingDrawingMode("delete", "white")}
            className={cx("SplitPanelV2__PdfDocument__mode-button", {
              "SplitPanelV2__PdfDocument__mode-button--active":
                drawingMode === "delete"
            })}
          >
            <RiDeleteBin7Line size={25} />
          </Button>
        </div>
        <div className={"SplitPanelV2__PdfDocument__controls-label"}>
          LINE THICKNESS
        </div>
        <div className="SplitPanelV2__PdfDocument__thickness-buttons-wrapper">
          <Button
            onClick={() => handleSettingLineWidth(2)}
            className={cx("SplitPanelV2__PdfDocument__mode-button", {
              "SplitPanelV2__PdfDocument__mode-button--active": lineWidth === 2
            })}
          >
            <AiOutlineLine
              className="SplitPanelV2__PdfDocument__thin-line"
              size={30}
            />
          </Button>
          <Button
            onClick={() => handleSettingLineWidth(4)}
            className={cx("SplitPanelV2__PdfDocument__mode-button", {
              "SplitPanelV2__PdfDocument__mode-button--active": lineWidth === 4
            })}
          >
            <AiOutlineLine
              className="SplitPanelV2__PdfDocument__medium-line"
              size={30}
            />
          </Button>
          <Button
            onClick={() => handleSettingLineWidth(6)}
            className={cx("SplitPanelV2__PdfDocument__mode-button", {
              "SplitPanelV2__PdfDocument__mode-button--active": lineWidth === 6
            })}
          >
            <AiOutlineLine
              className="SplitPanelV2__PdfDocument__thick-line"
              size={30}
            />
          </Button>
        </div>
      </div>
      <Button
        className={"SplitPanelV2__PdfDocument__control-button"}
        onClick={clearAll}
        style={{ marginTop: "2rem" }}
      >
        Clear
      </Button>
      <Button
        className={"SplitPanelV2__PdfDocument__control-button"}
        onClick={undoChange}
      >
        Undo
      </Button>
      <Button
        className={"SplitPanelV2__PdfDocument__control-button"}
        onClick={redoChange}
      >
        Redo
      </Button>
      <Button
        className={"SplitPanelV2__PdfDocument__control-button"}
        onClick={saveDrawingData}
        disabled={!editsMade}
      >
        Save Edits
      </Button>
      <Button
        className={"SplitPanelV2__PdfDocument__submit-button"}
        onClick={handleSubmitButtonClick}
      >
        Submit Page
      </Button>
      <Button
        className={"SplitPanelV2__PdfDocument__control-button"}
        onClick={closeModal}
      >
        Close Editor
      </Button>
    </div>
  );
};

export default PdfEditorControls;

PdfEditorControls.propTypes = {
  numberOfPages: PropTypes.number,
  previousDisabled: PropTypes.bool,
  nextDisabled: PropTypes.bool,
  goToNextPageClick: PropTypes.func,
  goToPrevPageClick: PropTypes.func,
  handleSettingDrawingMode: PropTypes.func,
  drawingMode: PropTypes.string,
  handleSettingLineWidth: PropTypes.func,
  lineWidth: PropTypes.number,
  clearAll: PropTypes.func,
  undoChange: PropTypes.func,
  redoChange: PropTypes.func,
  saveDrawingData: PropTypes.func,
  editsMade: PropTypes.bool,
  handleSubmitButtonClick: PropTypes.func,
  closeModal: PropTypes.func
};
