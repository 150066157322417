import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { reactLocalStorage } from "reactjs-localstorage";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import {
  fetchRentRollByPropId,
  fetchRentRollDocsByPropId,
  fetchRentRollByDocId,
  fetchAllRentRollsByPropId,
  clearDocuments,
  clearRentRoll
} from "ui/store/actions/rentRoll";
import {
  updatePanelTabIndex,
  updateHeaderTabIndex,
  updateSelectedDocId,
  resetUIToDefault
} from "ui/store/actions/propertyRentRollViewUI";
import {
  fetchProperty,
  updateProperty,
  fetchPropertyFromReisNetwork,
  resetSearch,
  mapPropertyToReisNetwork
} from "ui/store/actions/properties";

import NavLink from "ui/components/shared/NavLink";
import Documents from "./Documents";
import RentRoll from "./RentRoll";
import RentRollComposition from "./RentRollComposition";
import RentRollStatus from "./RentRollStatus";
import AuthenticatedRoute from "ui/components/routing/AuthenticatedRoute";
import HeaderWithSidebar from "ui/components/shared/HeaderWithSidebar";
import RentRollAnalytics from "ui/components/rentRoll/property/RentRollAnalytics";
import PropertyRentrollPopup from "ui/components/rentRoll/property/PropertyRentrollPopup";
import PropertyPopup from "ui/components/properties/PropertyPopup";
import { propertyActions } from "ui/components/properties/helpers/propertiesList";

class PropertyRentRollView extends Component {
  constructor() {
    super();
    this.state = {
      rentRoll: [],
      files: [],
      isUploading: false,
      isProcessing: false,
      isLoading: true
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { propertyId }
      }
    } = this.props;
    this.props.refreshPreview(propertyId);
    this.setState({
      isLoading: false
    });
  }

  componentWillUnmount() {
    const { resetDocuments, resetRentRoll } = this.props;
    resetDocuments();
    resetRentRoll();
  }

  getSelectedDoc = id => {
    const { documents } = this.props;
    return documents.find(doc => doc.id === Number(id));
  };

  componentDidUpdate(prevProps) {
    const {
      selectedProperty,
      changeHeaderTabIndex,
      changePanelTabIndex,
      headerIndex,
      panelIndex,
      currDocId,
      getRentRollByDocId
    } = this.props;

    if (selectedProperty !== prevProps.selectedProperty) {
      this.props.mapPropertyToReisNetwork(selectedProperty);
    }

    if (currDocId !== prevProps.currDocId) {
      getRentRollByDocId(currDocId);
    }

    if (
      headerIndex !== prevProps.headerIndex &&
      headerIndex !== Number(reactLocalStorage.get("headerTab", 0))
    ) {
      changeHeaderTabIndex(Number(reactLocalStorage.get("headerTab", 0)));
    }

    if (
      panelIndex !== prevProps.panelIndex &&
      panelIndex !== Number(reactLocalStorage.get("panelTab", 0))
    ) {
      changePanelTabIndex(Number(reactLocalStorage.get("panelTab", 0)));
    }
  }

  onTabChange = (newIndex, tabLocation) => {
    const { changeHeaderTabIndex, changePanelTabIndex } = this.props;
    switch (tabLocation) {
      case "HEADER":
        changeHeaderTabIndex(newIndex);
        reactLocalStorage.set("headerTab", newIndex);
        break;
      case "PANEL":
        changePanelTabIndex(newIndex);
        reactLocalStorage.set("panelTab", newIndex);
        break;
    }
  };

  beginUploadingFile = () => {
    this.setState({ isUploading: true });
  };

  finishUploadingFile = () => {
    this.setState({ isUploading: false, isProcessing: true });
  };

  finishProcessingFile = () => {
    this.setState({ isProcessing: false });
  };

  getTitleAddress = selectedPropertyDetails => {
    return (
      <div className="HeaderWithSidebar__rentroll-title-address">
        {selectedPropertyDetails.city}
        {selectedPropertyDetails.city && selectedPropertyDetails.state && ", "}
        {selectedPropertyDetails.state}
        {selectedPropertyDetails.state &&
          selectedPropertyDetails.postal_code &&
          " "}
        {selectedPropertyDetails.postal_code}
      </div>
    );
  };

  getRentrollHeader() {
    const { selectedProperty } = this.props;

    if (!selectedProperty) return null;
    if (Object.keys(selectedProperty).length === 0) return null;
    return (
      <div className="HeaderWithSidebar__header-components">
        {this.getNav(selectedProperty.portfolio_id)}
        <div>
          <div className="HeaderWithSidebar__category-header">
            RENT ROLL ANALYSIS
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div className="HeaderWithSidebar__title-name">
              {selectedProperty.name}
            </div>
            {this.getTitleAddress(selectedProperty)}
          </div>
        </div>
      </div>
    );
  }

  getNav = portfolioId => {
    return (
      portfolioId && (
        <NavLink
          className="HeaderWithSidebar__back-button-nav"
          to={{
            pathname: "/properties",
            search: `?portfolioId=${portfolioId}`
          }}
        >
          <img
            className="HeaderWithSidebar__back-button-img"
            src={require("ui/components/shared/images/arrows/LeftArrow.png")}
          />
        </NavLink>
      )
    );
  };

  resetSelectedRentroll = () => {
    const {
      getRentRollByPropId,
      match: {
        params: { propertyId }
      }
    } = this.props;
    getRentRollByPropId(propertyId);
  };

  getCurrentDocument() {
    const { documents, selectedProperty } = this.props;
    const currentDocument =
      documents &&
      selectedProperty &&
      documents.find(doc => doc.id === selectedProperty.current_document_id);
    return currentDocument;
  }

  getEditPropertyModal = () => {
    return (
      <div className="Properties__buttons">
        <PropertyPopup
          onNext={this.getReisNetworkProperty}
          onSubmit={this.updateProperty}
          propertyData={this.props.reisProperty}
          isLoadingReisProperty={this.props.isLoadingReisProperty}
          resetSearch={this.props.resetSearch}
          action={propertyActions.edit}
          canUpdatePropertyType={this.props.documents.length === 0}
        />
      </div>
    );
  };

  getReisNetworkProperty = location => {
    const { getPropertyFromReisNetwork, selectedProperty } = this.props;
    getPropertyFromReisNetwork(location, selectedProperty.property_type);
  };

  updateProperty = async property => {
    const { updatePropertyDetails, selectedProperty } = this.props;
    await updatePropertyDetails(selectedProperty, property);
  };

  render() {
    const {
      rentRoll,
      allRentRolls,
      match: {
        params: { propertyId }
      },
      selectedProperty,
      documents,
      panelIndex,
      headerIndex,
      currDocId,
      currentUser,
      selectedPortfolio
    } = this.props;

    return (
      <HeaderWithSidebar
        subHeader={
          !this.state.isLoading && this.getRentrollHeader(selectedProperty)
        }
        button={selectedPortfolio ? this.getEditPropertyModal() : ""}
      >
        <div className="PropertyRentRollView__tabs">
          <Tabs
            selectedIndex={headerIndex}
            onSelect={index => this.onTabChange(index, "HEADER")}
            style={{ marginTop: "15px", width: "100%" }}
          >
            <TabList className="PropertyRentRollView__tab-list">
              <Tab>ANALYSIS</Tab>
              <Tab>HISTORY</Tab>
            </TabList>
            <div>
              {documents.filter(doc => doc.status === "COMPLETED").length > 0 &&
                !this.state.isLoading && (
                  <RentRollStatus
                    portfolioId={selectedProperty.portfolio_id}
                    propertyId={propertyId}
                  />
                )}
              <TabPanel>
                <div className="PropertyRentRollView__metrics">
                  {documents.filter(doc => doc.status === "COMPLETED").length >
                    0 && (
                    <RentRollAnalytics
                      key={selectedProperty.id}
                      property={selectedProperty}
                      document={this.getSelectedDoc(currDocId)}
                    />
                  )}
                </div>
                <div className="PropertyRentRollView__tab-container">
                  <div className="PropertyRentRollView__grid">
                    <Tabs
                      style={{ width: "100%" }}
                      selectedIndex={panelIndex}
                      onSelect={index => this.onTabChange(index, "PANEL")}
                    >
                      <TabList>
                        <Tab>RENT ROLL</Tab>
                        <Tab>COMPOSITION</Tab>
                        {/* <Tab>LEASE ROLLOVER SCHEDULE</Tab> */}
                      </TabList>
                      <TabPanel>
                        <RentRoll
                          rentRoll={rentRoll}
                          allRentRolls={allRentRolls}
                          portfolioId={selectedProperty.portfolio_id}
                        />
                      </TabPanel>
                      <TabPanel>
                        {currDocId &&
                          documents.length > 0 &&
                          !this.state.isLoading && (
                            <RentRollComposition
                              document={this.getSelectedDoc(currDocId)}
                              property={selectedProperty}
                            />
                          )}
                      </TabPanel>
                      {/* <TabPanel></TabPanel> */}
                    </Tabs>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="PropertyRentRollView__tab-container">
                  <div className="PropertyRentRollView__document-grid">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row-reverse",
                        marginTop: "15px"
                      }}
                    >
                      <PropertyRentrollPopup
                        currentUser={currentUser}
                        beginUploadingFile={this.beginUploadingFile}
                        isProcessing={this.state.isProcessing}
                        isUploading={this.state.isUploading}
                      />
                    </div>
                    <Documents
                      onDeleteCheckCurrentDoc={this.resetSelectedRentroll}
                      currentDocument={this.getSelectedDoc(currDocId)}
                    />
                  </div>
                </div>
              </TabPanel>
            </div>
          </Tabs>
        </div>
      </HeaderWithSidebar>
    );
  }
}

PropertyRentRollView.propTypes = {
  match: PropTypes.object,
  rentRoll: PropTypes.array,
  allRentRolls: PropTypes.array,
  headerIndex: PropTypes.number,
  panelIndex: PropTypes.number,
  refreshPreview: PropTypes.func,
  getRentRollByPropId: PropTypes.func,
  getRentRollDocsByPropId: PropTypes.func,
  changeHeaderTabIndex: PropTypes.func,
  changePanelTabIndex: PropTypes.func,
  changeSelectedDocId: PropTypes.func,
  resetUI: PropTypes.func,
  getProperty: PropTypes.func,
  resetDocuments: PropTypes.func,
  resetRentRoll: PropTypes.func,
  getRentRollByDocId: PropTypes.func,
  documents: PropTypes.array,
  selectedProperty: PropTypes.object,
  currentUser: PropTypes.object,
  currDocId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  propertyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  selectedPortfolio: PropTypes.object,
  getPropertyFromReisNetwork: PropTypes.func,
  reisProperty: PropTypes.object,
  resetSearch: PropTypes.func,
  isLoadingReisProperty: PropTypes.bool,
  updatePropertyDetails: PropTypes.func,
  mapPropertyToReisNetwork: PropTypes.func
};

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser,
    rentRoll: state.rentRoll.rentRoll,
    allRentRolls: state.rentRoll.allRentRolls,
    documents: state.rentRoll.documents,
    // Todo: Choose weather selectedProperty should be in rentRoll or property reducer
    selectedProperty: state.rentRoll.selectedProperty,
    headerIndex: state.propertyRentRollViewUI.headerTabIndex,
    panelIndex: state.propertyRentRollViewUI.panelTabIndex,
    currDocId: state.propertyRentRollViewUI.docId,
    selectedPortfolio: state.properties.selectedPortfolio,
    isLoadingReisProperty: state.properties.isLoadingReisProperty,
    reisProperty: state.properties.reisProperty
  };
}

function mapDispatchToProps(dispatch) {
  return {
    refreshPreview: propertyId => {
      dispatch(fetchRentRollByPropId(propertyId));
      dispatch(fetchRentRollDocsByPropId(propertyId));
      dispatch(fetchAllRentRollsByPropId(propertyId));
    },
    getRentRollByPropId: propertyId => {
      dispatch(fetchRentRollByPropId(propertyId));
    },
    getRentRollDocsByPropId: propertyId => {
      dispatch(fetchRentRollDocsByPropId(propertyId));
    },
    getRentRollByDocId: documentId => {
      dispatch(fetchRentRollByDocId(documentId));
    },
    changeHeaderTabIndex: index => {
      dispatch(updateHeaderTabIndex(index));
    },
    changePanelTabIndex: index => {
      dispatch(updatePanelTabIndex(index));
    },
    changeSelectedDocId: id => {
      dispatch(updateSelectedDocId(id));
    },
    resetUI: () => {
      dispatch(resetUIToDefault());
    },
    getProperty: propertyId => dispatch(fetchProperty(propertyId)),
    resetDocuments: () => dispatch(clearDocuments()),
    resetRentRoll: () => dispatch(clearRentRoll()),
    updatePropertyDetails: (selectedProperty, property) => {
      dispatch(updateProperty(selectedProperty, property));
    },
    resetSearch: () => {
      dispatch(resetSearch());
    },
    getPropertyFromReisNetwork: (location, canUpdatePropertyType) => {
      dispatch(fetchPropertyFromReisNetwork(location, canUpdatePropertyType));
    },
    mapPropertyToReisNetwork: property => {
      dispatch(mapPropertyToReisNetwork(property));
    }
  };
}

export default AuthenticatedRoute(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(PropertyRentRollView))
);
