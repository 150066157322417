import { createRef, Component } from "react";

import {
  getPageByIndex,
  organizeHeaderData,
  getDocData,
  processExcelCellToDocData,
  getSheetDataFromDocument,
  getTemplateObject,
  getMultiLineHeader
} from "./helpers/processing";
import { fieldNames } from "./helpers/dictionaries";
import { scrollToCellOnDocument } from "./helpers/navigation";
import {
  updateDocumentTemplate,
  updateDocumentField,
  updateTextFieldsOpacity,
  updateEditHeader,
  clearEditHeader,
  // updateEditText,
  // clearEditText,
  clearCache,
  updateCache
} from "ui/store/actions/rentRoll";
import { BeginDocumentNav, EndDocumentNav } from "ui/store/actions/splitScreen";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import cx from "classnames";

import CellEditPopover from "./popovers/cell/CellEditPopover";
import HeaderEditPopover from "./popovers/header/HeaderEditPopover";
import AdvancedRulesPopover from "./popovers/advancedRules/AdvancedRulesPopover";
import {
  isLeaseLongerThanTenYears,
  isLeaseStartDateAfterEnd,
  isCellValueRequired
} from "./helpers/exceptions";

class ExcelDocumentView extends Component {
  constructor(props) {
    super();
    this.state = {
      selectedCell: "",
      page: getPageByIndex(props.document, props.pageIndex)
    };

    this.tableRef = createRef();
    this.tableRefs = {};
  }

  componentDidUpdate(prevProps) {
    const { pageIndex, document, documentNav, pageNavInProgress } = this.props;

    if (prevProps.pageIndex !== pageIndex || prevProps.document !== document) {
      this.setState({ page: getPageByIndex(document, pageIndex) });
    }

    if (
      documentNav &&
      prevProps.pageNavInProgress !== pageNavInProgress &&
      pageNavInProgress === false
    ) {
      this.scrollToCellInDocument();
    }

    if (prevProps.documentNav !== documentNav && pageNavInProgress === false) {
      this.scrollToCellInDocument();
    }
  }

  scrollToCellInDocument = () => {
    const { BeginDocNav, EndDocNav, documentNav } = this.props;
    if (documentNav !== "") {
      BeginDocNav();
      scrollToCellOnDocument(documentNav, true, this.tableRefs);
      new Promise(resolve => setTimeout(resolve, 1000)).then(() => EndDocNav());
    }
  };

  updateSelectedCell = selectedCell => {
    this.setState({ selectedCell });
  };

  clearSelectedCell = () => {
    this.setState({ selectedCell: "" });
  };

  editHeader = (page, table, row, col) => {
    const { updateEditHeader } = this.props;
    updateEditHeader({
      page,
      table,
      row,
      col
    });
  };

  rowHeader = (page, table, row) => {
    const { updateCache, cache } = this.props;
    const rowHeaderKey = `rowHeader-${page}-${table}`;

    if (cache && cache[rowHeaderKey]) {
      if (cache[rowHeaderKey].row.includes(row)) {
        updateCache(rowHeaderKey, {
          row: [row]
        });
      } else if (
        !cache[rowHeaderKey].row.includes(row) &&
        cache[rowHeaderKey].row.length === 1 &&
        (cache[rowHeaderKey].row[0] + 1 === row ||
          cache[rowHeaderKey].row[0] - 1 === row)
      ) {
        updateCache(rowHeaderKey, {
          row: [cache[rowHeaderKey].row[0], row].sort()
        });
      } else if (
        !cache[rowHeaderKey].row.includes(row) &&
        cache[rowHeaderKey].row.length === 2 &&
        (cache[rowHeaderKey].row[1] + 1 === row ||
          cache[rowHeaderKey].row[0] - 1 === row)
      ) {
        updateCache(rowHeaderKey, {
          row: [...cache[rowHeaderKey].row, row].sort()
        });
      } else if (
        !cache[rowHeaderKey].row.includes(row) &&
        cache[rowHeaderKey].row.length === 3
      ) {
        updateCache(rowHeaderKey, {
          row: [row].sort()
        });
      } else if (
        !cache[rowHeaderKey].row.includes(row) &&
        cache[rowHeaderKey].row.length === 1 &&
        !(
          cache[rowHeaderKey].row[0] + 1 === row ||
          cache[rowHeaderKey].row[0] - 1 === row
        )
      ) {
        updateCache(rowHeaderKey, {
          row: [row].sort()
        });
      } else if (
        !cache[rowHeaderKey].row.includes(row) &&
        cache[rowHeaderKey].row.length === 2 &&
        !(
          cache[rowHeaderKey].row[1] + 1 === row ||
          cache[rowHeaderKey].row[0] - 1 === row
        )
      ) {
        updateCache(rowHeaderKey, {
          row: [row].sort()
        });
      }
    } else {
      updateCache(rowHeaderKey, { row: [row] });
    }
  };

  closeHeaderEditor = () => {
    const { clearEditHeader } = this.props;
    clearEditHeader();
  };

  updateHeader = (pi, ti, ri, rowIds) => {
    const {
      clearEditHeader,
      cache,
      submitDocumentTemplate,
      document
    } = this.props;

    const headerData = organizeHeaderData(document);

    const templateObj = getTemplateObject(
      document,
      headerData,
      { pi, ti, ri },
      cache,
      rowIds
    );

    submitDocumentTemplate(
      templateObj,
      headerData[pi][ti].metaName,
      headerData[pi][ti].headerIndex,
      document.id,
      headerData[pi][ti].multipleLineGrouping,
      headerData[pi][ti].advancedRules
    );

    clearEditHeader();
  };

  inputChanged = event => {
    const { updateCache } = this.props;
    updateCache(event.target.name, event.target.value);
  };

  handleInputKeyDown = event => {
    if (event.keyCode === 13) {
      const { submitDocumentField, document, widgetAuth } = this.props;
      const fieldPosition = event.target.name.split("-");
      submitDocumentField(
        {
          page: parseInt(fieldPosition[1]),
          table: parseInt(fieldPosition[2]),
          row: parseInt(fieldPosition[3]),
          col: parseInt(fieldPosition[4]),
          text: event.target.value
        },
        document.id,
        widgetAuth
      );
    }
  };

  checkboxChanged = event => {
    const { updateCache } = this.props;
    updateCache(event.target.name, event.target.checked);
  };

  render() {
    const {
      cache,
      document,
      pageIndex,
      rentRoll,
      exceptionSidebarIsActive,
      handleScrollToCell,
      selectedDocumentCell,
      exceptions,
      propertyType,
      widgetAuth
    } = this.props;

    const excelWorkbook = document.excel_workbook;
    const excelCellToDocDataMap = processExcelCellToDocData(
      document,
      pageIndex
    );
    const headerData = organizeHeaderData(document);
    const docData = getDocData(rentRoll);

    const dataTableId = `excelDataTable-${pageIndex}`;
    const sheetName = excelWorkbook.SheetNames[pageIndex];
    const docPage = getPageByIndex(document, pageIndex);
    const sheet = getSheetDataFromDocument(document, pageIndex);
    const isWidgetAuthorized = widgetAuth && widgetAuth.authorized;

    return (
      <div
        className={cx(
          "RentRollDocumentView",
          "SplitPanel__documentView",
          {
            ["SplitPanel--withExceptionSidebar"]: exceptionSidebarIsActive
          },
          {
            ["SplitPanel__documentView__widget"]: isWidgetAuthorized
          }
        )}
      >
        {document &&
          document.raw_data_json &&
          document.raw_data_json.pages &&
          this.state.page && (
            <div key={"page_" + (pageIndex + 1)} style={{ padding: "10px" }}>
              <div
                className="ExcelDocumentView__header"
                style={{
                  width: this.tableRef.current
                    ? `${this.tableRef.current.clientWidth}px`
                    : "auto"
                }}
              >
                <div className="ExcelDocumentView__header__right">
                  <AdvancedRulesPopover location={{ pi: pageIndex, ti: 0 }} />
                </div>
                <h1>{sheetName}</h1>
                <div className="ExcelDocumentView__header__left"></div>
              </div>
              <table
                id={dataTableId}
                ref={this.tableRef}
                style={{
                  margin: "0 auto"
                }}
              >
                {sheet && sheet.length > 0 && (
                  <tbody>
                    {sheet.map((row, ri) => (
                      <tr key={`table-row-${ri}`}>
                        {row.map(node => {
                          const excelCellFromTdNode = node.position.excelCell;
                          if (!excelCellToDocDataMap[excelCellFromTdNode]) {
                            return null;
                          }
                          const {
                            docLocationFromExcelCell
                          } = excelCellToDocDataMap[excelCellFromTdNode];

                          const [pi, ti, ri, ci] = docLocationFromExcelCell;

                          let tdAttribs = {
                            style: {
                              cursor: "pointer",
                              verticalAlign: "middle",
                              minHeight: "10px"
                            },
                            excelid: node.position.excelCell
                          };

                          let headerOuter = null;
                          if (cache["rowHeader-" + pi + "-" + ti]) {
                            headerOuter =
                              cache["rowHeader-" + pi + "-" + ti].row &&
                              cache["rowHeader-" + pi + "-" + ti].row.includes(
                                ri
                              );
                          } else if (
                            headerData[pi][ti] &&
                            headerData[pi][ti].rowIds &&
                            headerData[pi][ti].rowIds.includes(ri)
                          ) {
                            headerOuter = { inExcel: true };
                          } else if (
                            !cache["rowHeader-" + pi + "-" + ti] &&
                            headerData[pi][ti] &&
                            !headerData[pi][ti].rowIds &&
                            headerData[pi][ti].rowIndex &&
                            headerData[pi][ti].rowIndex === ri
                          ) {
                            headerOuter = { inExcel: true };
                          }

                          const row = docPage.tableData[ti][ri];
                          const col = row[ci];
                          const rowIds = headerData[pi][ti].rowIds;

                          const isMapped =
                            (docData[pi + "-" + ti + "-" + ri + "-" + ci] ||
                              headerOuter) &&
                            headerData[pi][ti].headers[ci];

                          const corrected = isMapped && col.corrected;

                          const lowConfidence =
                            isMapped && !corrected && col.confidence < 90;

                          const lowPredefConfidence =
                            isMapped &&
                            !corrected &&
                            col.predef &&
                            col.predef.confidence < 90;

                          const noPredefMapping =
                            isMapped &&
                            !corrected &&
                            col.predef &&
                            !col.predef.id;

                          const leaseStartDateAfterEnd = isLeaseStartDateAfterEnd(
                            exceptions,
                            { pi, ti, ri, ci }
                          );

                          const leaseDateLongerThanTenYears = isLeaseLongerThanTenYears(
                            exceptions,
                            { pi, ti, ri, ci }
                          );

                          const cellValueRequied = isCellValueRequired(
                            exceptions,
                            { pi, ti, ri, ci }
                          );

                          let cell = null;

                          if (
                            cache["rowHeader-" + pi + "-" + ti]
                              ? cache[
                                  "rowHeader-" + pi + "-" + ti
                                ].row.includes(ri)
                              : (rowIds && rowIds.includes(ri)) ||
                                headerData[pi][ti].rowIndex === ri
                          ) {
                            cell = (
                              <td
                                // key={tdAttribs.excelid}
                                // id={tdAttribs.excelid}
                                key={pi + "-" + ti + "-" + ri + "-" + ci}
                                id={pi + "-" + ti + "-" + ri + "-" + ci}
                                ref={ref =>
                                  (this.tableRefs[
                                    pi + "-" + ti + "-" + ri + "-" + ci
                                  ] = ref)
                                }
                                className={cx(
                                  "RentRollDocumentView__cellExcel",
                                  {
                                    ["RentRollDocumentView__cellMapped"]:
                                      isMapped || headerOuter
                                  },
                                  {
                                    ["RentRollDocumentView__cellVarOpacity"]: isMapped
                                  },
                                  {
                                    ["RentRollDocumentView__cellNotMapped"]: !isMapped
                                  }
                                )}
                                style={{
                                  backgroundColor: "rgba(0, 0, 224, 0.2)",
                                  border: "0.2px solid rgb(0,0,224)",
                                  position: "relative"
                                }}
                              >
                                {ci === 0 && (
                                  <div
                                    className="RentRollDocumentView__excel__headerToggle"
                                    style={{
                                      borderLeft: "5px solid blue",
                                      cursor: "pointer"
                                    }}
                                    onClick={() => {
                                      this.rowHeader(pi, ti, ri);
                                    }}
                                  />
                                )}
                                <HeaderEditPopover
                                  multiLineHeader={getMultiLineHeader(
                                    cache,
                                    docPage,
                                    { pi, ti, ci },
                                    rowIds
                                  )}
                                  cellData={col}
                                  isMapped={isMapped}
                                  fieldNames={fieldNames}
                                  headerData={headerData}
                                  location={{ pi, ti, ri, ci }}
                                  cache={cache}
                                  isExcel={true}
                                  clearEditHeader={clearEditHeader}
                                  updateHeader={this.updateHeader}
                                />
                              </td>
                            );
                          } else {
                            cell = (
                              <td
                                {...tdAttribs}
                                key={pi + "-" + ti + "-" + ri + "-" + ci}
                                id={pi + "-" + ti + "-" + ri + "-" + ci}
                                // key={tdAttribs.excelid}
                                // id={tdAttribs.excelid}
                                ref={ref =>
                                  (this.tableRefs[
                                    pi + "-" + ti + "-" + ri + "-" + ci
                                  ] = ref)
                                }
                                className={cx(
                                  "RentRollDocumentView__cellExcel",
                                  {
                                    ["Popover--isSelected-excel"]:
                                      selectedDocumentCell ===
                                      pi + "-" + ti + "-" + ri + "-" + ci
                                  },
                                  {
                                    ["RentRollDocumentView__excel__cellVarOpacity"]: isMapped
                                  },
                                  {
                                    ["RentRollDocumentView__excel__cellNotMapped"]: !isMapped
                                  },
                                  {
                                    ["RentRollDocumentView__excel__cellCorrected"]: corrected
                                  },
                                  {
                                    ["RentRollDocumentView__excel__cellLowConfidence"]: lowConfidence
                                  },
                                  {
                                    ["RentRollDocumentView__excel__cellLowPredefConfidence"]: lowPredefConfidence
                                  },
                                  {
                                    ["RentRollDocumentView__excel__cellNoPredefMapping"]: noPredefMapping
                                  },
                                  {
                                    ["RentRollDocumentView__excel__cellValueRequied"]: cellValueRequied
                                  },
                                  {
                                    ["RentRollDocumentView__excel__leaseStartDateError"]: leaseStartDateAfterEnd
                                  },
                                  {
                                    ["RentRollDocumentView__excel__leaseStartDateWarning"]:
                                      !leaseStartDateAfterEnd &&
                                      leaseDateLongerThanTenYears
                                  }
                                )}
                                style={
                                  isMapped
                                    ? {
                                        ...tdAttribs.style,
                                        textAlign: "center",
                                        position: "relative"
                                      }
                                    : {
                                        ...tdAttribs.style,
                                        position: "relative",
                                        height: "17px"
                                      }
                                }
                              >
                                {ci === 0 && (
                                  <div
                                    className="RentRollDocumentView__excel__headerToggle"
                                    style={{
                                      borderLeft: `5px solid black`,
                                      bottom: isMapped ? "" : "30%",
                                      cursor: "pointer"
                                    }}
                                    onClick={() => {
                                      this.rowHeader(pi, ti, ri);
                                    }}
                                  />
                                )}
                                {isMapped ? (
                                  <CellEditPopover
                                    cellData={col}
                                    isMapped={isMapped}
                                    headerOuter={headerOuter}
                                    fieldNames={fieldNames}
                                    location={{ pi, ti, ri, ci }}
                                    cache={cache}
                                    documentId={document.id}
                                    isExcel={true}
                                    updateSelectedCell={this.updateSelectedCell.bind(
                                      this,
                                      pi + "-" + ti + "-" + ri + "-" + ci
                                    )}
                                    clearSelectedCell={this.clearSelectedCell}
                                    handleScrollToCell={handleScrollToCell}
                                    propertyType={propertyType}
                                    headerData={headerData}
                                  />
                                ) : (
                                  col.text
                                )}
                              </td>
                            );
                          }
                          return cell;
                        })}
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </div>
          )}
      </div>
    );
  }
}

ExcelDocumentView.defaultProps = {
  widgetAuth: {}
};

ExcelDocumentView.propTypes = {
  page: PropTypes.object,
  pi: PropTypes.number,
  document: PropTypes.object,
  rentRoll: PropTypes.array,
  submitDocumentTemplate: PropTypes.func,
  submitDocumentField: PropTypes.func,
  textFieldsOpacity: PropTypes.number,
  editHeader: PropTypes.object,
  updateEditHeader: PropTypes.func,
  updateTextFieldsOpacity: PropTypes.func,
  clearEditHeader: PropTypes.func,
  clearCache: PropTypes.func,
  updateCache: PropTypes.func,
  cache: PropTypes.object,
  exceptionSidebarIsActive: PropTypes.bool,
  pageIndex: PropTypes.number,
  documentMarkupRendered: PropTypes.func,
  handleScrollToCell: PropTypes.func,
  fieldNamesMappingForDropDown: PropTypes.object,
  selectedDocumentCell: PropTypes.string,
  exceptions: PropTypes.array,
  documentNav: PropTypes.string,
  pageNavInProgress: PropTypes.bool,
  BeginDocNav: PropTypes.func,
  EndDocNav: PropTypes.func,
  propertyType: PropTypes.string,
  widgetAuth: PropTypes.object
};

function mapStateToProps({ rentRoll, splitScreen, currentUser }) {
  return {
    document: rentRoll.document,
    rentRoll: rentRoll.rentRoll,
    textFieldsOpacity: rentRoll.textFieldsOpacity,
    editHeader: rentRoll.editHeader,
    cache: rentRoll.cache,
    exceptionSidebarIsActive: rentRoll.exceptionSidebarIsActive,
    pageIndex: rentRoll.pageIndex,
    fieldNamesMappingForDropDown: rentRoll.fieldNamesMappingForDropDown,
    documentNav: splitScreen.documentNav,
    pageNavInProgress: splitScreen.pageNavInProgress,
    BeginDocumentNav: splitScreen.BeginDocumentNav,
    EndDocumentNav: splitScreen.EndDocumentNav,
    exceptions: rentRoll.exceptions,
    widgetAuth: currentUser.widgetAuth
  };
}

function mapDispatchToProps(dispatch) {
  return {
    submitDocumentTemplate: function(
      templateData,
      metaName,
      headerIndex,
      documentId,
      multipleLineGrouping,
      advancedRules,
      widgetAuth
    ) {
      dispatch(
        updateDocumentTemplate(
          templateData,
          metaName,
          headerIndex,
          documentId,
          multipleLineGrouping,
          advancedRules,
          widgetAuth
        )
      );
    },
    submitDocumentField: function(field, documentId, widgetAuth) {
      dispatch(updateDocumentField(field, documentId, widgetAuth));
    },
    updateEditHeader: val => dispatch(updateEditHeader(val)),
    updateTextFieldsOpacity: obj => dispatch(updateTextFieldsOpacity(obj)),
    clearEditHeader: () => dispatch(clearEditHeader()),
    // updateEditText: obj => dispatch(updateEditText(obj)),
    // clearEditText: () => dispatch(clearEditText()),
    updateCache: (id, val) => dispatch(updateCache(id, val)),
    clearCache: () => dispatch(clearCache()),
    BeginDocNav: () => dispatch(BeginDocumentNav()),
    EndDocNav: () => dispatch(EndDocumentNav())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ExcelDocumentView);
