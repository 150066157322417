import { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import cx from "classnames";

import { fetchFieldsByPropertyType } from "ui/components/admin/rentroll/helpers/api";

import PreviewPaneTable from "./PreviewPaneTable/PreviewPaneTable";

import {
  configureTableMarkup,
  configurePreviewPaneHeader,
  mapRentRollData
} from "./helpers/processing";

const PreviewPane = ({
  rentRoll,
  document,
  exceptionSidebarIsActive,
  handleRef,
  handleScrollToCell,
  updateDeletedRows,
  updateSelectedDocumentCell,
  updateSelectedRentRollCell,
  selectedDocumentCell,
  selectedRentRowCell,
  exceptions,
  propertyType,
  widgetAuth
}) => {
  const [isAddingRow, setIsAddingRow] = useState(false);
  const [previewConfig, setPreviewConfig] = useState([]);
  const dataList = mapRentRollData(rentRoll, document, previewConfig);
  const markupConfig = configureTableMarkup(document, exceptions);
  const tableRefs = useRef({});

  useEffect(() => {
    (async () => {
      if (propertyType) {
        const rentRollColumns = await fetchFieldsByPropertyType(
          propertyType.toLowerCase(),
          widgetAuth
        );
        const { previewTableConfig } = configurePreviewPaneHeader(
          document,
          rentRollColumns
        );
        setPreviewConfig(previewTableConfig);
      }
    })();
  }, [propertyType, document]);

  useEffect(() => {
    updateDeletedRows(rentRoll);
  }, [rentRoll]);

  useEffect(() => {
    handleRef(tableRefs);
  });

  return (
    <div
      className={cx("PreviewPane", {
        ["PreviewPane--withExceptionSidebar"]: exceptionSidebarIsActive
      })}
    >
      <div className="PreviewPane__header">
        <button
          className={cx("Button", "Button__blueButton")}
          onClick={() => setIsAddingRow(true)}
        >
          Add Row
        </button>
      </div>
      <PreviewPaneTable
        document={document}
        previewTableConfig={previewConfig}
        tableRefs={tableRefs}
        dataList={dataList}
        markupConfig={markupConfig}
        isAddingRow={isAddingRow}
        setIsAddingRow={setIsAddingRow}
        handleScrollToCell={handleScrollToCell}
        updateDeletedRows={updateDeletedRows}
        updateSelectedDocumentCell={updateSelectedDocumentCell}
        updateSelectedRentRollCell={updateSelectedRentRollCell}
        selectedDocumentCell={selectedDocumentCell}
        selectedRentRowCell={selectedRentRowCell}
        widgetAuth={widgetAuth}
      />
    </div>
  );
};

PreviewPane.defaultProps = {
  widgetAuth: {}
};

PreviewPane.propTypes = {
  rentRoll: PropTypes.array,
  exceptions: PropTypes.array,
  document: PropTypes.object,
  exceptionSidebarIsActive: PropTypes.bool,
  handleRef: PropTypes.func,
  updatePdfMarkdownLoading: PropTypes.func,
  handleScrollToCell: PropTypes.func,
  addNewRowToRentRoll: PropTypes.func,
  removeRowFromRentRoll: PropTypes.func,
  undoRemoveRowFromRentRoll: PropTypes.func,
  updateDeletedRows: PropTypes.func,
  updateSelectedDocumentCell: PropTypes.func,
  updateSelectedRentRollCell: PropTypes.func,
  selectedDocumentCell: PropTypes.string,
  selectedRentRowCell: PropTypes.string,
  propertyType: PropTypes.string,
  fetchRentRollColumns: PropTypes.func,
  widgetAuth: PropTypes.object
};

const mapStateToProps = ({ rentRoll }) => ({
  rentRoll: rentRoll.rentRoll,
  document: rentRoll.document,
  exceptionSidebarIsActive: rentRoll.exceptionSidebarIsActive,
  exceptions: rentRoll.exceptions
});

export default connect(mapStateToProps)(PreviewPane);
