import PropTypes from "prop-types";
import { getConfidenceCSSClassByConfidence, isCellConfirmed } from "ui/components/opex/shared";
import { BsFillExclamationTriangleFill } from "react-icons/bs";
import CorrectedCellIndicator from "../OpexBaseTable/CorrectedCellIndicator";

const OpexCellContainer = ({ confidence, corrected, children }) => {
  const confidenceClass = getConfidenceCSSClassByConfidence(confidence);
  return (
    <section
      className={
        `ConfidenceColorContainer
        ${(!corrected && !isCellConfirmed(corrected)) ? confidenceClass : ""}`
      }
    >
      <div className="ConfidenceIcon">
        {confidenceClass === "Unmapped" ? (
          <BsFillExclamationTriangleFill color="#863835" size="1rem" />
        ) : (
          <CorrectedCellIndicator corrected={corrected} />
        )}
      </div>
      {children}
    </section>
  );
};

export default OpexCellContainer;

OpexCellContainer.propTypes = {
  children: PropTypes.element,
  confidence: PropTypes.number,
  corrected: PropTypes.bool
};
