import { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import cx from "classnames";

import { showFlash } from "ui/store/actions/flash";

import {
  fetchFieldsByPropertyType,
  fetchRentRollColumns,
  updateFieldsByPropertyType
} from "ui/components/admin/rentroll/helpers/api";
import {
  validateCustomFormData,
  errorFlashMessage
} from "ui/components/admin/rentroll/helpers/customColumnsForm";

import CustomColumnsRow from "./CustomColumnsRow";
import { bindActionCreators } from "redux";

const propertyTypes = ["office", "retail", "industrial", "multi_family"];

const CustomColumnsForm = ({ showFlash }) => {
  const [propertyType, setPropertyType] = useState(propertyTypes[0]);
  const [formData, setFormData] = useState([]);
  const [newRows, setNewRows] = useState([]);
  const [rentRollColumns, setRentRollColumns] = useState([]);

  useEffect(() => {
    (async () => {
      const responseBody = await fetchRentRollColumns();
      const columns = Object.keys(responseBody.columnInfo);
      // Todo: Move addition of deposit, lease-dates, and lease-term to backend?
      setRentRollColumns([...columns, "deposit", "lease_dates", "lease_term"]);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const responseBody = await fetchFieldsByPropertyType(propertyType);
      const newFormData = responseBody.fields.map(row => ({
        ...row,
        id: `${responseBody.propertyType}-${row.term}`,
        name: `form-${responseBody.propertyType}-${row.term}`
      }));
      await setFormData([...newFormData]);
    })();
  }, [propertyType]);

  const handleNewRow = () => {
    const newRowsIdx = newRows.length;
    const updatedNewRows = [
      ...newRows,
      {
        term: "",
        displayTerm: "",
        status: "hide",
        id: `${newRowsIdx}-newRow`,
        name: `form-${newRowsIdx}-newRow`
      }
    ];
    setNewRows(updatedNewRows);
  };

  function handleDeleteRow(idx, newRow) {
    let updatedNewRows;
    if (newRow) {
      updatedNewRows = [...newRows.slice(0, idx), ...newRows.slice(idx + 1)];
      setNewRows(updatedNewRows);
    } else {
      updatedNewRows = [...formData.slice(0, idx), ...formData.slice(idx + 1)];
      setFormData(updatedNewRows);
    }
  }

  const handleSubmit = e => {
    e.preventDefault();
    (async () => {
      let newCustomFields;
      let allFields = [];
      const newFields = formData.map((row, idx) => {
        let dataType;
        if (!rentRollColumns.includes(row.term)) {
          dataType = document.getElementById(`${idx}-${row.id}-dataType`).value;
        }
        const status =
          document.getElementById(`${idx}-${row.id}-status`).className ===
          "ToggleSwitch ToggleSwitch--on"
            ? "show"
            : "hide";
        const required =
          document.getElementById(`${idx}-${row.id}-isRequired`).className ===
          "ToggleSwitch ToggleSwitch--on"
            ? true
            : false;
        const newField = {
          term: e.target[`${idx}-${row.id}-term`].value,
          displayTerm: e.target[`${idx}-${row.id}-displayTerm`].value,
          status,
          required
        };
        if (dataType) {
          newField.dataType = dataType;
        }
        return newField;
      });
      allFields = [...newFields];
      if (newRows.length > 0) {
        newCustomFields = newRows.map((row, idx) => {
          const dataType = document.getElementById(`${idx}-${row.id}-dataType`)
            .value;
          const status =
            document.getElementById(`${idx}-${row.id}-status`).className ===
            "ToggleSwitch ToggleSwitch--on"
              ? "show"
              : "hide";
          const required =
            document.getElementById(`${idx}-${row.id}-isRequired`).className ===
            "ToggleSwitch ToggleSwitch--on"
              ? true
              : false;
          return {
            term: e.target[`${idx}-${row.id}-term`].value,
            displayTerm: e.target[`${idx}-${row.id}-displayTerm`].value,
            dataType,
            status,
            required
          };
        });
        allFields = [...allFields, ...newCustomFields];
      }
      const isValid = validateCustomFormData(allFields);

      if (isValid.isValid) {
        const responseBody = await updateFieldsByPropertyType(
          propertyType,
          allFields
        );
        const newFormData = responseBody.fields.map(row => ({
          ...row,
          id: `${responseBody.propertyType}-${row.term}`,
          name: `form-${responseBody.propertyType}-${row.term}`
        }));
        await setFormData([...newFormData]);
        await setNewRows([]);
        showFlash({
          type: "success",
          message: `${propertyType} fields successfully updated.`
        });
      } else {
        showFlash(errorFlashMessage[isValid.error]);
      }
    })();
  };
  return (
    <div style={{ padding: "15px" }}>
      <label htmlFor="select-property-type">Property Type:</label>
      <select
        id="select-property-type"
        style={{ marginBottom: "10px" }}
        value={propertyType}
        onChange={e => {
          setPropertyType(e.target.value);
        }}
      >
        {propertyTypes.map(type => (
          <option key={type} value={type}>
            {type.toLocaleUpperCase()}
          </option>
        ))}
      </select>
      <form onSubmit={e => handleSubmit(e)}>
        {formData.map((row, idx) => (
          <CustomColumnsRow
            idx={idx}
            key={`${propertyType}-${idx}-${row.term}`}
            row={row}
            rentRollColumns={rentRollColumns}
            handleDeleteRow={handleDeleteRow}
          />
        ))}
        {newRows.map((row, idx) => (
          <CustomColumnsRow
            idx={idx}
            key={`${propertyType}-${idx}-newRow`}
            row={row}
            newRow={true}
            handleDeleteRow={handleDeleteRow}
          />
        ))}
        <div className="CustomColumnsForm__inputs">
          <button
            className={cx("Button__blackButton", "CustomColumnsForm__newRow")}
            style={{ marginRight: "10px", cursor: "pointer" }}
            onClick={e => {
              e.preventDefault();
              handleNewRow();
            }}
          >
            New Row
          </button>
          <button
            className={cx("Button__blackButton", "CustomColumnsForm__newRow")}
            style={{ marginRight: "10px", cursor: "pointer" }}
            onClick={e => {
              e.preventDefault();
              (async () => {
                const responseBody = await fetchFieldsByPropertyType(
                  propertyType
                );
                const newFormData = responseBody.fields.map(row => ({
                  ...row,
                  id: `${responseBody.propertyType}-${row.term}`,
                  name: `form-${responseBody.propertyType}-${row.term}`
                }));
                await setFormData([...newFormData]);
                await setNewRows([]);
              })();
            }}
          >
            Clear
          </button>
          <input
            className="Button__blueButton"
            style={{ cursor: "pointer" }}
            type="submit"
            defaultValue="Update"
          />
        </div>
      </form>
    </div>
  );
};

CustomColumnsForm.propTypes = {
  showFlash: PropTypes.func
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ showFlash }, dispatch);

export default connect(null, mapDispatchToProps)(CustomColumnsForm);
