import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";

import { getPredefsNeedAttention, setPredefMetadata } from "./helpers/util";
import { updateDocumentField } from "ui/store/actions/rentRoll";
import { hasAdminAccess } from "helpers/authorization";

import RadioLabel from "./RadioLabel";

const Suggestion = ({
  updateDocumentField,
  documentId,
  suggestion,
  rentRollDocument,
  rentRoll,
  changeView,
  setFieldParams,
  userRole
}) => {
  const predefsNeedAttention = getPredefsNeedAttention(
    rentRollDocument,
    rentRoll
  );
  const handleClick = async (e, s) => {
    const valueIdx = predefsNeedAttention.findIndex(
      predef => predef.text === suggestion.value
    );
    const location = predefsNeedAttention[valueIdx].location;
    const { pi, ti, ri, ci } = location;
    const field = {
      page: pi,
      table: ti,
      row: ri,
      col: ci,
      text: s.value,
      predefValueId: s.id,
      ref: s.ref
    };
    field.metadata = { ...setPredefMetadata(s).metadata };
    await updateDocumentField(field, documentId);
  };

  return (
    <div key={suggestion.value} className="EntityResolution__suggestion">
      <div className="EntityResolution__initialValue">{suggestion.value}</div>
      <div className="EntityResolution__issue">{`Issue: No match, ${
        suggestion.suggestions.length > 0
          ? " some options"
          : " no recommendation"
      }`}</div>
      <div>
        {suggestion.suggestions &&
          suggestion.suggestions.length > 0 &&
          suggestion.suggestions.map(s => (
            <div key={`${suggestion.value}-${s.value}`}>
              <input
                type="radio"
                id={`${suggestion.value}-${s.value}`}
                name={suggestion.value}
                onClick={e => handleClick(e, s)}
              />
              <RadioLabel selectedField={suggestion.value} predef={s} />
            </div>
          ))}
        <div
          className="EntityResolution__searchLink"
          onClick={() => {
            const valueIdx = predefsNeedAttention.findIndex(
              predef => predef.text === suggestion.value
            );
            const location = predefsNeedAttention[valueIdx].location;
            setFieldParams(suggestion.value, suggestion.field, location);
            changeView("search-entity-view", 0);
          }}
        >
          Search {hasAdminAccess(userRole) ? "or Create New" : ""}
        </div>
      </div>
    </div>
  );
};

Suggestion.propTypes = {
  documentId: PropTypes.number,
  suggestion: PropTypes.object,
  state: PropTypes.object,
  updateDocumentField: PropTypes.func,
  setFieldParams: PropTypes.func,
  changeView: PropTypes.func,
  rentRollDocument: PropTypes.object,
  rentRoll: PropTypes.array,
  userRole: PropTypes.string
};

const mapStateToProps = ({ currentUser }) => ({
  userRole: currentUser.role
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateDocumentField }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Suggestion);
