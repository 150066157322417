import { documentStatus } from "server/middleware/api/helpers/documents";
export const statusMap = {
  [documentStatus.CREATED]: {
    status: "Uploading"
  },
  [documentStatus.PROCESSING_IMAGES]: {
    status: "Analyzing"
  },
  [documentStatus.ANALYZING]: {
    status: "Analyzing"
  },
  [documentStatus.ANALYSIS_QUEUED]: {
    status: "Analysis Queued"
  },
  [documentStatus.PENDING_REVIEW]: {
    status: "Needs Review"
  },
  [documentStatus.COMPLETED]: {
    status: "Completed"
  },
  [documentStatus.PROPERTY_INFO_NEEDED]: {
    status: "Property Info Needed"
  }
};
