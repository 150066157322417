import { apiFetch } from "./apiClient";

export const FETCHING_PORTFOLIOS = "FETCHING_PORTFOLIOS";
export const FETCHED_PORTFOLIOS_SUCCESSFUL = "FETCHED_PORTFOLIOS_SUCCESSFUL";
export const CREATING_PORTFOLIO = "CREATING_PORTFOLIO";
export const CREATING_PORTFOLIO_SUCCESSFUL = "CREATING_PORTFOLIO_SUCCESSFUL";
export const UPDATING_PORTFOLIOS = "UPDATING_PORTFOLIOS";
export const UPDATED_PORTFOLIOS_SUCCESSFUL = "UPDATED_PORTFOLIOS_SUCCESSFUL";
export const CLEAR_PORTFOLIOS = "CLEAR_PORTFOLIOS";

export function fetchPortfoliosByCompanyId() {
  return async function(dispatch) {
    dispatch(fetchingPortfolios());

    try {
      const response = await apiFetch(`/api/portfolios`, {
        method: "get"
      });

      if (!response.ok) {
        const responseBody = await response.json();
        throw new Error(responseBody.error);
      }
      const responseBody = await response.json();
      dispatch(fetchingPortfoliosSuccessful(responseBody.portfolios));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export function addPortfolio(portfolio) {
  return async function(dispatch) {
    dispatch(creatingPortfolio());
    try {
      const response = await apiFetch(`/api/portfolios/add-portfolio`, {
        method: "post",
        body: JSON.stringify({ portfolio })
      });

      if (!response.ok) {
        const responseBody = await response.json();
        throw new Error(responseBody.error);
      }

      const responseBody = await response.json();
      dispatch(creatingPortfolioSuccessful(responseBody.portfolios));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export function updatePortfolioById(
  newName,
  newSector,
  companyId,
  portfolioId
) {
  const update = { name: newName, sector: newSector };
  return async function(dispatch) {
    try {
      const response = await apiFetch(`/api/portfolios/update`, {
        method: "put",
        body: JSON.stringify({ companyId, portfolioId, update })
      });

      if (!response.ok) {
        const responseBody = await response.json();
        throw new Error(responseBody.error);
      }
      const responseBody = await response.json();
      dispatch(updatedPortfoliosSuccessful(responseBody.portfolios));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export function fetchingPortfolios() {
  return { type: FETCHING_PORTFOLIOS };
}

export function fetchingPortfoliosSuccessful(portfolios) {
  return { portfolios, type: FETCHED_PORTFOLIOS_SUCCESSFUL };
}

export function updatingPortfolios() {
  return { type: UPDATING_PORTFOLIOS };
}

export function updatedPortfoliosSuccessful(portfolios) {
  return { portfolios, type: UPDATED_PORTFOLIOS_SUCCESSFUL };
}

export function creatingPortfolio() {
  return { type: CREATING_PORTFOLIO };
}

export function creatingPortfolioSuccessful(portfolios) {
  return { portfolios, type: CREATING_PORTFOLIO_SUCCESSFUL };
}

export function clearPortfolios() {
  return { type: CLEAR_PORTFOLIOS };
}
