import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import cx from "classnames";
import Select, { components } from "react-select";
import {
  autocompletePropertySearch,
  clearPropertiesObj,
  fetchPropertiesByCompany
} from "ui/store/actions/properties";

import { assignPropertyToDocument } from "ui/store/actions/rentRoll";
import { getPropertyValues } from "ui/components/properties/propertiesUtil";
import { documentType as DocumentType } from "ui/components/helpers/documents";
import { updateDocument } from "ui/store/actions/rentRoll";
import { documentFileType } from "server/middleware/api/helpers/documents";
import { isCreditLensByGroupName } from "ui/components/opex/shared";

const EditDataPopupBody = ({
  changePage,
  fetchPropertiesByCompany,
  closePopover,
  clearPropertiesObj,
  assignPropertyToDocument,
  updateDocument,
  documentId,
  fetchCompanyDocuments,
  autocompletePropertySearch,
  suggestedProperties,
  widgetAuth,
  sortOrder,
  sortName,
  filterObject,
  setSortByLastUpdated,
  fileExtension,
  getDocumentType,
  groupName
}) => {
  const [selectedProperty, setSelectedProperty] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [documentType, setDocumentType] = useState();
  const [isDocumentTypeUpdated, setIsDocumentTypeUpdated] = useState(false);

  const newPropertyOption = {
    value: "new_property",
    label: "+ New Property"
  };
  let isCL = isCreditLensByGroupName(groupName);
  const propertyTypes = getPropertyValues(isCL);
  const getPropertyTypeName = id => {
    const PropertyType = propertyTypes.filter(item => item.id === id);
    return PropertyType[0] ? PropertyType[0].name : id;
  };

  const propertyList = suggestedProperties && [
    newPropertyOption,
    ...suggestedProperties.map(property => ({
      value: property.property_id,
      label:
        (property.property_type
          ? getPropertyTypeName(property.property_type) + " - "
          : "") +
        (property.property_name ? property.property_name + "\n" : "") +
        property.street_1 +
        (property.city ? ", " + property.city : "") +
        (property.state ? ", " + property.state : "") +
        (property.postal_code ? " " + property.postal_code : "")
    }))
  ];

  const filterOptions = (candidate, input) => {
    if (input) {
      return candidate || candidate.value === newPropertyOption.value;
    }
    return true;
  };

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <img
          src={require("ui/images/icon-magnifyingglass.svg")}
          height="16px"
          width="16px"
        />
      </components.DropdownIndicator>
    );
  };

  useEffect(() => {
    fetchPropertiesByCompany(true, widgetAuth);
    return () => {
      clearPropertiesObj();
    };
  }, []);

  function handleSelectProperty(data) {
    if (documentType || !isPdfDocument(fileExtension.toLowerCase())) {
      setIsSaveEnabled(true);
    }
    setSelectedProperty(data);
  }
  function handleSelectDocumentType(selectedDocType) {
    setDocumentType(selectedDocType);
    setIsDocumentTypeUpdated(true);
    if (selectedProperty) setIsSaveEnabled(true);
    getDocumentType(selectedDocType);
  }

  function handleInputChange(inputValue) {
    autocompletePropertySearch(inputValue, widgetAuth);
  }

  function isPdfDocument(fileType) {
    return [
      documentFileType.PDF,
      documentFileType.PNG,
      documentFileType.JPG,
      documentFileType.JPEG
    ].includes(fileType);
  }

  return (
    <div className="EditDataPopup">
      <div className="EditDataPopup__header">
        Edit data
        <img
          src={require("ui/images/x-icon.svg")}
          style={{ height: "20px", float: "right", cursor: "pointer" }}
          onClick={() => closePopover()}
        />
      </div>
      <div className="EditDataPopup__body">
        <h2 className="EditDataPopup__prompt">Property</h2>
        <div className="EditDataPopup__existing-properties">
          <Select
            className="EditDataPopup__select"
            value={selectedProperty}
            onChange={handleSelectProperty}
            onInputChange={handleInputChange}
            isSearchable={true}
            isClearable={true}
            options={propertyList}
            placeholder={"Type for options"}
            components={{ DropdownIndicator }}
            classNamePrefix="EditDataPopup"
            filterOption={filterOptions}
          />
        </div>
        {isPdfDocument(fileExtension.toLowerCase()) && (
          <>
            <h2 className="EditDataPopup__prompt">Document type</h2>
            <div className="EditDataPopup__documentType">
              <div className="EditDataPopup__documentTypeRadioContainer">
                <div className="EditDataPopup__documentTypeInputContainer">
                  <input
                    type="radio"
                    checked={
                      documentType &&
                      documentType === DocumentType.OPERATING_STATEMENT
                    }
                    readOnly
                    onClick={() =>
                      handleSelectDocumentType(DocumentType.OPERATING_STATEMENT)
                    }
                    style={{ margin: "0px" }}
                  />
                  <div className="EditDataPopup__documentTypeRadioLabel">
                    Operating Statement
                  </div>
                </div>
                <div className="EditDataPopup__documentTypeInputContainer">
                  <input
                    type="radio"
                    checked={
                      documentType && documentType === DocumentType.RENT_ROLL
                    }
                    readOnly
                    onClick={() =>
                      handleSelectDocumentType(DocumentType.RENT_ROLL)
                    }
                    style={{ margin: "0px" }}
                  />
                  <div className="EditDataPopup__documentTypeRadioLabel">
                    Rent Roll
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="EditDataPopup__submit-form">
        {isLoading ? (
          <button className="Button" disabled={true}>
            Updating...
          </button>
        ) : (
          <button
            className={cx("Button__blueBackground")}
            style={{ border: "0px", marginLeft: "0px", padding: "8px 20px" }}
            onClick={async () => {
              setIsLoading(true);
              if (isDocumentTypeUpdated) {
                await updateDocument(
                  documentId,
                  {
                    document_type: documentType
                  },
                  widgetAuth
                );
              }
              if (selectedProperty.value === "new_property") {
                changePage("new-property");
              } else {
                await assignPropertyToDocument(
                  documentId,
                  selectedProperty.value,
                  widgetAuth
                );
                await fetchCompanyDocuments(
                  0,
                  100,
                  `${sortName}%7C${sortOrder}`,
                  widgetAuth,
                  filterObject
                );

                setSortByLastUpdated();
                setIsLoading(false);
                closePopover();
              }
            }}
            disabled={!isSaveEnabled}
          >
            Save
          </button>
        )}
        <button
          className={cx("Button__whiteBackground-cancel")}
          onClick={() => closePopover()}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

EditDataPopupBody.propTypes = {
  changePage: PropTypes.func,
  closePopover: PropTypes.func,
  properties: PropTypes.array,
  fetchPropertiesByCompany: PropTypes.func,
  clearPropertiesObj: PropTypes.func,
  assignPropertyToDocument: PropTypes.func,
  updateDocument: PropTypes.func,
  fetchCompanyDocuments: PropTypes.func,
  documentId: PropTypes.number,
  suggestedProperties: PropTypes.array,
  autocompletePropertySearch: PropTypes.func,
  widgetAuth: PropTypes.object,
  sortOrder: PropTypes.string,
  sortName: PropTypes.string,
  setSortByLastUpdated: PropTypes.func,
  filterObject: PropTypes.object,
  userRole: PropTypes.string,
  fileExtension: PropTypes.string,
  getDocumentType: PropTypes.func,
  groupName: PropTypes.string
};

const mapStateToProps = ({ properties, currentUser }) => ({
  properties: properties.propertiesSelect,
  suggestedProperties: properties.suggestedProperties.suggestions,
  userRole: currentUser.role,
  groupName: currentUser.company.group_name
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchPropertiesByCompany,
      clearPropertiesObj,
      assignPropertyToDocument,
      autocompletePropertySearch,
      updateDocument
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(EditDataPopupBody);
