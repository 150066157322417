import { merge } from "ramda";

import { UPDATE_SIDEBAR } from "ui/store/actions/headerSidebar";

export const UPDATING = "UPDATING";
export const UPDATED = "UPDATED";

export default function headerSidebar(
  state = {
    status: UPDATING,
    resultStatus: UPDATED,
    cache: {},
    sidebarTabSelected: 0,
    isSidebarOpen: false
  },
  action
) {
  switch (action.type) {
    case UPDATE_SIDEBAR: {
      const val = action.activeScreenIndex;
      return merge(state, {
        status: UPDATED,
        sidebarTabSelected: val
      });
    }
    default: {
      return state;
    }
  }
}
