import PropTypes from "prop-types";
import cx from "classnames";
import { useEffect, useState } from "react";
import { displayRequiredFields } from "./helpers/displayData";
import IconButton from "ui/components/shared/IconButton";
import ExcludedValuesPopover from "ui/components/rentRoll/splitPanelV2/popovers/excludedValues/ExcludedValuesPopover";
import doesCellHaveRentRoll from "ui/components/rentRoll/splitPanelV2/helpers/doesCellHaveRentRoll";
export default function SourceColumns({
  rentRoll,
  headerData,
  activeDocumentTables,
  documentHeaderData,
  tableData,
  headerSelectOptions,
  setHeaderData,
  documentTables,
  currentHeaderIndex,
  deleteHeader,
  addHeader,
  currentPageIndex,
  rentRollDocument,
  documentMetadata,
  setDocumentMetadata,
  tableIndex,
  refreshData,
  fields,
  setRequiredFields,
  setAdvancedRulesOpen
}) {
  const rentRolls = rentRoll.filter(
    ({ doc_page_id, doc_table_id }) =>
      doc_page_id === currentPageIndex && doc_table_id === tableIndex
  );
  const rowIds = documentMetadata[currentHeaderIndex].rowIds;
  const [isLoaded, setIsLoaded] = useState(false);

  const UNMAPPED = "unmapped";
  const MAPPED = "mapped";
  /*
  mappings: {
    sourceColumn: headerColumn
  }
  */
  const [mappings, setMappings] = useState({
    [MAPPED]: [],
    [UNMAPPED]: []
  });

  const headerRowIndex =
    (documentMetadata && documentMetadata[currentHeaderIndex].rowIndex) || 0;

  useEffect(() => {
    if (!headerData || !Object.keys(headerData).length) {
      return;
    }
    setMappings(headerData);
  }, [documentHeaderData, headerData]);

  useEffect(() => {
    // this goes ALLLLL the way to SplitPanelV2.jsx instead of using context
    // combine mapped/unmapped, filter out computed values, and sort by original index (very important)
    if (!mappings[MAPPED].length && !mappings[UNMAPPED].length) {
      return;
    }
    setHeaderData(
      [...mappings[MAPPED], ...mappings[UNMAPPED]]
        .filter(mapping => mapping.columnIndex !== -1)
        .sort((a, b) => a.columnIndex - b.columnIndex)
    );
  }, [mappings]);

  useEffect(() => {
    setIsLoaded(true);
    setRequiredFields(getRequiredFields());
  }, [headerData, activeDocumentTables, currentHeaderIndex]);

  const mapToSelectedColumn = (newId, source, headerIndex) => {
    mappings[source][headerIndex].id = newId;
    mappings[source][headerIndex].isMapped = true;

    documentMetadata[currentHeaderIndex].headers = [
      ...mappings[MAPPED],
      ...mappings[UNMAPPED]
    ].sort((a, b) => a.columnIndex - b.columnIndex);

    setMappings({
      ...mappings
    });
    setDocumentMetadata([...documentMetadata]);
  };

  const getRequiredFields = () => {
    let requiredFields = [];
    fields.forEach(field => {
      if (field.required) {
        requiredFields.push({
          key: field.term,
          value: field.displayTerm
        });
      }
    });
    return requiredFields;
  };
  const requiredFields = getRequiredFields();
  const sampleData = columnIndex => {
    const location = {
      columnIndex,
      pageIndex: currentPageIndex,
      rowIndex: rowIds && rowIds.length > 0 ? rowIds[rowIds.length - 1] + 1 : 0,
      tableIndex,
      headerIndex: currentHeaderIndex
    };
    while (
      !doesCellHaveRentRoll({ rentRolls, location }) &&
      location.rowIndex < tableData.length
    ) {
      location.rowIndex++;
    }
    return tableData[location.rowIndex] &&
      tableData[location.rowIndex][columnIndex]
      ? tableData[location.rowIndex][columnIndex].text
      : "";
  };

  const displayInputs = (headers, source) =>
    headers.map(
      ({ headerNames, id, key, columnIndex, text, ...header }, headerIndex) => {
        const isDuplicate = id && headers.filter(h => h.id === id).length > 1;
        return (
          <div
            key={key}
            className={`SourceColumns-${source} SourceColumns--row`}
          >
            <div className="SourceColumns--col">
              <input
                name="SourceColumnCheckbox"
                className={cx("SourceColumnCheckbox", {
                  checkedOn: documentMetadata[
                    currentHeaderIndex
                  ].selectedColumns.has(columnIndex)
                })}
                type="checkbox"
                onClick={e => {
                  documentMetadata[currentHeaderIndex].selectedColumns[
                    e.target.checked ? "add" : "delete"
                  ](columnIndex);
                  setDocumentMetadata([...documentMetadata]);
                }}
              />
            </div>
            <div className="SourceColumns--col">
              <div className="SourceColumnBox">
                <input
                  className="SourceColumnName"
                  name="SourceColumnName"
                  readOnly
                  value={
                    (headerNames && headerNames[0]) ||
                    (columnIndex === -1
                      ? "Advanced Rule"
                      : `col_${columnIndex}`)
                  }
                />
                {columnIndex !== -1 && (
                  <IconButton
                    onClick={() => {
                      source === MAPPED
                        ? deleteHeader(columnIndex)
                        : addHeader(columnIndex);
                    }}
                    src={
                      source === MAPPED
                        ? require("ui/images/x-icon.svg")
                        : require("ui/images/add-icon.svg")
                    }
                    className="SourceColumnName-btn"
                  />
                )}
              </div>
            </div>
            {source === MAPPED && (
              <>
                <div className="SourceColumns--col">
                  <ExcludedValuesPopover
                    refreshData={refreshData}
                    headerKey={header.key}
                    location={{
                      pi: currentPageIndex,
                      ti: tableIndex,
                      ci: columnIndex,
                      ri: headerRowIndex
                    }}
                    rentRollDocument={rentRollDocument}
                    documentTables={documentTables}
                    documentMetadata={documentMetadata}
                    currentHeaderIndex={currentHeaderIndex}
                    setHeaderData={setHeaderData}
                    rentRoll={rentRoll}
                  />
                </div>
                {columnIndex !== -1 && (
                  <>
                    <div className="SourceColumns--col">&rarr;</div>
                    <div className="SourceColumns--col">
                      <select
                        className={cx(
                          "SourceColumnSelect",
                          { "SourceColumnSelect--error": !id },
                          {
                            "SourceColumnSelect--warning": isDuplicate
                          }
                        )}
                        onChange={e =>
                          mapToSelectedColumn(
                            e.target.value,
                            source,
                            headerIndex
                          )
                        }
                        value={id}
                      >
                        {headerSelectOptions.map(({ term, displayTerm }) => (
                          <option value={term} key={term}>
                            {displayTerm}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="SourceColumns--col">
                      <input
                        name="MappedToRentRollSampleData"
                        className="MappedToRentRollSampleData"
                        key={`${key}-rentRollSampleData`}
                        readOnly
                        value={sampleData(columnIndex)}
                        disabled
                      />
                    </div>
                  </>
                )}
                {columnIndex === -1 && (
                  <>
                    <div className="SourceColumns--col">&rarr;</div>
                    <div className="SourceColumns--col">
                      <input
                        className="SourceColumnName"
                        name="SourceColumnName"
                        readOnly
                        value={text}
                        onClick={() => {
                          setAdvancedRulesOpen(true);
                        }}
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        );
      }
    );
  return (
    <div className="SourceColumns">
      {isLoaded && !!mappings[MAPPED].length && (
        <div className="SourceColumns--table">
          <div className="SourceColumns--row">
            <div className="SourceColumns--col"></div>
            <div className="SourceColumns--col">
              <label htmlFor="SourceColumnSelect">Source Columns</label>
            </div>
            <div className="SourceColumns--col">
              <label htmlFor="SourceColumnExclude">Excluded Values</label>
            </div>
            <div className="SourceColumns--col"></div>
            <div className="SourceColumns--col">
              <label htmlFor="SourceColumnSelect">Map to Rent Roll Data</label>
            </div>
            <div className="SourceColumns--col">
              <label htmlFor="SourceColumnSelect">Sample Data</label>
            </div>
          </div>
          {displayInputs(mappings[MAPPED], MAPPED)}
        </div>
      )}
      <div className="RequiredFields">
        {isLoaded && displayRequiredFields(requiredFields, mappings[MAPPED])}
      </div>

      {isLoaded && !!mappings[UNMAPPED].length && (
        <>
          <hr />
          <div className="SourceColumns--table">
            <div className="SourceColumns--row">
              <div className="SourceColumns--col"></div>
              <div className="SourceColumns--col">
                <label htmlFor="SourceColumnSelect">Not Used</label>
              </div>
            </div>
            {displayInputs(mappings[UNMAPPED], UNMAPPED)}
          </div>
        </>
      )}
    </div>
  );
}

SourceColumns.propTypes = {
  rentRoll: PropTypes.array,
  errors: PropTypes.array,
  headerData: PropTypes.object,
  activeDocumentTables: PropTypes.array,
  onSelected: PropTypes.func.isRequired,
  selected: PropTypes.string,
  setHeaderData: PropTypes.func,
  headerSelectOptions: PropTypes.array,
  tableData: PropTypes.array,
  documentHeaderData: PropTypes.array,
  documentTables: PropTypes.array.isRequired,
  currentHeaderIndex: PropTypes.number,
  tableIndex: PropTypes.number.isRequired,
  headerRows: PropTypes.array,
  deleteHeader: PropTypes.func,
  addHeader: PropTypes.func,
  headerRowIndex: PropTypes.number,
  currentPageIndex: PropTypes.number,
  rentRollDocument: PropTypes.object,
  documentMetadata: PropTypes.array,
  refreshData: PropTypes.func,
  fields: PropTypes.array,
  setRequiredFields: PropTypes.func,
  setDocumentMetadata: PropTypes.func,
  setAdvancedRulesOpen: PropTypes.func
};
