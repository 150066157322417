import useSWR from "swr";
import fetchData from "ui/components/rentRoll/splitPanelV2/helpers/fetchData";
import updateData from "ui/components/rentRoll/splitPanelV2/helpers/updateData";
import { apiFetch } from "ui/components/rentRoll/splitPanelV2/helpers/apiFetch";
import useOpexPanelContext from "./useOpexPanelContext";
import { publishCompletedEventToCpm } from "ui/components/opex/shared";

const useDocument = () => {
  const { documentId, widgetAuth, groupName } = useOpexPanelContext();

  const { data, error, mutate } = useSWR(
    `/api/get-document/${documentId}`,
    fetchData(widgetAuth)
  );

  const update = fields =>
    updateData({
      path: `/api/documents/update/${documentId}`,
      method: "put",
      data: { fields },
      widgetAuth
    });

  const updateAccounts = rows =>
    updateData({
      path: `/api/opex/documents/${documentId}/opex-accounts`,
      method: "PATCH",
      data: { rows },
      widgetAuth
    });

  const mapRowsToAccounts = async templateId => {
    const response = await apiFetch(
      `/api/opex/map-accounts/${documentId}/${templateId}`,
      { method: "put" },
      widgetAuth
    );
    return response.json();
  };

  const remapRowsToAccounts = async templateId => {
    const response = await apiFetch(
      `/api/opex/remap-accounts/${documentId}/${templateId}`,
      { method: "put" },
      widgetAuth
    );
    return response.json();
  };

  const markOpexAsComplete = async (documentId, propertyId) => {
    const response = await apiFetch(
      `/api/opex/mark-as-complete/${documentId}`,
      {
        method: "put"
      },
      widgetAuth
    );
    const responseBody = await response.json();
    if (!response.ok) {
      throw new Error(responseBody.error);
    }

    if (widgetAuth && widgetAuth.authorized) {
      publishCompletedEventToCpm({ documentId, propertyId });
    }
  };

  return {
    document: data ? data.document : null,
    error,
    mutate,
    groupName,
    mapRowsToAccounts,
    remapRowsToAccounts,
    isLoading: !error && !data,
    update,
    updateAccounts,
    markOpexAsComplete
  };
};

export default useDocument;
