import { notification } from "antd";

const NotificationToast = (type, message) => {
  notification.config({
    placement: "bottomRight"
  });
  notification.open({
    message,
    className: `MessageToast MessageToast-${type}`,
    duration: 5
  });
};

export default NotificationToast;
