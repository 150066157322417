import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import cx from "classnames";

import {
  updateExceptionSidebar,
  updatePageIndex
} from "ui/store/actions/rentRoll";

import AuthenticatedRoute from "ui/components/routing/AuthenticatedRoute";
import PageChanger from "./pageChanger/PageChanger";

const SplitPanelSubHeader = ({ rentRollDocument, includeTopNav }) => {
  return (
    <div
      className={cx("SplitPanelSubHeader", {
        ["SplitPanelSubHeader__widget"]: !includeTopNav
      })}
    >
      {rentRollDocument && rentRollDocument.file_extension && (
        <PageChanger fileExtension={rentRollDocument.file_extension} />
      )}
    </div>
  );
};

SplitPanelSubHeader.propTypes = {
  updateExceptionSidebar: PropTypes.func,
  updatePageIndex: PropTypes.func,
  rentRollDocument: PropTypes.object,
  pagination: PropTypes.array,
  pageIndex: PropTypes.number,
  includeTopNav: PropTypes.bool
};

const mapStateToProps = ({ rentRoll }) => ({
  rentRollDocument: rentRoll.document,
  pageIndex: rentRoll.pageIndex
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateExceptionSidebar, updatePageIndex }, dispatch);

export default AuthenticatedRoute(
  connect(mapStateToProps, mapDispatchToProps)(SplitPanelSubHeader)
);
