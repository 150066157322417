import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import { isBlank } from "helpers/presence";
import { generateApiSignature } from "ui/store/actions/s2sAuthentication";
import AuthenticatedRoute from "ui/components/routing/AuthenticatedRoute";

const moment = require("moment");

const GenerateAPISignature = ({
  currentUser,
  generateApiSignature,
  s2sAuthentication
}) => {
  const { company } = currentUser;
  const [apiPath, setApiPath] = useState(
    "api/authentication/widget/manage-files"
  );
  const [apiRequestDate, setApiRequestDate] = useState("");
  useEffect(() => {
    setApiRequestDate(moment().format("YYYY-MM-DDTHH:mm:ss.SSSZZ"));
  }, []);

  const { apiPublicKey, apiSignature } = s2sAuthentication;
  const showGeneratedSignature =
    !isBlank(apiPublicKey) && !isBlank(apiSignature);

  const handleChangeApiPath = e => setApiPath(e.target.value);
  const handleSubmit = async () => {
    await generateApiSignature({
      companyId: currentUser.company.id,
      apiPath,
      apiRequestDate
    });
  };
  const renderGeneratedSignature = () => {
    if (showGeneratedSignature) {
      return (
        <div>
          <hr />
          <div className="GenerateAPISignatureForm__row">
            Api public key: {apiPublicKey}
          </div>
          <div className="GenerateAPISignatureForm__row">
            Generated signature: {apiSignature}
          </div>
        </div>
      );
    }
  };

  return (
    <div className="GenerateAPISignatureForm">
      <div className="GenerateAPISignatureForm__row">
        <label>Company</label> {company.company_name}
      </div>
      <div className="GenerateAPISignatureForm__row">
        <label htmlFor="apiPath">API Path</label>
        <input
          type="text"
          name="apiPath"
          value={apiPath}
          size="50"
          onChange={handleChangeApiPath}
        />
      </div>
      <div className="GenerateAPISignatureForm__row">
        <label htmlFor="apiRequestDate">API Request Date</label>
        {apiRequestDate}
        <input type="hidden" name="apiRequestDate" value={apiRequestDate} />
      </div>
      <div className="GenerateAPISignatureForm__row">
        <button className="Button Button__blueButton" onClick={handleSubmit}>
          Generate Signature
        </button>
      </div>
      {renderGeneratedSignature()}
    </div>
  );
};

GenerateAPISignature.propTypes = {
  currentUser: PropTypes.object,
  s2sAuthentication: PropTypes.object,
  generateApiSignature: PropTypes.func
};

function mapStateToProps({ currentUser, s2sAuthentication }) {
  return {
    currentUser,
    s2sAuthentication
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ generateApiSignature }, dispatch);
}

export default AuthenticatedRoute(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(GenerateAPISignature))
);
