import PropTypes from "prop-types";
import {
  WORKFLOW_TABLE_SELECTION,
  WORKFLOW_ACCOUNT_MAPPING,
  WORKFLOW_COLUMN_PERIODIZATION
} from "ui/components/opex/helpers/opex";
import useDocument from "../../../hooks/useDocument";
import useOpex from "../../../hooks/useOpex";
import OpexData from "../../DocumentView/modelOpexData";
import CompletedStatus from "../../DocumentView/components/CompletedStatus/CompletedStatus";

import {
  isCPMByGroupName,
  isCreditLensByGroupName,
  showNotification
} from "ui/components/opex/shared";
import { TemplateStatus } from "helpers/opex";
import { Button } from "antd";

const BottomNav = ({
  template,
  currentWorkflowStatus,
  saveAndContinue,
  goToPreviousStep
}) => {
  const { document } = useDocument();
  const { opex, groupName } = useOpex();
  const modelOpexData = new OpexData({ document, opex });
  const isLastStep = currentWorkflowStatus === WORKFLOW_COLUMN_PERIODIZATION;
  const isStepTwo = currentWorkflowStatus === WORKFLOW_ACCOUNT_MAPPING;
  const publishedWithArchivedTemp =
    modelOpexData.isPublished() && template?.status === TemplateStatus.ARCHIVED;

  const handleNextClick = () => {
    if (
      currentWorkflowStatus === WORKFLOW_TABLE_SELECTION &&
      modelOpexData.hasNoTablesQualified()
    ) {
      showNotification({
        type: "error",
        message: "The document looks empty",
        description: "We couldn't recognize any tables in your document"
      });
      return false;
    }
    return saveAndContinue();
  };

  const handleBackClick = () => {
    goToPreviousStep();
  };

  const renderNextButton = () => {
    const isCL = isCreditLensByGroupName(groupName);
    let text = "Continue";
    let disabled = false;

    // If the app is CPM, you can re-publish
    if (isCPMByGroupName(groupName) && isLastStep) {
      text = "Publish";
    } else if (isLastStep) {
      text = "Complete";
    }

    if (
      !isCL &&
      isStepTwo &&
      !modelOpexData.isPublished() &&
      template?.status === TemplateStatus.ARCHIVED
    ) {
      disabled = true;
    }

    if (isLastStep) {
      text = isCL
        ? "Complete"
        : modelOpexData.isPublished()
        ? "Republish changes"
        : "Mark as complete";
      if (
        // If the template has been archived
        template?.status === TemplateStatus.ARCHIVED ||
        // If there are all ignored columns, disable the complete button
        !modelOpexData.getActivePeriods().length ||
        // All active periods must have a start date and end date
        !modelOpexData.hasAllActivePeriodsSet() ||
        // If the statement is published, you must make change to republish
        (modelOpexData.isPublished() && !modelOpexData.hasChangesAfterPublish())
      ) {
        disabled = true;
      }
    }

    return (
      <Button
        onClick={handleNextClick}
        type="primary"
        disabled={disabled}
        className={`NavButton ${isLastStep ? "OpexButton-fullWidth" : ""}`}
      >
        {text}
      </Button>
    );
  };

  const renderButtonBar = () => (
    <>
      {currentWorkflowStatus !== WORKFLOW_TABLE_SELECTION && (
        <Button
          onClick={handleBackClick}
          disabled={publishedWithArchivedTemp && isLastStep}
          className="NavButton"
        >
          Back
        </Button>
      )}

      {renderNextButton()}
    </>
  );

  return (
    <div className="OpexPanelView__BottomNav">
      {!isCPMByGroupName(groupName) && modelOpexData.isStatusCompleted() && (
        <CompletedStatus
          date={modelOpexData.getLastPublishedDate()}
          text="Completed on"
          type="multiline"
        />
      )}
      {renderButtonBar()}
    </div>
  );
};

export default BottomNav;

BottomNav.propTypes = {
  template: PropTypes.object,
  currentWorkflowStatus: PropTypes.string,
  currentTable: PropTypes.number,
  saveAndContinue: PropTypes.func,
  goToPreviousStep: PropTypes.func
};
