import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import cx from "classnames";

import {
  updatePdfMarkdownLoading,
  updatePdfMarkdownLoaded,
  fetchRentRollDocument
} from "ui/store/actions/rentRoll";

import {
  EndPageNav,
  BeginDocumentNav,
  EndDocumentNav
} from "ui/store/actions/splitScreen";

import PdfDocumentMarkup from "./PdfDocumentMarkup";
import { getPageByIndex } from "./helpers/processing";
import { scrollToCellOnDocument } from "./helpers/navigation";

class PdfDocumentView extends Component {
  constructor(props) {
    super();

    this.state = {
      page: getPageByIndex(props.document, props.pageIndex),
      imageLoaded: false,
      svgLoaded: false,
      predefModal: false
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!nextState.predefModal) {
      return true;
    } else {
      return false;
    }
  }

  componentDidUpdate(prevProps) {
    const {
      document,
      pageIndex,
      pdfMarkdownStatus,
      documentNav,
      pageNavInProgress
    } = this.props;

    if (
      pageIndex !== prevProps.pageIndex ||
      document !== prevProps.document ||
      (pdfMarkdownStatus === "LOADING" &&
        prevProps.pdfMarkdownStatus !== pdfMarkdownStatus)
    ) {
      this.setState({
        imageLoaded: false,
        svgLoaded: false,
        page: getPageByIndex(document, pageIndex)
      });
    }

    if (
      documentNav &&
      prevProps.pageNavInProgress !== pageNavInProgress &&
      pageNavInProgress === false
    ) {
      this.scrollToCellInDocument();
    }

    if (prevProps.documentNav !== documentNav && pageNavInProgress === false) {
      this.scrollToCellInDocument();
    }
  }

  scrollToCellInDocument = () => {
    const { BeginDocumentNav, EndDocumentNav, documentNav } = this.props;
    if (documentNav !== "") {
      BeginDocumentNav();
      scrollToCellOnDocument(documentNav);
      new Promise(resolve => setTimeout(resolve, 1000)).then(() =>
        EndDocumentNav()
      );
    }
  };

  handleImageLoaded = () => this.setState({ imageLoaded: true });

  setPredefModal = bool => this.setState({ predefModal: bool });

  render() {
    const {
      document,
      exceptionSidebarIsActive,
      pageIndex,
      deletedRows,
      handleScrollToCell,
      selectedDocumentCell,
      updateSelectedRentRollCell,
      updateSelectedDocumentCell,
      propertyType,
      widgetAuth
    } = this.props;
    const isWidgetAuthorized = widgetAuth && widgetAuth.authorized;
    return (
      <div
        className={cx(
          "SplitPanel__documentView",
          {
            ["SplitPanel--withExceptionSidebar"]: exceptionSidebarIsActive
          },
          {
            ["SplitPanel__documentView__widget"]: isWidgetAuthorized
          }
        )}
      >
        {document &&
          document.raw_data_json &&
          document.raw_data_json.pages &&
          this.state.page && (
            <div
              style={{
                position: "relative",
                display: "inline-grid"
              }}
            >
              <img
                src={this.state.page.imageUrl}
                onLoad={this.handleImageLoaded.bind(this)}
                style={{ width: "100%" }}
              />
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%"
                }}
              >
                {this.state.imageLoaded && (
                  <PdfDocumentMarkup
                    page={this.state.page}
                    pi={pageIndex}
                    deletedRows={deletedRows}
                    handleScrollToCell={handleScrollToCell}
                    selectedDocumentCell={selectedDocumentCell}
                    updateSelectedRentRollCell={updateSelectedRentRollCell}
                    updateSelectedDocumentCell={updateSelectedDocumentCell}
                    setPredefModal={this.setPredefModal}
                    propertyType={propertyType}
                  />
                )}
              </div>
            </div>
          )}
      </div>
    );
  }
}

PdfDocumentView.propTypes = {
  document: PropTypes.object,
  exceptionSidebarIsActive: PropTypes.bool,
  cellNavInProgress: PropTypes.bool,
  pageNavInProgress: PropTypes.bool,
  pageIndex: PropTypes.number,
  updatePdfMarkdownLoading: PropTypes.func,
  fetchRentRollDocument: PropTypes.func,
  updatePdfMarkdownLoaded: PropTypes.func,
  EndPageNav: PropTypes.func,
  pdfMarkdownStatus: PropTypes.string,
  deletedRows: PropTypes.array,
  documentNav: PropTypes.string,
  BeginDocumentNav: PropTypes.func,
  EndDocumentNav: PropTypes.func,
  tableRefs: PropTypes.object,
  handleScrollToCell: PropTypes.func,
  selectedDocumentCell: PropTypes.string,
  updateSelectedRentRollCell: PropTypes.func,
  updateSelectedDocumentCell: PropTypes.func,
  propertyType: PropTypes.string,
  widgetAuth: PropTypes.object
};

function mapStateToProps({ rentRoll, splitScreen, currentUser }) {
  return {
    document: rentRoll.document,
    rentRoll: rentRoll.rentRoll,
    editHeader: rentRoll.editHeader,
    editText: rentRoll.editText,
    cache: rentRoll.cache,
    exceptionSidebarIsActive: rentRoll.exceptionSidebarIsActive,
    pageIndex: rentRoll.pageIndex,
    pdfMarkdownStatus: rentRoll.pdfMarkdownStatus,
    documentNav: splitScreen.documentNav,
    pageNavInProgress: splitScreen.pageNavInProgress,
    pageNav: splitScreen.pageNav,
    widgetAuth: currentUser.widgetAuth
  };
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updatePdfMarkdownLoading,
      updatePdfMarkdownLoaded,
      fetchRentRollDocument,
      EndPageNav,
      BeginDocumentNav,
      EndDocumentNav
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PdfDocumentView);
