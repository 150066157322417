import PropTypes from "prop-types";
import { GoCircleSlash } from "react-icons/go";
import { AiFillPlusCircle } from "react-icons/ai";

const IncludeExcludeRowButton = ({ className, style, isExcluded, onClick }) => (
  <button
    className={className}
    style={style}
    onClick={onClick}
  >
    {isExcluded ? (
      <AiFillPlusCircle size="20px" />
    ) : (
      <GoCircleSlash strokeWidth={0.5} size="20px" />
    )}
    <span style={{ width: "5rem" }}>
      {isExcluded ? "Include Row" : "Exclude Row"}
    </span>
  </button>
);

export default IncludeExcludeRowButton;

IncludeExcludeRowButton.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  isExcluded: PropTypes.bool,
  onClick: PropTypes.func
};
